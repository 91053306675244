export const getZones = (rsr) => {
  let zones = [];
  var _106 = {
    path:
      "M 249.00,604.00 C 260.16,614.44 269.50,626.41 279.43,638.00 279.43,638.00 294.00,654.00 294.00,654.00 294.00,654.00 305.87,668.00 305.87,668.00 307.32,669.69 311.23,674.09 311.85,676.00 313.85,682.23 301.75,686.49 303.20,692.00 304.09,695.42 316.47,705.00 315.48,712.00 314.95,715.70 307.91,724.39 305.52,728.00 299.86,736.52 293.06,749.57 289.00,759.00 278.33,757.36 268.26,753.21 258.00,750.03 258.00,750.03 200.00,731.97 200.00,731.97 200.00,731.97 178.00,725.26 178.00,725.26 175.89,724.67 171.26,723.43 170.11,721.49 168.78,719.23 170.86,714.33 171.69,712.00 171.69,712.00 181.25,689.00 181.25,689.00 192.18,666.99 204.24,648.09 221.09,630.00 221.09,630.00 249.00,604.00 249.00,604.00 Z",
    attr: {
      id: "_106",
      "data-name": "106",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "106", zone: "Lower Zone", zoneId: "1" },
  };
  var _107 = {
    path:
      "M 264.00,594.00 C 264.00,594.00 311.00,562.67 311.00,562.67 311.00,562.67 336.00,547.00 336.00,547.00 336.00,547.00 361.09,583.00 361.09,583.00 361.09,583.00 379.00,608.00 379.00,608.00 381.88,612.44 392.10,624.68 392.48,629.00 392.73,631.78 389.47,639.19 388.28,642.00 386.76,645.62 383.21,655.15 381.15,657.79 381.15,657.79 363.00,671.00 363.00,671.00 363.00,671.00 343.00,648.00 343.00,648.00 339.17,653.35 330.35,662.17 325.00,666.00 325.00,666.00 299.57,636.01 299.57,636.01 299.57,636.01 283.27,618.00 283.27,618.00 283.27,618.00 264.00,594.00 264.00,594.00 Z",
    attr: {
      id: "_107",
      "data-name": "107",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "107", zone: "Lower Zone", zoneId: "1" },
  };

  var _108 = {
    path:
      "M 394.00,507.00 C 394.00,507.00 416.69,537.00 416.69,537.00 416.69,537.00 424.94,549.00 424.94,549.00 424.94,549.00 419.01,566.00 419.01,566.00 419.01,566.00 406.85,596.00 406.85,596.00 406.85,596.00 400.00,610.00 400.00,610.00 400.00,610.00 362.79,557.00 362.79,557.00 362.79,557.00 349.00,537.00 349.00,537.00 349.00,537.00 394.00,507.00 394.00,507.00 Z",
    attr: {
      id: "_108",
      "data-name": "108",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "108", zone: "Lower Zone", zoneId: "1" },
  };

  var _109 = {
    path:
      "M 459.00,464.00 C 459.81,468.54 455.04,477.52 453.13,482.00 453.13,482.00 439.29,516.00 439.29,516.00 437.26,520.79 435.46,527.37 432.00,531.00 432.00,531.00 409.00,497.00 409.00,497.00 409.00,497.00 459.00,464.00 459.00,464.00 Z",
    attr: {
      id: "_109",
      "data-name": "109",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "109", zone: "Lower Zone", zoneId: "1" },
  };

  var _110 = {
    path:
      "M 450.00,533.00 C 450.00,533.00 453.28,547.00 453.28,547.00 453.28,547.00 457.25,568.00 457.25,568.00 457.25,568.00 470.00,639.00 470.00,639.00 470.00,639.00 440.00,642.28 440.00,642.28 440.00,642.28 415.00,647.23 415.00,647.23 415.00,647.23 403.00,650.00 403.00,650.00 403.00,650.00 411.66,628.00 411.66,628.00 411.66,628.00 439.45,559.00 439.45,559.00 439.45,559.00 450.00,533.00 450.00,533.00 Z",
    attr: {
      id: "_110",
      "data-name": "110",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "110", zone: "Lower Zone", zoneId: "1" },
  };

  var _111 = {
    path:
      "M 487.00,638.00 C 487.00,638.00 468.92,538.00 468.92,538.00 468.92,538.00 464.95,518.00 464.95,518.00 464.95,518.00 460.86,508.00 460.86,508.00 460.86,508.00 465.06,496.00 465.06,496.00 465.06,496.00 476.44,468.00 476.44,468.00 476.44,468.00 485.86,445.00 485.86,445.00 485.86,445.00 491.47,433.59 491.47,433.59 491.47,433.59 512.00,428.40 512.00,428.40 512.00,428.40 562.00,418.00 562.00,418.00 562.00,418.00 594.00,581.00 594.00,581.00 594.00,581.00 600.92,617.00 600.92,617.00 601.37,619.64 603.40,627.38 601.94,629.40 600.32,631.64 593.61,630.97 591.00,631.00 591.00,631.00 581.00,631.91 581.00,631.91 581.00,631.91 558.00,633.00 558.00,633.00 558.00,633.00 548.00,633.91 548.00,633.91 548.00,633.91 512.00,636.09 512.00,636.09 512.00,636.09 487.00,638.00 487.00,638.00 Z",
    attr: {
      id: "_111",
      "data-name": "111",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "111", zone: "Lower Zone", zoneId: "1" },
  };

  var _112 = {
    path:
      "M 578.00,414.00 C 591.91,413.36 646.92,398.03 663.00,398.00 663.00,398.00 688.00,398.00 688.00,398.00 688.00,398.00 688.00,627.00 688.00,627.00 688.00,627.00 665.00,627.00 665.00,627.00 665.00,627.00 650.00,628.00 650.00,628.00 650.00,628.00 620.00,629.00 620.00,629.00 620.00,629.00 612.45,589.00 612.45,589.00 612.45,589.00 600.80,529.00 600.80,529.00 600.80,529.00 587.42,461.00 587.42,461.00 587.42,461.00 578.00,414.00 578.00,414.00 Z",
    attr: {
      id: "_112",
      "data-name": "112",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "112", zone: "Lower Zone", zoneId: "1" },
  };

  var _113 = {
    path: "M 705.00,398.00 C 705.00,398.00 800.00,398.00 800.00,398.00 800.00,398.00 800.00,627.00 800.00,627.00 800.00,627.00 705.00,627.00 705.00,627.00 705.00,627.00 705.00,398.00 705.00,398.00 Z",
    attr: {
      id: "_113",
      "data-name": "113",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "113", zone: "Lower Zone", zoneId: "1" },
  };

  var _114 = {
    path: "M 817.00,398.00 C 817.00,398.00 915.00,398.00 915.00,398.00 915.00,398.00 915.00,627.00 915.00,627.00 915.00,627.00 817.00,627.00 817.00,627.00 817.00,627.00 817.00,398.00 817.00,398.00 Z",
    attr: {
      id: "_114",
      "data-name": "114",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "114", zone: "Lower Zone", zoneId: "1" },
  };

  var _115 = {
    path: "M 932.00,398.00 C 932.00,398.00 1027.00,398.00 1027.00,398.00 1027.00,398.00 1027.00,627.00 1027.00,627.00 1027.00,627.00 932.00,627.00 932.00,627.00 932.00,627.00 932.00,398.00 932.00,398.00 Z",
    attr: {
      id: "_115",
      "data-name": "115",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "115", zone: "Lower Zone", zoneId: "1" },
  };

  var _116 = {
    path: "M 1044.00,398.00 C 1044.00,398.00 1141.00,398.00 1141.00,398.00 1141.00,398.00 1141.00,627.00 1141.00,627.00 1141.00,627.00 1044.00,627.00 1044.00,627.00 1044.00,627.00 1044.00,398.00 1044.00,398.00 Z",
    attr: {
      id: "_116",
      "data-name": "116",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "116", zone: "Lower Zone", zoneId: "1" },
  };

  var _117 = {
    path:
      "M 1158.00,398.00 C 1158.00,398.00 1190.00,398.00 1190.00,398.00 1190.00,398.00 1223.00,403.58 1223.00,403.58 1223.00,403.58 1256.00,410.00 1256.00,410.00 1256.00,410.00 1272.00,413.00 1272.00,413.00 1272.00,413.00 1266.42,443.00 1266.42,443.00 1266.42,443.00 1257.45,486.00 1257.45,486.00 1257.45,486.00 1237.34,585.00 1237.34,585.00 1237.34,585.00 1228.00,630.00 1228.00,630.00 1228.00,630.00 1185.00,627.00 1185.00,627.00 1185.00,627.00 1158.00,627.00 1158.00,627.00 1158.00,627.00 1158.00,398.00 1158.00,398.00 Z",
    attr: {
      id: "_117",
      "data-name": "117",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "117", zone: "Lower Zone", zoneId: "1" },
  };

  var _118 = {
    path:
      "M 1245.00,631.00 C 1245.00,631.00 1254.20,586.00 1254.20,586.00 1254.20,586.00 1277.60,472.00 1277.60,472.00 1277.60,472.00 1286.00,432.00 1286.00,432.00 1286.00,432.00 1289.73,418.02 1289.73,418.02 1289.73,418.02 1296.00,418.02 1296.00,418.02 1296.00,418.02 1312.00,421.25 1312.00,421.25 1312.00,421.25 1341.00,427.12 1341.00,427.12 1341.00,427.12 1351.30,430.56 1351.30,430.56 1351.30,430.56 1355.86,440.00 1355.86,440.00 1355.86,440.00 1363.99,460.00 1363.99,460.00 1363.99,460.00 1374.30,487.00 1374.30,487.00 1374.30,487.00 1370.88,506.00 1370.88,506.00 1370.88,506.00 1364.45,539.00 1364.45,539.00 1364.45,539.00 1353.26,597.00 1353.26,597.00 1353.26,597.00 1345.00,639.00 1345.00,639.00 1345.00,639.00 1318.00,636.91 1318.00,636.91 1318.00,636.91 1271.00,633.17 1271.00,633.17 1271.00,633.17 1245.00,631.00 1245.00,631.00 Z",
    attr: {
      id: "_118",
      "data-name": "118",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "118", zone: "Lower Zone", zoneId: "1" },
  };

  var _119 = {
    path:
      "M 1386.00,516.00 C 1386.00,516.00 1393.73,534.00 1393.73,534.00 1393.73,534.00 1409.00,571.00 1409.00,571.00 1409.00,571.00 1384.00,566.00 1384.00,566.00 1384.00,566.00 1415.00,644.00 1415.00,644.00 1415.00,644.00 1405.00,643.09 1405.00,643.09 1405.00,643.09 1384.00,641.83 1384.00,641.83 1384.00,641.83 1362.00,640.00 1362.00,640.00 1362.00,640.00 1378.45,555.00 1378.45,555.00 1378.45,555.00 1386.00,516.00 1386.00,516.00 Z",
    attr: {
      id: "_119",
      "data-name": "119",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "119", zone: "Lower Zone", zoneId: "1" },
  };

  var _120 = {
    path:
      "M 1334.00,341.00 C 1334.00,341.00 1366.00,361.33 1366.00,361.33 1366.00,361.33 1441.00,411.00 1441.00,411.00 1441.00,411.00 1429.91,431.00 1429.91,431.00 1429.91,431.00 1408.22,466.00 1408.22,466.00 1408.22,466.00 1395.00,488.00 1395.00,488.00 1390.97,485.48 1389.62,480.29 1387.96,476.00 1387.96,476.00 1377.87,451.00 1377.87,451.00 1377.87,451.00 1350.86,384.00 1350.86,384.00 1350.86,384.00 1334.00,341.00 1334.00,341.00 Z",
    attr: {
      id: "_120",
      "data-name": "120",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "120", zone: "Lower Zone", zoneId: "1" },
  };

  var _121 = {
    path:
      "M 1455.00,421.00 C 1455.00,421.00 1484.00,440.28 1484.00,440.28 1484.00,440.28 1509.00,457.00 1509.00,457.00 1509.00,457.00 1523.00,466.33 1523.00,466.33 1524.65,467.44 1528.05,469.50 1528.84,471.32 1529.95,473.92 1527.05,477.82 1525.69,480.00 1525.69,480.00 1509.79,503.00 1509.79,503.00 1509.79,503.00 1462.07,572.00 1462.07,572.00 1462.07,572.00 1447.66,593.00 1447.66,593.00 1444.59,597.36 1443.82,600.34 1439.00,603.00 1439.00,603.00 1431.34,583.00 1431.34,583.00 1431.34,583.00 1411.66,534.00 1411.66,534.00 1411.66,534.00 1404.26,516.00 1404.26,516.00 1404.26,516.00 1401.89,508.00 1401.89,508.00 1401.89,508.00 1407.18,498.00 1407.18,498.00 1407.18,498.00 1418.50,480.00 1418.50,480.00 1418.50,480.00 1455.00,421.00 1455.00,421.00 Z",
    attr: {
      id: "_121",
      "data-name": "121",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "121", zone: "Lower Zone", zoneId: "1" },
  };

  var _122 = {
    path:
      "M 1544.00,481.00 C 1544.00,481.00 1602.00,519.00 1602.00,519.00 1609.26,523.84 1628.20,535.33 1633.00,541.00 1633.00,541.00 1607.58,571.00 1607.58,571.00 1607.58,571.00 1591.29,589.00 1591.29,589.00 1591.29,589.00 1566.83,618.00 1566.83,618.00 1566.83,618.00 1558.28,627.00 1558.28,627.00 1558.28,627.00 1532.83,657.00 1532.83,657.00 1532.83,657.00 1507.00,687.00 1507.00,687.00 1507.00,687.00 1480.00,667.44 1480.00,667.44 1480.00,667.44 1461.81,656.37 1461.81,656.37 1459.82,654.11 1456.02,643.43 1454.58,640.00 1452.91,636.02 1447.84,625.83 1448.60,622.00 1449.38,618.07 1454.67,611.34 1457.08,608.00 1457.08,608.00 1474.93,582.00 1474.93,582.00 1474.93,582.00 1491.03,559.00 1491.03,559.00 1491.03,559.00 1518.96,518.00 1518.96,518.00 1518.96,518.00 1544.00,481.00 1544.00,481.00 Z",
    attr: {
      id: "_122",
      "data-name": "122",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "122", zone: "Lower Zone", zoneId: "1" },
  };

  var _123 = {
    path:
      "M 1518.00,698.00 C 1518.00,698.00 1575.73,631.91 1575.73,631.91 1575.73,631.91 1590.08,615.00 1590.08,615.00 1591.61,613.17 1594.78,608.93 1597.04,608.34 1599.80,607.61 1602.21,610.32 1604.00,612.02 1609.50,617.26 1614.64,623.23 1619.57,629.00 1633.36,645.12 1644.80,661.58 1655.28,680.00 1659.76,687.88 1666.69,700.45 1669.00,709.00 1669.00,709.00 1625.00,726.26 1625.00,726.26 1625.00,726.26 1575.00,745.86 1575.00,745.86 1575.00,745.86 1557.00,753.00 1557.00,753.00 1552.14,742.10 1539.18,723.42 1531.54,714.00 1531.54,714.00 1518.00,698.00 1518.00,698.00 Z",
    attr: {
      id: "_123",
      "data-name": "123",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "123", zone: "Lower Zone", zoneId: "1" },
  };

  var _124 = {
    path:
      "M 1551.00,805.00 C 1551.00,805.00 1551.00,781.00 1551.00,781.00 1551.00,781.00 1552.02,773.50 1552.02,773.50 1552.02,773.50 1561.00,768.99 1561.00,768.99 1561.00,768.99 1580.00,761.34 1580.00,761.34 1580.00,761.34 1651.00,733.63 1651.00,733.63 1651.00,733.63 1676.00,724.00 1676.00,724.00 1676.00,724.00 1681.28,741.00 1681.28,741.00 1684.15,750.65 1687.63,765.10 1689.00,775.00 1689.46,778.30 1691.59,789.17 1690.40,791.69 1689.03,794.56 1684.70,793.91 1682.00,794.17 1682.00,794.17 1657.00,796.17 1657.00,796.17 1657.00,796.17 1586.00,802.09 1586.00,802.09 1586.00,802.09 1551.00,805.00 1551.00,805.00 Z",
    attr: {
      id: "_124",
      "data-name": "124",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "124", zone: "Lower Zone", zoneId: "1" },
  };

  var _125 = {
    path:
      "M 1552.00,822.00 C 1552.00,822.00 1563.00,821.09 1563.00,821.09 1563.00,821.09 1598.00,818.00 1598.00,818.00 1598.00,818.00 1606.00,818.00 1606.00,818.00 1606.00,818.00 1635.00,815.09 1635.00,815.09 1635.00,815.09 1655.00,813.58 1655.00,813.58 1655.00,813.58 1682.00,811.28 1682.00,811.28 1684.54,810.98 1690.07,810.34 1691.94,812.57 1694.35,815.78 1692.01,831.18 1691.94,836.00 1691.94,836.00 1691.94,885.00 1691.94,885.00 1691.94,885.00 1585.00,885.00 1585.00,885.00 1585.00,885.00 1561.00,885.00 1561.00,885.00 1561.00,885.00 1553.60,883.98 1553.60,883.98 1553.60,883.98 1552.00,876.00 1552.00,876.00 1552.00,876.00 1552.00,822.00 1552.00,822.00 Z",
    attr: {
      id: "_125",
      "data-name": "125",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "125", zone: "Lower Zone", zoneId: "1" },
  };

  var _126 = {
    path:
      "M 1553.00,902.00 C 1553.00,902.00 1692.00,902.00 1692.00,902.00 1692.00,902.00 1692.00,991.00 1692.00,991.00 1692.00,991.00 1554.00,991.00 1554.00,991.00 1554.00,991.00 1553.00,958.00 1553.00,958.00 1553.00,958.00 1553.00,902.00 1553.00,902.00 Z",
    attr: {
      id: "_126",
      "data-name": "126",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "126", zone: "Lower Zone", zoneId: "1" },
  };

  var _127 = {
    path: "M 1554.00,1008.00 C 1554.00,1008.00 1692.00,1008.00 1692.00,1008.00 1692.00,1008.00 1692.00,1082.00 1692.00,1082.00 1692.00,1082.00 1554.00,1082.00 1554.00,1082.00 1554.00,1082.00 1554.00,1008.00 1554.00,1008.00 Z",
    attr: {
      id: "_127",
      "data-name": "127",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "127", zone: "Lower Zone", zoneId: "1" },
  };

  var _128 = {
    path:
      "M 1554.00,1099.00 C 1554.00,1099.00 1692.00,1099.00 1692.00,1099.00 1692.00,1099.00 1692.00,1191.00 1692.00,1191.00 1692.00,1191.00 1622.00,1184.17 1622.00,1184.17 1622.00,1184.17 1583.00,1180.17 1583.00,1180.17 1583.00,1180.17 1553.00,1177.00 1553.00,1177.00 1553.00,1177.00 1553.00,1157.00 1553.00,1157.00 1553.00,1157.00 1554.00,1141.00 1554.00,1141.00 1554.00,1141.00 1554.00,1099.00 1554.00,1099.00 Z",
    attr: {
      id: "_128",
      "data-name": "128",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "128", zone: "Lower Zone", zoneId: "1" },
  };

  var _129 = {
    path:
      "M 1553.00,1194.00 C 1553.00,1194.00 1592.00,1198.17 1592.00,1198.17 1592.00,1198.17 1654.00,1204.83 1654.00,1204.83 1654.00,1204.83 1681.00,1207.42 1681.00,1207.42 1683.26,1207.73 1688.62,1207.81 1689.96,1209.60 1691.76,1211.98 1688.84,1224.67 1688.20,1228.00 1684.18,1248.79 1679.56,1261.37 1673.00,1281.00 1673.00,1281.00 1645.00,1269.56 1645.00,1269.56 1645.00,1269.56 1601.00,1250.82 1601.00,1250.82 1601.00,1250.82 1565.00,1236.00 1565.00,1236.00 1565.00,1236.00 1566.00,1233.00 1566.00,1233.00 1562.15,1231.28 1552.91,1226.61 1551.38,1222.83 1550.14,1220.52 1551.23,1214.63 1551.38,1212.00 1552.39,1205.92 1551.32,1199.92 1553.00,1194.00 Z",
    attr: {
      id: "_129",
      "data-name": "129",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "129", zone: "Lower Zone", zoneId: "1" },
  };

  var _130 = {
    path:
      "M 1558.00,1251.00 C 1558.00,1251.00 1584.00,1261.44 1584.00,1261.44 1584.00,1261.44 1640.00,1285.23 1640.00,1285.23 1640.00,1285.23 1666.00,1296.00 1666.00,1296.00 1656.30,1320.26 1635.09,1351.38 1617.85,1371.00 1611.94,1377.71 1602.28,1390.18 1594.00,1393.00 1594.00,1393.00 1574.08,1369.96 1574.08,1369.96 1574.08,1369.96 1541.75,1331.00 1541.75,1331.00 1541.75,1331.00 1521.00,1305.00 1521.00,1305.00 1534.43,1291.27 1550.38,1268.69 1558.00,1251.00 Z",
    attr: {
      id: "_130",
      "data-name": "130",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "130", zone: "Lower Zone", zoneId: "1" },
  };

  var _131 = {
    path:
      "M 1508.00,1317.00 C 1514.86,1322.02 1532.78,1345.04 1539.41,1353.00 1539.41,1353.00 1595.25,1419.00 1595.25,1419.00 1595.25,1419.00 1618.59,1447.00 1618.59,1447.00 1622.92,1452.20 1628.53,1457.71 1631.00,1464.00 1631.00,1464.00 1564.00,1509.33 1564.00,1509.33 1564.00,1509.33 1547.00,1520.67 1547.00,1520.67 1545.10,1521.93 1541.31,1524.79 1539.04,1524.70 1535.19,1524.56 1530.77,1516.06 1528.63,1513.00 1528.63,1513.00 1501.91,1473.00 1501.91,1473.00 1501.91,1473.00 1481.72,1443.00 1481.72,1443.00 1481.72,1443.00 1460.83,1412.00 1460.83,1412.00 1458.13,1407.80 1446.94,1392.58 1446.27,1389.00 1445.64,1385.65 1448.53,1380.13 1449.87,1377.00 1449.87,1377.00 1462.65,1347.21 1462.65,1347.21 1465.05,1344.30 1476.79,1339.04 1481.00,1336.69 1489.86,1331.75 1500.68,1323.99 1508.00,1317.00 Z",
    attr: {
      id: "_131",
      "data-name": "131",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "131", zone: "Lower Zone", zoneId: "1" },
  };

  var _132 = {
    path:
      "M 1437.00,1408.00 C 1437.00,1408.00 1439.00,1408.00 1439.00,1408.00 1439.00,1408.00 1459.48,1439.00 1459.48,1439.00 1459.48,1439.00 1484.21,1476.00 1484.21,1476.00 1484.21,1476.00 1503.05,1504.00 1503.05,1504.00 1503.05,1504.00 1518.52,1527.00 1518.52,1527.00 1520.00,1529.20 1523.54,1533.08 1522.68,1535.82 1521.77,1538.71 1509.12,1546.25 1506.00,1548.33 1506.00,1548.33 1460.00,1579.00 1460.00,1579.00 1460.00,1579.00 1448.04,1561.00 1448.04,1561.00 1448.04,1561.00 1419.87,1519.00 1419.87,1519.00 1419.87,1519.00 1408.70,1502.00 1408.70,1502.00 1408.70,1502.00 1403.49,1493.00 1403.49,1493.00 1403.49,1493.00 1406.01,1484.00 1406.01,1484.00 1406.01,1484.00 1412.87,1467.00 1412.87,1467.00 1412.87,1467.00 1431.55,1422.00 1431.55,1422.00 1431.55,1422.00 1437.00,1408.00 1437.00,1408.00 Z",
    attr: {
      id: "_132",
      "data-name": "132",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "132", zone: "Lower Zone", zoneId: "1" },
  };

  var _133 = {
    path:
      "M 1394.00,1513.00 C 1400.53,1516.37 1405.42,1526.85 1409.59,1533.00 1409.59,1533.00 1435.66,1572.00 1435.66,1572.00 1439.34,1577.81 1443.49,1582.43 1446.00,1589.00 1446.00,1589.00 1378.00,1634.72 1378.00,1634.72 1378.00,1634.72 1349.00,1654.33 1349.00,1654.33 1349.00,1654.33 1332.00,1665.00 1332.00,1665.00 1332.00,1665.00 1337.26,1652.00 1337.26,1652.00 1337.26,1652.00 1352.86,1614.00 1352.86,1614.00 1352.86,1614.00 1394.00,1513.00 1394.00,1513.00 Z",
    attr: {
      id: "_133",
      "data-name": "133",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "133", zone: "Lower Zone", zoneId: "1" },
  };

  var _134 = {
    path:
      "M 1395.00,1466.00 C 1395.00,1466.00 1388.75,1432.00 1388.75,1432.00 1388.75,1432.00 1380.75,1387.00 1380.75,1387.00 1380.75,1387.00 1377.21,1368.00 1377.21,1368.00 1377.00,1365.91 1375.71,1361.53 1377.21,1359.74 1378.47,1357.79 1391.05,1357.18 1394.00,1356.98 1394.00,1356.98 1425.00,1353.84 1425.00,1353.84 1425.00,1353.84 1442.00,1352.00 1442.00,1352.00 1442.00,1352.00 1418.14,1411.00 1418.14,1411.00 1418.14,1411.00 1402.56,1449.00 1402.56,1449.00 1402.56,1449.00 1395.00,1466.00 1395.00,1466.00 Z",
    attr: {
      id: "_134",
      "data-name": "134",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "134", zone: "Lower Zone", zoneId: "1" },
  };

  var _135 = {
    path:
      "M 1245.00,1370.00 C 1245.00,1370.00 1280.00,1366.91 1280.00,1366.91 1280.00,1366.91 1323.00,1363.17 1323.00,1363.17 1323.00,1363.17 1359.00,1360.00 1359.00,1360.00 1359.00,1360.00 1363.72,1387.00 1363.72,1387.00 1363.72,1387.00 1375.80,1455.00 1375.80,1455.00 1375.80,1455.00 1380.61,1482.00 1380.61,1482.00 1380.61,1482.00 1383.16,1495.00 1383.16,1495.00 1383.16,1495.00 1377.14,1511.00 1377.14,1511.00 1377.14,1511.00 1363.34,1545.00 1363.34,1545.00 1363.34,1545.00 1352.49,1569.57 1352.49,1569.57 1352.49,1569.57 1341.00,1573.42 1341.00,1573.42 1341.00,1573.42 1312.00,1579.60 1312.00,1579.60 1312.00,1579.60 1295.00,1583.08 1295.00,1583.08 1295.00,1583.08 1288.58,1583.08 1288.58,1583.08 1288.58,1583.08 1284.45,1569.00 1284.45,1569.00 1284.45,1569.00 1276.88,1531.00 1276.88,1531.00 1276.88,1531.00 1258.40,1438.00 1258.40,1438.00 1258.40,1438.00 1250.00,1396.00 1250.00,1396.00 1250.00,1396.00 1245.00,1370.00 1245.00,1370.00 Z",
    attr: {
      id: "_135",
      "data-name": "135",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "135",
    data: { zoneNo: "135", zone: "Lower Zone", zoneId: "1" },
  };

  var _136 = {
    path:
      "M 1166.00,1375.00 C 1166.00,1375.00 1217.00,1371.91 1217.00,1371.91 1217.00,1371.91 1228.00,1371.00 1228.00,1371.00 1228.00,1371.00 1230.74,1385.00 1230.74,1385.00 1230.74,1385.00 1238.55,1424.00 1238.55,1424.00 1238.55,1424.00 1260.20,1533.00 1260.20,1533.00 1260.20,1533.00 1268.21,1573.00 1268.21,1573.00 1268.82,1575.89 1271.62,1584.98 1269.97,1587.27 1268.80,1588.88 1264.89,1589.44 1263.00,1589.88 1263.00,1589.88 1244.00,1593.80 1244.00,1593.80 1230.23,1596.60 1201.42,1603.98 1189.00,1604.00 1189.00,1604.00 1166.00,1604.00 1166.00,1604.00 1166.00,1604.00 1166.00,1375.00 1166.00,1375.00 Z",
    attr: {
      id: "_136",
      "data-name": "136",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "136", zone: "Lower Zone", zoneId: "1" },
  };

  var _137 = {
    path: "M 1045.00,1375.00 C 1045.00,1375.00 1149.00,1375.00 1149.00,1375.00 1149.00,1375.00 1149.00,1604.00 1149.00,1604.00 1149.00,1604.00 1045.00,1604.00 1045.00,1604.00 1045.00,1604.00 1045.00,1375.00 1045.00,1375.00 Z",
    attr: {
      id: "_137",
      "data-name": "137",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "137", zone: "Lower Zone", zoneId: "1" },
  };

  var _138 = {
    path: "M 933.00,1374.00 C 933.00,1374.00 1028.00,1374.00 1028.00,1374.00 1028.00,1374.00 1028.00,1604.00 1028.00,1604.00 1028.00,1604.00 933.00,1604.00 933.00,1604.00 933.00,1604.00 933.00,1374.00 933.00,1374.00 Z",
    attr: {
      id: "_138",
      "data-name": "138",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "138", zone: "Lower Zone", zoneId: "1" },
  };

  var _139 = {
    path: "M 816.00,1374.00 C 816.00,1374.00 916.00,1374.00 916.00,1374.00 916.00,1374.00 916.00,1603.00 916.00,1603.00 916.00,1603.00 816.00,1603.00 816.00,1603.00 816.00,1603.00 816.00,1374.00 816.00,1374.00 Z",
    attr: {
      id: "_139",
      "data-name": "139",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "139", zone: "Lower Zone", zoneId: "1" },
  };

  var _140 = {
    path:
      "M 695.00,1373.00 C 695.00,1373.00 774.00,1373.00 774.00,1373.00 774.00,1373.00 784.00,1373.96 784.00,1373.96 784.00,1373.96 799.00,1373.96 799.00,1373.96 799.00,1373.96 799.00,1603.00 799.00,1603.00 799.00,1603.00 696.00,1603.00 696.00,1603.00 696.00,1603.00 696.00,1489.00 696.00,1489.00 696.00,1489.00 695.00,1474.00 695.00,1474.00 695.00,1474.00 695.00,1373.00 695.00,1373.00 Z",
    attr: {
      id: "_140",
      "data-name": "140",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "140", zone: "Lower Zone", zoneId: "1" },
  };

  var _141 = {
    path:
      "M 574.00,1587.00 C 574.00,1587.00 583.00,1542.00 583.00,1542.00 583.00,1542.00 599.21,1464.00 599.21,1464.00 599.21,1464.00 606.75,1429.00 606.75,1429.00 606.75,1429.00 615.39,1388.00 615.39,1388.00 616.03,1384.58 617.20,1373.96 619.72,1371.98 622.56,1369.78 632.33,1371.80 636.00,1371.98 636.00,1371.98 652.00,1373.00 652.00,1373.00 652.00,1373.00 678.00,1373.00 678.00,1373.00 678.00,1373.00 678.00,1477.00 678.00,1477.00 678.00,1477.00 679.00,1492.00 679.00,1492.00 679.00,1492.00 679.00,1603.00 679.00,1603.00 679.00,1603.00 665.00,1603.00 665.00,1603.00 665.00,1603.00 631.00,1597.61 631.00,1597.61 631.00,1597.61 574.00,1587.00 574.00,1587.00 Z",
    attr: {
      id: "_141",
      "data-name": "141",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "141", zone: "Lower Zone", zoneId: "1" },
  };

  var _142 = {
    path:
      "M 492.00,1360.00 C 492.00,1360.00 529.00,1363.17 529.00,1363.17 529.00,1363.17 567.00,1366.17 567.00,1366.17 567.00,1366.17 591.00,1368.09 591.00,1368.09 591.00,1368.09 602.00,1369.00 602.00,1369.00 602.00,1369.00 590.66,1423.00 590.66,1423.00 590.66,1423.00 572.66,1509.00 572.66,1509.00 572.66,1509.00 563.46,1553.00 563.46,1553.00 563.46,1553.00 557.00,1584.00 557.00,1584.00 557.00,1584.00 513.00,1575.60 513.00,1575.60 513.00,1575.60 495.42,1571.72 495.42,1571.72 495.42,1571.72 489.71,1561.00 489.71,1561.00 489.71,1561.00 478.86,1534.00 478.86,1534.00 478.86,1534.00 467.81,1507.00 467.81,1507.00 467.81,1507.00 463.66,1495.00 463.66,1495.00 463.66,1495.00 466.11,1485.00 466.11,1485.00 466.11,1485.00 470.58,1464.00 470.58,1464.00 470.58,1464.00 486.60,1386.00 486.60,1386.00 486.60,1386.00 492.00,1360.00 492.00,1360.00 Z",
    attr: {
      id: "_142",
      "data-name": "142",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "142", zone: "Lower Zone", zoneId: "1" },
  };

  var _143 = {
    path:
      "M 407.00,1354.00 C 407.00,1354.00 433.00,1355.83 433.00,1355.83 433.00,1355.83 454.00,1357.17 454.00,1357.17 454.00,1357.17 475.00,1359.00 475.00,1359.00 475.00,1359.00 469.42,1386.00 469.42,1386.00 469.42,1386.00 461.42,1425.00 461.42,1425.00 461.42,1425.00 455.80,1452.00 455.80,1452.00 455.80,1452.00 452.00,1468.00 452.00,1468.00 452.00,1468.00 422.86,1396.00 422.86,1396.00 419.34,1387.48 407.96,1361.40 407.00,1354.00 Z",
    attr: {
      id: "_143",
      "data-name": "143",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "143", zone: "Lower Zone", zoneId: "1" },
  };

  var _144 = {
    path:
      "M 428.00,1455.00 C 428.00,1455.00 439.57,1481.00 439.57,1481.00 439.57,1481.00 449.14,1505.00 449.14,1505.00 449.14,1505.00 464.00,1542.00 464.00,1542.00 458.13,1539.75 451.37,1534.58 446.00,1531.00 446.00,1531.00 415.00,1510.00 415.00,1510.00 411.90,1507.93 400.34,1501.43 400.16,1497.91 400.03,1495.42 405.98,1487.27 407.66,1485.00 407.66,1485.00 428.00,1455.00 428.00,1455.00 Z",
    attr: {
      id: "_144",
      "data-name": "144",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "144", zone: "Lower Zone", zoneId: "1" },
  };

  var _145 = {
    path:
      "M 394.00,1372.00 C 398.52,1376.92 402.23,1388.48 404.68,1395.00 404.68,1395.00 415.34,1421.00 415.34,1421.00 416.66,1424.68 421.06,1433.66 420.39,1437.00 419.80,1439.91 416.54,1443.46 414.82,1446.00 414.82,1446.00 401.20,1466.00 401.20,1466.00 401.20,1466.00 390.07,1482.00 390.07,1482.00 388.63,1484.13 386.83,1487.65 383.96,1487.90 381.03,1488.14 372.64,1481.74 370.00,1479.86 362.06,1474.20 340.03,1460.94 335.00,1455.00 335.00,1455.00 352.82,1430.00 352.82,1430.00 352.82,1430.00 359.62,1421.00 359.62,1421.00 359.62,1421.00 377.91,1395.00 377.91,1395.00 377.91,1395.00 394.00,1372.00 394.00,1372.00 Z",
    attr: {
      id: "_145",
      "data-name": "145",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "145", zone: "Lower Zone", zoneId: "1" },
  };

  var _146 = {
    path:
      "M 340.00,1314.00 C 340.00,1314.00 365.00,1333.28 365.00,1333.28 365.00,1333.28 384.32,1346.39 384.32,1346.39 384.32,1346.39 388.00,1353.00 388.00,1353.00 388.00,1353.00 372.23,1375.00 372.23,1375.00 372.23,1375.00 338.69,1422.00 338.69,1422.00 338.69,1422.00 326.71,1439.00 326.71,1439.00 325.31,1441.10 323.70,1444.62 320.91,1444.99 317.58,1445.43 308.11,1437.77 305.00,1435.67 305.00,1435.67 263.00,1407.00 263.00,1407.00 265.87,1400.81 279.14,1386.13 284.25,1380.00 284.25,1380.00 300.13,1361.01 300.13,1361.01 312.50,1346.97 329.51,1329.12 340.00,1314.00 Z",
    attr: {
      id: "_146",
      "data-name": "146",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "146", zone: "Lower Zone", zoneId: "1" },
  };

  var _147 = {
    path:
      "M 170.00,1279.00 C 170.00,1279.00 210.00,1265.69 210.00,1265.69 210.00,1265.69 254.00,1251.00 254.00,1251.00 254.00,1251.00 276.00,1243.67 276.00,1243.67 276.00,1243.67 285.61,1241.67 285.61,1241.67 285.61,1241.67 289.76,1247.00 289.76,1247.00 289.76,1247.00 296.60,1259.00 296.60,1259.00 296.60,1259.00 314.45,1285.00 314.45,1285.00 314.45,1285.00 329.00,1303.00 329.00,1303.00 329.00,1303.00 300.57,1337.00 300.57,1337.00 300.57,1337.00 277.75,1363.00 277.75,1363.00 277.75,1363.00 249.00,1396.00 249.00,1396.00 221.81,1373.70 200.53,1347.47 184.75,1316.00 180.18,1306.87 172.20,1288.66 170.00,1279.00 Z",
    attr: {
      id: "_147",
      "data-name": "147",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "147", zone: "Lower Zone", zoneId: "1" },
  };

  var _224 = {
    path:
      "M 1695.00,733.00 C 1695.00,733.00 1729.00,723.71 1729.00,723.71 1729.00,723.71 1787.00,707.29 1787.00,707.29 1787.00,707.29 1822.00,698.00 1822.00,698.00 1822.00,698.00 1825.39,716.00 1825.39,716.00 1825.39,716.00 1831.80,750.00 1831.80,750.00 1831.80,750.00 1836.00,777.00 1836.00,777.00 1836.00,777.00 1836.00,820.00 1836.00,820.00 1836.00,820.00 1708.00,820.00 1708.00,820.00 1708.00,820.00 1707.00,805.00 1707.00,805.00 1706.94,792.59 1704.25,775.31 1701.80,763.00 1701.80,763.00 1695.00,733.00 1695.00,733.00 Z",
    attr: {
      id: "_224",
      "data-name": "224",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "224", zone: "Middle Zone", zoneId: "2" },
  };

  var _225 = {
    path: "M 1708.00,837.00 C 1708.00,837.00 1836.00,837.00 1836.00,837.00 1836.00,837.00 1836.00,943.00 1836.00,943.00 1836.00,943.00 1708.00,943.00 1708.00,943.00 1708.00,943.00 1708.00,837.00 1708.00,837.00 Z",
    attr: {
      id: "_225",
      "data-name": "225",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "225", zone: "Middle Zone", zoneId: "2" },
  };

  var _226 = {
    path: "M 1708.00,960.00 C 1708.00,960.00 1836.00,960.00 1836.00,960.00 1836.00,960.00 1836.00,1041.00 1836.00,1041.00 1836.00,1041.00 1708.00,1041.00 1708.00,1041.00 1708.00,1041.00 1708.00,960.00 1708.00,960.00 Z",
    attr: {
      id: "_226",
      "data-name": "226",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "226", zone: "Middle Zone", zoneId: "2" },
  };

  var _227 = {
    path: "M 1708.00,1058.00 C 1708.00,1058.00 1836.00,1058.00 1836.00,1058.00 1836.00,1058.00 1836.00,1163.00 1836.00,1163.00 1836.00,1163.00 1708.00,1163.00 1708.00,1163.00 1708.00,1163.00 1708.00,1058.00 1708.00,1058.00 Z",
    attr: {
      id: "_227",
      "data-name": "227",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "227", zone: "Middle Zone", zoneId: "2" },
  };

  var _228 = {
    path:
      "M 1708.00,1180.00 C 1708.00,1180.00 1836.00,1180.00 1836.00,1180.00 1836.00,1180.00 1836.00,1227.00 1836.00,1227.00 1836.00,1227.00 1830.40,1261.00 1830.40,1261.00 1830.40,1261.00 1821.00,1307.00 1821.00,1307.00 1821.00,1307.00 1792.00,1297.34 1792.00,1297.34 1792.00,1297.34 1753.00,1284.67 1753.00,1284.67 1753.00,1284.67 1713.00,1272.88 1713.00,1272.88 1713.00,1272.88 1693.00,1268.00 1693.00,1268.00 1698.92,1249.90 1706.91,1216.71 1707.00,1198.00 1707.00,1198.00 1708.00,1180.00 1708.00,1180.00 Z",
    attr: {
      id: "_228",
      "data-name": "228",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "228", zone: "Middle Zone", zoneId: "2" },
  };

  var _235 = {
    path:
      "M 1291.00,1600.00 C 1291.00,1600.00 1345.00,1589.00 1345.00,1589.00 1345.00,1589.00 1336.56,1610.00 1336.56,1610.00 1336.56,1610.00 1323.86,1641.00 1323.86,1641.00 1323.86,1641.00 1307.00,1681.00 1307.00,1681.00 1307.00,1681.00 1295.20,1622.00 1295.20,1622.00 1295.20,1622.00 1291.00,1600.00 1291.00,1600.00 Z",
    attr: {
      id: "_235",
      "data-name": "235",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "235", zone: "Middle Zone", zoneId: "2" },
  };

  var _236 = {
    path:
      "M 1192.00,1620.00 C 1192.00,1620.00 1222.00,1614.65 1222.00,1614.65 1222.00,1614.65 1256.00,1607.58 1256.00,1607.58 1256.00,1607.58 1275.00,1604.00 1275.00,1604.00 1275.00,1604.00 1282.00,1641.00 1282.00,1641.00 1282.00,1641.00 1292.00,1691.00 1292.00,1691.00 1292.00,1691.00 1211.00,1704.00 1211.00,1704.00 1211.00,1704.00 1192.00,1704.00 1192.00,1704.00 1192.00,1704.00 1192.00,1620.00 1192.00,1620.00 Z",
    attr: {
      id: "_236",
      "data-name": "236",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "236", zone: "Middle Zone", zoneId: "2" },
  };

  var _237 = {
    path: "M 1113.00,1620.00 C 1113.00,1620.00 1175.00,1620.00 1175.00,1620.00 1175.00,1620.00 1175.00,1704.00 1175.00,1704.00 1175.00,1704.00 1113.00,1704.00 1113.00,1704.00 1113.00,1704.00 1113.00,1620.00 1113.00,1620.00 Z",
    attr: {
      id: "_237",
      "data-name": "237",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "237", zone: "Middle Zone", zoneId: "2" },
  };

  var _238 = {
    path: "M 997.00,1650.00 C 997.00,1650.00 1096.00,1650.00 1096.00,1650.00 1096.00,1650.00 1096.00,1704.00 1096.00,1704.00 1096.00,1704.00 997.00,1704.00 997.00,1704.00 997.00,1704.00 997.00,1650.00 997.00,1650.00 Z",
    attr: {
      id: "_238",
      "data-name": "238",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "238", zone: "Middle Zone", zoneId: "2" },
  };

  var _239 = {
    path: "M 865.00,1650.00 C 865.00,1650.00 980.00,1650.00 980.00,1650.00 980.00,1650.00 980.00,1704.00 980.00,1704.00 980.00,1704.00 865.00,1704.00 865.00,1704.00 865.00,1704.00 865.00,1650.00 865.00,1650.00 Z",
    attr: {
      id: "_239",
      "data-name": "239",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "239", zone: "Middle Zone", zoneId: "2" },
  };

  var _240 = {
    path: "M 749.00,1650.00 C 749.00,1650.00 848.00,1650.00 848.00,1650.00 848.00,1650.00 848.00,1704.00 848.00,1704.00 848.00,1704.00 749.00,1704.00 749.00,1704.00 749.00,1704.00 749.00,1650.00 749.00,1650.00 Z",
    attr: {
      id: "_240",
      "data-name": "240",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "240", zone: "Middle Zone", zoneId: "2" },
  };

  var _241 = {
    path: "M 671.00,1619.00 C 671.00,1619.00 732.00,1619.00 732.00,1619.00 732.00,1619.00 732.00,1704.00 732.00,1704.00 732.00,1704.00 671.00,1704.00 671.00,1704.00 671.00,1704.00 671.00,1619.00 671.00,1619.00 Z",
    attr: {
      id: "_241",
      "data-name": "241",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "241", zone: "Middle Zone", zoneId: "2" },
  };

  var _242 = {
    path:
      "M 554.00,1687.00 C 554.00,1687.00 559.26,1661.00 559.26,1661.00 559.26,1661.00 566.35,1625.00 566.35,1625.00 566.35,1625.00 569.21,1610.00 569.21,1610.00 569.21,1610.00 571.72,1603.98 571.72,1603.98 571.72,1603.98 578.00,1603.98 578.00,1603.98 578.00,1603.98 593.00,1606.61 593.00,1606.61 593.00,1606.61 654.00,1618.00 654.00,1618.00 654.00,1618.00 654.00,1704.00 654.00,1704.00 654.00,1704.00 639.00,1704.00 639.00,1704.00 627.84,1703.98 606.06,1698.21 594.00,1695.80 594.00,1695.80 554.00,1687.00 554.00,1687.00 Z",
    attr: {
      id: "_242",
      "data-name": "242",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "242", zone: "Middle Zone", zoneId: "2" },
  };

  var _243 = {
    path:
      "M 427.00,1661.00 C 428.41,1654.08 434.99,1646.01 439.00,1640.00 439.00,1640.00 463.33,1603.00 463.33,1603.00 465.89,1599.16 472.92,1586.16 477.09,1585.48 477.09,1585.48 500.00,1589.39 500.00,1589.39 500.00,1589.39 554.00,1599.00 554.00,1599.00 554.00,1599.00 551.46,1613.00 551.46,1613.00 551.46,1613.00 541.74,1664.00 541.74,1664.00 541.74,1664.00 538.00,1684.00 538.00,1684.00 538.00,1684.00 461.00,1667.79 461.00,1667.79 461.00,1667.79 427.00,1661.00 427.00,1661.00 Z",
    attr: {
      id: "_243",
      "data-name": "243",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "243", zone: "Middle Zone", zoneId: "2" },
  };

  var _308 = {
    path:
      "M 479.00,418.00 C 479.00,418.00 437.14,359.00 437.14,359.00 437.14,359.00 427.16,345.00 427.16,345.00 427.16,345.00 425.15,338.58 425.15,338.58 425.15,338.58 430.00,336.35 430.00,336.35 430.00,336.35 444.00,333.21 444.00,333.21 444.00,333.21 499.00,322.00 499.00,322.00 499.00,322.00 505.00,354.00 505.00,354.00 505.00,354.00 516.00,410.00 516.00,410.00 516.00,410.00 479.00,418.00 479.00,418.00 Z",
    attr: {
      id: "_308",
      "data-name": "308",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "308", zone: "Middle Zone", zoneId: "2" },
  };

  var _309 = {
    path:
      "M 515.00,319.00 C 515.00,319.00 554.00,310.80 554.00,310.80 554.00,310.80 600.00,302.00 600.00,302.00 600.00,302.00 611.42,366.00 611.42,366.00 611.42,366.00 615.00,390.00 615.00,390.00 615.00,390.00 555.00,402.21 555.00,402.21 555.00,402.21 532.00,406.00 532.00,406.00 532.00,406.00 524.61,367.00 524.61,367.00 524.61,367.00 515.00,319.00 515.00,319.00 Z",
    attr: {
      id: "_309",
      "data-name": "309",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "309", zone: "Middle Zone", zoneId: "2" },
  };

  var _310 = {
    path:
      "M 616.00,299.00 C 634.92,292.75 667.27,295.00 688.00,295.00 688.00,295.00 688.00,381.00 688.00,381.00 688.00,381.00 661.00,381.00 661.00,381.00 661.00,381.00 632.00,386.00 632.00,386.00 632.00,386.00 626.25,354.00 626.25,354.00 626.25,354.00 616.00,299.00 616.00,299.00 Z",
    attr: {
      id: "_310",
      "data-name": "310",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "310", zone: "Middle Zone", zoneId: "2" },
  };

  var _311 = {
    path: "M 705.00,295.00 C 705.00,295.00 743.00,295.00 743.00,295.00 743.00,295.00 743.00,381.00 743.00,381.00 743.00,381.00 705.00,381.00 705.00,381.00 705.00,381.00 705.00,295.00 705.00,295.00 Z",
    attr: {
      id: "_311",
      "data-name": "311",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "311", zone: "Middle Zone", zoneId: "2" },
  };

  var _312 = {
    path: "M 760.00,346.00 C 760.00,346.00 802.00,346.00 802.00,346.00 802.00,346.00 802.00,381.00 802.00,381.00 802.00,381.00 760.00,381.00 760.00,381.00 760.00,381.00 760.00,346.00 760.00,346.00 Z",
    attr: {
      id: "_312",
      "data-name": "312",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "312", zone: "Middle Zone", zoneId: "2" },
  };

  var _313 = {
    path:
      "M 819.00,346.00 C 819.00,346.00 847.00,345.00 847.00,345.00 847.00,345.00 861.00,345.00 861.00,345.00 861.00,345.00 860.00,381.00 860.00,381.00 860.00,381.00 819.00,381.00 819.00,381.00 819.00,381.00 819.00,346.00 819.00,346.00 Z",
    attr: {
      id: "_313",
      "data-name": "313",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "313", zone: "Middle Zone", zoneId: "2" },
  };

  var _313A = {
    path: "M 760.00,295.00 C 760.00,295.00 814.00,295.00 814.00,295.00 814.00,295.00 814.00,329.00 814.00,329.00 814.00,329.00 760.00,329.00 760.00,329.00 760.00,329.00 760.00,295.00 760.00,295.00 Z",
    attr: {
      id: "_313A",
      "data-name": "313A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "313", zone: "Middle Zone A", zoneId: "2" },
  };

  var _314 = {
    path: "M 876.00,345.00 C 876.00,345.00 913.00,345.00 913.00,345.00 913.00,345.00 914.00,381.00 914.00,381.00 914.00,381.00 877.00,381.00 877.00,381.00 877.00,381.00 876.00,345.00 876.00,345.00 Z",
    attr: {
      id: "_314",
      "data-name": "314",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "314", zone: "Middle Zone", zoneId: "2" },
  };

  var _314A = {
    path:
      "M 831.00,295.00 C 831.00,295.00 914.00,295.00 914.00,295.00 914.00,295.00 914.00,328.00 914.00,328.00 914.00,328.00 849.00,328.00 849.00,328.00 849.00,328.00 831.00,329.00 831.00,329.00 831.00,329.00 831.00,295.00 831.00,295.00 Z",
    attr: {
      id: "_314A",
      "data-name": "314A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "314", zone: "Middle Zone A", zoneId: "2" },
  };

  var _315 = {
    path: "M 930.00,345.00 C 930.00,345.00 973.00,345.00 973.00,345.00 973.00,345.00 973.00,381.00 973.00,381.00 973.00,381.00 929.00,381.00 929.00,381.00 929.00,381.00 930.00,345.00 930.00,345.00 Z",
    attr: {
      id: "_315",
      "data-name": "315",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "315", zone: "Middle Zone", zoneId: "2" },
  };

  var _315A = {
    path:
      "M 931.00,295.00 C 931.00,295.00 1015.00,295.00 1015.00,295.00 1015.00,295.00 1015.00,327.00 1015.00,327.00 1015.00,327.00 998.00,328.00 998.00,328.00 998.00,328.00 931.00,328.00 931.00,328.00 931.00,328.00 931.00,295.00 931.00,295.00 Z",
    attr: {
      id: "_315A",
      "data-name": "315A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "315", zone: "Middle Zone A", zoneId: "2" },
  };

  var _316 = {
    path:
      "M 990.00,344.96 C 990.00,344.96 1003.00,344.96 1003.00,344.96 1003.00,344.96 1014.00,344.00 1014.00,344.00 1014.00,344.00 1030.00,344.00 1030.00,344.00 1030.00,344.00 1030.00,381.00 1030.00,381.00 1030.00,381.00 990.00,381.00 990.00,381.00 990.00,381.00 990.00,344.96 990.00,344.96 Z",
    attr: {
      id: "_316",
      "data-name": "316",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "316", zone: "Middle Zone", zoneId: "2" },
  };

  var _316A = {
    path:
      "M 1032.00,295.00 C 1032.00,295.00 1073.00,295.00 1073.00,295.00 1080.48,295.05 1079.67,296.23 1086.00,299.00 1086.00,299.00 1086.00,327.00 1086.00,327.00 1086.00,327.00 1032.00,327.00 1032.00,327.00 1032.00,327.00 1032.00,295.00 1032.00,295.00 Z",
    attr: {
      id: "_316A",
      "data-name": "316A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "316", zone: "Middle Zone A", zoneId: "2" },
  };

  var _317 = {
    path: "M 1047.00,344.00 C 1047.00,344.00 1086.00,344.00 1086.00,344.00 1086.00,344.00 1086.00,381.00 1086.00,381.00 1086.00,381.00 1047.00,381.00 1047.00,381.00 1047.00,381.00 1047.00,344.00 1047.00,344.00 Z",
    attr: {
      id: "_317",
      "data-name": "317",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "317", zone: "Middle Zone", zoneId: "2" },
  };

  var _318 = {
    path: "M 1103.00,295.00 C 1103.00,295.00 1140.00,295.00 1140.00,295.00 1140.00,295.00 1140.00,381.00 1140.00,381.00 1140.00,381.00 1103.00,381.00 1103.00,381.00 1103.00,381.00 1103.00,295.00 1103.00,295.00 Z",
    attr: {
      id: "_318",
      "data-name": "318",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "318", zone: "Middle Zone", zoneId: "2" },
  };

  var _319 = {
    path:
      "M 1157.00,295.00 C 1157.00,295.00 1215.00,295.00 1215.00,295.00 1215.00,295.00 1238.00,298.00 1238.00,298.00 1238.00,298.00 1226.46,358.00 1226.46,358.00 1226.46,358.00 1221.00,386.00 1221.00,386.00 1221.00,386.00 1191.00,381.00 1191.00,381.00 1191.00,381.00 1157.00,381.00 1157.00,381.00 1157.00,381.00 1157.00,295.00 1157.00,295.00 Z",
    attr: {
      id: "_319",
      "data-name": "319",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "319", zone: "Middle Zone", zoneId: "2" },
  };

  var _320 = {
    path:
      "M 1254.00,302.00 C 1254.00,302.00 1289.00,307.58 1289.00,307.58 1289.00,307.58 1302.49,310.93 1302.49,310.93 1302.49,310.93 1306.86,319.00 1306.86,319.00 1306.86,319.00 1314.74,339.00 1314.74,339.00 1314.74,339.00 1334.81,388.00 1334.81,388.00 1334.81,388.00 1343.00,410.00 1343.00,410.00 1343.00,410.00 1315.00,404.80 1315.00,404.80 1315.00,404.80 1263.00,394.42 1263.00,394.42 1263.00,394.42 1245.00,390.88 1245.00,390.88 1245.00,390.88 1238.69,387.69 1238.69,387.69 1238.69,387.69 1239.39,380.00 1239.39,380.00 1239.39,380.00 1242.58,364.00 1242.58,364.00 1242.58,364.00 1254.00,302.00 1254.00,302.00 Z",
    attr: {
      id: "_320",
      "data-name": "320",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "320", zone: "Middle Zone", zoneId: "2" },
  };

  var _510 = {
    path:
      "M 407.00,226.00 C 407.00,226.00 478.00,212.00 478.00,212.00 478.00,212.00 486.00,249.00 486.00,249.00 486.00,249.00 446.00,257.00 446.00,257.00 442.65,257.67 433.09,260.32 430.32,258.89 428.24,257.81 424.66,252.14 423.14,250.00 423.14,250.00 407.00,226.00 407.00,226.00 Z",
    attr: {
      id: "_510",
      "data-name": "510",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "510", zone: "Upper Zone", zoneId: "3" },
  };

  var _511 = {
    path:
      "M 494.00,208.00 C 494.00,208.00 539.00,198.66 539.00,198.66 539.00,198.66 556.00,195.35 556.00,195.35 556.00,195.35 572.00,191.92 572.00,191.92 572.00,191.92 578.42,191.92 578.42,191.92 578.42,191.92 581.79,202.00 581.79,202.00 581.79,202.00 588.00,230.00 588.00,230.00 588.00,230.00 502.00,246.00 502.00,246.00 502.00,246.00 494.00,208.00 494.00,208.00 Z",
    attr: {
      id: "_511",
      "data-name": "511",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "511", zone: "Upper Zone", zoneId: "3" },
  };

  var _512 = {
    path:
      "M 596.00,187.00 C 609.06,184.37 627.23,179.02 640.00,179.00 640.00,179.00 678.00,179.00 678.00,179.00 678.00,179.00 678.00,219.00 678.00,219.00 678.00,219.00 649.00,219.00 649.00,219.00 649.00,219.00 604.00,226.00 604.00,226.00 604.00,226.00 596.00,187.00 596.00,187.00 Z",
    attr: {
      id: "_512",
      "data-name": "512",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "512", zone: "Upper Zone", zoneId: "3" },
  };

  var _513 = {
    path: "M 695.00,179.00 C 695.00,179.00 810.00,179.00 810.00,179.00 810.00,179.00 810.00,219.00 810.00,219.00 810.00,219.00 695.00,219.00 695.00,219.00 695.00,219.00 695.00,179.00 695.00,179.00 Z",
    attr: {
      id: "_513",
      "data-name": "513",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "513", zone: "Upper Zone", zoneId: "3" },
  };

  var _514 = {
    path:
      "M 827.00,179.00 C 827.00,179.00 914.00,179.00 914.00,179.00 914.00,179.00 914.00,210.00 914.00,210.00 914.00,210.00 912.98,217.40 912.98,217.40 912.98,217.40 905.00,219.00 905.00,219.00 905.00,219.00 827.00,219.00 827.00,219.00 827.00,219.00 827.00,179.00 827.00,179.00 Z",
    attr: {
      id: "_514",
      "data-name": "514",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "514", zone: "Upper Zone", zoneId: "3" },
  };

  var _515 = {
    path:
      "M 931.00,179.00 C 931.00,179.00 1011.00,179.00 1011.00,179.00 1011.00,179.00 1011.00,218.00 1011.00,218.00 1011.00,218.00 992.00,218.00 992.00,218.00 992.00,218.00 976.00,219.00 976.00,219.00 976.00,219.00 931.00,219.00 931.00,219.00 931.00,219.00 931.00,179.00 931.00,179.00 Z",
    attr: {
      id: "_515",
      "data-name": "515",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "515", zone: "Upper Zone", zoneId: "3" },
  };

  var _516 = {
    path: "M 1028.00,179.00 C 1028.00,179.00 1146.00,179.00 1146.00,179.00 1146.00,179.00 1146.00,218.00 1146.00,218.00 1146.00,218.00 1028.00,218.00 1028.00,218.00 1028.00,218.00 1028.00,179.00 1028.00,179.00 Z",
    attr: {
      id: "_516",
      "data-name": "516",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "516", zone: "Upper Zone", zoneId: "3" },
  };

  var _517 = {
    path:
      "M 1163.00,179.00 C 1163.00,179.00 1219.00,179.00 1219.00,179.00 1219.00,179.00 1244.00,183.00 1244.00,183.00 1244.00,183.00 1236.00,223.00 1236.00,223.00 1236.00,223.00 1206.00,218.00 1206.00,218.00 1206.00,218.00 1163.00,218.00 1163.00,218.00 1163.00,218.00 1163.00,179.00 1163.00,179.00 Z",
    attr: {
      id: "_517",
      "data-name": "517",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "517", zone: "Upper Zone", zoneId: "3" },
  };

  var _518 = {
    path:
      "M 1260.00,187.00 C 1260.00,187.00 1295.00,193.39 1295.00,193.39 1295.00,193.39 1371.00,209.00 1371.00,209.00 1371.00,209.00 1361.00,249.00 1361.00,249.00 1361.00,249.00 1337.00,244.00 1337.00,244.00 1337.00,244.00 1279.00,232.00 1279.00,232.00 1279.00,232.00 1253.00,227.00 1253.00,227.00 1253.00,227.00 1260.00,187.00 1260.00,187.00 Z",
    attr: {
      id: "_518",
      "data-name": "518",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "518", zone: "Upper Zone", zoneId: "3" },
  };

  var _519 = {
    path:
      "M 1387.00,212.00 C 1387.00,212.00 1424.00,219.45 1424.00,219.45 1424.00,219.45 1447.00,224.00 1447.00,224.00 1447.00,224.00 1429.21,250.00 1429.21,250.00 1429.21,250.00 1420.70,260.34 1420.70,260.34 1420.70,260.34 1407.00,258.42 1407.00,258.42 1407.00,258.42 1378.00,253.00 1378.00,253.00 1378.00,253.00 1387.00,212.00 1387.00,212.00 Z",
    attr: {
      id: "_519",
      "data-name": "519",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "519", zone: "Upper Zone", zoneId: "3" },
  };

  var _520 = {
    path: "M 1347.00,264.00 C 1347.00,264.00 1410.00,276.00 1410.00,276.00 1410.00,276.00 1396.00,298.00 1396.00,298.00 1388.92,295.75 1368.50,281.00 1361.00,276.00 1355.84,272.56 1349.83,269.70 1347.00,264.00 Z",
    attr: {
      id: "_520",
      "data-name": "520",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "520", zone: "Upper Zone", zoneId: "3" },
  };

  var _521 = {
    path:
      "M 1462.00,231.00 C 1462.00,231.00 1487.00,247.28 1487.00,247.28 1487.00,247.28 1539.00,282.00 1539.00,282.00 1539.00,282.00 1488.00,359.00 1488.00,359.00 1481.11,357.31 1468.47,347.65 1462.00,343.33 1462.00,343.33 1409.00,308.00 1409.00,308.00 1409.00,308.00 1423.48,287.00 1423.48,287.00 1423.48,287.00 1462.00,231.00 1462.00,231.00 Z",
    attr: {
      id: "_521",
      "data-name": "521",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "521", zone: "Upper Zone", zoneId: "3" },
  };

  var _522 = {
    path:
      "M 1552.00,292.00 C 1552.00,292.00 1609.00,329.67 1609.00,329.67 1609.00,329.67 1621.99,338.66 1621.99,338.66 1623.79,339.94 1626.65,341.59 1626.68,344.09 1626.71,346.74 1620.89,353.65 1619.13,356.00 1619.13,356.00 1596.42,386.00 1596.42,386.00 1596.42,386.00 1579.87,408.00 1579.87,408.00 1578.38,409.99 1575.16,414.90 1572.83,415.56 1569.59,416.48 1560.90,409.60 1558.00,407.67 1558.00,407.67 1517.00,380.00 1517.00,380.00 1513.93,377.95 1502.34,371.47 1502.33,367.91 1502.33,366.00 1504.87,362.67 1505.93,361.00 1505.93,361.00 1516.63,345.00 1516.63,345.00 1516.63,345.00 1528.59,327.00 1528.59,327.00 1528.59,327.00 1552.00,292.00 1552.00,292.00 Z",
    attr: {
      id: "_522",
      "data-name": "522",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "522", zone: "Upper Zone", zoneId: "3" },
  };

  var _523 = {
    path:
      "M 1622.00,375.00 C 1627.96,376.21 1630.95,379.05 1636.00,382.19 1636.00,382.19 1660.00,397.66 1660.00,397.66 1662.93,399.57 1670.52,402.98 1669.75,407.04 1669.27,409.60 1660.91,419.36 1658.80,422.00 1658.80,422.00 1632.00,456.00 1632.00,456.00 1632.00,456.00 1585.00,426.00 1585.00,426.00 1585.00,426.00 1598.79,406.00 1598.79,406.00 1598.79,406.00 1622.00,375.00 1622.00,375.00 Z",
    attr: {
      id: "_523",
      "data-name": "523",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "523", zone: "Upper Zone", zoneId: "3" },
  };

  var _530 = {
    path:
      "M 1591.00,1586.00 C 1591.00,1586.00 1617.00,1569.00 1617.00,1569.00 1619.43,1567.38 1626.49,1562.22 1629.00,1562.01 1633.14,1561.66 1637.24,1568.09 1639.61,1571.00 1639.61,1571.00 1662.58,1599.00 1662.58,1599.00 1664.53,1601.38 1670.59,1607.72 1669.67,1610.82 1669.05,1612.89 1665.74,1614.71 1664.00,1615.81 1664.00,1615.81 1651.00,1624.31 1651.00,1624.31 1651.00,1624.31 1635.00,1634.66 1635.00,1634.66 1633.01,1635.95 1629.56,1638.40 1627.09,1638.01 1624.22,1637.56 1618.85,1628.68 1616.92,1626.00 1616.92,1626.00 1598.58,1599.00 1598.58,1599.00 1595.48,1594.26 1592.51,1591.55 1591.00,1586.00 Z",
    attr: {
      id: "_530",
      "data-name": "530",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "530", zone: "Upper Zone", zoneId: "3" },
  };

  var _531 = {
    path:
      "M 1576.00,1596.00 C 1576.00,1596.00 1601.44,1632.00 1601.44,1632.00 1601.44,1632.00 1628.00,1672.00 1628.00,1672.00 1628.00,1672.00 1555.00,1720.00 1555.00,1720.00 1555.00,1720.00 1517.83,1664.00 1517.83,1664.00 1517.83,1664.00 1509.09,1651.00 1509.09,1651.00 1509.09,1651.00 1506.32,1644.18 1506.32,1644.18 1506.32,1644.18 1512.00,1639.00 1512.00,1639.00 1512.00,1639.00 1525.00,1630.33 1525.00,1630.33 1525.00,1630.33 1557.00,1609.00 1557.00,1609.00 1557.00,1609.00 1576.00,1596.00 1576.00,1596.00 Z",
    attr: {
      id: "_531",
      "data-name": "531",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "531", zone: "Upper Zone", zoneId: "3" },
  };

  var _532 = {
    path:
      "M 1459.00,1784.00 C 1459.00,1784.00 1423.42,1729.00 1423.42,1729.00 1423.42,1729.00 1414.35,1715.00 1414.35,1715.00 1413.11,1713.16 1410.93,1710.44 1411.16,1708.09 1411.54,1704.41 1422.77,1698.16 1426.00,1696.00 1426.00,1696.00 1472.00,1665.33 1472.00,1665.33 1475.55,1662.96 1487.08,1653.88 1490.91,1655.24 1493.96,1656.34 1502.72,1670.54 1504.91,1674.00 1504.91,1674.00 1527.09,1707.00 1527.09,1707.00 1531.38,1713.02 1539.08,1723.27 1541.00,1730.00 1541.00,1730.00 1487.00,1766.34 1487.00,1766.34 1479.94,1771.15 1466.47,1781.62 1459.00,1784.00 Z",
    attr: {
      id: "_532",
      "data-name": "532",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "532", zone: "Upper Zone", zoneId: "3" },
  };

  var _533 = {
    path: "M 1346.00,1757.00 C 1346.03,1748.16 1345.92,1749.39 1358.00,1741.33 1365.69,1736.21 1389.23,1719.59 1396.00,1717.00 1396.00,1717.00 1414.00,1744.00 1414.00,1744.00 1414.00,1744.00 1346.00,1757.00 1346.00,1757.00 Z",
    attr: {
      id: "_533",
      "data-name": "533",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "533", zone: "Upper Zone", zoneId: "3" },
  };

  var _534 = {
    path:
      "M 1379.75,1769.00 C 1379.75,1769.00 1419.00,1759.06 1419.00,1759.06 1424.98,1758.40 1426.00,1762.67 1428.87,1767.00 1434.35,1775.26 1441.67,1785.34 1446.00,1794.00 1437.76,1799.83 1424.91,1801.60 1415.00,1803.60 1415.00,1803.60 1387.50,1809.25 1387.50,1809.25 1387.50,1809.25 1379.75,1769.00 1379.75,1769.00 Z",
    attr: {
      id: "_534",
      "data-name": "534",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "534", zone: "Upper Zone", zoneId: "3" },
  };

  var _535 = {
    path:
      "M 1255.00,1792.00 C 1255.00,1792.00 1283.00,1786.80 1283.00,1786.80 1283.00,1786.80 1338.00,1775.60 1338.00,1775.60 1338.00,1775.60 1360.00,1771.00 1360.00,1771.00 1362.11,1770.58 1365.25,1770.50 1366.75,1770.00 1367.75,1771.75 1368.25,1776.51 1368.75,1779.00 1368.75,1779.00 1374.00,1803.00 1374.00,1803.00 1374.63,1805.88 1374.75,1807.25 1375.00,1811.50 1375.00,1811.50 1365.00,1814.40 1365.00,1814.40 1365.00,1814.40 1341.00,1819.40 1341.00,1819.40 1341.00,1819.40 1302.00,1827.42 1302.00,1827.42 1302.00,1827.42 1264.00,1835.00 1264.00,1835.00 1264.00,1835.00 1255.00,1792.00 1255.00,1792.00 Z M 1260.67,1737.33",
    attr: {
      id: "_535",
      "data-name": "535",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "535", zone: "Upper Zone", zoneId: "3" },
  };

  var _536 = {
    path:
      "M 1165.00,1801.00 C 1165.00,1801.00 1208.00,1801.00 1208.00,1801.00 1208.00,1801.00 1239.00,1796.00 1239.00,1796.00 1239.00,1796.00 1248.00,1838.00 1248.00,1838.00 1248.00,1838.00 1232.00,1842.00 1232.00,1842.00 1232.00,1842.00 1236.00,1844.00 1236.00,1844.00 1236.00,1844.00 1216.00,1842.00 1216.00,1842.00 1216.00,1842.00 1165.00,1842.00 1165.00,1842.00 1165.00,1842.00 1165.00,1801.00 1165.00,1801.00 Z",
    attr: {
      id: "_536",
      "data-name": "536",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "536", zone: "Upper Zone", zoneId: "3" },
  };

  var _537 = {
    path: "M 1029.00,1801.00 C 1029.00,1801.00 1148.00,1801.00 1148.00,1801.00 1148.00,1801.00 1148.00,1842.00 1148.00,1842.00 1148.00,1842.00 1029.00,1842.00 1029.00,1842.00 1029.00,1842.00 1029.00,1801.00 1029.00,1801.00 Z",
    attr: {
      id: "_537",
      "data-name": "537",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "537", zone: "Upper Zone", zoneId: "3" },
  };

  var _538 = {
    path: "M 933.00,1801.00 C 933.00,1801.00 1012.00,1801.00 1012.00,1801.00 1012.00,1801.00 1012.00,1842.00 1012.00,1842.00 1012.00,1842.00 933.00,1842.00 933.00,1842.00 933.00,1842.00 933.00,1801.00 933.00,1801.00 Z",
    attr: {
      id: "_538",
      "data-name": "538",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "538", zone: "Upper Zone", zoneId: "3" },
  };

  var _539 = {
    path: "M 832.00,1801.00 C 832.00,1801.00 916.00,1801.00 916.00,1801.00 916.00,1801.00 916.00,1842.00 916.00,1842.00 916.00,1842.00 832.00,1842.00 832.00,1842.00 832.00,1842.00 832.00,1801.00 832.00,1801.00 Z",
    attr: {
      id: "_539",
      "data-name": "539",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "539", zone: "Upper Zone", zoneId: "3" },
  };

  var _540 = {
    path: "M 696.00,1801.00 C 696.00,1801.00 815.00,1801.00 815.00,1801.00 815.00,1801.00 815.00,1842.00 815.00,1842.00 815.00,1842.00 696.00,1842.00 696.00,1842.00 696.00,1842.00 696.00,1801.00 696.00,1801.00 Z",
    attr: {
      id: "_540",
      "data-name": "540",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "540", zone: "Upper Zone", zoneId: "3" },
  };

  var _541 = {
    path:
      "M 606.00,1795.00 C 606.00,1795.00 646.00,1801.00 646.00,1801.00 646.00,1801.00 679.00,1801.00 679.00,1801.00 679.00,1801.00 679.00,1842.00 679.00,1842.00 679.00,1842.00 634.00,1842.00 634.00,1842.00 634.00,1842.00 598.00,1836.00 598.00,1836.00 598.00,1836.00 606.00,1795.00 606.00,1795.00 Z",
    attr: {
      id: "_541",
      "data-name": "541",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "541", zone: "Upper Zone", zoneId: "3" },
  };

  var _542 = {
    path:
      "M 482.00,1769.00 C 482.00,1769.00 530.00,1778.60 530.00,1778.60 530.00,1778.60 565.00,1785.80 565.00,1785.80 565.00,1785.80 590.00,1791.00 590.00,1791.00 590.00,1791.00 582.00,1833.00 582.00,1833.00 582.00,1833.00 513.00,1819.55 513.00,1819.55 513.00,1819.55 473.00,1812.00 473.00,1812.00 473.00,1812.00 482.00,1769.00 482.00,1769.00 Z",
    attr: {
      id: "_542",
      "data-name": "542",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "542", zone: "Upper Zone", zoneId: "3" },
  };

  var _543 = {
    path:
      "M 406.00,1799.00 C 406.00,1799.00 425.42,1770.00 425.42,1770.00 425.42,1770.00 434.18,1759.69 434.18,1759.69 434.18,1759.69 444.00,1761.00 444.00,1761.00 444.00,1761.00 465.00,1765.00 465.00,1765.00 465.00,1765.00 457.00,1808.00 457.00,1808.00 457.00,1808.00 406.00,1799.00 406.00,1799.00 Z",
    attr: {
      id: "_543",
      "data-name": "543",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "543", zone: "Upper Zone", zoneId: "3" },
  };

  var _610 = {
    path:
      "M 408.00,209.00 C 408.00,209.00 429.83,184.00 429.83,184.00 429.83,184.00 458.01,153.39 458.01,153.39 458.01,153.39 478.00,143.58 478.00,143.58 478.00,143.58 506.00,132.00 506.00,132.00 506.00,132.00 509.89,148.00 509.89,148.00 509.89,148.00 519.00,186.00 519.00,186.00 519.00,186.00 408.00,209.00 408.00,209.00 Z",
    attr: {
      id: "_610",
      "data-name": "610",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "610", zone: "Upper Zone", zoneId: "3" },
  };

  var _611 = {
    path:
      "M 522.00,125.00 C 522.00,125.00 570.00,107.69 570.00,107.69 573.32,106.51 585.89,101.83 588.70,102.77 592.10,103.92 592.84,113.76 593.42,117.00 593.42,117.00 600.88,155.00 600.88,155.00 601.55,157.94 603.86,165.80 601.39,168.02 599.36,169.83 585.45,171.96 582.00,172.61 582.00,172.61 535.00,182.00 535.00,182.00 535.00,182.00 524.58,137.00 524.58,137.00 524.58,137.00 522.00,125.00 522.00,125.00 Z",
    attr: {
      id: "_611",
      "data-name": "611",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "611", zone: "Upper Zone", zoneId: "3" },
  };

  var _612 = {
    path:
      "M 700.00,72.00 C 700.00,72.00 701.00,100.00 701.00,100.00 701.00,100.00 701.00,162.00 701.00,162.00 701.00,162.00 638.00,162.00 638.00,162.00 634.31,162.05 622.68,164.50 620.59,162.96 618.81,161.64 618.24,156.21 617.80,154.00 617.80,154.00 612.75,128.00 612.75,128.00 612.75,128.00 608.39,105.00 608.39,105.00 607.66,102.37 606.32,98.16 608.39,96.09 609.99,94.78 614.06,93.85 616.00,93.29 616.00,93.29 634.00,88.15 634.00,88.15 634.00,88.15 683.00,75.88 683.00,75.88 683.00,75.88 700.00,72.00 700.00,72.00 Z",
    attr: {
      id: "_612",
      "data-name": "612",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "612", zone: "Upper Zone", zoneId: "3" },
  };

  var _613 = {
    path:
      "M 718.00,162.00 C 718.00,162.00 718.00,77.00 718.00,77.00 718.00,75.03 717.74,71.31 718.99,69.73 720.81,67.45 731.78,66.13 735.00,65.59 735.00,65.59 787.00,58.00 787.00,58.00 787.00,58.00 787.00,162.00 787.00,162.00 787.00,162.00 718.00,162.00 718.00,162.00 Z",
    attr: {
      id: "_613",
      "data-name": "613",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "613", zone: "Upper Zone", zoneId: "3" },
  };

  var _614 = {
    path:
      "M 804.00,56.00 C 804.00,56.00 838.00,52.91 838.00,52.91 838.00,52.91 869.00,51.00 869.00,51.00 869.00,51.00 885.00,50.00 885.00,50.00 885.00,50.00 910.00,50.00 910.00,50.00 910.00,50.00 910.00,162.00 910.00,162.00 910.00,162.00 804.00,162.00 804.00,162.00 804.00,162.00 804.00,56.00 804.00,56.00 Z",
    attr: {
      id: "_614",
      "data-name": "614",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "614", zone: "Upper Zone", zoneId: "3" },
  };

  var _615 = {
    path:
      "M 927.00,50.00 C 927.00,50.00 957.00,50.00 957.00,50.00 957.00,50.00 973.00,51.00 973.00,51.00 973.00,51.00 990.00,51.91 990.00,51.91 990.00,51.91 1012.00,53.17 1012.00,53.17 1012.00,53.17 1032.00,55.00 1032.00,55.00 1032.00,55.00 1032.00,162.00 1032.00,162.00 1032.00,162.00 927.00,162.00 927.00,162.00 927.00,162.00 927.00,50.00 927.00,50.00 Z",
    attr: {
      id: "_615",
      "data-name": "615",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "615", zone: "Upper Zone", zoneId: "3" },
  };

  var _616 = {
    path:
      "M 1049.00,57.00 C 1049.00,57.00 1103.00,64.59 1103.00,64.59 1103.00,64.59 1119.98,68.59 1119.98,68.59 1119.98,68.59 1121.00,76.00 1121.00,76.00 1121.00,76.00 1121.00,162.00 1121.00,162.00 1121.00,162.00 1049.00,162.00 1049.00,162.00 1049.00,162.00 1049.00,57.00 1049.00,57.00 Z",
    attr: {
      id: "_616",
      "data-name": "616",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "616", zone: "Upper Zone", zoneId: "3" },
  };

  var _617 = {
    path:
      "M 1138.00,71.00 C 1158.69,73.79 1211.52,87.73 1233.00,94.00 1233.00,94.00 1222.65,145.00 1222.65,145.00 1222.65,145.00 1218.42,160.98 1218.42,160.98 1218.42,160.98 1211.00,162.00 1211.00,162.00 1211.00,162.00 1138.00,162.00 1138.00,162.00 1138.00,162.00 1138.00,71.00 1138.00,71.00 Z",
    attr: {
      id: "_617",
      "data-name": "617",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "617", zone: "Upper Zone", zoneId: "3" },
  };

  var _618 = {
    path:
      "M 1249.00,99.00 C 1249.00,99.00 1280.00,109.00 1280.00,109.00 1280.00,109.00 1338.00,131.00 1338.00,131.00 1338.00,131.00 1328.00,182.00 1328.00,182.00 1319.05,181.92 1289.39,175.17 1279.00,172.88 1279.00,172.88 1236.00,164.00 1236.00,164.00 1236.00,164.00 1244.46,122.00 1244.46,122.00 1244.46,122.00 1249.00,99.00 1249.00,99.00 Z",
    attr: {
      id: "_618",
      "data-name": "618",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "618", zone: "Upper Zone", zoneId: "3" },
  };

  var _619 = {
    path:
      "M 1354.00,138.00 C 1354.00,138.00 1378.00,148.76 1378.00,148.76 1380.41,149.95 1384.62,151.71 1385.81,154.21 1387.11,156.95 1384.05,171.22 1383.41,175.00 1382.84,178.41 1381.77,191.09 1378.69,192.31 1376.90,193.02 1371.08,191.78 1369.00,191.39 1369.00,191.39 1344.00,186.00 1344.00,186.00 1344.00,186.00 1354.00,138.00 1354.00,138.00 Z",
    attr: {
      id: "_619",
      "data-name": "619",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "619", zone: "Upper Zone", zoneId: "3" },
  };

  var _634 = {
    path:
      "M 1323.00,1839.00 C 1323.00,1839.00 1383.00,1827.00 1383.00,1827.00 1383.00,1827.00 1386.42,1851.00 1386.42,1851.00 1386.74,1853.15 1388.00,1859.87 1387.34,1861.61 1386.20,1864.63 1377.05,1868.10 1374.00,1869.42 1374.00,1869.42 1335.00,1886.00 1335.00,1886.00 1335.00,1886.00 1329.73,1864.00 1329.73,1864.00 1329.73,1864.00 1323.00,1839.00 1323.00,1839.00 Z",
    attr: {
      id: "_634",
      "data-name": "634",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "634", zone: "Upper Zone", zoneId: "3" },
  };

  var _635 = {
    path:
      "M 1241.00,1856.00 C 1241.00,1856.00 1288.00,1846.60 1288.00,1846.60 1291.13,1845.97 1303.36,1842.79 1305.58,1843.66 1307.87,1844.56 1308.53,1847.90 1309.14,1850.00 1309.14,1850.00 1313.77,1868.00 1313.77,1868.00 1314.80,1872.24 1319.63,1887.52 1318.36,1890.78 1316.88,1894.59 1305.85,1897.35 1302.00,1898.81 1302.00,1898.81 1253.00,1915.00 1253.00,1915.00 1253.00,1915.00 1241.00,1856.00 1241.00,1856.00 Z",
    attr: {
      id: "_635",
      "data-name": "635",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "635", zone: "Upper Zone", zoneId: "3" },
  };

  var _636 = {
    path:
      "M 1144.00,1858.00 C 1144.00,1858.00 1224.00,1858.00 1224.00,1858.00 1224.00,1858.00 1233.21,1902.00 1233.21,1902.00 1233.21,1902.00 1237.00,1920.00 1237.00,1920.00 1237.00,1920.00 1215.00,1926.58 1215.00,1926.58 1200.08,1930.83 1157.78,1942.06 1144.00,1944.00 1144.00,1944.00 1144.00,1858.00 1144.00,1858.00 Z",
    attr: {
      id: "_636",
      "data-name": "636",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "636", zone: "Upper Zone", zoneId: "3" },
  };

  var _637 = {
    path:
      "M 1054.00,1858.00 C 1054.00,1858.00 1127.00,1858.00 1127.00,1858.00 1127.00,1858.00 1127.00,1948.00 1127.00,1948.00 1127.00,1948.00 1091.00,1953.73 1091.00,1953.73 1091.00,1953.73 1054.00,1959.00 1054.00,1959.00 1054.00,1959.00 1054.00,1858.00 1054.00,1858.00 Z",
    attr: {
      id: "_637",
      "data-name": "637",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "637", zone: "Upper Zone", zoneId: "3" },
  };

  var _638 = {
    path:
      "M 932.00,1858.00 C 932.00,1858.00 1037.00,1858.00 1037.00,1858.00 1037.00,1858.00 1037.00,1952.00 1037.00,1952.00 1037.00,1952.00 1035.98,1959.40 1035.98,1959.40 1035.98,1959.40 1029.00,1961.17 1029.00,1961.17 1029.00,1961.17 1012.00,1962.83 1012.00,1962.83 1012.00,1962.83 988.00,1964.09 988.00,1964.09 988.00,1964.09 932.00,1966.00 932.00,1966.00 932.00,1966.00 932.00,1858.00 932.00,1858.00 Z",
    attr: {
      id: "_638",
      "data-name": "638",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "638", zone: "Upper Zone", zoneId: "3" },
  };

  var _639 = {
    path:
      "M 806.00,1858.00 C 806.00,1858.00 915.00,1858.00 915.00,1858.00 915.00,1858.00 915.00,1966.00 915.00,1966.00 915.00,1966.00 890.00,1966.00 890.00,1966.00 890.00,1966.00 878.00,1965.04 878.00,1965.04 878.00,1965.04 865.00,1965.04 865.00,1965.04 865.00,1965.04 851.00,1964.00 851.00,1964.00 851.00,1964.00 806.00,1961.00 806.00,1961.00 806.00,1961.00 806.00,1858.00 806.00,1858.00 Z",
    attr: {
      id: "_639",
      "data-name": "639",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "639", zone: "Upper Zone", zoneId: "3" },
  };

  var _640 = {
    path:
      "M 722.00,1858.00 C 722.00,1858.00 789.00,1858.00 789.00,1858.00 789.00,1858.00 789.00,1959.00 789.00,1959.00 789.00,1959.00 761.00,1954.58 761.00,1954.58 761.00,1954.58 722.00,1948.00 722.00,1948.00 722.00,1948.00 722.00,1858.00 722.00,1858.00 Z",
    attr: {
      id: "_640",
      "data-name": "640",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "640", zone: "Upper Zone", zoneId: "3" },
  };

  var _641 = {
    path:
      "M 623.00,1857.00 C 623.00,1857.00 654.00,1858.00 654.00,1858.00 654.00,1858.00 705.00,1858.00 705.00,1858.00 705.00,1858.00 705.00,1944.00 705.00,1944.00 705.00,1944.00 675.00,1938.13 675.00,1938.13 675.00,1938.13 633.00,1927.87 633.00,1927.87 633.00,1927.87 616.00,1923.29 616.00,1923.29 616.00,1923.29 609.69,1919.70 609.69,1919.70 609.69,1919.70 612.58,1903.00 612.58,1903.00 612.58,1903.00 623.00,1857.00 623.00,1857.00 Z",
    attr: {
      id: "_641",
      "data-name": "641",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "641", zone: "Upper Zone", zoneId: "3" },
  };

  var _642 = {
    path:
      "M 533.00,1840.00 C 533.00,1840.00 588.00,1850.25 588.00,1850.25 591.32,1850.83 604.15,1852.46 605.34,1855.43 606.11,1857.35 602.99,1870.17 602.35,1873.00 602.35,1873.00 593.00,1916.00 593.00,1916.00 584.51,1914.69 549.69,1902.26 540.00,1898.58 536.61,1897.28 524.89,1893.57 523.59,1890.61 522.47,1888.08 524.76,1879.01 525.42,1876.00 525.42,1876.00 533.00,1840.00 533.00,1840.00 Z",
    attr: {
      id: "_642",
      "data-name": "642",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "642", zone: "Upper Zone", zoneId: "3" },
  };

  var _643 = {
    path:
      "M 464.00,1826.00 C 464.00,1826.00 517.00,1836.00 517.00,1836.00 517.00,1836.00 507.00,1884.00 507.00,1884.00 498.17,1882.62 479.48,1872.28 471.00,1867.78 467.95,1866.16 460.62,1862.84 459.19,1859.79 458.12,1857.51 459.33,1852.52 459.75,1850.00 459.75,1850.00 464.00,1826.00 464.00,1826.00 Z",
    attr: {
      id: "_643",
      "data-name": "643",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "643", zone: "Upper Zone", zoneId: "3" },
  };

  var _PILCOUNTRY = {
    path:
      "M193.630219,1252.661987   C182.890579,1256.234375 172.514999,1259.647461 161.455170,1263.285522   C158.591583,1252.791382 156.398865,1242.439087 152.923096,1232.537354   C147.072525,1215.870361 146.768204,1198.792358 146.906998,1181.455688   C147.208496,1143.794678 146.905640,1106.129272 147.018692,1068.466064   C147.294708,976.519043 147.541489,884.571289 148.226990,792.627014   C148.284698,784.889099 151.367523,777.128723 153.363602,769.461731   C155.861801,759.866028 158.689835,750.356079 161.451660,740.538391   C167.973297,742.523254 174.448639,744.428406 180.882584,746.464294   C202.846008,753.414246 224.783936,760.444885 246.753235,767.376221   C260.810974,771.811462 274.879181,776.218811 289.006958,780.422913   C292.194916,781.371521 293.209320,782.994385 293.029877,786.141785   C288.466736,866.175476 291.910004,946.277527 291.109283,1026.345215   C290.544342,1082.834839 290.954590,1139.334595 291.075073,1195.829590   C291.086273,1201.081177 291.913116,1206.369873 292.784576,1211.570557   C293.852814,1217.945557 293.403839,1219.004883 287.384918,1221.031372   C256.251953,1231.513550 225.123978,1242.010742 193.630219,1252.661987  z",
    attr: {
      id: "_PILCOUNTRY",
      "data-name": "PILCOUNTRY",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "PIL COUNTRY - STANDING ROOM ONLY", zoneId: "1" },
  };

  zones.push(
    _106,
    _107,
    _108,
    _109,
    _110,
    _111,
    _112,
    _113,
    _114,
    _115,
    _116,
    _117,
    _118,
    _119,
    _120,
    _121,
    _122,
    _123,
    _124,
    _125,
    _126,
    _127,
    _128,
    _129,
    _130,
    _131,
    _132,
    _133,
    _134,
    _135,
    _136,
    _137,
    _138,
    _139,
    _140,
    _141,
    _142,
    _143,
    _144,
    _145,
    _146,
    _147,
    _224,
    _225,
    _226,
    _227,
    _228,
    _235,
    _236,
    _237,
    _238,
    _239,
    _240,
    _241,
    _242,
    _243,
    _308,
    _309,
    _310,
    _311,
    _312,
    _313,
    _313A,
    _314,
    _314A,
    _315,
    _315A,
    _316,
    _316A,
    _317,
    _318,
    _319,
    _320,
    _510,
    _511,
    _512,
    _513,
    _514,
    _515,
    _516,
    _517,
    _518,
    _519,
    _520,
    _521,
    _522,
    _523,
    _530,
    _531,
    _532,
    _533,
    _534,
    _535,
    _536,
    _537,
    _538,
    _539,
    _540,
    _541,
    _542,
    _543,
    _610,
    _611,
    _612,
    _613,
    _614,
    _615,
    _616,
    _617,
    _618,
    _619,
    _634,
    _635,
    _636,
    _637,
    _638,
    _639,
    _640,
    _641,
    _642,
    _643,
    _PILCOUNTRY
  );
  return zones;
};
