import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Nav from '../components/OldNav';
import Footer from '../components/OldFooter';

const PlainLayout = ({ 
  children,
  currUser,
  isAuthenticated,
  handleLogout,
  history,
  classes,
  ...props
 }) => {
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Nav
          currUser={currUser}
          isAuthenticated={isAuthenticated}
          handleLogout={handleLogout}
          history={history}
          {...props}
        />
      </Hidden>
      {children}
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
};

export default PlainLayout;
