import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
  
const styles = theme => ({
  [theme.breakpoints.up("md")]: {
    formContainer: {
      margin: "5rem 0"
    }
  },
  [theme.breakpoints.down("sm")]: {
    formContainer: {
      marginTop: "3rem",
      padding: "0 1rem"
    }
  },
  textField: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    border: 'solid 1px #e5e5ea',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  textFieldError: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 23, 68, .05)',
    border: 'solid 1px rgba(255, 23, 68, .5)',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  form: {
    marginTop: '2rem'
  },
  input: {
    padding: 0,
    fontSize: '0.875rem'
  },
  button: {
    padding: '.875rem',
    marginTop: '.5rem'
  }
});

class NewPassword extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      user: null,
      isSubmitting: false,
      isSubmitted: false,
      newPassword: '',
      confirmPassword: '',
      isValidNewPassword: true,
      isValidConfirmPassword: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitNewPassword = this.submitNewPassword.bind(this);
  }

  componentDidMount() {
    const user = this.props.userNeedNewPassword;
    if(!user) {
      alert('Session is outdated. Redirecting you now to login.');
      this.props.history.push('/login');
    }

    this.setState({ user });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  isValidPasswords() {
    const { newPassword, confirmPassword } = this.state;

    const isValidNewPassword = newPassword !== '' && newPassword.length >= 6;
    const isValidConfirmPassword =
      confirmPassword !== '' && newPassword === confirmPassword;

    this.setState({
      isValidNewPassword,
      isValidConfirmPassword
    });

    return isValidNewPassword && isValidConfirmPassword;
  }

  async submitNewPassword(e) {
    e.preventDefault();

    try {
      this.setState({
        isSubmitting: true,
        isSubmitted: false
      });

      if(this.isValidPasswords()) {
        const { newPassword, user } = this.state;

        await Auth.completeNewPassword(
          user,
          newPassword
        );

        const currUser = await Auth.currentAuthenticatedUser();

        this.props.userHasAuthenticated(true);
        this.props.setUserAttributes(currUser);

        this.setState({
          isSubmitting: false,
          isSubmitted: false
        });

        this.props.history.push('/listings');
      } else {
        // Passwords do not match
        this.setState({
          isSubmitting: false,
          isSubmitted: false
        });
      }
    } catch(e) {
      this.setState({
        isSubmitting: false,
        isSubmitted: false
      });

      alert(e);
      console.error(e);
    }
  }

  renderForm() {
    const { classes } = this.props;
    const {
      isSubmitting,
      newPassword,
      confirmPassword,
      isValidNewPassword,
      isValidConfirmPassword
    } = this.state;
    
    return (
      <form onSubmit={this.submitNewPassword}>
        <Typography
          style={{
            marginBottom: "1.5rem"
          }}
          variant="title"
          color="textPrimary"
        >
          Set Your New Password
        </Typography>

        <TextField
            className={
              isValidNewPassword ? classes.textField : classes.textFieldError
            }
            disabled={isSubmitting}
            error={true}
            id="newPassword"
            placeholder="New Password"
            name="newPassword"
            fullWidth={true}
            value={newPassword}
            onChange={this.handleChange}
            margin="dense"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input
              }
            }}
            type="password"
          />

          {isValidNewPassword ? (
            ''
          ) : (
            <FormHelperText error={true}>
              Your new password must be at least 6 characters
            </FormHelperText>
          )}

          <TextField
            className={
              isValidConfirmPassword
                ? classes.textField
                : classes.textFieldError
            }
            disabled={isSubmitting}
            error={true}
            id="confirmPassword"
            placeholder="Confirm New Password"
            name="confirmPassword"
            fullWidth={true}
            value={confirmPassword}
            onChange={this.handleChange}
            margin="dense"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input
              }
            }}
            type="password"
          />

          {isValidConfirmPassword ? (
            ''
          ) : (
            <FormHelperText error={true}>Passwords do not match</FormHelperText>
          )}

          <Button
            className={classes.button}
            color="primary"
            disabled={isSubmitting}
            fullWidth={true}
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
      </form>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid
          className={classes.formContainer}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            {this.renderForm()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NewPassword);