import moment from "moment";
import Setting from "../../constants/Setting";
import SENATORS_ROWS from "../../constants/SenatorsSeatRows";
import WHITECAPS_ROWS from "../../constants/WhitecapsSeatRows";
import { 
  raptors2023TEId,
  lions2023TEId,
  blueJays2024TEId,
  jets2024TEId,
  oilers2024TEId,
  canucks2024TEId,
  leafs2024TEId,
  whitecaps2024TEId,
  argonauts2024TEId,
  roughriders2024TEId,
  blueBombers2024TEId, 
} from "../../constants/PlayoffGamesTeId";
import { getNonAlcoholLabel, getTeamZone } from "./get-team-zone";
import { getTeamTier } from "./get-team-tier";

export const renderSeatNumbers = (
  seat,
  isWithPrefix = true,
  emptyLabel = "",
  isAdmin = false
) => {
  const numbersLabel =
    Number(seat.seatNumberStart) === Number(seat.seatNumberEnd)
      ? `${isWithPrefix ? "\u00A0\u2022 Seat " : ""}${seat.seatNumberStart}`
      : `${isWithPrefix ? "\u00A0\u2022 Seats " : ""}${
          seat.seatNumberStart
        } - ${Number(seat.seatNumberStart) + Number(seat.noOfSeats) - Number(seat.seatsSold) - 1 }`;
  if (
    (Number(seat.seatNumberStart) > 0) && 
    (seat.seatsSold === 0) &&
    (isAdmin)
    ) {
    return numbersLabel;
  } else if (
    (!isAdmin) &&
    (seat.seatsSold === 0) &&
    (Number(seat.seatNumberStart) > 0)
  ) {
    return numbersLabel;
  } else {
    return emptyLabel;
  }
};

export const rateFallback = 1.35;

export const getAppliedTEFee = (game) => {
  const gameDate = game ? getLocalizedDateTime(game.date, game.timezone) : {stringDate: ''};
  const teamSlug = game ? game.homeTeamSlug : '';
  
  switch (true) {
    case teamSlug === "toronto-blue-jays" && gameDate.stringDate === "2023-07-28":
      {
        return Setting["TE_FEE_TORONTO_BLUE_JAYS"]["2023-07-28"]
        ? Setting["TE_FEE_TORONTO_BLUE_JAYS"]["2023-07-28"]
        : Setting.TE_FEE;
      }

    default:
      {
        return Setting.TE_FEE;
      }
  }
};

export const convertPriceToCAD = (seat, currencyRate, game) => {
  const conversionRate =
    currencyRate && currencyRate.rate
      ? Number(currencyRate.rate)
      : rateFallback;
  const convertedPriceToCAD = conversionRate * Number(seat.retail_price);
  const appliedTEFee = getAppliedTEFee(game);
  // add percentage fee
  const fee = (convertedPriceToCAD / 100) * Number(appliedTEFee);
  const totalPrice = Number(convertedPriceToCAD) + Number(fee);
  const roundUpSplit = totalPrice.toFixed(1).split(".");
  const roundUp =
    Number(roundUpSplit[1]) <= 5
      ? `${roundUpSplit[0]}.50`
      : `${Number(roundUpSplit[0]) + 1}`;
  return roundUp;
};

export const convertPriceToCADNoFee = (seat, currencyRate, game) => {
  const conversionRate =
    currencyRate && currencyRate.rate
      ? Number(currencyRate.rate)
      : rateFallback;
  const convertedPriceToCAD = conversionRate * Number(seat.retail_price);
  // add percentage fee
  const fee = (convertedPriceToCAD / 100) * Number(5);
  const totalPrice = Number(convertedPriceToCAD) + Number(fee);
  const roundUpSplit = totalPrice.toFixed(1).split(".");
  const roundUp =
    Number(roundUpSplit[1]) <= 5
      ? `${roundUpSplit[0]}.50`
      : `${Number(roundUpSplit[0]) + 1}`;
  return roundUp;
};

export const nextDaysGameCheck = (game) => {
  const todayTimeUTC = moment().valueOf();
  const offset =
      moment["tz"](moment(new Date(todayTimeUTC)), game.timezone).utcOffset() * 60000;
  const todayInLocal = todayTimeUTC + offset;
  const max3TimeUTC = moment(new Date(todayInLocal)).add(2, "days").valueOf();
  const max2TimeUTC = moment(new Date(todayInLocal)).add(1, "days").valueOf();
  const gameDateString = moment.tz(Number(game.date), game.timezone).format("YYYY-MM-DD");
  const gameDateStart = new Date(Number(game.date)).valueOf();
  const gameDate = new Date(gameDateString).valueOf();
  const isNext3DaysGameCalc = gameDateStart >= todayTimeUTC && gameDate < max3TimeUTC;
  const isNext2DaysGameCalc = gameDateStart >= todayTimeUTC && gameDate < max2TimeUTC;
  switch (game.homeTeamSlug) {
    case "vancouver-canucks": {
      return isNext3DaysGameCalc;
    }
    case "calgary-flames": {
      return isNext3DaysGameCalc;
    }
    case "winnipeg-jets": {
      return isNext2DaysGameCalc;
    }
    default: {
      return true;
    }
  }
}

export const includeTeListingsCheck = (game) => {
  const todayTimeUTC = moment().valueOf();
  const gameDate = new Date(Number(game.date)).valueOf();
  const gameDateDiff = gameDate - todayTimeUTC;
  const gameDateDiffInHour = gameDateDiff / 1000 / 60 / 60;
  const isMorethan1Hour = gameDateDiffInHour > 1.05 ? true : false;
  const isNext5DaysGame = nextDaysGameCheck(game)
  const isGameAvailable =
    game.homeTeamSlug === "toronto-blue-jays" || cflTeamCheck(game.homeTeamSlug) || mlsTeamCheck(game.homeTeamSlug) ? true : isNext5DaysGame;
  const isTeamNeedToInclude =
    game.homeTeamSlug === "toronto-maple-leafs" ||
    // game.homeTeamSlug === "calgary-flames" || turn off flames
    game.homeTeamSlug === "toronto-raptors" ||
    game.homeTeamSlug === "vancouver-canucks" ||
    game.homeTeamSlug === "toronto-blue-jays" ||
    game.homeTeamSlug === "ottawa-senators" ||
    game.homeTeamSlug === "winnipeg-jets" ||
    cflTeamCheck(game.homeTeamSlug) ||
    mlsTeamCheck(game.homeTeamSlug) ||
    ahlTeamCheck(game.homeTeamSlug);
  const isPlayoffsGame = game && game.isPlayoffs;
  const isSpecialGame = game && game.isSpecial;
  return (isGameAvailable && isTeamNeedToInclude && isMorethan1Hour) || (isPlayoffsGame && isTeamNeedToInclude && isMorethan1Hour) || (isSpecialGame && isTeamNeedToInclude && isMorethan1Hour);
};

export const getLocalizedDateTime = (date, timezone, formatHour = "H:mm") => {
  const dateWithTimeZone = moment
    .tz(Number(date), timezone)
    .format("YYYY-MM-DD");
  const timeWithTimeZone = moment.tz(Number(date), timezone).format(formatHour);
  return {
    stringDate: dateWithTimeZone,
    stringTime: timeWithTimeZone,
  };
};

export const getStaticTeID = (game) => {
  const staticTeId = {
    'winnipeg-blue-bombers': {
      '2023-06-09': '2301301',
      '2023-06-22': '2301274',
    },
    'vancouver-canucks': {
      '2024-09-25': '2732304'
    },
    'ottawa-senators': {
      '2024-09-26': '2691385'
    }
  }
  switch (game.homeTeamSlug) {
    case "winnipeg-blue-bombers": {
      const gameDate = getLocalizedDateTime(game.date, game.timezone);
      const teID = staticTeId[game.homeTeamSlug][gameDate.stringDate];
      return teID ? teID : '';
    }

    case "vancouver-canucks": {
      const gameDate = getLocalizedDateTime(game.date, game.timezone);
      const teID = staticTeId[game.homeTeamSlug][gameDate.stringDate];
      return teID ? teID : '';
    }

    case "ottawa-senators": {
      const gameDate = getLocalizedDateTime(game.date, game.timezone);
      const teID = staticTeId[game.homeTeamSlug][gameDate.stringDate];
      return teID ? teID : '';
    }

    default:
      return '';
  }
}

export const handleGameClickHelper = ({ game, zone, history, teId }) => {
  const staticId = getStaticTeID(game);
  teId = teId === '' ? staticId : teId;
  const tePlayoffId = game && game.playoffSequence ? getTEPlayoffID(game.homeTeamSlug, game.playoffSequence) : '';
  const teQueryPlayoffId = tePlayoffId !== '' ? tePlayoffId : teId;
  const teQueryParams = includeTeListingsCheck(game) && teId !== '' ? `&teId=${teQueryPlayoffId}` : "";
  const slug = `${game.homeTeamSlug}-tickets`;
  history.push(`/seats/${slug}/${game.id}?zone=${zone}`);
};

export const teSellerID = "c843acc5-aa23-49a6-9634-6c5415064fa7";
export const excludeCheck = (seat, teamSlug) => {
  switch (teamSlug) {
    case "calgary-flames": {
      return false;
    }

    case "calgary-wranglers": {
      return false;
    }

    case "edmonton-oilers": {
      return false;
    }

    case "vancouver-canucks": {
      const exlcludedList = [
        { section: 317, rows: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
        { section: 318, rows: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
        { section: 311, rows: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
        { section: 312, rows: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
        { section: 102, rows: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25] },
        { section: 101 },
      ];
      const isTEListing = typeof seat.sellerId === "undefined";

      if (isTEListing) {
        const findSection = exlcludedList.find((sections) => {
          return sections.section === seat.zoneNo;
        });
        return findSection ? true : false;
      }

      return false;
    }

    case "winnipeg-jets": {
      return false;
    }

    case "toronto-maple-leafs": {
      return false;
    }

    case "ottawa-senators": {
      const exlcludedList = [
        { section: 317 },
      ];
      const isTEListing = typeof seat.sellerId === "undefined";

      if (isTEListing) {
        const findSection = exlcludedList.find((sections) => {
          return sections.section === seat.zoneNo;
        });
        return findSection ? true : false;
      }

      return false;
    }

    case "toronto-raptors": {
      return false;
    }

    case "abbotsford-canucks": {
      return false;
    }

    case "belleville-senators": {
      return false;
    }

    case "toronto-marlies": {
      return false;
    }

    default: {
      return false;
    }
  }
};

export const teSenatorRowCheck = (rows) => {
  const isExist = SENATORS_ROWS.A_TO_S.find((row) => {
    return row.value === String(rows).toUpperCase();
  });

  return isExist ? true : false;
};
export const teWhitecapsFcRowCheck = (rows) => {
  const isExist = WHITECAPS_ROWS.DEFAULT_ROWS.find((row) => {
    return row.value === String(rows).toUpperCase();
  });

  return isExist ? true : false;
};

export const teTeamToFF = (teTeamId) => {
  switch (teTeamId) {
    case 16415: {
      return "calgary-flames";
    }

    case 16417: {
      return "edmonton-oilers";
    }

    case 16419: {
      return "vancouver-canucks";
    }

    case 22681: {
      return "winnipeg-jets";
    }

    case 16404: {
      return "toronto-maple-leafs";
    }

    case 16403: {
      return "ottawa-senators";
    }

    case 16305: {
      return "toronto-raptors";
    }

    case 15535: {
      return "toronto-blue-jays";
    }

    case -1: {
      return "calgary-wranglers";
    }

    default: {
      return "";
    }
  }
};

export const getSectionZone = (section, teamSlug) => {
  const isNumber = !isNaN(Number(section));
  const variant1 = Number(section.substring(2));
  const variant2 = section.split(" ").pop();
  const fallbackFlame = isNaN(variant1) ? variant2 : variant1;
  switch (teamSlug) {
    case "calgary-flames": {
      return isNumber ? Number(section) : Number(fallbackFlame);
    }
    case "edmonton-oilers": {
      return isNumber ? Number(section) : Number(section.substring(2));
    }
    case "vancouver-canucks": {
      return isNumber ? Number(section) : Number(section.substring(2));
    }
    case "winnipeg-jets": {
      return isNumber ? Number(section) : Number(section.substring(2));
    }
    case "toronto-maple-leafs": {
      return isNumber ? Number(section) : NaN;
    }
    case "ottawa-senators": {
      return isNumber ? Number(section) : Number(section.substring(2));
    }
    case "toronto-raptors": {
      return isNumber ? Number(section) : NaN;
    }
    case "toronto-blue-jays": {
      return isNumber ? Number(section) : section;
    }
    case "bc-lions": {
      const otherZone = ["7B", "8B", "9B", "11B", "Party Zone", "VIP Zone", "GA Party Zone", "PARTY3", "PARTY5"];
      const isOtherZone = otherZone.find((zone) => zone === String(section)) ? true : false;
      const specialLabel = isOtherZone ? '' : section;
      return isNumber ? Number(section) : specialLabel;
    }
    case "edmonton-elks": {
      const lastItem = Number(section[section.length - 1]);
      return lastItem ? Number(lastItem) : "";
    }
    case "winnipeg-blue-bombers": {
      return isNumber ? Number(section) : section;
    }
    case "calgary-stampeders": {
      const sectionLabel = String(section).toUpperCase() === 'SECX' ? 'X' : String(section).toUpperCase();
      return isNumber ? Number(section) : sectionLabel;
    }
    case "toronto-argonauts": {
      return isNumber ? Number(section) : section;
    }
    case "ottawa-redblacks": {
      return Number(section);
    }
    case "saskatchewan-roughriders": {
      return Number(section);
    }
    case "hamilton-tigercats": {
      return Number(section);
    }
    case "grey-cup": {
      return isNumber ? Number(section) : section;
    }
    case "calgary-wranglers": {
      return isNumber ? Number(section) : Number(fallbackFlame);
    }
    case "manitoba-moose": {
      return isNumber ? Number(section) : Number(section.substring(2));
    }
    case "vancouver-whitecaps-fc": {
      return isNumber ? Number(section) : section;
    }
    case "toronto-fc": {
      return isNumber ? Number(section) : section;
    }
    case "abbotsford-canucks": {
      return isNumber ? Number(section) : section;
    }
    case "belleville-senators": {
      return isNumber ? Number(section) : section;
    }
    case "toronto-marlies": {
      return isNumber ? Number(section) : section;
    }
    default: {
      return 0;
    }
  }
};

export const getRowSection = (row, teamSlug) => {
  const isNumber = !isNaN(Number(row));

  const numberRow = Number(row);
  switch (teamSlug) {
    case "calgary-flames": {
      return isNumber ? numberRow : "";
    }
    case "edmonton-oilers": {
      return isNumber ? numberRow : "";
    }
    case "vancouver-canucks": {
      return isNumber ? numberRow : "";
    }
    case "winnipeg-jets": {
      return isNumber ? numberRow : "";
    }
    case "toronto-maple-leafs": {
      return isNumber ? numberRow : "";
    }
    case "ottawa-senators": {
      return isNumber ? numberRow : row;
    }
    case "toronto-raptors": {
      return isNumber ? numberRow : "";
    }
    case "toronto-blue-jays": {
      return isNumber ? numberRow : "";
    }
    case "bc-lions": {
      return row;
    }
    case "ottawa-redblacks": {
      return row;
    }
    case "toronto-argonauts": {
      return row;
    }
    case "calgary-stampeders": {
      return row;
    }
    case "winnipeg-blue-bombers": {
      return row;
    }
    case "edmonton-elks": {
      return row;
    }
    case "saskatchewan-roughriders": {
      return row;
    }
    case "hamilton-tigercats": {
      return row;
    }
    case "grey-cup": {
      return row;
    }
    case "calgary-wranglers": {
      return isNumber ? numberRow : "";
    }
    case "manitoba-moose": {
      return isNumber ? numberRow : "";
    }
    case "vancouver-whitecaps-fc": {
      return row;
    }
    case "toronto-fc": {
      return row;
    }
    case "abbotsford-canucks": {
      return row;
    }
    case "belleville-senators": {
      return row;
    }
    case "toronto-marlies": {
      return row;
    }

    default: {
      return 0;
    }
  }
};

export const getTEPlayoffID = (teamSlug, playoffSequence) => {
  switch (teamSlug) {
    case "calgary-flames": {
      return '';
    }

    case "edmonton-oilers": {
      const findTEId = oilers2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "vancouver-canucks": {
      const findTEId = canucks2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "winnipeg-jets": {
      const findTEId = jets2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "toronto-maple-leafs": {
      const findTEId = leafs2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "ottawa-senators": {
      return '';
    }

    case "toronto-raptors": {
      const findTEId = raptors2023TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "toronto-blue-jays": {
      const findTEId = blueJays2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "calgary-wranglers": {
      return '';
    }

    case "winnipeg-blue-bombers": {
      const findTEId = blueBombers2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "toronto-argonauts": {
      const findTEId = argonauts2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "bc-lions": {
      const findTEId = lions2023TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "saskatchewan-roughriders": {
      const findTEId = roughriders2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "vancouver-whitecaps-fc": {
      const findTEId = whitecaps2024TEId.find((id) => id.playoffSequence === playoffSequence);
      return findTEId ? findTEId.id : '';
    }

    case "abbotsford-canucks": {
      return '';
    }

    case "belleville-senators": {
      return '';
    }

    case "toronto-marlies": {
      return '';
    }

    default: {
      return '';
    }
  }
};

export const getTeamId = (teamSlug) => {
  switch (teamSlug) {
    case "calgary-flames": {
      return 16415;
    }
    case "edmonton-oilers": {
      return 16417;
    }
    case "vancouver-canucks": {
      return 16419;
    }
    case "winnipeg-jets": {
      return -1;
    }
    case "toronto-maple-leafs": {
      return 16404;
    }
    case "ottawa-senators": {
      return 16403;
    }
    case "toronto-raptors": {
      return 16305;
    }
    case "toronto-blue-jays": {
      return 15535;
    }
    case "edmonton-elks": {
      return 78171;
    }
    case "winnipeg-blue-bombers": {
      return 16300;
    }
    case "toronto-argonauts": {
      return 16295;
    }
    case "calgary-stampeders": {
      return 16297;
    }
    case "ottawa-redblacks": {
      return 41497;
    }
    case "bc-lions": {
      return 30587;
    }
    case "saskatchewan-roughriders": {
      return 16299;
    }
    case "hamilton-tigercats": {
      return 16292;
    }
    case "grey-cup": {
      return 13828;
    }
    case "calgary-wranglers": {
      return 83865;
    }
    case "manitoba-moose": {
      return 15191;
    }
    case "vancouver-whitecaps-fc": {
      return 30243;
    }
    case "toronto-fc": {
      return 16206;
    }
    case "abbotsford-canucks": {
      return 15112;
    }
    case "belleville-senators": {
      return 53829;
    }
    case "toronto-marlies": {
      return 14618;
    }

    default: {
      return -1;
    }
  }
};

export const renderZoneName = (zone, zoneNo, row, homeTeamSlug, isSpecial=false) => {
  const zoneNumber = zoneNo ? zoneNo : "";
  if (homeTeamSlug === "toronto-blue-jays") {
    // format zone name
    const letter = zone.split(" ").pop();
    if (["WL", "WR"].includes(letter)) {
      const parts = letter.match(/.{1}/g);
      return `${zone.replace(letter, "")}${parts[0]}${zoneNo}${parts[1]}`;
    }else if (["W"].includes(letter)) {
      return `${zone.replace(letter, "")}${letter}${zoneNo}`;
    }else if (
      ["R", "AR", "AL", "BR", "BL", "CR", "CL", "DR", "DL", "A", "B", "C", "D"].includes(
        letter
      )
    ) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    } else if (["L"].includes(letter)) {
      if(zone == 'Lower Zone L'){
        return `Lower Zone ${zoneNo}${letter}`;
      }else{
        return `${zone.replace(letter, "")}${zoneNo}${letter}`;
      }
    } else if (zone.replace(/[^a-zA-Z]+/g, '').toUpperCase() === 'WESTJETFLIGHTDECK'){
      return `WESTJET FLIGHT DECK Standing Room Only`;
    }
  } else if (homeTeamSlug === "edmonton-oilers") {
    const zoneOilers = zone.replace(/Upper Zone/g, 'Upper Bowl').replace(/Lower Zone/g, 'Lower Bowl');
    let result = `${zoneOilers} ${zoneNo ? zoneNo : ""}`;
  
    if (isSpecial) {
      const letter = zone.split(" ").pop();
      const letters = ["S", "AA", "BB", "CC", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "DD", "EE", "FF", "GG", "HH", "JJ", "KK", "LL", "MM", "NN", "OO", "N", "O", "P", "Q", "R", "T", "U", "V", "W", "X", "Y", "Z", "PP", "QQ", "RR"];    
  
      if (letters.includes(letter)) {
        if (zone.includes("Upper Bowl")) {
          result = `Upper Bowl ${letter}${zoneNumber}`;
        } else if (zone.includes("Lower Bowl")) {
          result = `Lower Bowl ${letter}${zoneNumber}`;
        }
      }
    }

    if ((zoneNo >= 101 && zoneNo <= 104) || (zoneNo >= 118 && zoneNo <= 121)) {
      result = result.replace(/Lower Bowl/g, 'Lower Club');
    } else if ([107, 109, 113, 115].includes(zoneNo) && ["23", "24", "25"].includes(row)) {
      result = result.replace(/Lower Bowl/g, 'Ozone Club');
    }
  
    return result;
  } else if (homeTeamSlug === "ottawa-redblacks") {
    return `${zone} ${Number(zoneNo) > 0 ? zoneNo : ""}`;
  } else if (homeTeamSlug === "bc-lions" || homeTeamSlug === "grey-cup" ) {
    const zoneLions = String(zone).toLowerCase().includes("middle zone") ? String(zone).replace("Middle Zone", "Club Seats") : zone;
    return `${zoneLions} ${zoneNo ? zoneNo : ""}${getNonAlcoholLabel({zone: zoneLions, zoneNo, gameDetails: {homeTeamSlug}})}`;
  } else if (homeTeamSlug === "toronto-argonauts") {
    const letter = zone.split(" ").pop();
    if (
      ["A"].includes(
        letter
      )
    ) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    }
  } else if (homeTeamSlug === "edmonton-elks") {
    const letter = zone.split(" ").pop();
    if (
      ["S", "AA", "BB", "CC", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "DD", "EE", "FF", "GG", "HH", "JJ", "KK", "LL", "MM", "NN", "OO", "N", "O", "P", "Q", "R", "T", "U", "V", "W", "X", "Y", "Z", "PP", "QQ", "RR"].includes(
        letter
      )
    ) {
      if(zoneNo){
        return `${zone}${zoneNo}`;
      } else {
        return `${zone} ${zoneNo ? zoneNo : ""}`;
      }
    }
  } else if (homeTeamSlug === "saskatchewan-roughriders") {
    const letter = zone.split(" ").pop();
    if (
      ["A"].includes(
        letter
      )
    ) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    } else if (zone.replace(/[^a-zA-Z]+/g, '').toUpperCase() === 'PILCOUNTRYSTANDINGROOMONLY'){
      return `Pil Country Standing Room Only +19 years of age`;
    }
  } else if (homeTeamSlug === "hamilton-tigercats") {
    if (zone.includes("Premium Zone")) {
      return `${zone}${zoneNumber}`;
    }    
  } else if (homeTeamSlug === "winnipeg-blue-bombers") {
    if ((zoneNo >= 106 && zoneNo <= 109) && ( row >= 9 )) {
      return `${zone.replace(/Lower Zone/g, 'Pinnacle Club')} ${zoneNo ? zoneNo : ""}${getNonAlcoholLabel({zone, zoneNo, gameDetails: {homeTeamSlug}})}`;
    }
  } else if (homeTeamSlug === "abbotsford-canucks") {
    const letter = zone.split(" ").pop();
    if (["A", "B"].includes(letter)) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    }
  } else if (homeTeamSlug === "vancouver-canucks"){
    if (isSpecial){
      const letter = zone.split(" ").pop();
      if (["A", "B"].includes(letter)) {
        return `${zone.replace(letter, "")}${zoneNo}${letter}`;
      }
    }
  } else if (homeTeamSlug === "belleville-senators"){
    const letter = zone.split(" ").pop();
    if (["L"].includes(letter)) {
      if (zone == 'Lower Zone L') {
        return `Lower Zone ${zoneNo}${letter}`;
      } else {
        return `${zone.replace(letter, "")}${zoneNo}${letter}`;
      }
    } else if (["Box"].includes(letter)) {
      if (zone == 'Lower Zone Box') {
        return `Lower Zone ${zoneNo} ${letter}`;
      } else {
        return `${zone.replace(letter, "")}${zoneNo} ${letter}`;
      }
    }
  }

  return `${zone} ${zoneNo ? zoneNo : ""}${getNonAlcoholLabel({zone, zoneNo, gameDetails: {homeTeamSlug}})}`;
};

export const renderRowName = (zone, rowNo, homeTeamSlug, isSpecial=false) => {
  const zeroPad = (num, places) => String(num).padStart(places, '0')

  if (homeTeamSlug === "toronto-blue-jays") {
    if (zone.replace(/[^a-zA-Z]+/g, '').toUpperCase() === 'WESTJETFLIGHTDECK') {
      return `GA${zeroPad(rowNo.replace(/\D/g,''), 2)}`;
    }
  } 
  if (homeTeamSlug === "bc-lions" && isSpecial) {
    return rowNo.replace(/\d*-GA|GA\d*|-GA|GA-/g, 'GA');
  } 
  return rowNo;
};

export const blueJaysMergeSectionZone = (zone) => {
  let toCheck = ["R", "L", "AR", "AL", "BR", "BL", "CR", "CL", "DR", "DL", "A", "B", "C", "D"];
  let nameCheck = [
    "WESTJET FLIGHT DECK",
    "TM LOUNGE",
    "CORONA ROOFTOP PATIO",
    "HOME RUN ZONE",
    "LEFT FIELD BALCONY",
    "RIGHT FIELD BLEACHERS",
    "ROGERS LANDING",
    "SCHNEIDERS PORCH",
    "TD PARK SOCIAL",
    "THE CATCH BAR",
    "THE STOP"
  ];
  if ( nameCheck.some(o => zone.includes(o)) ) {
    return `${zone}`;
  } 
  else if (zone.includes(" W")) {
    return `${zone.replace(/R|(?!^)L/g, "")}`;
  }
  else if ( toCheck.some(o => zone.includes(o)) ) {
    return `${zone.replace(/R|(?!^)L| L| R/g, "")}`;
  } 
  return `${zone}`;
};

export const formatTEZoneToFF = (teSeat, game) => {
  const { homeTeamSlug: teamSlug } = game;
  const zoneInvetory = teSeat.tevo_section_name
    .split(" ")
    .slice(0, -1)
    .join(" ");
  const zoneNo = teSeat.tevo_section_name.split(" ").pop();
  return getTeamZone(teamSlug, zoneInvetory.toLowerCase(), zoneNo);
};

export const formatTEZoneCFLToFF = (teSeat, game) => {
  const { homeTeamSlug: teamSlug } = game;
  const zoneInvetory = teSeat.tevo_section_name
    .split(" ")
    .slice(0, -1)
    .join(" ");
  const zoneNo = teSeat.section;
  return getTeamZone(teamSlug, zoneInvetory.toLowerCase(), zoneNo);
};

export const formatTEZoneMLSToFF = (teSeat, game) => {
  const { homeTeamSlug: teamSlug } = game;
  const zoneInvetory = teSeat.tevo_section_name
    .split(" ")
    .slice(0, -1)
    .join(" ");
  const zoneNo = teSeat.section;
  return getTeamZone(teamSlug, zoneInvetory.toLowerCase(), zoneNo);
};

export const formatJaysTEZoneToFF = (teSeat, game) => {
  const { homeTeamSlug: teamSlug } = game;
  const zoneInvetory = teSeat.tevo_section_name
    .split(" ")
    .slice(0, -1)
    .join(" ");

  const zoneNo = teSeat.tevo_section_name.split(" ").pop();
  const trimTESection = zoneNo.length > 3 ? zoneNo.slice(0, -1) : zoneNo;
  const teZoneNo = !isNaN(Number(trimTESection)) ? Number(trimTESection) : 0;

  return getTeamZone(teamSlug, zoneInvetory.toLowerCase(), teZoneNo);
};

export const grabLowestPrice = (teListings, noOfSets, game, currencyRate) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        teamSlug === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : teamSlug === "vancouver-whitecaps-fc" ? teWhitecapsFcRowCheck(seatItem.row) 
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const findMore3Option = seatItem.teSeatSplit.find(
        (split) => split === 3
      )
        ? true
        : false;
      const findMore4Option = seatItem.teSeatSplit.find(
        (split) => split === 4
      )
        ? true
        : false;
      const isMore4 =
        Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
      const isSetsNumber = noOfSets > 2 ? isMore4 : true;
      const isExcludedSeat = excludeCheck(seatItem, teamSlug);
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        isSetsNumber &&
        seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= 8) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= 2) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= 2) {
        break;
      }
    }
  }
  return seatList;
};

export const grabLowestCanucksPrice = (teListings, noOfSets, game, currencyRate) => {
  let maxListing = 6;
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    let innerSeat = [];
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const isValidZone = zoneInvetory.includes("Club Lower") || zoneInvetory.includes("Lower Bowl") || zoneInvetory.includes("Upper Bowl");

      if (isValidZone) {
        const seatItem = {
          game: game.id,
          gameName: game.longName,
          gameDate: game.date,
          gameTimezone: game.timezone,
          isPlayoffs: game.isPlayoffs,
          price: Number(convertedPrice),
          zone: zoneInvetory,
          zoneNo: getSectionZone(seat.section, teamSlug),
          row: getRowSection(seat.row, teamSlug),
          noOfSeats: Number(seat.available_quantity),
          sellerEmail: "te@fansfirst.ca",
          sellOption: 1,
          isAisleSeat: false,
          tier: getTeamTier(teamSlug, zoneInvetory),
          seatsSold: 0,
          seatNumberEnd: 0,
          seatNumberStart: 0,
          quantityOptions: seat.splits,
          teSeatSplit: seat.splits,
          teListingId: seat.id,
          priceNoFee: convertedPriceNoFee,
        };
        const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
        const isRowNumber = !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
        const isZoneExist = seatItem.zone !== "";
        const isSeatsAvaiable = seatItem.teSeatSplit.find((split) => split === noOfSets) ? true : false;
        const isExcludedSeat = excludeCheck(seatItem, teamSlug);
        !isExcludedSeat && isZoneExist && isZoneNumber && isRowNumber && isSeatsAvaiable && seatList.push(seatItem);
        !isExcludedSeat && isZoneExist && isZoneNumber && isRowNumber && isSeatsAvaiable && innerSeat.push(seatItem);
        if (innerSeat.length == maxListing) {
          break;
        }
      }
    }
  }
  return seatList;
};

export const grabLowestPriceRaptorSpecial = (teListings, noOfSets, game, currencyRate) => {
  let seatList = [];
  let { homeTeamSlug: tempTeamSlug } = game;
  let teamSlug = tempTeamSlug === 'toronto-raptors' && game.isSpecial ? 'vancouver-canucks' : tempTeamSlug;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneToFF(seat, {...game, homeTeamSlug: teamSlug});
      const convertedPrice = convertPriceToCAD(seat, currencyRate, {...game, homeTeamSlug: teamSlug});
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, {...game, homeTeamSlug: teamSlug});
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        teamSlug === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const findMore3Option = seatItem.teSeatSplit.find(
        (split) => split === 3
      )
        ? true
        : false;
      const findMore4Option = seatItem.teSeatSplit.find(
        (split) => split === 4
      )
        ? true
        : false;
      const isMore4 =
        Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
      const isSetsNumber = noOfSets > 2 ? isMore4 : true;
      const isExcludedSeat = excludeCheck(seatItem, teamSlug);
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        isSetsNumber &&
        seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= 6) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= 2) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= 2) {
        break;
      }
    }
  }
  return seatList;
};

export const grabJaysNonNumberedRowsLowestPrice = (prevList, teListings, noOfSets, game, currencyRate, size=1) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const trimTESection =
        seat.section.length > 3 ? seat.section.slice(0, -1) : seat.section;

      const teSection = !isNaN(Number(trimTESection))
        ? Number(trimTESection)
        : 0;

      const zoneInvetory = (seat.section).toUpperCase();
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        row: seat.row,
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = true;
      const isZoneExist = !seatItem.zone.includes("---");
      const findMore3Option = seatItem.teSeatSplit.find(
        (split) => split === 3
      )
        ? true
        : false;
      const findMore4Option = seatItem.teSeatSplit.find(
        (split) => split === 4
      )
        ? true
        : false;
      const isMore4 =
        Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
      const isSetsNumber = noOfSets > 2 ? isMore4 : true;
      const isExcludedSeat = excludeCheck(seatItem, teamSlug);
      const inPrevList = prevList.find((list) => {
        return (
          `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}${list.price}` ===
          `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}${seatItem.price}`
        );
      });

      !isExcludedSeat &&
      !inPrevList &&
      isZoneExist &&
      isZoneNumber &&
      isSetsNumber &&
      seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= size) {
        break;
      }
    }
  }
  return seatList;
};

export const grabJaysRowsLowestPrice = (prevList, teListings, noOfSets, game, currencyRate, size=1) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const trimTESection =
        seat.section.length > 3 ? seat.section.slice(0, -1) : seat.section;

      const teSection = !isNaN(Number(trimTESection))
        ? Number(trimTESection)
        : 0;
      const jaysTeSection = seat.tevo_section_name.split(" ").pop();
      const jaysTeSectionChar =
        jaysTeSection.length === 4 ? seat.section.slice(-1) : "";
      
      const zoneInvetory = `${formatJaysTEZoneToFF(seat, game)}${
        jaysTeSectionChar ? " " : ""
      }${jaysTeSectionChar}`;
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: teSection,
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = true;
        const isRowNumber =
          teamSlug === "ottawa-senators"
            ? teSenatorRowCheck(seatItem.row)
            : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
        const isZoneExist = !seatItem.zone.includes("---");
        const findMore3Option = seatItem.teSeatSplit.find(
          (split) => split === 3
        )
          ? true
          : false;
        const findMore4Option = seatItem.teSeatSplit.find(
          (split) => split === 4
        )
          ? true
          : false;
        const isMore4 =
          Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
        const isSetsNumber = noOfSets > 2 ? isMore4 : true;
        const isExcludedSeat = excludeCheck(seatItem, teamSlug);
        const inPrevList = prevList.find((list) => {
          return (
            `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}${list.price}` ===
            `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}${seatItem.price}`
          );
        });

        !isExcludedSeat &&
        !inPrevList &&
          isZoneExist &&
          isZoneNumber &&
          isRowNumber &&
          isSetsNumber &&
          seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= size) {
        break;
      }
    }
  }
  return seatList;
};

export const grabLowestPriceLowerBowl = (teListings, noOfSets, game, currencyRate, prevList) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        teamSlug === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const isMore4 = noOfSets >= 4 ? seatItem.teSeatSplit.find((set) => set >= 4) : seatItem.teSeatSplit.includes(noOfSets);
      const isSetsNumber = noOfSets > 2 ? isMore4 : true;
      const isPrevItemExist = Array.isArray(prevList) && prevList.find(
        (seat) =>
          seat.row === seatItem.row &&
          seat.zoneNo === seatItem.zoneNo &&
          seat.noOfSeats === seatItem.noOfSeats
      );
      const isExcludedSeat = excludeCheck(seatItem, teamSlug);
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        isSetsNumber &&
        !isPrevItemExist &&
        seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= 3) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= 1) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= 1) {
        break;
      }
    }
  }
  return seatList;
};

export const grabLowestPriceLowerBowlRaptorsSpecial = (teListings, noOfSets, game, currencyRate, prevList) => {
  let seatList = [];
  let { homeTeamSlug: tempTeamSlug } = game;
  let teamSlug = tempTeamSlug === 'toronto-raptors' && game.isSpecial ? 'vancouver-canucks' : tempTeamSlug;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneToFF(seat, {...game, homeTeamSlug: teamSlug});
      const convertedPrice = convertPriceToCAD(seat, currencyRate, {...game, homeTeamSlug: teamSlug});
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, {...game, homeTeamSlug: teamSlug});
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        teamSlug === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const isMore4 = noOfSets >= 4 ? seatItem.teSeatSplit.find((set) => set >= 4) : seatItem.teSeatSplit.includes(noOfSets);
      const isSetsNumber = noOfSets > 2 ? isMore4 : true;
      const isPrevItemExist = Array.isArray(prevList) && prevList.find(
        (seat) =>
          seat.row === seatItem.row &&
          seat.zoneNo === seatItem.zoneNo &&
          seat.noOfSeats === seatItem.noOfSeats
      );
      const isExcludedSeat = excludeCheck(seatItem, teamSlug);
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        isSetsNumber &&
        !isPrevItemExist &&
        seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= 6) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= 2) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= 2) {
        break;
      }
    }
  }
  return seatList;
};

export const grabLowestPriceLeafsSection = (teListings, noOfSets, game, currencyRate, prevList, includeList) => {
  let seatList = [];
  let filteredTEListings = Array.isArray(teListings) ? teListings.filter((list) => leafsSectioCheck(list.section, includeList)) : [];
  let { homeTeamSlug: teamSlug } = game;
  if (filteredTEListings.length > 0) {
    for (let i = 0; i < filteredTEListings.length; i++) {
      const seat = filteredTEListings[i];
      const zoneInvetory = formatTEZoneToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        teamSlug === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        seatList.push(seatItem);

      if (seatList.length >= 5) {
        break;
      }
    }
  }
  return seatList;
};

export const generalTENHLTeams = (game, teListings, currencyRate, seats) => {
  const filteredTEListings = (game.homeTeamSlug === 'toronto-maple-leafs') ? teListings.filter((list) => {
    const numberSection = !isNaN(Number(list.section)) ? Number(list.section) : 0;
    return numberSection > 199;
  }) : teListings;
  const pairSeatLowest = grabLowestPrice(filteredTEListings, 2, game, currencyRate);
  const threeSeatLowest = grabLowestPrice(filteredTEListings, 3, game, currencyRate);
  const threeItem =
    threeSeatLowest.length > 0
      ? threeSeatLowest[0]
      : { row: 0, zoneNo: 0, noOfSeats: 0 };
  const isThreeItemExist = pairSeatLowest.find(
    (seat) =>
      seat.row === threeItem.row &&
      seat.zoneNo === threeItem.zoneNo &&
      seat.noOfSeats === threeItem.noOfSeats
  );
  const threeItemList = isThreeItemExist ? [] : threeSeatLowest;
  const fourSeatLowest = grabLowestPrice(filteredTEListings, 4, game, currencyRate);
  const fourItem =
    fourSeatLowest.length > 0
      ? fourSeatLowest[0]
      : { row: 0, zoneNo: 0, noOfSeats: 0 };
  const isFourItemExist = pairSeatLowest.find(
    (seat) =>
      seat.row === fourItem.row &&
      seat.zoneNo === fourItem.zoneNo &&
      seat.noOfSeats === fourItem.noOfSeats
  );
  const fourItemList = isFourItemExist ? [] : fourSeatLowest;
  const seatsMerged = [
    ...pairSeatLowest,
    ...threeItemList,
    ...fourItemList,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [...pairSeatLowest, ...threeItemList, ...fourItemList],
  };
}
export const canucksNHLTE = (game, teListings, currencyRate, seats) => {
  const filteredTEListings = teListings;
  const lowerSectionData = [];
  for (let index = 103; index < 123; index++) {
    lowerSectionData.push(index);
  }
  const getOnlyLowest2 = grabLowestCanucksPrice(filteredTEListings, 2, game, currencyRate);
  const getOnlyLowest3 = grabLowestCanucksPrice(filteredTEListings, 3, game, currencyRate);
  const getOnlyLowest4 = grabLowestCanucksPrice(filteredTEListings, 4, game, currencyRate);
  const getLowestFromUpper = grabNHLTeamsLowestPriceMore2Set({
    teListings: filteredTEListings,
    noOfSets: 2,
    game,
    currencyRate,
    prevList: [...getOnlyLowest2],
    reqSec: [307, 308, 309, 322, 323, 324],
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });
  const getLowestFromLower = grabNHLTeamsLowestPriceMore2Set({
    teListings: filteredTEListings,
    noOfSets: 2,
    game,
    currencyRate,
    prevList: [...getOnlyLowest2],
    reqSec: lowerSectionData,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });
  
  const seatsMerged = [
    ...getOnlyLowest2,
    ...getOnlyLowest3,
    ...getOnlyLowest4,
    ...getLowestFromUpper,
    ...getLowestFromLower,
  ];
  const uniqSeats = [...new Set(seatsMerged)];

  return {
    seatsMerged: uniqSeats,
    normalizedTESeats: [...uniqSeats],
  };
}

export const grabNHLTeamLowestPrice = (propsParam) => {
  let {
    teListings,
    noOfSets,
    game,
    currencyRate,
    prevList,
    reqSec = [],
    reqRow = [],
    pairType = "",
    maxCount = 20,
    seatCount,
  } = propsParam;
    let seatList = [];
    let { homeTeamSlug: teamSlug } = game;
    if (teListings.length > 0) {
      for (let i = 0; i < teListings.length; i++) {
        const seat = teListings[i];
        const zoneInvetory = formatTEZoneToFF(seat, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const seatItem = {
          game: game.id,
          gameName: game.longName,
          gameDate: game.date,
          gameTimezone: game.timezone,
          isPlayoffs: game.isPlayoffs,
          price: Number(convertedPrice),
          zone: zoneInvetory,
          zoneNo: getSectionZone(seat.section, teamSlug),
          row: getRowSection(seat.row, teamSlug),
          noOfSeats: Number(seat.available_quantity),
          sellerEmail: "te@fansfirst.ca",
          sellOption: 1,
          isAisleSeat: false,
          tier: getTeamTier(teamSlug, zoneInvetory),
          seatsSold: 0,
          seatNumberEnd: 0,
          seatNumberStart: 0,
          quantityOptions: seat.splits,
          teSeatSplit: seat.splits,
          teListingId: seat.id,
          priceNoFee: convertedPriceNoFee,
        };
        const isZoneNumber = zoneInvetory !== "";
        const isZoneExist = true;
        const findMore3Option = seatItem.teSeatSplit.find(
          (split) => split === 3
        )
          ? true
          : false;
        const findMore4Option = seatItem.teSeatSplit.find(
          (split) => split >= 4
        )
          ? true
          : false;
        const findMore5Option = seatItem.teSeatSplit.find(
          (split) => split === 5
        )
          ? true
          : false;
        const isMore4 =
          Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
        const isMore5 =
          Number(noOfSets) === 5 ? findMore5Option : isMore4;
          const isPrevItemExist = prevList.find((list) => {
            return (
              `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}` ===
              `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}`
            );
          });
        const isSectionInclude = reqSec.length > 0 ? reqSec.find((sec) => {
          return String(sec) === String(seatItem.zoneNo);
        }) : true;
  
        const isRowInclude = reqRow.length > 0 ? reqRow.find((row) => {
          return String(row) === String(seatItem.row);
        }) : true;

        const isExcludedSeat = excludeCheck(seatItem, teamSlug);
  
        const isSetsNumber = noOfSets > 2 ? isMore5 : true;
          !isExcludedSeat &&
          isZoneExist &&
          isZoneNumber &&
          isSectionInclude &&
          isRowInclude &&
          !isPrevItemExist &&
          isSetsNumber &&
          seatList.push(seatItem);
  
        if (Number(noOfSets) === 2 && seatList.length >= seatCount) {
          break;
        }
        if (Number(noOfSets) === 3 && seatList.length >= seatCount) {
          break;
        }
        if (Number(noOfSets) === 4 && seatList.length >= seatCount) {
          break;
        }
        if (Number(noOfSets) === 5 && seatList.length >= seatCount) {
          break;
        }
      }
    }
    return seatList;
  };

  export const grabNHLTeamsLowestPriceMore2Set = (propsParam) => {
    let {
      teListings,
      noOfSets,
      game,
      currencyRate,
      prevList,
      reqSec = [],
      reqRow = [],
      pairType = "",
      maxCount = 20,
      seatCount,
    } = propsParam;
      let seatList = [];
      let { homeTeamSlug: teamSlug } = game;
      if (teListings.length > 0) {
        for (let i = 0; i < teListings.length; i++) {
          const seat = teListings[i];
          const zoneInvetory = formatTEZoneToFF(seat, game);
          const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
          const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
          const seatItem = {
            game: game.id,
            gameName: game.longName,
            gameDate: game.date,
            gameTimezone: game.timezone,
            isPlayoffs: game.isPlayoffs,
            price: Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, teamSlug),
            row: getRowSection(seat.row, teamSlug),
            noOfSeats: Number(seat.available_quantity),
            sellerEmail: "te@fansfirst.ca",
            sellOption: 1,
            isAisleSeat: false,
            tier: getTeamTier(teamSlug, zoneInvetory),
            seatsSold: 0,
            seatNumberEnd: 0,
            seatNumberStart: 0,
            quantityOptions: seat.splits,
            teSeatSplit: seat.splits,
            teListingId: seat.id,
            priceNoFee: convertedPriceNoFee,
          };
          const isZoneNumber = zoneInvetory !== "";
          const isZoneExist = true;
          const findMore3Option = seatItem.teSeatSplit.find(
            (split) => split >= 2
          )
            ? true
            : false;
            const isPrevItemExist = prevList.find((list) => {
              return (
                `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}` ===
                `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}`
              );
            });
          const isSectionInclude = reqSec.length > 0 ? reqSec.find((sec) => {
            return String(sec) === String(seatItem.zoneNo);
          }) : true;
    
          const isRowInclude = reqRow.length > 0 ? reqRow.find((row) => {
            return String(row) === String(seatItem.row);
          }) : true;

          const isExcludedSeat = excludeCheck(seatItem, teamSlug);
    
          const isSetsNumber = findMore3Option;
            !isExcludedSeat &&
            isZoneExist &&
            isZoneNumber &&
            isSectionInclude &&
            isRowInclude &&
            !isPrevItemExist &&
            isSetsNumber &&
            seatList.push(seatItem);

          if (seatList.length >= seatCount) {
            break;
          }
        }
      }
      return seatList;
    };

export const senatorsNHLTE = (game, teListings, currencyRate, seats) => {
  const filteredTEListings = teListings;
  const more2SeatLower = grabNHLTeamsLowestPriceMore2Set({
    teListings: filteredTEListings,
    noOfSets: 2,
    game,
    currencyRate,
    prevList: [],
    reqSec: [104, 105, 106, 107, 108, 114, 115, 116, 117, 118],
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 2,
  });
  const more2SeatMiddle = grabNHLTeamsLowestPriceMore2Set({
    teListings: filteredTEListings,
    noOfSets: 2,
    game,
    currencyRate,
    prevList: [],
    reqSec: [206, 207, 208, 209 ,210, 220, 221, 222, 223, 224],
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 2,
  });
  const more2SeatUpper = grabNHLTeamsLowestPriceMore2Set({
    teListings: filteredTEListings,
    noOfSets: 2,
    game,
    currencyRate,
    prevList: [],
    reqSec: [306,307,308, 309, 310, 320, 321, 322, 323, 324],
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 2,
  });
  const more2SeatsAllZone = [...more2SeatLower, ...more2SeatUpper, ...more2SeatMiddle];
  const upperZoneList = [];
  for (let index = 301; index < 329; index++) {
    upperZoneList.push(index);
  }
  const lowerZoneList = [];
  for (let index = 101; index < 121; index++) {
    lowerZoneList.push(index);
  }
  const pair2Upper = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 2,
    game,
    currencyRate,
    prevList: [...more2SeatsAllZone],
    reqSec: upperZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 2,
  });
  const set3count2Upper = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 3,
    game,
    currencyRate,
    prevList: [...more2SeatsAllZone, ...pair2Upper],
    reqSec: upperZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 2,
  });
  const set4count2Upper = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 4,
    game,
    currencyRate,
    prevList: [...more2SeatsAllZone, ...pair2Upper, ...set3count2Upper],
    reqSec: upperZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 2,
  });
  const count2AllSetUpper = [...pair2Upper, ...set3count2Upper, ...set4count2Upper]
  const set2count2Lower = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 2,
    game,
    currencyRate,
    prevList: [...more2SeatsAllZone],
    reqSec: lowerZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 2,
  });
  const set3count1Lower = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 3,
    game,
    currencyRate,
    prevList: [...more2SeatsAllZone, ...set2count2Lower],
    reqSec: lowerZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });
  const set4count1Lower = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 4,
    game,
    currencyRate,
    prevList: [...more2SeatsAllZone, ...set2count2Lower, ...set3count1Lower],
    reqSec: lowerZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });

  const count2AllSetLower = [...set2count2Lower, ...set3count1Lower, ...set4count1Lower];
  
  const seatsMerged = [
    ...more2SeatsAllZone,
    ...count2AllSetUpper,
    ...count2AllSetLower,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [...more2SeatsAllZone, ...count2AllSetUpper, ...count2AllSetLower],
  };
}

export const flamesNHLTE = (game, teListings, currencyRate, seats) => {
  const filteredTEListings = teListings;
  const sectionData = [];
  for (let index = 101; index < 123; index++) {
    sectionData.push(index);
  }
  const set3SeatLower = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 3,
    game,
    currencyRate,
    prevList: [],
    reqSec: sectionData,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });
  const set4SeatLower = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 4,
    game,
    currencyRate,
    prevList: [...set3SeatLower],
    reqSec: sectionData,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });

  const seatsMerged = [
    ...set3SeatLower,
    ...set4SeatLower,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [
      ...set3SeatLower,
      ...set4SeatLower,
    ],
  };
}

export const jetsNHLTE = (game, teListings, currencyRate, seats) => {
  const lowestPrices = generalTENHLTeams(game, teListings, currencyRate, seats);
  const filteredTEListings = teListings;

  const lowerZoneList = [];
  for (let index = 101; index < 128; index++) {
    lowerZoneList.push(index);
  }
  
  const set3count1Lower = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 3,
    game,
    currencyRate,
    prevList: [...lowestPrices.seatsMerged],
    reqSec: lowerZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });
  const set4count1Lower = grabNHLTeamLowestPrice({
    teListings: filteredTEListings,
    noOfSets: 4,
    game,
    currencyRate,
    prevList: [...lowestPrices.seatsMerged, ...set3count1Lower],
    reqSec: lowerZoneList,
    reqRow:[],
    pairType: "",
    maxCount: 16,
    seatCount: 1,
  });

  const count2AllSetLower = [...set3count1Lower, ...set4count1Lower];
  
  const seatsMerged = [
    ...count2AllSetLower,
    ...lowestPrices.seatsMerged,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [...lowestPrices.normalizedTESeats, ...count2AllSetLower],
  };
}

export const composeTElist = (game, teListings, currencyRate, seats) => {
  switch (game.homeTeamSlug) {
    case "ottawa-senators": {
      return senatorsNHLTE(game, teListings, currencyRate, seats);
    }
    case "vancouver-canucks": {
      return canucksNHLTE(game, teListings, currencyRate, seats);
    }
    case "calgary-flames": {
      return flamesNHLTE(game, teListings, currencyRate, seats);
    }
    case "winnipeg-jets": {
      return jetsNHLTE(game, teListings, currencyRate, seats);
    }
  
    default: {
      return generalTENHLTeams(game, teListings, currencyRate, seats);
    }
  }
};

export const composeTElistRaptorsSpecial = (game, teListings, currencyRate, seats) => {
  const filteredTEListings = game.homeTeamSlug === 'toronto-maple-leafs' ? teListings.filter((list) => {
    const numberSection = !isNaN(Number(list.section)) ? Number(list.section) : 0;
    return numberSection > 199;
  }) : teListings;
  const pairSeatLowest = grabLowestPriceRaptorSpecial(filteredTEListings, 2, game, currencyRate);
  const threeSeatLowest = grabLowestPriceRaptorSpecial(filteredTEListings, 3, game, currencyRate);
  const threeItem =
    threeSeatLowest.length > 0
      ? threeSeatLowest[0]
      : { row: 0, zoneNo: 0, noOfSeats: 0 };
  const isThreeItemExist = pairSeatLowest.find(
    (seat) =>
      seat.row === threeItem.row &&
      seat.zoneNo === threeItem.zoneNo &&
      seat.noOfSeats === threeItem.noOfSeats
  );
  const threeItemList = isThreeItemExist ? [] : threeSeatLowest;
  const fourSeatLowest = grabLowestPriceRaptorSpecial(filteredTEListings, 4, game, currencyRate);
  const fourItem =
    fourSeatLowest.length > 0
      ? fourSeatLowest[0]
      : { row: 0, zoneNo: 0, noOfSeats: 0 };
  const isFourItemExist = pairSeatLowest.find(
    (seat) =>
      seat.row === fourItem.row &&
      seat.zoneNo === fourItem.zoneNo &&
      seat.noOfSeats === fourItem.noOfSeats
  );
  const fourItemList = isFourItemExist ? [] : fourSeatLowest;
  const seatsMerged = [
    ...pairSeatLowest,
    ...threeItemList,
    ...fourItemList,
  ];
  const lowerBowlListTE = Array.isArray(teListings) ? teListings.filter((list) => leafsRaptorsToIncludeclude(list.section)) : [];
  const lowerBowlSpecial = grabLowestPriceLowerBowlRaptorsSpecial(lowerBowlListTE, 2, game, currencyRate, [...seatsMerged])
  const lowerBowlSpecial3 = grabLowestPriceLowerBowlRaptorsSpecial(lowerBowlListTE, 3, game, currencyRate, [...seatsMerged, ...lowerBowlSpecial])
  const lowerBowlSpecial4 = grabLowestPriceLowerBowlRaptorsSpecial(lowerBowlListTE, 4, game, currencyRate, [...seatsMerged, ...lowerBowlSpecial, ...lowerBowlSpecial3])

  return {
    seatsMerged: [...seatsMerged, ...lowerBowlSpecial, ...lowerBowlSpecial3, ...lowerBowlSpecial4],
    normalizedTESeats: [...pairSeatLowest, ...threeItemList, ...fourItemList],
  };
};

export const grabCFLTeamsLowestPrice = (
  teListings,
  noOfSets,
  game,
  currencyRate,
  prevList,
  reqSec = [],
  reqRow = [],
  pairType = "",
  maxCount = 20,
  maxLength = 1
) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneCFLToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = game.isSpecial ? true : zoneInvetory !== "";
      const isZoneExist = true;
      const findMore3Option = seatItem.teSeatSplit.find((split) => split === 3)
        ? true
        : false;
      const findMore4Option = seatItem.teSeatSplit.find((split) => split === 4)
        ? true
        : false;
      const findMore5Option = seatItem.teSeatSplit.find((split) => split === 5)
        ? true
        : false;
      const isMore4 =
        Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
      const isMore5 = Number(noOfSets) === 5 ? findMore5Option : isMore4;
      const isPrevItemExist = prevList.find((list) => {
        return (
          `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}` ===
          `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}`
        );
      });
      const isSectionInclude =
        reqSec.length > 0
          ? reqSec.find((sec) => {
              return String(sec) === String(seatItem.zoneNo);
            })
          : true;

      const isRowInclude =
        reqRow.length > 0
          ? reqRow.find((row) => {
              return String(row) === String(seatItem.row);
            })
          : true;

      const isSetsNumber = noOfSets > 2 ? isMore5 : true;
      isZoneExist &&
        isZoneNumber &&
        isSectionInclude &&
        isRowInclude &&
        !isPrevItemExist &&
        isSetsNumber &&
        seatList.push(seatItem);

      const pairMax =
        pairType === "final" && prevList.length < maxCount
          ? maxCount - prevList.length
          : 4;

      if (Number(noOfSets) === 2 && seatList.length >= pairMax) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= maxLength) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= maxLength) {
        break;
      }
      if (Number(noOfSets) === 5 && seatList.length >= maxLength) {
        break;
      }
    }
  }
  return seatList;
};

export const grabMLSTeamsLowestPrice = (
  teListings,
  noOfSets,
  game,
  currencyRate,
  prevList,
  reqSec = [],
  reqRow = [],
  pairType = "",
  maxCount = Array.isArray(teListings) && teListings.length > 0 ? teListings.length : 20,
  maxLength = 1
) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneMLSToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(
        seat,
        currencyRate,
        game
      );
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = zoneInvetory !== "";
      const isZoneExist = true;
      const findMore3Option = seatItem.teSeatSplit.find((split) => split === 3)
        ? true
        : false;
      const findMore4Option = seatItem.teSeatSplit.find((split) => split === 4)
        ? true
        : false;
      const findMore5Option = seatItem.teSeatSplit.find((split) => split === 5)
        ? true
        : false;
      const isMore4 =
        Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
      const isMore5 = Number(noOfSets) === 5 ? findMore5Option : isMore4;
      const isPrevItemExist = prevList.find((list) => {
        return (
          `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}` ===
          `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}`
        );
      });
      const isSectionInclude =
        reqSec.length > 0
          ? reqSec.find((sec) => {
              return String(sec) === String(seatItem.zoneNo);
            })
          : true;

      const isRowInclude =
        reqRow.length > 0
          ? reqRow.find((row) => {
              return String(row) === String(seatItem.row);
            })
          : true;

      const isSetsNumber = noOfSets > 2 ? isMore5 : true;
      isZoneExist &&
        isZoneNumber &&
        isSectionInclude &&
        isRowInclude &&
        !isPrevItemExist &&
        isSetsNumber &&
        seatList.push(seatItem);

      const pairMax =
        pairType === "final" && prevList.length < maxCount
          ? maxCount - prevList.length
          : 4;

      if (Number(noOfSets) === 2 && seatList.length >= pairMax) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= maxLength) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= maxLength) {
        break;
      }
      if (Number(noOfSets) === 5 && seatList.length >= maxLength) {
        break;
      }
    }
  }
  return seatList;
};

export const grabDefaultCFLLowestPrice = (teListings, noOfSets, game, currencyRate) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatTEZoneCFLToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = zoneInvetory !== "";
      const isZoneExist = true;
      const findMore3Option = seatItem.teSeatSplit.find(
        (split) => split === 3
      )
        ? true
        : false;
      const findMore4Option = seatItem.teSeatSplit.find(
        (split) => split === 4
      )
        ? true
        : false;
      const isMore4 =
        Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
      const isSetsNumber = noOfSets > 2 ? isMore4 : true;
        isZoneExist &&
        isZoneNumber &&
        isSetsNumber &&
        seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= 6) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= 2) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= 2) {
        break;
      }
    }
  }
  return seatList;
};

export const getFormattedCFL = (game, teListings, currencyRate) => {
  if(game.isSpecial){
    switch (game.homeTeamSlug) {
      case "bc-lions": {
        const filteredTEListings = teListings;
        const maxCount = filteredTEListings.length;
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final', maxCount);
       
        return [
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
    } 
  }else{
    switch (game.homeTeamSlug) {
      case "calgary-stampeders": {
        const filteredTEListings = teListings;
        const reqSec = ['E', 'F', 'G', 'R', 'S', 'T'];
        const reqRow = [1,2,3,4,5,6];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
  
        const threereqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
        const fourreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
        const pairreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest,], reqSec, []);
        const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
  
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
        
  
        return [
          ...reqRowList,
          ...reqSecList,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "edmonton-elks": {
        const filteredTEListings = teListings;
        const reqSec = ['E', 'F', 'G', 'H', 'I', 'R', 'S', 'T', 'U', 'V'];
        const reqRow = [1,2,3,4,5,6];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
        
        const threereqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
        const fourreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
        const pairreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest,], reqSec, []);
        const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
        
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
        
  
        return [
          ...reqRowList,
          ...reqSecList,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "ottawa-redblacks": {
        const filteredTEListings = teListings;
        const reqSec = ['D', 'E', 'F', 'R', 'S', 'T'];
        const reqRow = [1,2,3,4,5,6];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
        
        const threereqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
        const fourreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
        const pairreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest,], reqSec, []);
        const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
        
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
        
  
        return [
          ...reqRowList,
          ...reqSecList,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "toronto-argonauts": {
        const filteredTEListings = teListings;
        const reqSec = [106, 107, 108, 109, 121, 122, 123, 124];
        const reqRow = [1,2,3,4,5,6];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
        
        const threereqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
        const fourreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
        const pairreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest,], reqSec, []);
        const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
        
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
        
  
        return [
          ...reqRowList,
          ...reqSecList,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "hamilton-tigercats": {
        const filteredTEListings = teListings;
        const reqSec = [103,104,105,106, 107,113,114,115,116,117];
        const reqRow = [1,2,3,4,5,6];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
        
        const threereqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
        const fourreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
        const pairreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest,], reqSec, []);
        const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
        
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
        
  
        return [
          ...reqRowList,
          ...reqSecList,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "bc-lions": {
        const filteredTEListings = teListings;
        const reqSec = [212, 213, 214, 215, 239, 240, 241, 242];
        const reqRow = [1,2,3,4,5,6];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
        
        const threereqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
        const fourreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
        const pairreqSecSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest,], reqSec, []);
        const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
        
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
        
  
        return [
          ...reqRowList,
          ...reqSecList,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "winnipeg-blue-bombers": {
        const filteredTEListings = teListings;
        const reqSec1 = [207, 208, 209];
        const reqSec2 = [105, 106, 107, 108, 109, 110, 127, 128, 129, 130, 131];
        const reqSec3 = [223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234]
        const reqRow = [];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow, '', 20, 2);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow, '', 20, 2);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow, '', 20, 2);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
  
        const threereqSec1SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec1, [], '', 20, 2);
        const fourreqSec1SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSec1SeatLowest], reqSec1, [], '', 20, 2);
        const pairreqSec1SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSec1SeatLowest, ...fourreqSec1SeatLowest,], reqSec1, [], '', 20, 2);
        const reqSec1List = [...threereqSec1SeatLowest, ...fourreqSec1SeatLowest, ...pairreqSec1SeatLowest,];
        
        const threereqSec3SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec3, '', [], 20, 4);
        const fourreqSec3SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSec3SeatLowest], reqSec3, [], '', 20, 4);
        const pairreqSec3SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSec3SeatLowest, ...fourreqSec3SeatLowest,], reqSec3, [], '', 20, 4);
        const reqSec3List = [...threereqSec3SeatLowest, ...fourreqSec3SeatLowest, ...pairreqSec3SeatLowest,];
  
        const threereqSec2SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec2, [], '', 20, 2);
        const fourreqSec2SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSec2SeatLowest], reqSec2, [], '', 20, 2);
        const pairreqSec2SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSec2SeatLowest, ...fourreqSec2SeatLowest,], reqSec2, [], '', 20, 2);
        const reqSec2List = [...threereqSec2SeatLowest, ...fourreqSec2SeatLowest, ...pairreqSec2SeatLowest,];
        
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqSec1List, ...reqSec2List, ...reqSec3List, ...reqRowList], [], '', 20, 2);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSec1List, ...reqSec2List, ...reqSec3List, ...reqRowList, ...threeSeatLowest], [], '', 20, 2);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSec1List, ...reqSec2List, ...reqSec3List, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], [], '', 20, 2);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSec1List, ...reqSec2List, ...reqSec3List, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final', 28);
        
        return [
          ...reqRowList,
          ...reqSec1List,
          ...reqSec2List,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "saskatchewan-roughriders": {
        const filteredTEListings = teListings;
        const reqSec1 = [312,313,314,315,316,317,237,238,239,240,241];
        const reqSec2 = [113,114,115,116,117,137,138,139,140];
        const reqRow = [1,2,3,4,5,6];
  
        const threereqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
        const fourreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
        const pairreqRowSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest,], [], reqRow);
        const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
  
        const threereqSec1SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec1, []);
        const fourreqSec1SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSec1SeatLowest], reqSec1, []);
        const pairreqSec1SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSec1SeatLowest, ...fourreqSec1SeatLowest,], reqSec1, []);
        const reqSec1List = [...threereqSec1SeatLowest, ...fourreqSec1SeatLowest, ...pairreqSec1SeatLowest,];
        
        const threereqSec2SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec2, []);
        const fourreqSec2SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSec2SeatLowest], reqSec2, []);
        const pairreqSec2SeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSec2SeatLowest, ...fourreqSec2SeatLowest,], reqSec2, []);
        const reqSec2List = [...threereqSec2SeatLowest, ...fourreqSec2SeatLowest, ...pairreqSec2SeatLowest,];
        
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSec1List, ...reqSec2List, ...reqRowList, ...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSec1List, ...reqSec2List, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSec1List, ...reqSec2List, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], '', 24);
  
        return [
          ...reqRowList,
          ...reqSec1List,
          ...reqSec2List,
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      case "grey-cup": {
        const filteredTEListings = teListings;
        const maxCount = filteredTEListings.length;
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threeSeatLowest], []);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest], []);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final', maxCount);
  
        return [
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
      default: {
        const filteredTEListings = teListings;
        const threeSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, []);
        const fourSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threeSeatLowest]);
        const fiveSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest]);
        const pairSeatLowest = grabCFLTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest]);
        return [
          ...threeSeatLowest,
          ...fourSeatLowest,
          ...fiveSeatLowest,
          ...pairSeatLowest,
        ];
      }
    } 
  }
}

export const getFormattedMLS = (game, teListings, currencyRate) => {
  switch (game.homeTeamSlug) {
    case "toronto-fc": {
      const filteredTEListings = teListings;
      const reqSec = [106, 107, 108, 109, 121, 122, 123, 124];
      const reqRow = [1,2,3,4,5,6];

      const threereqRowSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
      const fourreqRowSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest, ], [], reqRow);
      const pairreqRowSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest], [], reqRow);
      const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
      
      const threereqSecSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
      const fourreqSecSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
      const pairreqSecSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest], reqSec, []);
      const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
      
      const threeSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
      const fourSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
      const fiveSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
      const pairSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
      
      return [
        ...reqRowList,
        ...reqSecList,
        ...threeSeatLowest,
        ...fourSeatLowest,
        ...fiveSeatLowest,
        ...pairSeatLowest
      ];
    }
    case "vancouver-whitecaps-fc": {
      const filteredTEListings = teListings;
      const reqSec = [212, 213, 214, 215, 239, 240, 241, 242];
      const reqRow = [1,2,3,4];

      const threereqRowSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], [], reqRow);
      const fourreqRowSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threereqRowSeatLowest], [], reqRow);
      const pairreqRowSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threereqRowSeatLowest, ...fourreqRowSeatLowest], [], reqRow);
      const reqRowList = [...pairreqRowSeatLowest, ...fourreqRowSeatLowest, ...threereqRowSeatLowest];
      
      const threereqSecSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [...reqRowList], reqSec, []);
      const fourreqSecSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest], reqSec, []);
      const pairreqSecSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqRowList, ...threereqSecSeatLowest, ...fourreqSecSeatLowest], reqSec, []);
      const reqSecList = [...threereqSecSeatLowest, ...fourreqSecSeatLowest, ...pairreqSecSeatLowest,];
      
      const threeSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, [], []);
      const fourSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest], []);
      const fiveSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest], []);
      const pairSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...reqSecList, ...reqRowList, ...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest], [], [], 'final');
      
      return [
        ...reqRowList,
        ...reqSecList,
        ...threeSeatLowest,
        ...fourSeatLowest,
        ...fiveSeatLowest,
        ...pairSeatLowest
      ];
    }
    default: {
      const filteredTEListings = teListings;
      const threeSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 3, game, currencyRate, []);
      const fourSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 4, game, currencyRate, [...threeSeatLowest]);
      const fiveSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 5, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest]);
      const pairSeatLowest = grabMLSTeamsLowestPrice(filteredTEListings, 2, game, currencyRate, [...threeSeatLowest, ...fourSeatLowest, ...fiveSeatLowest]);
      return [
        ...threeSeatLowest,
        ...fourSeatLowest,
        ...fiveSeatLowest,
        ...pairSeatLowest
      ];
    }
  }
}

export const composeCFLTElist = (game, teListings, currencyRate, seats) => {
  const formatedCFL = getFormattedCFL(game, teListings, currencyRate, seats);
  const seatsMerged = [
    ...formatedCFL,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [
      ...formatedCFL,
    ],
  };
};

export const composeMLSTElist = (game, teListings, currencyRate, seats) => {
  const formatedMLS = getFormattedMLS(game, teListings, currencyRate, seats);
  const seatsMerged = [
    ...formatedMLS,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [
      ...formatedMLS,
    ],
  };
};

export const composeLowerBowlTElist = (game, teListings, currencyRate, seats) => {
  const lowerBowlListTE = Array.isArray(teListings) ? teListings.filter((list) => leafsRaptorsToIncludeclude(list.section)) : [];
  const additional1s = grabLowestPriceLeafsSection(teListings, 2, game, currencyRate, [], [107, 108, 109, 118, 119, 120]);
  const additional3s = grabLowestPriceLeafsSection(teListings, 2, game, currencyRate, [], [308, 309, 310, 320, 321, 322]);
  const additional13s = [...additional1s, ...additional3s];
  const pairSeatLowest = grabLowestPriceLowerBowl(lowerBowlListTE, 2, game, currencyRate, [...additional13s]);
  const threeSeatLowest = grabLowestPriceLowerBowl(lowerBowlListTE, 3, game, currencyRate, [...additional13s, ...pairSeatLowest]);
  const threeItemList = threeSeatLowest;
  const fourSeatLowest = grabLowestPriceLowerBowl(lowerBowlListTE, 4, game, currencyRate, [...additional13s, ...pairSeatLowest, ...threeSeatLowest]);
  
  const fourItemList = fourSeatLowest;
  const seatsMerged = [
    ...pairSeatLowest,
    ...threeItemList,
    ...fourItemList,
    ...additional13s,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [...pairSeatLowest, ...threeItemList, ...fourItemList, ...additional13s],
  };
};

export const composeLowerBowlRaptorsTElist = (game, teListings, currencyRate, seats) => {
  const lowerBowlListTE = Array.isArray(teListings) ? teListings.filter((list) => leafsRaptorsToIncludeclude(list.section)) : [];
  const additional1s = grabLowestPriceLeafsSection(teListings, 2, game, currencyRate, [], [107, 108, 109, 118, 119, 120]);
  const additional3s = grabLowestPriceLeafsSection(teListings, 2, game, currencyRate, [], [308, 309, 310, 320, 321, 322]);
  const suites200 = [];
  const suites400 = [];
  const suites500 = [];
  const suites600 = [];
  for (let index = 200; index < 265; index++) {
    suites200.push(index);
  }
  for (let index = 402; index < 409; index++) {
    suites400.push(index);
  }
  for (let index = 501; index < 520; index++) {
    suites500.push(index);
  }
  for (let index = 601; index < 622; index++) {
    suites600.push(index);
  }
  const additionalSuites = grabLowestPriceLeafsSection(teListings, 2, game, currencyRate, [], [...suites200, ...suites400, ...suites500, ...suites600]);
  const additional13s = [...additional1s, ...additional3s, ...additionalSuites];
  const pairSeatLowest = grabLowestPriceLowerBowl(lowerBowlListTE, 2, game, currencyRate, [...additional13s]);
  const threeSeatLowest = grabLowestPriceLowerBowl(lowerBowlListTE, 3, game, currencyRate, [...additional13s, ...pairSeatLowest]);
  const threeItemList = threeSeatLowest;
  const fourSeatLowest = grabLowestPriceLowerBowl(lowerBowlListTE, 4, game, currencyRate, [...additional13s, ...pairSeatLowest, ...threeSeatLowest]);
  
  const fourItemList = fourSeatLowest;
  const seatsMerged = [
    ...pairSeatLowest,
    ...threeItemList,
    ...fourItemList,
    ...additional13s,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [...pairSeatLowest, ...threeItemList, ...fourItemList, ...additional13s],
  };
};

export const grabJaysFrontRows = (prevList, game, teListings, currencyRate, sectionStart=100, sectionEnd=200) => {
  const row1SectionTEData = teListings.filter((seat) => {
    const sectionNumber = String(seat.section).length > 3 ? String(seat.section).slice(0, -1) : String(seat.section);
    const numberSection = !isNaN(Number(sectionNumber)) ? Number(sectionNumber) : 0;
    const numberRow = !isNaN(Number(seat.row)) ? Number(seat.row) : 0;
    return (numberRow === 1) && (numberSection > sectionStart && numberSection < sectionEnd);
  });
  const row2SectionTEData = teListings.filter((seat) => {
    const sectionNumber = String(seat.section).length > 3 ? String(seat.section).slice(0, -1) : String(seat.section);
    const numberSection = !isNaN(Number(sectionNumber)) ? Number(sectionNumber) : 0;
    const numberRow = !isNaN(Number(seat.row)) ? Number(seat.row) : 0;
    return (numberRow === 2) && (numberSection > sectionStart && numberSection < sectionEnd);
  });
  const row3SectionTEData = teListings.filter((seat) => {
    const sectionNumber = String(seat.section).length > 3 ? String(seat.section).slice(0, -1) : String(seat.section);
    const numberSection = !isNaN(Number(sectionNumber)) ? Number(sectionNumber) : 0;
    const numberRow = !isNaN(Number(seat.row)) ? Number(seat.row) : 0;
    return (numberRow > 2 && numberRow < 10) && (numberSection > sectionStart && numberSection < sectionEnd);
  });  
  const lowestRow1Sections = grabJaysRowsLowestPrice(
    prevList,
    row1SectionTEData,
    2,
    game,
    currencyRate
  );
  const lowestRow2Sections = grabJaysRowsLowestPrice(
    prevList,
    row2SectionTEData,
    2,
    game,
    currencyRate
  );
  const lowestRow3Sections = grabJaysRowsLowestPrice(
    prevList,
    row3SectionTEData,
    2,
    game,
    currencyRate
  );
  return [...lowestRow1Sections, ...lowestRow2Sections, ...lowestRow3Sections]
}

export const grabJaysNonNumberedSectionFrontRows = (prevList, game, teListings, currencyRate) => {
  const nonNumberedSectionTEData = teListings.filter((seat) => {
    const customSectionName = ['westjetflightdeck'];
    const sectionName = (seat.section).replace(/[^a-zA-Z]+/g, '').toLowerCase();
    return (customSectionName.includes(sectionName));
  });
  
  const lowestNonNumberedSections = grabJaysNonNumberedRowsLowestPrice(
    prevList,
    nonNumberedSectionTEData,
    2,
    game,
    currencyRate
  );

  return [...lowestNonNumberedSections]
}

export const grabJaysPremiumSection = (game, teListings, currencyRate, sectionStart=100, sectionEnd=200, size) => {
  const premiumSectionList = teListings.filter((seat) => {
    const sectionNumber = String(seat.section).length > 3 ? String(seat.section).slice(0, -1) : String(seat.section);
    const numberSection = !isNaN(Number(sectionNumber)) ? Number(sectionNumber) : 0;
    return numberSection > sectionStart && numberSection < sectionEnd;
  });
  
  const lowestPremiumSections = grabJaysRowsLowestPrice(
    [],
    premiumSectionList,
    2,
    game,
    currencyRate,
    size
  );
  return [...lowestPremiumSections]
}

export const composeBlueJaysTElist = (
  game,
  teListings,
  currencyRate,
  seats
) => {
  const allSeat = grabJaysPremiumSection(game, teListings, currencyRate, 0, 600, 1000);
  const frontRowsAll = [
    ...allSeat,
  ];
  const seatsMerged = [
    ...frontRowsAll,
  ];
  return {
    seatsMerged,
    normalizedTESeats: [
      ...frontRowsAll,
    ],
  };
};

export const grabLowestPriceBlueJays = (
  prevList,
  teListings,
  noOfSets,
  game,
  currencyRate,
  sectionType
) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const trimTESection =
        seat.section.length > 3 ? seat.section.slice(0, -1) : seat.section;

      const teSection = !isNaN(Number(trimTESection))
        ? Number(trimTESection)
        : 0;
      const grabLowerZone = teSection > 100 && teSection < 143;
      const grabMiddleZone = teSection > 203 && teSection < 244;
      const grabUpperZone = teSection > 507 && teSection < 541;
      const jaysSectionType = {
        low: grabLowerZone,
        mid: grabMiddleZone,
        up: grabUpperZone,
      };

      const jaysTeSection = seat.tevo_section_name.split(" ").pop();
      const jaysTeSectionChar =
        jaysTeSection.length === 4 ? seat.section.slice(-1) : "";
      if (jaysSectionType[sectionType] && jaysTeSectionChar !== "R") {
        const zoneInvetory = `${formatJaysTEZoneToFF(seat, game)}${
          jaysTeSectionChar ? " " : ""
        }${jaysTeSectionChar}`;
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const seatItem = {
          game: game.id,
          gameName: game.longName,
          gameDate: game.date,
          gameTimezone: game.timezone,
          isPlayoffs: game.isPlayoffs,
          price: Number(convertedPrice),
          zone: zoneInvetory,
          zoneNo: teSection,
          row: getRowSection(seat.row, teamSlug),
          noOfSeats: Number(seat.available_quantity),
          sellerEmail: "te@fansfirst.ca",
          sellOption: 1,
          isAisleSeat: false,
          tier: getTeamTier(teamSlug, zoneInvetory),
          seatsSold: 0,
          seatNumberEnd: 0,
          seatNumberStart: 0,
          quantityOptions: seat.splits,
          teSeatSplit: seat.splits,
          teListingId: seat.id,
          priceNoFee: convertedPriceNoFee,
        };
        const isZoneNumber = true;
        const isRowNumber =
          teamSlug === "ottawa-senators"
            ? teSenatorRowCheck(seatItem.row)
            : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
        const isZoneExist = seatItem.zone !== "---";
        const findMore3Option = seatItem.teSeatSplit.find(
          (split) => split === 3
        )
          ? true
          : false;
        const findMore4Option = seatItem.teSeatSplit.find(
          (split) => split === 4
        )
          ? true
          : false;
        const isMore4 =
          Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
        const isSetsNumber = noOfSets > 2 ? isMore4 : true;
        const isExcludedSeat = excludeCheck(seatItem, teamSlug);
        const inPrevList = prevList.find((list) => {
          return (
            `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}${list.price}` ===
            `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}${seatItem.price}`
          );
        });

        !isExcludedSeat &&
          !inPrevList &&
          isZoneExist &&
          isZoneNumber &&
          isRowNumber &&
          isSetsNumber &&
          seatList.push(seatItem);

        if (Number(noOfSets) === 2 && seatList.length >= 2) {
          break;
        }
        if (Number(noOfSets) === 3 && seatList.length >= 1) {
          break;
        }
        if (Number(noOfSets) === 4 && seatList.length >= 1) {
          break;
        }
      }
    }
  }
  return seatList;
};

export const leafsRaptorsToIncludeclude = (section) => {
  const numberSection = !isNaN(Number(section)) ? Number(section) : 0;
  const includeList = [
    101,
    102,
    103,
    104,
    107,
    108,
    109,
    111,
    112,
    113,
    114,
    115,
    116,
    121,
  ];
  const findInclude = includeList.find((sectionItem) => numberSection === sectionItem);
  return findInclude ? true : false;
}
export const leafsSectioCheck = (section, includeList=[]) => {
  const numberSection = !isNaN(Number(section)) ? Number(section) : 0;
  const findInclude = includeList.find((sectionItem) => numberSection === sectionItem);
  return findInclude ? true : false;
}

export const cflTeamCheck = (teamSlug) => {
  return (
    teamSlug === "bc-lions" ||
    teamSlug === "ottawa-redblacks" ||
    teamSlug === "toronto-argonauts" ||
    teamSlug === "calgary-stampeders" ||
    teamSlug === "winnipeg-blue-bombers" ||
    teamSlug === "edmonton-elks" ||
    teamSlug === "saskatchewan-roughriders" ||
    teamSlug === "hamilton-tigercats" ||
    teamSlug === "grey-cup"
  );
}

export const nhlTeamCheck = (teamSlug) => {
  return (
    teamSlug === "calgary-flames" ||
    //teamSlug === "winnipeg-jets" ||
    teamSlug === "edmonton-oilers" ||
    teamSlug === "vancouver-canucks" ||
    teamSlug === "toronto-maple-leafs" ||
    teamSlug === "ottawa-senators"
  );
}

export const mlsTeamCheck = (teamSlug) => {
  return (
    teamSlug === "vancouver-whitecaps-fc" ||
    teamSlug === "toronto-fc"
  );
}

export const ahlTeamCheck = (teamSlug) => {
  return (
    // teamSlug === "calgary-wranglers" ||
    teamSlug === "manitoba-moose" ||
    teamSlug === "abbotsford-canucks" ||
    teamSlug === "belleville-senators" ||
    teamSlug === "toronto-marlies"
  );
}

export const getNormalizedTEData = (game, teListings, currencyRate, seats) => {
  let bookedSection = [...new Set(seats.map(obj => obj.zoneNo ? obj.zone + ' ' + obj.zoneNo : obj.zone))]; 
  const cflSeats = cflTeamCheck(game.homeTeamSlug)
    ? composeCFLTElist(game, teListings, currencyRate, seats)
    : mlsTeamCheck(game.homeTeamSlug) ? composeMLSTElist(game, teListings, currencyRate, seats) 
    : (ahlTeamCheck(game.homeTeamSlug) && (!game.isSpecial)) ? composeAHLTElist(game, teListings, currencyRate, seats) 
    : composeTElist(game, teListings, currencyRate, seats);
  const seatsMerged =
    game.homeTeamSlug === "toronto-blue-jays"
      ? composeBlueJaysTElist(game, teListings, currencyRate, seats)
      : cflSeats;
  const lowerBowlMerged =
    (game.homeTeamSlug === "toronto-maple-leafs") ||
    (game.homeTeamSlug === "toronto-raptors" && !game.isSpecial)
      ? composeLowerBowlTElist(game, teListings, currencyRate, seats)
      : { normalizedTESeats: [] };
  const raptorsSpecial = game.homeTeamSlug === "toronto-raptors" && game.isSpecial
      ? composeTElistRaptorsSpecial(game, teListings, currencyRate, seats)
      : { seatsMerged: [] };
  const canucksSpecial = game.homeTeamSlug === "vancouver-canucks" && game.isSpecial
      ? composeTElistCanucksSpecial(game, teListings, currencyRate, seats)
      : { seatsMerged: [] };
  const marliesSpecial = ((game.homeTeamSlug === "toronto-marlies") && (game.isSpecial))
      ? composeTElistMarliesSpecial(game, teListings, currencyRate, seats)
      : { seatsMerged: [] };
  const teListData = raptorsSpecial.seatsMerged.length > 0 ? raptorsSpecial.seatsMerged 
      : canucksSpecial.seatsMerged.length > 0 ? canucksSpecial.seatsMerged
      : marliesSpecial.seatsMerged.length > 0 ? marliesSpecial.seatsMerged
      : seatsMerged.seatsMerged;
  const uniqueTeListings = {};
  teListData.forEach(teListing => {
    uniqueTeListings[teListing.teListingId] = teListing;
  });

  lowerBowlMerged.normalizedTESeats.forEach(teListing => {
    uniqueTeListings[teListing.teListingId] = teListing;
  });

  let filteredTeListings = Object.fromEntries(
    Object.entries(uniqueTeListings).filter(([key, value]) => !bookedSection.includes(value.zone + ' ' + value.zoneNo)) );

  const allLowestPrices = Object.values(filteredTeListings);

  allLowestPrices.sort((a,b) => {
    return a.price - b.price
  });

  const pick1Lowest = allLowestPrices.map((value, key) => {
    if (key < 1 && game.homeTeamSlug !== "winnipeg-jets" && game.homeTeamSlug !== "calgary-flames") {
      return {...value, price: Number(value.priceNoFee)}
    } else {
      return value;
    }
  });

  seatsMerged.normalizedTESeats = seatsMerged.normalizedTESeats.filter(function( obj ) {
    return bookedSection.includes(obj.zone + ' ' + obj.zoneNo)
  });

  seatsMerged.seatsMerged = seatsMerged.seatsMerged.filter(function( obj ) {
    return bookedSection.includes(obj.zone + ' ' + obj.zoneNo)
  });
  
  return {
    allSeatsMerged: [
      ...pick1Lowest,
      ...seats,
    ],
    seatsMerged: seatsMerged,
  };
};

export const testDataCheck = (game) => {
  const url = window.location.href;
    let isDevelopmentServer = false;
    if (url.includes('development') || url.includes('localhost')){
      isDevelopmentServer = true
    }
  return (isDevelopmentServer && game.testGame) || !game.testGame ? false : true;
}

export const composeTElistCanucksSpecial = (
  game,
  teListings,
  currencyRate,
  seats
) => {
  const allSeat = grabAbbotfordSection(game, teListings, currencyRate, 0, 600, 1000);
  const frontRowsAll = [
    ...allSeat,
  ];
  const seatsMerged = [
    ...frontRowsAll,
  ];
  return {
    seatsMerged,
    normalizedTESeats: [
      ...frontRowsAll,
    ],
  };
};

export const grabAbbotfordSection = (game, teListings, currencyRate, sectionStart=100, sectionEnd=200, size) => {
  const SectionList = teListings.filter((seat) => {
    const sectionNumber = String(seat.section).length > 3 ? String(seat.section).slice(0, -1) : String(seat.section);
    const numberSection = !isNaN(Number(sectionNumber)) ? Number(sectionNumber) : 0;
    return numberSection > sectionStart && numberSection < sectionEnd;
  });
  
  const lowestPremiumSections = grabAbbotfordRowsLowestPrice(
    [],
    SectionList,
    2,
    game,
    currencyRate,
    size
  );
  return [...lowestPremiumSections]
};

export const grabAbbotfordRowsLowestPrice = (prevList, teListings, noOfSets, game, currencyRate, size=1) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const trimTESection =
        seat.section.length > 3 ? seat.section.slice(0, -1) : seat.section;

      const teSection = !isNaN(Number(trimTESection))
        ? Number(trimTESection)
        : 0;
      
      const zoneInvetory = 'Lower Zone';
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: teSection,
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier('abbotsford-canucks', zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = true;
        const isRowNumber =
          teamSlug === "ottawa-senators"
            ? teSenatorRowCheck(seatItem.row)
            : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
        const isZoneExist = !seatItem.zone.includes("---");
        const findMore3Option = seatItem.teSeatSplit.find(
          (split) => split === 3
        )
          ? true
          : false;
        const findMore4Option = seatItem.teSeatSplit.find(
          (split) => split === 4
        )
          ? true
          : false;
        const isMore4 =
          Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
        const isSetsNumber = noOfSets > 2 ? isMore4 : true;
        const isExcludedSeat = excludeCheck(seatItem, teamSlug);
        const inPrevList = prevList.find((list) => {
          return (
            `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}${list.price}` ===
            `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}${seatItem.price}`
          );
        });

        !isExcludedSeat &&
        !inPrevList &&
          isZoneExist &&
          isZoneNumber &&
          isRowNumber &&
          isSetsNumber &&
          seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= size) {
        break;
      }
    }
  }
  return seatList;
};

export const composeAHLTElist = (game, teListings, currencyRate, seats) => {
  const formatedMLS = getFormattedAHL(game, teListings, currencyRate, seats);
  const seatsMerged = [
    ...formatedMLS,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [
      ...formatedMLS,
    ],
  };
};

export const getFormattedAHL = (game, teListings, currencyRate) => {
  switch (game.homeTeamSlug) {
    case "abbotsford-canucks": {
      const allSeat = grabAbbotfordSection(game, teListings, currencyRate, 0, 600, 1000);
      return allSeat;
    }
    
    case "belleville-senators": {
      const allSeat = grabBellevilleSection(game, teListings, currencyRate, 0, 600, 1000);
      return allSeat;
    }

    case "toronto-marlies": {
      const allSeat = grabMarliesSection(game, teListings, currencyRate, 0, 600, 1000);
      return allSeat;
    }
    
    default: {
      return [];
    }
  }
}

export const grabBellevilleSection = (game, teListings, currencyRate, sectionStart=100, sectionEnd=200, size) => {
  const SectionList = teListings.filter((seat) => {
    const sectionNumber = String(seat.section).length > 3 ? String(seat.section).slice(0, -1) : String(seat.section);
    const numberSection = !isNaN(Number(sectionNumber)) ? Number(sectionNumber) : 0;
    return numberSection > sectionStart && numberSection < sectionEnd;
  });
  
  const lowestPremiumSections = grabBellevilleRowsLowestPrice(
    [],
    SectionList,
    2,
    game,
    currencyRate,
    size
  );
  return [...lowestPremiumSections]
};

export const grabBellevilleRowsLowestPrice = (prevList, teListings, noOfSets, game, currencyRate, size=1) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const trimTESection =
        seat.section.length > 3 ? seat.section.slice(0, -1) : seat.section;

      const teSection = !isNaN(Number(trimTESection))
        ? Number(trimTESection)
        : 0;
      
      const zoneInvetory = formatTEZoneToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: teSection,
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = true;
        const isZoneExist = !seatItem.zone.includes("---");
        const findMore3Option = seatItem.teSeatSplit.find(
          (split) => split === 3
        )
          ? true
          : false;
        const findMore4Option = seatItem.teSeatSplit.find(
          (split) => split === 4
        )
          ? true
          : false;
        const isMore4 =
          Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
        const isSetsNumber = noOfSets > 2 ? isMore4 : true;
        const isExcludedSeat = excludeCheck(seatItem, teamSlug);
        const inPrevList = prevList.find((list) => {
          return (
            `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}${list.price}` ===
            `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}${seatItem.price}`
          );
        });

        !isExcludedSeat &&
        !inPrevList &&
          isZoneExist &&
          isZoneNumber &&
          isSetsNumber &&
          seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= size) {
        break;
      }
    }
  }
  return seatList;
};

export const grabMarliesSection = (game, teListings, currencyRate, sectionStart=100, sectionEnd=200, size) => {
  const SectionList = teListings.filter((seat) => {
    const sectionNumber = String(seat.section).length > 3 ? String(seat.section).slice(0, -1) : String(seat.section);
    const numberSection = !isNaN(Number(sectionNumber)) ? Number(sectionNumber) : 0;
    return numberSection > sectionStart && numberSection < sectionEnd;
  });
  
  const lowestPremiumSections = grabMarliesRowsLowestPrice(
    [],
    SectionList,
    2,
    game,
    currencyRate,
    size
  );
  return [...lowestPremiumSections]
};

export const grabMarliesRowsLowestPrice = (prevList, teListings, noOfSets, game, currencyRate, size=1) => {
  let seatList = [];
  let { homeTeamSlug: teamSlug } = game;
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const trimTESection =
        seat.section.length > 3 ? seat.section.slice(0, -1) : seat.section;

      const teSection = !isNaN(Number(trimTESection))
        ? Number(trimTESection)
        : 0;
      const zoneInvetory = formatTEZoneToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: teSection,
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = true;
      const isZoneExist = !seatItem.zone.includes("---");
        const findMore3Option = seatItem.teSeatSplit.find(
          (split) => split === 3
        )
          ? true
          : false;
        const findMore4Option = seatItem.teSeatSplit.find(
          (split) => split === 4
        )
          ? true
          : false;
        const isMore4 =
          Number(noOfSets) === 4 ? findMore4Option : findMore3Option;
        const isSetsNumber = noOfSets > 2 ? isMore4 : true;
        const isExcludedSeat = excludeCheck(seatItem, teamSlug);
        const inPrevList = prevList.find((list) => {
          return (
            `${list.zone}${list.zoneNo}${list.row}${list.noOfSeats}${list.price}` ===
            `${seatItem.zone}${seatItem.zoneNo}${seatItem.row}${seatItem.noOfSeats}${seatItem.price}`
          );
        });

        !isExcludedSeat &&
        !inPrevList &&
          isZoneExist &&
          isZoneNumber &&
          isSetsNumber &&
          seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= size) {
        break;
      }
    }
  }
  return seatList;
};

export const composeTElistMarliesSpecial = (
  game,
  teListings,
  currencyRate,
  seats
) => {
  const lowerBowlListTE = Array.isArray(teListings) ? teListings.filter((list) => leafsRaptorsToIncludeclude(list.section)) : [];
  const additional1s = grabLowestPriceMarliesSpecialSection(teListings, 2, game, currencyRate, [], [101,
    102,
    103,
    104,
    107,
    108,
    109,
    111,
    112,
    113,
    114,
    115,
    116,
    121]);
  const additional3s = grabLowestPriceMarliesSpecialSection(teListings, 2, game, currencyRate, [], [308, 309, 310, 320, 321, 322]);
  const additional13s = [...additional1s, ...additional3s];
  const pairSeatLowest = grabLowestPriceLowerBowlMarliesSpecial(lowerBowlListTE, 2, game, currencyRate, [...additional13s]);
  const threeSeatLowest = grabLowestPriceLowerBowlMarliesSpecial(lowerBowlListTE, 3, game, currencyRate, [...additional13s, ...pairSeatLowest]);
  const threeItemList = threeSeatLowest;
  const fourSeatLowest = grabLowestPriceLowerBowlMarliesSpecial(lowerBowlListTE, 4, game, currencyRate, [...additional13s, ...pairSeatLowest, ...threeSeatLowest]);
  
  const fourItemList = fourSeatLowest;
  const seatsMerged = [
    ...pairSeatLowest,
    ...threeItemList,
    ...fourItemList,
    ...additional13s,
  ];

  return {
    seatsMerged,
    normalizedTESeats: [...pairSeatLowest, ...threeItemList, ...fourItemList, ...additional13s],
  };
};

export const grabLowestPriceMarliesSpecialSection = (teListings, noOfSets, game, currencyRate, prevList, includeList) => {
  let seatList = [];
  let filteredTEListings = Array.isArray(teListings) ? teListings.filter((list) => leafsSectioCheck(list.section, includeList)) : [];
  let teamSlug = 'toronto-maple-leafs';
  if (filteredTEListings.length > 0) {
    for (let i = 0; i < filteredTEListings.length; i++) {
      const seat = filteredTEListings[i];
      const zoneInvetory = formatSpecialTEZoneToFF(seat, teamSlug);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        teamSlug === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        seatList.push(seatItem);
    }
  }
  return seatList;
};

export const formatSpecialTEZoneToFF = (teSeat, teamSlug) => {
  const zoneInvetory = teSeat.tevo_section_name
    .split(" ")
    .slice(0, -1)
    .join(" ");
  const zoneNo = teSeat.tevo_section_name.split(" ").pop();
  return getTeamZone(teamSlug, zoneInvetory.toLowerCase(), zoneNo);
};

export const grabLowestPriceLowerBowlMarliesSpecial = (teListings, noOfSets, game, currencyRate, prevList) => {
  let seatList = [];
  let teamSlug = 'toronto-maple-leafs';
  if (teListings.length > 0) {
    for (let i = 0; i < teListings.length; i++) {
      const seat = teListings[i];
      const zoneInvetory = formatSpecialTEZoneToFF(seat, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, teamSlug);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        game: game.id,
        gameName: game.longName,
        gameDate: game.date,
        gameTimezone: game.timezone,
        isPlayoffs: game.isPlayoffs,
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, teamSlug),
        row: getRowSection(seat.row, teamSlug),
        noOfSeats: Number(seat.available_quantity),
        sellerEmail: "te@fansfirst.ca",
        sellOption: 1,
        isAisleSeat: false,
        tier: getTeamTier(teamSlug, zoneInvetory),
        seatsSold: 0,
        seatNumberEnd: 0,
        seatNumberStart: 0,
        quantityOptions: seat.splits,
        teSeatSplit: seat.splits,
        teListingId: seat.id,
        priceNoFee: convertedPriceNoFee,
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        teamSlug === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const isMore4 = noOfSets >= 4 ? seatItem.teSeatSplit.find((set) => set >= 4) : seatItem.teSeatSplit.includes(noOfSets);
      const isSetsNumber = noOfSets > 2 ? isMore4 : true;
      const isPrevItemExist = Array.isArray(prevList) && prevList.find(
        (seat) =>
          seat.row === seatItem.row &&
          seat.zoneNo === seatItem.zoneNo &&
          seat.noOfSeats === seatItem.noOfSeats
      );
      const isExcludedSeat = excludeCheck(seatItem, teamSlug);
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        isSetsNumber &&
        !isPrevItemExist &&
        seatList.push(seatItem);

      if (Number(noOfSets) === 2 && seatList.length >= 3) {
        break;
      }
      if (Number(noOfSets) === 3 && seatList.length >= 1) {
        break;
      }
      if (Number(noOfSets) === 4 && seatList.length >= 1) {
        break;
      }
    }
  }
  return seatList;
};