import { flatten } from "lodash";

const zoneGroup = [
  {
    label: "Lower Zone",
    numbers: [
      ...["100", "101", "102", "103", "104", "105", "106", "107", "108", "109", "110"],
      ...["111", "112", "113", "114", "115", "116", "117", "118", "119"],
    ],
  },
];


const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});

export default flatten([...zones]);