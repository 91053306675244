export const newTeams = {
    "toronto-maple-leafs": {
        name: "Toronto Maple Leafs",
        colorAttribute: '#001b5a'
    },
    "ottawa-senators": {
        name: "Ottawa Senators",
        colorAttribute: '#d31831'
    },
    "toronto-raptors": {
        name: "Toronto Raptors",
        colorAttribute: '#7637bf'
    },
    "toronto-blue-jays": {
        name: "Toronto Blue Jays",
        colorAttribute: '#134A8E'
    },
}