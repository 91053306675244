export const getZones = rsr => {
  let zones = [];

  var _134 = {
    path:
      'M562.322,661.322V527.871s9.259-1.742,16.323-6c9.58,19.645,57.484,118.839,57.484,118.839S608.322,655.613,562.322,661.322Z',
    attr: {
      id: '_134',
      'data-name': '134',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 134, zone: 'Lower Bowl', zoneId: 2 },
  };

  var _132 = {
    path:
      'M637.968,640.032,580.29,520.806s4.839-3,10.356-10.257c52.451,40.837,117.773,91.837,117.773,91.837s-19.064,22.065-33.968,31.453c-14.8-18-8.709-10.258-8.709-10.258A170.53,170.53,0,0,1,637.968,640.032Z',
    attr: {
      id: '_132',
      'data-name': '132',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 132, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _130 = {
    path:
      'M709.871,600.646,591.9,508.614A43.841,43.841,0,0,0,598.774,494c93,24.678,143.9,38.614,143.9,38.614S730.387,578.387,709.871,600.646Z',
    attr: {
      id: '_130',
      'data-name': '130',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 130, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _129 = {
    path:
      'M599.226,469.1H649.1V459.033H749.8v26.516s-1.417,26.9-7.126,44.516c-140.032-38.322-144.291-39.1-144.291-39.1Z',
    attr: {
      id: '_129',
      'data-name': '129',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 129, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _127 = {
    path: 'M742.678,386.742s8.709,29.419,6.871,70.452H599.226V425.645Z',
    attr: {
      id: '_127',
      'data-name': '127',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 127, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _126 = {
    path:
      'M709.871,316.484,591.9,408.516a43.829,43.829,0,0,1,6.871,14.613c93-24.677,143.9-38.613,143.9-38.613S730.387,338.742,709.871,316.484Z',
    attr: {
      id: '_126',
      'data-name': '126',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 126, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _124 = {
    path:
      'M637.968,277.1,580.29,396.323s4.839,3,10.356,10.258c52.451-40.839,117.773-91.839,117.773-91.839s-19.064-22.064-33.968-31.451c-14.8,18-8.709,10.258-8.709,10.258A170.573,170.573,0,0,0,637.968,277.1Z',
    attr: {
      id: '_124',
      'data-name': '124',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 124, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _122 = {
    path:
      'M562.322,255.807V389.259s9.259,1.741,16.323,6c9.58-19.646,57.484-118.839,57.484-118.839S608.322,261.516,562.322,255.807Z',
    attr: {
      id: '_122',
      'data-name': '122',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 122, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _117 = {
    path:
      'M310.839,255.807V389.259s-10.839,2.483-14.323,6.1C272.258,345.161,238.193,276,238.193,276S268.258,260.775,310.839,255.807Z',
    attr: {
      id: '_117',
      'data-name': '117',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 117, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _115 = {
    path:
      'M236.387,276.9l58.064,119.484S286.58,402.451,284,406.58C206.323,346.064,176.9,323.1,176.9,323.1S198.451,295.355,236.387,276.9Z',
    attr: {
      id: '_115',
      'data-name': '115',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 115, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _113 = {
    path:
      'M175.484,325.032,282.71,408.645s-6.065,7.871-6.968,14.839C145.807,387.355,144,386.839,144,386.839S155.226,348.387,175.484,325.032Z',
    attr: {
      id: '_113',
      'data-name': '113',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 113, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _112 = {
    path:
      'M144,389.161a257.266,257.266,0,0,0-7.613,46.064v23.226H275.613v-32.9Z',
    attr: {
      id: '_112',
      'data-name': '112',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 112, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _110 = {
    path:
      'M144,527.871a245.988,245.988,0,0,1-7.613-44.992V460.193H275.613v32.138Z',
    attr: {
      id: '_110',
      'data-name': '110',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 110, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _109 = {
    path:
      'M175.484,592.1,282.71,508.484s-6.065-7.87-6.968-14.838C145.807,529.775,144,530.29,144,530.29S155.226,568.743,175.484,592.1Z',
    attr: {
      id: '_109',
      'data-name': '109',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 109, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _107 = {
    path:
      'M236.387,640.226l58.064-119.483S286.58,514.678,284,510.549c-77.677,60.516-107.1,83.484-107.1,83.484S198.451,621.775,236.387,640.226Z',
    attr: {
      id: '_107',
      'data-name': '107',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 107, zone: 'Lower Bowl', zoneId: 2 },
  };
  var _105 = {
    path:
      'M310.839,661.322V527.871s-10.839-2.485-14.323-6.1c-24.258,50.194-58.323,119.355-58.323,119.355S268.258,656.354,310.839,661.322Z',
    attr: {
      id: '_105',
      'data-name': '105',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 105, zone: 'Lower Bowl', zoneId: 2 },
  };
  var l31 = {
    path:
      'M768.322,308.452l-24.58,14.258s10.839,19.032,14.322,33.355c17.419-5.42,27.807-8.71,27.807-8.71A373.311,373.311,0,0,0,768.322,308.452Z',
    attr: {
      id: 'l31',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 31, zone: 'Loge', zoneId: 3 },
  };
  var l30 = {
    path:
      'M767.226,306.709l-24.9,14.71s-12.387-21.807-29.936-38.968c18.194-18.839,21.162-21.935,21.162-21.935S756.933,285.969,767.226,306.709Z',
    attr: {
      id: 'l30',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 30, zone: 'Loge', zoneId: 3 },
  };
  var l29 = {
    path:
      'M731.839,258.709l-20.71,22.452s-17.807-18.968-41.9-31.935c9.193-16.354,16.064-28.065,16.064-28.065S712.968,237.9,731.839,258.709Z',
    attr: {
      id: 'l29',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 29, zone: 'Loge', zoneId: 3 },
  };
  var l28 = {
    path:
      'M682.774,220.193l-15.291,27.871s-24.29-14.516-48.773-20.9c6-22.549,7.935-30.1,7.935-30.1S659.161,205.484,682.774,220.193Z',
    attr: {
      id: 'l28',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 28, zone: 'Loge', zoneId: 3 },
  };
  var l27 = {
    path:
      'M624.129,197.065l-7.258,29.323s-20.064-7.259-54.549-10.646V188.936S602.839,189.323,624.129,197.065Z',
    attr: {
      id: 'l27',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 27, zone: 'Loge', zoneId: 3 },
  };
  var l26 = {
    path: 'M558.966,219.7v26.983H500.74V219.7Z',
    attr: {
      id: 'l26',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't0.905 -30.764',
    data: { zoneNo: 26, zone: 'Loge', zoneId: 3 },
  };
  var l25 = {
    path: 'M562.482,219.7v26.983H500.74V219.7Z',
    attr: {
      id: 'l25',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't-62.611 -30.764',
    data: { zoneNo: 25, zone: 'Loge', zoneId: 3 },
  };
  var l24 = {
    path: 'M561.708,219.7v26.983H500.74V219.7Z',
    attr: {
      id: 'l24',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't-125.837 -30.764',
    data: { zoneNo: 24, zone: 'Loge', zoneId: 3 },
  };
  var l23 = {
    path: 'M560.224,219.7v26.983H500.74V219.7Z',
    attr: {
      id: 'l23',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't-187.579 -30.764',
    data: { zoneNo: 23, zone: 'Loge', zoneId: 3 },
  };
  var l22 = {
    path:
      'M310.839,188.935v26.71s-38,5.13-52.581,11.323c-6.71-26.387-7.806-30.516-7.806-30.516S273.161,189.872,310.839,188.935Z',
    attr: {
      id: 'l22',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 22, zone: 'Loge', zoneId: 3 },
  };
  var l21 = {
    path:
      'M248.064,197.065,256,227.549s-33.872,11.9-48,21.29c-7.065-11.806-16.936-28.742-16.936-28.742S222.419,202.678,248.064,197.065Z',
    attr: {
      id: 'l21',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 21, zone: 'Loge', zoneId: 3 },
  };
  var l20 = {
    path:
      'M188.839,221.452l17.129,28.355s-25.645,16.452-40.548,32.129c-20.9-21.775-22.549-23.71-22.549-23.71S167.258,233.355,188.839,221.452Z',
    attr: {
      id: 'l20',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 20, zone: 'Loge', zoneId: 3 },
  };
  var l19 = {
    path:
      'M163.581,283.774l-22.742-23.807s-25.451,28.839-33.774,46.258c25.645,14.807,27.677,15.871,27.677,15.871S147.226,299.838,163.581,283.774Z',
    attr: {
      id: 'l19',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 19, zone: 'Loge', zoneId: 3 },
  };
  var l18 = {
    path:
      'M105.807,308.452l27.871,15.677s-17.419,33-20.517,51.678c-26.612-8.032-31.645-9.291-31.645-9.291S92.2,330.264,105.807,308.452Z',
    attr: {
      id: 'l18',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 18, zone: 'Loge', zoneId: 3 },
  };
  var l17 = {
    path:
      'M80.936,368.548l31.257,9.387s-6.774,30.677-7.257,48.29c-32.808-.967-34.84-1.258-34.84-1.258S73,394.484,80.936,368.548Z',
    attr: {
      id: 'l17',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 17, zone: 'Loge', zoneId: 3 },
  };
  var l16 = {
    path:
      'M70.1,427.193l34.355,1.162v60.774L70.1,490.483A336.538,336.538,0,0,1,70.1,427.193Z',
    attr: {
      id: 'l16',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 16, zone: 'Loge', zoneId: 3 },
  };
  var l15 = {
    path:
      'M80.936,548.968l31.257-9.387s-6.774-30.677-7.257-48.291c-32.808.968-34.84,1.259-34.84,1.259S73,523.032,80.936,548.968Z',
    attr: {
      id: 'l15',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 15, zone: 'Loge', zoneId: 3 },
  };
  var l14 = {
    path:
      'M105.807,609.065l27.871-15.678s-17.419-33-20.517-51.676C86.549,549.743,81.516,551,81.516,551S92.2,587.253,105.807,609.065Z',
    attr: {
      id: 'l14',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 14, zone: 'Loge', zoneId: 3 },
  };
  var l13 = {
    path:
      'M163.581,633.742l-22.742,23.807s-25.451-28.839-33.774-46.259c25.645-14.807,27.677-15.871,27.677-15.871S147.226,617.678,163.581,633.742Z',
    attr: {
      id: 'l13',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 13, zone: 'Loge', zoneId: 3 },
  };
  var l12 = {
    path:
      'M188.839,696.064l17.129-28.353s-25.645-16.453-40.548-32.13c-20.9,21.774-22.549,23.71-22.549,23.71S167.258,684.161,188.839,696.064Z',
    attr: {
      id: 'l12',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 12, zone: 'Loge', zoneId: 3 },
  };
  var l11 = {
    path:
      'M248.064,720.451,256,689.968s-33.872-11.9-48-21.29c-7.065,11.8-16.936,28.741-16.936,28.741S222.419,714.839,248.064,720.451Z',
    attr: {
      id: 'l11',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 11, zone: 'Loge', zoneId: 3 },
  };
  var l10 = {
    path:
      'M310.839,728.581v-26.71s-38-5.129-52.581-11.322c-6.71,26.386-7.806,30.515-7.806,30.515S273.161,727.645,310.839,728.581Z',
    attr: {
      id: 'l10',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 10, zone: 'Loge', zoneId: 3 },
  };
  var l9 = {
    path: 'M0-.011,59.484,0V26.71H0Z',
    attr: {
      id: 'l9',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't313.161 701.871',
    data: { zoneNo: 9, zone: 'Loge', zoneId: 3 },
  };
  var l8 = {
    path: 'M0,0H61.226V26.71H0Z',
    attr: {
      id: 'l8',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't374.903 701.871',
    data: { zoneNo: 8, zone: 'Loge', zoneId: 3 },
  };
  var l7 = {
    path: 'M0,0H61.742V26.71H0Z',
    attr: {
      id: 'l7',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't438.129 701.871',
    data: { zoneNo: 7, zone: 'Loge', zoneId: 3 },
  };
  var l6 = {
    path: 'M0,0H58.226V26.71H0Z',
    attr: {
      id: 'l6',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't501.645 701.871',
    data: { zoneNo: 6, zone: 'Loge', zoneId: 3 },
  };
  var l5 = {
    path:
      'M624.129,720.451l-7.258-29.322s-20.064,7.258-54.549,10.645v26.807S602.839,728.193,624.129,720.451Z',
    attr: {
      id: 'l5',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 5, zone: 'Loge', zoneId: 3 },
  };
  var l4 = {
    path:
      'M682.774,697.322l-15.291-27.871s-24.29,14.517-48.773,20.9c6,22.549,7.935,30.1,7.935,30.1S659.161,712.032,682.774,697.322Z',
    attr: {
      id: 'l4',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 4, zone: 'Loge', zoneId: 3 },
  };
  var l3 = {
    path:
      'M731.839,658.807l-20.71-22.452s-17.807,18.968-41.9,31.935c9.193,16.356,16.064,28.065,16.064,28.065S712.968,679.614,731.839,658.807Z',
    attr: {
      id: 'l3',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 3, zone: 'Loge', zoneId: 3 },
  };
  var l2 = {
    path:
      'M767.226,610.807l-24.9-14.71s-12.387,21.807-29.936,38.968C730.581,653.9,733.549,657,733.549,657S756.933,631.547,767.226,610.807Z',
    attr: {
      id: 'l2',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 2, zone: 'Loge', zoneId: 3 },
  };
  var l1 = {
    path:
      'M768.322,609.065l-24.58-14.258s10.839-19.032,14.322-33.355c17.419,5.42,27.807,8.71,27.807,8.71A373.308,373.308,0,0,1,768.322,609.065Z',
    attr: {
      id: 'l1',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 1, zone: 'Loge', zoneId: 3 },
  };
  var skyaa = {
    path:
      'M869.032,322.709l-14.451,4.9a395.332,395.332,0,0,1,9.354,46.065,475.7,475.7,0,0,1,3.484,55.742v56.323s.387,31.741-2.9,52.839-7.484,42-9.935,51.1c10.515,3.677,14.451,5.419,14.451,5.419s8.839-35.806,11.161-53.419c1.936-17.806,3.388-42.774,3.388-55.645V429.806s-2.323-46.839-3.871-57.967-10.678-49.13-10.678-49.13',
    attr: {
      id: 'sky-aa',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'AA', zone: 'Sky Lounge', zoneId: 3 },
  };
  var skye = {
    path:
      'M837.29,534.968S833.549,564,828.517,580.9c12,4.257,19.741,6.838,19.741,6.838a420.9,420.9,0,0,0,9.032-48.258C844.258,536.646,837.29,534.968,837.29,534.968Z',
    attr: {
      id: 'sky-e',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'E', zone: 'Sky Lounge', zoneId: 3 },
  };
  var skyd = {
    path:
      'M841.29,485.549h20.646s-1.291,35.226-4.258,51.612c-18.324-3.742-20.388-4.258-20.388-4.258A290.066,290.066,0,0,0,841.29,485.549Z',
    attr: {
      id: 'sky-d',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'D', zone: 'Sky Lounge', zoneId: 3 },
  };
  var skyc = {
    path: 'm 842.3448,457.81643 v -24.63726 h 9.35593 9.35592 v 24.63726 24.63726 h -9.35592 -9.35593 z',
    attr: {
      id: 'sky-c',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't82.589',
    data: { zoneNo: '', zone: 'Sky Lounge C', zoneId: 3 },
  };
  var sncc = {
    path: 'm 810.53467,457.81643 v -24.63726 h 11.85084 11.85083 v 24.63726 24.63726 h -11.85083 -11.85084 z',
    attr: {
      id: 'sn-cc',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't50.977',
    data: { zoneNo: '', zone: 'Sportsnet Club CC', zoneId: 3 },
  };
  var skyb = {
    path:
      'M837.032,381.677l20.646-4s2.193,10.581,4.257,52.129H841.161A392.947,392.947,0,0,0,837.032,381.677Z',
    attr: {
      id: 'sky-b',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'B', zone: 'Sky Lounge', zoneId: 3 },
  };
  var skya = {
    path:
      'M848.646,329.452l-20.807,6.871a276.069,276.069,0,0,1,8.935,43.161c17.548-3.225,20.9-3.871,20.9-3.871S853.548,345.871,848.646,329.452Z',
    attr: {
      id: 'sky-a',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'A', zone: 'Sky Lounge', zoneId: 3 },
  };
  var snee = {
    path:
      'M805.549,528.646l25.161,5.161s-2.193,25.548-8.129,45.29c-17.032-5.678-25.42-8.515-25.42-8.515A211.17,211.17,0,0,0,805.549,528.646Z',
    attr: {
      id: 'sn-ee',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'EE', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var sndd = {
    path:
      'M809.678,485.549H835.1s-1.29,32-3.871,46.064c-15.612-3.355-25.677-5.162-25.677-5.162S808.646,512.775,809.678,485.549Z',
    attr: {
      id: 'sn-dd',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'DD', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var snbb = {
    path:
      'M806.065,388.258l24.774-5.291s2.709,16.258,4.257,47.484H809.677S809.032,405.161,806.065,388.258Z',
    attr: {
      id: 'sn-bb',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'BB', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var snaa = {
    path:
      'M797.807,346.709l23.871-7.613s3.226,3.872,9.161,41.549c-16.258,3.613-25.161,5.548-25.161,5.548A270.321,270.321,0,0,0,797.807,346.709Z',
    attr: {
      id: 'sn-aa',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'AA', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var sne = {
    path:
      'M779.354,523.1l20,4.129a306.113,306.113,0,0,1-7.483,41.032c-17.29-5.548-21.936-6.9-21.936-6.9S777.29,545.678,779.354,523.1Z',
    attr: {
      id: 'sn-e',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'E', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var snd = {
    path:
      'M782.322,485.549H803.1s-1.3,26.784-3.225,39.612c-16.517-3.612-20.258-4.515-20.258-4.515A210.411,210.411,0,0,0,782.322,485.549Z',
    attr: {
      id: 'sn-d',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'D', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var snc = {
    path: 'm 783.09063,457.81643 v -24.63726 h 9.66779 9.66779 v 24.63726 24.63726 h -9.66779 -9.66779 z',
    attr: {
      id: 'sn-c',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    transform: 't23.622',
    data: { zoneNo: '', zone: 'Sportsnet Club C', zoneId: 3 },
  };
  var snb = {
    path:
      'M803.1,430.452H782.323s-.387-21.419-3.1-36.9c10.323-1.807,20.517-3.742,20.517-3.742S801.807,406.581,803.1,430.452Z',
    attr: {
      id: 'sn-b',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'B', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var sna = {
    path:
      'M779.226,391.355l20.258-4s-2.92-23.708-7.612-38.71c-13.549,4.387-22.453,7.484-22.453,7.484S774.839,366.064,779.226,391.355Z',
    attr: {
      id: 'sn-a',
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'data-name': 'Edmonton-Oilers-Seatmap – 1',

      opacity: 0,

      'stroke-opacity': '1',
    },
    data: { zoneNo: 'A', zone: 'Sportsnet Club', zoneId: 3 },
  };
  var _234 = {
    path:
      'M562.322,797.387V734.678s33.936.516,63.742-7.613c13.419,52.386,14.839,57.29,14.839,57.29S610.451,793.517,562.322,797.387Z',
    attr: {
      id: '_234',
      'data-name': '234',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 234, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _233 = {
    path:
      'M642.839,783.581l-14.581-56.517s36.9-10.581,57.935-24c12.258,20.775,30.453,52.646,30.453,52.646S678.71,774.549,642.839,783.581Z',
    attr: {
      id: '_233',
      'data-name': '233',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 233, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _232 = {
    path:
      'M718.71,754.549l-30.452-52.774s30.839-18.71,48-38.324c23.871,25.291,45.678,48.646,45.678,48.646S753.29,738.032,718.71,754.549Z',
    attr: {
      id: '_232',
      'data-name': '232',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 232, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _231 = {
    path:
      'M783.354,710.549l-45.419-48.517s21.807-23.742,34.71-47.742c58.451,33.549,61.161,34.968,61.161,34.968S812.128,686.678,783.354,710.549Z',
    attr: {
      id: '_231',
      'data-name': '231',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 231, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _230 = {
    path:
      'M821.419,639.581,774.065,611.71s11.742-22.452,16.386-36.517c25.162,23.742,42.066,39.871,42.066,39.871Z',
    attr: {
      id: '_230',
      'data-name': '230',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 230, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _201 = {
    path: 'M313.161,804.575v62.709h58.225V804.575Z',
    attr: {
      id: '_201',
      'data-name': '201',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't188.485 -69.897',
    data: { zoneNo: 201, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _202 = {
    path: 'M313.161,804.575v62.709H374.9V804.575Z',
    attr: {
      id: '_202',
      'data-name': '202',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't124.968 -69.897',
    data: { zoneNo: 202, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _203 = {
    path: 'M313.161,804.575v62.709h61.226V804.575Z',
    attr: {
      id: '_203',
      'data-name': '203',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't61.742 -69.897',
    data: { zoneNo: 203, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _204 = {
    path: 'M313.161,804.575v62.709h59.484V804.575Z',
    attr: {
      id: '_204',
      'data-name': '204',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't0 -69.897',
    data: { zoneNo: 204, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _205 = {
    path:
      'M310.839,797.387V734.678s-35.484.387-62.194-7.613c-12.9,49.419-15.225,57.549-15.225,57.549S275.613,795.194,310.839,797.387Z',
    attr: {
      id: '_205',
      'data-name': '205',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 205, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _206 = {
    path:
      'M231.355,783.71l15.226-56.646s-42.194-11.742-58.839-24.386c-21.29,36.515-30.968,52.9-30.968,52.9A423.825,423.825,0,0,0,231.355,783.71Z',
    attr: {
      id: '_206',
      'data-name': '206',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 206, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _207 = {
    path:
      'M154.452,754.161l31.1-52.387s-29.678-17.935-47.226-38.193c-42.581,44.387-46.581,48.387-46.581,48.387S130.71,744.1,154.452,754.161Z',
    attr: {
      id: '_207',
      'data-name': '207',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 207, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _208 = {
    path:
      'M90.193,710.419l46.194-48.387s-24.129-26.193-34.71-47.871C53.806,641.129,40,648.742,40,648.742S62.967,687.581,90.193,710.419Z',
    attr: {
      id: '_208',
      'data-name': '208',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 208, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _209 = {
    path:
      'M100.129,612.354s-18.064-34.708-24.387-59.612c-45.806,13.806-65.161,19.225-65.161,19.225s8.9,41.034,28.387,74.969C67.871,630.678,100.129,612.354,100.129,612.354Z',
    attr: {
      id: '_209',
      'data-name': '209',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 209, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _210 = {
    path:
      'M74.581,550.807l-64,19.354s-7.936-27.677-7.742-75.483l60.774-2.129A419.379,419.379,0,0,0,74.581,550.807Z',
    attr: {
      id: '_210',
      'data-name': '210',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 210, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _211 = {
    path: 'M63.419,427.1s-4.064,40.839.774,63.29L2.838,492.323V424.968Z',
    attr: {
      id: '_211',
      'data-name': '211',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 211, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _212 = {
    path:
      'M74.581,366.709l-64-19.353s-7.936,27.677-7.742,75.483l60.774,2.128A419.407,419.407,0,0,1,74.581,366.709Z',
    attr: {
      id: '_212',
      'data-name': '212',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 212, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _213 = {
    path:
      'M100.129,305.161s-18.064,34.71-24.387,59.613c-45.806-13.806-65.161-19.226-65.161-19.226s8.9-41.032,28.387-74.968C67.871,286.839,100.129,305.161,100.129,305.161Z',
    attr: {
      id: '_213',
      'data-name': '213',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 213, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _214 = {
    path:
      'M90.193,207.1l46.194,48.387s-24.129,26.194-34.71,47.871C53.806,276.388,40,268.775,40,268.775S62.967,229.936,90.193,207.1Z',
    attr: {
      id: '_214',
      'data-name': '214',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 214, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _215 = {
    path:
      'M154.452,163.355l31.1,52.387s-29.678,17.935-47.226,38.194c-42.581-44.388-46.581-48.388-46.581-48.388S130.71,173.419,154.452,163.355Z',
    attr: {
      id: '_215',
      'data-name': '215',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 215, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _216 = {
    path:
      'M231.355,133.807l15.226,56.645s-42.194,11.742-58.839,24.387c-21.29-36.516-30.968-52.9-30.968-52.9A423.824,423.824,0,0,1,231.355,133.807Z',
    attr: {
      id: '_216',
      'data-name': '216',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 216, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _217 = {
    path:
      'M310.839,120.129v62.71s-35.484-.387-62.194,7.613c-12.9-49.42-15.225-57.549-15.225-57.549s42.193-10.58,77.419-12.774',
    attr: {
      id: '_217',
      'data-name': '217',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 217, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _218 = {
    path: 'M0,0H59.484V62.71H0Z',
    attr: {
      id: '_218',
      'data-name': '218',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't313.161 120.129',
    data: { zoneNo: 218, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _219 = {
    path: 'M0,0H61.226V62.71H0Z',
    attr: {
      id: '_219',
      'data-name': '219',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't374.903 120.129',
    data: { zoneNo: 219, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _220 = {
    path: 'M0,0H61.742V62.71H0Z',
    attr: {
      id: '_220',
      'data-name': '220',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't438.129 120.129',
    data: { zoneNo: 220, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _221 = {
    path: 'M0,0H58.226V62.71H0Z',
    attr: {
      id: '_221',
      'data-name': '221',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    transform: 't501.646 120.129',
    data: { zoneNo: 221, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _222 = {
    path:
      'M562.322,120.129v62.71s33.936-.516,63.742,7.613c13.419-52.387,14.839-57.291,14.839-57.291S610.451,124,562.322,120.129Z',
    attr: {
      id: '_222',
      'data-name': '222',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 222, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _223 = {
    path:
      'M642.839,133.935l-14.581,56.517s36.9,10.581,57.935,24c12.258-20.775,30.453-52.645,30.453-52.645s-37.936-18.839-73.807-27.872',
    attr: {
      id: '_223',
      'data-name': '223',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 223, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _224 = {
    path:
      'M718.71,162.968l-30.452,52.774s30.839,18.71,48,38.323c23.871-25.29,45.678-48.645,45.678-48.645s-28.646-25.936-63.226-42.452',
    attr: {
      id: '_224',
      'data-name': '224',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 224, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _225 = {
    path:
      'M783.354,206.968l-45.419,48.516s21.807,23.742,34.71,47.742c58.451-33.549,61.161-34.967,61.161-34.967S812.128,230.839,783.354,206.968Z',
    attr: {
      id: '_225',
      'data-name': '225',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 225, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _226 = {
    path:
      'M821.419,277.935l-47.354,27.872s11.742,22.452,16.386,36.516c25.162-23.742,42.066-39.871,42.066-39.871Z',
    attr: {
      id: '_226',
      'data-name': '226',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 226, zone: 'Upper Bowl', zoneId: 1 },
  };
  var _104club = {
    path:
      'M312.9,661.322V649.613h8.9V627.451h-8.9v-99.58h60.871v99.774h-7.549V649.9h7.549v11.419Z',
    attr: {
      id: '_104club',
      'data-name': '104',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 104, zone: 'Lower Club', zoneId: 2 },
  };
  var _103club = {
    path:
      'M375.081,661.322V649.613h8.9V627.451h-8.9v-99.58h60.871v99.774H428.4V649.9h7.548v11.419Z',
    attr: {
      id: '_103club',
      'data-name': '103',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 103, zone: 'Lower Club', zoneId: 2 },
  };
  var _102club = {
    path:
      'M438.129,661.322V649.613h8.9V627.451h-8.9v-99.58H499v99.774h-7.549V649.9H499v11.419Z',
    attr: {
      id: '_102club',
      'data-name': '102',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 102, zone: 'Lower Club', zoneId: 2 },
  };
  var _101club = {
    path:
      'M501.919,661.322V649.613H510.4V627.451h-8.477v-99.58h57.952v99.774h-7.186V649.9h7.186v11.419Z',
    attr: {
      id: '_101',
      'data-name': '101club',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 101, zone: 'Lower Club', zoneId: 2 },
  };
  var _121club = {
    path:
      'M501.919,255.807v11.709H510.4v22.162h-8.477v99.581h57.952V289.484h-7.186V267.226h7.186V255.807Z',
    attr: {
      id: '_121club',
      'data-name': '121',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 121, zone: 'Lower Club', zoneId: 2 },
  };
  var _120club = {
    path:
      'M438.129,255.807v11.709h8.9v22.162h-8.9v99.581H499V289.484h-7.549V267.226H499V255.807Z',
    attr: {
      id: '_120club',
      'data-name': '120',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 120, zone: 'Lower Club', zoneId: 2 },
  };
  var _119club = {
    path:
      'M375.081,255.807v11.709h8.9v22.162h-8.9v99.581h60.871V289.484H428.4V267.226h7.548V255.807Z',
    attr: {
      id: '_119club',
      'data-name': '119',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 119, zone: 'Lower Club', zoneId: 2 },
  };
  var _118club = {
    path:
      'M312.9,255.807v11.709h8.9v22.162h-8.9v99.581h60.871V289.484h-7.549V267.226h7.549V255.807Z',
    attr: {
      id: '_118club',
      'data-name': '118',

      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',

      'stroke-opacity': '1',
    },
    data: { zoneNo: 118, zone: 'Lower Club', zoneId: 2 },
  };

  zones.push(
    _105,
    _107,
    _109,
    _110,
    _112,
    _113,
    _115,
    _117,
    _122,
    _124,
    _126,
    _127,
    _129,
    _130,
    _132,
    _134,
    l31,
    l30,
    l29,
    l28,
    l27,
    l26,
    l25,
    l24,
    l23,
    l22,
    l21,
    l20,
    l19,
    l18,
    l17,
    l16,
    l15,
    l14,
    l13,
    l12,
    l11,
    l10,
    l9,
    l8,
    l7,
    l6,
    l5,
    l4,
    l3,
    l2,
    l1,
    skyaa,
    skye,
    skyd,
    skyc,
    sncc,
    skyb,
    skya,
    snee,
    sndd,
    snbb,
    snaa,
    sne,
    snd,
    snc,
    snb,
    sna,
    _234,
    _233,
    _232,
    _231,
    _230,
    _201,
    _202,
    _203,
    _204,
    _205,
    _206,
    _207,
    _208,
    _209,
    _210,
    _211,
    _212,
    _213,
    _214,
    _215,
    _216,
    _217,
    _218,
    _219,
    _220,
    _221,
    _222,
    _223,
    _224,
    _225,
    _226,
    _101club,
    _102club,
    _103club,
    _104club,
    _118club,
    _119club,
    _120club,
    _121club
  );

  return zones;
};
