const singleRow = [
    {
      value: 1,
      label: 'Row 1',
    },
  ];
  
  const defaultRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 32; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  const upperRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 18; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  const letterAndNumberRows = () => {
    let arr = defaultRows()

    arr.push({
      value: "D",
      label: "Row D"
    })
    arr.push({
      value: "E",
      label: "Row E"
    })
    arr.push({
      value: "F",
      label: "Row F"
    })

    return arr
  }
  
  export default {
    SINGLE_ROW: singleRow,
    DEFAULT_ROWS: defaultRows(),
    UPPER_ROWS: upperRows(),
    NUMBER_AND_LETTER_ROWS: letterAndNumberRows()
  };
  