const defaultRows = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "BB", "CC", "DD", "EE", "FF", "GG", "HH", "II", "JJ", "KK", "LL", "MM", "NN", "OO", "PP", "QQ", "RR", "SS", "TT", "UU", "VV", "WW", "XX", "YY", "ZZ"];
  
    for (let i = 1; i <= 4; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const lowerZoneRows = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "BB", "CC", "DD", "EE", "FF", "GG", "HH", "II", "JJ", "KK", "LL", "MM", "NN", "OO", "PP", "QQ", "RR", "SS", "TT", "UU", "VV", "WW", "XX", "YY", "ZZ"];
  
    for (let i = 1; i <= 4; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const upperZoneRows = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "BB", "CC", "DD", "EE", "FF", "GG", "HH", "II", "JJ", "KK", "LL", "MM", "NN", "OO", "PP", "QQ", "RR", "SS", "TT", "UU", "VV", "WW", "XX", "YY", "ZZ"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const middleZoneRows = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H"];
  
    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const theDenRows = () => [
    {
      value: "1",
      label: "Row 1",
    },
    {
        value: "2",
        label: "Row 2",
      },
  ];
  
  export default {
    THE_DEN_ROWS: theDenRows(),
    LOWER_ZONE_ROWS: lowerZoneRows(),
    UPPER_ZONE_ROWS: upperZoneRows(),
    MIDDLE_ZONE_ROWS: middleZoneRows(),
    DEFAULT_ROWS: defaultRows(),
  };
  