export const styles = (theme) => ({
    bannerContainer: {
      maxWidth: 1366,
    },
    bannerImageContainer: {
      position: "relative",
      padding: "32px",
      display: "flex",
      justifyContents: "center",
      alignItems: "center",
      width: "100%"
    },
    bannerImage: {
      width: "auto",
      height: "340px",
    },
    bannerHeadlineContainer: {
      display: "block",
    },
  });
  