/** @format */

export const getZones = (rsr) => {
  let zones = [];

  var _1 = {
    path: "M 1816.00,1456.00 C 1816.00,1456.00 1900.00,1456.00 1900.00,1456.00 1900.00,1456.00 1900.00,1525.00 1900.00,1525.00 1900.00,1525.00 1816.00,1525.00 1816.00,1525.00 1816.00,1525.00 1816.00,1456.00 1816.00,1456.00 Z",
    attr: {
      id: "_1",
      "data-name": "1",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "1", zone: "Lower Zone", zoneId: "1" },
  };

  var _2 = {
    path: "M 1632.00,1456.00 C 1632.00,1456.00 1795.00,1456.00 1795.00,1456.00 1795.00,1456.00 1795.00,1525.00 1795.00,1525.00 1795.00,1525.00 1632.00,1525.00 1632.00,1525.00 1632.00,1525.00 1632.00,1456.00 1632.00,1456.00 Z",
    attr: {
      id: "_2",
      "data-name": "2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Lower Zone", zoneId: "1" },
  };

  var _3 = {
    path: "M 1442.00,1456.00 C 1442.00,1456.00 1611.00,1456.00 1611.00,1456.00 1611.00,1456.00 1611.00,1621.00 1611.00,1621.00 1611.00,1621.00 1442.00,1621.00 1442.00,1621.00 1442.00,1621.00 1442.00,1456.00 1442.00,1456.00 Z",
    attr: {
      id: "_3",
      "data-name": "3",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "3", zone: "Lower Zone", zoneId: "1" },
  };

  var _4 = {
    path: "M 1253.00,1456.00 C 1253.00,1456.00 1421.00,1456.00 1421.00,1456.00 1421.00,1456.00 1421.00,1621.00 1421.00,1621.00 1421.00,1621.00 1253.00,1621.00 1253.00,1621.00 1253.00,1621.00 1253.00,1456.00 1253.00,1456.00 Z",
    attr: {
      id: "_4",
      "data-name": "4",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "4", zone: "Lower Zone", zoneId: "1" },
  };

  var _5 = {
    path: "M 1061.00,1456.00 C 1061.00,1456.00 1232.00,1456.00 1232.00,1456.00 1232.00,1456.00 1232.00,1621.00 1232.00,1621.00 1232.00,1621.00 1061.00,1621.00 1061.00,1621.00 1061.00,1621.00 1061.00,1456.00 1061.00,1456.00 Z",
    attr: {
      id: "_5",
      "data-name": "5",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "5", zone: "Lower Zone", zoneId: "1" },
  };

  var _6 = {
    path: "M 866.00,1456.00 C 866.00,1456.00 1040.00,1456.00 1040.00,1456.00 1040.00,1456.00 1040.00,1524.00 1040.00,1524.00 1040.00,1524.00 866.00,1524.00 866.00,1524.00 866.00,1524.00 866.00,1456.00 866.00,1456.00 Z",
    attr: {
      id: "_6",
      "data-name": "6",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "6", zone: "Lower Zone", zoneId: "1" },
  };

  var _7 = {
    path: "M 691.00,1456.00 C 691.00,1456.00 845.00,1456.00 845.00,1456.00 845.00,1456.00 845.00,1511.00 845.00,1511.00 845.00,1511.00 691.00,1511.00 691.00,1511.00 691.00,1511.00 691.00,1456.00 691.00,1456.00 Z",
    attr: {
      id: "_7",
      "data-name": "7",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "7", zone: "Lower Zone", zoneId: "1" },
  };

  var _7b = {
    path: "M 691.00,1533.00 C 691.00,1533.00 713.00,1532.00 713.00,1532.00 713.00,1532.00 845.00,1532.00 845.00,1532.00 845.00,1532.00 845.00,1621.00 845.00,1621.00 845.00,1621.00 691.00,1621.00 691.00,1621.00 691.00,1621.00 691.00,1533.00 691.00,1533.00 Z",
    attr: {
      id: "_7b",
      "data-name": "7b",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "7B", zoneId: "2" },
  };

  var _8 = {
    path: "M 522.00,1456.00 C 522.00,1456.00 670.00,1456.00 670.00,1456.00 670.00,1456.00 670.00,1512.00 670.00,1512.00 670.00,1512.00 522.00,1512.00 522.00,1512.00 522.00,1512.00 522.00,1456.00 522.00,1456.00 Z",
    attr: {
      id: "_8",
      "data-name": "8",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "8", zone: "Lower Zone", zoneId: "1" },
  };

  var _8b = {
    path: "M 522.00,1533.00 C 522.00,1533.00 670.00,1533.00 670.00,1533.00 670.00,1533.00 670.00,1621.00 670.00,1621.00 670.00,1621.00 624.00,1621.00 624.00,1621.00 624.00,1621.00 607.00,1620.00 607.00,1620.00 607.00,1620.00 522.00,1620.00 522.00,1620.00 522.00,1620.00 522.00,1533.00 522.00,1533.00 Z",
    attr: {
      id: "_8b",
      "data-name": "8b",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "8B", zoneId: "2" },
  };

  var _9 = {
    path: "M 349.00,1456.00 C 349.00,1456.00 501.00,1456.00 501.00,1456.00 501.00,1456.00 501.00,1513.00 501.00,1513.00 501.00,1513.00 349.00,1513.00 349.00,1513.00 349.00,1513.00 349.00,1456.00 349.00,1456.00 Z",
    attr: {
      id: "_9",
      "data-name": "9",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "9", zone: "Lower Zone", zoneId: "1" },
  };

  var _9b = {
    path: "M 349.00,1534.00 C 349.00,1534.00 501.00,1534.00 501.00,1534.00 501.00,1534.00 501.00,1620.00 501.00,1620.00 501.00,1620.00 349.00,1620.00 349.00,1620.00 349.00,1620.00 349.00,1534.00 349.00,1534.00 Z",
    attr: {
      id: "_9b",
      "data-name": "9b",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "9B", zoneId: "2" },
  };

  var _10 = {
    path: "M 171.00,1367.00 C 163.18,1361.39 158.11,1356.92 167.02,1349.15 168.30,1348.02 169.38,1347.20 171.00,1346.60 173.31,1345.75 179.29,1346.00 182.00,1346.00 182.00,1346.00 203.00,1346.00 203.00,1346.00 205.65,1346.01 208.57,1345.81 211.00,1347.02 214.60,1348.82 230.90,1365.92 235.00,1370.00 235.00,1370.00 247.00,1381.04 247.00,1381.04 247.00,1381.04 270.00,1403.96 270.00,1403.96 270.00,1403.96 279.00,1412.04 279.00,1412.04 279.00,1412.04 304.00,1436.83 304.00,1436.83 304.00,1436.83 320.00,1452.00 320.00,1452.00 322.41,1454.41 326.23,1457.82 327.40,1461.00 328.12,1462.94 328.00,1465.91 328.00,1468.00 328.00,1468.00 328.00,1493.00 328.00,1493.00 328.00,1499.84 329.28,1503.37 323.94,1508.42 321.40,1510.83 316.75,1516.13 313.00,1514.83 310.31,1513.90 301.54,1504.54 299.00,1502.00 293.21,1510.09 285.23,1516.35 278.00,1523.09 278.00,1523.09 247.00,1552.99 247.00,1552.99 247.00,1552.99 235.00,1563.95 235.00,1563.95 232.98,1565.75 230.94,1567.85 228.00,1566.83 225.41,1565.94 218.31,1558.31 216.00,1556.00 216.00,1556.00 191.00,1531.00 191.00,1531.00 191.00,1531.00 174.00,1514.99 174.00,1514.99 174.00,1514.99 129.00,1470.00 129.00,1470.00 122.13,1463.13 103.72,1445.59 99.00,1439.00 99.00,1439.00 171.00,1367.00 171.00,1367.00 Z",
    attr: {
      id: "_10",
      "data-name": "10",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "10", zone: "Lower Zone", zoneId: "1" },
  };

  var _11 = {
    path: "M 155.00,1170.00 C 155.00,1170.00 202.00,1170.00 202.00,1170.00 202.00,1170.00 202.00,1325.00 202.00,1325.00 202.00,1325.00 155.00,1325.00 155.00,1325.00 155.00,1325.00 155.00,1170.00 155.00,1170.00 Z",
    attr: {
      id: "_11",
      "data-name": "11",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "11", zone: "Lower Zone", zoneId: "1" },
  };

  var _11b = {
    path: "M 52.00,1170.00 C 52.00,1170.00 134.00,1170.00 134.00,1170.00 134.00,1170.00 134.00,1325.00 134.00,1325.00 134.00,1325.00 52.00,1325.00 52.00,1325.00 52.00,1325.00 52.00,1170.00 52.00,1170.00 Z",
    attr: {
      id: "_11b",
      "data-name": "11b",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "11B", zoneId: "2" },
  };

  var _12 = {
    path: "M 155.00,1064.00 C 155.00,1064.00 202.00,1064.00 202.00,1064.00 202.00,1064.00 202.00,1149.00 202.00,1149.00 202.00,1149.00 155.00,1149.00 155.00,1149.00 155.00,1149.00 155.00,1064.00 155.00,1064.00 Z",
    attr: {
      id: "_12",
      "data-name": "12",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "12", zone: "Lower Zone", zoneId: "1" },
  };

  var _13 = {
    path: "M 480.00,308.00 C 480.00,308.00 579.00,308.00 579.00,308.00 579.00,308.00 579.00,534.00 579.00,534.00 579.00,534.00 480.00,534.00 480.00,534.00 480.00,534.00 480.00,308.00 480.00,308.00 Z",
    attr: {
      id: "_13",
      "data-name": "13",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "13", zone: "Lower Zone", zoneId: "1" },
  };

  var _14 = {
    path: "M 600.00,308.00 C 600.00,308.00 727.00,308.00 727.00,308.00 727.00,308.00 727.00,534.00 727.00,534.00 727.00,534.00 600.00,534.00 600.00,534.00 600.00,534.00 600.00,308.00 600.00,308.00 Z",
    attr: {
      id: "_14",
      "data-name": "14",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "14", zone: "Lower Zone", zoneId: "1" },
  };

  var _15 = {
    path: "M 748.00,308.00 C 748.00,308.00 890.00,308.00 890.00,308.00 890.00,308.00 890.00,534.00 890.00,534.00 890.00,534.00 748.00,534.00 748.00,534.00 748.00,534.00 748.00,308.00 748.00,308.00 Z",
    attr: {
      id: "_15",
      "data-name": "15",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "15", zone: "Lower Zone", zoneId: "1" },
  };

  var _16 = {
    path: "M 911.00,308.00 C 911.00,308.00 1048.00,308.00 1048.00,308.00 1048.00,308.00 1048.00,534.00 1048.00,534.00 1048.00,534.00 911.00,534.00 911.00,534.00 911.00,534.00 911.00,308.00 911.00,308.00 Z",
    attr: {
      id: "_16",
      "data-name": "16",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "16", zone: "Lower Zone", zoneId: "1" },
  };

  var _17 = {
    path: "M 1069.00,308.00 C 1069.00,308.00 1212.00,308.00 1212.00,308.00 1212.00,308.00 1212.00,534.00 1212.00,534.00 1212.00,534.00 1069.00,534.00 1069.00,534.00 1069.00,534.00 1069.00,308.00 1069.00,308.00 Z",
    attr: {
      id: "_17",
      "data-name": "17",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "17", zone: "Lower Zone", zoneId: "1" },
  };

  var _18 = {
    path: "M 1233.00,308.00 C 1233.00,308.00 1368.00,308.00 1368.00,308.00 1368.00,308.00 1368.00,534.00 1368.00,534.00 1368.00,534.00 1233.00,534.00 1233.00,534.00 1233.00,534.00 1233.00,308.00 1233.00,308.00 Z",
    attr: {
      id: "_18",
      "data-name": "18",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "18", zone: "Lower Zone", zoneId: "1" },
  };

  var _19 = {
    path: "M 1389.00,308.00 C 1389.00,308.00 1530.00,308.00 1530.00,308.00 1530.00,308.00 1530.00,534.00 1530.00,534.00 1530.00,534.00 1389.00,534.00 1389.00,534.00 1389.00,534.00 1389.00,308.00 1389.00,308.00 Z",
    attr: {
      id: "_19",
      "data-name": "19",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "19", zone: "Lower Zone", zoneId: "1" },
  };

  var _20 = {
    path: "M 1551.00,308.00 C 1551.00,308.00 1670.00,308.00 1670.00,308.00 1670.00,308.00 1670.00,534.00 1670.00,534.00 1670.00,534.00 1551.00,534.00 1551.00,534.00 1551.00,534.00 1551.00,308.00 1551.00,308.00 Z",
    attr: {
      id: "_20",
      "data-name": "20",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "20", zone: "Lower Zone", zoneId: "1" },
  };

  var _21 = {
    path: "M 1691.00,308.00 C 1691.00,308.00 1796.00,308.00 1796.00,308.00 1796.00,308.00 1796.00,534.00 1796.00,534.00 1796.00,534.00 1691.00,534.00 1691.00,534.00 1691.00,534.00 1691.00,308.00 1691.00,308.00 Z",
    attr: {
      id: "_21",
      "data-name": "21",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "21", zone: "Lower Zone", zoneId: "1" },
  };

  var _PartyZone = {
    path: "M 223.00,308.00 C 223.00,308.00 459.00,308.00 459.00,308.00 459.00,308.00 459.00,534.00 459.00,534.00 459.00,534.00 407.96,534.00 407.96,534.00 407.96,534.00 407.96,547.00 407.96,547.00 407.96,547.00 407.00,559.00 407.00,559.00 407.00,559.00 407.00,726.00 407.00,726.00 407.00,726.00 406.00,741.00 406.00,741.00 406.00,741.00 406.00,1325.00 406.00,1325.00 406.00,1325.00 223.00,1325.00 223.00,1325.00 223.00,1325.00 223.00,308.00 223.00,308.00 Z",
    attr: {
      id: "_PartyZone",
      "data-name": "PartyZone",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Party Zone", zoneId: "2" },
  };

  var _VIPZone = {
    path: "M 1817.00,308.00 C 1817.00,308.00 1936.00,308.00 1936.00,308.00 1936.00,308.00 1936.00,362.00 1936.00,362.00 1936.00,362.00 1937.00,379.00 1937.00,379.00 1937.00,379.00 1937.00,660.00 1937.00,660.00 1937.00,660.00 1937.00,787.00 1937.00,787.00 1937.00,787.00 1938.00,802.00 1938.00,802.00 1938.00,802.00 1938.00,1047.00 1938.00,1047.00 1938.00,1047.00 1938.00,1159.00 1938.00,1159.00 1938.00,1159.00 1939.00,1174.00 1939.00,1174.00 1939.00,1174.00 1939.00,1525.00 1939.00,1525.00 1939.00,1525.00 1921.00,1525.00 1921.00,1525.00 1921.00,1525.00 1921.00,1366.00 1921.00,1366.00 1921.00,1366.00 1921.00,1335.00 1921.00,1335.00 1920.91,1327.77 1919.03,1321.94 1912.00,1318.57 1908.44,1316.86 1904.83,1317.01 1901.00,1317.00 1901.00,1317.00 1875.00,1317.00 1875.00,1317.00 1875.00,1317.00 1875.00,534.00 1875.00,534.00 1875.00,534.00 1817.00,534.00 1817.00,534.00 1817.00,534.00 1817.00,308.00 1817.00,308.00 Z",
    attr: {
      id: "_VIPZone",
      "data-name": "VIPZone",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "VIP Zone", zoneId: "2" },
  };

  zones.push(
    _1,
    _2,
    _3,
    _4,
    _5,
    _6,
    _7,
    _7b,
    _8,
    _8b,
    _9,
    _9b,
    _10,
    _11,
    _11b,
    _12,
    _13,
    _14,
    _15,
    _16,
    _17,
    _18,
    _19,
    _20,
    _21,
    _PartyZone,
    _VIPZone
  );
  return zones;
};
