const zones = [
  {
    label: "Club Lower 104",
  },
  {
    label: "Club Lower 105",
  },
  {
    label: "Club Lower 106",
  },
  {
    label: "Club Lower 107",
  },
  {
    label: "Club Lower 108",
  },
  {
    label: "Lower Bowl 101",
  },
  {
    label: "Lower Bowl 102",
  },
  {
    label: "Lower Bowl - No Alcohol 102",
  },
  {
    label: "Lower Bowl 103",
  },
  {
    label: "Lower Bowl 109",
  },
  {
    label: "Lower Bowl 110",
  },
  {
    label: "Lower Bowl 111",
  },
  {
    label: "Lower Bowl 112",
  },
  {
    label: "Lower Bowl 113",
  },
  {
    label: "Lower Bowl 114",
  },
  {
    label: "Lower Bowl 115",
  },
  {
    label: "Lower Bowl 116",
  },
  {
    label: "Lower Bowl 117",
  },
  {
    label: "Lower Bowl 118",
  },
  {
    label: "Lower Bowl 119",
  },
  {
    label: "Lower Bowl 120",
  },
  {
    label: "Lower Bowl 121",
  },
  {
    label: "Lower Bowl 122",
  },
  {
    label: "Upper Bowl 301",
  },
  {
    label: "Upper Bowl 302",
  },
  {
    label: "Upper Bowl 303",
  },
  {
    label: "Upper Bowl 304",
  },
  {
    label: "Upper Bowl 305",
  },
  {
    label: "Upper Bowl 306",
  },
  {
    label: "Upper Bowl 307",
  },
  {
    label: "Upper Bowl 308",
  },
  {
    label: "Upper Bowl 309",
  },
  {
    label: "Upper Bowl 310",
  },
  {
    label: "Upper Bowl 311",
  },
  {
    label: "Upper Bowl - No Alcohol 311",
  },
  {
    label: "Upper Bowl 312",
  },
  {
    label: "Upper Bowl - No Alcohol 312",
  },
  {
    label: "Upper Bowl 313",
  },
  {
    label: "Upper Bowl 314",
  },
  {
    label: "Upper Bowl 315",
  },
  {
    label: "Upper Bowl 316",
  },
  {
    label: "Upper Bowl 317",
  },
  {
    label: "Upper Bowl - No Alcohol 317",
  },
  {
    label: "Upper Bowl 318",
  },
  {
    label: "Upper Bowl - No Alcohol 318",
  },
  {
    label: "Upper Bowl 319",
  },
  {
    label: "Upper Bowl 320",
  },
  {
    label: "Upper Bowl 321",
  },
  {
    label: "Upper Bowl 322",
  },
  {
    label: "Upper Bowl 323",
  },
  {
    label: "Upper Bowl 324",
  },
  {
    label: "Upper Bowl 325",
  },
  {
    label: "Upper Bowl 326",
  },
  {
    label: "Upper Bowl 327",
  },
  {
    label: "Upper Bowl 328",
  },
  {
    label: "Upper Bowl 329",
  },
  {
    label: "Upper Bowl 330",
  },
  {
    label: "Upper Bowl - Balcony 417",
  },
  {
    label: "Upper Bowl - Balcony 418",
  },
  {
    label: "Upper Bowl - Balcony 419",
  },
  {
    label: "Sports Bar 400",
  },
  {
    label: "Sports Bar 401",
  },
  {
    label: "Sports Bar 402",
  },
  {
    label: "WC 311",
  },
  {
    label: "WC 317",
  },
  {
    label: "WC 400E",
  },
  {
    label: "WC 400S",
  },
];

export default zones;
