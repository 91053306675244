import React, { useEffect, useState } from "react";
import { Typography, withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  container: {
    borderRadius: "10px",
    padding: "15px",
    backgroundPosition: "center",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize:
      "cover" /* Resize the background image to cover the entire container */,
    height: "375px",
    position: "relative",
  },
  date: {
    color: "#ffffff",
    padding: "10px 0px 20px 0px",
  },
  title: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: "1.5rem",
    color: "#ffffff",
    paddingBottom: "20px",
    display: "-webkit-box",
    height: "3em",
    lineHeight: "1.5em",
    maxWidth: "100%",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  description: {
    color: "#ffffff",
    paddingBottom: "30px",
    height: "100px",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  viewPost: {
    color: "#000000",
    textDecoration: "none",
    textTransform: "none",
  },
  actionArea: {
    position: "absolute",
    bottom: "0",
  },
  [theme.breakpoints.down("md")]: {
    container: {
      borderRadius: "10px",
      padding: "15px 40px",
      height: "fit-content",
    },
    date: {
      color: "#ffffff",
      padding: "10px 0px 20px 0px",
    },
    title: {
      color: "#ffffff",
      paddingBottom: "10px",
    },
    description: {
      color: "#ffffff",
      padding: "0px",
      marginBottom: "40px",
      height: "fit-content",
    },
    viewPost: {
      color: "#000000",
      textDecoration: "none",
      textTransform: "none",
    },
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      borderRadius: "10px",
      padding: "15px 20px",
    },
    date: {
      color: "#ffffff",
      padding: "10px 0px 20px 0px",
    },
    title: {
      color: "#ffffff",
      paddingBottom: "10px",
    },
    description: {
      color: "#ffffff",
      padding: "0px",
    },
    viewPost: {
      color: "#000000",
      textDecoration: "none",
      textTransform: "none",
    },
  },
});

const BlogCard = ({ source, classes }) => {
  const [content, setContent] = useState();
  const [title, setTitle] = useState();
  const [date, setDate] = useState();
  const [name, setName] = useState();
  const [imageId, setImageId] = useState();
  const [description, setDescription] = useState();
  const DOMPurify = require("dompurify")(window);

  useEffect(() => {
    fetchContent(source);
  }, []);

  function parseDescription(data) {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element[0] === "p") {
        setDescription(DOMPurify.sanitize(data[i][1]));
        return;
      }
    }
  }

  function fetchContent(url) {
    try {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setContent(data);
          setName(DOMPurify.sanitize(data.find(element => element[0] === 'title')[1]));
          setTitle(DOMPurify.sanitize(data.find(element => element[0] === 'h1')[1]))
          setDate(DOMPurify.sanitize(data.find(element => element[0] === 'time')[1]));
          setImageId(DOMPurify.sanitize(data.find(element => element[0] === 'picture')[1]));
          parseDescription(data);
        });
    } catch (error) {
      console.log(error);
    }
  }

  if (!content) {
    return null;
  }
  return (
    <Card
      className={classes.container}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 160, 255, 0.9),  rgba(0, 160, 255, 0.9)), url(${imageId})`,
      }}
    >
      <CardContent>
        <Typography
          component="p"
          className={classes.date}
          dangerouslySetInnerHTML={{ __html: date }}
        ></Typography>
        <Typography
          gutterBottom
          component="h2"
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: title }}
        ></Typography>
        <Typography
          component="p"
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: description }}
        ></Typography>
      </CardContent>
      <CardActions className={classes.actionArea}>
        <Button
          size="small"
          color="primary"
          style={{ position: "relative", bottom: "10px" }}
        >
          <Link to={`/blogs/${name}`} className={classes.viewPost}>
            <Typography
              component="p"
              style={{ color: "#ffffff", fontWeight: 700 }}
            >
              View Post →
            </Typography>
          </Link>
        </Button>
      </CardActions>
    </Card>
  );
};
export default withStyles(styles)(BlogCard);
