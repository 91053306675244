import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import querySearch from 'stringquery';

import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  [theme.breakpoints.up('md')]: {
    formContainer: {
      margin: '5rem 0'
    }
  },
  [theme.breakpoints.down('sm')]: {
    formContainer: {
      marginTop: '3rem',
      padding: '0 1rem'
    }
  },
  textField: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    border: 'solid 1px #e5e5ea',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  textFieldError: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 23, 68, .05)',
    border: 'solid 1px rgba(255, 23, 68, .5)',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  form: {
    marginTop: '2rem'
  },
  input: {
    padding: 0,
    fontSize: '0.875rem'
  },
  button: {
    padding: '.875rem',
    marginTop: '.5rem'
  }
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isSubmitted: false,
      newPassword: '',
      confirmPassword: '',
      isValidNewPassword: true,
      isValidConfirmPassword: true,
      isValidCode: true,
      isPasswordChanged: false,
      code: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitNewPassword = this.submitNewPassword.bind(this);
  }

  componentDidMount() {
    const { reset_token, email } = querySearch(this.props.location.search);

    this.setState({
      code: reset_token,
      email
    });
  }

  isValidPasswords() {
    const { newPassword, confirmPassword } = this.state;

    const isValidNewPassword = newPassword !== '' && newPassword.length >= 6;
    const isValidConfirmPassword =
      confirmPassword !== '' && newPassword === confirmPassword;

    this.setState({
      isValidNewPassword,
      isValidConfirmPassword
    });

    return isValidNewPassword && isValidConfirmPassword;
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async submitNewPassword(e) {
    e.preventDefault();

    try {
      this.setState({
        isSubmitting: true,
        isPasswordChanged: false
      });

      const { code, newPassword, email } = this.state;

      if (this.isValidPasswords()) {
        await Auth.forgotPasswordSubmit(email, code, newPassword);

        this.setState({
          isSubmitting: false,
          isPasswordChanged: true,
          code: '',
          newPassword: '',
          confirmPassword: ''
        });
      } else {
        this.setState({
          isSubmitting: false,
          isPasswordChanged: false
        });
      }
    } catch (e) {
      console.error(e);
      switch(e.code) {
        case 'ExpiredCodeException': {
          alert('Sorry, the link you are visiting is outdated. Please try again.')
          break;
        }

        case 'CodeMismatchException': {
          alert('The link you are visiting is incorrect. Please try again.');
          break;
        }



        default: {
          alert('Sorry something went wrong. Contact support using the chat window in the bottom right for assistance');
          break;
        }
      }

      this.setState({
        isSubmitting: false,
        isPasswordChanged: false
      });
    }
  }

  renderNewPassword() {
    const { classes } = this.props;
    const {
      isSubmitting,
      newPassword,
      confirmPassword,
      isValidNewPassword,
      isValidConfirmPassword
    } = this.state;

    return (
      <React.Fragment>
        <Typography
          style={{
            marginBottom: '1rem'
          }}
          variant="title"
          color="textPrimary"
        >
          Enter New Password
        </Typography>
        <form onSubmit={this.submitNewPassword} className={classes.form}>
          <TextField
            className={
              isValidNewPassword ? classes.textField : classes.textFieldError
            }
            disabled={isSubmitting}
            error={true}
            id="newPassword"
            placeholder="New Password"
            name="newPassword"
            fullWidth={true}
            value={newPassword}
            onChange={this.handleChange}
            margin="dense"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input
              }
            }}
            type="password"
          />

          {isValidNewPassword ? (
            ''
          ) : (
            <FormHelperText error={true}>
              Your new password must be at least 6 characters
            </FormHelperText>
          )}

          <TextField
            className={
              isValidConfirmPassword
                ? classes.textField
                : classes.textFieldError
            }
            disabled={isSubmitting}
            error={true}
            id="confirmPassword"
            placeholder="Confirm New Password"
            name="confirmPassword"
            fullWidth={true}
            value={confirmPassword}
            onChange={this.handleChange}
            margin="dense"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input
              }
            }}
            type="password"
          />

          {isValidConfirmPassword ? (
            ''
          ) : (
            <FormHelperText error={true}>Passwords do not match</FormHelperText>
          )}

          <Button
            className={classes.button}
            color="primary"
            disabled={isSubmitting}
            fullWidth={true}
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const { isPasswordChanged } = this.state;

    return (
      <div>
        <Grid
          className={classes.formContainer}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            {!isPasswordChanged && this.renderNewPassword()}
            {isPasswordChanged && (
              <React.Fragment>
                <Typography
                  style={{
                    marginBottom: '1rem'
                  }}
                  variant="title"
                >
                  Your password has been changed
                </Typography>
                <Typography variant="body2">
                  <a
                    href="/login"
                    style={{ color: '#2196f3', textDecoration: 'none' }}
                  >
                    Click here to login.
                  </a>
                </Typography>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPassword);
