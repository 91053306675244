export const getZones = rsr => {
  let zones = [];

  var _101 = {
    path:
      'M649.13,535.91l13.09-15.56L706.09,551c-9.39,14.05-17.85,21.45-19.63,22.94Z',
    attr: {
      id: '_101',
      'data-name': '101',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 101,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _103 = {
    path:
      'M593.85,525.18l9.11-4.49,4.24,7.53.42-.18a62.939,62.939,0,0,0,16.83-11.18c9.27,9.11,54.44,54.95,58.91,59.49-3.88,3.74-39.15,36.48-89.51,42.79Z',
    attr: {
      id: '_103',
      'data-name': '103',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 103,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _104 = {
    path: 'M305.95,215.125v93.84H371.9v-93.84Z',
    attr: {
      id: '_104',
      'data-name': '104',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't218.1 310.745',
    data: {
      zoneNo: 104,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _105 = {
    path: 'M443,502.71v-77.1h28.27V408.87H517v93.84Z',
    attr: {
      id: '_105',
      'data-name': '105',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 117',
    data: {
      zoneNo: 105,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _106 = {
    path: 'M372.56,502.71V408.87h38.1v17H439v76.84Z',
    attr: {
      id: '_106',
      'data-name': '106',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 117',
    data: {
      zoneNo: 106,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _107 = {
    path: 'M305.95,215.125v93.84h66.87v-93.84Z',
    attr: {
      id: '_107',
      'data-name': '107',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-4.9 310.745',
    data: {
      zoneNo: 107,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _109 = {
    path:
      'M293.25,619.27c-11.43,0-52-3.14-95.82-43.42L261,511.28l8.35,5.15-7.18,12.62,9.17,4.68-7.94,22.09,13.11,5.83,13-37.2a19,19,0,0,0,5.58.92v93.87C294.72,619.25,294.1,619.27,293.25,619.27Z',
    attr: {
      id: '_109',
      'data-name': '109',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 109,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _111 = {
    path:
      'M193.81,572.83c-3.66-3.92-34.43-38.16-44.11-89.75h91.57a98,98,0,0,0,15.54,24.64Z',
    attr: {
      id: '_111',
      'data-name': '111',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 111,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _112 = {
    path: 'M366,114.625h91.65v71.77H366Z',
    attr: {
      id: '_112',
      'data-name': '112',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-215.34 291.725',
    data: {
      zoneNo: 112,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _113 = {
    path:
      'M149.66,401.17c.72-5.77,7.91-55.16,44.9-89.57l62,64.32c-2,1.93-11.45,11.58-15.16,25.25Z',
    attr: {
      id: '_113',
      'data-name': '113',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 113,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _115 = {
    path:
      'M197,307.23A178.67,178.67,0,0,1,242.71,276l12,34.7-8.73,5,20.35,52.13L261,372.48Z',
    attr: {
      id: '_115',
      'data-name': '115',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 115,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _116 = {
    path:
      'M288.21,357.44,268,305.81l-8.31,3.09-12.27-34.35c11.46-6.43,43.08-10.35,47.66-10.89v93.78Z',
    attr: {
      id: '_116',
      'data-name': '116',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 116,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _117 = {
    path: 'M305.95,215.125v93.84H373.4v-93.84Z',
    attr: {
      id: '_117',
      'data-name': '117',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-4.9 48.975',
    data: {
      zoneNo: 117,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _118 = {
    path: 'M305.95,215.125v78.16h66.44v-78.16Z',
    attr: {
      id: '_118',
      'data-name': '118',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't67.61 48.975',
    data: {
      zoneNo: 118,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _119 = {
    path: 'M490.81,225.26V204.94h-11.1v20.32H443V147.1h74v78.16Z',
    attr: {
      id: '_119',
      'data-name': '119',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 117',
    data: {
      zoneNo: 119,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _120 = {
    path: 'M305.95,215.125v93.222h66V215.125Z',
    attr: {
      id: '_120',
      'data-name': '120',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't218.05 48.975',
    data: {
      zoneNo: 120,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _121 = {
    path:
      'M624.45,365.94c-7.11-6.93-16.74-11.13-16.83-11.17l-.42-.18-4.2,7.53-9.11-4.5v-94c50.36,6.32,85.63,39.06,89.51,42.8C678.89,311,633.72,356.83,624.45,365.94Z',
    attr: {
      id: '_121',
      'data-name': '121',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 121,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _123 = {
    path: 'M627.3,253.06l59.25-59.28,19.2,22.2-69.41,47.06Z',
    attr: {
      id: '_123',
      'data-name': '123',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 117',
    data: {
      zoneNo: 123,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _124 = {
    path:
      'M639.12,400.6c-1-4.78-4.55-11.22-5.49-12.9L708,336.53c16.74,18.53,23.62,58.79,24.46,64.07Z',
    attr: {
      id: '_124',
      'data-name': '124',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 124,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _125 = {
    path: 'M644.933,444.55v31.77h-94.42V444.55Z',
    attr: {
      id: '_125',
      'data-name': '125',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't89.697 -37.68',
    data: {
      zoneNo: 125,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _126 = {
    path: 'M644.933,444.55v34.2h-94.42v-34.2Z',
    attr: {
      id: '_126',
      'data-name': '126',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't89.697 0.5',
    data: {
      zoneNo: 126,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _127 = {
    path:
      'M633.63,497c.94-1.68,4.45-8.12,5.49-12.9h93.33c-.84,5.28-7.72,45.53-24.46,64.07Z',
    attr: {
      id: '_127',
      'data-name': '127',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 127,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };

  var _202 = {
    path:
      'M648.66,607.29A130.241,130.241,0,0,0,690.56,576l14.62,14.14,19.88-23.09,16.55,11.83c-3.13,4.33-31.54,42.38-77.44,64.82Z',
    attr: {
      id: '_202',
      'data-name': '202',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 202,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _203 = {
    path:
      'M593.35,624.32c3.93-.51,32.94-4.6,50.2-14.73,6.88,16.11,14,33.43,15.19,36.31-3.36,1.79-28.23,14.5-65.39,18.28Z',
    attr: {
      id: '_203',
      'data-name': '203',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 203,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _204 = {
    path: 'M305.95,215.125v40h66v-40Z',
    attr: {
      id: '_204',
      'data-name': '204',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't218.05 409.375',
    data: {
      zoneNo: 204,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _205 = {
    path: 'M305.95,215.125v40h74v-40Z',
    attr: {
      id: '_205',
      'data-name': '205',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't138.05 409.375',
    data: {
      zoneNo: 205,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _206 = {
    path: 'M305.95,215.125v40h73.875v-40Z',
    attr: {
      id: '_206',
      'data-name': '206',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't60.05 409.375',
    data: {
      zoneNo: 206,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _207 = {
    path: 'M305.95,215.125v40h54.925v-40Z',
    attr: {
      id: '_207',
      'data-name': '207',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't0 409.375',
    data: {
      zoneNo: 207,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _208 = {
    path:
      'M281.72,664.38c-15.93,0-45.63-12.61-49.65-14.36l15.75-36.74c20.52,9.56,46.83,10.93,50.73,11.08v40Z',
    attr: {
      id: '_208',
      'data-name': '208',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 208,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _209 = {
    path:
      'M227,648.34c-31.69-13.31-54.7-34.12-57.85-37,1.36-1.4,7.17-7.5,27.69-29.72,17.12,16.82,41.41,28.13,45,29.74Z',
    attr: {
      id: '_209',
      'data-name': '209',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 209,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _210 = {
    path:
      'M165.4,607.4c-25.65-22.27-40.18-51.95-42.07-56l38.19-17.2a160,160,0,0,0,31.85,43.88Z',
    attr: {
      id: '_210',
      'data-name': '210',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 210,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _211 = {
    path:
      'M121.51,546.29c-13.32-22.59-15.84-55.3-16.12-59.83h41c3.16,24.25,11,39.76,12.51,42.62Z',
    attr: {
      id: '_211',
      'data-name': '211',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 211,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _212 = {
    path: 'M366,114.625h40.09v78.38H366Z',
    attr: {
      id: '_212',
      'data-name': '212',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-259.34 288.875',
    data: {
      zoneNo: 212,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _213 = {
    path:
      'M105.89,396.92c.28-4.53,2.8-37.24,16.12-59.83l37.42,17.2c-1.53,2.87-9.35,18.38-12.51,42.63Z',
    attr: {
      id: '_213',
      'data-name': '213',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 213,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _214 = {
    path:
      'M123.83,332c1.89-4,16.42-33.7,42.07-56l28,29.29A160,160,0,0,0,162,349.15Z',
    attr: {
      id: '_214',
      'data-name': '214',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 214,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _215 = {
    path:
      'M197.37,301.8c-20.52-22.22-26.33-28.32-27.69-29.72,3.15-2.93,26.17-23.73,57.85-37l14.83,37C238.78,273.67,214.49,285,197.37,301.8Z',
    attr: {
      id: '_215',
      'data-name': '215',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 215,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _216 = {
    path:
      'M232.57,233.36c4-1.75,33.72-14.36,49.65-14.36h16.83v40c-3.9.15-30.21,1.52-50.73,11.08Z',
    attr: {
      id: '_216',
      'data-name': '216',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 216,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _217 = {
    path: 'M305.95,215.125v40h54.925v-40Z',
    attr: {
      id: '_217',
      'data-name': '217',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't0 4.375',
    data: {
      zoneNo: 217,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _218 = {
    path: 'M305.95,215.125v40h73.875v-40Z',
    attr: {
      id: '_218',
      'data-name': '218',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't60.05 4.375',
    data: {
      zoneNo: 218,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _219 = {
    path: 'M305.95,215.125v40h74v-40Z',
    attr: {
      id: '_219',
      'data-name': '219',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't138.05 4.375',
    data: {
      zoneNo: 219,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _221 = {
    path:
      'M644.05,273.79c-17.26-10.13-46.27-14.22-50.2-14.73V219.2c37.16,3.78,62,16.49,65.39,18.28C658.05,240.36,650.93,257.68,644.05,273.79Z',
    attr: {
      id: '_221',
      'data-name': '221',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 221,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _220 = {
    path: 'M305.95,215.125v40h66v-40Z',
    attr: {
      id: '_220',
      'data-name': '220',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't218.05 4.375',
    data: {
      zoneNo: 220,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _222 = {
    path:
      'M705.68,293.24l-14.62,14.13a130.3,130.3,0,0,0-41.9-31.28l15.51-36.41c45.9,22.44,74.31,60.49,77.44,64.82l-16.55,11.83Z',
    attr: {
      id: '_222',
      'data-name': '222',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 222,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _223 = {
    path:
      'M722.75,353.13a135.627,135.627,0,0,0-28.19-41.65L705.23,301l19.3,22.58.37-.25,20.23-14c6.57,8,14.65,25.46,16.07,28.57Z',
    attr: {
      id: '_223',
      'data-name': '223',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 223,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _224 = {
    path:
      'M733.22,382.22A123.913,123.913,0,0,0,725,358.1l38-15.4c6.6,13.14,9.67,28.59,10.21,31.49Z',
    attr: {
      id: '_224',
      'data-name': '224',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 224,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _225 = {
    path:
      'M763.79,438.15v-44h-7.1V383.22l18.19-3.47a142,142,0,0,1,2.23,21.75v36.65Z',
    attr: {
      id: '_225',
      'data-name': '225',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 225,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _226 = {
    path: 'M756.19,500.16V489.23h7.1v-44h13.32v36.65a142,142,0,0,1-2.23,21.75Z',
    attr: {
      id: '_226',
      'data-name': '226',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 226,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _227 = {
    path:
      'M724.5,525.28a123.911,123.911,0,0,0,8.22-24.12l40,8c-.54,2.89-3.61,18.35-10.21,31.49Z',
    attr: {
      id: '_227',
      'data-name': '227',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 227,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _228 = {
    path:
      'M694.06,571.9a135.77,135.77,0,0,0,28.19-41.65l38.45,15.21c-1.42,3.11-9.5,20.57-16.07,28.57l-20.23-14-.37-.25-19.3,22.58Z',
    attr: {
      id: '_228',
      'data-name': '228',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 228,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };

  var _319 = {
    path: 'M366,114.625h74v74H366Z',
    attr: {
      id: '_319',
      'data-name': '319',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-78 2.875',
    data: {
      zoneNo: 319,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _318 = {
    path:
      'M231.77,201.86c-9.34-22.46-24.74-62.22-26.62-67.06,4.49-1.53,39-13.06,75.85-17.73v74C277.12,191.57,248.55,195.46,231.77,201.86Z',
    attr: {
      id: '_318',
      'data-name': '318',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 318,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _317 = {
    path:
      'M175.63,230.77c-13.25-20.83-36.77-57.9-39.46-62.14,3.58-2.55,31.8-22.28,64-32.51l25.66,68.09C221.86,205.75,193.26,217,175.63,230.77Z',
    attr: {
      id: '_317',
      'data-name': '317',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 317,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _316 = {
    path:
      'M122.39,280.79c-26.8-20.34-54.67-41.68-58.21-44.4,2.84-4.11,28.2-39.94,67.17-64.21l39.48,61.2C167.22,236.14,138.32,258.53,122.39,280.79Z',
    attr: {
      id: '_316',
      'data-name': '316',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 316,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _315 = {
    path:
      'M90.21,337.84c-41-17.3-64.66-27.19-68.09-28.62,1.5-4.41,14.46-41.11,38.45-68l59.19,44.39C117.19,288.45,102,306.31,90.21,337.84Z',
    attr: {
      id: '_315',
      'data-name': '315',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 315,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _314 = {
    path:
      'M1,402c.18-5.2,2.24-48.8,19.73-86.85l67.11,27.63C86.46,346.54,77.11,372.69,77,402Z',
    attr: {
      id: '_314',
      'data-name': '314',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 314,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _313 = {
    path: 'M366,114.625h76v67H366Z',
    attr: {
      id: '_313',
      'data-name': '313',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-364 293.875',
    data: {
      zoneNo: 313,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _312 = {
    path:
      'M20.25,568.24C2.76,530.18.7,486.58.52,481.38h76c.11,29.31,9.46,55.46,10.86,59.22Z',
    attr: {
      id: '_312',
      'data-name': '312',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 312,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _311 = {
    path:
      'M60.07,642.2c-24-26.94-36.94-63.62-38.45-68,3.43-1.43,27.13-11.32,68.09-28.62,11.8,31.53,27,49.39,29.55,52.27Z',
    attr: {
      id: '_311',
      'data-name': '311',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 311,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _310 = {
    path:
      'M130.85,711.2c-39-24.27-64.33-60.1-67.17-64.21,3.54-2.72,31.41-24.06,58.21-44.4,15.93,22.26,44.83,44.65,48.44,47.41Z',
    attr: {
      id: '_310',
      'data-name': '310',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 310,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _309 = {
    path:
      'M199.7,747.26c-32.24-10.23-60.45-30-64-32.51,2.69-4.24,26.21-41.31,39.46-62.14,17.63,13.74,46.23,25,50.23,26.56Z',
    attr: {
      id: '_309',
      'data-name': '309',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 309,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _308 = {
    path:
      'M280.5,766.31c-36.81-4.67-71.36-16.2-75.85-17.73,1.88-4.84,17.28-44.6,26.62-67.06,16.78,6.4,45.35,10.29,49.23,10.8Z',
    attr: {
      id: '_308',
      'data-name': '308',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 308,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _307 = {
    path: 'M366,114.625h74v74H366Z',
    attr: {
      id: '_307',
      'data-name': '307',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-79 577.875',
    data: {
      zoneNo: 307,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _306 = {
    path: 'M366,114.625h74v74H366Z',
    attr: {
      id: '_306',
      'data-name': '306',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-1 577.875',
    data: {
      zoneNo: 306,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _305 = {
    path: 'M366,114.625h74v74H366Z',
    attr: {
      id: '_305',
      'data-name': '305',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't77.5 577.875',
    data: {
      zoneNo: 305,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _304 = {
    path: 'M366,114.625h73v74H366Z',
    attr: {
      id: '_304',
      'data-name': '304',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't157 577.875',
    data: {
      zoneNo: 304,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _303 = {
    path:
      'M601.5,692.35c3.12-.24,21.42-1.93,49.21-10.85,9.35,22.45,24.76,62.23,26.64,67.08-4.49,1.53-39,13.06-75.85,17.73Z',
    attr: {
      id: '_303',
      'data-name': '303',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 303,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _302 = {
    path:
      'M656.64,679.17c4-1.54,32.6-12.82,50.23-26.56,13.25,20.83,36.77,57.9,39.46,62.14-3.58,2.55-31.8,22.28-64,32.51Z',
    attr: {
      id: '_302',
      'data-name': '302',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 302,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _301 = {
    path:
      'M711.67,650c3.61-2.76,32.51-25.15,48.44-47.41,26.8,20.34,54.67,41.68,58.21,44.4-2.84,4.11-28.2,39.94-67.17,64.21Z',
    attr: {
      id: '_301',
      'data-name': '301',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 301,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _330 = {
    path:
      'M762.74,597.81c2.57-2.88,17.75-20.74,29.55-52.27,41,17.3,64.66,27.19,68.09,28.62-1.5,4.41-14.46,41.11-38.45,68Z',
    attr: {
      id: '_330',
      'data-name': '330',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 330,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _329 = {
    path:
      'M794.64,540.6c1.4-3.76,10.75-29.91,10.86-59.22h76c-.18,5.2-2.24,48.8-19.73,86.86Z',
    attr: {
      id: '_329',
      'data-name': '329',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 329,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _328 = {
    path: 'M366,114.625h76v67H366Z',
    attr: {
      id: '_328',
      'data-name': '328',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't441 293.875',
    data: {
      zoneNo: 328,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _327 = {
    path:
      'M806,402c-.11-29.31-9.46-55.46-10.86-59.22l67.11-27.63C879.74,353.2,881.8,396.8,882,402Z',
    attr: {
      id: '_327',
      'data-name': '327',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 327,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _326 = {
    path:
      'M792.79,337.84c-11.8-31.53-27-49.39-29.55-52.27l59.19-44.39c24,26.94,37,63.62,38.45,68C857.45,310.65,833.75,320.54,792.79,337.84Z',
    attr: {
      id: '_326',
      'data-name': '326',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 326,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _325 = {
    path:
      'M760.61,280.79c-15.93-22.26-44.83-44.65-48.44-47.41l39.48-61.2c39,24.27,64.33,60.1,67.17,64.21C815.28,239.11,787.41,260.45,760.61,280.79Z',
    attr: {
      id: '_325',
      'data-name': '325',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 325,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _324 = {
    path:
      'M707.37,230.77c-17.63-13.74-46.23-25-50.23-26.56l25.66-68.09c32.24,10.23,60.45,30,64,32.51C744.14,172.87,720.62,209.94,707.37,230.77Z',
    attr: {
      id: '_324',
      'data-name': '324',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 324,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _323 = {
    path:
      'M651.21,201.88C623.42,193,605.12,191.27,602,191V117c36.81,4.67,71.36,16.2,75.85,17.73C676,139.65,660.56,179.43,651.21,201.88Z',
    attr: {
      id: '_323',
      'data-name': '323',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 323,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _322 = {
    path: 'M366,114.625h73v74H366Z',
    attr: {
      id: '_322',
      'data-name': '322',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't158 2.875',
    data: {
      zoneNo: 322,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _321 = {
    path: 'M366,114.625h74v74H366Z',
    attr: {
      id: '_321',
      'data-name': '321',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't78.5 2.875',
    data: {
      zoneNo: 321,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var _320 = {
    path: 'M366,114.625h74v74H366Z',
    attr: {
      id: '_320',
      'data-name': '320',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't0 2.875',
    data: {
      zoneNo: 320,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  var L301 = {
    path:
      'M699.68,631c3.19-2.32,25-18.52,42.42-40.44L755.29,600c-2.53,3.36-20.67,26.86-46.16,45.18Z',
    attr: {
      id: 'L301',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 301,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L302 = {
    path:
      'M648.66,658.16c3.94-1.57,31.35-12.66,46.24-24.56,4.23,5.91,9.11,12.46,10.38,14.16-3.61,2.41-29,19.06-50,26.49Z',
    attr: {
      id: 'L302',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 302,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L303 = {
    path:
      'M601.5,670.32c3.4-.44,25.71-3.56,42.22-10.77,2.56,6.76,5.78,14.17,6.62,16.07-3.07,1.28-21.21,8.48-48.84,12.11Z',
    attr: {
      id: 'L303',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 303,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L304 = {
    path: 'M286.265,196.05v16.924h73V196.05Z',
    attr: {
      id: 'L304',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't236.735 475',
    data: {
      zoneNo: 304,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L305 = {
    path: 'M286.265,196.05v16.924h74.5V196.05Z',
    attr: {
      id: 'L305',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't156.735 475',
    data: {
      zoneNo: 305,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L306 = {
    path: 'M286.265,196.05v16.924h74V196.05Z',
    attr: {
      id: 'L306',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't78.735 475',
    data: {
      zoneNo: 306,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L307 = {
    path: 'M286.265,196.05v16.924h74V196.05Z',
    attr: {
      id: 'L307',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't0.735 475',
    data: {
      zoneNo: 307,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L308 = {
    path:
      'M281.22,687.57c-27.82-3.66-46.07-10.91-49.14-12.19.84-1.91,4.09-9.36,6.66-16.17,16.61,7.25,39.06,10.39,42.48,10.83Z',
    attr: {
      id: 'L308',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 308,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L309 = {
    path:
      'M227.11,674c-21.17-7.47-46.68-24.23-50.31-26.65,1.28-1.71,6.19-8.3,10.45-14.25,15,12,42.57,23.14,46.53,24.71Z',
    attr: {
      id: 'L309',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 309,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L310 = {
    path:
      'M173.59,645c-25.5-18.33-43.63-41.82-46.17-45.18l13.2-9.43c17.45,21.92,39.23,38.12,42.41,40.44Z',
    attr: {
      id: 'L310',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 310,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L311 = {
    path:
      'M123.8,595a191.729,191.729,0,0,1-28.46-51l15.11-6.61A300.761,300.761,0,0,0,136,585.6Z',
    attr: {
      id: 'L311',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 311,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L312 = {
    path: 'M92.72,538.71l16-7a171.06,171.06,0,0,1-10-50h-17s0,38,11,57',
    attr: {
      id: 'L312',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 312,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L313 = {
    path: 'M100.554,409.47v67h-17v-67Z',
    attr: {
      id: 'L313',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-0.054 -0.97',
    data: {
      zoneNo: 313,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L314 = {
    path: 'M93.22,344.67l16,7a171.06,171.06,0,0,0-10,50h-17s0-38,11-57',
    attr: {
      id: 'L314',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 314,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L315 = {
    path:
      'M95.84,339.39a191.73,191.73,0,0,1,28.46-51l12.24,9.42A300.76,300.76,0,0,0,111,346Z',
    attr: {
      id: 'L315',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 315,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L316 = {
    path:
      'M127.92,283.56c2.54-3.37,20.67-26.86,46.17-45.19l9.44,14.17c-3.18,2.32-25,18.52-42.41,40.44Z',
    attr: {
      id: 'L316',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 316,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L317 = {
    path:
      'M187.75,250.28c-4.26-5.95-9.17-12.54-10.45-14.25,3.63-2.42,29.14-19.18,50.31-26.65l6.67,16.19C230.32,227.14,202.74,238.31,187.75,250.28Z',
    attr: {
      id: 'L317',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 317,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L318 = {
    path:
      'M239.24,224.17c-2.57-6.81-5.82-14.26-6.66-16.17,3.07-1.28,21.33-8.53,49.14-12.19v17.53C278.3,213.78,255.85,216.92,239.24,224.17Z',
    attr: {
      id: 'L318',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 318,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L319 = {
    path: 'M286.265,196.05v16.924h74V196.05Z',
    attr: {
      id: 'L319',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1.735',
    data: {
      zoneNo: 319,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L320 = {
    path: 'M364.276,196.05v16.924h74V196.05Z',
    attr: {
      id: 'L320',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1.724',
    data: {
      zoneNo: 320,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L321 = {
    path: 'M443.04,196.05v16.924h74.5V196.05Z',
    attr: {
      id: 'L321',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't0.96',
    data: {
      zoneNo: 321,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L322 = {
    path: 'M522.218,196.05v16.924h73V196.155Z',
    attr: {
      id: 'L322',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1.782',
    data: {
      zoneNo: 322,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L323 = {
    path:
      'M644.22,223.83c-16.51-7.21-38.82-10.33-42.22-10.77V195.65c27.63,3.63,45.77,10.83,48.84,12.11C650,209.66,646.78,217.07,644.22,223.83Z',
    attr: {
      id: 'L323',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 323,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L324 = {
    path:
      'M695.4,249.78c-14.89-11.9-42.3-23-46.24-24.56l6.62-16.09c21,7.43,46.39,24.08,50,26.49C704.51,237.32,699.63,243.87,695.4,249.78Z',
    attr: {
      id: 'L324',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 324,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L325 = {
    path:
      'M742.6,292.82c-17.46-21.92-39.23-38.12-42.42-40.44l9.45-14.17c25.5,18.33,43.63,41.82,46.16,45.18Z',
    attr: {
      id: 'L325',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 325,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L326 = {
    path:
      'M772.77,345.84a301.563,301.563,0,0,0-25.59-48.23l12.24-9.41a191.729,191.729,0,0,1,28.46,51Z',
    attr: {
      id: 'L326',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 326,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L327 = {
    path: 'M790.5,344.5l-16,7a171.057,171.057,0,0,1,10,50h17s0-38-11-57',
    attr: {
      id: 'L327',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 327,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L328 = {
    path: 'M780.754,409.47v67h17v-67Z',
    attr: {
      id: 'L328',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't4.746 -0.97',
    data: {
      zoneNo: 328,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L329 = {
    path: 'M790,538.88l-16-7a171.057,171.057,0,0,0,10-50h17s0,38-11,57',
    attr: {
      id: 'L329',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 329,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var L330 = {
    path:
      'M746.68,585.77a301.556,301.556,0,0,0,25.59-48.23l15.11,6.61a191.411,191.411,0,0,1-28.46,51Z',
    attr: {
      id: 'L330',
      opacity: 0,
      fill: '#2196f3',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't1 0.5',
    data: {
      zoneNo: 330,
      zone: 'Loge',
      zoneId: 4
    }
  };

  var _PREMIUMSUITELOUNGE = {
    path:
      'M764.000000,460.000000   C764.000000,440.193024 763.957581,420.885834 764.045288,401.579254   C764.058594,398.651367 764.169373,396.164886 760.090759,396.987579   C759.773743,397.051514 759.246826,397.107330 759.122070,396.939880   C756.606628,393.564178 758.576355,389.711945 758.039368,386.107208   C757.741638,384.108215 758.855469,383.249207 760.913574,382.947083   C765.700867,382.244354 770.473755,381.376678 775.193237,380.312714   C777.022705,379.900269 777.795044,379.766418 778.058838,382.039978   C778.906433,389.346008 780.062256,396.599365 780.034668,404.002411   C779.942993,428.635132 779.916382,453.268799 780.045532,477.901184   C780.090454,486.468536 778.468445,494.859894 777.984375,503.362030   C777.815857,506.322113 775.582703,506.387665 773.640015,505.865997   C768.795959,504.565308 763.943604,503.414185 758.936157,502.998474   C758.604797,502.970978 758.069885,503.092651 757.968994,502.931946   C755.786499,499.453674 757.486816,495.621063 757.037659,491.968750   C756.782043,489.889984 758.070312,488.715149 760.059326,489.050079   C764.113403,489.732697 764.107422,487.444458 764.051331,484.466431   C763.900879,476.480133 764.000000,468.489136 764.000000,460.000000  z',
    attr: {
      id: '_PREMIUMSUITELOUNGE',
      'data-name': 'PREMIUMSUITELOUNGE',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: {
      zoneNo: "",
      zone: 'PREMIUM SUITE LOUNGE',
      zoneId: "1"
    },
  };

  zones.push(
    _101,
    _103,
    _104,
    _105,
    _106,
    _107,
    _109,
    _111,
    _112,
    _113,
    _115,
    _116,
    _117,
    _118,
    _119,
    _220,
    _120,
    _121,
    _123,
    _124,
    _125,
    _126,
    _127,
    _202,
    _203,
    _204,
    _205,
    _206,
    _207,
    _208,
    _209,
    _210,
    _211,
    _212,
    _213,
    _214,
    _215,
    _216,
    _217,
    _218,
    _219,
    _221,
    _222,
    _223,
    _224,
    _225,
    _226,
    _227,
    _228,
    _319,
    _318,
    _317,
    _316,
    _315,
    _314,
    _313,
    _312,
    _311,
    _310,
    _309,
    _308,
    _307,
    _306,
    _305,
    _304,
    _303,
    _302,
    _301,
    _330,
    _329,
    _328,
    _327,
    _326,
    _325,
    _324,
    _323,
    _322,
    _321,
    _320,
    L301,
    L302,
    L303,
    L304,
    L305,
    L306,
    L307,
    L308,
    L309,
    L310,
    L311,
    L312,
    L313,
    L314,
    L315,
    L316,
    L317,
    L318,
    L319,
    L320,
    L321,
    L322,
    L323,
    L324,
    L325,
    L326,
    L327,
    L328,
    L329,
    L330,
    _PREMIUMSUITELOUNGE
  );

  return zones;
};
