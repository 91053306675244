export const getZones = rsr => {
  let zones = [];

  var _204 = {
    path:
      'M 1280.00,368.00 C 1294.47,375.06 1330.72,402.72 1346.00,413.00 1346.00,413.00 1335.37,429.00 1335.37,429.00 1335.37,429.00 1323.00,444.00 1323.00,444.00 1305.96,430.40 1279.18,414.05 1260.00,403.00 1260.00,403.00 1280.00,368.00 1280.00,368.00 Z',
    attr: {
      id: '_204',
      'data-name': '204',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "204", zone: 'Middle Zone', zoneId: "2" },
  };

  var _205 = {
    path:
      'M 1354.00,421.00 C 1360.18,423.09 1366.84,429.42 1372.00,433.61 1379.82,439.96 1400.73,456.98 1406.00,464.00 1406.00,464.00 1388.00,481.00 1388.00,481.00 1385.98,482.99 1381.81,487.38 1379.00,487.83 1375.58,488.38 1370.39,483.12 1368.00,480.91 1368.00,480.91 1343.00,459.39 1343.00,459.39 1340.67,457.50 1334.30,453.01 1334.16,449.96 1334.04,447.50 1337.82,443.05 1339.29,441.00 1339.29,441.00 1354.00,421.00 1354.00,421.00 Z',
    attr: {
      id: '_205',
      'data-name': '205',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "205", zone: 'Middle Zone', zoneId: "2" },
  };

  var _206 = {
    path:
      'M 1413.00,471.00 C 1422.33,477.25 1435.24,492.21 1443.05,501.00 1448.50,507.14 1457.07,516.47 1460.00,524.00 1460.00,524.00 1438.00,536.60 1438.00,536.60 1435.59,538.03 1429.61,541.95 1427.04,542.05 1423.09,542.21 1417.99,533.95 1415.58,531.00 1415.58,531.00 1395.58,508.00 1395.58,508.00 1391.84,503.70 1388.46,501.20 1386.00,496.00 1386.00,496.00 1413.00,471.00 1413.00,471.00 Z',
    attr: {
      id: '_206',
      'data-name': '206',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "206", zone: 'Middle Zone', zoneId: "2" },
  };

  var _207 = {
    path:
      'M 1432.00,552.00 C 1432.00,552.00 1466.00,533.00 1466.00,533.00 1466.00,533.00 1496.00,598.00 1496.00,598.00 1496.00,598.00 1464.00,609.00 1464.00,609.00 1457.30,593.42 1453.07,585.47 1444.19,571.00 1444.19,571.00 1432.00,552.00 1432.00,552.00 Z',
    attr: {
      id: '_207',
      'data-name': '207',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "207", zone: 'Middle Zone', zoneId: "2" },
  };

  var _210 = {
    path:
      'M 1492.00,850.00 C 1492.00,850.00 1490.83,835.00 1490.83,835.00 1490.83,835.00 1486.95,798.00 1486.95,798.00 1486.54,794.37 1484.09,782.15 1486.02,779.58 1487.84,777.18 1498.72,776.00 1502.00,775.39 1502.00,775.39 1550.00,767.00 1550.00,767.00 1552.84,788.09 1560.98,822.43 1561.00,843.00 1561.00,843.00 1532.00,845.83 1532.00,845.83 1532.00,845.83 1492.00,850.00 1492.00,850.00 Z',
    attr: {
      id: '_210',
      'data-name': '210',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "210", zone: 'Middle Zone', zoneId: "2" },
  };

  var _211 = {
    path:
      'M 1493.00,861.00 C 1493.00,861.00 1533.00,856.83 1533.00,856.83 1533.00,856.83 1563.00,854.00 1563.00,854.00 1563.00,854.00 1565.00,887.00 1565.00,887.00 1565.00,887.00 1566.00,906.00 1566.00,906.00 1566.00,906.00 1566.00,921.00 1566.00,921.00 1566.00,921.00 1491.00,921.00 1491.00,921.00 1491.00,921.00 1492.00,878.00 1492.00,878.00 1492.00,878.00 1493.00,856.00 1493.00,856.00 1493.00,856.00 1493.00,861.00 1493.00,861.00 Z',
    attr: {
      id: '_211',
      'data-name': '211',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "211", zone: 'Middle Zone', zoneId: "2" },
  };

  var _212 = {
    path:
      'M 1486.00,999.00 C 1486.00,999.00 1488.00,974.00 1488.00,974.00 1488.00,974.00 1489.00,958.00 1489.00,958.00 1489.00,958.00 1489.00,941.00 1489.00,941.00 1489.02,932.36 1489.36,932.02 1498.00,932.00 1498.00,932.00 1566.00,932.00 1566.00,932.00 1566.00,947.83 1564.57,989.65 1562.00,1004.00 1562.00,1004.00 1537.00,1002.09 1537.00,1002.09 1537.00,1002.09 1512.00,1000.91 1512.00,1000.91 1512.00,1000.91 1486.00,999.00 1486.00,999.00 Z',
    attr: {
      id: '_212',
      'data-name': '212',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "212", zone: 'Middle Zone', zoneId: "2" },
  };

  var _213 = {
    path:
      'M 1485.00,1010.00 C 1485.00,1010.00 1511.00,1011.91 1511.00,1011.91 1511.00,1011.91 1536.00,1013.09 1536.00,1013.09 1536.00,1013.09 1561.00,1015.00 1561.00,1015.00 1561.00,1015.00 1555.08,1057.00 1555.08,1057.00 1555.08,1057.00 1549.00,1087.00 1549.00,1087.00 1549.00,1087.00 1494.00,1076.60 1494.00,1076.60 1490.67,1075.93 1477.85,1074.39 1476.61,1071.57 1475.72,1069.54 1478.42,1057.02 1478.92,1054.00 1478.92,1054.00 1485.00,1010.00 1485.00,1010.00 Z',
    attr: {
      id: '_213',
      'data-name': '213',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "213", zone: 'Middle Zone', zoneId: "2" },
  };

  var _214 = {
    path:
      'M 1473.00,1084.00 C 1473.00,1084.00 1547.00,1098.00 1547.00,1098.00 1542.12,1122.24 1534.81,1146.57 1527.00,1170.00 1527.00,1170.00 1498.00,1161.33 1498.00,1161.33 1498.00,1161.33 1457.00,1148.00 1457.00,1148.00 1460.64,1132.02 1471.70,1099.48 1473.00,1084.00 Z',
    attr: {
      id: '_214',
      'data-name': '214',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "214", zone: 'Middle Zone', zoneId: "2" },
  };

  var _215 = {
    path:
      'M 1453.00,1159.00 C 1453.00,1159.00 1485.00,1168.33 1485.00,1168.33 1485.00,1168.33 1523.00,1181.00 1523.00,1181.00 1520.20,1193.28 1502.92,1235.43 1497.00,1248.00 1497.00,1248.00 1471.00,1236.25 1471.00,1236.25 1471.00,1236.25 1432.00,1218.00 1432.00,1218.00 1432.00,1218.00 1439.00,1199.00 1439.00,1199.00 1439.00,1199.00 1453.00,1159.00 1453.00,1159.00 Z',
    attr: {
      id: '_215',
      'data-name': '215',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "215", zone: 'Middle Zone', zoneId: "2" },
  };

  var _216 = {
    path:
      'M 1427.00,1227.00 C 1427.00,1227.00 1492.00,1258.00 1492.00,1258.00 1492.00,1258.00 1485.42,1273.00 1485.42,1273.00 1485.42,1273.00 1462.00,1318.00 1462.00,1318.00 1462.00,1318.00 1436.00,1303.40 1436.00,1303.40 1436.00,1303.40 1400.00,1282.00 1400.00,1282.00 1400.00,1282.00 1410.22,1262.00 1410.22,1262.00 1410.22,1262.00 1427.00,1227.00 1427.00,1227.00 Z',
    attr: {
      id: '_216',
      'data-name': '216',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "216", zone: 'Middle Zone', zoneId: "2" },
  };

  var _217 = {
    path:
      'M 1394.00,1291.00 C 1394.00,1291.00 1416.00,1303.60 1416.00,1303.60 1416.00,1303.60 1456.00,1327.00 1456.00,1327.00 1456.00,1327.00 1445.60,1346.00 1445.60,1346.00 1445.60,1346.00 1421.00,1384.00 1421.00,1384.00 1414.20,1381.84 1401.59,1372.35 1395.00,1368.00 1386.76,1362.57 1364.40,1349.38 1359.00,1343.00 1372.58,1327.18 1382.64,1308.39 1394.00,1291.00 Z',
    attr: {
      id: '_217',
      'data-name': '217',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "217", zone: 'Middle Zone', zoneId: "2" },
  };

  var _218 = {
    path:
      'M 1375.00,1444.00 C 1375.00,1444.00 1338.00,1406.00 1338.00,1406.00 1338.00,1406.00 1323.34,1390.00 1323.34,1390.00 1323.34,1390.00 1325.97,1384.00 1325.97,1384.00 1325.97,1384.00 1337.25,1371.00 1337.25,1371.00 1337.25,1371.00 1347.92,1358.02 1347.92,1358.02 1347.92,1358.02 1353.09,1354.10 1353.09,1354.10 1353.09,1354.10 1370.00,1364.00 1370.00,1364.00 1370.00,1364.00 1414.00,1393.00 1414.00,1393.00 1410.10,1400.95 1398.64,1415.38 1392.87,1423.00 1388.20,1429.17 1380.88,1439.59 1375.00,1444.00 Z',
    attr: {
      id: '_218',
      'data-name': '218',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "218", zone: 'Middle Zone', zoneId: "2" },
  };

  var _219 = {
    path:
      'M 1314.00,1397.00 C 1323.61,1403.88 1336.52,1419.10 1345.04,1428.00 1351.63,1434.89 1364.00,1446.02 1369.00,1453.00 1369.00,1453.00 1354.96,1468.47 1354.96,1468.47 1354.96,1468.47 1335.00,1479.25 1335.00,1479.25 1335.00,1479.25 1309.00,1491.00 1309.00,1491.00 1309.00,1491.00 1285.75,1444.00 1285.75,1444.00 1285.75,1444.00 1275.00,1420.00 1275.00,1420.00 1275.00,1420.00 1305.00,1405.37 1305.00,1405.37 1305.00,1405.37 1314.00,1397.00 1314.00,1397.00 Z',
    attr: {
      id: '_219',
      'data-name': '219',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "219", zone: 'Middle Zone', zoneId: "2" },
  };

  var _220 = {
    path:
      'M 1264.00,1425.00 C 1264.00,1425.00 1278.25,1452.00 1278.25,1452.00 1278.25,1452.00 1300.00,1496.00 1300.00,1496.00 1300.00,1496.00 1247.00,1517.00 1247.00,1517.00 1247.00,1517.00 1231.81,1477.00 1231.81,1477.00 1231.81,1477.00 1219.00,1444.00 1219.00,1444.00 1219.00,1444.00 1264.00,1425.00 1264.00,1425.00 Z',
    attr: {
      id: '_220',
      'data-name': '220',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "220", zone: 'Middle Zone', zoneId: "2" },
  };

  var _221 = {
    path:
      'M 1205.98,1447.58 C 1212.58,1447.48 1213.34,1454.65 1215.32,1460.00 1215.32,1460.00 1229.42,1497.00 1229.42,1497.00 1230.71,1500.39 1234.68,1508.41 1233.36,1511.73 1232.10,1514.92 1222.31,1517.72 1219.00,1518.95 1219.00,1518.95 1210.00,1522.22 1210.00,1522.22 1210.00,1522.22 1199.00,1525.06 1199.00,1525.06 1199.00,1525.06 1172.05,1533.91 1172.05,1533.91 1166.51,1534.47 1165.28,1524.04 1164.14,1520.00 1164.14,1520.00 1153.14,1482.00 1153.14,1482.00 1152.34,1479.18 1149.09,1469.53 1149.78,1467.22 1150.80,1463.83 1160.71,1461.80 1164.00,1460.86 1164.00,1460.86 1189.00,1453.33 1189.00,1453.33 1189.00,1453.33 1205.98,1447.58 1205.98,1447.58 Z',
    attr: {
      id: '_221',
      'data-name': '221',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "221", zone: 'Middle Zone', zoneId: "2" },
  };

  var _222 = {
    path:
      'M 1078.00,1481.00 C 1078.00,1481.00 1141.00,1469.00 1141.00,1469.00 1141.00,1469.00 1153.71,1514.00 1153.71,1514.00 1153.71,1514.00 1158.66,1531.98 1158.66,1531.98 1159.67,1539.85 1148.42,1539.60 1143.00,1540.88 1143.00,1540.88 1132.00,1543.54 1132.00,1543.54 1132.00,1543.54 1124.00,1544.46 1124.00,1544.46 1124.00,1544.46 1116.00,1546.50 1116.00,1546.50 1116.00,1546.50 1087.00,1551.00 1087.00,1551.00 1087.00,1551.00 1078.00,1481.00 1078.00,1481.00 Z',
    attr: {
      id: '_222',
      'data-name': '222',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "222", zone: 'Middle Zone', zoneId: "2" },
  };


  var _223 = {
    path:
      'M 1008.04,1490.00 C 1008.04,1490.00 1020.00,1488.83 1020.00,1488.83 1020.00,1488.83 1036.00,1486.85 1036.00,1486.85 1036.00,1486.85 1069.00,1483.00 1069.00,1483.00 1069.00,1483.00 1078.00,1552.00 1078.00,1552.00 1078.00,1552.00 1051.00,1555.06 1051.00,1555.06 1051.00,1555.06 1043.00,1555.06 1043.00,1555.06 1043.00,1555.06 1010.00,1559.00 1010.00,1559.00 1010.00,1559.00 1010.00,1541.00 1010.00,1541.00 1010.00,1541.00 1009.04,1529.00 1009.04,1529.00 1009.04,1529.00 1009.04,1514.00 1009.04,1514.00 1009.04,1514.00 1008.04,1502.00 1008.04,1502.00 1008.04,1502.00 1008.04,1490.00 1008.04,1490.00 Z',
    attr: {
      id: '_223',
      'data-name': '223',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "223", zone: 'Middle Zone', zoneId: "2" },
  };

  var _224 = {
    path:
      'M 938.00,1491.00 C 938.00,1491.00 999.00,1490.00 999.00,1490.00 999.00,1490.00 1000.00,1523.00 1000.00,1523.00 1000.00,1523.00 1001.00,1540.00 1001.00,1540.00 1001.00,1540.00 1002.00,1558.00 1002.00,1558.00 1002.00,1558.00 979.00,1559.00 979.00,1559.00 979.00,1559.00 936.00,1559.00 936.00,1559.00 936.00,1559.00 936.00,1540.00 936.00,1540.00 936.00,1540.00 936.96,1528.00 936.96,1528.00 936.96,1528.00 936.96,1513.00 936.96,1513.00 936.96,1513.00 938.00,1491.00 938.00,1491.00 Z',
    attr: {
      id: '_224',
      'data-name': '224',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "224", zone: 'Middle Zone', zoneId: "2" },
  };

  var _225 = {
    path:
      'M 869.00,1485.00 C 869.00,1485.00 880.00,1485.91 880.00,1485.91 880.00,1485.91 919.00,1489.09 919.00,1489.09 919.00,1489.09 928.96,1490.00 928.96,1490.00 928.96,1490.00 928.96,1502.00 928.96,1502.00 928.96,1502.00 927.96,1514.00 927.96,1514.00 927.96,1514.00 927.96,1529.00 927.96,1529.00 927.96,1529.00 927.00,1540.00 927.00,1540.00 927.00,1540.00 927.00,1559.00 927.00,1559.00 927.00,1559.00 915.00,1558.00 915.00,1558.00 915.00,1558.00 904.00,1558.00 904.00,1558.00 904.00,1558.00 890.00,1556.00 890.00,1556.00 890.00,1556.00 880.00,1556.00 880.00,1556.00 880.00,1556.00 859.00,1554.00 859.00,1554.00 859.00,1554.00 869.00,1485.00 869.00,1485.00 Z',
    attr: {
      id: '_225',
      'data-name': '225',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "225", zone: 'Middle Zone', zoneId: "2" },
  };

  var _226 = {
    path:
      'M 781.00,1538.00 C 781.00,1538.00 794.29,1487.00 794.29,1487.00 795.19,1483.86 797.33,1472.77 800.30,1471.77 802.45,1471.05 813.95,1474.37 817.00,1475.00 817.00,1475.00 860.00,1483.00 860.00,1483.00 860.00,1483.00 850.00,1552.00 850.00,1552.00 850.00,1552.00 817.00,1545.09 817.00,1545.09 817.00,1545.09 781.00,1538.00 781.00,1538.00 Z',
    attr: {
      id: '_226',
      'data-name': '226',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "226", zone: 'Middle Zone', zoneId: "2" },
  };

  var _227 = {
    path:
      'M 708.00,1516.00 C 706.09,1509.04 709.54,1503.36 712.28,1497.00 712.28,1497.00 725.00,1466.00 725.00,1466.00 726.38,1462.55 729.81,1451.87 733.22,1450.69 735.65,1449.85 740.56,1451.85 743.00,1452.67 743.00,1452.67 766.00,1460.33 766.00,1460.33 766.00,1460.33 783.00,1466.00 783.00,1466.00 784.96,1466.66 788.83,1467.68 789.92,1469.51 791.23,1471.69 786.74,1482.90 785.86,1486.00 785.86,1486.00 773.00,1534.16 773.00,1534.16 773.00,1534.16 766.00,1534.16 766.00,1534.16 766.00,1534.16 724.00,1520.33 724.00,1520.33 724.00,1520.33 708.00,1516.00 708.00,1516.00 Z',
    attr: {
      id: '_227',
      'data-name': '227',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "227", zone: 'Middle Zone', zoneId: "2" },
  };

  var _228 = {
    path:
      'M 676.00,1428.00 C 676.00,1428.00 722.00,1447.00 722.00,1447.00 722.00,1447.00 713.00,1471.00 713.00,1471.00 713.00,1471.00 693.00,1520.00 693.00,1520.00 680.55,1515.68 653.09,1504.31 642.00,1498.00 642.00,1498.00 652.75,1475.00 652.75,1475.00 652.75,1475.00 676.00,1428.00 676.00,1428.00 Z',
    attr: {
      id: '_228',
      'data-name': '228',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "228", zone: 'Middle Zone', zoneId: "2" },
  };

  var _229 = {
    path:
      'M 627.00,1399.00 C 627.00,1399.00 641.00,1408.58 641.00,1408.58 641.00,1408.58 667.00,1423.00 667.00,1423.00 667.00,1423.00 632.00,1493.00 632.00,1493.00 623.17,1490.48 596.27,1474.40 588.00,1468.65 584.74,1466.39 576.31,1459.39 577.32,1455.00 578.10,1451.64 587.98,1442.10 590.72,1439.00 590.72,1439.00 627.00,1399.00 627.00,1399.00 Z',
    attr: {
      id: '_228',
      'data-name': '229',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "229", zone: 'Middle Zone', zoneId: "2" },
  };

  var _230 = {
    path:
      'M 528.00,1398.00 C 528.00,1398.00 559.00,1375.86 559.00,1375.86 559.00,1375.86 591.00,1354.00 591.00,1354.00 591.00,1354.00 610.00,1378.00 610.00,1378.00 612.09,1380.43 618.50,1387.22 618.99,1390.00 619.78,1394.54 611.21,1401.68 608.28,1405.00 608.28,1405.00 585.17,1430.00 585.17,1430.00 580.87,1434.75 572.69,1444.35 568.00,1448.00 559.45,1438.70 533.71,1407.92 528.00,1398.00 Z',
    attr: {
      id: '_230',
      'data-name': '230',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "230", zone: 'Middle Zone', zoneId: "2" },
  };

  var _231 = {
    path:
      'M 483.00,1332.00 C 483.00,1332.00 526.00,1304.81 526.00,1304.81 526.00,1304.81 549.00,1292.00 549.00,1292.00 549.00,1292.00 567.03,1321.00 567.03,1321.00 567.03,1321.00 584.00,1346.00 584.00,1346.00 584.00,1346.00 539.00,1377.34 539.00,1377.34 535.93,1379.45 525.11,1388.00 522.04,1387.84 518.42,1387.66 512.69,1378.00 510.67,1375.00 510.67,1375.00 483.00,1332.00 483.00,1332.00 Z',
    attr: {
      id: '_231',
      'data-name': '231',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "231", zone: 'Middle Zone', zoneId: "2" },
  };

  var _232 = {
    path:
      'M 444.00,1257.00 C 444.00,1257.00 464.00,1248.42 464.00,1248.42 464.00,1248.42 514.00,1226.00 514.00,1226.00 514.00,1226.00 530.31,1259.00 530.31,1259.00 533.71,1265.29 540.76,1276.49 542.00,1283.00 542.00,1283.00 498.00,1310.19 498.00,1310.19 498.00,1310.19 477.00,1322.00 477.00,1322.00 477.00,1322.00 454.75,1281.00 454.75,1281.00 454.75,1281.00 444.00,1257.00 444.00,1257.00 Z',
    attr: {
      id: '_232',
      'data-name': '232',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "232", zone: 'Middle Zone', zoneId: "2" },
  };

  var _233 = {
    path:
      'M 488.00,1160.00 C 488.00,1160.00 496.05,1181.00 496.05,1181.00 496.05,1181.00 510.00,1216.00 510.00,1216.00 510.00,1216.00 481.00,1229.31 481.00,1229.31 481.00,1229.31 439.00,1248.00 439.00,1248.00 439.00,1248.00 421.68,1204.00 421.68,1204.00 420.49,1200.78 415.90,1190.14 416.78,1187.27 417.89,1183.64 431.27,1180.27 435.00,1179.00 435.00,1179.00 488.00,1160.00 488.00,1160.00 Z',
    attr: {
      id: '_233',
      'data-name': '233',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "233", zone: 'Middle Zone', zoneId: "2" },
  };

  var _234 = {
    path:
      'M 392.00,1101.00 C 392.00,1101.00 469.00,1087.00 469.00,1087.00 469.00,1087.00 485.00,1150.00 485.00,1150.00 485.00,1150.00 431.00,1169.00 431.00,1169.00 427.61,1170.13 415.15,1175.45 412.51,1173.89 410.12,1172.47 406.67,1158.34 405.71,1155.00 405.71,1155.00 392.00,1101.00 392.00,1101.00 Z',
    attr: {
      id: '_234',
      'data-name': '234',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "234", zone: 'Middle Zone', zoneId: "2" },
  };

  var _235 = {
    path:
      'M 380.00,1020.00 C 380.00,1020.00 400.00,1017.83 400.00,1017.83 400.00,1017.83 457.00,1012.00 457.00,1012.00 457.00,1012.00 466.00,1076.00 466.00,1076.00 466.00,1076.00 420.00,1084.80 420.00,1084.80 420.00,1084.80 390.00,1090.00 390.00,1090.00 387.09,1074.55 380.04,1034.15 380.00,1020.00 Z',
    attr: {
      id: '_235',
      'data-name': '235',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "235", zone: 'Middle Zone', zoneId: "2" },
  };

  var _236 = {
    path:
      'M 375.00,930.00 C 375.00,930.00 387.00,931.00 387.00,931.00 387.00,931.00 415.00,932.00 415.00,932.00 415.00,932.00 437.00,933.00 437.00,933.00 437.00,933.00 452.00,934.00 452.00,934.00 452.00,934.00 452.00,954.00 452.00,954.00 452.00,954.00 452.91,966.00 452.91,966.00 452.91,966.00 454.09,988.00 454.09,988.00 454.09,988.00 455.00,1001.00 455.00,1001.00 455.00,1001.00 435.00,1003.17 435.00,1003.17 435.00,1003.17 379.00,1009.00 379.00,1009.00 379.00,1009.00 376.91,984.00 376.91,984.00 376.91,984.00 375.00,930.00 375.00,930.00 Z',
    attr: {
      id: '_236',
      'data-name': '236',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "236", zone: 'Middle Zone', zoneId: "2" },
  };

  var _237 = {
    path:
      'M 379.00,854.00 C 379.00,854.00 435.00,859.17 435.00,859.17 435.00,859.17 454.00,861.00 454.00,861.00 454.00,861.00 452.83,876.00 452.83,876.00 452.83,876.00 452.00,899.00 452.00,899.00 452.00,899.00 451.04,911.00 451.04,911.00 451.04,911.00 451.04,923.00 451.04,923.00 451.04,923.00 432.00,922.00 432.00,922.00 432.00,922.00 412.00,921.00 412.00,921.00 412.00,921.00 400.00,920.04 400.00,920.04 400.00,920.04 388.00,920.04 388.00,920.04 388.00,920.04 375.00,919.00 375.00,919.00 375.00,919.00 376.00,896.00 376.00,896.00 376.00,896.00 377.00,884.00 377.00,884.00 377.00,884.00 377.00,875.00 377.00,875.00 377.00,875.00 379.00,854.00 379.00,854.00 Z',
    attr: {
      id: '_237',
      'data-name': '237',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "237", zone: 'Middle Zone', zoneId: "2" },
  };

  var _238 = {
    path:
      'M 390.00,773.00 C 390.00,773.00 433.00,780.08 433.00,780.08 433.00,780.08 452.00,783.00 452.00,783.00 452.00,783.00 449.28,806.00 449.28,806.00 449.28,806.00 446.00,850.00 446.00,850.00 446.00,850.00 399.00,844.28 399.00,844.28 396.13,843.94 383.18,843.29 381.84,841.98 379.43,840.10 381.70,828.11 381.84,825.00 381.84,825.00 390.00,773.00 390.00,773.00 Z',
    attr: {
      id: '_238',
      'data-name': '238',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "238", zone: 'Middle Zone', zoneId: "2" },
  };

  var _239 = {
    path:
      'M 408.00,692.00 C 408.00,692.00 459.00,706.00 459.00,706.00 459.00,706.00 441.00,770.00 441.00,770.00 441.00,770.00 392.00,762.00 392.00,762.00 392.00,762.00 400.37,723.00 400.37,723.00 400.37,723.00 408.00,692.00 408.00,692.00 Z',
    attr: {
      id: '_239',
      'data-name': '239',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "239", zone: 'Middle Zone', zoneId: "2" },
  };

  var _240 = {
    path:
      'M 436.00,608.00 C 443.45,610.01 462.06,619.06 470.00,622.78 472.66,624.03 480.67,627.13 481.89,629.39 483.52,632.40 478.83,643.50 477.67,647.00 477.67,647.00 462.00,696.00 462.00,696.00 462.00,696.00 412.00,682.00 412.00,682.00 412.00,682.00 418.98,656.00 418.98,656.00 418.98,656.00 436.00,608.00 436.00,608.00 Z',
    attr: {
      id: '_240',
      'data-name': '240',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "240", zone: 'Middle Zone', zoneId: "2" },
  };

  var _241 = {
    path:
      'M 492.00,546.00 C 492.00,546.00 537.00,571.00 537.00,571.00 537.00,571.00 525.20,593.00 525.20,593.00 525.20,593.00 505.00,627.00 505.00,627.00 505.00,627.00 462.00,608.00 462.00,608.00 462.00,608.00 492.00,546.00 492.00,546.00 Z',
    attr: {
      id: '_241',
      'data-name': '241',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "241", zone: 'Middle Zone', zoneId: "2" },
  };

  var _242 = {
    path:
      'M 481.00,527.00 C 485.63,518.00 504.56,495.82 512.04,488.00 513.84,486.12 522.43,476.82 524.17,476.34 527.77,475.34 534.56,483.56 537.00,486.00 537.00,486.00 566.00,515.00 566.00,515.00 568.33,517.33 575.80,523.84 575.80,527.00 575.80,529.86 570.25,534.77 568.28,537.00 560.60,545.73 552.17,554.09 546.00,564.00 546.00,564.00 516.00,547.42 516.00,547.42 516.00,547.42 481.00,527.00 481.00,527.00 Z',
    attr: {
      id: '_242',
      'data-name': '242',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "242", zone: 'Middle Zone', zoneId: "2" },
  };

  var _243 = {
    path:
      'M 584.00,422.00 C 584.00,422.00 604.88,449.00 604.88,449.00 604.88,449.00 630.00,483.00 630.00,483.00 620.29,489.14 606.01,501.64 597.00,509.42 593.96,512.05 588.17,519.27 584.00,517.83 581.24,516.88 571.66,506.66 569.00,504.00 569.00,504.00 533.00,467.00 533.00,467.00 533.00,467.00 563.00,439.27 563.00,439.27 563.00,439.27 584.00,422.00 584.00,422.00 Z',
    attr: {
      id: '_243',
      'data-name': '243',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "243", zone: 'Middle Zone', zoneId: "2" },
  };

  var _244 = {
    path:
      'M 593.00,415.00 C 611.22,402.06 642.74,378.28 662.00,369.00 662.00,369.00 682.31,408.00 682.31,408.00 682.31,408.00 697.00,438.00 697.00,438.00 697.00,438.00 659.00,461.67 659.00,461.67 659.00,461.67 638.00,476.00 638.00,476.00 638.00,476.00 607.13,435.00 607.13,435.00 607.13,435.00 593.00,415.00 593.00,415.00 Z',
    attr: {
      id: '_244',
      'data-name': '244',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "244", zone: 'Middle Zone', zoneId: "2" },
  };

  var _508 = {
    path:
      'M 1562.00,552.00 C 1562.00,552.00 1602.00,536.40 1602.00,536.40 1605.60,534.93 1615.86,529.76 1619.00,529.77 1622.37,529.79 1628.07,533.61 1631.00,535.42 1631.00,535.42 1656.87,550.96 1656.87,550.96 1661.16,554.18 1664.31,564.80 1666.40,570.00 1667.38,572.43 1668.43,576.30 1671.22,577.12 1672.97,577.63 1678.01,576.58 1680.00,576.25 1680.00,576.25 1697.00,573.41 1697.00,573.41 1697.00,573.41 1762.00,563.00 1762.00,563.00 1762.00,563.00 1768.67,583.00 1768.67,583.00 1768.67,583.00 1769.89,591.44 1769.89,591.44 1769.89,591.44 1758.00,596.14 1758.00,596.14 1758.00,596.14 1725.00,605.86 1725.00,605.86 1725.00,605.86 1596.00,644.00 1596.00,644.00 1596.00,644.00 1587.05,616.00 1587.05,616.00 1587.05,616.00 1572.00,577.00 1572.00,577.00 1572.00,577.00 1562.00,552.00 1562.00,552.00 Z',
    attr: {
      id: '_508',
      'data-name': '508',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "508", zone: 'Upper Zone', zoneId: "5" },
  };

  var _509 = {
    path:
      'M 1600.00,654.00 C 1600.00,654.00 1636.00,643.42 1636.00,643.42 1636.00,643.42 1727.00,616.58 1727.00,616.58 1727.00,616.58 1775.00,603.00 1775.00,603.00 1775.00,603.00 1793.65,673.00 1793.65,673.00 1793.65,673.00 1800.00,704.00 1800.00,704.00 1800.00,704.00 1757.00,710.92 1757.00,710.92 1757.00,710.92 1662.00,727.08 1662.00,727.08 1662.00,727.08 1620.00,734.00 1620.00,734.00 1620.00,734.00 1606.85,679.00 1606.85,679.00 1606.85,679.00 1600.00,654.00 1600.00,654.00 ',
    attr: {
      id: '_509',
      'data-name': '509',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "509", zone: 'Upper Zone', zoneId: "5" },
  };

  var _510 = {
    path:
      'M 1802.00,714.00 C 1802.00,714.00 1808.25,745.00 1808.25,745.00 1808.25,745.00 1818.00,814.00 1818.00,814.00 1818.00,814.00 1797.00,815.83 1797.00,815.83 1797.00,815.83 1761.00,818.83 1761.00,818.83 1761.00,818.83 1682.00,825.17 1682.00,825.17 1682.00,825.17 1635.00,829.00 1635.00,829.00 1632.02,806.40 1627.53,765.88 1622.00,745.00 1622.00,745.00 1663.00,738.08 1663.00,738.08 1663.00,738.08 1737.00,725.42 1737.00,725.42 1737.00,725.42 1802.00,714.00 1802.00,714.00 Z',
    attr: {
      id: '_510',
      'data-name': '510',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "510", zone: 'Upper Zone', zoneId: "5" },
  };

  var _511 = {
    path:
      'M 1636.00,840.00 C 1636.00,840.00 1683.00,836.17 1683.00,836.17 1683.00,836.17 1762.00,829.83 1762.00,829.83 1762.00,829.83 1798.00,826.83 1798.00,826.83 1798.00,826.83 1819.00,825.00 1819.00,825.00 1819.00,825.00 1822.00,865.00 1822.00,865.00 1822.00,865.00 1823.04,880.00 1823.04,880.00 1823.04,880.00 1823.04,891.00 1823.04,891.00 1823.04,891.00 1824.00,902.00 1824.00,902.00 1824.00,902.00 1824.00,919.00 1824.00,919.00 1824.00,919.00 1810.00,919.00 1810.00,919.00 1810.00,919.00 1795.00,920.00 1795.00,920.00 1795.00,920.00 1744.00,920.00 1744.00,920.00 1744.00,920.00 1727.00,921.00 1727.00,921.00 1727.00,921.00 1674.00,921.00 1674.00,921.00 1674.00,921.00 1659.00,922.00 1659.00,922.00 1659.00,922.00 1640.00,922.00 1640.00,922.00 1640.00,922.00 1640.00,907.00 1640.00,907.00 1640.00,907.00 1639.00,891.00 1639.00,891.00 1639.00,891.00 1637.91,863.00 1637.91,863.00 1637.91,863.00 1636.00,840.00 1636.00,840.00 Z',
    attr: {
      id: '_511',
      'data-name': '511',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "511", zone: 'Upper Zone', zoneId: "5" },
  };

  var _512 = {
    path:
      'M 1640.00,933.00 C 1640.00,933.00 1661.00,933.00 1661.00,933.00 1661.00,933.00 1677.00,932.00 1677.00,932.00 1677.00,932.00 1728.00,932.00 1728.00,932.00 1728.00,932.00 1745.00,931.00 1745.00,931.00 1745.00,931.00 1798.00,931.00 1798.00,931.00 1798.00,931.00 1813.00,930.00 1813.00,930.00 1813.00,930.00 1824.00,930.00 1824.00,930.00 1824.00,930.00 1824.00,950.00 1824.00,950.00 1824.00,950.00 1823.00,966.00 1823.00,966.00 1823.00,966.00 1821.91,992.00 1821.91,992.00 1821.91,992.00 1818.00,1032.00 1818.00,1032.00 1818.00,1032.00 1799.00,1030.17 1799.00,1030.17 1799.00,1030.17 1755.00,1026.17 1755.00,1026.17 1755.00,1026.17 1679.00,1019.28 1679.00,1019.28 1679.00,1019.28 1647.00,1016.42 1647.00,1016.42 1647.00,1016.42 1635.93,1014.26 1635.93,1014.26 1635.93,1014.26 1635.93,1008.00 1635.93,1008.00 1635.93,1008.00 1636.96,994.00 1636.96,994.00 1636.96,994.00 1639.00,954.00 1639.00,954.00 1639.00,954.00 1640.00,933.00 1640.00,933.00 Z',
    attr: {
      id: '_512',
      'data-name': '512',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "512", zone: 'Upper Zone', zoneId: "5" },
  };

  var _513 = {
    path:
      'M 1634.00,1026.00 C 1634.00,1026.00 1688.00,1031.17 1688.00,1031.17 1688.00,1031.17 1803.00,1041.83 1803.00,1041.83 1803.00,1041.83 1858.00,1047.00 1858.00,1047.00 1855.72,1075.09 1850.57,1103.24 1845.75,1131.00 1845.75,1131.00 1841.00,1156.00 1841.00,1156.00 1841.00,1156.00 1792.00,1145.79 1792.00,1145.79 1792.00,1145.79 1678.00,1122.40 1678.00,1122.40 1678.00,1122.40 1636.00,1113.79 1636.00,1113.79 1636.00,1113.79 1621.03,1109.42 1621.03,1109.42 1621.03,1109.42 1621.03,1103.00 1621.03,1103.00 1621.03,1103.00 1624.20,1088.00 1624.20,1088.00 1624.20,1088.00 1634.00,1026.00 1634.00,1026.00 Z',
    attr: {
      id: '_513',
      'data-name': '513',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "513", zone: 'Upper Zone', zoneId: "5" },
  };

  var _514 = {
    path:
      'M 1617.00,1121.00 C 1617.00,1121.00 1674.00,1132.79 1674.00,1132.79 1674.00,1132.79 1772.00,1152.80 1772.00,1152.80 1772.00,1152.80 1838.00,1166.00 1838.00,1166.00 1838.00,1166.00 1831.88,1196.00 1831.88,1196.00 1831.88,1196.00 1817.58,1250.00 1817.58,1250.00 1817.58,1250.00 1811.00,1272.00 1811.00,1272.00 1811.00,1272.00 1751.00,1252.67 1751.00,1252.67 1751.00,1252.67 1650.00,1219.33 1650.00,1219.33 1650.00,1219.33 1610.00,1206.33 1610.00,1206.33 1610.00,1206.33 1596.11,1200.49 1596.11,1200.49 1596.11,1200.49 1596.66,1194.00 1596.66,1194.00 1596.66,1194.00 1601.33,1179.00 1601.33,1179.00 1601.33,1179.00 1617.00,1121.00 1617.00,1121.00 Z',
    attr: {
      id: '_514',
      'data-name': '514',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "514", zone: 'Upper Zone', zoneId: "5" },
  };

  var _515 = {
    path:
      'M 1590.00,1212.00 C 1590.00,1212.00 1614.00,1219.00 1614.00,1219.00 1614.00,1219.00 1653.00,1231.67 1653.00,1231.67 1653.00,1231.67 1749.00,1263.33 1749.00,1263.33 1749.00,1263.33 1807.00,1282.00 1807.00,1282.00 1807.00,1282.00 1800.34,1303.00 1800.34,1303.00 1800.34,1303.00 1779.40,1357.00 1779.40,1357.00 1779.40,1357.00 1770.00,1379.00 1770.00,1379.00 1770.00,1379.00 1746.00,1369.26 1746.00,1369.26 1746.00,1369.26 1708.00,1351.86 1708.00,1351.86 1708.00,1351.86 1600.00,1302.69 1600.00,1302.69 1600.00,1302.69 1561.00,1285.00 1561.00,1285.00 1567.73,1268.17 1586.49,1227.39 1590.00,1212.00 Z',
    attr: {
      id: '_515',
      'data-name': '515',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "515", zone: 'Upper Zone', zoneId: "5" },
  };

  var _516 = {
    path:
      'M 1556.00,1295.00 C 1556.00,1295.00 1578.00,1304.14 1578.00,1304.14 1578.00,1304.14 1623.00,1324.69 1623.00,1324.69 1623.00,1324.69 1708.00,1363.31 1708.00,1363.31 1708.00,1363.31 1764.00,1389.00 1764.00,1389.00 1762.98,1392.59 1758.61,1403.06 1755.61,1405.00 1752.29,1407.10 1744.09,1405.11 1740.00,1405.00 1740.00,1405.00 1694.00,1401.17 1694.00,1401.17 1686.47,1400.59 1686.60,1403.96 1682.14,1412.00 1682.14,1412.00 1662.00,1447.00 1662.00,1447.00 1662.00,1447.00 1615.00,1420.01 1615.00,1420.01 1615.00,1420.01 1559.00,1387.58 1559.00,1387.58 1559.00,1387.58 1520.00,1365.00 1520.00,1365.00 1520.00,1365.00 1533.58,1339.00 1533.58,1339.00 1533.58,1339.00 1556.00,1295.00 1556.00,1295.00 Z',
    attr: {
      id: '_516',
      'data-name': '516',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "516", zone: 'Upper Zone', zoneId: "5" },
  };

  var _517 = {
    path:
      'M 1604.00,1535.00 C 1596.98,1532.76 1577.16,1517.70 1570.00,1512.58 1570.00,1512.58 1506.00,1467.42 1506.00,1467.42 1506.00,1467.42 1482.00,1450.58 1482.00,1450.58 1479.92,1449.09 1472.77,1444.38 1471.71,1442.68 1469.28,1438.81 1476.81,1430.28 1479.14,1427.00 1479.14,1427.00 1504.95,1388.00 1504.95,1388.00 1506.90,1384.92 1511.45,1375.47 1515.09,1375.09 1517.47,1374.85 1523.69,1378.96 1526.00,1380.28 1526.00,1380.28 1553.00,1395.99 1553.00,1395.99 1553.00,1395.99 1627.00,1438.99 1627.00,1438.99 1627.00,1438.99 1656.00,1456.00 1656.00,1456.00 1656.00,1456.00 1641.05,1481.00 1641.05,1481.00 1641.05,1481.00 1604.00,1535.00 1604.00,1535.00 Z',
    attr: {
      id: '_517',
      'data-name': '517',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "517", zone: 'Upper Zone', zoneId: "5" },
  };

  var _518 = {
    path:
      'M 1463.00,1450.00 C 1463.00,1450.00 1495.00,1472.28 1495.00,1472.28 1495.00,1472.28 1561.00,1518.72 1561.00,1518.72 1561.00,1518.72 1596.00,1543.00 1596.00,1543.00 1594.17,1549.72 1587.65,1556.45 1583.35,1562.00 1583.35,1562.00 1548.83,1603.00 1548.83,1603.00 1548.83,1603.00 1536.96,1615.94 1536.96,1615.94 1535.32,1617.48 1533.45,1619.28 1531.00,1618.66 1527.58,1617.81 1518.14,1606.18 1515.00,1603.00 1515.00,1603.00 1471.83,1557.00 1471.83,1557.00 1471.83,1557.00 1461.09,1546.00 1461.09,1546.00 1461.09,1546.00 1434.00,1517.00 1434.00,1517.00 1430.95,1513.94 1421.68,1505.58 1423.15,1501.00 1423.84,1498.88 1433.47,1487.67 1435.60,1485.00 1435.60,1485.00 1463.00,1450.00 1463.00,1450.00 Z',
    attr: {
      id: '_518',
      'data-name': '518',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "518", zone: 'Upper Zone', zoneId: "5" },
  };

  var _519 = {
    path:
      'M 1414.00,1511.00 C 1414.00,1511.00 1451.17,1550.00 1451.17,1550.00 1451.17,1550.00 1461.91,1561.00 1461.91,1561.00 1461.91,1561.00 1484.09,1585.00 1484.09,1585.00 1484.09,1585.00 1497.42,1599.00 1497.42,1599.00 1497.42,1599.00 1516.04,1619.00 1516.04,1619.00 1517.97,1621.03 1522.80,1625.13 1522.80,1628.00 1522.80,1630.24 1520.42,1632.49 1518.98,1634.00 1518.98,1634.00 1506.00,1646.96 1506.00,1646.96 1491.34,1660.99 1483.27,1663.95 1466.00,1673.31 1466.00,1673.31 1438.00,1688.00 1438.00,1688.00 1438.00,1688.00 1417.75,1648.00 1417.75,1648.00 1417.75,1648.00 1390.75,1595.00 1390.75,1595.00 1390.75,1595.00 1374.75,1563.00 1374.75,1563.00 1374.75,1563.00 1366.00,1544.00 1366.00,1544.00 1366.00,1544.00 1385.00,1534.25 1385.00,1534.25 1385.00,1534.25 1399.91,1526.37 1399.91,1526.37 1399.91,1526.37 1414.00,1511.00 1414.00,1511.00 Z',
    attr: {
      id: '_519',
      'data-name': '519',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "519", zone: 'Upper Zone', zoneId: "5" },
  };

  var _520 = {
    path:
      'M 1292.00,1577.00 C 1292.00,1577.00 1340.00,1556.69 1340.00,1556.69 1340.00,1556.69 1356.00,1549.00 1356.00,1549.00 1356.00,1549.00 1369.25,1575.00 1369.25,1575.00 1369.25,1575.00 1391.25,1619.00 1391.25,1619.00 1391.25,1619.00 1423.00,1682.00 1423.00,1682.00 1423.00,1682.00 1404.00,1691.75 1404.00,1691.75 1404.00,1691.75 1371.00,1706.86 1371.00,1706.86 1371.00,1706.86 1348.00,1716.00 1348.00,1716.00 1348.00,1716.00 1333.42,1680.00 1333.42,1680.00 1333.42,1680.00 1307.60,1617.00 1307.60,1617.00 1307.60,1617.00 1292.00,1577.00 1292.00,1577.00 Z',
    attr: {
      id: '_520',
      'data-name': '520',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "520", zone: 'Upper Zone', zoneId: "5" },
  };

  var _521 = {
    path:
      'M 1206.00,1607.00 C 1206.00,1607.00 1223.00,1601.66 1223.00,1601.66 1223.00,1601.66 1254.00,1591.33 1254.00,1591.33 1254.00,1591.33 1282.00,1582.00 1282.00,1582.00 1282.00,1582.00 1320.80,1677.00 1320.80,1677.00 1320.80,1677.00 1338.00,1721.00 1338.00,1721.00 1338.00,1721.00 1312.00,1730.31 1312.00,1730.31 1296.63,1735.78 1257.60,1749.05 1243.00,1752.00 1243.00,1752.00 1227.12,1690.00 1227.12,1690.00 1227.12,1690.00 1216.12,1647.00 1216.12,1647.00 1216.12,1647.00 1206.00,1607.00 1206.00,1607.00 Z',
    attr: {
      id: '_521',
      'data-name': '521',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "521", zone: 'Upper Zone', zoneId: "5" },
  };

  var _522 = {
    path:
      'M 1113.00,1628.00 C 1131.96,1627.13 1174.67,1614.09 1195.00,1610.00 1195.00,1610.00 1203.15,1641.00 1203.15,1641.00 1203.15,1641.00 1222.87,1718.00 1222.87,1718.00 1222.87,1718.00 1229.63,1744.00 1229.63,1744.00 1229.63,1744.00 1230.89,1754.44 1230.89,1754.44 1230.89,1754.44 1224.00,1757.37 1224.00,1757.37 1224.00,1757.37 1207.00,1761.63 1207.00,1761.63 1207.00,1761.63 1163.00,1771.20 1163.00,1771.20 1163.00,1771.20 1134.00,1776.00 1134.00,1776.00 1134.00,1776.00 1119.73,1676.00 1119.73,1676.00 1119.73,1676.00 1113.00,1628.00 1113.00,1628.00 Z',
    attr: {
      id: '_522',
      'data-name': '522',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "522", zone: 'Upper Zone', zoneId: "5" },
  };

  var _523 = {
    path:
      'M 1019.00,1640.00 C 1019.00,1640.00 1074.00,1634.58 1074.00,1634.58 1074.00,1634.58 1102.00,1631.00 1102.00,1631.00 1102.00,1631.00 1107.27,1667.00 1107.27,1667.00 1107.27,1667.00 1117.73,1740.00 1117.73,1740.00 1117.73,1740.00 1121.77,1767.00 1121.77,1767.00 1121.91,1769.23 1123.25,1775.52 1121.77,1777.26 1120.73,1778.95 1116.96,1779.13 1115.00,1779.42 1115.00,1779.42 1098.00,1781.85 1098.00,1781.85 1075.33,1784.56 1048.60,1788.00 1026.00,1788.00 1026.00,1788.00 1026.00,1777.00 1026.00,1777.00 1026.00,1777.00 1025.00,1758.00 1025.00,1758.00 1025.00,1758.00 1024.00,1738.00 1024.00,1738.00 1024.00,1738.00 1023.00,1722.00 1023.00,1722.00 1023.00,1722.00 1022.00,1696.00 1022.00,1696.00 1022.00,1696.00 1021.00,1681.00 1021.00,1681.00 1021.00,1681.00 1020.00,1660.00 1020.00,1660.00 1020.00,1660.00 1019.00,1640.00 1019.00,1640.00 Z',
    attr: {
      id: '_523',
      'data-name': '523',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "523", zone: 'Upper Zone', zoneId: "5" },
  };

  var _524 = {
    path:
      'M 933.00,1641.00 C 949.98,1644.04 988.91,1641.00 1008.00,1641.00 1008.00,1641.00 1009.00,1656.00 1009.00,1656.00 1009.00,1656.00 1010.00,1683.00 1010.00,1683.00 1010.00,1683.00 1011.00,1696.00 1011.00,1696.00 1011.00,1696.00 1012.00,1722.00 1012.00,1722.00 1012.00,1722.00 1013.00,1739.00 1013.00,1739.00 1013.00,1739.00 1014.00,1758.00 1014.00,1758.00 1014.00,1758.00 1015.00,1789.00 1015.00,1789.00 1015.00,1789.00 996.00,1790.00 996.00,1790.00 996.00,1790.00 925.00,1789.00 925.00,1789.00 925.00,1789.00 928.00,1727.00 928.00,1727.00 928.00,1727.00 928.96,1717.00 928.96,1717.00 928.96,1717.00 928.96,1708.00 928.96,1708.00 928.96,1708.00 930.00,1691.00 930.00,1691.00 930.00,1691.00 931.00,1676.00 931.00,1676.00 931.00,1676.00 933.00,1641.00 933.00,1641.00 Z',
    attr: {
      id: '_524',
      'data-name': '524',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "524", zone: 'Upper Zone', zoneId: "5" },
  };

  var _525 = {
    path:
      'M 839.00,1633.00 C 839.00,1633.00 863.00,1635.85 863.00,1635.85 863.00,1635.85 911.00,1640.09 911.00,1640.09           911.00,1640.09 922.00,1641.00 922.00,1641.00 922.00,1641.00 921.00,1657.00 921.00,1657.00 921.00,1657.00 920.00,1674.00 920.00,1674.00 920.00,1674.00 919.00,1689.00 919.00,1689.00 919.00,1689.00 918.00,1712.00 918.00,1712.00 918.00,1712.00 917.00,1729.00 917.00,1729.00 917.00,1729.00 916.00,1746.00 916.00,1746.00 916.00,1746.00 915.00,1762.00 915.00,1762.00 915.00,1762.00 914.00,1789.00 914.00,1789.00 914.00,1789.00 905.00,1788.00 905.00,1788.00 905.00,1788.00 896.00,1788.00 896.00,1788.00 896.00,1788.00 853.00,1783.83 853.00,1783.83 853.00,1783.83 818.00,1779.00 818.00,1779.00 818.00,1779.00 822.87,1745.00 822.87,1745.00 822.87,1745.00 834.27,1666.00 834.27,1666.00 834.27,1666.00 839.00,1633.00 839.00,1633.00 Z',
    attr: {
      id: '_525',
      'data-name': '525',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "525", zone: 'Upper Zone', zoneId: "5" },
  };

  var _526 = {
    path:
      'M 749.00,1614.00 C 749.00,1614.00 771.00,1619.13 771.00,1619.13 771.00,1619.13 806.00,1626.75 806.00,1626.75 806.00,1626.75 821.00,1629.42 821.00,1629.42 821.00,1629.42 826.86,1631.73 826.86,1631.73 826.86,1631.73 826.86,1642.00 826.86,1642.00 826.86,1642.00 822.73,1669.00 822.73,1669.00 822.73,1669.00 807.00,1777.00 807.00,1777.00 791.91,1775.78 747.11,1765.90 731.00,1761.87 731.00,1761.87 713.00,1757.29 713.00,1757.29 710.95,1756.69 707.52,1756.01 706.78,1753.70 706.12,1751.63 708.60,1744.32 709.34,1742.00 709.34,1742.00 717.67,1715.00 717.67,1715.00 717.67,1715.00 749.00,1614.00 749.00,1614.00 Z',
    attr: {
      id: '_526',
      'data-name': '526',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "526", zone: 'Upper Zone', zoneId: "5" },
  };

  var _527 = {
    path:
      'M 661.00,1585.00 C 667.87,1586.57 674.40,1589.53 681.00,1591.95 692.12,1596.02 727.98,1608.46 738.00,1610.00 738.00,1610.00 731.67,1633.00 731.67,1633.00 731.67,1633.00 723.03,1661.00 723.03,1661.00 723.03,1661.00 695.00,1752.00 695.00,1752.00 680.24,1749.02 646.59,1737.31 631.00,1732.00 631.00,1732.00 615.00,1726.05 615.00,1726.05 613.13,1725.33 610.14,1724.46 609.12,1722.58 607.86,1720.23 610.16,1715.38 611.05,1713.00 611.05,1713.00 620.80,1688.00 620.80,1688.00 620.80,1688.00 647.80,1618.00 647.80,1618.00 647.80,1618.00 661.00,1585.00 661.00,1585.00 Z',
    attr: {
      id: '_527',
      'data-name': '527',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "527", zone: 'Upper Zone', zoneId: "5" },
  };

  var _528 = {
    path:
      'M 586.00,1551.00 C 602.53,1558.12 634.98,1575.68 651.00,1580.00 651.00,1580.00 639.42,1610.00 639.42,1610.00 639.42,1610.00 617.81,1666.00 617.81,1666.00 617.81,1666.00 597.00,1719.00 597.00,1719.00 568.75,1709.20 542.38,1694.80 515.00,1683.00 515.00,1683.00 541.69,1633.00 541.69,1633.00 541.69,1633.00 586.00,1551.00 586.00,1551.00 Z',
    attr: {
      id: '_528',
      'data-name': '528',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "528", zone: 'Upper Zone', zoneId: "5" },
  };

  var _529 = {
    path:
      'M 521.00,1508.00 C 521.00,1508.00 541.00,1526.47 541.00,1526.47 541.00,1526.47 576.00,1546.00 576.00,1546.00 576.00,1546.00 556.31,1584.00 556.31,1584.00 556.31,1584.00 525.69,1641.00 525.69,1641.00 525.69,1641.00 506.00,1678.00 506.00,1678.00 506.00,1678.00 477.00,1663.00 477.00,1663.00 477.00,1663.00 464.18,1657.52 464.18,1657.52 464.18,1657.52 458.00,1665.00 458.00,1665.00 449.83,1661.32 432.03,1643.03 425.00,1636.00 422.67,1633.67 415.20,1627.16 415.20,1624.00 415.20,1621.14 420.04,1617.00 421.99,1615.00 421.99,1615.00 440.42,1595.00 440.42,1595.00 440.42,1595.00 463.91,1570.00 463.91,1570.00 463.91,1570.00 497.09,1534.00 497.09,1534.00 497.09,1534.00 521.00,1508.00 521.00,1508.00 Z',
    attr: {
      id: '_529',
      'data-name': '529',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "529", zone: 'Upper Zone', zoneId: "5" },
  };

  var _530 = {
    path:
      'M 342.00,1540.00 C 342.00,1540.00 435.00,1476.00 435.00,1476.00 435.00,1476.00 461.00,1458.00 461.00,1458.00 461.00,1458.00 475.00,1450.00 475.00,1450.00 475.00,1450.00 504.42,1487.00 504.42,1487.00 504.42,1487.00 515.00,1500.00 515.00,1500.00 515.00,1500.00 434.09,1587.00 434.09,1587.00 434.09,1587.00 414.91,1608.00 414.91,1608.00 413.07,1609.97 408.81,1614.80 406.01,1614.80 403.47,1614.80 401.26,1611.71 399.72,1610.00 399.72,1610.00 388.85,1598.00 388.85,1598.00 377.52,1585.13 350.46,1553.39 342.00,1540.00 Z',
    attr: {
      id: '_530',
      'data-name': '530',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "530", zone: 'Upper Zone', zoneId: "5" },
  };

  var _531 = {
    path:
      'M 422.00,1373.00 C 422.00,1373.00 433.69,1391.00 433.69,1391.00 433.69,1391.00 455.02,1423.00 455.02,1423.00 457.34,1426.34 466.55,1437.30 465.56,1440.91 464.89,1443.36 461.03,1445.64 459.00,1447.00 459.00,1447.00 440.00,1460.03 440.00,1460.03 440.00,1460.03 366.00,1511.03 366.00,1511.03 366.00,1511.03 345.00,1525.58 345.00,1525.58 342.92,1527.05 338.70,1530.33 336.09,1529.99 333.66,1529.67 331.91,1526.81 330.58,1525.00 330.58,1525.00 321.34,1512.00 321.34,1512.00 321.34,1512.00 296.32,1474.00 296.32,1474.00 292.42,1467.82 285.97,1458.74 284.00,1452.00 284.00,1452.00 358.00,1409.85 358.00,1409.85 358.00,1409.85 399.00,1386.42 399.00,1386.42 399.00,1386.42 422.00,1373.00 422.00,1373.00 Z',
    attr: {
      id: '_531',
      'data-name': '531',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "531", zone: 'Upper Zone', zoneId: "5" },
  };

  var _532 = {
    path:
      'M 235.00,1359.00 C 235.00,1359.00 332.00,1315.70 332.00,1315.70 332.00,1315.70 361.00,1302.70 361.00,1302.70 361.00,1302.70 381.00,1295.00 381.00,1295.00 386.73,1311.41 407.99,1346.96 417.00,1364.00 417.00,1364.00 380.00,1385.42 380.00,1385.42 380.00,1385.42 313.00,1423.72 313.00,1423.72 313.00,1423.72 288.00,1438.00 288.00,1438.00 285.69,1439.32 280.87,1442.67 278.21,1441.71 276.18,1440.97 274.62,1437.79 273.58,1436.00 273.58,1436.00 265.74,1422.00 265.74,1422.00 265.74,1422.00 245.75,1383.00 245.75,1383.00 242.23,1375.96 236.74,1366.40 235.00,1359.00 Z',
    attr: {
      id: '_532',
      'data-name': '532',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "532", zone: 'Upper Zone', zoneId: "5" },
  };

  var _533 = {
    path:
      'M 230.00,1349.00 C 224.21,1338.55 208.23,1297.76 203.69,1285.00 203.69,1285.00 198.00,1269.00 198.00,1269.00 197.41,1267.24 196.22,1264.09 196.78,1262.27 197.66,1259.44 203.41,1258.23 206.00,1257.31 206.00,1257.31 232.00,1248.19 232.00,1248.19 232.00,1248.19 295.00,1226.31 295.00,1226.31 295.00,1226.31 319.00,1217.95 319.00,1217.95 319.00,1217.95 345.00,1209.00 345.00,1209.00 345.00,1209.00 352.42,1229.00 352.42,1229.00 352.42,1229.00 363.60,1257.00 363.60,1257.00 363.60,1257.00 375.00,1285.00 375.00,1285.00 375.00,1285.00 280.00,1327.30 280.00,1327.30 280.00,1327.30 230.00,1349.00 230.00,1349.00 Z',
    attr: {
      id: '_533',
      'data-name': '533',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "533", zone: 'Upper Zone', zoneId: "5" },
  };

  var _534 = {
    path:
      'M 165.00,1150.00 C 165.00,1150.00 204.00,1142.60 204.00,1142.60 204.00,1142.60 279.00,1127.40 279.00,1127.40 279.00,1127.40 308.00,1121.60 308.00,1121.60 308.00,1121.60 318.42,1121.04 318.42,1121.04 318.42,1121.04 320.88,1127.00 320.88,1127.00 320.88,1127.00 324.37,1141.00 324.37,1141.00 324.37,1141.00 333.34,1173.00 333.34,1173.00 333.34,1173.00 341.00,1199.00 341.00,1199.00 341.00,1199.00 271.00,1223.31 271.00,1223.31 271.00,1223.31 231.00,1237.19 231.00,1237.19 231.00,1237.19 203.00,1247.00 203.00,1247.00 200.51,1247.85 194.85,1250.27 192.51,1248.89 190.59,1247.76 189.73,1244.02 189.14,1242.00 189.14,1242.00 183.71,1224.00 183.71,1224.00 176.71,1199.65 169.69,1174.91 165.00,1150.00 Z',
    attr: {
      id: '_534',
      'data-name': '534',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "534", zone: 'Upper Zone', zoneId: "5" },
  };

  var _535 = {
    path:
      'M 148.00,1039.00 C 148.00,1039.00 179.00,1036.17 179.00,1036.17 179.00,1036.17 198.00,1034.83 198.00,1034.83 198.00,1034.83 267.00,1028.91 267.00,1028.91 267.00,1028.91 303.00,1026.00 303.00,1026.00 306.24,1050.55 310.79,1085.56 317.00,1109.00 317.00,1109.00 278.00,1116.40 278.00,1116.40 278.00,1116.40 203.00,1131.60 203.00,1131.60 203.00,1131.60 174.00,1137.40 174.00,1137.40 171.43,1137.91 165.78,1139.55 163.58,1137.96 161.82,1136.68 161.43,1133.02 161.08,1131.00 161.08,1131.00 157.75,1113.00 157.75,1113.00 155.02,1096.60 148.01,1053.75 148.00,1039.00 Z',
    attr: {
      id: '_535',
      'data-name': '535',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "535", zone: 'Upper Zone', zoneId: "5" },
  };

  var _536 = {
    path:
      'M 142.00,929.00 C 142.00,929.00 161.00,930.00 161.00,930.00 161.00,930.00 204.00,930.00 204.00,930.00 204.00,930.00 221.00,931.00 221.00,931.00 221.00,931.00 265.00,931.00 265.00,931.00 265.00,931.00 280.00,932.00 280.00,932.00 280.00,932.00 298.00,932.00 298.00,932.00 298.00,932.00 298.00,959.00 298.00,959.00 298.00,959.00 299.00,975.00 299.00,975.00 299.00,975.00 302.00,1015.00 302.00,1015.00 302.00,1015.00 267.00,1017.83 267.00,1017.83 267.00,1017.83 190.00,1024.17 190.00,1024.17 190.00,1024.17 156.00,1027.09 156.00,1027.09 156.00,1027.09 146.00,1028.00 146.00,1028.00 146.00,1028.00 144.00,1001.00 144.00,1001.00 144.00,1001.00 144.00,992.00 144.00,992.00 144.00,992.00 143.00,980.00 143.00,980.00 143.00,980.00 142.00,958.00 142.00,958.00 142.00,958.00 142.00,929.00 142.00,929.00 Z',
    attr: {
      id: '_536',
      'data-name': '536',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "536", zone: 'Upper Zone', zoneId: "5" },
  };

  var _537 = {
    path:
      'M 146.00,824.00 C 146.00,824.00 182.00,827.83 182.00,827.83 182.00,827.83 254.00,835.17 254.00,835.17 254.00,835.17 303.00,840.00 303.00,840.00 303.00,840.00 299.91,873.00 299.91,873.00 299.91,873.00 299.00,888.00 299.00,888.00 299.00,888.00 298.00,902.00 298.00,902.00 298.00,902.00 298.00,921.00 298.00,921.00 298.00,921.00 282.00,921.00 282.00,921.00 282.00,921.00 266.00,920.00 266.00,920.00 266.00,920.00 222.00,920.00 222.00,920.00 222.00,920.00 205.00,919.00 205.00,919.00 205.00,919.00 162.00,919.00 162.00,919.00 162.00,919.00 146.00,918.00 146.00,918.00 146.00,918.00 142.00,918.00 142.00,918.00 142.00,918.00 142.00,896.00 142.00,896.00 142.00,896.00 143.00,873.00 143.00,873.00 143.00,873.00 144.00,858.00 144.00,858.00 144.00,858.00 146.00,824.00 146.00,824.00 Z',
    attr: {
      id: '_537',
      'data-name': '537',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "537", zone: 'Upper Zone', zoneId: "5" },
  };

  var _538 = {
    path:
      'M 160.00,717.00 C 160.00,717.00 189.00,723.21 189.00,723.21 189.00,723.21 241.00,734.21 241.00,734.21 241.00,734.21 280.00,742.42 280.00,742.42 280.00,742.42 317.00,750.00 317.00,750.00 317.00,750.00 307.73,801.00 307.73,801.00 307.73,801.00 304.00,829.00 304.00,829.00 304.00,829.00 255.00,824.17 255.00,824.17 255.00,824.17 183.00,816.83 183.00,816.83 183.00,816.83 147.00,813.00 147.00,813.00 147.00,813.00 152.28,770.00 152.28,770.00 152.28,770.00 160.00,717.00 160.00,717.00 Z',
    attr: {
      id: '_538',
      'data-name': '538',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "538", zone: 'Upper Zone', zoneId: "5" },
  };

  var _539 = {
    path:
      'M 183.00,612.00 C 183.00,612.00 254.00,630.85 254.00,630.85 254.00,630.85 312.00,646.58 312.00,646.58 312.00,646.58 342.00,655.00 342.00,655.00 342.00,655.00 324.63,716.00 324.63,716.00 324.63,716.00 320.87,732.00 320.87,732.00 320.87,732.00 318.28,737.97 318.28,737.97 318.28,737.97 308.00,737.20 308.00,737.20 308.00,737.20 279.00,731.00 279.00,731.00 279.00,731.00 199.00,714.20 199.00,714.20 199.00,714.20 163.00,707.00 163.00,707.00 163.00,707.00 167.80,679.00 167.80,679.00 167.80,679.00 183.00,612.00 183.00,612.00 Z',
    attr: {
      id: '_539',
      'data-name': '539',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "539", zone: 'Upper Zone', zoneId: "5" },
  };

  var _540 = {
    path:
      'M 292.00,528.00 C 292.00,528.00 336.00,546.58 336.00,546.58 336.00,546.58 375.00,564.00 375.00,564.00 375.00,564.00 356.05,613.00 356.05,613.00 356.05,613.00 345.00,644.00 345.00,644.00 345.00,644.00 323.00,638.15 323.00,638.15 323.00,638.15 281.00,626.85 281.00,626.85 281.00,626.85 258.00,621.00 258.00,621.00 258.68,613.46 266.53,595.08 269.58,587.00 269.58,587.00 292.00,528.00 292.00,528.00 Z',
    attr: {
      id: '_540',
      'data-name': '540',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "540", zone: 'Upper Zone', zoneId: "5" },
  };

  var _TMLOUNGE = {
    path:
      'M 890.00,1583.00 C 901.24,1580.99 927.79,1584.84 941.00,1585.00 941.00,1585.00 987.00,1585.00 987.00,1585.00 987.00,1585.00 1004.00,1584.00 1004.00,1584.00 1004.00,1584.00 1018.00,1583.09 1018.00,1583.09 1018.00,1583.09 1085.00,1576.00 1085.00,1576.00 1085.00,1576.00 1088.00,1596.00 1088.00,1596.00 1073.80,1599.58 1048.03,1602.04 1033.00,1603.09 1033.00,1603.09 996.00,1605.00 996.00,1605.00 996.00,1605.00 979.00,1606.00 979.00,1606.00 979.00,1606.00 961.00,1606.00 961.00,1606.00 961.00,1606.00 945.00,1605.00 945.00,1605.00 945.00,1605.00 921.00,1604.09 921.00,1604.09 921.00,1604.09 893.00,1602.00 893.00,1602.00 893.00,1602.00 895.00,1583.00 895.00,1583.00 895.00,1583.00 890.00,1583.00 890.00,1583.00 Z',
    attr: {
      id: '_TMLOUNGE',
      'data-name': 'TMLOUNGE',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'TM LOUNGE', zoneId: "2" },
  };



  var _W207 = {
    path:
      'M 1476.00,528.00 C 1476.00,528.00 1493.00,520.00 1493.00,520.00 1504.55,541.86 1515.35,564.24 1525.00,587.00 1525.00,587.00 1506.00,594.00 1506.00,594.00 1506.00,594.00 1486.14,551.00 1486.14,551.00 1486.14,551.00 1476.00,528.00 1476.00,528.00 Z',
    attr: {
      id: '_W207',
      'data-name': 'W 207',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "207", zone: 'Middle Zone W', zoneId: "2" },
  };

  var _W240 = {
    path:
      'M 459.00,527.00 C 459.00,527.00 483.00,540.00 483.00,540.00 483.00,540.00 472.26,564.00 472.26,564.00 472.26,564.00 453.00,603.00 453.00,603.00 453.00,603.00 427.00,592.00 427.00,592.00 428.38,584.66 434.25,576.00 437.75,569.00 437.75,569.00 459.00,527.00 459.00,527.00 Z',
    attr: {
      id: '_W240',
      'data-name': 'W 240',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "240", zone: 'Middle Zone W', zoneId: "2" },
  };

  var _WESTJETFLIGHTDECK = {
    path:
      'M 668.00,356.00 C 707.95,333.26 761.45,313.88 806.00,302.12 953.47,263.18 1108.73,274.12 1246.00,342.75 1246.00,342.75 1274.00,357.00 1274.00,357.00 1274.00,357.00 1258.85,384.00 1258.85,384.00 1257.17,386.92 1252.72,395.32 1250.39,397.10 1247.90,399.00 1232.03,399.80 1228.00,400.00 1228.00,400.00 1182.00,405.53 1182.00,405.53 1176.02,406.02 1160.47,398.31 1154.00,395.81 1138.17,389.70 1117.58,383.82 1101.00,379.88 1073.10,373.27 1044.57,368.91 1016.00,366.91 1016.00,366.91 1006.00,365.96 1006.00,365.96 1006.00,365.96 995.00,365.96 995.00,365.96 995.00,365.96 983.00,365.00 983.00,365.00 983.00,365.00 966.00,365.00 966.00,365.00 966.00,365.00 944.00,366.00 944.00,366.00 944.00,366.00 903.00,369.72 903.00,369.72 873.21,373.60 843.96,379.37 815.00,387.42 815.00,387.42 777.00,399.33 777.00,399.33 769.17,401.95 763.46,405.29 755.00,404.96 755.00,404.96 710.00,401.03 710.00,401.03 706.18,400.82 692.17,399.97 689.63,398.05 687.50,396.42 684.13,388.74 682.75,386.00 678.69,377.92 670.31,364.11 668.00,356.00 Z',
    attr: {
      id: '_WESTJETFLIGHTDECK',
      'data-name': 'WESTJETFLIGHTDECK',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'WESTJET FLIGHT DECK', zoneId: "2" },
  };

  var _1 = {
    path:
      'M 1079.00,1243.00 C 1084.87,1246.73 1089.84,1251.64 1095.00,1256.28 1096.72,1257.84 1099.80,1260.46 1099.80,1262.99 1099.80,1265.93 1094.16,1270.01 1092.00,1271.87 1085.58,1277.40 1071.41,1290.26 1065.00,1294.00 1065.00,1294.00 1056.25,1277.00 1056.25,1277.00 1055.18,1274.84 1053.29,1271.43 1053.52,1269.00 1053.90,1265.22 1058.35,1262.21 1061.00,1259.84 1061.00,1259.84 1079.00,1243.00 1079.00,1243.00 Z',
    attr: {
      id: '_1',
      'data-name': '1',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "1", zone: 'Lower Zone', zoneId: "1" },
  };

  var _2 = {
    path:
      'M 1016.00,1316.00 C 1016.00,1316.00 1013.81,1297.00 1013.81,1297.00 1013.81,1297.00 1013.81,1290.21 1013.81,1290.21 1013.81,1290.21 1020.00,1285.25 1020.00,1285.25 1020.00,1285.25 1039.00,1277.00 1039.00,1277.00 1039.00,1277.00 1051.00,1302.00 1051.00,1302.00 1051.00,1302.00 1016.00,1316.00 1016.00,1316.00 Z',
    attr: {
      id: '_2',
      'data-name': '2',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "2", zone: 'Lower Zone', zoneId: "1" },
  };

  var _3 = {
    path:
      'M 950.00,1292.00 C 950.00,1292.00 966.00,1293.95 966.00,1293.95 966.00,1293.95 986.00,1293.95 986.00,1293.95 988.76,1293.96 995.25,1292.56 996.98,1294.61 998.00,1295.82 998.51,1300.32 998.72,1302.00 998.72,1302.00 1001.00,1320.00 1001.00,1320.00 1001.00,1320.00 948.00,1320.00 948.00,1320.00 948.00,1320.00 950.00,1292.00 950.00,1292.00 Z',
    attr: {
      id: '_3',
      'data-name': '3',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "3", zone: 'Lower Zone', zoneId: "1" },
  };

  var _4 = {
    path:
      'M 900.00,1273.00 C 900.00,1273.00 935.00,1287.00 935.00,1287.00 935.00,1287.00 932.00,1318.00 932.00,1318.00 932.00,1318.00 888.00,1303.00 888.00,1303.00 889.05,1296.28 896.64,1279.47 900.00,1273.00 Z',
    attr: {
      id: '_4',
      'data-name': '4',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "4", zone: 'Lower Zone', zoneId: "1" },
  };

  var _5 = {
    path:
      'M 859.00,1243.00 C 859.00,1243.00 870.00,1251.55 870.00,1251.55 870.00,1251.55 878.00,1257.13 878.00,1257.13 878.00,1257.13 885.22,1264.17 885.22,1264.17 885.22,1264.17 882.42,1274.00 882.42,1274.00 882.42,1274.00 873.00,1296.00 873.00,1296.00 873.00,1296.00 849.08,1281.65 849.08,1281.65 849.08,1281.65 835.00,1264.00 835.00,1264.00 835.00,1264.00 859.00,1243.00 859.00,1243.00 Z',
    attr: {
      id: '_5',
      'data-name': '5',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "5", zone: 'Lower Zone', zoneId: "1" },
  };

  var _16 = {
    path:
      'M 1199.00,1165.00 C 1199.00,1165.00 1238.00,1199.00 1238.00,1199.00 1234.58,1204.85 1230.50,1209.06 1225.00,1213.00 1225.00,1213.00 1187.00,1178.00 1187.00,1178.00 1187.00,1178.00 1199.00,1165.00 1199.00,1165.00 Z',
    attr: {
      id: '_16',
      'data-name': '16',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "16", zone: 'Lower Zone', zoneId: "1" },
  };

  var _17 = {
    path:
      'M 1176.00,1190.00 C 1176.00,1190.00 1215.00,1223.00 1215.00,1223.00 1213.33,1228.67 1206.58,1235.22 1202.00,1239.00 1202.00,1239.00 1175.00,1214.01 1175.00,1214.01 1172.72,1211.81 1166.32,1206.99 1166.11,1203.99 1165.86,1200.51 1173.65,1193.28 1176.00,1190.00 Z',
    attr: {
      id: '_17',
      'data-name': '17',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "17", zone: 'Lower Zone', zoneId: "1" },
  };

  var _18 = {
    path:
      'M 1141.00,1230.00 C 1143.51,1226.48 1151.19,1215.50 1156.00,1217.17 1158.43,1218.01 1163.76,1223.80 1166.00,1225.83 1173.12,1232.27 1186.85,1243.81 1192.00,1251.00 1192.00,1251.00 1180.91,1260.81 1180.91,1260.81 1180.91,1260.81 1169.00,1252.58 1169.00,1252.58 1169.00,1252.58 1141.00,1230.00 1141.00,1230.00 Z',
    attr: {
      id: '_18',
      'data-name': '18',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "18", zone: 'Lower Zone', zoneId: "1" },
  };

  var _19 = {
    path:
      'M 1130.00,1241.00 C 1136.17,1244.26 1141.56,1249.41 1147.00,1253.80 1147.00,1253.80 1164.00,1267.75 1164.00,1267.75 1165.73,1269.24 1168.80,1271.50 1168.80,1274.01 1168.81,1276.47 1165.02,1280.15 1163.42,1282.00 1161.53,1284.19 1158.16,1288.71 1155.00,1288.66 1152.13,1288.63 1148.10,1284.06 1146.00,1282.17 1146.00,1282.17 1126.00,1264.28 1126.00,1264.28 1123.78,1262.32 1119.32,1259.15 1119.11,1256.00 1118.87,1252.47 1127.51,1244.29 1130.00,1241.00 ',
    attr: {
      id: '_19',
      'data-name': '19',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "19", zone: 'Lower Zone', zoneId: "1" },
  };

  var _20 = {
    path:
      'M 1113.00,1273.00 C 1113.00,1273.00 1130.00,1287.83 1130.00,1287.83 1130.00,1287.83 1145.00,1302.00 1145.00,1302.00 1145.00,1302.00 1130.00,1319.00 1130.00,1319.00 1124.81,1314.03 1104.37,1289.77 1101.00,1284.00 1101.00,1284.00 1113.00,1273.00 1113.00,1273.00 Z',
    attr: {
      id: '_20',
      'data-name': '20',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "20", zone: 'Lower Zone', zoneId: "1" },
  };

  var _21 = {
    path:
      'M 1071.00,1309.00 C 1074.19,1306.59 1085.15,1296.44 1087.96,1296.05 1091.27,1295.60 1094.69,1300.72 1096.59,1303.00 1096.59,1303.00 1117.00,1328.00 1117.00,1328.00 1117.00,1328.00 1091.00,1344.00 1091.00,1344.00 1086.74,1338.17 1072.80,1315.17 1071.00,1309.00 Z',
    attr: {
      id: '_21',
      'data-name': '21',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "21", zone: 'Lower Zone', zoneId: "1" },
  };

  var _22 = {
    path:
      'M 1044.00,1364.00 C 1044.00,1364.00 1037.13,1337.00 1037.13,1337.00 1036.44,1334.28 1034.57,1328.90 1036.06,1326.41 1037.15,1324.61 1040.13,1323.54 1042.00,1322.72 1046.77,1320.62 1051.98,1318.35 1057.00,1317.00 1057.00,1317.00 1078.00,1353.00 1078.00,1353.00 1069.39,1357.52 1053.65,1362.51 1044.00,1364.00 Z',
    attr: {
      id: '_22',
      'data-name': '22',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "22", zone: 'Lower Zone', zoneId: "1" },
  };

  var _23 = {
    path:
      'M 995.00,1338.00 C 1011.33,1338.00 1009.43,1334.18 1023.00,1331.00 1023.00,1331.00 1029.65,1359.00 1029.65,1359.00 1030.26,1361.65 1032.38,1368.25 1031.39,1370.59 1030.38,1372.97 1025.32,1374.47 1023.00,1375.40 1009.75,1380.70 1012.13,1380.00 998.00,1380.00 998.00,1380.00 995.00,1338.00 995.00,1338.00 Z',
    attr: {
      id: '_23',
      'data-name': '23',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "23", zone: 'Lower Zone', zoneId: "1" },
  };

  var _24 = {
    path:
      'M 954.00,1338.00 C 954.00,1338.00 981.00,1338.00 981.00,1338.00 981.00,1338.00 985.00,1380.00 985.00,1380.00 985.00,1380.00 954.00,1380.00 954.00,1380.00 954.00,1380.00 954.00,1338.00 954.00,1338.00 Z',
    attr: {
      id: '_24',
      'data-name': '24',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "24", zone: 'Lower Zone', zoneId: "1" },
  };

  var _25 = {
    path:
      'M 922.00,1331.00 C 929.65,1334.04 933.61,1335.92 942.00,1336.00 942.00,1336.00 940.00,1369.00 940.00,1369.00 939.96,1371.45 940.18,1375.15 937.57,1376.42 935.30,1377.53 931.29,1376.34 929.00,1375.66 929.00,1375.66 913.00,1371.00 913.00,1371.00 913.00,1371.00 922.00,1331.00 922.00,1331.00 Z',
    attr: {
      id: '_25',
      'data-name': '25',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "25", zone: 'Lower Zone', zoneId: "1" },
  };

  var _26 = {
    path:
      'M 881.00,1317.00 C 881.00,1317.00 907.00,1326.00 907.00,1326.00 907.00,1326.00 898.00,1365.00 898.00,1365.00 890.47,1364.32 867.42,1356.88 861.00,1353.00 861.00,1353.00 881.00,1317.00 881.00,1317.00 Z',
    attr: {
      id: '_26',
      'data-name': '26',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "26", zone: 'Lower Zone', zoneId: "1" },
  };

  var _27 = {
    path:
      'M 820.00,1329.00 C 822.34,1322.13 833.41,1311.92 838.58,1306.00 840.67,1303.60 843.48,1299.75 847.00,1299.73 849.68,1299.71 862.90,1308.17 867.00,1310.00 867.00,1310.00 848.00,1344.00 848.00,1344.00 848.00,1344.00 820.00,1329.00 820.00,1329.00 Z',
    attr: {
      id: '_27',
      'data-name': '27',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "27", zone: 'Lower Zone', zoneId: "1" },
  };

  var _28 = {
    path:
      'M 807.00,1318.00 C 807.00,1318.00 796.15,1306.00 796.15,1306.00 794.69,1304.24 793.27,1302.50 794.17,1300.09 794.94,1298.00 800.14,1293.86 802.00,1292.27 807.72,1287.37 817.64,1277.87 824.00,1275.00 824.00,1275.00 832.83,1288.00 832.83,1288.00 832.83,1288.00 826.92,1297.00 826.92,1297.00 826.92,1297.00 807.00,1318.00 807.00,1318.00 Z',
    attr: {
      id: '_28',
      'data-name': '28',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "28", zone: 'Lower Zone', zoneId: "1" },
  };

  var _29 = {
    path:
      'M 805.00,1239.00 C 810.97,1243.27 816.76,1250.00 821.00,1256.00 821.00,1256.00 793.00,1280.42 793.00,1280.42 790.62,1282.44 785.09,1287.98 782.00,1287.84 777.56,1287.65 768.52,1275.77 766.00,1272.00 766.00,1272.00 805.00,1239.00 805.00,1239.00 Z',
    attr: {
      id: '_29',
      'data-name': '29',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "29", zone: 'Lower Zone', zoneId: "1" },
  };

  var _30 = {
    path:
      'M 784.00,1216.00 C 786.73,1217.96 793.80,1223.43 793.80,1226.99 793.80,1230.10 787.33,1234.63 785.00,1236.59 777.88,1242.57 761.89,1256.98 755.00,1261.00 752.75,1258.74 746.38,1253.17 746.34,1250.00 746.29,1246.66 752.54,1242.28 755.00,1240.25 755.00,1240.25 784.00,1216.00 784.00,1216.00 Z',
    attr: {
      id: '_30',
      'data-name': '30',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "30", zone: 'Lower Zone', zoneId: "1" },
  };

  var _31 = {
    path:
      'M 760.00,1189.00 C 762.81,1191.11 772.80,1200.77 772.80,1203.99 772.80,1206.98 767.20,1210.93 765.00,1212.75 765.00,1212.75 744.00,1230.08 744.00,1230.08 741.99,1231.76 737.54,1235.86 735.00,1236.10 731.07,1236.47 721.62,1227.22 723.17,1223.17 723.88,1221.31 730.23,1215.84 732.00,1214.28 732.00,1214.28 742.00,1205.28 742.00,1205.28 742.00,1205.28 760.00,1189.00 760.00,1189.00 Z',
    attr: {
      id: '_31',
      'data-name': '31',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "31", zone: 'Lower Zone', zoneId: "1" },
  };

  var _32 = {
    path:
      'M 750.00,1178.00 C 750.00,1178.00 733.00,1193.72 733.00,1193.72 733.00,1193.72 722.00,1203.42 722.00,1203.42 720.03,1205.13 715.03,1210.13 713.00,1210.83 708.89,1212.25 699.25,1203.12 700.34,1199.09 700.87,1197.11 706.28,1192.63 708.00,1191.07 708.00,1191.07 729.00,1172.42 729.00,1172.42 730.97,1170.72 735.47,1166.19 738.00,1166.20 741.76,1166.20 747.93,1175.01 750.00,1178.00 Z',
    attr: {
      id: '_32',
      'data-name': '32',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "32", zone: 'Lower Zone', zoneId: "1" },
  };

  var _101A = {
    path:
      'M 1049.00,454.00 C 1049.00,454.00 1051.59,445.97 1051.59,445.97 1051.59,445.97 1060.00,445.97 1060.00,445.97 1060.00,445.97 1082.00,449.00 1082.00,449.00 1082.00,449.00 1078.00,466.00 1078.00,466.00 1071.44,464.81 1055.37,457.31 1049.00,454.00 Z',
    attr: {
      id: '_101A',
      'data-name': '101A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "101", zone: 'Lower Zone A', zoneId: "1" },
  };
  var _101B = {
    path:
      'M 1095.00,394.00 C 1094.65,401.45 1090.35,416.08 1088.37,424.00 1087.73,426.59 1086.53,433.50 1084.44,434.96 1082.06,436.63 1071.20,434.29 1068.00,433.75 1065.33,433.31 1055.45,432.38 1054.11,430.40 1051.74,426.90 1055.44,419.28 1058.13,416.89 1058.13,416.89 1077.00,402.37 1077.00,402.37 1083.96,397.13 1086.06,393.58 1095.00,394.00 Z',
    attr: {
      id: '_101B',
      'data-name': '101B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "101", zone: 'Lower Zone B', zoneId: "1" },
  };
  var _102A = {
    path:
      'M 1095.00,453.00 C 1095.00,453.00 1145.00,471.00 1145.00,471.00 1145.00,471.00 1139.15,485.00 1139.15,485.00 1138.41,486.68 1137.15,489.97 1135.51,490.87 1132.89,492.32 1125.65,488.44 1123.00,487.23 1123.00,487.23 1090.00,473.00 1090.00,473.00 1090.00,473.00 1095.00,453.00 1095.00,453.00 Z',
    attr: {
      id: '_102A',
      'data-name': '102A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "102", zone: 'Lower Zone A', zoneId: "1" },
  };

  var _102B = {
    path:
      'M 1109.00,398.00 C 1125.07,401.24 1152.29,411.72 1168.00,418.00 1168.00,418.00 1151.00,458.00 1151.00,458.00 1151.00,458.00 1132.00,452.00 1132.00,452.00 1132.00,452.00 1098.00,440.00 1098.00,440.00 1098.00,440.00 1101.44,427.00 1101.44,427.00 1101.44,427.00 1105.12,415.00 1105.12,415.00 1105.12,415.00 1109.00,398.00 1109.00,398.00 Z',
    attr: {
      id: '_102B',
      'data-name': '102B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "102", zone: 'Lower Zone B', zoneId: "1" },
  };

  var _103A = {
    path:
      'M 1157.00,476.00 C 1157.00,476.00 1194.00,494.00 1194.00,494.00 1191.34,499.97 1187.26,506.39 1183.80,512.00 1182.55,514.02 1180.65,517.67 1177.91,517.67 1175.37,517.66 1170.10,512.86 1168.00,511.21 1158.61,503.82 1158.53,503.49 1148.00,498.00 1148.00,498.00 1157.00,476.00 1157.00,476.00 Z',
    attr: {
      id: '_103A',
      'data-name': '103A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "103", zone: 'Lower Zone A', zoneId: "1" },
  };
  var _103B = {
    path:
      'M 1180.00,424.00 C 1188.73,426.05 1214.72,440.10 1224.00,445.00 1224.00,445.00 1201.00,483.00 1201.00,483.00 1201.00,483.00 1163.00,464.00 1163.00,464.00 1163.00,464.00 1180.00,424.00 1180.00,424.00 Z',
    attr: {
      id: '_103B',
      'data-name': '103B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "103", zone: 'Lower Zone B', zoneId: "1" },
  };
  var _105 = {
    path:
      'M 1304.00,540.00 C 1304.00,540.00 1332.00,562.58 1332.00,562.58 1332.00,562.58 1360.00,587.00 1360.00,587.00 1354.14,591.71 1353.87,590.65 1344.00,581.84 1344.00,581.84 1314.00,555.42 1314.00,555.42 1307.62,549.94 1303.15,548.74 1304.00,540.00 Z',
    attr: {
      id: '_105',
      'data-name': '105',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "105", zone: 'Lower Zone', zoneId: "1" },
  };
  var _108 = {
    path:
      'M 1346.00,836.00 C 1346.00,836.00 1372.00,808.00 1372.00,808.00 1372.00,808.00 1385.00,796.17 1385.00,796.17 1385.00,796.17 1400.00,793.00 1400.00,793.00 1400.00,793.00 1435.00,787.00 1435.00,787.00 1435.00,787.00 1436.99,808.00 1436.99,808.00 1436.99,808.00 1436.99,816.00 1436.99,816.00 1437.44,818.90 1438.89,824.98 1436.99,827.40 1435.49,829.29 1432.15,828.95 1430.00,829.01 1430.00,829.01 1415.00,830.28 1415.00,830.28 1415.00,830.28 1378.00,833.17 1378.00,833.17 1378.00,833.17 1346.00,836.00 1346.00,836.00 Z',
    attr: {
      id: '_108',
      'data-name': '108',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "108", zone: 'Lower Zone', zoneId: "1" },
  };
  var _109 = {
    path:
      'M 1447.00,840.42 C 1457.67,839.26 1454.17,843.88 1456.42,861.00 1456.86,864.28 1458.26,871.55 1454.69,873.42 1452.44,874.59 1444.74,874.96 1442.00,875.00 1442.00,875.00 1426.00,876.04 1426.00,876.04 1426.00,876.04 1404.00,877.91 1404.00,877.91 1404.00,877.91 1369.00,880.09 1369.00,880.09 1369.00,880.09 1353.00,881.42 1353.00,881.42 1350.79,881.70 1347.65,882.44 1346.02,880.40 1344.77,878.83 1345.00,874.98 1345.00,873.00 1345.00,873.00 1344.17,856.00 1344.17,856.00 1344.01,853.89 1343.93,851.43 1345.60,849.85 1347.84,847.74 1364.00,847.20 1368.00,846.91 1368.00,846.91 1424.00,842.17 1424.00,842.17 1424.00,842.17 1447.00,840.42 1447.00,840.42 Z',
    attr: {
      id: '_109',
      'data-name': '109',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "109", zone: 'Lower Zone', zoneId: "1" },
  };

  var _110 = {
    path:
      'M 1285.00,897.00 C 1292.86,894.59 1306.35,894.51 1315.00,893.91 1315.00,893.91 1355.00,890.99 1355.00,890.99 1355.00,890.99 1378.00,889.09 1378.00,889.09 1378.00,889.09 1404.00,887.10 1404.00,887.10 1404.00,887.10 1413.00,887.10 1413.00,887.10 1413.00,887.10 1433.00,884.97 1433.00,884.97 1433.00,884.97 1442.00,884.97 1442.00,884.97 1445.29,884.33 1454.81,882.97 1457.40,884.97 1459.31,886.53 1459.86,898.14 1460.00,901.00 1460.19,904.93 1462.27,913.54 1459.40,916.40 1457.24,918.56 1451.14,917.91 1448.00,918.28 1448.00,918.28 1415.00,921.04 1415.00,921.04 1415.00,921.04 1371.00,924.83 1371.00,924.83 1371.00,924.83 1326.00,928.17 1326.00,928.17 1326.00,928.17 1302.00,930.29 1302.00,930.29 1299.19,930.44 1293.87,931.65 1291.31,930.29 1288.27,928.92 1288.02,916.41 1288.00,913.00 1287.95,902.82 1288.31,906.27 1285.00,897.00 Z',
    attr: {
      id: '_110',
      'data-name': '110',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "110", zone: 'Lower Zone', zoneId: "1" },
  };

  var _111 = {
    path:
      'M 1455.00,927.43 C 1463.31,926.70 1462.59,930.28 1463.09,937.00 1463.09,937.00 1464.03,947.00 1464.03,947.00 1464.21,950.12 1466.03,955.70 1462.69,957.41 1460.63,958.45 1451.70,958.89 1449.00,959.09 1449.00,959.09 1423.00,961.09 1423.00,961.09 1423.00,961.09 1381.00,964.83 1381.00,964.83 1381.00,964.83 1336.00,968.17 1336.00,968.17 1336.00,968.17 1309.00,970.42 1309.00,970.42 1306.89,970.70 1294.56,971.81 1293.31,971.31 1290.31,970.11 1290.04,958.16 1290.00,955.00 1289.97,952.28 1289.54,944.56 1291.02,942.56 1293.04,939.83 1298.94,940.08 1302.00,939.99 1302.00,939.99 1321.00,938.00 1321.00,938.00 1321.00,938.00 1329.00,938.00 1329.00,938.00 1329.00,938.00 1420.00,930.17 1420.00,930.17 1420.00,930.17 1455.00,927.43 1455.00,927.43 Z',
    attr: {
      id: '_111',
      'data-name': '111',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "111", zone: 'Lower Zone', zoneId: "1" },
  };

  var _112 = {
    path:
      'M 1454.00,968.42 C 1456.58,968.11 1462.28,967.40 1464.42,969.02 1467.20,971.14 1466.85,978.74 1467.17,982.00 1467.17,982.00 1470.17,1015.00 1470.17,1015.00 1470.17,1015.00 1471.56,1029.00 1471.56,1029.00 1471.74,1031.02 1472.00,1033.39 1469.69,1034.31 1467.66,1035.12 1460.44,1033.27 1458.00,1032.80 1458.00,1032.80 1431.00,1027.60 1431.00,1027.60 1431.00,1027.60 1330.00,1008.00 1330.00,1008.00 1330.00,1008.00 1305.00,1003.00 1305.00,1003.00 1302.39,1002.48 1296.26,1001.54 1294.31,1000.07 1291.95,998.27 1292.05,995.69 1292.00,993.00 1291.96,990.64 1291.63,984.35 1293.02,982.60 1294.69,980.50 1300.33,981.03 1303.00,980.72 1303.00,980.72 1330.00,978.28 1330.00,978.28 1330.00,978.28 1352.00,976.83 1352.00,976.83 1352.00,976.83 1430.00,970.17 1430.00,970.17 1430.00,970.17 1454.00,968.42 1454.00,968.42 Z',
    attr: {
      id: '_112',
      'data-name': '112',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "112", zone: 'Lower Zone', zoneId: "1" },
  };

  var _113 = {
    path:
      'M 1247.00,1005.00 C 1247.00,1005.00 1303.00,1015.58 1303.00,1015.58 1303.00,1015.58 1422.00,1038.61 1422.00,1038.61 1422.00,1038.61 1465.00,1047.00 1465.00,1047.00 1465.00,1047.00 1452.25,1074.00 1452.25,1074.00 1452.25,1074.00 1434.00,1111.00 1434.00,1111.00 1434.00,1111.00 1388.00,1087.36 1388.00,1087.36 1388.00,1087.36 1291.00,1038.14 1291.00,1038.14 1291.00,1038.14 1260.00,1022.25 1260.00,1022.25 1260.00,1022.25 1247.99,1015.37 1247.99,1015.37 1247.99,1015.37 1247.00,1005.00 1247.00,1005.00 Z',
    attr: {
      id: '_113',
      'data-name': '113',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "113", zone: 'Lower Zone', zoneId: "1" },
  };

  var _114 = {
    path:
      'M 1246.79,1033.00 C 1246.79,1033.00 1272.00,1045.74 1272.00,1045.74 1272.00,1045.74 1325.00,1072.74 1325.00,1072.74 1325.00,1072.74 1382.00,1101.75 1382.00,1101.75 1382.00,1101.75 1427.00,1125.00 1427.00,1125.00 1427.00,1125.00 1409.00,1162.00 1409.00,1162.00 1409.00,1162.00 1375.00,1145.60 1375.00,1145.60 1375.00,1145.60 1311.00,1113.25 1311.00,1113.25 1311.00,1113.25 1257.00,1086.25 1257.00,1086.25 1257.00,1086.25 1230.00,1072.00 1230.00,1072.00 1230.00,1072.00 1239.52,1059.00 1239.52,1059.00 1239.52,1059.00 1246.79,1047.00 1246.79,1047.00 1246.79,1047.00 1246.79,1033.00 1246.79,1033.00 Z',
    attr: {
      id: '_114',
      'data-name': '114',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "114", zone: 'Lower Zone', zoneId: "1" },
  };

  var _115 = {
    path:
      'M 1208.00,1101.00 C 1208.00,1101.00 1221.09,1086.81 1221.09,1086.81 1221.09,1086.81 1234.00,1091.75 1234.00,1091.75 1234.00,1091.75 1266.00,1107.86 1266.00,1107.86 1266.00,1107.86 1345.00,1147.75 1345.00,1147.75 1345.00,1147.75 1401.00,1176.00 1401.00,1176.00 1401.00,1176.00 1380.00,1219.00 1380.00,1219.00 1380.00,1219.00 1343.00,1194.34 1343.00,1194.34 1343.00,1194.34 1267.00,1142.42 1267.00,1142.42 1267.00,1142.42 1226.00,1114.42 1226.00,1114.42 1226.00,1114.42 1208.00,1101.00 1208.00,1101.00 Z',
    attr: {
      id: '_115',
      'data-name': '115',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "115", zone: 'Lower Zone', zoneId: "1" },
  };

  var _116 = {
    path:
      'M 1227.00,1134.00 C 1227.00,1134.00 1267.00,1160.81 1267.00,1160.81 1267.00,1160.81 1334.00,1206.43 1334.00,1206.43 1334.00,1206.43 1372.00,1232.00 1372.00,1232.00 1367.41,1239.22 1354.89,1252.90 1348.72,1260.00 1346.86,1262.14 1341.77,1268.80 1338.96,1268.80 1336.47,1268.81 1331.01,1263.48 1329.00,1261.72 1329.00,1261.72 1305.00,1240.02 1305.00,1240.02 1305.00,1240.02 1241.00,1182.17 1241.00,1182.17 1233.46,1175.36 1215.11,1160.13 1210.00,1153.00 1210.00,1153.00 1227.00,1134.00 1227.00,1134.00 Z',
    attr: {
      id: '_116',
      'data-name': '116',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "116", zone: 'Lower Zone', zoneId: "1" },
  };

  var _117 = {
    path:
      'M 1249.00,1210.00 C 1249.00,1210.00 1275.00,1232.83 1275.00,1232.83 1275.00,1232.83 1311.00,1265.17 1311.00,1265.17 1311.00,1265.17 1329.00,1281.00 1329.00,1281.00 1326.40,1286.52 1311.01,1303.87 1306.00,1308.00 1306.00,1308.00 1254.00,1262.28 1254.00,1262.28 1254.00,1262.28 1225.00,1236.00 1225.00,1236.00 1225.00,1236.00 1249.00,1210.00 1249.00,1210.00 Z',
    attr: {
      id: '_117',
      'data-name': '117',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "117", zone: 'Lower Zone', zoneId: "1" },
  };

  var _118 = {
    path:
      'M 1214.00,1248.00 C 1220.45,1251.46 1227.31,1258.65 1233.00,1263.58 1233.00,1263.58 1272.00,1297.85 1272.00,1297.85 1272.00,1297.85 1288.00,1311.93 1288.00,1311.93 1289.52,1313.31 1293.42,1316.46 1294.26,1318.09 1295.89,1321.28 1293.18,1323.70 1291.26,1326.00 1291.26,1326.00 1277.42,1342.00 1277.42,1342.00 1275.59,1344.09 1273.16,1347.37 1270.00,1346.95 1267.36,1346.60 1263.86,1342.83 1262.00,1341.00 1262.00,1341.00 1247.00,1327.00 1247.00,1327.00 1247.00,1327.00 1218.00,1299.96 1218.00,1299.96 1218.00,1299.96 1191.00,1275.00 1191.00,1275.00 1191.00,1275.00 1214.00,1248.00 1214.00,1248.00 Z',
    attr: {
      id: '_118',
      'data-name': '118',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "118", zone: 'Lower Zone', zoneId: "1" },
  };

  var _119 = {
    path:
      'M 1154.00,1315.00 C 1159.35,1307.43 1166.16,1300.94 1172.28,1294.00 1174.11,1291.93 1178.07,1287.20 1181.00,1287.20 1183.51,1287.20 1186.28,1290.36 1188.00,1291.97 1188.00,1291.97 1202.00,1304.99 1202.00,1304.99 1202.00,1304.99 1226.00,1327.09 1226.00,1327.09 1226.00,1327.09 1236.00,1336.83 1236.00,1336.83 1241.67,1341.96 1257.17,1355.67 1261.00,1361.00 1261.00,1361.00 1237.00,1387.00 1237.00,1387.00 1237.00,1387.00 1177.00,1334.72 1177.00,1334.72 1177.00,1334.72 1154.00,1315.00 1154.00,1315.00 Z',
    attr: {
      id: '_119',
      'data-name': '119',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "119", zone: 'Lower Zone', zoneId: "1" },
  };
  var _120 = {
    path:
      'M 1134.00,1335.00 C 1136.78,1332.80 1141.63,1326.52 1145.00,1327.33 1146.81,1327.77 1150.47,1331.11 1152.00,1332.42 1152.00,1332.42 1166.00,1344.72 1166.00,1344.72 1166.00,1344.72 1204.00,1377.95 1204.00,1377.95 1209.74,1383.05 1217.63,1388.95 1222.00,1395.00 1222.00,1395.00 1201.00,1415.00 1201.00,1415.00 1201.00,1415.00 1160.87,1368.00 1160.87,1368.00 1160.87,1368.00 1134.00,1335.00 1134.00,1335.00 Z',
    attr: {
      id: '_110',
      'data-name': '120',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "120", zone: 'Lower Zone', zoneId: "1" },
  };
  var _121 = {
    path:
      'M 1184.00,1419.00 C 1170.14,1428.24 1162.37,1432.60 1147.00,1439.15 1144.32,1440.29 1135.95,1444.23 1133.41,1443.32 1131.46,1442.62 1130.51,1439.74 1129.69,1438.00 1129.69,1438.00 1123.72,1425.00 1123.72,1425.00 1123.72,1425.00 1102.58,1378.00 1102.58,1378.00 1101.03,1374.45 1095.23,1364.34 1095.92,1361.10 1096.60,1357.93 1102.35,1355.21 1105.00,1353.50 1107.89,1351.62 1116.94,1345.03 1119.96,1345.24 1122.54,1345.42 1124.53,1348.19 1126.08,1350.01 1126.08,1350.01 1137.13,1363.00 1137.13,1363.00 1137.13,1363.00 1156.59,1386.00 1156.59,1386.00 1156.59,1386.00 1184.00,1419.00 1184.00,1419.00 Z',
    attr: {
      id: '_121',
      'data-name': '121',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "121", zone: 'Lower Zone', zoneId: "1" },
  };
  var _122 = {
    path:
      'M 1080.00,1369.00 C 1084.83,1373.84 1091.34,1390.92 1094.42,1398.00 1094.42,1398.00 1118.00,1450.00 1118.00,1450.00 1108.18,1453.21 1081.31,1461.22 1072.00,1462.00 1072.00,1462.00 1063.02,1432.00 1063.02,1432.00 1063.02,1432.00 1055.98,1408.00 1055.98,1408.00 1055.98,1408.00 1048.00,1380.00 1048.00,1380.00 1056.34,1377.22 1073.60,1373.27 1080.00,1369.00 Z',
    attr: {
      id: '_122',
      'data-name': '122',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "122", zone: 'Lower Zone', zoneId: "1" },
  };
  var _123 = {
    path:
      'M 1000.00,1392.00 C 1008.96,1391.97 1013.55,1390.04 1022.00,1387.58 1024.48,1386.85 1029.23,1384.79 1031.57,1386.11 1033.45,1387.18 1034.12,1390.07 1034.71,1392.00 1034.71,1392.00 1039.14,1407.00 1039.14,1407.00 1039.14,1407.00 1057.00,1466.00 1057.00,1466.00 1057.00,1466.00 1032.00,1469.87 1032.00,1469.87 1032.00,1469.87 1009.00,1473.00 1009.00,1473.00 1009.00,1473.00 1004.84,1439.00 1004.84,1439.00 1004.84,1439.00 1000.00,1392.00 1000.00,1392.00 Z',
    attr: {
      id: '_123',
      'data-name': '123',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "123", zone: 'Lower Zone', zoneId: "1" },
  };
  var _124 = {
    path:
      'M 955.00,1392.00 C 955.00,1392.00 984.00,1392.00 984.00,1392.00 984.00,1392.00 988.72,1436.00 988.72,1436.00 988.72,1436.00 993.00,1474.00 993.00,1474.00 993.00,1474.00 980.00,1475.00 980.00,1475.00 980.00,1475.00 945.00,1475.00 945.00,1475.00 945.00,1475.00 955.00,1392.00 955.00,1392.00 Z',
    attr: {
      id: '_124',
      'data-name': '124',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "124", zone: 'Lower Zone', zoneId: "1" },
  };
  var _125 = {
    path:
      'M 939.00,1392.00 C 939.00,1392.00 932.16,1449.00 932.16,1449.00 932.16,1449.00 930.28,1466.00 930.28,1466.00 930.04,1467.82 929.96,1470.99 928.83,1472.43 927.06,1474.66 921.54,1474.02 919.00,1473.72 919.00,1473.72 892.00,1470.27 892.00,1470.27 889.32,1469.88 882.77,1469.72 881.78,1466.69 881.09,1464.56 884.86,1452.94 885.63,1450.00 885.63,1450.00 898.43,1404.00 898.43,1404.00 899.39,1400.61 902.52,1386.73 905.42,1385.68 907.44,1384.94 914.68,1387.60 917.00,1388.34 929.11,1392.23 926.14,1392.00 939.00,1392.00 Z',
    attr: {
      id: '_125',
      'data-name': '125',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "125", zone: 'Lower Zone', zoneId: "1" },
  };
  var _129 = {
    path:
      'M 700.00,1384.00 C 700.00,1384.00 682.98,1365.00 682.98,1365.00 681.00,1362.83 678.01,1360.28 679.17,1357.01 680.17,1354.18 692.05,1344.47 695.00,1341.84 695.00,1341.84 738.00,1303.58 738.00,1303.58 738.00,1303.58 750.00,1293.00 750.00,1293.00 751.72,1291.45 754.65,1288.54 756.99,1288.17 759.84,1287.71 761.58,1290.18 763.28,1292.02 763.28,1292.02 776.15,1306.00 776.15,1306.00 777.78,1307.90 780.37,1310.21 779.66,1313.00 779.03,1315.52 774.95,1318.73 773.00,1320.42 773.00,1320.42 758.00,1333.72 758.00,1333.72 758.00,1333.72 735.00,1353.95 735.00,1353.95 735.00,1353.95 700.00,1384.00 700.00,1384.00 Z',
    attr: {
      id: '_129',
      'data-name': '129',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "129", zone: 'Lower Zone', zoneId: "1" },
  };
  var _126 = {
    path:
      'M 856.00,1369.00 C 856.00,1369.00 889.00,1379.00 889.00,1379.00 889.00,1379.00 880.15,1411.00 880.15,1411.00 880.15,1411.00 865.00,1465.00 865.00,1465.00 851.49,1461.41 831.17,1456.24 819.00,1450.00 819.00,1450.00 829.14,1427.00 829.14,1427.00 829.14,1427.00 856.00,1369.00 856.00,1369.00 Z',
    attr: {
      id: '_126',
      'data-name': '126',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "126", zone: 'Lower Zone', zoneId: "1" },
  };
  var _127 = {
    path:
      'M 817.00,1346.00 C 823.97,1347.66 836.21,1356.09 843.00,1360.00 843.00,1360.00 836.31,1375.00 836.31,1375.00 836.31,1375.00 819.22,1412.00 819.22,1412.00 819.22,1412.00 805.00,1443.00 805.00,1443.00 793.75,1440.89 769.87,1424.99 760.00,1418.00 762.56,1411.45 773.00,1399.90 777.92,1394.00 777.92,1394.00 817.00,1346.00 817.00,1346.00 Z',
    attr: {
      id: '_127',
      'data-name': '127',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "127", zone: 'Lower Zone', zoneId: "1" },
  };
  var _128 = {
    path:
      'M 716.00,1390.00 C 716.00,1390.00 740.00,1369.16 740.00,1369.16 740.00,1369.16 771.00,1341.93 771.00,1341.93 771.00,1341.93 785.00,1329.43 785.00,1329.43 786.67,1328.02 789.74,1325.17 792.00,1325.19 795.52,1325.23 802.91,1335.07 805.00,1338.00 805.00,1338.00 786.58,1360.00 786.58,1360.00 786.58,1360.00 760.25,1392.00 760.25,1392.00 760.25,1392.00 740.00,1415.00 740.00,1415.00 740.00,1415.00 716.00,1390.00 716.00,1390.00 Z',
    attr: {
      id: '_128',
      'data-name': '128',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "128", zone: 'Lower Zone', zoneId: "1" },
  };
  var _130 = {
    path:
      'M 721.99,1250.54 C 722.76,1250.37 723.01,1249.78 724.83,1250.54 726.62,1251.69 738.95,1265.49 741.16,1268.00 742.91,1269.97 746.45,1272.98 745.66,1275.91 745.17,1277.73 742.38,1279.90 741.00,1281.16 741.00,1281.16 728.00,1292.84 728.00,1292.84 728.00,1292.84 686.00,1330.00 686.00,1330.00 683.10,1332.65 670.84,1344.11 668.00,1344.70 665.11,1345.30 663.04,1342.88 661.28,1340.98 661.28,1340.98 647.93,1326.00 647.93,1326.00 645.98,1323.79 643.06,1321.36 644.74,1318.17 645.46,1316.80 649.64,1313.24 651.00,1312.00 651.00,1312.00 666.00,1298.84 666.00,1298.84 666.00,1298.84 701.00,1267.84 701.00,1267.84 706.24,1263.16 716.32,1253.32 721.99,1250.54 Z',
    attr: {
      id: '_130',
      'data-name': '130',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "130", zone: 'Lower Zone', zoneId: "1" },
  };
  var _131 = {
    path:
      'M 687.00,1210.00 C 687.00,1210.00 705.17,1229.00 705.17,1229.00 705.17,1229.00 710.80,1237.00 710.80,1237.00 710.80,1237.00 706.00,1243.72 706.00,1243.72 706.00,1243.72 692.00,1256.05 692.00,1256.05 692.00,1256.05 658.00,1286.07 658.00,1286.07 658.00,1286.07 632.00,1308.00 632.00,1308.00 632.00,1308.00 614.58,1289.00 614.58,1289.00 614.58,1289.00 609.20,1281.04 609.20,1281.04 609.20,1281.04 614.00,1274.84 614.00,1274.84 614.00,1274.84 628.00,1262.28 628.00,1262.28 628.00,1262.28 668.00,1226.84 668.00,1226.84 668.00,1226.84 687.00,1210.00 687.00,1210.00 Z',
    attr: {
      id: '_131',
      'data-name': '131',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "131", zone: 'Lower Zone', zoneId: "1" },
  };

  var _132 = {
    path:
      'M 566.00,1233.00 C 566.00,1233.00 672.00,1160.79 672.00,1160.79 672.00,1160.79 698.00,1143.02 698.00,1143.02 700.31,1141.44 707.64,1136.04 710.00,1135.87 712.84,1135.67 714.72,1138.14 716.42,1140.02 720.98,1145.03 724.31,1148.19 728.00,1154.00 728.00,1154.00 693.00,1184.84 693.00,1184.84 693.00,1184.84 633.00,1238.08 633.00,1238.08 633.00,1238.08 608.00,1260.42 608.00,1260.42 605.77,1262.35 600.83,1267.63 598.00,1267.66 594.74,1267.71 590.43,1262.31 588.42,1260.00 582.86,1253.59 569.04,1239.55 566.00,1233.00 Z',
    attr: {
      id: '_132',
      'data-name': '132',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "132", zone: 'Lower Zone', zoneId: "1" },
  };

  var _133 = {
    path:
      'M 540.00,1182.00 C 540.00,1182.00 561.00,1171.26 561.00,1171.26 561.00,1171.26 600.00,1151.30 600.00,1151.30 600.00,1151.30 675.00,1113.17 675.00,1113.17 675.00,1113.17 708.00,1096.25 708.00,1096.25 711.34,1094.58 717.21,1090.51 720.82,1092.70 723.36,1094.25 728.10,1100.72 726.84,1103.82 725.85,1106.23 719.36,1110.11 717.00,1111.71 717.00,1111.71 692.00,1128.93 692.00,1128.93 692.00,1128.93 599.00,1192.34 599.00,1192.34 599.00,1192.34 558.00,1220.00 558.00,1220.00 553.67,1212.39 542.19,1189.76 540.00,1182.00 Z',
    attr: {
      id: '_133',
      'data-name': '133',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "133", zone: 'Lower Zone', zoneId: "1" },
  };

  var _134 = {
    path:
      'M 688.00,1033.00 C 688.00,1033.00 690.07,1045.00 690.07,1045.00 690.07,1045.00 710.00,1078.00 710.00,1078.00 710.00,1078.00 693.00,1086.74 693.00,1086.74 693.00,1086.74 655.00,1106.14 655.00,1106.14 655.00,1106.14 588.00,1140.30 588.00,1140.30 588.00,1140.30 551.00,1159.25 551.00,1159.25 551.00,1159.25 532.00,1168.00 532.00,1168.00 532.00,1168.00 511.00,1123.00 511.00,1123.00 511.00,1123.00 544.00,1106.36 544.00,1106.36 544.00,1106.36 607.00,1074.26 607.00,1074.26 607.00,1074.26 660.00,1047.35 660.00,1047.35 660.00,1047.35 688.00,1033.00 688.00,1033.00 Z',
    attr: {
      id: '_134',
      'data-name': '134',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "134", zone: 'Lower Zone', zoneId: "1" },
  };

  var _135 = {
    path:
      'M 689.00,1007.00 C 689.00,1007.00 688.01,1015.31 688.01,1015.31 688.01,1015.31 676.00,1022.24 676.00,1022.24 676.00,1022.24 648.00,1036.25 648.00,1036.25 648.00,1036.25 548.00,1087.25 548.00,1087.25 548.00,1087.25 520.00,1101.26 520.00,1101.26 520.00,1101.26 503.00,1109.00 503.00,1109.00 503.00,1109.00 474.00,1049.00 474.00,1049.00 474.00,1049.00 502.00,1043.58 502.00,1043.58 502.00,1043.58 538.00,1036.58 538.00,1036.58 538.00,1036.58 573.00,1029.61 573.00,1029.61 573.00,1029.61 639.00,1017.00 639.00,1017.00 639.00,1017.00 689.00,1007.00 689.00,1007.00 Z',
    attr: {
      id: '_135',
      'data-name': '135',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "135", zone: 'Lower Zone', zoneId: "1" },
  };

  var _136 = {
    path:
      'M 476.00,972.00 C 476.00,972.00 489.00,972.91 489.00,972.91 489.00,972.91 518.00,974.91 518.00,974.91 518.00,974.91 599.00,980.09 599.00,980.09 599.00,980.09 628.00,982.09 628.00,982.09 628.00,982.09 639.00,983.00 639.00,983.00 639.00,983.00 650.00,983.91 650.00,983.91 650.00,983.91 678.00,985.83 678.00,985.83 684.14,986.42 688.77,984.04 690.00,991.00 690.00,991.00 546.00,1018.80 546.00,1018.80 546.00,1018.80 503.00,1027.20 503.00,1027.20 503.00,1027.20 472.00,1033.00 472.00,1033.00 472.00,1033.00 476.00,972.00 476.00,972.00 Z',
    attr: {
      id: '_136',
      'data-name': '136',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "136", zone: 'Lower Zone', zoneId: "1" },
  };

  var _137 = {
    path:
      'M 480.00,929.00 C 480.00,929.00 548.00,933.91 548.00,933.91 548.00,933.91 611.00,938.91 611.00,938.91 611.00,938.91 642.00,941.17 642.00,941.17 642.00,941.17 668.00,943.17 668.00,943.17 668.00,943.17 692.00,945.00 692.00,945.00 692.00,945.00 690.00,971.00 690.00,971.00 690.00,971.00 661.00,968.91 661.00,968.91 661.00,968.91 617.00,965.91 617.00,965.91 617.00,965.91 478.00,956.00 478.00,956.00 478.00,956.00 480.00,929.00 480.00,929.00 Z',
    attr: {
      id: '_137',
      'data-name': '137',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "137", zone: 'Lower Zone', zoneId: "1" },
  };

  var _138 = {
    path:
      'M 483.00,885.00 C 483.00,885.00 495.00,885.91 495.00,885.91 495.00,885.91 532.00,888.83 532.00,888.83 532.00,888.83 609.00,894.83 609.00,894.83 609.00,894.83 646.00,897.58 646.00,897.58 646.00,897.58 658.00,899.17 658.00,899.17 658.00,899.17 669.04,910.00 669.04,910.00 669.04,910.00 687.00,929.00 687.00,929.00 687.00,929.00 665.00,927.17 665.00,927.17 665.00,927.17 645.00,925.91 645.00,925.91 645.00,925.91 626.00,924.09 626.00,924.09 626.00,924.09 552.00,918.17 552.00,918.17 552.00,918.17 531.00,916.83 531.00,916.83 531.00,916.83 494.00,913.91 494.00,913.91 494.00,913.91 481.00,913.00 481.00,913.00 481.00,913.00 483.00,885.00 483.00,885.00 Z',
    attr: {
      id: '_138',
      'data-name': '138',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "138", zone: 'Lower Zone', zoneId: "1" },
  };

  var _139 = {
    path:
      'M 486.00,843.00 C 486.00,843.00 520.00,846.09 520.00,846.09 520.00,846.09 575.00,850.91 575.00,850.91 575.00,850.91 599.00,852.91 599.00,852.91 599.00,852.91 612.00,854.51 612.00,854.51 612.00,854.51 625.00,865.00 625.00,865.00 625.00,865.00 640.00,881.00 640.00,881.00 640.00,881.00 614.00,879.17 614.00,879.17 614.00,879.17 575.00,876.09 575.00,876.09 575.00,876.09 523.00,872.09 523.00,872.09 523.00,872.09 485.00,869.00 485.00,869.00 485.00,869.00 486.00,843.00 486.00,843.00 Z',
    attr: {
      id: '_139',
      'data-name': '139',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "139", zone: 'Lower Zone', zoneId: "1" },
  };

  var _140 = {
    path:
      'M 488.00,828.00 C 488.00,828.00 488.91,813.00 488.91,813.00 488.91,813.00 490.02,805.60 490.02,805.60 490.02,805.60 496.00,804.17 496.00,804.17 496.00,804.17 510.00,805.00 510.00,805.00 510.00,805.00 525.00,806.00 525.00,806.00 525.00,806.00 545.00,807.00 545.00,807.00 545.00,807.00 558.00,808.00 558.00,808.00 558.00,808.00 567.00,808.60 567.00,808.60 567.00,808.60 577.00,817.00 577.00,817.00 577.00,817.00 596.00,837.00 596.00,837.00 596.00,837.00 536.00,831.91 536.00,831.91 536.00,831.91 488.00,828.00 488.00,828.00 Z',
    attr: {
      id: '_140',
      'data-name': '140',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "140", zone: 'Lower Zone', zoneId: "1" },
  };

  var _141 = {
    path:
      'M 504.00,767.00 C 512.19,767.00 524.49,766.46 531.83,770.42 534.41,771.81 537.81,775.79 540.00,778.00 544.49,782.53 549.28,786.80 553.00,792.00 553.00,792.00 504.00,790.00 504.00,790.00 504.00,790.00 504.00,767.00 504.00,767.00 Z',
    attr: {
      id: '_141',
      'data-name': '141',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "141", zone: 'Lower Zone', zoneId: "1" },
  };

  var _142 = {
    path:
      'M 539.00,671.00 C 539.00,671.00 527.09,638.01 527.09,638.01 523.83,631.49 518.48,631.16 518.29,626.96 518.17,624.17 523.63,616.55 525.33,614.00 527.29,611.06 533.63,600.33 537.09,600.33 539.61,600.34 544.93,605.14 547.00,606.80 547.00,606.80 569.00,624.00 569.00,624.00 569.00,624.00 556.42,645.00 556.42,645.00 556.42,645.00 539.00,671.00 539.00,671.00 Z',
    attr: {
      id: '_142',
      'data-name': '142',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "142", zone: 'Lower Zone', zoneId: "1" },
  };

  var _143 = {
    path:
      'M 545.00,589.00 C 545.00,589.00 554.59,575.00 554.59,575.00 554.59,575.00 572.04,555.00 572.04,555.00 572.04,555.00 584.00,544.00 584.00,544.00 584.00,544.00 603.91,566.00 603.91,566.00 605.84,568.07 610.74,573.14 610.67,576.00 610.59,579.20 604.39,584.55 602.17,587.00 602.17,587.00 578.00,614.00 578.00,614.00 578.00,614.00 545.00,589.00 545.00,589.00 Z',
    attr: {
      id: '_143',
      'data-name': '143',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "143", zone: 'Lower Zone', zoneId: "1" },
  };

  var _144A = {
    path:
      'M 650.00,557.00 C 650.00,557.00 664.00,546.00 664.00,546.00 664.00,546.00 678.37,565.00 678.37,565.00 680.07,567.26 683.53,570.98 682.68,573.99 681.80,577.10 675.68,581.21 673.00,583.00 673.00,583.00 650.00,557.00 650.00,557.00 Z',
    attr: {
      id: '_144A',
      'data-name': '144A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "144", zone: 'Lower Zone A', zoneId: "1" },
  };

  var _144B = {
    path:
      'M 593.00,534.00 C 600.65,526.35 621.05,506.03 630.00,502.00 630.00,502.00 646.65,524.00 646.65,524.00 648.38,526.24 652.93,531.34 653.13,534.00 653.39,537.56 648.37,540.87 646.00,543.09 637.43,551.09 630.84,559.05 621.00,566.00 621.00,566.00 593.00,534.00 593.00,534.00 Z',
    attr: {
      id: '_144B',
      'data-name': '144B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "144", zone: 'Lower Zone B', zoneId: "1" },
  };

  var _145A = {
    path:
      'M 694.00,564.00 C 694.00,564.00 679.35,545.00 679.35,545.00 677.88,543.08 674.77,539.77 675.45,537.18 676.13,534.61 682.67,531.15 685.00,529.68 685.00,529.68 710.00,514.05 710.00,514.05 712.30,512.61 718.22,508.37 720.79,508.87 723.30,509.36 725.55,513.93 726.90,516.00 733.31,526.82 734.77,527.17 726.90,535.00 726.90,535.00 712.00,548.91 712.00,548.91 706.56,553.97 700.12,559.90 694.00,564.00 Z',
    attr: {
      id: '_145A',
      'data-name': '145A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "145", zone: 'Lower Zone A', zoneId: "1" },
  };

  var _145B = {
    path:
      'M 714.00,496.00 C 714.00,496.00 680.00,517.42 680.00,517.42 677.29,519.11 668.81,525.13 666.04,524.90 663.31,524.66 660.80,520.99 659.21,519.00 659.21,519.00 645.35,501.00 645.35,501.00 643.54,498.62 640.20,495.39 641.82,492.17 643.08,489.69 652.28,483.52 655.00,481.58 663.46,475.53 683.09,461.29 692.00,458.00 692.00,458.00 714.00,496.00 714.00,496.00 ',
    attr: {
      id: '_145B',
      'data-name': '145B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "145", zone: 'Lower Zone B', zoneId: "1" },
  };

  var _146A = {
    path:
      'M 734.00,500.00 C 734.00,500.00 764.00,485.75 764.00,485.75 764.00,485.75 782.00,478.00 782.00,478.00 782.00,478.00 787.00,492.00 787.00,492.00 787.00,492.00 763.00,501.94 763.00,501.94 763.00,501.94 743.00,518.00 743.00,518.00 743.00,518.00 734.00,500.00 734.00,500.00 Z',
    attr: {
      id: '_146A',
      'data-name': '146A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "146", zone: 'Lower Zone A', zoneId: "1" },
  };

  var _146B = {
    path:
      'M 776.00,465.00 C 776.00,465.00 741.00,482.25 741.00,482.25 738.23,483.63 729.78,488.46 727.10,488.01 724.22,487.53 722.19,483.30 720.80,481.00 720.80,481.00 708.05,460.00 708.05,460.00 706.55,457.60 703.14,453.24 704.82,450.39 706.16,448.12 716.20,443.16 719.00,441.75 728.15,437.17 750.42,425.45 759.00,423.00 759.00,423.00 776.00,465.00 776.00,465.00 Z',
    attr: {
      id: '_146B',
      'data-name': '146B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "146", zone: 'Lower Zone B', zoneId: "1" },
  };

  var _147A = {
    path:
      'M 794.00,470.00 C 794.00,470.00 805.00,466.34 805.00,466.34 805.00,466.34 842.00,454.00 842.00,454.00 842.00,454.00 846.00,470.00 846.00,470.00 846.00,470.00 800.00,487.00 800.00,487.00 800.00,487.00 794.00,470.00 794.00,470.00 Z',
    attr: {
      id: '_147A',
      'data-name': '147A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "147", zone: 'Lower Zone A', zoneId: "1" },
  };

  var _147B = {
    path:
      'M 771.00,418.00 C 780.57,413.04 818.02,400.50 829.00,398.00 829.00,398.00 839.00,440.00 839.00,440.00 839.00,440.00 819.00,447.42 819.00,447.42 819.00,447.42 788.00,458.00 788.00,458.00 788.00,458.00 771.00,418.00 771.00,418.00 Z',
    attr: {
      id: '_147B',
      'data-name': '147B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "147", zone: 'Lower Zone B', zoneId: "1" },
  };

  var _148A = {
    path:
      'M 855.00,450.00 C 855.00,450.00 888.94,445.00 888.94,445.00 888.94,445.00 888.94,451.59 888.94,451.59 888.94,451.59 880.00,456.32 880.00,456.32 880.00,456.32 858.00,465.00 858.00,465.00 858.00,465.00 855.00,450.00 855.00,450.00 Z',
    attr: {
      id: '_148A',
      'data-name': '148A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "148", zone: 'Lower Zone A', zoneId: "1" },
  };

  var _148B = {
    path:
      'M 845.00,404.00 C 848.86,403.68 858.31,401.38 861.00,402.02 864.45,402.85 875.70,412.27 878.98,415.00 882.37,417.81 888.46,425.67 884.96,430.41 883.59,432.27 878.26,432.71 876.00,433.08 876.00,433.08 852.00,437.00 852.00,437.00 852.00,437.00 845.00,404.00 845.00,404.00 Z',
    attr: {
      id: '_148B',
      'data-name': '148B',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "148", zone: 'Lower Zone B', zoneId: "1" },
  };

  var _W111 = {
    path:
      'M 1274.00,907.00 C 1274.00,907.00 1276.00,922.00 1276.00,922.00 1276.00,922.00 1278.00,965.00 1278.00,965.00 1278.00,965.00 1280.00,995.00 1280.00,995.00 1280.00,995.00 1257.00,990.40 1257.00,990.40 1254.65,989.93 1249.49,989.34 1248.02,987.42 1246.76,985.77 1247.00,982.04 1247.00,980.00 1247.00,980.00 1248.00,961.00 1248.00,961.00 1248.00,961.00 1248.00,941.00 1248.00,941.00 1248.06,932.30 1250.17,932.31 1255.72,926.00 1255.72,926.00 1274.00,907.00 1274.00,907.00 Z',
    attr: {
      id: '_W111',
      'data-name': 'W111',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "111", zone: 'Lower Zone W', zoneId: "1" },
  };

  var _LEFTFIELDBALCONY = {
    path:
      'M 513.00,642.00 C 520.74,647.69 521.90,655.36 525.19,664.00 527.31,669.56 534.03,684.90 533.40,690.00 533.40,690.00 528.42,708.00 528.42,708.00 528.42,708.00 514.00,760.00 514.00,760.00 514.00,760.00 479.00,757.00 479.00,757.00 479.00,757.00 488.28,725.00 488.28,725.00 488.28,725.00 506.42,665.00 506.42,665.00 506.42,665.00 513.00,642.00 513.00,642.00 Z',
    attr: {
      id: '_LEFTFIELDBALCONY',
      'data-name': 'LEFTFIELDBALCONY',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'LEFT FIELD BALCONY', zoneId: "1" },
  };


  var _RIGHTFIELDBLEACHERS = {
    path:
      'M 1235.00,452.00 C 1243.56,454.70 1269.29,471.86 1278.00,477.67 1281.38,479.92 1291.12,485.54 1292.23,489.17 1292.86,491.22 1291.44,501.29 1291.17,504.00 1291.17,504.00 1288.00,537.00 1288.00,537.00 1288.00,537.00 1271.00,526.42 1271.00,526.42 1271.00,526.42 1213.00,490.00 1213.00,490.00 1213.00,490.00 1235.00,452.00 1235.00,452.00 Z',
    attr: {
      id: '_RIGHTFIELDBLEACHERS',
      'data-name': 'RIGHTFIELDBLEACHERS',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'RIGHT FIELD BLEACHERS', zoneId: "1" },
  };

  var _ROGERSLANDING = {
    path:
      'M 1331.00,647.00 C 1336.78,640.88 1350.31,626.59 1357.00,623.00 1381.10,657.04 1404.75,692.49 1421.15,731.00 1425.14,740.36 1428.64,750.13 1431.11,760.00 1431.68,762.29 1433.41,768.30 1431.97,770.26 1430.71,771.97 1426.97,772.10 1425.00,772.43 1425.00,772.43 1405.00,776.00 1405.00,776.00 1406.02,774.13 1407.33,772.21 1407.31,770.00 1407.29,767.53 1405.09,764.14 1403.85,762.00 1403.85,762.00 1391.60,743.00 1391.60,743.00 1391.60,743.00 1351.42,678.00 1351.42,678.00 1346.56,670.14 1337.23,652.26 1331.00,647.00 Z',
    attr: {
      id: '_ROGERSLANDING',
      'data-name': 'ROGERSLANDING',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'ROGERS LANDING', zoneId: "1" },
  };

  var _THECATCHBAR = {
    path:
      'M 1305.80,503.00 C 1311.94,505.68 1320.72,513.52 1326.00,517.92 1326.00,517.92 1360.00,548.00 1360.00,548.00 1363.25,551.24 1375.04,562.17 1375.75,566.00 1376.48,569.90 1370.76,574.85 1368.00,577.00 1368.00,577.00 1324.00,539.75 1324.00,539.75 1324.00,539.75 1305.80,522.83 1305.80,522.83 1305.80,522.83 1305.80,503.00 1305.80,503.00 Z',
    attr: {
      id: '_THECATCHBAR',
      'data-name': 'THECATCHBAR',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'THE CATCH BAR', zoneId: "1" },
  };

  var _THESTOP = {
    path:
      'M 875.00,394.00 C 875.00,394.00 898.00,390.28 898.00,390.28 898.00,390.28 924.00,387.91 924.00,387.91 924.00,387.91 936.00,387.00 936.00,387.00 936.00,387.00 984.00,387.00 984.00,387.00 984.00,387.00 1012.00,388.91 1012.00,388.91 1012.00,388.91 1049.00,392.58 1049.00,392.58 1054.06,393.28 1059.62,393.15 1064.00,396.00 1064.00,396.00 1057.00,400.28 1057.00,400.28 1057.00,400.28 1044.00,401.00 1044.00,401.00 1044.00,401.00 1020.00,401.00 1020.00,401.00 1020.00,401.00 919.00,401.00 919.00,401.00 919.00,401.00 895.00,401.00 895.00,401.00 895.00,401.00 883.00,400.44 883.00,400.44 883.00,400.44 875.00,394.00 875.00,394.00 ',
    attr: {
      id: '_THESTOP',
      'data-name': 'THESTOP',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'THE STOP', zoneId: "1" },
  };

  var _HOMERUNZONE = {
    path:
      'M 699.00,410.00 C 699.00,410.00 712.00,410.91 712.00,410.91 712.00,410.91 727.00,411.72 727.00,411.72 727.00,411.72 737.00,413.00 737.00,413.00 737.00,413.00 706.00,425.00 706.00,425.00 706.00,425.00 699.00,410.00 699.00,410.00 Z',
    attr: {
      id: '_HOMERUNZONE',
      'data-name': 'HOMERUNZONE',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'HOME RUN ZONE', zoneId: "2" },
  };

  var _SCHNEIDERSPORCH = {
    path:
      'M 1445.00,633.00 C 1456.00,627.29 1492.26,613.81 1504.00,612.00 1504.00,612.00 1516.19,643.00 1516.19,643.00 1516.19,643.00 1530.98,685.00 1530.98,685.00 1530.98,685.00 1543.39,733.00 1543.39,733.00 1543.39,733.00 1547.00,756.00 1547.00,756.00 1547.00,756.00 1487.00,769.00 1487.00,769.00 1487.00,769.00 1479.15,738.00 1479.15,738.00 1469.10,700.89 1459.32,668.81 1445.00,633.00 Z',
    attr: {
      id: '_SCHNEIDERSPORCH',
      'data-name': 'SCHNEIDERSPORCH',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'SCHNEIDERS PORCH', zoneId: "2" },
  };

  var _CORONAROOFTOPPATIO = {
    path:
      'M 1340.00,253.00 C 1346.57,255.43 1361.47,264.86 1368.00,268.95 1368.00,268.95 1412.00,297.58 1412.00,297.58 1467.72,337.00 1519.46,380.40 1559.85,436.00 1573.42,454.68 1586.33,475.74 1595.42,497.00 1595.42,497.00 1601.33,513.00 1601.33,513.00 1601.90,514.77 1603.01,517.78 1602.33,519.59 1601.55,521.67 1597.92,522.88 1596.00,523.72 1596.00,523.72 1579.00,530.86 1579.00,530.86 1576.09,532.14 1563.23,538.38 1561.13,538.30 1558.27,538.19 1557.34,535.22 1556.40,533.00 1556.40,533.00 1548.86,516.00 1548.86,516.00 1539.40,494.83 1523.53,471.26 1509.21,453.00 1473.71,407.73 1426.13,365.27 1379.00,332.28 1363.74,321.60 1333.32,301.03 1317.00,294.00 1317.00,294.00 1340.00,253.00 1340.00,253.00 Z',
    attr: {
      id: '_CORONAROOFTOPPATIO',
      'data-name': 'CORONAROOFTOPPATIO',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'CORONA ROOFTOP PATIO', zoneId: "5" },
  };

  var _TDPARKSOCIAL = {
    path:
      'M 377.00,548.00 C 377.00,548.00 341.00,532.70 341.00,532.70 338.06,531.37 330.69,528.86 329.70,525.79 329.01,523.64 330.34,520.96 331.15,519.00 331.15,519.00 338.42,503.00 338.42,503.00 347.34,482.71 366.44,454.05 379.73,436.00 418.62,383.21 473.72,333.67 527.00,295.58 543.21,283.99 584.06,256.34 601.00,249.00 601.00,249.00 613.31,274.00 613.31,274.00 613.31,274.00 621.00,293.00 621.00,293.00 603.91,302.56 571.31,325.89 555.00,338.13 504.73,375.83 452.76,424.14 417.02,476.00 404.06,494.81 395.39,509.57 385.25,530.00 385.25,530.00 377.00,548.00 377.00,548.00 Z',
    attr: {
      id: '_TDPARKSOCIAL',
      'data-name': 'TDPARKSOCIAL',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'TD PARK SOCIAL', zoneId: "5" },
  };

  zones.push(
    _204,
    _205,
    _206,
    _207,
    _210,
    _211,
    _212,
    _213,
    _214,
    _215,
    _216,
    _217,
    _218,
    _219,
    _220,
    _221,
    _222,
    _223,
    _224,
    _225,
    _226,
    _227,
    _228,
    _229,
    _230,
    _231,
    _232,
    _233,
    _234,
    _235,
    _236,
    _237,
    _238,
    _239,
    _240,
    _241,
    _242,
    _243,
    _244,
    _508,
    _509,
    _510,
    _511,
    _512,
    _513,
    _514,
    _515,
    _516,
    _517,
    _518,
    _519,
    _520,
    _521,
    _522,
    _523,
    _524,
    _525,
    _526,
    _527,
    _528,
    _529,
    _530,
    _531,
    _532,
    _533,
    _534,
    _535,
    _536,
    _537,
    _538,
    _539,
    _540,
    _121,
    _120,
    _TMLOUNGE,
    _W207,
    _W240,
    _WESTJETFLIGHTDECK,
    _1,
    _2,
    _3,
    _4,
    _5,
    _16,
    _17,
    _18,
    _19,
    _20,
    _21,
    _22,
    _23,
    _24,
    _25,
    _26,
    _27,
    _28,
    _29,
    _30,
    _31,
    _32,
    _101A,
    _101B,
    _102A,
    _102B,
    _103A,
    _103B,
    // _105,
    _108,
    _109,
    _110,
    _111,
    _112,
    _113,
    _114,
    _115,
    _116,
    _117,
    _118,
    _119,
    _120,
    _121,
    _122,
    _123,
    _124,
    _125,
    _126,
    _127,
    _128,
    _129,
    _130,
    _131,
    _132,
    _133,
    _134,
    _135,
    _136,
    _137,
    _138,
    _139,
    _140,
    _141,
    _142,
    _143,
    _144A,
    _144B,
    _145A,
    _145B,
    _146A,
    _146B,
    _147A,
    _147B,
    _148A,
    _148B,
    _CORONAROOFTOPPATIO,
    _HOMERUNZONE,
    _LEFTFIELDBALCONY,
    _RIGHTFIELDBLEACHERS,
    _ROGERSLANDING,
    _SCHNEIDERSPORCH,
    _TDPARKSOCIAL,
    _THECATCHBAR,
    _THESTOP,
    _W111
  );

  return zones;
};