import React from "react";

import ChatIcon from "../images/icons/ic-chat.png";
import FastPayIcon from "../images/icons/ic-fastpay.png";
import MapleLeafIcon from "../images/icons/ic-maple-leaf.png";
import NoFeeIcon from "../images/icons/ic-nofee.png";
import SecureIcon from "../images/icons/ic-secure-2.png";
import TransferIcon from "../images/icons/ic-transfer.png";

export const WHY_US_CONTENTS = [
  {
    icon: MapleLeafIcon,
    title: () => {
      return (
        <span>
          We're Canadians. <br />
          Made by Fans, For Fans
        </span>
      );
    },

    content:
      "We want tickets to be more affordable for real fans and want ticket holders to sell their tickets quickly and easily; without expensive middlemen.",
  },
  {
    icon: NoFeeIcon,
    title: () => {
      return (
        <span>
          No Buyer Fees and <br />
          We Mean It!
        </span>
      );
    },

    content:
      "FansFirst DOES NOT charge buyers service fees of any kind! The price you see is the price you pay at checkout. Buyers elsewhere pay fees as high as 50%!",
  },
  {
    icon: SecureIcon,
    title: () => {
      return (
        <span>
          Safe, Easy, and Secured;
          <br />
          Use your credit card!
        </span>
      );
    },

    content:
      "Buying tickets off cash-only sites runs the risk of buying stolen tickets. At FansFirst we ONLY accept credit cards. Moneyback guarantee if anything goes wrong AND we have real people available to help ASAP.",
  },
  {
    icon: TransferIcon,
    title: () => {
      return (
        <span>
          Mobile Transfer;
          <br /> No PDFs
        </span>
      );
    },

    content:
      "Mobile Transfers guarantee you'll get the original ticket from the Team's ticketing system: plain and simple. All you need is your email.",
  },
  {
    icon: ChatIcon,
    title: () => {
      return (
        <span>
          Real People,
          <br /> Available Anytime
        </span>
      );
    },

    content:
      "Connect to us anytime through the online chat, email, text or by phone. Speak to a real human and get your questions answered quickly.",
  },
  {
    icon: FastPayIcon,
    title: () => {
      return <span style={{ lineHeight: "3.5rem" }}>Fast Payouts</span>;
    },

    content:
      "Sellers are paid out by the end of the next business day after the event, weeks and weeks faster than our competition.",
  },
];
