const singleRow = [
    {
      value: 1,
      label: 'Row 1',
    },
  ];
  
  const defaultRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 32; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  }; 
  
  const lowerZoneRows = () => {
    const arr = [];
    const alphabet = ["A","B","C","D","E","F","G","H"];
  
    for (let i = 1; i <= 32; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }

    alphabet.map(item => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    })
  
    return arr;
  };

  const courtsideRows = () => {
    const arr = [];
  
    for (let i = 65; i <= 67; i++) {
      const character = String.fromCharCode(i)
      arr.push({
        value: character,
        label: `Row ${character}`,
      });
    }
  
    return arr;
  };
  
  export default {
    SINGLE_ROW: singleRow,
    COURTSIDE_ROWS: courtsideRows(),
    LOWER_ZONE_ROWS: lowerZoneRows(),
    DEFAULT_ROWS: defaultRows(),
  };
  