import _ from 'lodash';

const defaultRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 30; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };
  
  const lowerZoneRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 28; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  const pinnacleClubRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 28; i++) {
      arr.push({
        value: i,
        label: `Row ${i} ${_.range(9, 29).includes(i) ? 'Pinnacle Club' : ''}`,
      });
    }
  
    return arr;
  };

  const upperZoneRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 27; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };
  
  const jimBeamSocialPasses = () => [
    {
        value: 'Jim Beam Social',
        label: `Jim Beam Social Passes`,
      }
  ];

  export default {
    JIM_BEAM_SOCIAL: jimBeamSocialPasses(),
    LOWER_ZONE_ROWS: lowerZoneRows(),
    UPPER_ZONE_ROWS: upperZoneRows(),
    PINNACLE_CLUB_ROWS: pinnacleClubRows(),
    DEFAULT_ROWS: defaultRows(),
  };
  