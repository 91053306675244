import React, { Component } from "react";
import { Auth } from "aws-amplify";
import Helmet from "react-helmet";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles, FormHelperText } from "@material-ui/core";
import validator from "validator";
import cdialog from "../components/cdialog";

const styles = (theme) => ({
  [theme.breakpoints.up("md")]: {
    formContainer: {
      // margin: "5rem 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    formContainer: {
      // marginTop: "3rem",
      padding: "0 1rem",
    },
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  button: {
    padding: ".875rem",
    marginTop: ".5rem",
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isValidEmail: true,
      isValidPassword: true,
    };

    this.isValidEmail = this.isValidEmail.bind(this);
    this.isValidPassword = this.isValidPassword.bind(this);
  }

  componentWillMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  
  handleChangeEmail = (event) => {
    this.setState({
      [event.target.id]: String(event.target.value).toLowerCase(),
    });
  };

  isValidEmail(email) {
    const isValidEmail = validator.isEmail(email);
    this.setState({ isValidEmail });

    return isValidEmail;
  }

  isValidPassword(password) {
    const isValidPassword = !validator.isEmpty(password);
    this.setState({ isValidPassword });

    return isValidPassword;
  }

  isValidLogin(email, pw) {
    const isValidEmail = this.isValidEmail(email);
    const isValidPassword = this.isValidPassword(pw);
    return isValidEmail && isValidPassword;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    try {
      if (this.isValidLogin(email, password)) {
        this.setState({ isSubmitting: true });

        const user = await Auth.signIn(email.toLowerCase().trim(), password);

        switch (user.challengeName) {
          case "NEW_PASSWORD_REQUIRED": {
            this.props.setUserNeedNewPassword(user);
            this.props.history.push("/new-password");
            break;
          }
          default: {
            const currUser = await Auth.currentAuthenticatedUser();

            this.props.userHasAuthenticated(true);
            this.props.setUserAttributes(currUser);

            this.setState({ isSubmitting: false });

            this.props.history.push(`/dashboard`);
          }
        }
      }
    } catch (e) {
      switch (e.code) {
        case "NotAuthorizedException": {
          const msg = e.message.toLowerCase().includes("attempts exceeded")
            ? <p>Login attempts exceeded. Please try again later.</p> 
            : <p>The email or password you entered is incorrect. Please try again or contact support by text at <a href='tel:1-403-768-2298' style={{ color: "#2196f3", textDecoration: "none" }}>1-403-768-2298</a> or email at <a href='mailto:tix@fansfirst.ca' style={{ color: "#2196f3", textDecoration: "none" }}>tix@fansfirst.ca</a> for assistance.</p>
          cdialog.error("Login failed!", msg);
          break;
        }

        default: {
          cdialog.error("Login failed!", <p>The email or password you entered is incorrect. Please try again or contact support by text at <a href='tel:1-403-768-2298' style={{ color: "#2196f3", textDecoration: "none" }}>1-403-768-2298</a> or email at <a href='mailto:tix@fansfirst.ca' style={{ color: "#2196f3", textDecoration: "none" }}>tix@fansfirst.ca</a> for assistance.</p>);
        }
      }
      this.setState({ isSubmitting: false });
    }
  };

  renderForm() {
    const { classes } = this.props;
    const { isSubmitting } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Typography
          style={{
            marginBottom: "1.5rem",
            marginTop: "9rem",
          }}
          variant="title"
          color="textPrimary"
        >
          Login
        </Typography>

        <TextField
          autoFocus={true}
          className={
            this.state.isValidEmail ? classes.textField : classes.textFieldError
          }
          disabled={isSubmitting}
          error={true}
          id="email"
          placeholder="Email Address"
          name="email"
          type="email"
          fullWidth={true}
          value={this.state.email}
          onChange={this.handleChangeEmail}
          margin="dense"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {!this.state.isValidEmail && (
          <FormHelperText error={true}>
            Please enter your email address
          </FormHelperText>
        )}

        <TextField
          className={
            this.state.isValidPassword
              ? classes.textField
              : classes.textFieldError
          }
          disabled={isSubmitting}
          error={true}
          id="password"
          placeholder="Password"
          name="password"
          type="password"
          fullWidth={true}
          value={this.state.password}
          onChange={this.handleChange}
          margin="dense"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {!this.state.isValidPassword && (
          <FormHelperText error={true}>
            Please enter your password
          </FormHelperText>
        )}

        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting}
          variant="contained"
          fullWidth={true}
          onClick={this.handleSubmit}
          type="submit"
        >
          Login
        </Button>
        <Button
          className={classes.button}
          color="secondary"
          disabled={isSubmitting}
          variant="text"
          fullWidth={true}
          href="/forgot-password"
          type="submit"
        >
          Forgot Password
        </Button>
        <Typography variant="caption" color="textSecondary" align="center">
          Don't have an account{" "}
          <a
            style={{ color: "#2196f3", textDecoration: "none" }}
            href="/signup"
          >
            Sign up here!
          </a>
        </Typography>
      </form>
    );
  }

  render() {
    const { classes } = this.props;
    const currentURL = window.location.href;
    return (
      <div>
        <Helmet>
          <title>Login | fansfirst.ca</title>
          <meta
            name="description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta
            name="keywords"
            content={`login, sell tickets, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`}
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Login | fansfirst.ca" />
          <meta
            property="og:description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Typography variant="headline" style={{display: 'none'}}>
          Login
        </Typography>
        <Grid
          className={classes.formContainer}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            {this.renderForm()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
