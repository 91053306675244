export const getZones = (rsr) => {
  let zones = [];
  var _104 = {
    path:
      "M 1505.00,1280.00 C 1505.00,1280.00 1537.00,1274.20 1537.00,1274.20 1540.27,1273.86 1546.64,1272.22 1549.49,1274.20 1552.16,1275.66 1559.13,1286.75 1561.42,1290.00 1564.45,1294.28 1574.59,1308.21 1576.20,1312.00 1576.20,1312.00 1583.42,1343.00 1583.42,1343.00 1583.42,1343.00 1612.42,1474.00 1612.42,1474.00 1612.42,1474.00 1621.65,1516.00 1621.65,1516.00 1623.28,1523.23 1626.68,1536.16 1627.00,1543.00 1627.00,1543.00 1558.00,1552.27 1558.00,1552.27 1558.00,1552.27 1537.00,1555.00 1537.00,1555.00 1537.00,1555.00 1532.17,1513.00 1532.17,1513.00 1532.17,1513.00 1522.72,1429.00 1522.72,1429.00 1522.72,1429.00 1519.17,1401.00 1519.17,1401.00 1519.17,1401.00 1510.17,1323.00 1510.17,1323.00 1510.17,1323.00 1505.00,1280.00 1505.00,1280.00 Z",
    attr: {
      id: "_104",
      "data-name": "104",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "104", zone: "Lower Zone", zoneId: "2" },
  };

  var _105 = {
    path:
      "M 1370.00,1347.00 C 1370.00,1347.00 1496.00,1347.00 1496.00,1347.00 1496.00,1347.00 1510.28,1471.00 1510.28,1471.00 1510.28,1471.00 1517.16,1530.00 1517.16,1530.00 1517.16,1530.00 1520.00,1557.00 1520.00,1557.00 1520.00,1557.00 1370.00,1557.00 1370.00,1557.00 1370.00,1557.00 1370.00,1347.00 1370.00,1347.00 Z",
    attr: {
      id: "_105",
      "data-name": "105",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "105", zone: "Lower Zone", zoneId: "2" },
  };

  var _105A = {
    path:
      "M 1488.00,1282.00 C 1488.00,1282.00 1490.28,1298.00 1490.28,1298.00 1490.28,1298.00 1494.00,1330.00 1494.00,1330.00 1494.00,1330.00 1370.00,1330.00 1370.00,1330.00 1370.00,1330.00 1370.00,1286.00 1370.00,1286.00 1370.00,1286.00 1439.00,1286.00 1439.00,1286.00 1455.12,1286.00 1472.40,1286.77 1488.00,1282.00 Z",
    attr: {
      id: "_105A",
      "data-name": "105A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "105", zone: "Lower Zone A", zoneId: "2" },
  };

  var _106 = {
    path:
      "M 1217.00,1347.00 C 1217.00,1347.00 1353.00,1347.00 1353.00,1347.00 1353.00,1347.00 1353.00,1557.00 1353.00,1557.00 1353.00,1557.00 1300.00,1557.00 1300.00,1557.00 1300.00,1557.00 1283.00,1556.00 1283.00,1556.00 1283.00,1556.00 1217.00,1556.00 1217.00,1556.00 1217.00,1556.00 1217.00,1347.00 1217.00,1347.00 Z",
    attr: {
      id: "_106",
      "data-name": "106",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "106", zone: "Lower Zone", zoneId: "2" },
  };

  var _106A = {
    path:
      "M 1217.00,1285.00 C 1217.00,1285.00 1300.00,1285.00 1300.00,1285.00 1300.00,1285.00 1315.00,1286.00 1315.00,1286.00 1315.00,1286.00 1353.00,1286.00 1353.00,1286.00 1353.00,1286.00 1353.00,1330.00 1353.00,1330.00 1353.00,1330.00 1217.00,1330.00 1217.00,1330.00 1217.00,1330.00 1217.00,1285.00 1217.00,1285.00 Z",
    attr: {
      id: "_106A",
      "data-name": "106A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "106", zone: "Lower Zone A", zoneId: "2" },
  };

  var _107 = {
    path:
      "M 1055.00,1284.00 C 1055.00,1284.00 1142.00,1284.00 1142.00,1284.00 1142.00,1284.00 1157.00,1285.00 1157.00,1285.00 1157.00,1285.00 1200.00,1285.00 1200.00,1285.00 1200.00,1285.00 1200.00,1556.00 1200.00,1556.00 1200.00,1556.00 1069.00,1556.00 1069.00,1556.00 1069.00,1556.00 1055.00,1555.00 1055.00,1555.00 1055.00,1555.00 1055.00,1284.00 1055.00,1284.00 Z",
    attr: {
      id: "_107",
      "data-name": "107",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "107", zone: "Lower Zone", zoneId: "2" },
  };

  var _108 = {
    path:
      "M 886.00,1283.00 C 886.00,1283.00 980.00,1283.00 980.00,1283.00 980.00,1283.00 994.00,1284.00 994.00,1284.00 994.00,1284.00 1038.00,1284.00 1038.00,1284.00 1038.00,1284.00 1038.00,1555.00 1038.00,1555.00 1038.00,1555.00 886.00,1555.00 886.00,1555.00 886.00,1555.00 886.00,1283.00 886.00,1283.00 Z",
    attr: {
      id: "_108",
      "data-name": "108",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "108", zone: "Lower Zone", zoneId: "2" },
  };

  var _109 = {
    path:
      "M 741.00,1336.00 C 741.00,1336.00 869.00,1336.00 869.00,1336.00 869.00,1336.00 869.00,1555.00 869.00,1555.00 869.00,1555.00 841.00,1555.00 841.00,1555.00 841.00,1555.00 824.00,1554.00 824.00,1554.00 824.00,1554.00 741.00,1554.00 741.00,1554.00 741.00,1554.00 741.00,1336.00 741.00,1336.00 Z",
    attr: {
      id: "_109",
      "data-name": "109",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "109", zone: "Lower Zone", zoneId: "2" },
  };

  var _109A = {
    path:
      "M 741.00,1282.00 C 741.00,1282.00 798.00,1282.00 798.00,1282.00 798.00,1282.00 815.00,1283.00 815.00,1283.00 815.00,1283.00 869.00,1283.00 869.00,1283.00 869.00,1283.00 869.00,1319.00 869.00,1319.00 869.00,1319.00 741.00,1319.00 741.00,1319.00 741.00,1319.00 741.00,1282.00 741.00,1282.00 Z",
    attr: {
      id: "_109A",
      "data-name": "109A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "109", zone: "Lower Zone A", zoneId: "2" },
  };

  var _110 = {
    path:
      "M 593.00,1336.00 C 593.00,1336.00 724.00,1336.00 724.00,1336.00 724.00,1336.00 724.00,1554.00 724.00,1554.00 724.00,1554.00 609.00,1554.00 609.00,1554.00 609.00,1554.00 595.00,1553.00 595.00,1553.00 595.00,1553.00 567.00,1553.00 567.00,1553.00 567.00,1553.00 570.84,1521.00 570.84,1521.00 570.84,1521.00 580.84,1437.00 580.84,1437.00 580.84,1437.00 585.28,1397.00 585.28,1397.00 585.28,1397.00 587.83,1378.00 587.83,1378.00 587.83,1378.00 593.00,1336.00 593.00,1336.00 Z",
    attr: {
      id: "_110",
      "data-name": "110",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "110", zone: "Lower Zone", zoneId: "2" },
  };

  var _110A = {
    path:
      "M 599.00,1280.00 C 599.00,1280.00 625.00,1282.00 625.00,1282.00 625.00,1282.00 724.00,1282.00 724.00,1282.00 724.00,1282.00 724.00,1319.00 724.00,1319.00 724.00,1319.00 595.00,1319.00 595.00,1319.00 595.00,1319.00 599.00,1280.00 599.00,1280.00 Z",
    attr: {
      id: "_110A",
      "data-name": "110A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "110", zone: "Lower Zone A", zoneId: "2" },
  };

  var _111 = {
    path:
      "M 523.00,1268.00 C 523.00,1268.00 583.00,1277.00 583.00,1277.00 583.00,1277.00 579.72,1302.00 579.72,1302.00 579.72,1302.00 572.72,1361.00 572.72,1361.00 572.72,1361.00 550.00,1552.00 550.00,1552.00 550.00,1552.00 485.00,1542.27 485.00,1542.27 485.00,1542.27 469.00,1539.87 469.00,1539.87 466.94,1539.57 463.35,1539.20 462.03,1537.42 459.95,1534.62 463.42,1523.64 464.21,1520.00 464.21,1520.00 475.87,1470.00 475.87,1470.00 475.87,1470.00 507.35,1335.00 507.35,1335.00 507.35,1335.00 523.00,1268.00 523.00,1268.00 Z",
    attr: {
      id: "_111",
      "data-name": "111",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "111", zone: "Lower Zone", zoneId: "2" },
  };

  var _112 = {
    path:
      "M 479.00,1229.00 C 479.00,1229.00 487.58,1250.00 487.58,1250.00 487.58,1250.00 489.31,1259.00 489.31,1259.00 489.31,1259.00 471.17,1279.00 471.17,1279.00 471.17,1279.00 434.00,1318.00 434.00,1318.00 434.00,1318.00 417.00,1335.00 417.00,1335.00 417.00,1335.00 400.67,1287.00 400.67,1287.00 400.67,1287.00 393.00,1263.00 393.00,1263.00 393.00,1263.00 433.00,1247.45 433.00,1247.45 433.00,1247.45 479.00,1229.00 479.00,1229.00 Z",
    attr: {
      id: "_112",
      "data-name": "112",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "112", zone: "Lower Zone", zoneId: "2" },
  };

  var _113 = {
    path:
      "M 376.00,1107.00 C 376.00,1107.00 470.11,1107.00 470.11,1107.00 470.11,1107.00 470.11,1169.00 470.11,1169.00 470.11,1169.00 470.11,1202.00 470.11,1202.00 470.11,1202.00 474.00,1214.00 474.00,1214.00 474.00,1214.00 457.00,1220.66 457.00,1220.66 457.00,1220.66 409.00,1239.45 409.00,1239.45 409.00,1239.45 387.00,1248.00 387.00,1248.00 383.08,1236.24 376.02,1220.05 376.00,1208.00 376.00,1208.00 376.00,1107.00 376.00,1107.00 Z",
    attr: {
      id: "_113",
      "data-name": "113",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "113", zone: "Lower Zone", zoneId: "2" },
  };

  var _114 = {
    path:
      "M 376.00,954.00 C 376.00,954.00 471.00,954.00 471.00,954.00 471.00,954.00 471.00,1019.00 471.00,1019.00 471.00,1019.00 470.00,1034.00 470.00,1034.00 470.00,1034.00 470.00,1090.00 470.00,1090.00 470.00,1090.00 376.00,1090.00 376.00,1090.00 376.00,1090.00 376.00,954.00 376.00,954.00 Z",
    attr: {
      id: "_114",
      "data-name": "114",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "114", zone: "Lower Zone", zoneId: "2" },
  };

  var _115 = {
    path: "M 376.00,803.00 C 376.00,803.00 471.00,803.00 471.00,803.00 471.00,803.00 471.00,937.00 471.00,937.00 471.00,937.00 376.00,937.00 376.00,937.00 376.00,937.00 376.00,803.00 376.00,803.00 Z",
    attr: {
      id: "_115",
      "data-name": "115",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "115", zone: "Lower Zone", zoneId: "2" },
  };

  var _116 = {
    path:
      "M 377.00,650.00 C 377.00,650.00 472.00,650.00 472.00,650.00 472.00,650.00 472.00,690.00 472.00,690.00 472.00,690.00 471.00,707.00 471.00,707.00 471.00,707.00 471.00,786.00 471.00,786.00 471.00,786.00 376.04,786.00 376.04,786.00 376.04,786.00 376.04,772.00 376.04,772.00 376.04,772.00 377.00,760.00 377.00,760.00 377.00,760.00 377.00,650.00 377.00,650.00 Z",
    attr: {
      id: "_116",
      "data-name": "116",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "116", zone: "Lower Zone", zoneId: "2" },
  };

  var _117 = {
    path:
      "M 390.00,494.00 C 390.00,494.00 415.00,503.26 415.00,503.26 415.00,503.26 455.00,518.86 455.00,518.86 455.00,518.86 470.00,524.63 470.00,524.63 470.00,524.63 475.97,527.70 475.97,527.70 475.97,527.70 475.33,535.00 475.33,535.00 475.33,535.00 472.00,550.00 472.00,550.00 472.00,550.00 472.00,633.00 472.00,633.00 472.00,633.00 377.00,633.00 377.00,633.00 377.00,633.00 377.00,533.00 377.00,533.00 377.02,520.24 385.25,505.88 390.00,494.00 Z",
    attr: {
      id: "_117",
      "data-name": "117",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "117", zone: "Lower Zone", zoneId: "2" },
  };

  var _118 = {
    path:
      "M 424.00,410.00 C 430.05,414.35 437.87,424.12 443.17,430.00 443.17,430.00 470.28,460.00 470.28,460.00 473.85,464.05 488.68,479.71 490.35,483.00 492.57,487.37 487.30,499.21 485.67,504.00 485.05,505.80 484.21,508.87 482.49,509.89 479.39,511.72 468.47,506.35 465.00,505.05 465.00,505.05 416.00,486.05 416.00,486.05 412.34,484.68 398.72,480.44 397.92,476.87 397.30,474.05 403.04,461.39 404.40,458.00 404.40,458.00 424.00,410.00 424.00,410.00 Z",
    attr: {
      id: "_118",
      "data-name": "118",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "118", zone: "Lower Zone", zoneId: "2" },
  };

  var _119 = {
    path:
      "M 427.00,373.00 C 427.00,373.00 462.00,348.58 462.00,348.58 462.00,348.58 481.00,335.66 481.00,335.66 488.41,331.95 523.00,323.21 532.00,322.00 532.00,322.00 544.12,376.00 544.12,376.00 544.12,376.00 558.42,440.00 558.42,440.00 558.42,440.00 566.00,473.00 566.00,473.00 566.00,473.00 541.00,479.34 541.00,479.34 537.52,480.46 531.47,483.15 528.00,482.18 524.52,481.22 519.59,476.51 517.00,473.99 517.00,473.99 500.00,457.00 500.00,457.00 500.00,457.00 478.83,434.00 478.83,434.00 478.83,434.00 448.58,400.00 448.58,400.00 443.10,393.59 430.06,379.78 427.00,373.00 Z",
    attr: {
      id: "_119",
      "data-name": "119",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "119", zone: "Lower Zone", zoneId: "2" },
  };

  var _120 = {
    path:
      "M 548.00,319.00 C 548.00,319.00 585.00,312.72 585.00,312.72 585.00,312.72 633.00,308.00 633.00,308.00 633.00,308.00 656.00,308.00 656.00,308.00 656.00,308.00 670.00,309.00 670.00,309.00 670.00,309.00 670.00,467.00 670.00,467.00 647.88,467.00 626.01,466.73 604.00,468.83 604.00,468.83 583.00,471.00 583.00,471.00 583.00,471.00 570.58,417.00 570.58,417.00 570.58,417.00 557.65,361.00 557.65,361.00 557.65,361.00 548.00,319.00 548.00,319.00 Z",
    attr: {
      id: "_120",
      "data-name": "120",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "120", zone: "Lower Zone", zoneId: "2" },
  };

  var _121 = {
    path:
      "M 687.00,309.00 C 687.00,309.00 802.00,309.00 802.00,309.00 802.00,309.00 802.00,468.00 802.00,468.00 802.00,468.00 725.00,468.00 725.00,468.00 725.00,468.00 710.00,467.00 710.00,467.00 710.00,467.00 687.00,467.00 687.00,467.00 687.00,467.00 687.00,309.00 687.00,309.00 Z",
    attr: {
      id: "_121",
      "data-name": "121",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "121", zone: "Lower Zone", zoneId: "2" },
  };

  var _122 = {
    path:
      "M 819.00,310.00 C 819.00,310.00 938.00,310.00 938.00,310.00 938.00,310.00 938.00,469.00 938.00,469.00 938.00,469.00 880.00,469.00 880.00,469.00 880.00,469.00 863.00,468.00 863.00,468.00 863.00,468.00 819.00,468.00 819.00,468.00 819.00,468.00 819.00,310.00 819.00,310.00 Z",
    attr: {
      id: "_122",
      "data-name": "122",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "122", zone: "Lower Zone", zoneId: "2" },
  };

  var _123 = {
    path:
      "M 955.00,310.00 C 955.00,310.00 996.00,310.00 996.00,310.00 996.00,310.00 1013.00,311.00 1013.00,311.00 1013.00,311.00 1143.00,311.00 1143.00,311.00 1143.00,311.00 1143.00,444.00 1143.00,444.00 1143.00,444.00 1128.00,444.00 1128.00,444.00 1128.00,444.00 1128.00,469.00 1128.00,469.00 1128.00,469.00 955.00,469.00 955.00,469.00 955.00,469.00 955.00,310.00 955.00,310.00 Z",
    attr: {
      id: "_123",
      "data-name": "123",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "123", zone: "Lower Zone", zoneId: "2" },
  };

  var _124 = {
    path:
      "M 1160.00,311.00 C 1160.00,311.00 1226.00,311.00 1226.00,311.00 1226.00,311.00 1243.00,312.00 1243.00,312.00 1243.00,312.00 1279.00,312.00 1279.00,312.00 1279.00,312.00 1279.00,445.00 1279.00,445.00 1279.00,445.00 1230.00,445.00 1230.00,445.00 1230.00,445.00 1213.00,444.00 1213.00,444.00 1213.00,444.00 1160.00,444.00 1160.00,444.00 1160.00,444.00 1160.00,311.00 1160.00,311.00 Z",
    attr: {
      id: "_124",
      "data-name": "124",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "124", zone: "Lower Zone", zoneId: "2" },
  };

  var _125 = {
    path:
      "M 1296.00,312.00 C 1296.00,312.00 1413.00,312.00 1413.00,312.00 1413.00,312.00 1413.00,469.00 1413.00,469.00 1413.00,469.00 1315.00,469.00 1315.00,469.00 1315.00,469.00 1315.00,445.00 1315.00,445.00 1315.00,445.00 1296.00,445.00 1296.00,445.00 1296.00,445.00 1296.00,312.00 1296.00,312.00 Z",
    attr: {
      id: "_125",
      "data-name": "125",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "125", zone: "Lower Zone", zoneId: "2" },
  };

  var _126 = {
    path:
      "M 1430.00,312.00 C 1430.00,312.00 1470.00,312.00 1470.00,312.00 1493.27,312.04 1524.38,321.08 1548.00,323.00 1548.00,323.00 1541.25,363.00 1541.25,363.00 1541.25,363.00 1527.58,439.00 1527.58,439.00 1527.58,439.00 1521.00,476.00 1521.00,476.00 1496.23,469.44 1469.39,470.29 1444.00,470.00 1444.00,470.00 1430.00,469.00 1430.00,469.00 1430.00,469.00 1430.00,312.00 1430.00,312.00 Z",
    attr: {
      id: "_126",
      "data-name": "126",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "126", zone: "Lower Zone", zoneId: "2" },
  };

  var _127 = {
    path:
      "M 1565.00,327.00 C 1565.00,327.00 1587.00,331.12 1587.00,331.12 1587.00,331.12 1615.00,338.87 1615.00,338.87 1615.00,338.87 1647.00,358.00 1647.00,358.00 1647.00,358.00 1681.00,380.00 1681.00,380.00 1681.00,380.00 1654.08,411.00 1654.08,411.00 1654.08,411.00 1607.28,463.00 1607.28,463.00 1607.28,463.00 1589.28,483.00 1589.28,483.00 1589.28,483.00 1579.00,492.59 1579.00,492.59 1579.00,492.59 1566.00,488.60 1566.00,488.60 1566.00,488.60 1538.00,480.00 1538.00,480.00 1538.00,480.00 1547.58,423.00 1547.58,423.00 1547.58,423.00 1565.00,327.00 1565.00,327.00 Z",
    attr: {
      id: "_127",
      "data-name": "127",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "127", zone: "Lower Zone", zoneId: "2" },
  };

  var _203 = {
    path:
      "M 1538.00,1574.00 C 1538.00,1574.00 1603.00,1574.00 1603.00,1574.00 1603.00,1574.00 1603.00,1788.00 1603.00,1788.00 1603.00,1788.00 1603.00,1844.00 1603.00,1844.00 1602.98,1852.64 1602.64,1852.98 1594.00,1853.00 1594.00,1853.00 1538.00,1853.00 1538.00,1853.00 1538.00,1853.00 1538.00,1574.00 1538.00,1574.00 Z",
    attr: {
      id: "_203",
      "data-name": "203",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "203", zone: "Upper Zone", zoneId: "3" },
  };

  var _204 = {
    path: "M 1440.00,1573.00 C 1440.00,1573.00 1521.00,1573.00 1521.00,1573.00 1521.00,1573.00 1521.00,1853.00 1521.00,1853.00 1521.00,1853.00 1440.00,1853.00 1440.00,1853.00 1440.00,1853.00 1440.00,1573.00 1440.00,1573.00 Z",
    attr: {
      id: "_204",
      "data-name": "204",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "204", zone: "Upper Zone", zoneId: "3" },
  };

  var _205 = {
    path:
      "M 1287.00,1572.00 C 1287.00,1572.00 1300.00,1573.00 1300.00,1573.00 1300.00,1573.00 1423.00,1573.00 1423.00,1573.00 1423.00,1573.00 1423.00,1853.00 1423.00,1853.00 1423.00,1853.00 1287.00,1853.00 1287.00,1853.00 1287.00,1853.00 1287.00,1572.00 1287.00,1572.00 Z",
    attr: {
      id: "_205",
      "data-name": "205",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "205", zone: "Upper Zone", zoneId: "3" },
  };

  var _206 = {
    path:
      "M 1148.00,1572.00 C 1148.00,1572.00 1270.00,1572.00 1270.00,1572.00 1270.00,1572.00 1270.00,1853.00 1270.00,1853.00 1270.00,1853.00 1247.00,1852.00 1247.00,1852.00 1247.00,1852.00 1148.00,1852.00 1148.00,1852.00 1148.00,1852.00 1148.00,1572.00 1148.00,1572.00 Z",
    attr: {
      id: "_206",
      "data-name": "206",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "206", zone: "Upper Zone", zoneId: "3" },
  };

  var _207 = {
    path:
      "M 965.00,1571.00 C 965.00,1571.00 1055.00,1571.00 1055.00,1571.00 1055.00,1571.00 1070.00,1572.00 1070.00,1572.00 1070.00,1572.00 1131.00,1572.00 1131.00,1572.00 1131.00,1572.00 1131.00,1852.00 1131.00,1852.00 1131.00,1852.00 965.00,1852.00 965.00,1852.00 965.00,1852.00 965.00,1571.00 965.00,1571.00 Z",
    attr: {
      id: "_207",
      "data-name": "207",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "207", zone: "Upper Zone", zoneId: "3" },
  };

  var _208 = {
    path:
      "M 822.00,1570.00 C 822.00,1570.00 841.00,1571.00 841.00,1571.00 841.00,1571.00 948.00,1571.00 948.00,1571.00 948.00,1571.00 948.00,1852.00 948.00,1852.00 948.00,1852.00 822.00,1852.00 822.00,1852.00 822.00,1852.00 822.00,1570.00 822.00,1570.00 Z",
    attr: {
      id: "_208",
      "data-name": "208",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "208", zone: "Upper Zone", zoneId: "3" },
  };

  var _209 = {
    path: "M 674.00,1570.00 C 674.00,1570.00 805.00,1570.00 805.00,1570.00 805.00,1570.00 805.00,1852.00 805.00,1852.00 805.00,1852.00 674.00,1852.00 674.00,1852.00 674.00,1852.00 674.00,1570.00 674.00,1570.00 Z",
    attr: {
      id: "_209",
      "data-name": "209",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "209", zone: "Upper Zone", zoneId: "3" },
  };

  var _210 = {
    path:
      "M 567.00,1569.00 C 567.00,1569.00 594.00,1569.00 594.00,1569.00 594.00,1569.00 611.00,1570.00 611.00,1570.00 611.00,1570.00 657.00,1570.00 657.00,1570.00 657.00,1570.00 657.00,1852.00 657.00,1852.00 657.00,1852.00 567.00,1852.00 567.00,1852.00 567.00,1852.00 567.00,1569.00 567.00,1569.00 Z",
    attr: {
      id: "_210",
      "data-name": "210",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "210", zone: "Upper Zone", zoneId: "3" },
  };

  var _211 = {
    path:
      "M 488.00,1569.00 C 488.00,1569.00 550.00,1569.00 550.00,1569.00 550.00,1569.00 550.00,1852.00 550.00,1852.00 550.00,1852.00 520.00,1852.00 520.00,1852.00 520.00,1852.00 505.00,1851.00 505.00,1851.00 505.00,1851.00 487.00,1851.00 487.00,1851.00 487.00,1851.00 487.00,1760.00 487.00,1760.00 487.00,1760.00 488.00,1745.00 488.00,1745.00 488.00,1745.00 488.00,1569.00 488.00,1569.00 Z",
    attr: {
      id: "_211",
      "data-name": "211",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "211", zone: "Upper Zone", zoneId: "3" },
  };

  var _212A = {
    path:
      "M 341.00,1284.00 C 341.00,1284.00 378.00,1270.00 378.00,1270.00 378.00,1270.00 395.66,1321.00 395.66,1321.00 398.46,1329.08 401.41,1341.22 406.00,1348.00 406.00,1348.00 389.17,1365.00 389.17,1365.00 384.71,1369.92 382.23,1374.05 376.00,1377.00 376.00,1377.00 351.58,1313.00 351.58,1313.00 351.58,1313.00 341.00,1284.00 341.00,1284.00 Z",
    attr: {
      id: "_212A",
      "data-name": "212A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "212", zone: "Upper Zone A", zoneId: "3" },
  };

  var _213A = {
    path:
      "M 318.00,1107.00 C 318.00,1107.00 360.00,1107.00 360.00,1107.00 360.00,1107.00 360.00,1191.00 360.00,1191.00 360.00,1191.00 359.00,1206.00 359.00,1206.00 358.87,1217.81 363.94,1228.94 367.67,1240.00 368.68,1242.99 372.40,1250.87 370.89,1253.50 369.57,1255.79 363.49,1257.44 361.00,1258.37 361.00,1258.37 335.00,1269.00 335.00,1269.00 335.00,1269.00 324.58,1243.00 324.58,1243.00 320.80,1233.08 318.02,1229.96 318.00,1219.00 318.00,1219.00 318.00,1107.00 318.00,1107.00 Z",
    attr: {
      id: "_213A",
      "data-name": "213A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "213", zone: "Upper Zone A", zoneId: "3" },
  };

  var _214A = {
    path: "M 318.00,954.00 C 318.00,954.00 360.00,954.00 360.00,954.00 360.00,954.00 360.00,1090.00 360.00,1090.00 360.00,1090.00 318.00,1090.00 318.00,1090.00 318.00,1090.00 318.00,954.00 318.00,954.00 Z",
    attr: {
      id: "_214A",
      "data-name": "214A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "214", zone: "Upper Zone A", zoneId: "3" },
  };

  var _215A = {
    path: "M 318.00,803.00 C 318.00,803.00 360.00,803.00 360.00,803.00 360.00,803.00 360.00,937.00 360.00,937.00 360.00,937.00 318.00,937.00 318.00,937.00 318.00,937.00 318.00,803.00 318.00,803.00 Z",
    attr: {
      id: "_215A",
      "data-name": "215A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "215", zone: "Upper Zone A", zoneId: "3" },
  };

  var _216A = {
    path:
      "M 319.00,650.00 C 319.00,650.00 361.00,650.00 361.00,650.00 361.00,650.00 361.00,733.00 361.00,733.00 361.00,733.00 360.00,748.00 360.00,748.00 360.00,748.00 360.00,786.00 360.00,786.00 360.00,786.00 318.00,786.00 318.00,786.00 318.00,786.00 318.00,758.00 318.00,758.00 318.00,758.00 319.00,741.00 319.00,741.00 319.00,741.00 319.00,650.00 319.00,650.00 Z",
    attr: {
      id: "_216A",
      "data-name": "216A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "216", zone: "Upper Zone A", zoneId: "3" },
  };

  var _217A = {
    path:
      "M 336.00,473.00 C 336.00,473.00 375.00,488.00 375.00,488.00 371.17,502.18 361.02,516.26 361.00,531.00 361.00,531.00 361.00,633.00 361.00,633.00 361.00,633.00 319.13,633.00 319.13,633.00 319.13,633.00 319.13,518.00 319.13,518.00 319.13,518.00 319.13,511.00 319.13,511.00 319.13,511.00 336.00,473.00 336.00,473.00 Z",
    attr: {
      id: "_217A",
      "data-name": "217A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "217", zone: "Upper Zone A", zoneId: "3" },
  };

  var _218A = {
    path:
      "M 382.00,472.00 C 376.71,471.17 359.68,464.41 354.00,462.00 351.89,461.10 346.16,459.32 345.12,457.49 343.42,454.51 349.02,444.21 350.42,441.00 350.42,441.00 368.58,401.00 368.58,401.00 369.86,398.04 373.50,388.50 376.27,387.35 378.56,386.39 384.57,388.58 387.00,389.34 387.00,389.34 412.00,397.00 412.00,397.00 412.00,397.00 392.00,447.00 392.00,447.00 392.00,447.00 382.00,472.00 382.00,472.00 Z",
    attr: {
      id: "_218A",
      "data-name": "218A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "218", zone: "Upper Zone A", zoneId: "3" },
  };

  var _219 = {
    path:
      "M 481.00,89.00 C 481.00,89.00 542.00,82.07 542.00,82.07 542.00,82.07 556.00,80.88 556.00,80.88 556.00,80.88 562.40,80.88 562.40,80.88 562.40,80.88 564.42,93.00 564.42,93.00 564.42,93.00 565.17,105.00 565.17,105.00 565.17,105.00 567.04,129.00 567.04,129.00 567.04,129.00 568.83,149.00 568.83,149.00 568.83,149.00 570.09,170.00 570.09,170.00 570.09,170.00 571.91,189.00 571.91,189.00 571.91,189.00 576.06,241.00 576.06,241.00 576.06,241.00 576.06,251.00 576.06,251.00 576.06,251.00 578.09,276.00 578.09,276.00 578.09,276.00 578.99,285.00 578.99,285.00 578.99,285.00 578.99,296.26 578.99,296.26 578.99,296.26 566.00,299.27 566.00,299.27 566.00,299.27 530.00,306.00 530.00,306.00 530.00,306.00 518.58,256.00 518.58,256.00 518.58,256.00 496.42,156.00 496.42,156.00 496.42,156.00 481.00,89.00 481.00,89.00 Z",
    attr: {
      id: "_219",
      "data-name": "219",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "219", zone: "Upper Zone", zoneId: "3" },
  };

  var _220 = {
    path:
      "M 580.00,78.00 C 580.00,78.00 620.00,74.00 620.00,74.00 620.00,74.00 680.00,74.00 680.00,74.00 680.00,74.00 680.00,292.96 680.00,292.96 680.00,292.96 668.00,292.96 668.00,292.96 668.00,292.96 657.00,292.00 657.00,292.00 657.00,292.00 633.00,292.00 633.00,292.00 633.00,292.00 597.00,295.00 597.00,295.00 597.00,295.00 594.91,268.00 594.91,268.00 594.91,268.00 592.91,242.00 592.91,242.00 592.91,242.00 589.09,196.00 589.09,196.00 589.09,196.00 584.17,131.00 584.17,131.00 584.17,131.00 580.00,78.00 580.00,78.00 Z",
    attr: {
      id: "_220",
      "data-name": "220",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "220", zone: "Upper Zone", zoneId: "3" },
  };

  var _221 = {
    path:
      "M 697.00,130.00 C 697.00,130.00 818.00,130.00 818.00,130.00 818.00,130.00 818.00,294.00 818.00,294.00 818.00,294.00 800.00,293.00 800.00,293.00 800.00,293.00 697.00,293.00 697.00,293.00 697.00,293.00 697.00,130.00 697.00,130.00 Z",
    attr: {
      id: "_221",
      "data-name": "221",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "221", zone: "Upper Zone", zoneId: "3" },
  };

  var _222 = {
    path: "M 835.00,109.00 C 835.00,109.00 951.00,109.00 951.00,109.00 951.00,109.00 951.00,294.00 951.00,294.00 951.00,294.00 835.00,294.00 835.00,294.00 835.00,294.00 835.00,109.00 835.00,109.00 Z",
    attr: {
      id: "_222",
      "data-name": "222",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "222", zone: "Upper Zone", zoneId: "3" },
  };

  var _223 = {
    path:
      "M 968.00,109.00 C 968.00,109.00 1065.00,109.00 1065.00,109.00 1065.00,109.00 1080.00,108.00 1080.00,108.00 1080.00,108.00 1130.00,108.00 1130.00,108.00 1130.00,108.00 1130.00,295.00 1130.00,295.00 1130.00,295.00 1011.00,295.00 1011.00,295.00 1011.00,295.00 996.00,294.00 996.00,294.00 996.00,294.00 968.00,294.00 968.00,294.00 968.00,294.00 968.00,109.00 968.00,109.00 Z",
    attr: {
      id: "_223",
      "data-name": "223",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "223", zone: "Upper Zone", zoneId: "3" },
  };

  var _224 = {
    path:
      "M 1147.00,108.00 C 1147.00,108.00 1265.00,108.00 1265.00,108.00 1265.00,108.00 1265.00,296.00 1265.00,296.00 1265.00,296.00 1243.00,296.00 1243.00,296.00 1243.00,296.00 1226.00,295.00 1226.00,295.00 1226.00,295.00 1147.00,295.00 1147.00,295.00 1147.00,295.00 1147.00,108.00 1147.00,108.00 Z",
    attr: {
      id: "_224",
      "data-name": "224",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "224", zone: "Upper Zone", zoneId: "3" },
  };

  var _225 = {
    path: "M 1282.00,136.00 C 1282.00,136.00 1401.00,136.00 1401.00,136.00 1401.00,136.00 1401.00,296.00 1401.00,296.00 1401.00,296.00 1282.00,296.00 1282.00,296.00 1282.00,296.00 1282.00,136.00 1282.00,136.00 Z",
    attr: {
      id: "_225",
      "data-name": "225",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "225", zone: "Upper Zone", zoneId: "3" },
  };

  var _226 = {
    path:
      "M 1418.00,76.00 C 1418.00,76.00 1484.00,76.00 1484.00,76.00 1484.00,76.00 1524.00,81.00 1524.00,81.00 1524.00,81.00 1519.17,138.00 1519.17,138.00 1519.17,138.00 1514.83,192.00 1514.83,192.00 1514.83,192.00 1510.09,245.00 1510.09,245.00 1510.09,245.00 1508.09,267.00 1508.09,267.00 1508.09,267.00 1506.72,286.00 1506.72,286.00 1506.72,286.00 1504.40,298.83 1504.40,298.83 1504.40,298.83 1495.00,298.83 1495.00,298.83 1495.00,298.83 1472.00,296.00 1472.00,296.00 1472.00,296.00 1418.00,296.00 1418.00,296.00 1418.00,296.00 1418.00,76.00 1418.00,76.00 Z",
    attr: {
      id: "_226",
      "data-name": "226",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "226", zone: "Upper Zone", zoneId: "3" },
  };

  var _227 = {
    path:
      "M 1541.00,83.00 C 1541.00,83.00 1620.00,94.00 1620.00,94.00 1619.79,103.82 1611.07,136.10 1608.42,148.00 1608.42,148.00 1582.87,258.00 1582.87,258.00 1582.87,258.00 1574.00,296.00 1574.00,296.00 1573.37,298.92 1571.74,308.39 1569.44,309.94 1567.10,311.51 1558.91,309.16 1556.00,308.61 1556.00,308.61 1522.00,303.00 1522.00,303.00 1522.00,303.00 1523.17,290.00 1523.17,290.00 1523.17,290.00 1525.17,267.00 1525.17,267.00 1525.17,267.00 1532.09,186.00 1532.09,186.00 1532.09,186.00 1533.83,169.00 1533.83,169.00 1533.83,169.00 1539.09,105.00 1539.09,105.00 1539.09,105.00 1541.00,83.00 1541.00,83.00 Z",
    attr: {
      id: "_227",
      "data-name": "227",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "227", zone: "Upper Zone", zoneId: "3" },
  };

  var _321A = {
    path:
      "M 697.00,75.00 C 697.00,75.00 764.00,75.00 764.00,75.00 764.00,75.00 781.00,76.00 781.00,76.00 781.00,76.00 818.00,76.00 818.00,76.00 818.00,76.00 818.00,113.00 818.00,113.00 818.00,113.00 697.00,113.00 697.00,113.00 697.00,113.00 697.00,75.00 697.00,75.00 Z",
    attr: {
      id: "_321A",
      "data-name": "321A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "321", zone: "Upper Zone A", zoneId: "3" },
  };

  var _325A = {
    path:
      "M 1282.00,77.00 C 1282.00,77.00 1359.00,77.00 1359.00,77.00 1359.00,77.00 1375.00,76.00 1375.00,76.00 1375.00,76.00 1401.00,76.00 1401.00,76.00 1401.00,76.00 1401.00,119.00 1401.00,119.00 1401.00,119.00 1282.00,119.00 1282.00,119.00 1282.00,119.00 1282.00,77.00 1282.00,77.00 Z",
    attr: {
      id: "_325A",
      "data-name": "325A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "325", zone: "Upper Zone A", zoneId: "3" },
  };

  var _SideSeats = {
    path:
      "M 563.00,1243.00 C 563.00,1243.00 563.00,1212.00 563.00,1212.00 563.00,1212.00 564.02,1204.60 564.02,1204.60 564.02,1204.60 572.00,1203.00 572.00,1203.00 572.00,1203.00 675.00,1203.00 675.00,1203.00 675.00,1203.00 690.00,1204.00 690.00,1204.00 690.00,1204.00 806.00,1204.00 806.00,1204.00 806.00,1204.00 1038.00,1204.00 1038.00,1204.00 1038.00,1204.00 1273.00,1204.00 1273.00,1204.00 1273.00,1204.00 1391.00,1204.00 1391.00,1204.00 1391.00,1204.00 1406.00,1205.00 1406.00,1205.00 1406.00,1205.00 1517.00,1205.00 1517.00,1205.00 1517.00,1205.00 1517.00,1233.00 1517.00,1233.00 1517.00,1235.39 1517.28,1242.89 1516.40,1244.69 1515.19,1247.15 1512.35,1246.95 1510.00,1247.00 1510.00,1247.00 1488.00,1246.00 1488.00,1246.00 1488.00,1246.00 1268.00,1246.00 1268.00,1246.00 1268.00,1246.00 1253.00,1245.00 1253.00,1245.00 1253.00,1245.00 1054.00,1245.00 1054.00,1245.00 1054.00,1245.00 964.00,1245.00 964.00,1245.00 964.00,1245.00 951.00,1244.00 951.00,1244.00 951.00,1244.00 627.00,1244.00 627.00,1244.00 627.00,1244.00 612.00,1243.00 612.00,1243.00 612.00,1243.00 563.00,1243.00 563.00,1243.00 Z",
    attr: {
      id: "_SideSeats",
      "data-name": "SideSeats",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Side Seats", zoneId: "1" },
  };

  zones.push(
    _104,
    _SideSeats,
    _321A,
    _325A,
    _227,
    _226,
    _225,
    _224,
    _223,
    _222,
    _221,
    _220,
    _219,
    _218A,
    _217A,
    _216A,
    _215A,
    _214A,
    _213A,
    _212A,
    _211,
    _210,
    _209,
    _208,
    _207,
    _206,
    _205,
    _204,
    _203,
    _105A,
    _105,
    _106A,
    _106,
    _107,
    _108,
    _109A,
    _109,
    _110A,
    _110,
    _111,
    _112,
    _113,
    _114,
    _115,
    _116,
    _117,
    _118,
    _119,
    _120,
    _121,
    _122,
    _123,
    _124,
    _125,
    _126,
    _127
  );
  return zones;
};
