import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import "./index.css";
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
const renderReviewsIframe = (url) => {
  // var fullUrl = `https://www.facebook.com/plugins/post.php?href=${url}&width=auto&show_text=true&appId=694091671087246&height=150`;
  var fullUrl = `https://www.facebook.com/plugins/post.php?href=${url[0]}&show_text=true&width=auto&height=150`;
  return (
    <div className="iframe-container">
      <iframe
        src={fullUrl}
        title="testimony"
        className="iframe"
        paddingleft="15%"
        paddingright="15%"
        height="100%"
        width="100%"
        maxwidth="450px"
        maxheight="235px"
        display="block"
        scrolling="yes"
        frameBorder="0"
        allow="fullscreen; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
};

// Stores review URLs and whether they have already been rendered. Second property is used to optimize lazy loading.
var reviewUrls = [
  [
    "https%3A%2F%2Fwww.facebook.com%2Fmark.heim.754%2Fposts%2Fpfbid02vnUvKrqBa2yQiBzEKWPP1EXgPrTfmuTfH24bp5GMWh6JaR96VcshjHwVXTFKCWUhl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fhelena.gonzalez.777%2Fposts%2Fpfbid021WvpDd25qS4ZRAvxYxWjqUv7JkfMKaiDHgU5x6Z5hT9HMbf8cx4AzKpP5gHAQ8kml",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fhelena.gonzalez.777%2Fposts%2Fpfbid021WvpDd25qS4ZRAvxYxWjqUv7JkfMKaiDHgU5x6Z5hT9HMbf8cx4AzKpP5gHAQ8kml",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fdannycmah%2Fposts%2Fpfbid0Vsdij2E8Wo8sFB8NXyXK5KDuopjxi6BEzyVQeS2LiMV1SbM1JCpNZEGCtLs2vBLVl",
    false
  ], 
  [
    "https%3A%2F%2Fwww.facebook.com%2FLizLyle%2Fposts%2Fpfbid02XJjya2gTZzf71qHEQCYCJp6foafKTuLjYTkm8iBmFnhuZt4WqRTAehPX9adUBNYvl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2FAnto.M.Chan%2Fposts%2Fpfbid0zqwXzWDeFCC5uToxhBYg7xvsiCLTJ6jwSwUn4Aezjjkpcb1sssQg9kZFSE4hZcpzl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fdani.bessette.8%2Fposts%2Fpfbid033Z6Lwgk4u8QkLaaoNG9HXzuP8PzDjgBneRciL42MiAMrozET8DHj4B2dC4SLbNbul",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fmichael.dumka%2Fposts%2Fpfbid02mBwuZ4hgbo9Ssd4pnUeM5TrUtVTEBcdDs84nKr4GigRqe7m6J9ThXAMHdUca1F8il",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fkatia.perron.1%2Fposts%2Fpfbid02usWtY34gzQX4mKZxxAcEz5StxGJH3fmGPHcc5wMm7mfFhG4R1MPQk53K27ZCh8oRl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fchris.jermyn%2Fposts%2Fpfbid0y7uPudEz27NfpY5f62zUtycCziyab2ysE4w9dKV2zk2Hzpz7L8oGhx2edZsofRFrl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fchrisle9%2Fposts%2Fpfbid0rD6xT51Ck1KxyCFUsiV7ng4iuCEDZaLjxy1EYKGrUp1txon4HQen2f8mXYJaGWWwl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fcasey.toews.9%2Fposts%2Fpfbid0qaxKJkYhcfKrFwZdXy3xinwBx9XoRJAxSk8wTqb9Ps21yAq2dGaa7FyzA4zmoLXXl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fbrock.mansoff%2Fposts%2Fpfbid026NN1htyJF4458NiuKoa457iQqWNNp3u1Pcg339n5RV36M7utFhZKXVCoTdHekDczl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fsahota1.jas%2Fposts%2Fpfbid024DyQ8Hy6ftWmWd8CqAYGFJQ4gRabd2dizAiSaUF8qJxQjM738q9zY6jybukxnZtyl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Frooven.maharaj%2Fposts%2Fpfbid0NyAFyqcaBYW2Q1pVc4mX57mE4tqdTFof8sL7jab7EnBEmj929d4iRLjvfqRDHaPNl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fnick.czuba%2Fposts%2Fpfbid0Xb8ATenpFwoY81bMDAmnS2iezANBdbMcr98yMrydmMQ2SzXpMgHQehVMXS8e2cSzl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Ftravis.dyck.16%2Fposts%2Fpfbid0Du6V1b4CDvQ3qSGG9syP53BqMNZh2eeFiEmE9qeeWb3mjpGu1sFN82QumW1x8eBLl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fbob.mellin.18%2Fposts%2Fpfbid0FqFh9jAjfN8WKyQVW2kQp8oqozATZfkLRDCbzxzwNqQn2BnqoqeCxt5oqpQHxXqGl",
    false
  ],
  [
    "https%3A%2F%2Fwww.facebook.com%2Fdan.jefkins%2Fposts%2Fpfbid02xMEgJkJ6j4Jc3CudvcqLvfh2n2oKxid63UgWXxcuJkt2DSRqUui4nY95x5GEHB5bl",
    false
  ],
];

// Randomly sort the array of review urls.
reviewUrls = shuffle(reviewUrls);

const WhatCustomerSayV2 = () => {
  const [current, setCurrent] = useState(0);
  const [direction, setDirection] = useState("prev");
  const length = reviewUrls.length;
  const lazyLoad = 2;

  const nextReview = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    setDirection("next");
  };

  const prevReview = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    setDirection("prev");
  };

  return (
    <div className="container">
      <Typography
        variant="headline"
        align="center"
        color="error"
        style={{
          paddingTop: "2rem",
          paddingBottom: "2rem",
          margin: "0",
          fontSize: "2rem",
          fontWeight: "bold",
          backgroundColor: "#C9EBFF",
        }}
      >
        <span
          style={{
            color: "#00A0FF",
            whiteSpace: "nowrap",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          WHAT CUSTOMERS SAY
        </span>
        <br />
      </Typography>

      <div className="slider">
        {reviewUrls.map((slide, index) => {
          if (
            reviewUrls[index][1] == true ||
            index - current < lazyLoad ||
            length - index - current < lazyLoad
          ) {
            reviewUrls[index][1] = true;
            var disabledClass =
              direction === "next"
                ? "review inactive next"
                : "review inactive prev";
            var activeClass =
              direction === "next"
                ? "review active next"
                : "review active prev";
            return (
              <div
                className={index === current ? activeClass : disabledClass}
                key={index}
              >
                {renderReviewsIframe(slide)}
              </div>
            );
          }
        })}
      </div>

      <div className="prevNext">
        <a className="fas fa-chevron-left" onClick={prevReview}></a>
        <a className="fas fa-chevron-right" onClick={nextReview}></a>
      </div>
    </div>
  );
};
export default WhatCustomerSayV2;
