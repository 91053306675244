const zoneIds = {
  "calgary-flames": {
    "press level": 1,
    "second level": 2,
    "lower bowl": 3,
  },
  "winnipeg-jets": {
    "upper zone": 1,
    "middle zone": 2,
    "lower zone": 3,
  },
  "edmonton-oilers": {
    "upper zone": 1,
    "lower zone": 2,
    others: 3,
  },
  "vancouver-canucks": {
    "upper bowl": 1,
    "lower bowl": 2,
    "club lower": 3,
  },
};

const competitorFeeCalculation = (price, quantity, tier) => {
  const stubhubFeeCalculations = (price, quantity) => {
    return price * quantity * 0.37;
  };
  const ticketmasterFeeCalculations = (price, quantity, tier) => {
    const minimumPricesByTier = {
      1: 25,
      2: 44,
      3: 142,
    };
    return Math.max([minimumPricesByTier[tier], price]) * quantity * 0.22;
  };

  return {
    stubhub: stubhubFeeCalculations(price, quantity),
    ticketmaster: ticketmasterFeeCalculations(price, quantity, tier),
  };
};

const tmUpperZoneFees = (price, qty) => {
  if (price >= 25) {
    return price * qty * 0.22;
  }

  return 25 * qty * 0.22;
};

const tmMidZoneFees = (price, qty) => {
  if (price >= 44) {
    return price * qty * 0.22;
  }

  return 44 * qty * 0.22;
};

const tmLowerZoneFees = (price, qty) => {
  if (price >= 142) {
    return price * qty * 0.22;
  }

  return 142 * qty * 0.22;
};

// TM Fees
export const computeTmFees = ({ price, qty, zone, homeTeamSlug }) => {
  switch (zoneIds[homeTeamSlug][zone.toLowerCase()]) {
    case 1: {
      return tmUpperZoneFees(price, qty);
    }

    case 2: {
      return tmMidZoneFees(price, qty);
    }

    case 3: {
      return tmLowerZoneFees(price, qty);
    }
  }
};

// STUBHUB fees
export const computeStubhubFees = ({ price, qty, zone, homeTeamSlug }) => {
  return price * qty * 0.37;
};
