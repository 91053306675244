import React, { Component } from "react";
import { API } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import PendingSeatsTable from "../../components/PendingSeatsTable";
import ActiveSeatsViewTable from "../../components/ActiveSeatsViewTable";
import SellHistory from "../../components/SellHistory";
import SellerSoldOutSeatsTable from "../../components/SellerSoldOutSeatsTable";
import getTimezone from "../../libs/get-timezone";

const styles = (theme) => ({
  alert: {
    padding: "1rem",
    backgroundColor: "#ffffc7",
  },
  stickyTabs: {
    backgroundColor: "#ffffff",
    zIndex: "1",
    top: "188px",
    "@media (max-width: 959px)": {
      top: "200px",
    },
  },
  header: {
    position: "-webkit-sticky",
    position: "sticky",
    top: "0px",
    backgroundColor: "#ffffff",
    zIndex: "1",
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
  },
  indicator: {
    backgroundColor: "#00a0ff",
    height: "5px",
    borderRadius: "25px 25px 0px 0px",
  },
  [theme.breakpoints.up("md")]: {
    header: {
      paddingTop: "3rem",
      paddingBottom: "2rem",
      position: "-webkit-sticky",
      position: "sticky",
      top: "0px",
      backgroundColor: "#fafafa",
    },
    btnsContainer: {
      marginTop: "2rem",
      borderRadius: "99px",
      display: "flex",
    },
    listingsText: {
      display: "block",
      paddingTop: "65px",
      marginTop: "-40px",
      fontWeight: "600",
      fontFamily: "Nunito Sans",
    },
    transferButton: {
      marginRight: "1rem",
    },
    tabs: {
      borderBottom: "1px solid #D5DEE9",
      fontFamily: "Nunito Sans",
      "& button": {
        minWidth: 150,
        fontFamily: "Nunito Sans",
        fontWeight: "600",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    header: {
      paddingTop: "6rem",
      paddingRight: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
    },
    btnsContainer: {
      marginTop: "1rem",
      borderRadius: "99px",
      display: "flex",
      justifyContent: "space-between",
    },
    transferButton: {
      marginRight: "1rem",
      marginTop: "1rem",
    },
    flexContainer: {
      justifyContent: "space-around",
    },
    listingsText: {
      display: "block",
      paddingTop: "65px",
      fontSize: "32px",
      marginTop: "-60px",
      fontWeight: "bold",
      fontFamily: "Nunito Sans",
    },
    tabs: {
      borderBottom: "1px solid #D5DEE9",
    },
    main: {
      paddingLeft: "0rem",
      paddingRight: "0rem",
    }
  },
  progress: {
    height: 24,
    width: 24,
    marginLeft: "1rem",
  },
});

class ListingsWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      pendingGames: [],
      history: [],
      tab: "active",
      isLoading: true,
      seats: null,
      activeSeats: null,
      isEditing: false,
      submitting: false,
      discarding: false,
      lastEvaluatedKey: null,
      lastEvaluatedHistoryKey: null,
      loadMoreLoading: false,
      lastEvaluatedSellRequestsKey: null
    };

    this.setSeats = this.setSeats.bind(this);
    this.setPendingRequest = this.setPendingRequest.bind(this);
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      await this.setSeats();
      await this.setPendingRequest();
      if (this.state.activeSeats && this.state.pendingGames) {
        if (this._isMounted && this.state.activeSeats.length === 0 && this.state.pendingGames.length === 0) {
          this.setState({ isLoading: false });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async setSeats(includeInactive = false, tab = "active") {
    const seats = await this.seats(includeInactive, tab);
    if (!seats) {
      return;
    }
    const seatLists = seats.listings;
    const {
      activeSeats: activeSeatsState,
      history: historyState,
      lastEvaluatedKey,
      lastEvaluatedHistoryKey,
    } = this.state;

    const arrangedSeats = _.orderBy(
      seatLists,
      ["gameDate", "zoneNo", "zone", "row", "price"],
      "asc"
    );
    const activeSeats = _.chain(arrangedSeats)
      .filter(
        (seat) =>
          !seat.isSold &&
          !seat.isArchived &&
          (!seat.gameDate ||
            seat.gameDate >=
              moment.tz(getTimezone(seat.game)).subtract(1, "hour").valueOf())
      )
      .orderBy(["gameDate", "gameName", "zoneNo", "zone", "row", "price"], "asc")
      .value();

    const soldOutSeats = _.filter(
      arrangedSeats,
      (seat) => (seat.isSold || seat.seatsSold > 0) && !seat.isArchived
    );

    const history = _.chain(arrangedSeats)
      .filter((seat) => {
        return (
          (seat.gameDate &&
            seat.gameDate <=
              moment
                .tz(getTimezone(seat.game))
                .subtract(1, "hour")
                .valueOf()) ||
          seat.isArchived ||
          seat.isSold
        );
      })
      .orderBy(["gameDate", "orderNo"], "desc")
      .value();

    const isHistory = tab === "history";
    const preActiveState = activeSeatsState ? activeSeatsState : [];
    const preHistory = historyState ? historyState : [];
    if (this._isMounted) {
      this.setState({
        ...this.state,
        seats: arrangedSeats,
        activeSeats: isHistory
          ? activeSeatsState
          : [...preActiveState, ...activeSeats],
        soldOutSeats,
        history: isHistory ? [...preHistory, ...history] : historyState,
        isLoading: false,
        loadMoreLoading: false,
        lastEvaluatedKey: isHistory ? lastEvaluatedKey : seats.lastEvaluatedKey,
        lastEvaluatedHistoryKey: isHistory
          ? seats.lastEvaluatedKey
          : lastEvaluatedHistoryKey,
      });
    }
  }

  async setPendingRequest() {
    const requests = await this.requests();
    if (!requests) {
      return;
    }
    const arrangedRequests = _.chain(requests.sell_requests)
      .orderBy("createdAt", "desc")
      .value();

    const pendingGames = _.chain(arrangedRequests)
      .flatMap((request) => {
        return request.games.map((game) => {
          return {
            ...game,
            ...{
              section: request.section,
              sectionNo: request.sectionNo,
              row: request.row,
              noOfSeats: request.noOfSeats,
            },
          };
        });
      })
      .orderBy(["date", "orderNo"], "asc")
      .value();
    if (this._isMounted) {
      this.setState({
        ...this.state,
        requests: arrangedRequests,
        pendingGames,
        isLoading: false,
        lastEvaluatedSellRequestsKey: requests.lastEvaluatedSellRequestsKey,
      });
    }
  };

  async seats(includeInactive = false, tag = "active") {
    try {
      const { currUser } = this.props;
      const { lastEvaluatedKey, lastEvaluatedHistoryKey } = this.state;
      const lastKey = tag === "history" ? lastEvaluatedHistoryKey : lastEvaluatedKey;
      if (currUser) {
        const response = await API.get(
          "v2",
          `marketplace/listings/by/paginated/sellerId/${
            currUser.sub
          }?includeInactive=${includeInactive}${
            lastKey ? `&lastEvaluatedKey=${JSON.stringify(lastKey)}` : ""
          }`
        );
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async requests() {
    try {
      const { currUser } = this.props;
      const { lastEvaluatedSellRequestsKey } = this.state;
      if (currUser) {
        const response = await API.get(
          "v2",
          `marketplace/sellRequests/paginated/by/sellerId/${currUser.sub}${
            lastEvaluatedSellRequestsKey ? `?lastEvaluatedSellRequestsKey=${JSON.stringify(lastEvaluatedSellRequestsKey)}` : ""
          }`
        );
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  renderMain() {
    const { tab, history } = this.state;
    const { classes } = this.props;
    return (
      <Grid container className={classes.main}>
        <Grid item xs={12} className={classes.stickyTabs}>
          <Tabs
            value={tab}
            scrollable
            scrollButtons="off"
            onChange={(event, newValue) => {
              this.setState({ tab: newValue, isEditing: false });
              if (newValue === "active") {
                this.setState({
                  ...this.state,
                  activeSeats: [],
                  isLoading: true,
                  tab: newValue,
                });
                this.setSeats();
              } else if (newValue === "history" && history.length === 0) {
                this.setState({
                  ...this.state,
                  isLoading: true,
                  tab: newValue,
                });
                this.setSeats(true, newValue);
              } else if (newValue === "pending") {
                this.setState({
                  ...this.state,
                  isLoading: true,
                  tab: newValue,
                });
                this.setPendingRequest();
              }
            }}
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
              flexContainer: classes.flexContainer,
            }}
          >
            <Tab
              value="active"
              label={
                <Typography
                  style={{
                    fontWeight: "600",
                    color: tab === "active" ? "#00A0FF" : "#5F666F",
                    fontSize: "18px",
                  }}
                >
                  Active
                </Typography>
              }
              style={{
                textTransform: "none",
                color: tab === "active" ? "#00A0FF" : "#5F666F",
                fontSize: "1rem",
              }}
            />
            <Tab
              value="pending"
              label={
                <Typography
                  style={{
                    fontWeight: "600",
                    color: tab === "pending" ? "#00A0FF" : "#5F666F",
                    fontSize: "18px",
                  }}
                >
                  Pending
                </Typography>
              }
              style={{
                textTransform: "none",
                color: tab === "pending" ? "#00A0FF" : "#5F666F",
              }}
            />
            <Tab
              value="sold"
              label={
                <Typography
                  style={{
                    fontWeight: "600",
                    color: tab === "sold" ? "#00A0FF" : "#5F666F",
                    fontSize: "18px",
                  }}
                >
                  Sold
                </Typography>
              }
              style={{
                textTransform: "none",
                color: tab === "sold" ? "#00A0FF" : "#5F666F",
              }}
            />
            <Tab
              value="history"
              label={
                <Typography
                  style={{
                    fontWeight: "600",
                    color: tab === "history" ? "#00A0FF" : "#5F666F",
                    fontSize: "18px",
                  }}
                >
                  History
                </Typography>
              }
              style={{
                textTransform: "none",
                color: tab === "history" ? "#00A0FF" : "#5F666F",
              }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} md={12}>
          {this.renderListings()}
        </Grid>
      </Grid>
    );
  }

  refreshListings = () => {
    this.seats()
     .then(() => {
        this.setPendingRequest()
         .then(() => {
            if (this.state.activeSeats.length === 0 && this.state.pendingGames.length === 0) {
              this.setState({ isLoading: false });
            }
            this.render();
          });
      });
  };

  updateActiveSeats = (newActiveSeats) => {
    this.setState({ activeSeats: newActiveSeats });
  }

  renderListings() {
    const {
      requests,
      isLoading,
      pendingGames,
      tab,
      activeSeats,
      isEditing,
      lastEvaluatedKey,
      lastEvaluatedHistoryKey,
      history,
      loadMoreLoading,
      lastEvaluatedSellRequestsKey
    } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <Typography
            align="center"
            variant="body2"
            style={{ marginTop: "2rem" }}
          >
            <CircularProgress
              color="inherit"
              style={{ width: 16, height: 16, marginRight: 16 }}
            />
            Loading your listings...
          </Typography>
        </React.Fragment>
      );
    }
    if (requests) {
      return (
        <React.Fragment>
          {tab === "active" && (
            <ActiveSeatsViewTable
              seats={activeSeats ? activeSeats : null}
              updateActiveSeats={this.updateActiveSeats}
              editing={isEditing}
              setEditing={this.handleEditClose}
              updateSeats={this.updateSeats}
              submitting={this.state.submitting}
              discarding={this.state.discarding}
              makeMoreChanges={this.makeMoreChanges}
              onSeatArchived={this.refreshListings}
              currUser={this.props.currUser}
            />
          )}
          {tab === "pending" && (
            <PendingSeatsTable games={pendingGames ? pendingGames : null} />
          )}
          {tab === "sold" && (
            <SellerSoldOutSeatsTable currUser={this.props.currUser} />
          )}
          {tab === "history" && (
            <SellHistory seats={history ? history : null} />
          )}
          {tab === "history" && lastEvaluatedHistoryKey && (
            <Button
              onClick={() => {
                this.setState({ ...this.state, loadMoreLoading: true });
                this.setSeats(true, "history");
              }}
              disabled={this.state.isLoading || this.state.loadMoreLoading}
              variant="contained"
              color="secondary"
              style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
            >
              {this.state.isLoading || this.state.loadMoreLoading
                ? "Loading..."
                : "Load More"}
            </Button>
          )}
          {tab === "active" && lastEvaluatedKey && (
            <Button
              onClick={() => {
                this.setState({ ...this.state, loadMoreLoading: true });
                this.setSeats(false, "active");
              }}
              disabled={this.state.isLoading || this.state.loadMoreLoading}
              variant="contained"
              style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
              color="secondary"
            >
              {this.state.isLoading || this.state.loadMoreLoading
                ? "Loading..."
                : "Load More"}
            </Button>
          )}
          {tab === "pending" && lastEvaluatedSellRequestsKey && (
            <Button
              onClick={() => {
                this.setState({ ...this.state, loadMoreLoading: true });
                this.setPendingRequest();
              }}
              disabled={this.state.isLoading || this.state.loadMoreLoading}
              variant="contained"
              color="secondary"
              style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
            >
              {this.state.isLoading || this.state.loadMoreLoading
                ? "Loading..."
                : "Load More"}
            </Button>
          )}
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container justify="center">
          <Grid item xs={12} md={12}>
            {this.renderMain()}
          </Grid>
        </Grid>        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ListingsWidget);
