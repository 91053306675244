import React, { Component } from "react";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { 
  Card, 
  CardContent, 
  TextField, 
  InputAdornment,
  Button, 
  Divider, 
  Grow, 
  Grid, 
  Typography,
  withStyles,
} from "@material-ui/core";
import validator from "validator";
import SearchIcon from "@material-ui/icons/Search";
import TrackOrdersTable from "../components/TrackOrdersTable";

const styles = (theme) => ({
  root: {
    minWidth: 275,
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      marginTop: "4.5rem",
    },
  },
  titleText: {
    color: '#2196f3',
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: "1.5rem",
  },
  descriptionText: {
    fontSize: '0.85rem',
  },
  divider: {
    margin: '.5rem 0',
  },
  textField: {
    padding: ".5rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginRight: ".5rem",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
    width: "95%",
  },
  textFieldError: {
    padding: ".5rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginRight: ".5rem",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
    width: "95%",
  },
  [theme.breakpoints.down("md")]: {
    textField: {
      width: "100%",
    },
    textFieldError: {
      width: "100%",
    }
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  inputEmail: {
    padding: '0.07rem',
    fontSize: "0.875rem",
  },
  searchButton: {
    marginTop: theme.spacing.unit,
  },
  searchIcon: {
    color: '#2196f3',
    fontSize: '1.2rem',
  },
  error: {
    color: 'red',
    fontSize: '0.85rem',
  },
  noresultTitle: {
    fontSize: '1.2rem', 
    fontWeight: 600,
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  noresultText: {
    fontSize: '0.9rem', 
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  footerCard: {
    marginTop: "1rem",
  },
  footerText: {
    fontSize: '0.85rem',
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  }
});

class TrackOrder extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      statusOrders: [],
      isLoading: false,
      orderNumber: '',
      email: '',
      error: '',
      isValidEmail: true,
      showResult: false,
    };

    this.handleSearchOrder = this.handleSearchOrder.bind(this);
  }

  componentDidMount() {
    const { isAuthenticated, currUser } = this.props;
    if (isAuthenticated && currUser) {
      this.setState({ email: currUser.email });
    }
  }

  isValidEmail = (email) => validator.isEmail(email);

  handleSearchOrder = async () => {
    const { isAuthenticated } = this.props;
    const { orderNumber, email } = this.state;
  
    if (!orderNumber) {
      this.setState({ error: 'Please enter an order number' });
      return;
    }else{
      this.setState({ error: '' });
    }

    if (!(isAuthenticated) && !this.isValidEmail(email)) {
      this.setState({ isValidEmail: false });
      return;
    }else{
      this.setState({ isValidEmail: true });
    }

    this.setState({ isLoading: true, showResult: false });
    try {
      const order = await this.getTrackOrders(orderNumber.trim().toUpperCase(), email);
      const filteredOrders = order.orders.filter(
        (order) => order.email.toLowerCase().replace(/\s+/g, "") === email.toLowerCase().replace(/\s+/g, "")
      );

      const sortedOrders = _.orderBy(filteredOrders, ["createdAt"], ["desc"]);

      this.setState({
        statusOrders: sortedOrders,
        isLoading: false,
        showResult: true,
      });
    } catch (error) {
      this.setState({ error: "Failed to retrieve order status", isLoading: false });
    }
  };

  getTrackOrders = async (orderNumber, email) => {
    const params = [
      `buyerEmail=${encodeURIComponent(email.toLowerCase().replace(/\s+/g, ""))}`,
      `orderNumber=${encodeURIComponent(orderNumber)}`,
    ];
    const queryString = params.join("&");

    const timeout = 10000;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await API.get("v2", `marketplace/orders/search?${queryString}`, {
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      if (error.name === "AbortError") {
        console.error("API call timed out");
      } else {
        console.error(error);
      }
      throw error;
    }
  };

  renderMain() {
    const { classes, isAuthenticated } = this.props;
    const { isLoading, statusOrders, orderNumber, email, isValidEmail, error, showResult } = this.state;

    return (
      <main>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Typography 
                  component="h2" 
                  className={classes.titleText}
                >
                  Track Your Tickets
                </Typography>
                <Divider className={classes.divider}/>
                <Typography className={classes.descriptionText}>
                  Enter your Order Number to track the status of your order. You can find the Order Number in the Email Confirmation.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="order-number"
                  placeholder="Order Number"
                  className={!error ? classes.textField : classes.textFieldError}
                  value={orderNumber}
                  onChange={(e) => this.setState({ orderNumber: e.target.value })}
                  margin="dense"
                  disabled={isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                {error && <Typography className={classes.error}>{error}</Typography>}
              </Grid>
              {!isAuthenticated && (
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="email"
                    placeholder="Email Address"
                    type="email"
                    className={isValidEmail ? classes.textField : classes.textFieldError}
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    margin="dense"
                    disabled={isLoading}
                    InputProps={{
                      disableUnderline: true,
                      classes: { input: classes.inputEmail },
                    }}
                  />
                  {!isValidEmail && <Typography className={classes.error}>Enter valid email</Typography>}
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={this.handleSearchOrder} 
                  className={classes.searchButton}
                  disabled={isLoading}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {isLoading ? (
          <Typography
            align="center"
            variant="body2"
            style={{ marginTop: "2rem" }}
          >
            <CircularProgress
            color="inherit"
            style={{ width: 16, height: 16, marginRight: 16 }}
            />
            Loading ticket status...
          </Typography>
        ) : (
          showResult && (
            <Grow in={showResult}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      {!statusOrders || statusOrders.length === 0 ? (
                        <>
                          <Typography
                            variant="title"
                            className={classes.noresultTitle}
                          >
                            No result found
                          </Typography>
                          <Divider/>
                          <Typography
                            variant="body2"
                            className={classes.noresultText}
                          >
                            We couldn't find a match for the order number  {!isAuthenticated && `and email` } you provided. <br />
                            Please verify your details and try again.
                          </Typography>
                        </>
                      ) : (
                        <TrackOrdersTable
                          orders={statusOrders ? statusOrders : null}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.footerCard}>
                    <CardContent>
                      <Typography className={classes.footerText}>
                        Need more help? Text us at <a href='tel:1-403-768-2298' style={{ color: "#2196f3", textDecoration: "none" }}>1-403-768-2298</a> or email at <a href='mailto:tix@fansfirst.ca' style={{ color: "#2196f3", textDecoration: "none" }}>tix@fansfirst.ca</a>. Our response time is within minutes during business hours.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grow>
          )
        )}
      </main>
    );
  }

  render() {
    const currentURL = window.location.href;
    return (
      <React.Fragment>
        <Helmet>
          <title>Track Your Tickets</title>
          <meta name="description" content="Track Your Tickets" />
          <meta name="keywords" content={`track order, order status, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Track Your Tickets" />
          <meta property="og:description" content="Track Your Tickets" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Grid container justify="center">
          <Grid item xs={12} sm={10}>
            {this.renderMain()}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TrackOrder);