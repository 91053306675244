import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CircularProgress } from "@material-ui/core";

const DialogLoading = () => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    return () => {
      setOpen(false);
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogContent>
        <CircularProgress />
        <DialogContentText>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default DialogLoading;