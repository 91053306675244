import { API } from "aws-amplify";
import React, { Component } from "react";
import moment from "moment-timezone";
import _ from "lodash";

import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core";
import { calculateGST } from "../libs/shared/gst-helpers";
import { renderZoneName, renderRowName } from "../libs/shared/seat-helpers";
import { getFormattedTime } from "../libs/shared/grid-helper";
import { getGameName } from "../libs/shared/team-helper";
import Button from "@material-ui/core/Button";
import { formatCurrency } from "../utils/formatCurrency";
import CustomTableSortLabel from "./ctable/CustomTableSortLabel";

const styles = (theme) => ({
  totalSoldBanner: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    fontFamily: "Nunito Sans",
    backgroundColor: "#C1FFE8",
    height: "2.5rem",
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "16px"
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  priceInput: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
    fontFamily: "Roboto",
  },
  headerCell: {
    fontWeight: "600",
    color: "#5F666F",
    fontSize: "14px",
  }
});

class SellerSoldOutSeatsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      isDeleting: false,
      seatArchiving: "",
      isEditPriceOpen: false,
      isDeleteSeatOpen: true,
      noOfSeats: "-",
      price: 0,
      isValidNoOfSeats: true,
      isValidPrice: true,
      editingSeat: null,
      deletingSeat: null,
      isSubmitting: false,
      isLoading: true,
      lastEvaluatedSoldKey: null,
      loadMoreSoldLoading: false,
      soldSeats: null,
      order: "desc",
      sortBy: "Date Purchased",
    };
  }

  componentWillMount() {
    this.setSeats();
  }

  async setSeats() {
    const {
      orders: soldSeatsState,
      sortBy,
      order
    } = this.state;

    const ordersData = await this.orders();
    const preSoldState = soldSeatsState ? soldSeatsState : [];
    const newOrders = [...preSoldState, ...ordersData.orders]
    this.setState({
      orders: this.sortTable(newOrders, sortBy, order),
      isLoading: false,
      loadMoreSoldLoading: false,
      lastEvaluatedSoldKey: ordersData.lastEvaluatedKey,
    });
  }

  async orders() {
    const { currUser } = this.props;
    const { lastEvaluatedSoldKey } = this.state;
    const response = await API.get(
      "v2",
      `marketplace/orders/by/paginated/sellerId?sellerId=${currUser.sub}${lastEvaluatedSoldKey ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedSoldKey)}` : ""
      }`
    );
    return response;
  }

  getGameDate(game) {
    return moment.tz(game.date, game.timezone).format("MMM D");
  }

  getGameTime(game) {
    return getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);
  }

  handleRequestSort(property) {
    const sortBy = property;
    let order = 'desc';

    if (this.state.sortBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({
      order,
      sortBy,
      orders: this.sortTable(this.state.orders, sortBy, order)
    });
  };

  sortTable(orders, sortBy, order) {
    if (sortBy === "Date Purchased") {
      return _.orderBy(orders, (order) => order.createdAt, order);
    } else if (sortBy === "Game Date") {
      return _.orderBy(orders, (order) => order.game.date, order);
    } else {
      return orders
    }
  }

  renderTotalSold(orders) {
    const { classes } = this.props;
    return (
      <Paper elevation={0} square className={classes.totalSoldBanner}>
        <Typography
          style={{
            fontWeight: "400",
            color: "#33373C",
            fontSize: "14px",
          }}
        >
          Total{"\u00a0"}
        </Typography>
        <Typography
          style={{
            color: "#1f883d",
            fontWeight: "800",
            fontSize: "14px",
          }}
        >
          CAD${"\u00a0"}
          {formatCurrency(_.chain(orders)
            .map((order) => {
              return {
                ...order,
                ...{
                  totalProceeds: calculateGST(
                    order.noOfSeats,
                    order.ticketPrice
                  ),
                },
              };
            })
            .sumBy("totalProceeds")
            .value())}
        </Typography>
      </Paper>
    );
  }

  renderTable(orders) {
    const { classes } = this.props;
    const { order, sortBy } = this.state;
    const tableHeaders = [
      "Order Number",
      "Date Purchased",
      "Game Date",
      "Seat Details",
      "Price (CAD)",
      "Sold",
      "Proceeds",
      "Status",
    ];
    const sortableColumns = [
      "Date Purchased",
      "Game Date",
    ];

    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow style={{ fontFamily: "Nunito Sans" }}>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  padding={index > 1 ? "checkbox" : "dense"}
                  style={{ fontWeight: "bold", textAlign: index > 1 ? "center" : "left" }}
                  sortDirection={sortBy === header && sortableColumns.includes(header) ? order : false}
                >
                  {sortableColumns.includes(header) ? (
                    <CustomTableSortLabel
                      active={sortBy === header}
                      direction={order}
                      onClick={e => this.handleRequestSort(header)}
                    >
                      <Typography className={classes.headerCell}>
                        {header}
                      </Typography>
                    </CustomTableSortLabel>
                  ) : (
                    <Typography className={classes.headerCell}>
                      {header}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => {
              const { seat } = order;
              const slug = `${seat.gameDetails.homeTeamSlug}-tickets`;
              return (
                <TableRow key={index}>
                  <TableCell className={classes.subTitleText}>
                    <Typography className={classes.subTitleText}>
                      {order.trackingNumber}
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>
                      {moment.tz(Number(order.createdAt), "America/Edmonton").format("MMM DD, YYYY")}
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ padding: "10px 24px" }}>
                    <Typography
                      className={classes.titleText}
                      style={{ color: "#00A0FF", textTransform: "uppercase" }}
                    >
                      {seat.gameDetails.isTbd ? "TBD" : this.getGameDate(seat.gameDetails)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {seat.gameDetails.isTbd ? null : this.getGameTime(seat.gameDetails)}
                    </Typography>
                    <a href={`/seats/${slug}/${seat.gameDetails.id}`}>
                      <Typography className={classes.titleText}>
                        {getGameName(seat.gameDetails)}
                      </Typography>
                    </a>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.titleText}>
                      {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                    </Typography>
                    <Typography className={classes.subTitleText}>
                      Row {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ textAlign: "right" }}>
                    <Typography className={classes.subTitleText}>
                      ${formatCurrency(order.ticketPrice)}
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>
                      {order.noOfSeats}
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ textAlign: "right" }}>
                    <Typography className={classes.subTitleText}>
                      ${formatCurrency(calculateGST(order.noOfSeats, order.ticketPrice))}
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    {order.isSaleCancel ? (
                      <Chip
                        label="Cancelled"
                        variant="outlined"
                        style={{
                          border: "1px solid #ff1744",
                          color: "#ff1744",
                          backgroundColor: 'transparent'
                        }}
                      />
                    ) : (
                      order.isPaid && (
                        <Chip
                          label="Paid"
                          variant="outlined"
                          style={{
                            border: "1px solid #1f883d",
                            color: "#1f883d",
                            backgroundColor: 'transparent'
                          }}
                        />
                      )
                    )}
                    {order.isSent && !order.isSaleCancel && (
                      <Chip
                        label="Delivered"
                        color="primary"
                        variant="outlined"
                        style={{
                          border: "1px solid #1976d2",
                          color: "#1976d2",
                          backgroundColor: 'transparent',
                          marginTop: order.isPaid ? '2px' : '0'
                        }}
                      />
                    )}
                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    const { orders, isLoading, lastEvaluatedSoldKey } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <Typography
            align="center"
            variant="body2"
            style={{ marginTop: "2rem" }}
          >
            <CircularProgress
              color="inherit"
              style={{ width: 16, height: 16, marginRight: 16 }}
            />
            Loading your sold listings...
          </Typography>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {orders.length === 0 && (
          <Typography
            style={{ margin: "2rem 0" }}
            variant="subheading"
            align="center"
            color="textSecondary"
          >
            {/* {!currUser.isSoldOut && !currUser.isHistory && "No Active Listings"}
            {currUser.isSoldOut && !currUser.isHistory && "No Sold Out Listings"}
            {currUser.isHistory && "No Past Listings"} */}
            No Sold Listings
          </Typography>
        )}
        {orders.length > 0 && this.renderTotalSold(orders)}
        {orders.length > 0 && this.renderTable(orders)}
        {lastEvaluatedSoldKey && (
          <Button
            onClick={() => {
              this.setState({ ...this.state, loadMoreSoldLoading: true });
              this.setSeats();
            }}
            disabled={this.state.isLoading || this.state.loadMoreSoldLoading}
            variant="contained"
            style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
            color="secondary"
          >
            {this.state.isLoading || this.state.loadMoreSoldLoading
              ? "Loading..."
              : "Load More"}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SellerSoldOutSeatsTable);
