import React from "react";
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Toolbar,
  withStyles,
} from "@material-ui/core";
import { 
  Dashboard,
  PersonOutline,
  ConfirmationNumber as TicketTrackIcon,
  Receipt,
  List as ListIcon,
} from "@material-ui/icons";
import { getInitialName } from "../../utils/general";

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: 240,
    borderRight: 'none',
  },
  avatarContainer: {
    paddingTop: '24px !important',
    paddingBottom: '8px !important',
  },
  profileAvatar: {
    padding: 35,
    color: '#000',
    backgroundColor: '#EAEFF4',
    fontWeight: '600',
    fontSize: '1.6rem',
  },
  usernameText: {
    fontWeight: '500',
    fontSize: '1.2rem',
  },
  emailText: {
    fontSize: '0.875rem'
  },
  listItemText: {
    padding: '0 16px 0 0'
  },
  activeListItem: {
    backgroundColor: '#2196f3',
  },
  activeIcon: {
    color: '#2196f3',
  },
  activeText: {
    color: '#2196f3',
  },
});

const sideNav = ({ 
  classes, 
  currUser, 
  isAuthenticated, 
  handleLogout, 
  history 
}) => {
  const currPath = history.location.pathname;
  const profilePath = ['/account', '/account/password'];
  const listingPath = ['/listings', '/listings/new', '/sell', '/sell/set-prices']
  const userName = currUser ? currUser.name : '';
  const userEmail = currUser ? currUser.email : '';

  return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          <ListItem className={classes.avatarContainer}>
            <Avatar className={classes.profileAvatar}>{getInitialName(userName)}</Avatar>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary={userName} 
              secondary={userEmail} 
              primaryTypographyProps={{
                classes: {
                  root: classes.usernameText,
                }
              }}
              secondaryTypographyProps={{
                classes: {
                  root: classes.emailText,
                }
              }}
            />
          </ListItem>
        </List>
        <Divider />
        <List component="nav">
          <ListItem
            button
            component="a"
            href="/dashboard"
          >
            <ListItemIcon>
              <Dashboard
                className={currPath === '/dashboard' ? classes.activeIcon : ''}
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              primaryTypographyProps={{
                className: currPath === '/dashboard' ? classes.activeText : '',
              }}
              className={classes.listItemText}  
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/account"
          >
            <ListItemIcon>
              <PersonOutline
                className={profilePath.includes(currPath) ? classes.activeIcon : ''}
              />
            </ListItemIcon>
            <ListItemText
              primary="Profile"
              primaryTypographyProps={{
                className: profilePath.includes(currPath) ? classes.activeText : '',
              }}
              className={classes.listItemText}  
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/track-order"
          >
            <ListItemIcon>
              <TicketTrackIcon
                className={currPath === '/track-order' ? classes.activeIcon : ''}
              />
            </ListItemIcon>
            <ListItemText
              primary="Track Your Tickets"
              primaryTypographyProps={{
                className: currPath === '/track-order' ? classes.activeText : '',
              }}
              className={classes.listItemText}  
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/orders"
          >
            <ListItemIcon>
              <Receipt
                className={currPath === '/orders' ? classes.activeIcon : ''}
              />
            </ListItemIcon>
            <ListItemText
              primary="Orders"
              primaryTypographyProps={{
                className: currPath === '/orders' ? classes.activeText : '',
              }}
              className={classes.listItemText}  
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/listings"
          >
            <ListItemIcon>
              <ListIcon
                className={listingPath.includes(currPath) ? classes.activeIcon : ''}
              />
            </ListItemIcon>
            <ListItemText
              primary="Listings (Sell)"
              primaryTypographyProps={{
                className: listingPath.includes(currPath) ? classes.activeText : '',
              }}
              className={classes.listItemText}  
            />
          </ListItem>
          <Toolbar />
        </List>
      </Drawer>
  );
};

export default withStyles(styles)(sideNav);
