const teamQuestions = {
    "calgary-flames": {
        questions: [
            {
                title: "How can I resell my Calgary Flames tickets?",
                description:
                    "Got extra Flames tickets you can't use? No problem! You can easily resell them on <b>FansFirst.ca</b>. Head to <a href=\"https://www.fansfirst.ca/sell\">www.fansfirst.ca/sell</a>. It’s quick, secure, and connects you with other passionate fans!"
            },
            {
                title: "Where can I buy Calgary Flames tickets?",
                description:
                    "Looking to catch a Calgary Flames game? Grab your tickets easily and securely on <b>FansFirst.ca</b> at <a href=\"https://www.fansfirst.ca/calgary-flames/games\">https://www.fansfirst.ca/calgary-flames/games</a>. Whether you're hunting for last-minute seats or planning ahead, find the perfect spot to cheer on the Flames with other die-hard fans!",
            },
            {
                title: "What are the best ways to find cheap Calgary Flames tickets?",
                description:
                    "Looking to score affordable Calgary Flames tickets? Check out FansFirst.ca for the best deals and secure purchases (<a href=\"https://www.fansfirst.ca/calgary-flames/games\">https://www.fansfirst.ca/calgary-flames/games</a>). Whether you’re hunting for last-minute bargains or planning ahead, FansFirst offers great options to get you into the game without breaking the bank!",
            },
            {
                title: "What is the home arena for the Calgary Flames?",
                description:
                    "The Calgary Flames play their home games at the <b>Scotiabank Saddledome</b>, located in downtown Calgary, Alberta. The Saddledome has been home to the Flames since 1983 and is one of the most recognizable venues in the NHL.",
            },
            {
                title: "What is the seating capacity for Calgary Flames home games?",
                description:
                    "The seating capacity of the Scotiabank Saddledome for Calgary Flames home games is approximately <b>19,289</b>, providing fans with an exciting and energetic game-day atmosphere.",
            },
            {
                title: "Who's the head coach of the Calgary Flames?",
                description:
                    "As of the 2023-2024 season, the head coach of the Calgary Flames is <b>Ryan Huska</b>, who took over in 2023. He brings years of coaching experience and a focus on team development to the organization.",
            },
            {
                title: "When were the Calgary Flames founded?",
                description:
                    "The Calgary Flames were originally founded in <b>1972</b> as the Atlanta Flames. The team relocated to Calgary in <b>1980</b>, where they have been a key part of the city's sports culture ever since.",
            },
            {
                title: "Which division are the Calgary Flames part of?",
                description:
                    "The Calgary Flames compete in the <b>Pacific Division</b> of the <b>Western Conference</b> in the NHL, alongside teams like the Edmonton Oilers, Vancouver Canucks, and Vegas Golden Knights.",
            },
            {
                title: "When do the gates open for Calgary Flames games?",
                description:
                    "Gates at the Scotiabank Saddledome typically open <b>90 minutes</b> before the scheduled start of the game. Fans are encouraged to arrive early to avoid lines and enjoy pre-game activities and concessions.",
            },
            {
                title: "Is a parking pass required for Calgary Flames games?",
                description:
                    "Yes, parking passes are recommended for attending Calgary Flames games at the Scotiabank Saddledome. While limited on-site parking is available, purchasing a pass in advance via the arena’s website is advised to ensure a spot. Alternatively, public transportation options are also available.",
            },
            {
                title: "What is the bag policy for Calgary Flames games?",
                description:
                    "The Scotiabank Saddledome enforces a <b>clear bag policy</b>:<br /><ul><li>Bags must be clear and no larger than <b>14\" x 14\" x 6\"</b>.</li><li>All bags are subject to inspection, and backpacks or large bags are prohibited.</li></ul>For more details on prohibited items and the full bag policy, visit the <a href=\"https://www.scotiabanksaddledome.com\" target=\"_blank\">Scotiabank Saddledome's official site</a>.<br /><a href=\"https://www.scotiabanksaddledome.com/a-to-z-guide/#bags-purses\" target=\"_blank\">https://www.scotiabanksaddledome.com/a-to-z-guide/#bags-purses</a>",
            },
        ]
    }, 
    "edmonton-oilers": {
        questions: [
            {
                title: "How can I resell my Edmonton Oilers tickets?",
                description:
                    "Got extra Oilers tickets you can't use? No problem! You can easily resell them on <b>FansFirst.ca</b>. Head to <a href=\"https://www.fansfirst.ca/sell\">www.fansfirst.ca/sell</a>. It’s quick, secure, and connects you with other passionate fans!"
            },
            {
                title: "Where can I buy Edmonton Oilers tickets?",
                description:
                    "Looking to catch a Edmonton Oilers game? Grab your tickets easily and securely on <b>FansFirst.ca</b> at <a href=\"https://www.fansfirst.ca/edmonton-oilers/game\">https://www.fansfirst.ca/edmonton-oilers/game</a>. Whether you're hunting for last-minute seats or planning ahead, find the perfect spot to cheer on the Flames with other die-hard fans!",
            },
            {
                title: "What are the best ways to find cheap Edmonton Oilers tickets?",
                description:
                    "Looking to score affordable Edmonton Oilers tickets? Check out FansFirst.ca for the best deals and secure purchases (<a href=\"https://www.fansfirst.ca/edmonton-oilers/game\">https://www.fansfirst.ca/edmonton-oilers/game</a>). Whether you’re hunting for last-minute bargains or planning ahead, FansFirst offers great options to get you into the game without breaking the bank!",
            },
            {
                title: "What is the home arena for the Edmonton Oilers?",
                description:
                    "The Edmonton Oilers play their home games at <b>Rogers Place</b>, a state-of-the-art arena located in downtown Edmonton. Opened in 2016, Rogers Place is known for its modern facilities and electric atmosphere during Oilers games.",
            },
            {
                title: "What is the seating capacity for Edmonton Oilers home games?",
                description:
                    "The seating capacity at Rogers Place for Edmonton Oilers home games is approximately <b>18,500</b>, providing an intimate yet vibrant setting for fans to enjoy NHL action.",
            },
            {
                title: "Who's the head coach of the Edmonton Oilers?",
                description:
                    "As of the 2023-2024 season, the head coach of the Edmonton Oilers is <b>Kris Knoblauch</b>, who took over in 2023. Under his leadership, the Oilers have focused on a strong offensive and balanced approach to the game.",
            },
            {
                title: "When were the Edmonton Oilers founded?",
                description:
                    "The Edmonton Oilers were founded in <b>1971</b> as one of the original franchises of the World Hockey Association (WHA). The team joined the National Hockey League (NHL) in <b>1971</b> following the WHA-NHL merger.",
            },
            {
                title: "Which division are the Edmonton Oilers part of?",
                description:
                    "The Edmonton Oilers compete in the <b>Pacific Division</b> of the <b>Western Conference</b> in the NHL, alongside teams like the Edmonton Oilers, Los Angeles Kings, and Vegas Golden Knights.",
            },
            {
                title: "When do the gates open for Edmonton Oilers games?",
                description:
                    "Gates at the Rogers Place typically open <b>90 minutes</b> before the puck drop. Arriving early allows fans to explore the arena, grab refreshments, and enjoy pre-game activities.",
            },
            {
                title: "Is a parking pass required for Edmonton Oilers games?",
                description:
                    "While a <b>parking pass</b> is not strictly required, parking around Rogers Place can be limited. Fans are encouraged to book parking in advance through the Rogers Place website or use nearby lots. Public transit, including the LRT, is also a convenient option for getting to the arena.",
            },
            {
                title: "What is the bag policy for Edmonton Oilers games?",
                description:
                    "Rogers Place has a <b>clear bag policy</b>:<br /><ul><li>Only clear bags up to <b>14\" x 14\" x 6\"</b> are allowed.</li><li>Small clutches or purses no larger than <b>4.5\" x 6.5\"</b> are permitted.</li><li>All bags are subject to inspection upon entry, and backpacks or oversized bags are prohibited.</li><li><u>Guests will be required to return their bags to their vehicles or another secure area prior to admittance.</u></li></ul>For more detailed information, visit the <a href=\"https://rogersarena.com/frequently-asked-questions/\" target=\"_blank\">Rogers Place bag policy page</a>.",
            },
        ]
    }, 
    "winnipeg-jets": {
        questions: [
            {
                title: "How can I resell my Winnipeg Jets tickets?",
                description:
                    "Got extra Jets tickets you can't use? No problem! You can easily resell them on <b>FansFirst.ca</b>. Head to <a href=\"https://www.fansfirst.ca/sell\">www.fansfirst.ca/sell</a>. It’s quick, secure, and connects you with other passionate fans!"
            },
            {
                title: "Where can I buy Winnipeg Jets tickets?",
                description:
                    "Looking to catch a Winnipeg Jets game? Grab your tickets easily and securely on <b>FansFirst.ca</b> at <a href=\"https://www.fansfirst.ca/winnipeg-jets/games\">https://www.fansfirst.ca/winnipeg-jets/games</a>. Whether you're hunting for last-minute seats or planning ahead, find the perfect spot to cheer on the Flames with other die-hard fans!",
            },
            {
                title: "What are the best ways to find cheap Winnipeg Jets tickets?",
                description:
                    "Looking to score affordable Winnipeg Jets tickets? Check out FansFirst.ca for the best deals and secure purchases (<a href=\"https://www.fansfirst.ca/winnipeg-jets/games\">https://www.fansfirst.ca/winnipeg-jets/games</a>). Whether you’re hunting for last-minute bargains or planning ahead, FansFirst offers great options to get you into the game without breaking the bank!",
            },
            {
                title: "What is the home arena for the Winnipeg Jets?",
                description:
                    "The Winnipeg Jets play their home games at <b>Canada Life Centre</b>, located in downtown Winnipeg, Manitoba. This venue has been home to the Jets since the team returned to Winnipeg in 2011.",
            },
            {
                title: "What is the seating capacity for Winnipeg Jets home games?",
                description:
                    "Canada Life Centre has a seating capacity of <b>15,321</b> for Winnipeg Jets home games, providing an intimate and passionate atmosphere for NHL hockey.",
            },
            {
                title: "Who's the head coach of the Winnipeg Jets?",
                description:
                    "As of 2024, the head coach of the Winnipeg Jets is <b>Scott Arniel</b>.",
            },
            {
                title: "When were the Winnipeg Jets founded?",
                description:
                    "The current Winnipeg Jets franchise was founded in <b>1999</b> as the Atlanta Thrashers. The team relocated to Winnipeg in <b>2011</b> and rebranded as the Winnipeg Jets, reviving the city's rich hockey tradition.",
            },
            {
                title: "Which division are the Winnipeg Jets part of?",
                description:
                    "The Winnipeg Jets compete in the <b>Central Division</b> of the NHL’s <b>Western Conference</b>, playing alongside teams like the Colorado Avalanche, Minnesota Wild, and St. Louis Blues.",
            },
            {
                title: "When do the gates open for Winnipeg Jets games?",
                description:
                    "Gates at Canada Life Centre typically open <b>90 minutes</b> before the puck drop. Arriving early allows fans to explore the arena, grab snacks, and watch pre-game warmups.",
            },
            {
                title: "Is a parking pass required for Winnipeg Jets games?",
                description:
                    "While a <b>parking pass</b> is not strictly required, parking near Canada Life Centre is limited, and it is recommended to purchase parking in advance. The venue is easily accessible by public transportation, and various parking lots and garages are available around the downtown area.",
            },
            {
                title: "What is the bag policy for Winnipeg Jets games?",
                description:
                    "Canada Life Centre has a <b>clear bag policy</b>:<br /><ul><li>Only clear bags up to <b>12\" x 9\" x 5\"</b> are allowed.</li><li>Small clutches or purses no larger than <b>6.5\" x 4.5\"</b> are permitted.</li><li>All bags are subject to inspection upon entry, and backpacks or oversized bags are prohibited.</li></ul>For more detailed information, visit the <a href=\"https://www.canadalifecentre.ca/fan-guide/bag-policy/\" target=\"_blank\">Canada Life Centre's policies</a> before attending.",
            },
        ]
    }, 
    "vancouver-canucks": {
        questions: [
            {
                title: "How can I resell my Vancouver Canucks tickets?",
                description:
                    "Got extra Canucks tickets you can't use? No problem! You can easily resell them on <b>FansFirst.ca</b>. Head to <a href=\"https://www.fansfirst.ca/sell\">www.fansfirst.ca/sell</a>. It’s quick, secure, and connects you with other passionate fans!"
            },
            {
                title: "Where can I buy Vancouver Canucks tickets?",
                description:
                    "Looking to catch a Vancouver Canucks game? Grab your tickets easily and securely on <b>FansFirst.ca</b> at <a href=\"https://www.fansfirst.ca/vancouver-canucks/games\">https://www.fansfirst.ca/vancouver-canucks/games</a>. Whether you're hunting for last-minute seats or planning ahead, find the perfect spot to cheer on the Flames with other die-hard fans!",
            },
            {
                title: "What are the best ways to find cheap Vancouver Canucks tickets?",
                description:
                    "Looking to score affordable Vancouver Canucks tickets? Check out FansFirst.ca for the best deals and secure purchases (<a href=\"https://www.fansfirst.ca/vancouver-canucks/games\">https://www.fansfirst.ca/vancouver-canucks/games</a>). Whether you’re hunting for last-minute bargains or planning ahead, FansFirst offers great options to get you into the game without breaking the bank!",
            },
            {
                title: "What is the home arena for the Vancouver Canucks?",
                description:
                    "The Vancouver Canucks play their home games at <b>Rogers Arena</b>, located in downtown Vancouver, British Columbia. Rogers Arena has been home to the Canucks since it opened in 1995.",
            },
            {
                title: "What is the seating capacity for Vancouver Canucks home games?",
                description:
                    "The seating capacity at Rogers Arena for Vancouver Canucks home games is approximately <b>18,910</b>, offering fans a dynamic and exciting environment for watching NHL hockey.",
            },
            {
                title: "Who's the head coach of the Vancouver Canucks?",
                description:
                    "As of the 2023-2024 season, the head coach of the Vancouver Canucks is <b>Rick Tocchet</b>, who took over in 2023. Tocchet brings years of coaching experience and a focus on team development.",
            },
            {
                title: "When were the Vancouver Canucks founded?",
                description:
                    "The Vancouver Canucks were founded in <b>1970</b>, joining the NHL as part of the league's expansion. They are one of Canada's most popular hockey franchises, with a passionate fanbase.",
            },
            {
                title: "Which division are the Vancouver Canucks part of?",
                description:
                    "The Vancouver Canucks compete in the <b>Pacific Division</b> of the NHL’s <b>Western Conference</b>, playing alongside rivals like the Edmonton Oilers, Calgary Flames, and Vegas Golden Knights.",
            },
            {
                title: "When do the gates open for Vancouver Canucks games?",
                description:
                    "Gates at Rogers Arena typically open <b>60 minutes</b> before the scheduled start of the game. Fans are encouraged to arrive early to enjoy the arena amenities, grab food and drinks, and watch the teams warm up.",
            },
            {
                title: "Is a parking pass required for Vancouver Canucks games?",
                description:
                    "While a <b>parking pass</b> is not strictly required, parking around Rogers Arena is limited, and it is recommended to pre-book parking online to secure a spot. The arena is also easily accessible via public transportation, with nearby SkyTrain stations offering a convenient option.",
            },
            {
                title: "What is the bag policy for Vancouver Canucks games?",
                description:
                    "Rogers Arena has a <b>clear bag policy</b> in effect for Vancouver Canucks games:<br /><ul><li>Bags must be no larger than <b>14\" x 14\" x 6\"</b>.</li><li>All bags are subject to inspection, and backpacks or oversized bags are not permitted.</li></ul>For further details, check the <a href=\"https://rogersarena.com\" target=\"_blank\">Rogers Arena bag policy</a> before attending a game.",
            },
        ]
    },
    "ottawa-senators": {
        questions: [
            {
                title: "How can I resell my Ottawa Senators tickets?",
                description:
                    "Got extra Senators tickets you can't use? No problem! You can easily resell them on <b>FansFirst.ca</b>. Head to <a href=\"https://www.fansfirst.ca/sell\">www.fansfirst.ca/sell</a>. It’s quick, secure, and connects you with other passionate fans!"
            },
            {
                title: "Where can I buy Ottawa Senators tickets?",
                description:
                    "Looking to catch a Ottawa Senators game? Grab your tickets easily and securely on <b>FansFirst.ca</b> at <a href=\"https://www.fansfirst.ca/ottawa-senators/games\">https://www.fansfirst.ca/ottawa-senators/games</a>. Whether you're hunting for last-minute seats or planning ahead, find the perfect spot to cheer on the Flames with other die-hard fans!",
            },
            {
                title: "What are the best ways to find cheap Ottawa Senators tickets?",
                description:
                    "Looking to score affordable Ottawa Senators tickets? Check out FansFirst.ca for the best deals and secure purchases (<a href=\"https://www.fansfirst.ca/ottawa-senators/games\">https://www.fansfirst.ca/ottawa-senators/games</a>). Whether you’re hunting for last-minute bargains or planning ahead, FansFirst offers great options to get you into the game without breaking the bank!",
            },
            {
                title: "What is the home arena for the Ottawa Senators?",
                description:
                    "The Ottawa Senators play their home games at <b>Canadian Tire Centre</b>, located in Kanata, a suburb of Ottawa, Ontario. The arena has been the home of the Senators since it opened in 1996.",
            },
            {
                title: "What is the seating capacity for Ottawa Senators home games?",
                description:
                    "The Canadian Tire Centre has a seating capacity of approximately <b>18,652</b> for Ottawa Senators home games, creating an exciting atmosphere for hockey fans.",
            },
            {
                title: "Who's the head coach of the Ottawa Senators?",
                description:
                    "<b>Travis Green</b>, who joined the Ottawa Senators as head coach in 2024, is known for his strategic approach and focus on developing young talent. His leadership aims to foster a fast-paced, disciplined team.",
            },
            {
                title: "When were the Ottawa Senators founded?",
                description:
                    "The current Ottawa Senators franchise was founded in <b>1992</b> as an expansion team, though they share a name and legacy with the original Senators franchise that played from 1883 to 1934.",
            },
            {
                title: "Which division are the Ottawa Senators part of?",
                description:
                    "The Ottawa Senators compete in the <b>Atlantic Division</b> of the NHL’s <b>Eastern Conference</b>, facing rivals like the Toronto Maple Leafs, Montreal Canadiens, and Boston Bruins.",
            },
            {
                title: "When do the gates open for Ottawa Senators games?",
                description:
                    "Gates at the Canadian Tire Centre typically open <b>2 hours</b> before the puck drops. Fans are encouraged to arrive early to enjoy concessions, shop for merchandise, and watch the teams warm up.",
            },
            {
                title: "Is a parking pass required for Ottawa Senators games?",
                description:
                    "While a <b>parking pass</b> is not strictly required, parking is available at Canadian Tire Centre and is recommended to be purchased in advance to secure a spot, especially for high-demand games. Public transportation options are also available for getting to and from the arena.",
            },
            {
                title: "What is the bag policy for Ottawa Senators games?",
                description:
                    "The Canadian Tire Centre follows a <b>clear bag policy</b>:<br /><ul><li>Only small bags are allowed and no larger than <b>4\" x 6\" x 1.5\"</b>.</li><li>All bags are subject to inspection, and backpacks or oversized bags are not permitted.</li></ul>For more details on the bag policy and prohibited items, check the <a href=\"https://www.canadiantirecentre.com\" target=\"_blank\">Canadian Tire Centre website</a> before attending a game.",
            },
        ]
    }, 
    "toronto-maple-leafs": {
        questions: [
            {
                title: "How can I resell my Toronto Maple Leafs tickets?",
                description:
                    "Got extra Leafs tickets you can't use? No problem! You can easily resell them on <b>FansFirst.ca</b>. Head to <a href=\"https://www.fansfirst.ca/sell\">www.fansfirst.ca/sell</a>. It’s quick, secure, and connects you with other passionate fans!"
            },
            {
                title: "Where can I buy Toronto Maple Leafs tickets?",
                description:
                    "Looking to catch a Toronto Maple Leafs game? Grab your tickets easily and securely on <b>FansFirst.ca</b> at <a href=\"https://www.fansfirst.ca/toronto-maple-leafs/games\">https://www.fansfirst.ca/toronto-maple-leafs/games</a>. Whether you're hunting for last-minute seats or planning ahead, find the perfect spot to cheer on the Flames with other die-hard fans!",
            },
            {
                title: "What are the best ways to find cheap Toronto Maple Leafs tickets?",
                description:
                    "Looking to score affordable Toronto Maple Leafs tickets? Check out FansFirst.ca for the best deals and secure purchases (<a href=\"https://www.fansfirst.ca/toronto-maple-leafs/games\">https://www.fansfirst.ca/toronto-maple-leafs/games</a>). Whether you’re hunting for last-minute bargains or planning ahead, FansFirst offers great options to get you into the game without breaking the bank!",
            },
            {
                title: "What is the home arena for the Toronto Maple Leafs?",
                description:
                    "The Toronto Maple Leafs play their home games at <b>Scotiabank Arena</b>, located in downtown Toronto, Ontario. Scotiabank Arena has been home to the Maple Leafs since it opened in 1999.",
            },
            {
                title: "What is the seating capacity for Toronto Maple Leafs home games?",
                description:
                    "Scotiabank Arena has a seating capacity of approximately <b>18,800</b> for Toronto Maple Leafs games, providing an exciting atmosphere for fans.",
            },
            {
                title: "Who's the head coach of the Toronto Maple Leafs?",
                description:
                    "<b>Craig Berube</b> joined the Toronto Maple leafs to become their head coach in 2024. Known for his tough, disciplined style, he emphasizes physicality and teamwork, reflecting his gritty playing career in the NHL.",
            },
            {
                title: "When were the Toronto Maple Leafs founded?",
                description:
                    "The Toronto Maple Leafs were founded in <b>1917</b> as one of the Original Six teams in the NHL. They are one of the league’s most storied and beloved franchises with a rich history in hockey.",
            },
            {
                title: "Which division are the Toronto Maple Leafs part of?",
                description:
                    "The Toronto Maple Leafs compete in the <b>Atlantic Division</b> of the NHL’s <b>Eastern Conference</b>, alongside rivals like the Montreal Canadiens, Boston Bruins, and Ottawa Senators.",
            },
            {
                title: "When do the gates open for Toronto Maple Leafs games?",
                description:
                    "Gates at Scotiabank Arena typically open <b>60 minutes</b> before the puck drops. Fans are encouraged to arrive early to explore the venue, visit concession stands, and watch the players during their pre-game warm up.",
            },
            {
                title: "Is a parking pass required for Toronto Maple Leafs games?",
                description:
                    "A <b>parking pass</b> is not required, but it is highly recommended to book parking in advance due to the limited parking spaces near Scotiabank Arena. Several parking lots and garages are available around the downtown core. Alternatively, public transit options, including the Toronto subway and streetcar system, provide easy access to the arena.",
            },
            {
                title: "What is the bag policy for Toronto Maple Leafs games?",
                description:
                    "Scotiabank Arena has a <b>clear bag policy</b> for all events, including Toronto Maple Leafs games:<br /><ul><li>Only small clutches or purses no larger than <b>6.5\" x 4.5\" x 1.5\"</b> are permitted.</li><li>All bags are subject to inspection, and backpacks or oversized bags are not permitted.</li></ul>For more detailed information on the bag policy and other prohibited items, visit the <a href=\"https://www.scotiabankarena.com\" target=\"_blank\">Scotiabank Arena website</a>.",
            },
        ]
    }, 
    "toronto-raptors": {}, 
    "toronto-blue-jays": {}, 
    "edmonton-elks": {}, 
    "winnipeg-blue-bombers": {}, 
    "toronto-argonauts": {}, 
    "calgary-stampeders": {}, 
    "ottawa-redblacks": {}, 
    "bc-lions": {}, 
    "saskatchewan-roughriders": {}, 
    "hamilton-tigercats": {}, 
    "grey-cup": {}, 
    "calgary-wranglers": {}, 
    "nhl-special-games": {}, 
    "manitoba-moose": {}, 
    "vancouver-whitecaps-fc": {}, 
    "toronto-fc": {}, 
    "world-junior": {}, 
    "abbotsford-canucks": {}, 
    "taylor-swift": {}, 
    "belleville-senators": {}, 
    "toronto-marlies": {},
  };
  export default teamQuestions;