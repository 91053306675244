import React from "react";
import { Typography, withStyles } from "@material-ui/core";

import GamesList from "./games-list";
import TeamSelector from "./team-selector";

const styles = (theme) => ({
  gamesGridContainer: {
    maxWidth: 1366,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
});

const homeGamesList = ({
  classes,
  selectedTeam,
  isLoadingGames,
  games,
  onTeamSelection,
  teIdList,
}) => {
  return (
    <div className={classes.gamesGridContainer}>
      <TeamSelector
        selectedTeam={selectedTeam}
        onTeamSelection={onTeamSelection}
        isLoadingGames={isLoadingGames}
      />
      <GamesList
        games={games[selectedTeam]}
        isLoadingGames={isLoadingGames}
        selectedTeam={selectedTeam}
        teIdList={teIdList}
      />
    </div>
  );
};

export default withStyles(styles)(homeGamesList);
