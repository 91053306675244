const terms = [
  {
    title: "FansFirst User Agreement",
    description:
      'FansFirst and FansFirst Tickets Inc. (the "Company, " "FansFirst, " or the "Site") is a pioneering ticket marketplace whose goal is to provide a cost-effective, efficient platform for buying and selling sporting event tickets.',
  },
  {
    title: "User Agreement and the Terms Therein",
    description:
      "This User Agreement (“Agreement”) describes the terms between you and FansFirst for the buying and selling of tickets, and any other service we provide. By using the Site, you indicate your acceptance of this Agreement. If you do not accept this Agreement, do not use the Site. If we change or modify this Agreement, FansFirst will post a revised version here, which shall automatically replace the previous terms of the Agreement. Your continued use of the Site following a revised Agreement will constitute your acceptance of the revised Agreement. Do not continue to use the Services if you do not agree with the terms of any revised version of the Agreement.",
  },
  {
    title: "The FansFirst Experience",
    description:
      "FansFirst is an online marketplace that allows fans to buy and sell sporting event tickets. Those posting tickets for sale through FansFirst are hereinafter referred to as “Sellers”, and those purchasing tickets through FansFirst are hereinafter referred to as “Buyers”.",
  },
  {
    title: "Service Fees",
    description:
      "FansFirst does not charge a fee to Buyers. We charge Sellers 9% of the list price of the tickets being sold, in addition to any applicable taxes. FansFirst will not alter the Seller’s asking price, but may suggest alternative pricing to reflect the demand for a particular game.",
  },
  {
    title: "Selling Tickets",
    description:
      "By listing tickets on FansFirst, you confirm that you are in possession of the tickets or related passes. To verify the tickets or related passes are in your possession, you must provide details of the seats you hold, along with your email, name, and phone number at www.fansfirst.ca/sell. The Seller is responsible for providing full and accurate information when listing tickets.<br/><br/>When listed tickets sell the Seller will be instructed to electronically transfer the tickets from the Seller's account to FansFirst. The Seller is responsible for fulfilling the sale of the Buyers tickets with the exact tickets listed. If the listed tickets are not available, the Seller must notify us immediately. We reserve the right to remove or cancel any listings for any reason.",
  },

  {
    title: "Multiple Listings on Other Sites",
    description:
      "By listing a ticket(s) for sale on FansFirst, you are making a binding offer to sell that ticket to the Buyer who purchases the ticket. When a Buyer accepts your offer by purchasing your ticket, you are contractually bound to supply that exact ticket for the specified price within the required delivery timeframe.<br/><br/>If you as a Seller list your tickets on FansFirst and another site(s) concurrently, and then sell your tickets elsewhere, or no longer have them in your possession, immediately delete the listing from FansFirst. If your ticket sells on FansFirst but has already been sold elsewhere, and you are unable to complete the sale, please inform us as soon as possible."
  },

  {
    title: "No Seller Guarantee",
    description:
      "FansFirst does not guarantee that your ticket(s) will sell. FansFirst will not provide compensation for tickets that do not sell, even if the Site is unavailable for any reason.",
  },
  {
    title: "Payments to Sellers",
    description:
      "Sellers that have sold tickets through FansFirst will receive payment via Interac email transfer one business day after the game. FansFirst will take a commission equal to 9% plus applicable GST before issuing the payment. If the Buyer reports any issues associated with the sold tickets prior to payment, we will withhold the Sellers' payment pending an investigation. If the Sellers sold fraudulent tickets or otherwise, we will refund the purchase price to the Buyer, and the Seller will not receive any payment, potentially facing criminal charges.",
  },
  {
    title: "Invalid Tickets",
    description:
      "If we receive a report that tickets sold on the Site were not valid for entry, you will be notified by text and email and FansFirst will investigate to determine the cause. If necessary, you will be requested to provide documentation. If the Seller fails to respond to our correspondence and / or to take any other actions required by FansFirst to investigate the invalid ticket claim, criminal charges will be pursued if FansFirst determines the Seller was purposely fraudulent. A response constitutes an acknowledgment of our request and a bona fide effort to investigate the claim and obtain documentation. Failing to respond, and not being able to provide documentation are not the same and FansFirst will work with you closely to resolve the issue.",
  },
  {
    title: "Reporting an Issue",
    description:
      "If you are a Seller and sell tickets, we urge you to report any issues as soon as possible. You can report an issue by emailing us at <a href='mailto:tix@fansfirst.ca'>tix@fansfirst.ca</a>, or by texting <a href='sms:+14037682298'>1-403-768-2298</a>. When reporting an issue, if you are unable to deliver the tickets sold, the sale will be cancelled and you will not receive payment. No additional monetary penalty will be imposed. If you report an issue more than three times within a twelve-month period, FansFirst reserves the right to suspend your usage of the Site. If you are a Buyer, you have until the gates close for the event to report an issue.",
  },
  {
    title: "Game Postponement",
    description:
      "In the unlikely event that a game is postponed and/or rescheduled, and the original tickets remain valid for the new date, both the Buyer and Seller must honor any and all existing sales. FansFirst takes no responsibility for any potential conflicts surrounding the new date. FansFirst will update all existing listings to reflect the new date. Payment for sales of rescheduled games will be made one business day after the new date.",
  },
  {
    title: "Game Cancellation",
    description:
      "In the unlikely event a game is cancelled, FansFirst will remove the ticket(s) and any listings for tickets for the game from the Site. If you sold tickets to a game that is cancelled, we will cancel the sale and you will not be paid. If you had already been paid for the sale, a FansFirst representative will contact you with specifics on how to return the sale proceeds. If you bought tickets to a game that is cancelled, you will be fully refunded.",
  },
  {
    title: "Fraud Protection",
    description:
      "In our effort to protect Buyers, we may call you to verify your account, request proof of ownership of the event tickets or passes,request proof of ownership of event tickets or passes, and screen ticket sales or listings for signs of fraud. This may result in the automatic cancellation of listings or sales and temporary or permanent suspension of accounts. If your transaction is mistakenly canceled or your account is erroneously suspended, please contact us, and we may reinstate your account and/or listing(s).",
  },
  {
    title: "Buying Tickets",
    description:
      "If you place an order to purchase tickets or related passes, we will obtain an authorization from your credit card via ElavonTM for the price of the tickets or related passes. In most cases, the charge for the Total will be withdrawn from the Buyer’s credit card account within seconds of placing the order. The total amount is remitted to FansFirst, and the sale proceeds for the tickets are disbursed to the Seller. Although an authorization is not a charge, credit card and debit card issuers may place a hold on funds when an authorization is obtained. Please contact your financial institution to better understand their policies. In all cases, we are not responsible for any bank fees or other penalties resulting from authorizations and/or charges.",
  },
  {
    title: "FansFirst Guarantee",
    description:
      "In the rare event that you get rejected at the door and it is deemed an issue related to FansFirst, we will at the choice of the Buyer 1) provide you a full refund to your payment method; or 2) secure replacement tickets if suitable alternatives are available. If you are rejected at the door you must notify us immediately by emailing us at <a href='mailto:tix@fansfirst.ca'>tix@fansfirst.ca</a>, or by calling or texting <a href='tel:+14037682298'>1-403-768-2298</a>.",
  },
  {
    title: "Ownership of Intellectual Property Rights and License",
    description:
      "The information and materials that contained within the Site therein, are the property of FansFirst, and are protected from unauthorized copying and dissemination by copyright law, trademark law, and other intellectual property laws. Subject to the terms of this Agreement, FansFirst grants you a non-transferable, non-exclusive, license to use the Site for your use. Nothing in this Agreement gives you a right to use the FansFirst names, trademarks, logos, domain names, and other distinctive brand features without our prior written consent. You shall not attempt to override or circumvent any of the usage rules or restrictions on the Site. Any future release, update, or other addition to functionality of the Site shall be subject to the terms of this Agreement.",
  },
  {
    title: "Feedback",
    description:
      "If you provide FansFirst with any suggestions, comments or other feedback relating to any aspect of the Site, FansFirst may use such Feedback in the Site, or in any other FansFirst products or services. Accordingly, you agree that: 1) FansFirst is not subject to any confidentiality obligations in respect to the Feedback; 2) the feedback is not confidential or proprietary information of you or any third party and you have all of the necessary rights to disclose the feedback to FansFirst; 3) FansFirst (including all of its successors and assigns and any successors and assigns of any of the FansFirst Offerings) may freely use, reproduce, publicize, license, distribute, and otherwise commercialize feedback in any FansFirst offerings; and 4) you are not entitled to receive any compensation or reimbursement of any kind from FansFirst or anyone else in respect of the Feedback.",
  },
  {
    title: "Offline Transactions",
    description:
      "You agree not to use the Site to 1) contact other FansFirst users; 2) invite contact with other FansFirst users; or 3) solicit sales outside of FansFirst.",
  },
  {
    title: "Illegal Activity",
    description:
      "You agree not to use FansFirst for any unlawful purposes or in an unlawful manner. You agree to comply with all applicable local, provincial, federal and international laws, statutes and regulations regarding use of the Site and the listing, selling, buying, and use of tickets and the laws pertaining to pricing your tickets. You acknowledge that FansFirst does not monitor or obtain the face value or total amount paid by the Seller of tickets listed on FansFirst. You agree to pay any and all local, provincial, federal, or international taxes that may be due. You agree not to use, or permit anyone to use, information provided through the Site for any unlawful or unauthorized purpose. You agree not to register under a false name or use an invalid or unauthorized payment method. You agree not to copy, modify, or distribute any content from the Site, including FansFirst copyrights and trademarks, and/or link to FansFirst.ca without FansFirst's prior written permission.",
  },
  {
    title: "Disclosure of Information",
    description:
      "You agree that FansFirst may report any activity that it suspects violates any law or regulation to appropriate law enforcement officials, regulators, or other relevant third parties. FansFirst will cooperate to ensure that violators are prosecuted to the fullest extent of the law. FansFirst will also respond to all inquiries initiated by governmental agencies or as otherwise legally compelled.",
  },
  {
    title: "Modification or Suspension of the Site",
    description:
      "FansFirst reserves the right at any time to modify or discontinue, temporarily or permanently, the Site without notice for any reason. You agree that we shall not be liable to you or any third party for any modification, suspension or discontinuance of the Site. From time to time, the Site may be temporarily unavailable due to maintenance. You agree that we shall not be liable to you or any third party for any losses incurred in connection with unavailability of the Site.",
  },
  {
    title: "Sale of Tickets",
    description:
      "FansFirst does not provide any guarantee that your tickets or related passes will sell and will not provide any compensation for tickets or related passes that do not sell on our Site, including tickets or related passes that go unsold due to a Site outage or maintenance. FansFirst also does not guarantee how long it will take for a listing to appear on the Site after it is posted and is not responsible for unsold tickets or related passes resulting from any listing delays.",
  },
  {
    title: "Disclaimer of Representations, Warranties and Conditions",
    description:
      'The site and all materials provided therein are provided " as is." FansFirst specifically disclaims all representations, warranties and conditions, either express, implied, statutory, by usage of trade, course of dealing or otherwise including but not limited to any implied warranties of merchantability, non-infringement, title, satisfactory quality or fitness for a particular purpose. Any information or material downloaded or otherwise obtained through the use of the service is at your own discretion and risk and you will be solely responsible for any damage to your computer system, loss of data, or any other loss that results from downloading or using any such material.',
  },
  {
    title: "Limitation of Liability",
    description:
      "Under no circumstances shall FansFirst be liable for any direct, indirect, incidental, special, consequential, exemplary or other damages whatsoever, including, without limitation, any damages that result from 1) your use of or your inability to use this site; 2) the cost of procurement of substitute goods, data, information or services; 3) errors, mistakes, or inaccuracies in the materials on the site; 4) personal injury or property damage of any kind whatsoever arising from or relating to your use of the service, any bugs, viruses, trojan horses, or any other files or data that may be harmful to computer or communication equipment or data that may have been transmitted to or through the site; or 5) any errors or omissions in any material on the site or any other loss or damage of any kind arising from or relating to your use of the site. These limitations shall apply even if FansFirst has been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, FansFirst’s is in no way liable to you for any damages arising from or related to this agreement (for any cause whatsoever and regardless of the form of the action). The foregoing limitations shall apply to the fullest extent permitted by law in the applicable jurisdiction.",
  },
  {
    title: "General Indemnity",
    description:
      "You agree to indemnify and hold FansFirst and (if applicable) FansFirst’s parent, subsidiaries, affiliates, and our and their respective officers, directors, attorneys, agents, employees, contractors, licensors and suppliers harmless against any claims, actions, proceedings, demands, damages, liabilities, losses, costs and expenses (including reasonable attorneys' fees) incurred in connection with your breach of this user agreement, your improper use of FansFirst’s site and/or your violation of any law or the rights of a third party.<br/><br/>This Agreement, together with our Privacy Policy constitutes the entire agreement between the parties relating to the Site. This Agreement shall not be modified except in writing signed by both parties or by a new posting of this Agreement by FansFirst. If any part of this Agreement is held to be unlawful, void, or unenforceable, that part shall be deemed severed and shall not affect the validity and enforceability of the remaining provisions. The failure of FansFirst to exercise or enforce any right or provision under this Agreement shall not constitute a waiver of such right or provision. Any waiver of any right or provision by FansFirst must be in writing and shall only apply to the specific instance identified in such writing. No agency, partnership, joint venture, employer-employee or franchisor-franchisee relationship is intended or created by this Agreement. You may not assign this Agreement, or any rights or licenses granted hereunder, whether voluntarily, by operation of law, or otherwise without FansFirst’s prior written consent. FansFirst may assign this Agreement to anyone at any time. You agree that the laws of the Province of Alberta and the Province of Ontario, without regard to principles of conflict of laws, will govern this User Agreement and any claim or dispute that has arisen or may arise between you and FansFirst, except as otherwise stated in the User Agreement.",
  },
];

export default terms;
