import moment from "moment-timezone";
import React, { Component } from "react";
import _ from "lodash";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core";
import { calculateGST } from "../libs/shared/gst-helpers";
import { renderZoneName, renderRowName } from "../libs/shared/seat-helpers";
import { getFormattedTime } from "../libs/shared/grid-helper";
import { getGameName } from "../libs/shared/team-helper";

const styles = (theme) => ({
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    //fontSize: "14px",
  },
  paper: {
    padding: "1.5rem",
    maxHeight: "60vh",
    overflow: "auto",
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  priceInput: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
    fontFamily: "Roboto",
  },
});

class SellHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seats: null,
      isArchiving: false,
      seatArchiving: "",
      isEditPriceOpen: false,
      noOfSeats: "-",
      price: 0,
      isValidNoOfSeats: true,
      isValidPrice: true,
      editingSeat: null,
      isSubmitting: false,
    };
  }

  componentWillMount() {
    const { seats } = this.props;

    this.setState({ seats });
  }

  componentWillReceiveProps(nextProps) {
    const { seats } = nextProps;

    this.setState({ seats });
  }

  getStatusColor(seat) {
    if (seat.isArchived) {
      return "#ff1744";
    } else if (!seat.isArchived && !seat.isSold && seat.seatsSold > 0) {
      return "#2196f3";
    } else {
      return "#1f883d";
    }
  }

  getGameDate(game) {
    return moment.tz(game.date, game.timezone).format("MMM D YYYY");
  }

  getGameTime(game) {
    return getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);
  }

  renderTable(seats) {
    const { classes } = this.props;
    const tableHeaders = [
      "Game",
      "Seat Details",
      "Price (CAD)",
      "Qty",
      "Proceeds",
      "Status",
    ];
  
    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow style={{ fontFamily: "Nunito Sans" }}>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  padding={index > 1 ? "checkbox" : "dense"}
                  style={{ textAlign: index > 0 ? "center" : "left", fontWeight: "bold" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: "#5F666F",
                      fontSize: "14px",
                    }}
                  >
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {seats.map((seat, index) => {
              const slug = `${seat.gameDetails.homeTeamSlug}-tickets`;
              const status = seat.isArchived ? "Deleted" 
                            : !seat.isSold && seat.seatsSold > 0 ? "Partially Sold" 
                            : !seat.isSold && seat.seatsSold === 0 ? "Expired" 
                            : "Sold";
              return (
                <TableRow key={index}>
                  <TableCell style={{ padding: "10px 24px" }}>
                    <Typography
                      className={classes.titleText}
                      style={{ color: "#00A0FF", textTransform: "uppercase" }}
                    >
                      {!seat.gameDetails
                        ? ""
                        : seat.gameDetails.isTbd
                        ? "TBD"
                        : this.getGameDate(seat.gameDetails)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {!seat.gameDetails
                        ? ""
                        : seat.gameDetails.isTbd
                        ? "TBD"
                        : this.getGameTime(seat.gameDetails)}
                    </Typography>
                    <a href={`/seats/${slug}/${seat.gameDetails.id}`}>
                      <Typography className={classes.titleText}>
                        {seat.gameDetails ? getGameName(seat.gameDetails) : "-"}
                      </Typography>
                    </a>
                  </TableCell>

                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.titleText}>
                      {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                    </Typography>
                    <Typography className={classes.subTitleText}>
                      Row {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                    </Typography>
                  </TableCell>
  
                  <TableCell style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>
                      ${seat.price.toFixed(2)}
                    </Typography>
                  </TableCell>
  
                  <TableCell style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>
                      {seat.noOfSeats}
                    </Typography>
                  </TableCell>
  
                  <TableCell style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>
                      {!seat.isArchived
                        ? `$${calculateGST(seat.noOfSeats, seat.price).toFixed(2)}`
                        : "-"}
                    </Typography>
                  </TableCell>
  
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip
                      label={status}
                      variant="outlined"
                      style={{
                        border: `1px solid ${this.getStatusColor(seat)}`,
                        color: this.getStatusColor(seat),
                        backgroundColor: 'transparent'
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    const { seats } = this.state;
    let sortedSeats = _.orderBy(seats, (seat) => seat.game.date, "desc");
    const seatsData = _.chain(sortedSeats).value();

    return (
      <React.Fragment>
        {seatsData.length > 0 && this.renderTable(seatsData)}
        {seatsData.length === 0 && (
          <Typography
            style={{ margin: "2rem 0" }}
            variant="subheading"
            align="center"
            color="textSecondary"
          >
            No Past Listings
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SellHistory);
