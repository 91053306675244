export const getZones = rsr => {
    let zones = [];

    var _100 = { 
      path:
        'M 1036.00,1410.00 C 1036.00,1410.00 1054.00,1410.00 1054.00,1410.00 1054.00,1410.00 1070.00,1411.00 1070.00,1411.00 1070.00,1411.00 1258.00,1411.00 1258.00,1411.00 1258.00,1411.00 1258.00,1804.00 1258.00,1804.00 1258.00,1804.00 1036.00,1804.00 1036.00,1804.00 1036.00,1804.00 1036.00,1410.00 1036.00,1410.00 Z', //replace path data with the path. Make sure it's all on one line and there's no errors.
      attr: {
        id: '_100',
        'data-name': '100',
         opacity: 0,
        fill: '#2196f3',
        stroke: '#d7d7d7',
        'stroke-miterlimit': '10',
        'stroke-width': '0',
         'stroke-opacity': '1',
      },
      transform: "",
      data: { zoneNo: "100", zone: 'Lower Zone', zoneId: "1" }, 
    };

    var _101 = {
        path:'M 811.00,1410.00 C 811.00,1410.00 997.00,1410.00 997.00,1410.00 997.00,1410.00 997.00,1804.00 997.00,1804.00 997.00,1804.00 811.00,1804.00 811.00,1804.00 811.00,1804.00 811.00,1410.00 811.00,1410.00 Z',
        attr: {
          id: '_101',
          'data-name': '101',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "101", zone: 'Lower Zone', zoneId: "1" },
      };
      var _102 = {
        path:'M 636.00,1404.00 C 655.92,1409.77 664.05,1408.97 684.00,1409.00 684.00,1409.00 700.00,1410.00 700.00,1410.00 700.00,1410.00 772.00,1410.00 772.00,1410.00 772.00,1410.00 772.00,1805.00 772.00,1805.00 772.00,1805.00 670.00,1805.00 670.00,1805.00 670.00,1805.00 655.00,1806.00 655.00,1806.00 655.00,1806.00 590.00,1806.00 590.00,1806.00 590.00,1806.00 573.00,1805.00 573.00,1805.00 557.56,1804.98 538.18,1802.02 523.00,1798.79   523.00,1798.79 488.00,1790.00 488.00,1790.00 488.00,1790.00 503.58,1749.00 503.58,1749.00 503.58,1749.00 538.54,1658.00 538.54,1658.00 538.54,1658.00 599.91,1497.00 599.91,1497.00 599.91,1497.00 611.54,1468.00 611.54,1468.00 611.54,1468.00 636.00,1404.00 636.00,1404.00 Z',
        attr: {
          id: '_102',
          'data-name': '102',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "102", zone: 'Lower Zone', zoneId: "1" },
      };
      var _103 = {
        path:
          'M 546.00,1345.00 C 552.70,1349.79 557.91,1356.33 564.00,1361.83   574.53,1371.35 587.61,1381.07 600.00,1388.00 600.00,1388.00 584.58,1428.00 584.58,1428.00 584.58,1428.00 577.46,1445.00 577.46,1445.00 577.46,1445.00 560.58,1490.00 560.58,1490.00 560.58,1490.00 549.46,1518.00 549.46,1518.00 549.46,1518.00 539.58,1545.00 539.58,1545.00 539.58,1545.00 501.58,1644.00 501.58,1644.00 501.58,1644.00 490.46,1672.00 490.46,1672.00 490.46,1672.00 467.09,1734.00 467.09,1734.00 467.09,1734.00 451.00,1776.00 451.00,1776.00 435.73,1771.88 396.88,1751.34 382.00,1742.85   342.86,1720.52 307.38,1693.31 274.00,1663.17   260.12,1650.64 230.21,1620.70 220.00,1606.00 220.00,1606.00 245.00,1585.28 245.00,1585.28   245.00,1585.28 259.00,1574.91 259.00,1574.91   259.00,1574.91 297.00,1543.82 297.00,1543.82   297.00,1543.82 307.00,1536.71 307.00,1536.71   307.00,1536.71 334.00,1514.28 334.00,1514.28   334.00,1514.28 408.00,1455.72 408.00,1455.72   408.00,1455.72 445.00,1425.45 445.00,1425.45   445.00,1425.45 457.00,1416.71 457.00,1416.71   457.00,1416.71 478.00,1399.28 478.00,1399.28   478.00,1399.28 512.00,1372.72 512.00,1372.72   512.00,1372.72 546.00,1345.00 546.00,1345.00 Z',
        attr: {
          id: '_103',
          'data-name': '103',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "103", zone: 'Lower Zone', zoneId: "1" },
      };
      var _104 = {
        path: 'M 89.00,1293.00  C 89.00,1293.00 130.00,1289.17 130.00,1289.17    130.00,1289.17 168.00,1285.83 168.00,1285.83    168.00,1285.83 200.00,1282.17 200.00,1282.17    200.00,1282.17 328.00,1269.83 328.00,1269.83    328.00,1269.83 360.00,1266.17 360.00,1266.17    360.00,1266.17 379.00,1264.83 379.00,1264.83    379.00,1264.83 391.00,1263.17 391.00,1263.17    391.00,1263.17 418.00,1260.83 418.00,1260.83    418.00,1260.83 430.00,1259.17 430.00,1259.17    430.00,1259.17 468.00,1255.83 468.00,1255.83    468.00,1255.83 497.00,1253.00 497.00,1253.00  498.00,1264.95 506.54,1285.33 512.31,1296.00  514.14,1299.39 521.83,1310.58 520.71,1313.82    519.93,1316.05 515.91,1318.35 514.00,1319.75    514.00,1319.75 499.00,1332.08 499.00,1332.08    499.00,1332.08 438.00,1381.08 438.00,1381.08    438.00,1381.08 417.00,1397.28 417.00,1397.28    417.00,1397.28 396.00,1414.72 396.00,1414.72    396.00,1414.72 362.00,1441.28 362.00,1441.28    362.00,1441.28 341.00,1458.72 341.00,1458.72    341.00,1458.72 306.00,1486.09 306.00,1486.09    306.00,1486.09 285.00,1503.55 285.00,1503.55    285.00,1503.55 273.00,1512.29 273.00,1512.29    273.00,1512.29 252.00,1529.72 252.00,1529.72    252.00,1529.72 218.00,1556.28 218.00,1556.28    218.00,1556.28 195.00,1575.00 195.00,1575.00  195.00,1575.00 177.00,1548.00 177.00,1548.00  177.00,1548.00 152.26,1506.00 152.26,1506.00  118.43,1440.44 94.98,1366.77 89.00,1293.00 Z',
        attr: {
          id: '_104',
          'data-name': '104',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "104", zone: 'Lower Zone', zoneId: "1" },
      };
      var _105 = {
        path:'M 91.00,977.00 C 91.00,977.00 116.00,978.83 116.00,978.83   116.00,978.83 140.00,980.83 140.00,980.83   140.00,980.83 217.00,987.17 217.00,987.17   217.00,987.17 229.00,988.83 229.00,988.83   229.00,988.83 260.00,991.17 260.00,991.17   260.00,991.17 412.00,1004.17 412.00,1004.17   412.00,1004.17 494.00,1011.00 494.00,1011.00 494.00,1011.00 494.00,1205.00 494.00,1205.00 494.00,1205.00 493.01,1212.26 493.01,1212.26   493.01,1212.26 487.00,1214.15 487.00,1214.15   487.00,1214.15 472.00,1215.58 472.00,1215.58   472.00,1215.58 444.00,1218.17 444.00,1218.17   444.00,1218.17 400.00,1222.85 400.00,1222.85   400.00,1222.85 354.00,1227.17 354.00,1227.17   354.00,1227.17 322.00,1230.83 322.00,1230.83   322.00,1230.83 194.00,1243.17 194.00,1243.17   194.00,1243.17 162.00,1246.83 162.00,1246.83   162.00,1246.83 124.00,1250.17 124.00,1250.17   124.00,1250.17 111.00,1252.06 111.00,1252.06   111.00,1252.06 103.00,1252.06 103.00,1252.06   103.00,1252.06 87.00,1254.00 87.00,1254.00 87.00,1254.00 87.00,1177.00 87.00,1177.00 87.00,1177.00 88.00,1162.00 88.00,1162.00 88.00,1162.00 88.00,1117.00 88.00,1117.00 88.00,1117.00 89.00,1100.00 89.00,1100.00 89.00,1100.00 89.00,1061.00 89.00,1061.00 89.00,1061.00 90.00,1044.00 90.00,1044.00 90.00,1044.00 90.00,1007.00 90.00,1007.00 90.00,1007.00 91.00,992.00 91.00,992.00 91.00,992.00 91.00,977.00 91.00,977.00 Z',
        attr: {
          id: '_105',
          'data-name': '105',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "105", zone: 'Lower Zone', zoneId: "1" },
      };
      var _106 = {
        path: 'M 194.00,656.00  C 201.60,658.24 216.87,672.09 224.00,677.72    224.00,677.72 236.00,686.50 236.00,686.50  236.00,686.50 253.00,700.50 253.00,700.50  253.00,700.50 286.00,726.25 286.00,726.25    286.00,726.25 310.00,744.50 310.00,744.50  310.00,744.50 326.00,757.71 326.00,757.71    326.00,757.71 337.00,765.66 337.00,765.66    337.00,765.66 394.00,810.71 394.00,810.71    394.00,810.71 405.00,818.66 405.00,818.66    405.00,818.66 431.00,839.71 431.00,839.71    431.00,839.71 441.00,846.90 441.00,846.90  441.00,846.90 453.00,856.72 453.00,856.72    453.00,856.72 501.00,894.41 501.00,894.41    504.59,897.39 516.70,905.56 518.36,908.17    520.90,912.18 516.16,918.19 514.25,922.00  508.75,932.91 503.87,943.21 500.42,955.00  499.60,957.82 497.00,969.68 495.27,971.04    493.44,972.37 489.25,971.25 487.00,971.04    487.00,971.04 467.00,969.42 467.00,969.42    467.00,969.42 435.00,966.83 435.00,966.83    435.00,966.83 394.00,963.09 394.00,963.09    394.00,963.09 313.00,956.17 313.00,956.17    313.00,956.17 247.00,950.83 247.00,950.83    247.00,950.83 92.00,937.00 92.00,937.00  92.00,937.00 96.27,901.00 96.27,901.00  99.23,880.44 103.60,860.09 108.88,840.00  126.34,773.60 156.04,712.94 194.00,656.00 Z',
        attr: {
          id: '_106',
          'data-name': '106',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "106", zone: 'Lower Zone', zoneId: "1" },
      };
      var _107 = {
        path:'M 219.00,624.00 C 221.70,617.11 228.97,609.69 233.87,604.00 233.87,604.00 267.00,569.00 267.00,569.00 309.14,526.86 360.82,491.19 415.00,466.31   415.00,466.31 452.00,451.00 452.00,451.00 452.00,451.00 467.46,492.00 467.46,492.00 467.46,492.00 482.95,532.00 482.95,532.00 482.95,532.00 504.46,591.00 504.46,591.00 504.46,591.00 509.09,602.00 509.09,602.00 509.09,602.00 534.46,672.00 534.46,672.00 534.46,672.00 567.09,759.00 567.09,759.00 567.09,759.00 586.42,811.00 586.42,811.00 586.42,811.00 597.00,840.00 597.00,840.00 584.76,845.30 570.12,856.28 560.00,865.00 557.25,867.37 547.89,876.69 545.00,876.66   542.69,876.64 539.80,873.86 538.00,872.50 538.00,872.50 522.00,860.50 522.00,860.50 522.00,860.50 502.00,844.29 502.00,844.29   502.00,844.29 481.00,828.50 481.00,828.50 481.00,828.50 464.00,815.14 464.00,815.14   464.00,815.14 434.00,791.28 434.00,791.28   434.00,791.28 422.00,782.50 422.00,782.50 422.00,782.50 397.00,762.28 397.00,762.28   397.00,762.28 385.00,753.50 385.00,753.50 385.00,753.50 365.00,737.50 365.00,737.50 365.00,737.50 354.00,729.50 354.00,729.50 354.00,729.50 337.00,715.50 337.00,715.50 337.00,715.50 316.00,699.72 316.00,699.72   316.00,699.72 300.00,686.50 300.00,686.50 300.00,686.50 290.00,679.34 290.00,679.34   290.00,679.34 264.00,658.29 264.00,658.29   264.00,658.29 243.00,642.50 243.00,642.50 235.01,636.30 227.86,628.98 219.00,624.00 Z',
        attr: {
          id: '_107',
          'data-name': '107',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "107", zone: 'Lower Zone', zoneId: "1" },
      };
      var _108 = {
        path:'M 491.00,438.00 C 491.00,438.00 524.00,430.21 524.00,430.21 552.62,424.13 581.81,420.23 611.00,418.96 611.00,418.96 624.00,418.00 624.00,418.00 624.00,418.00 750.00,418.00 750.00,418.00 750.00,418.00 750.00,812.00 750.00,812.00 725.93,812.00 701.91,811.30 678.00,814.28   678.00,814.28 650.00,819.37 650.00,819.37   650.00,819.37 633.00,824.00 633.00,824.00 633.00,824.00 622.54,795.00 622.54,795.00 622.54,795.00 601.91,738.00 601.91,738.00 601.91,738.00 596.54,725.00 596.54,725.00 596.54,725.00 560.91,628.00 560.91,628.00 560.91,628.00 555.54,615.00 555.54,615.00 555.54,615.00 523.54,528.00 523.54,528.00 523.54,528.00 501.54,469.00 501.54,469.00 501.54,469.00 491.00,438.00 491.00,438.00 Z',
        attr: {
          id: '_108',
          'data-name': '108',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "108", zone: 'Lower Zone', zoneId: "1" },
      };
      var _109 = {
        path:'M 789.00,418.00 C 789.00,418.00 869.00,418.00 869.00,418.00 869.00,418.00 884.00,419.00 884.00,419.00 884.00,419.00 997.00,419.00 997.00,419.00 997.00,419.00 997.00,812.00 997.00,812.00 997.00,812.00 789.00,812.00 789.00,812.00 789.00,812.00 789.00,418.00 789.00,418.00 Z',
        attr: {
          id: '_109',
          'data-name': '109',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "109", zone: 'Lower Zone', zoneId: "1" },
      };
      var _110 = {
        path:'M 1036.00,419.00 C 1036.00,419.00 1136.00,419.00 1136.00,419.00 1136.00,419.00 1151.00,420.00 1151.00,420.00 1151.00,420.00 1260.00,420.00 1260.00,420.00 1260.00,420.00 1260.00,812.00 1260.00,812.00 1260.00,812.00 1036.00,812.00 1036.00,812.00 1036.00,812.00 1036.00,419.00 1036.00,419.00 Z',
        attr: {
          id: '_110',
          'data-name': '110',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "110", zone: 'Lower Zone', zoneId: "1" },
      };
      var _111 = {
        path:'M 1299.00,420.00 C 1299.00,420.00 1403.00,420.00 1403.00,420.00 1403.00,420.00 1418.00,421.00 1418.00,421.00 1418.00,421.00 1522.00,421.00 1522.00,421.00 1522.00,421.00 1522.00,812.00 1522.00,812.00 1522.00,812.00 1299.00,812.00 1299.00,812.00 1299.00,812.00 1299.00,420.00 1299.00,420.00 Z',
        attr: {
          id: '_111',
          'data-name': '111',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "111", zone: 'Lower Zone', zoneId: "1" },
      };
      var _112 = {
        path:'M 1561.00,421.00 C 1561.00,421.00 1668.00,421.00 1668.00,421.00 1668.00,421.00 1683.00,422.00 1683.00,422.00 1683.00,422.00 1695.00,422.00 1695.00,422.00 1695.00,422.00 1738.00,426.42 1738.00,426.42   1738.00,426.42 1781.00,434.00 1781.00,434.00 1781.00,434.00 1771.97,469.00 1771.97,469.00 1771.97,469.00 1768.43,479.00 1768.43,479.00 1768.43,479.00 1760.09,510.00 1760.09,510.00 1760.09,510.00 1756.44,520.00 1756.44,520.00 1756.44,520.00 1747.09,555.00 1747.09,555.00 1747.09,555.00 1743.44,565.00 1743.44,565.00 1743.44,565.00 1735.58,595.00 1735.58,595.00 1735.58,595.00 1729.72,613.00 1729.72,613.00 1729.72,613.00 1722.09,642.00 1722.09,642.00 1722.09,642.00 1718.44,652.00 1718.44,652.00 1718.44,652.00 1709.09,687.00 1709.09,687.00 1709.09,687.00 1705.44,697.00 1705.44,697.00 1705.44,697.00 1697.85,726.00 1697.85,726.00 1697.85,726.00 1693.14,740.00 1693.14,740.00 1693.14,740.00 1684.09,774.00 1684.09,774.00 1684.09,774.00 1680.75,783.00 1680.75,783.00 1680.75,783.00 1669.00,823.00 1669.00,823.00 1645.31,815.11 1630.78,813.04 1606.00,813.00 1606.00,813.00 1590.00,812.00 1590.00,812.00 1590.00,812.00 1561.00,812.00 1561.00,812.00 1561.00,812.00 1561.00,421.00 1561.00,421.00 Z',
        attr: {
          id: '_112',
          'data-name': '112',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "112", zone: 'Lower Zone', zoneId: "1" },
      };
      var _113 = {
        path:'M 1706.00,840.00 C 1706.00,840.00 1709.12,829.00 1709.12,829.00 1709.12,829.00 1717.43,798.00 1717.43,798.00 1717.43,798.00 1720.97,788.00 1720.97,788.00 1720.97,788.00 1729.43,756.00 1729.43,756.00 1729.43,756.00 1733.57,744.00 1733.57,744.00 1733.57,744.00 1741.91,713.00 1741.91,713.00 1741.91,713.00 1745.56,703.00 1745.56,703.00 1745.56,703.00 1753.42,673.00 1753.42,673.00 1753.42,673.00 1759.57,654.00 1759.57,654.00 1759.57,654.00 1766.91,626.00 1766.91,626.00 1766.91,626.00 1770.56,616.00 1770.56,616.00 1770.56,616.00 1779.91,581.00 1779.91,581.00 1779.91,581.00 1783.25,572.00 1783.25,572.00 1783.25,572.00 1788.87,551.00 1788.87,551.00 1788.87,551.00 1793.22,535.00 1793.22,535.00 1793.22,535.00 1796.56,526.00 1796.56,526.00 1796.56,526.00 1804.72,495.00 1804.72,495.00 1804.72,495.00 1811.12,475.00 1811.12,475.00 1811.12,475.00 1816.63,454.00 1816.63,454.00 1817.16,451.89 1817.96,447.31 1819.72,446.01   1822.43,444.01 1834.61,448.56 1838.00,449.67   1838.00,449.67 1891.00,469.28 1891.00,469.28   1945.71,492.83 1996.76,522.91 2042.00,561.87   2055.05,573.11 2079.46,594.66 2089.00,608.00 2089.00,608.00 2049.00,641.57 2049.00,641.57   2049.00,641.57 1987.00,693.72 1987.00,693.72   1987.00,693.72 1937.00,735.43 1937.00,735.43   1937.00,735.43 1845.00,813.08 1845.00,813.08   1845.00,813.08 1783.00,865.25 1783.00,865.25   1783.00,865.25 1767.00,878.59 1767.00,878.59   1765.31,879.98 1762.27,882.83 1760.01,882.81   1757.00,882.77 1747.62,871.59 1745.00,869.00 1736.00,860.11 1717.30,845.51 1706.00,840.00 Z',
        attr: {
          id: '_113',
          'data-name': '113',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "113", zone: 'Lower Zone', zoneId: "1" },
      };
      var _114 = {
        path:'M 2257.00,934.00 C 2257.00,934.00 2247.00,934.91 2247.00,934.91   2247.00,934.91 2205.00,938.83 2205.00,938.83   2205.00,938.83 2116.00,946.17 2116.00,946.17   2116.00,946.17 2103.00,947.83 2103.00,947.83   2103.00,947.83 2003.00,956.17 2003.00,956.17   2003.00,956.17 1990.00,957.83 1990.00,957.83   1990.00,957.83 1920.00,963.83 1920.00,963.83   1920.00,963.83 1889.00,966.17 1889.00,966.17   1889.00,966.17 1877.00,967.83 1877.00,967.83   1877.00,967.83 1846.00,970.17 1846.00,970.17   1846.00,970.17 1806.00,973.83 1806.00,973.83   1798.27,974.43 1799.31,971.25 1797.12,962.00 1794.94,952.84 1792.27,943.76 1788.80,935.00 1787.71,932.25 1783.00,922.07 1782.95,920.00 1782.87,917.03 1784.99,915.25 1787.02,913.43   1787.02,913.43 1842.00,867.57 1842.00,867.57   1842.00,867.57 2011.00,725.25 2011.00,725.25   2011.00,725.25 2077.00,669.72 2077.00,669.72   2077.00,669.72 2101.00,649.25 2101.00,649.25   2105.66,645.36 2110.52,640.54 2116.00,638.00 2116.00,638.00 2137.98,668.00 2137.98,668.00 2154.45,691.90 2169.27,717.04 2182.25,743.00 2182.25,743.00 2200.00,783.00 2200.00,783.00 2203.33,782.33 2213.45,778.88 2215.78,779.69   2218.54,780.65 2220.51,786.40 2221.75,789.00 2226.20,798.36 2229.40,808.19 2232.67,818.00 2239.99,839.98 2246.06,865.14 2249.92,888.00 2249.92,888.00 2253.72,911.00 2253.72,911.00 2253.72,911.00 2257.00,934.00 2257.00,934.00 Z',
        attr: {
          id: '_114',
          'data-name': '114',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "114", zone: 'Lower Zone', zoneId: "1" },
      };
      var _115 = {
        path:'M 1803.00,1212.00 C 1803.00,1212.00 1803.00,1142.00 1803.00,1142.00 1803.00,1142.00 1802.00,1126.00 1802.00,1126.00 1802.00,1126.00 1802.00,1058.00 1802.00,1058.00 1802.00,1058.00 1803.00,1043.00 1803.00,1043.00 1803.00,1043.00 1803.00,1022.00 1803.00,1022.00 1803.00,1022.00 1804.02,1014.60 1804.02,1014.60 1804.02,1014.60 1811.00,1012.91 1811.00,1012.91   1811.00,1012.91 1829.00,1011.17 1829.00,1011.17   1829.00,1011.17 1883.00,1006.83 1883.00,1006.83   1883.00,1006.83 1895.00,1005.17 1895.00,1005.17   1895.00,1005.17 1915.00,1003.72 1915.00,1003.72   1915.00,1003.72 1996.00,996.83 1996.00,996.83   1996.00,996.83 2009.00,995.17 2009.00,995.17   2009.00,995.17 2051.00,991.83 2051.00,991.83   2051.00,991.83 2157.00,982.17 2157.00,982.17   2157.00,982.17 2211.00,977.83 2211.00,977.83   2211.00,977.83 2235.00,975.17 2235.00,975.17   2235.00,975.17 2259.00,973.00 2259.00,973.00 2259.00,973.00 2259.00,1262.00 2259.00,1262.00 2259.00,1262.00 2237.00,1259.28 2237.00,1259.28   2237.00,1259.28 2210.00,1256.83 2210.00,1256.83   2210.00,1256.83 2190.00,1254.17 2190.00,1254.17   2190.00,1254.17 2081.00,1242.83 2081.00,1242.83   2081.00,1242.83 2034.00,1237.17 2034.00,1237.17   2034.00,1237.17 1953.00,1228.83 1953.00,1228.83   1953.00,1228.83 1905.00,1223.17 1905.00,1223.17   1905.00,1223.17 1803.00,1212.00 1803.00,1212.00 Z',
        attr: {
          id: '_115',
          'data-name': '115',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "115", zone: 'Lower Zone', zoneId: "1" },
      };
      var _116 = {
        path:'M 1800.00,1252.00 C 1800.00,1252.00 1844.00,1256.94 1844.00,1256.94   1844.00,1256.94 1852.00,1256.94 1852.00,1256.94   1852.00,1256.94 1873.00,1259.83 1873.00,1259.83   1873.00,1259.83 1899.00,1262.17 1899.00,1262.17   1899.00,1262.17 1919.00,1264.83 1919.00,1264.83   1919.00,1264.83 2156.00,1290.17 2156.00,1290.17   2156.00,1290.17 2177.00,1292.83 2177.00,1292.83   2177.00,1292.83 2230.00,1298.17 2230.00,1298.17   2230.00,1298.17 2256.00,1301.00 2256.00,1301.00 2251.99,1331.23 2243.77,1367.55 2235.58,1397.00 2235.58,1397.00 2224.00,1434.00 2224.00,1434.00 2224.00,1434.00 2206.00,1428.00 2206.00,1428.00 2203.68,1437.89 2197.07,1450.56 2192.86,1460.00 2182.08,1484.19 2160.49,1521.46 2145.14,1543.00 2145.14,1543.00 2127.99,1567.00 2127.99,1567.00 2126.28,1569.21 2122.76,1574.65 2119.91,1574.90 2117.53,1575.10 2113.89,1572.20 2112.00,1570.86   2112.00,1570.86 2094.00,1556.59 2094.00,1556.59   2094.00,1556.59 2024.00,1500.08 2024.00,1500.08   2024.00,1500.08 2003.00,1483.91 2003.00,1483.91   2003.00,1483.91 1977.00,1462.28 1977.00,1462.28   1977.00,1462.28 1936.00,1429.91 1936.00,1429.91   1936.00,1429.91 1910.00,1408.29 1910.00,1408.29   1910.00,1408.29 1900.00,1401.18 1900.00,1401.18   1900.00,1401.18 1876.00,1381.28 1876.00,1381.28   1876.00,1381.28 1845.00,1356.75 1845.00,1356.75   1845.00,1356.75 1829.00,1343.92 1829.00,1343.92   1829.00,1343.92 1803.00,1322.98 1803.00,1322.98   1803.00,1322.98 1788.00,1311.08 1788.00,1311.08   1786.19,1309.56 1783.04,1307.19 1782.23,1304.96   1780.97,1301.49 1786.12,1293.42 1787.69,1290.00 1792.84,1278.76 1797.71,1264.15 1800.00,1252.00 Z',
        attr: {
          id: '_116',
          'data-name': '116',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "116", zone: 'Lower Zone', zoneId: "1" },
      };
      var _117 = {
        path:'M 1758.00,1338.00 C 1758.00,1338.00 1770.00,1347.50 1770.00,1347.50 1770.00,1347.50 1782.00,1356.28 1782.00,1356.28   1782.00,1356.28 1808.00,1377.91 1808.00,1377.91   1808.00,1377.91 1849.00,1410.28 1849.00,1410.28   1849.00,1410.28 1875.00,1431.91 1875.00,1431.91   1875.00,1431.91 1888.00,1441.45 1888.00,1441.45   1888.00,1441.45 1931.00,1476.72 1931.00,1476.72   1931.00,1476.72 1951.00,1492.09 1951.00,1492.09   1951.00,1492.09 1977.00,1513.71 1977.00,1513.71   1977.00,1513.71 1988.00,1521.66 1988.00,1521.66   1988.00,1521.66 2008.00,1538.25 2008.00,1538.25   2008.00,1538.25 2071.00,1588.92 2071.00,1588.92   2071.00,1588.92 2087.00,1601.72 2087.00,1601.72   2088.57,1602.97 2092.04,1605.22 2092.55,1607.18   2093.51,1610.90 2083.83,1619.11 2081.28,1622.00 2068.08,1636.98 2052.15,1651.08 2037.00,1664.13   1990.37,1704.28 1934.17,1738.53 1877.00,1761.40 1861.59,1767.56 1831.59,1778.85 1816.00,1782.00 1816.00,1782.00 1808.63,1757.00 1808.63,1757.00 1808.63,1757.00 1801.56,1730.00 1801.56,1730.00 1801.56,1730.00 1797.91,1720.00 1797.91,1720.00 1797.91,1720.00 1787.56,1681.00 1787.56,1681.00 1787.56,1681.00 1783.91,1671.00 1783.91,1671.00 1783.91,1671.00 1773.56,1632.00 1773.56,1632.00 1773.56,1632.00 1769.91,1622.00 1769.91,1622.00 1769.91,1622.00 1759.85,1584.00 1759.85,1584.00 1759.85,1584.00 1755.91,1573.00 1755.91,1573.00 1755.91,1573.00 1745.85,1535.00 1745.85,1535.00 1745.85,1535.00 1741.91,1524.00 1741.91,1524.00 1741.91,1524.00 1731.85,1486.00 1731.85,1486.00 1731.85,1486.00 1727.91,1475.00 1727.91,1475.00 1727.91,1475.00 1718.56,1440.00 1718.56,1440.00 1718.56,1440.00 1714.72,1429.00 1714.72,1429.00 1714.72,1429.00 1708.12,1404.00 1708.12,1404.00 1708.12,1404.00 1703.00,1385.00 1703.00,1385.00 1726.05,1372.06 1740.14,1356.72 1758.00,1338.00 Z',
        attr: {
          id: '_117',
          'data-name': '117',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "117", zone: 'Lower Zone', zoneId: "1" },
      };
      var _118 = {
        path:'M 1536.00,1411.00 C 1536.00,1411.00 1614.00,1411.00 1614.00,1411.00 1624.64,1410.98 1640.56,1408.59 1651.00,1406.12   1651.00,1406.12 1667.00,1402.00 1667.00,1402.00 1667.00,1402.00 1683.58,1461.00 1683.58,1461.00 1683.58,1461.00 1690.43,1487.00 1690.43,1487.00 1690.43,1487.00 1697.58,1510.00 1697.58,1510.00 1697.58,1510.00 1705.44,1540.00 1705.44,1540.00 1705.44,1540.00 1709.09,1550.00 1709.09,1550.00 1709.09,1550.00 1719.44,1589.00 1719.44,1589.00 1719.44,1589.00 1723.09,1599.00 1723.09,1599.00 1723.09,1599.00 1733.15,1637.00 1733.15,1637.00 1733.15,1637.00 1737.09,1648.00 1737.09,1648.00 1737.09,1648.00 1747.44,1687.00 1747.44,1687.00 1747.44,1687.00 1751.09,1697.00 1751.09,1697.00 1751.09,1697.00 1761.44,1736.00 1761.44,1736.00 1761.44,1736.00 1764.78,1745.00 1764.78,1745.00 1764.78,1745.00 1778.00,1792.00 1778.00,1792.00 1760.29,1795.33 1723.74,1802.97 1707.00,1803.00 1707.00,1803.00 1680.00,1803.00 1680.00,1803.00 1680.00,1803.00 1663.00,1804.00 1663.00,1804.00 1663.00,1804.00 1536.00,1804.00 1536.00,1804.00 1536.00,1804.00 1536.00,1411.00 1536.00,1411.00 Z',
        attr: {
          id: '_118',
          'data-name': '118',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "118", zone: 'Lower Zone', zoneId: "1" },
      };
      var _119 = {
        path:'M 1297.00,1411.00 C 1297.00,1411.00 1497.00,1411.00 1497.00,1411.00 1497.00,1411.00 1497.00,1804.00 1497.00,1804.00 1497.00,1804.00 1297.00,1804.00 1297.00,1804.00 1297.00,1804.00 1297.00,1411.00 1297.00,1411.00 Z',
        attr: {
          id: '_119',
          'data-name': '119',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "119", zone: 'Lower Zone', zoneId: "1" },
      };

     

zones.push(
_100,
_101,
_102,
_103,
_104,
_105,
_106,
_107,
_108,
_109,
_110,
_111,
_112,
_113,
_114,
_115,
_116,
_117,
_118,
_119
);
return zones;
};