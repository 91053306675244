import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Helmet from "react-helmet";
import { styles as maintenanceStyles } from "./styles/theme";

import { Grid, Typography, Hidden } from "@material-ui/core";
import Illustration from "../../images/banners/under-maintenance.png";

const styles = (theme) => maintenanceStyles(theme);

class Maintenance extends Component {
  constructor(props) {
    super();
  }

  renderBanner() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.bannerContainer}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.bannerImageContainer}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Illustration}
              className={classes.bannerImage}
              alt="FansFirst Banner"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.bannerHeadlineContainer}
        >
          <Typography
            variant="display3"
            color="primary"
            style={{ padding: "32px" }}
          >
            <Hidden mdUp>
              <span
                style={{
                  display: "block",
                  fontWeight: "400",
                  fontFamily: "Nunito Sans",
                  fontSize: "2.5rem",
                  textAlign: "center",
                  lineHeight: "3.15rem",
                  marginTop: "-24px"
                }}
              >
                We're currently working hard to bring the site back online.
                Please check back shortly.
              </span>
              <a
                href="tel:+1-403-768-2298"
                style={{ textDecoration: "none", textAlign: "center", }}
              >
                <span
                style={{
                  display: "block",
                  fontWeight: "400",
                  fontFamily: "Nunito Sans",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  lineHeight: "3.15rem",
                  marginTop: "24px",
                }}
                >Contact us: +1-403-768-2298</span>
              </a>
            </Hidden>
            <Hidden smDown>
              <span
                style={{
                  display: "block",
                  fontWeight: "400",
                  fontFamily: "Nunito Sans",
                  textAlign: "center",
                }}
              >
                We're currently working hard to bring the site back online.
                Please check back shortly.
              </span>
              <a
                className={classes.callUsContainer}
                href="tel:+1-403-768-2298"
                style={{ textDecoration: "none", textAlign: "center", }}
              >
                <span
                style={{
                  display: "block",
                  fontWeight: "400",
                  fontFamily: "Nunito Sans",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  lineHeight: "3.15rem",
                  marginTop: "24px",
                }}
                >Contact us: +1-403-768-2298</span>
              </a>
            </Hidden>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    const currentURL = window.location.href;
    return (
      <div>
        <Helmet>
          <title>FansFirst - Buy Cheap NHL Tickets</title>
          <meta
            name="description"
            content="Buy NHL Tickets without any service fees at fansfirst.ca. Search all NHL events and get a last-minute ticket to see your favorite NHL team without paying extra services charge. Buy now!"
          />
          <meta name="keywords" content={`maintenance, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="FansFirst - Buy Cheap NHL Tickets" />
          <meta property="og:description" content="Buy NHL Tickets without any service fees at fansfirst.ca. Search all NHL events and get a last-minute ticket to see your favorite NHL team without paying extra services charge. Buy now!" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        {this.renderBanner()}
      </div>
    );
  }
}

export default withStyles(styles)(Maintenance);
