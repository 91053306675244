import { flatten } from "lodash";

const zoneGroup = [
  {
    label: "Lower Zone",
    numbers: [
      ...["100", "100A", "100B"],
      ...["101", "102", "103", "104", "105", "106", "107", "108", "109", "109A", "109B"],
      ...["110", "111", "112", "113", "114", "115", "116", "117"]
    ],
  },
];

const otherZones = [{
  label: "ADA",
}];

const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});

export default flatten([...zones, ...otherZones]);