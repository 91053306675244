import React, { Component } from 'react';
import Raphael from 'raphael';
import PropTypes from 'prop-types';
import _ from 'lodash';

// import Grid from '@material-ui/icons/Grid';
// import Typography from '@material-ui/icons/Typography';
import { withStyles } from '@material-ui/core/styles';
import ReginaStadium from '../images/regina-stadium.png';

import { getZones } from '../libs/regina-canda-mosaic-stadium';

let map = null;

const styles = theme => ({
  [theme.breakpoints.down('sm')]: {
    seatMapImage: {
      height: '100%',
      width: '100%'
    }
  },
  [theme.breakpoints.up('md')]: {
    seatMapImage: {
      height: '100%'
    }
  },
  map: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '0 1rem'
  }
});

class SeatMap2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seats: null,
      seatMapItems: [],
      availableSeatItems: [],
      seatItems: [],
      selectedSeat: null
    };
  }

  createMap(seats) {
    const mapDiv = this.mapDiv;

    const height =
      mapDiv.clientHeight === 0 ? (mapDiv.clientWidth - 32) * 0.75 : 768;
    //mapDiv.clientHeight === 0 ? mapDiv.clientWidth - 32 : mapDiv.clientHeight;
    const width = mapDiv.clientHeight === 0 ? mapDiv.clientWidth - 32 : 1024;
    //mapDiv.clientHeight === 0 ? mapDiv.clientWidth - 32 : mapDiv.clientHeight;

    const initViewBoxHeight = mapDiv.clientHeight === 0 ? 7680 : 10240;
    const initViewBoxWidth = 7680; // - width + width;

    const map = Raphael('map', width, height);

    map.setViewBox(
      mapDiv.clientWidth < 500 ? 1240 : 0,
      0,
      initViewBoxHeight,
      initViewBoxWidth,
      true
    );
    map.canvas.setAttribute('preserveAspectRatio', 'xMinYMin');
    map.image(ReginaStadium, 0, 0, 10240, 7680);
    map.canvas.setAttribute('x', 0);
    map.canvas.setAttribute('y', 0);
    map.canvas.setAttribute('height', '100%');

    const zones = getZones();

    const seatItems = zones.map(zone => {
      return (
        map
          .path(zone.path)
          .attr(zone.attr)
          // .transform(zone.transform)
          .data('zone', zone.data.zone)
          .data('zoneNo', zone.data.zoneNo)
          .data('zoneId', zone.data.zoneId)
      );
    });

    this.seatItems = seatItems;

    return map;
  }

  resetItems() {
    const { availableSeatItems } = this.state;

    availableSeatItems.forEach(seat => {
      seat.node.style.opacity = 0;
      seat.node.style['stroke-width'] = 0;
      seat.node.style.fill = '#2196f3';
      seat.node.setAttribute('cursor', 'default');

      seat.unmouseover();
      seat.unmouseout();
      seat.unclick();
    });
  }

  unclickItem() {
    const { selectedSeat } = this.state;

    if (selectedSeat) {
      selectedSeat.node.style.fill = '#2196f3';
      selectedSeat.node.style.opacity = 0.24;
      selectedSeat.node.style['stroke-width'] = 0;

      this.setState({
        selectedSeat: null
      });
    }
  }

  renderAvailableSeats(zonesList) {
    const seatItems = this.seatItems;
    this.resetItems();

    const seatZones = zonesList;

    const availableSeatItems = seatItems.filter(seat => {
      const section = `${seat.data('zone')} ${seat.data('zoneNo')}`;
      return _.includes(seatZones, section);
    });

    availableSeatItems.forEach(seat => {
      seat.node.setAttribute('cursor', 'pointer');
      seat.node.style.opacity = 0.24;

      seat.mouseover(e => {
        seat.node.style.opacity = 0.5;
      });

      seat.mouseout(e => {
        seat.node.style.opacity = 0.24;
      });

      seat.click(e => {
        seat.node.style.fill = '#e03a3e';
        seat.node.style.opacity = 0.5;
        seat.node.style['stroke-width'] = 2;
        this.onSeatClick(seat);
      });
    });

    this.setState({
      availableSeatItems
    });
  }

  onSeatClick(seat) {
    const { selectedSeat } = this.state;

    if (selectedSeat && selectedSeat === seat) {
      this.unclickItem();
      this.props.onSeatmapItemClick(null, null);

      this.setState({
        selectedSeat: null
      });
    } else if (selectedSeat && selectedSeat !== seat) {
      this.unclickItem();
      this.props.onSeatmapItemClick(seat.data('zoneId'), seat.data('zoneNo'));

      this.setState({
        selectedSeat: seat
      });
    } else if (!selectedSeat) {
      this.props.onSeatmapItemClick(seat.data('zoneId'), seat.data('zoneNo'));

      this.setState({
        selectedSeat: seat
      });
    }
  }

  componentDidMount() {
    map = this.createMap();

    const { seats, zonesList } = this.props;

    this.setState({
      seats,
      zonesList
    });

    if (zonesList) {
      this.renderAvailableSeats(zonesList);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.seats !== this.props.seats) {
      this.setState({
        seats: nextProps.seats
      });
    }

    if (nextProps.zonesList !== this.props.zonesList) {
      const { zonesList } = nextProps;
      this.renderAvailableSeats(zonesList);

      this.setState({
        zonesList
      });
    }
  }

  renderMap() {
    const { classes } = this.props;

    return (
      <div id="map" className={classes.map} ref={el => (this.mapDiv = el)} />
    );
  }

  render() {
    return <React.Fragment>{this.renderMap()}</React.Fragment>;
  }
}

SeatMap2.propTypes = {
  seats: PropTypes.array
};

SeatMap2.defaultProps = {
  seats: []
};

export default withStyles(styles)(SeatMap2);
