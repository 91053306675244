import React, { Component } from "react";
import Helmet from "react-helmet";
import { API } from "aws-amplify";
import moment from "moment-timezone";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { cflTeamCheck, renderZoneName, renderRowName, nhlTeamCheck } from "../libs/shared/seat-helpers";
import { isTimeTBD } from "../libs/shared/grid-helper";
import TEAMS from "../constants/Teams";
import ReviewButton from "../images/fb-review-button.png";

const styles = (theme) => ({
  header: {
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "6rem",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "2rem",
    },
  },
  logo: {
    marginBottom: "3rem",
    height: "50px",
  },
  li: {
    marginBottom: ".25rem",
  },
  orderDetails: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
  },
});

class ThankYou extends Component {
  constructor(props) {
    super();

    this.state = {
      order: null,
      game: null,
      seat: null,
      id: null,
    };
  }
  async componentDidMount() {
    const order = await this.order();

    this.setState({
      order,
      game: order.game,
      seat: order.seat,
    });
  }

  order = () => {
    const id = this.props.match.params.id;
    this.setState({ id });
    return API.get("v2", `marketplace/orders/${id}`);
  };

  game = (id) => {
    return API.get("v2", `marketplace/events/by/eventId/${id}`);
  };

  seat = (id, gameId) => {
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${gameId}?seatId=${id}`
    );
  };

  renderOrderDetails(order, game, seat) {
    const { classes } = this.props;
    const { id } = this.state;
    const regularGameDate = `${isTimeTBD(game) ? moment
      .tz(game.date, game.timezone)
      .format("MMMM DD, YYYY ") + "TBD" : moment
        .tz(game.date, game.timezone)
        .format("MMMM DD, YYYY hh:mm A")}`;
    const playoffsGameDate = null;
    const gameDate = game.isTbd ? playoffsGameDate : regularGameDate;
    return (
      <Paper elevation={1} className={classes.orderDetails}>
        <Typography
          variant="title"
          color="secondary"
          style={{ marginBottom: "1rem" }}
        >
          Your Order Details
        </Typography>
        {order.trackingNumber ? (
          <Typography variant="body1" gutterBottom>
            Order Number: {order.trackingNumber}
          </Typography>
        ) : null}
        <Typography variant="body1" gutterBottom>
          Amount: CAD ${(order.ticketPrice * order.noOfSeats).toFixed(2)}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {order.noOfSeats} Ticket(s)
        </Typography>
        <Typography variant="body1" gutterBottom>
          {game.longName}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {gameDate}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial)} Row {renderRowName(seat.zone, seat.row, game.homeTeamSlug, game.isSpecial)}
        </Typography>
      </Paper>
    );
  }
  renderPaymentInformation(order, game, seat) {
    const { classes } = this.props;

    return (
      <Paper elevation={1} className={classes.orderDetails}>
        <Typography
          variant="title"
          color="secondary"
          style={{ marginBottom: "1rem" }}
        >
          Your Payment Information
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" gutterBottom>
            Subtotal:
          </Typography>
          <Typography variant="body1" gutterBottom>
            CAD ${(order.ticketPrice * order.noOfSeats).toFixed(2)}
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" gutterBottom>
            Service Fee:
          </Typography>
          <Typography variant="body1" gutterBottom>
            CAD $0.00
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" gutterBottom>
            GST (5% - only applicable on service fee):
          </Typography>
          <Typography variant="body1" gutterBottom>
            CAD $0.00
          </Typography>
        </div>
        {
          //Check if has coupon
          order.discountValue > 0 ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1" gutterBottom>
                Coupon Value:
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                style={{ color: "#ff0000" }}
              >
                -CAD ${order.discountValue.toFixed(2)}
              </Typography>
            </div>
          ) : (
            ""
          )
        }
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" gutterBottom>
            <strong>Payment Total:</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>
              CAD $
              {(
                order.ticketPrice * order.noOfSeats -
                order.discountValue
              ).toFixed(2)}
            </strong>
          </Typography>
        </div>
      </Paper>
    );
  }
  render() {
    const { classes } = this.props;
    const { order, game, seat } = this.state;
    if (!game) {
      return null;
    }
    const { isTbd } = seat.gameDetails;

    let header = "";

    if (game) {
      if (isTbd && game.isPlayoffs) {
        header = (
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            The date and time are all subject to change and will be updated as soon as the official schedule is release by the league.
            <br />
            <br />
            If the game you have purchased doesn't occur (the series ends early or the team doesn't make it to a particular round of the playoffs) then your transaction is cancelled and you get a full refund.
            <br />
            <br />
            Please note that all sales are final, and declining the transfer does not cancel your order.
          </Typography>
        )
      } else if (isTbd) {
        header = (
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            The date and time are all subject to change and will be updated as soon as the official schedule is release by the league.
            <br />
            <br />
            If the game you have purchased doesn't occur (the series ends early or the team doesn't make it to a particular round of the playoffs) then your transaction is cancelled and you get a full refund.
          </Typography>
        )
      } else if (order.isInstantDelivery) {
        header = (
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            Your tickets are on their way. The season ticket holder has chosen
            FansFirst's Insta-delivery option: the season ticket holder has
            already sent the tickets to our system and they will be delivered
            right away during business hours (within a few minutes). If you have
            any concerns please text us anytime 1-403-768-2298.
          </Typography>
        )
      // } else if (nhlTeamCheck(game.homeTeamSlug)) { // Please enable this only when the preseason begins for the new NHL schedule.
      //   header = (
      //     <Typography variant="body1" style={{ marginBottom: "2rem" }}>
      //       <p>
      //         Thanks for your order! The season ticket holder has been notified and will deliver the tickets as soon as they are released by the team (usually early September).
      //       </p>           
      //     </Typography>
      //   )
      } else if (cflTeamCheck(game.homeTeamSlug) || ["toronto-blue-jays", "toronto-raptors", "toronto-maple-leafs", "ottawa-senators"].includes(game.homeTeamSlug)) {
        const team = TEAMS.find(team => team.homeTeamSlug === game.homeTeamSlug);
        header = (
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            <p>
              Your {team.name} tickets are on their way. 
              The ticket holder has been notified and will deliver the tickets as soon as they can. 
              Delivery times range from
            </p>
            <ul>
              <li>a few minutes to an hour if the ticket(s) are purchased on game day;{" "}</li>
              <li>a few hours or by the end of the day if the ticket(s) are purchased the day before game day; or</li>
              <li>one day prior to the game if the purchased tickets are further away on the calendar (this is the absolute latest they are expected to arrive, most sellers transfer tickets sooner).{" "}</li>
            </ul>
            <p>Keep in mind FansFirst does not own the tickets.</p>
            <p>
              Don't worry - if the seller informs us that the tickets are no longer available (usually right away) you will receive a FULL refund plus a coupon to be used for a future purchase. If you have any concerns or want to check on the status of your tickets, please text us anytime 1-403-768-2298.
            </p>
            <strong>
              Please note that all sales are final, and declining the transfer
              does not cancel your order.
            </strong>
          </Typography>
        )
      } else {
        header = (
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            <p>
              Your tickets are on their way. The season ticket holder has been
              notified and will deliver the tickets as soon as they can. Delivery
              times range from:
            </p>
            <ul>
              <li>a few minutes to an hour if the ticket(s) are purchased on game day;{" "}</li>
              <li>a few hours or by the end of the day if the ticket(s) are purchased the day before game day; or</li>
              <li>up to 24 to 36 hours if the purchased tickets are further away on the calendar.{" "}</li>
            </ul>
            <p>
              Don't worry - if they don't respond or don't deliver the tickets you
              will receive a FULL refund plus a coupon to be used for a future
              purchase. If you have any concerns or want to check on the status of
              your tickets you text us anytime 1-403-768-2298.
            </p>
            <strong>
              Please note that all sales are final, and declining the transfer
              does not cancel your order.
            </strong>
          </Typography>
        )
      }
    }
    const currentURL = window.location.href;

    return (
      order &&
      game &&
      seat && (
        <Grid container justify="center">
          <Helmet>
            <title>Order Confirmed</title>
            <meta
              name="description"
              content="Your order has been confirmed. We will send your e-ticket(s) to your e-mail once payment has been confirmed."
            />
            <meta name="keywords" content={`thank you, transaction confirmed, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="Order Confirmed" />
            <meta property="og:description" content="Your order has been confirmed. We will send your e-ticket(s) to your e-mail once payment has been confirmed." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={currentURL} />
            <link rel="canonical" href={currentURL} />
          </Helmet>
          <Grid item xs={12} md={6} xl={4}>
            <main>
              <Typography
                className={classes.header}
                variant="display1"
                color="primary"
              >
                Transaction Confirmed!
              </Typography>
              {header}

              {this.renderOrderDetails(order, game, seat)}
              {this.renderPaymentInformation(order, game, seat)}
              <Grid 
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
              >
                <a 
                  href="https://www.facebook.com/fansfirst.ca/reviews/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <img
                    style={{ margin: 'auto', display: 'block', width: '120px', height: 'auto', marginTop: '-31px' }}
                    src={ReviewButton}
                    alt="FansFirst Facebook Reviews"
                  />
                </a>
              </Grid>
              <Divider style={{ marginBottom: "2rem" }} />

              <Typography
                variant="body2"
                color="secondary"
                style={{ marginBottom: "1rem", textTransform: "uppercase" }}
                align="center"
              >
                Important Instructions
              </Typography>

              <Typography
                variant="body1"
                style={{ marginBottom: "2rem" }}
                align="center"
              >
                We guarantee all digitally scanned tickets. Printed copies do
                not fall under the guarantee. We encourage you to{" "}
                <strong>NOT</strong> print your tickets.
              </Typography>

              <Divider style={{ marginBottom: "2rem" }} />

              <Typography
                variant="title"
                color="primary"
                style={{ marginBottom: "1rem" }}
              >
                What's Mobile Transfer?
              </Typography>

              <ul
                style={{
                  margin: 0,
                  marginBottom: "3rem",
                  padding: "0 1rem",
                }}
              >
                <li className={classes.li}>
                  <Typography variant="body1">
                    All FansFirst transactions have tickets delivered digitally
                    from the seller to the buyer.
                  </Typography>
                </li>
                <li className={classes.li}>
                  <Typography variant="body1">
                    An email containing a link to accept your tickets will be
                    sent to you directly from the Team's ticketing site.
                  </Typography>
                </li>
                <li className={classes.li}>
                  <Typography variant="body1">
                    Just follow the prompts and enter some basic information and
                    agree to the terms and conditions.
                  </Typography>
                </li>
                <li className={classes.li}>
                  <Typography variant="body1">
                    A confirmation page will be displayed letting you know that
                    you successfully accepted the tickets.
                  </Typography>
                </li>
              </ul>

              <Typography
                variant="title"
                color="primary"
                style={{ marginBottom: "1rem" }}
              >
                Safe and Secure!
              </Typography>

              <Typography variant="body1">
                Mobile Transfers are the most secure way to transact tickets of
                any event. They can exist ONLY one account at a time. Once they
                are transferred, any previous hard copies or printed copies are
                cancelled.
                <br />
                <br />
                Staff at the game will simply scan the barcode on your ticket
                off your phone.
              </Typography>
            </main>
          </Grid>
        </Grid>
      )
    );
  }
}

export default withStyles(styles)(ThankYou);
