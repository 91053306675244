const zones = [
    {
      label: "Lower Zone 101",
    },
    {
      label: "Lower Zone 102",
    },
    {
      label: "Lower Zone 103",
    },
    {
      label: "Lower Zone 104",
    },
    {
      label: "Lower Zone 105",
    },
    {
      label: "Lower Zone 106",
    },
    {
      label: "Lower Zone 107",
    },
    {
      label: "Lower Zone 108",
    },
    {
      label: "Lower Zone 109",
    },
    {
      label: "Lower Zone C 109",
    },
    {
      label: "Lower Zone 110",
    },
    {
      label: "Lower Zone C 110",
    },
    {
      label: "Lower Zone 111",
    },
    {
      label: "Lower Zone C 111",
    },
    {
      label: "Lower Zone 112",
    },
    {
      label: "Lower Zone C 112",
    },
    {
      label: "Lower Zone 113",
    },
    {
      label: "Lower Zone C 113",
    },
    {
      label: "Lower Zone 114",
    },
    {
      label: "Lower Zone 115",
    },
    {
      label: "Lower Zone 116",
    },
    {
      label: "Lower Zone 117",
    },
    {
      label: "Lower Zone 118",
    },
    {
      label: "Lower Zone 119",
    },
    {
      label: "Lower Zone 120",
    },
    {
        label: "Middle Zone 201",
    },
    {
        label: "Middle Zone 202",
    },
    {
        label: "Middle Zone 203",
    },
    {
        label: "Middle Zone 204",
    },
    {
        label: "Middle Zone 205",
    },
    {
        label: "Middle Zone 206",
    },
    {
        label: "Middle Zone 207",
    },
    {
        label: "Middle Zone 208",
    },
    {
        label: "Middle Zone 209",
    },
    {
        label: "Middle Zone 210",
    },
    {
        label: "Middle Zone 211",
    },
    {
        label: "Middle Zone 212",
    },
    {
        label: "Middle Zone 213",
    },
    {
        label: "Middle Zone 214",
    },
    {
        label: "Middle Zone 215",
    },
    {
        label: "Middle Zone 216",
    },
    {
        label: "Middle Zone 217",
    },
    {
        label: "Middle Zone 218",
    },
    {
        label: "Middle Zone 219",
    },
    {
        label: "Middle Zone 220",
    },
    {
        label: "Middle Zone 221",
    },
    {
        label: "Middle Zone 222",
    },
    {
        label: "Middle Zone 223",
    },
    {
        label: "Middle Zone 224",
    },
    {
        label: "Middle Zone 225",
    },
    {
        label: "Middle Zone 226",
    },
    {
        label: "Middle Zone 227",
    },
    {
        label: "Middle Zone 228",
    },
    {
      label: "Upper Zone 301",
    },
    {
      label: "Upper Zone 302",
    },
    {
      label: "Upper Zone 303",
    },
    {
      label: "Upper Zone 304",
    },
    {
      label: "Upper Zone 305",
    },
    {
      label: "Upper Zone 306",
    },
    {
      label: "Upper Zone 307",
    },
    {
      label: "Upper Zone 308",
    },
    {
      label: "Upper Zone 309",
    },
    {
      label: "Upper Zone 310",
    },
    {
      label: "Upper Zone 311",
    },
    {
      label: "Upper Zone 312",
    },
    {
      label: "Upper Zone 313",
    },
    {
      label: "Upper Zone 314",
    },
    {
      label: "Upper Zone 315",
    },
    {
      label: "Upper Zone 316",
    },
    {
      label: "Upper Zone 317",
    },
    {
      label: "Upper Zone 318",
    },
    {
      label: "Upper Zone 319",
    },
    {
      label: "Upper Zone 320",
    },
    {
      label: "Upper Zone 321",
    },
    {
      label: "Upper Zone 322",
    },
    {
      label: "Upper Zone 323",
    },
    {
      label: "Upper Zone 324",
    },
    {
        label: "Upper Zone 325",
    },
    {
        label: "Upper Zone 326",
    },
    {
        label: "Upper Zone 327",
    },
    {
        label: "Upper Zone 328",
    },
    {
      label: "Suite 400",
    },
    {
      label: "Suite 401",
    },
    {
      label: "Suite 402",
    },
    {
      label: "Suite 403",
    },
    {
      label: "Suite 404",
    },
    {
      label: "Suite 405",
    },
    {
      label: "Suite 406",
    },
    {
      label: "Suite 407",
    },
    {
      label: "Suite 408",
    },
    {
      label: "Suite 409",
    },
    {
      label: "Suite 410",
    },
    {
      label: "Suite 411",
    },
    {
      label: "Suite 412",
    },
    {
      label: "Suite 413",
    },
    {
      label: "Suite 414",
    },
    {
      label: "Suite 415",
    },
    {
      label: "Suite 416",
    },
    {
      label: "Suite 417",
    },
    {
      label: "Suite 418",
    },
    {
      label: "Suite 419",
    },
    {
      label: "Suite 420",
    },
    {
      label: "Suite 427",
    },
    {
      label: "Suite 428",
    },
    {
      label: "Suite 429",
    },
    {
      label: "Suite 430",
    },
    {
      label: "Suite 431",
    },
    {
      label: "Suite 432",
    },
    {
      label: "Suite 433",
    },
    {
      label: "Suite 434",
    },
    {
      label: "Suite 435",
    },
    {
      label: "Suite 436",
    },
    {
      label: "Suite 437",
    },
    {
      label: "Suite 438",
    },
    {
      label: "Suite 450A",
    },
    {
      label: "Suite 450B",
    },
    {
      label: "Suite 475A",
    },
    {
      label: "Suite 480",
    },
    {
      label: "TLGTRA",
    },
    {
      label: "TLGTRB",
    },
    {
      label: "Ledge",
    },
  ];
  
  export default zones;
  