import React from 'react';
import ReactDOM from "react-dom";
import DialogAlert from './DialogAlert';
import DialogConfirm from './DialogConfirm';
import DialogConfirmInput from './DialogConfirmInput';
import DialogLoading from './DialogLoading';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: "#e03a3e",
    },
    tertiary: {
      main: "#e03a3e",
    },
  }
});

const renderAlert = (title, description, option, type) => {
  const modalEl = document.createElement("div");
  const rootElement = document.getElementById("root");
  rootElement.appendChild(modalEl);

  function destroy() {
    rootElement.removeChild(modalEl);
  }

  function render() {
    ReactDOM.render(
      <MuiThemeProvider theme={theme}>      
        <DialogAlert
          type={type}
          title={title}
          description={description}
          option={option}
          destroy={destroy}
        />
      </MuiThemeProvider>,
      modalEl
    );
  }

  render();
}

const alert = (title, description, option) => {
  renderAlert(title, description, option, null);
}

const success = (title, description, option) => {
  renderAlert(title, description, option, "success");
}

const error = (title, description, option) => {
  renderAlert(title, description, option, "error");
}

const apiError = (error) => {
  let description;
  if (error.response) {
    description = `${error.response.status} ${error.response.statusText}`;
  } else {
    description = "Internal server error.";
  }
  renderAlert("Error", description, "error");
}

const info = (title, description, option) => {
  renderAlert(title, description, option, "info");
}

const warning = (title, description, option) => {
  renderAlert(title, description, option, "warning");
}

const confirm = (title, description) => {
  const modalEl = document.createElement("div");
  const rootElement = document.getElementById("root");
  rootElement.appendChild(modalEl);

  function destroy() {
    rootElement.removeChild(modalEl);
  }

  return new Promise((resolve, reject) => {
    function onConfirm() {
      resolve(true);
    }
    function onCancel() {
      resolve(false);
    }
    ReactDOM.render(
      <DialogConfirm
        title={title}
        description={description}
        destroy={destroy}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />,
      modalEl
    );
  })
};

const confirmInput = (option = {}) => {
  const { title, description, value, inputRequired, inputType, inputLabel, cancelLabel, confirmLabel } = option;
  const modalEl = document.createElement("div");
  const rootElement = document.getElementById("root");
  rootElement.appendChild(modalEl);

  function destroy() {
    rootElement.removeChild(modalEl);
  }

  return new Promise((resolve, reject) => {
    function onConfirm(value) {
      resolve({ confirmed: true, value });
    }
    function onCancel() {
      resolve({ confirmed: false, value: null });
    }
    ReactDOM.render(
      <DialogConfirmInput
        title={title}
        description={description}
        value={value}
        inputLabel={inputLabel}
        inputType={inputType}
        inputRequired={inputRequired}
        destroy={destroy}
        onConfirm={onConfirm}
        onCancel={onCancel}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
      />,
      modalEl
    );
  })
};

const loading = (open) => {
  const rootElement = document.getElementById("root");
  let loadingEl = document.getElementById("cdialog-loading");

  if (!loadingEl) {
    loadingEl = document.createElement("div");
    loadingEl.setAttribute('id', 'cdialog-loading');
  }

  if (open) {
    rootElement.appendChild(loadingEl);
    ReactDOM.render(<DialogLoading />, loadingEl);
  } else {
    ReactDOM.unmountComponentAtNode(loadingEl);
  }
}

export default { alert, success, error, apiError, info, warning, confirm, confirmInput, loading }