import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CheckCircle, HighlightOff, InfoRounded, WarningRounded } from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  success: {
    color: green[800],
    marginRight: theme.spacing.unit,
  },
  error: {
    color: red[800],
    marginRight: theme.spacing.unit,
  },
  info: {
    color: blue[800],
    marginRight: theme.spacing.unit,
  },
  warning: {
    color: orange[800],
    marginRight: theme.spacing.unit,
  },
});

const DialogAlert = ({ classes, type, title, description, destroy, option }) => {
  const [open, setOpen] = useState(true);

  const destroyAlert = () => {
    setOpen(false);
    destroy();
  }

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircle className={classes.success} />
        break;
      case 'error':
        return <HighlightOff className={classes.error} />
        break;
      case 'info':
        return <InfoRounded className={classes.info} />
        break;
      case 'warning':
        return <WarningRounded className={classes.warning} />
        break;
      default:
        return null;
        break;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle>
        <span className={classes.header}>{renderIcon()} {title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {option.html}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => destroyAlert()} color="primary" variant="contained" size="large">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogAlert.defaultProps = {
  title: "Alert",
  description: "",
  option: {}
}

export default withStyles(styles)(DialogAlert);