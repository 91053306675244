import React from "react";
import { Typography } from "@material-ui/core";

import AcceptTicketsEmail from "../images/how-tos/accept-mobile-transfer/mobile-ver/AcceptTicketsEmail.png";
import AcceptTransfer from "../images/how-tos/accept-mobile-transfer/mobile-ver/AcceptTransfer.png";
import CreateAccount from "../images/how-tos/accept-mobile-transfer/mobile-ver/CreateAccount.png";
import OfferAccepted from "../images/how-tos/accept-mobile-transfer/mobile-ver/OfferAccepted.png";
import SignIn from "../images/how-tos/accept-mobile-transfer/mobile-ver/SignIn.png";

const AcceptMobileTransferDesktop = ({ classes }) => {
  return (
    <ol
      style={{
        margin: 0,
        marginBottom: "1rem",
        padding: "0 1rem"
      }}
    >
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          All FansFirst transactions have tickets delivered digitally from the
          seller to the buyer. You’ll receive an email with the following:
        </Typography>
        <img width="50%" src={AcceptTicketsEmail} alt="Accept Tickets Email" />
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Click "Accept Tickets" to begin the process.
        </Typography>
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          You’ll be taken to the Calgary Flame Account Manager. It will ask to
          you login with your email and password. If you don’t have an existing
          account, click “Create Account”. If you have an account, skip to Step
          4.
        </Typography>
        <img
          width="75%"
          src={SignIn}
          alt="Sign In Page"
          style={{ marginBottom: "1.5rem" }}
        />
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
          color="secondary"
        >
          Note: If you have ever signed up for Flames promotions or entered a
          Flames contest before they may have created an account for you. Some
          people have had problems logging in as a result of this. If you have
          issues logging in, and the system indicates an account already exists,
          then try setting up a new account with a new email and accepting the
          tickets into that account instead.
        </Typography>
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Fill-out the form
        </Typography>
        <img width="75%" src={CreateAccount} alt="Create Account" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          If you’re creating an account, fill-out the form and consent that you
          are over 13 years of age.
        </Typography>
        <img width="100%" src={CreateAccount} alt="CreateAccount" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Next click "Accept Offer".
        </Typography>
        <img width="100%" src={AcceptTransfer} alt="Accept Offer" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          And you're done
        </Typography>
        <img width="75%" src={OfferAccepted} alt="Offer Accepted" />
      </li>
    </ol>
  );
};

export default AcceptMobileTransferDesktop;
