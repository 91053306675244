
const defaultRows = () => {
  const arr = [];

  for (let i = 1; i <= 40; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneRows1 = () => {
  const arr = [];

  for (let i = 1; i <= 3; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneRows2 = () => {
  const arr = [];

  for (let i = 1; i <= 18; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneRows3 = () => {
  const arr = [];

  for (let i = 1; i <= 2; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneRows4 = () => {
  const arr = [];

  for (let i = 1; i <= 16; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  arr.push({
    value: '17(Standing room only)',
    label: `Row 17(Standing room only)`,
  });

  return arr;
};

const lowerZoneRows5 = () => {
  const arr = [];

  for (let i = 1; i <= 16; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  arr.push({
    value: 'Wheelchair',
    label: `Row Wheelchair`,
  });

  return arr;
};

const lowerZoneRows6 = () => {
  const arr = [];

  for (let i = 1; i <= 16; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  arr.push({
    value: '17(Standing room only)',
    label: `Row 17(Standing room only)`,
  });

  arr.push({
    value: 'Wheelchair',
    label: `Row Wheelchair`,
  });

  return arr;
};

const lowerZoneRows7 = () => {
  const arr = [];

  for (let i = 3; i <= 16; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  arr.push({
    value: '17(Standing room only)',
    label: `Row 17(Standing room only)`,
  });

  return arr;
};

const lowerZoneRows8 = () => {
  const arr = [];

  for (let i = 3; i <= 16; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  arr.push({
    value: 'Wheelchair',
    label: `Row Wheelchair`,
  });

  return arr;
};

const lowerZoneRows9 = () => {
  const arr = [];

  for (let i = 1; i <= 2; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const southRows = () => [
  {
    value: '1',
    label: 'Row 1',
  }
];

const northRows = () => [
  {
    value: 'Wheelchair',
    label: 'Row Wheelchair',
  }
];

const fanZoneRows = () => {
  const arr = [];

  for (let i = 1; i <= 8; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const mezzanineRows = () => {
  const arr = [];

  for (let i = 1; i <= 15; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

export default {
  DEFAULT_ROWS: defaultRows(),
  LOWER_ZONE_ROWS1: lowerZoneRows1(),
  LOWER_ZONE_ROWS2: lowerZoneRows2(),
  LOWER_ZONE_ROWS3: lowerZoneRows3(),
  LOWER_ZONE_ROWS4: lowerZoneRows4(),
  LOWER_ZONE_ROWS5: lowerZoneRows5(),
  LOWER_ZONE_ROWS6: lowerZoneRows6(),
  LOWER_ZONE_ROWS7: lowerZoneRows7(),
  LOWER_ZONE_ROWS8: lowerZoneRows8(),
  LOWER_ZONE_ROWS9: lowerZoneRows9(),
  SOUTH_ROWS: southRows(),
  NORTH_ROWS: northRows(),
  FAN_ZONE_ROWS: fanZoneRows(),
  MEZZANINE_ROWS: mezzanineRows()
};
