export const getZones = (rsr) => {
  let zones = [];
  var _TwistedTeaPartyDeck = {
    path:
      "M 1623.00,719.00 C 1623.00,719.00 1639.08,737.00 1639.08,737.00 1639.08,737.00 1668.08,772.00 1668.08,772.00 1668.08,772.00 1678.06,784.00 1678.06,784.00 1678.06,784.00 1680.55,789.81 1680.55,789.81 1680.55,789.81 1675.99,794.05 1675.99,794.05 1675.99,794.05 1663.00,801.71 1663.00,801.71 1663.00,801.71 1611.00,832.00 1611.00,832.00 1605.79,821.31 1589.84,801.27 1581.71,792.00 1581.71,792.00 1570.00,779.00 1570.00,779.00 1570.00,779.00 1583.17,764.00 1583.17,764.00 1583.17,764.00 1607.08,737.01 1607.08,737.01 1607.08,737.01 1623.00,719.00 1623.00,719.00 Z",
    attr: {
      id: "_TwistedTeaPartyDeck",
      "data-name": "TwistedTeaPartyDeck",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Twisted Tea Party Deck", zoneId: "1" },
  };
  var _CoorsPartyDeck = {
    path:
      "M 1501.00,1314.00 C 1514.05,1307.02 1526.19,1298.23 1538.00,1289.37 1540.67,1287.37 1550.35,1279.16 1553.00,1279.19 1556.80,1279.24 1565.84,1290.83 1568.58,1294.00 1568.58,1294.00 1579.83,1306.00 1579.83,1306.00 1579.83,1306.00 1590.16,1317.96 1590.16,1317.96 1596.32,1324.56 1602.75,1330.57 1608.00,1338.00 1608.00,1338.00 1566.00,1366.38 1566.00,1366.38 1566.00,1366.38 1538.00,1385.00 1538.00,1385.00 1538.00,1385.00 1513.33,1339.00 1513.33,1339.00 1513.33,1339.00 1501.00,1314.00 1501.00,1314.00 Z",
    attr: {
      id: "_CoorsPartyDec",
      "data-name": "CoorsPartyDeck",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Coors Light Party Deck", zoneId: "1" },
  };
  var _rooftoppatio = {
    path:
      "M 228.00,703.00 C 228.00,703.00 273.00,703.00 273.00,703.00 273.00,703.00 273.00,1334.00 273.00,1334.00 273.00,1334.00 228.00,1334.00 228.00,1334.00 228.00,1334.00 228.00,703.00 228.00,703.00 Z",
    attr: {
      id: "_rooftoppatio",
      "data-name": "rooftoppatio",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Rooftop Patio", zoneId: "1" },
  };
  var _A = {
    path:
      "M 512.00,439.00 C 512.00,439.00 573.00,418.67 573.00,418.67 583.44,415.19 590.83,411.02 602.00,411.00 602.00,411.00 619.00,411.00 619.00,411.00 619.00,411.00 626.72,477.00 626.72,477.00 626.72,477.00 641.28,596.00 641.28,596.00 641.28,596.00 648.15,651.00 648.15,651.00 648.15,651.00 652.00,685.00 652.00,685.00 652.00,685.00 612.00,685.00 612.00,685.00 612.00,685.00 604.51,683.98 604.51,683.98 604.51,683.98 598.01,670.00 598.01,670.00 598.01,670.00 582.67,629.00 582.67,629.00 582.67,629.00 572.37,602.00 572.37,602.00 572.37,602.00 533.63,498.00 533.63,498.00 533.63,498.00 517.66,456.00 517.66,456.00 517.66,456.00 512.00,439.00 512.00,439.00 Z",
    attr: {
      id: "_A",
      "data-name": "A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone A", zoneId: "2" },
  };
  var _A2 = {
    path:
      "M 476.00,128.00 C 476.00,128.00 497.00,121.42 497.00,121.42 516.77,115.64 545.94,107.66 566.00,105.00 566.00,105.00 574.00,149.00 574.00,149.00 574.00,149.00 588.20,222.00 588.20,222.00 588.20,222.00 604.20,305.00 604.20,305.00 604.20,305.00 612.00,345.00 612.00,345.00 589.68,349.20 568.46,356.83 547.00,364.00 547.00,364.00 532.96,317.00 532.96,317.00 532.96,317.00 499.04,205.00 499.04,205.00 499.04,205.00 476.00,128.00 476.00,128.00 Z",
    attr: {
      id: "_A2",
      "data-name": "A2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone A", zoneId: "3" },
  };
  var _AA = {
    path:
      "M 349.00,627.00 C 349.00,627.00 382.17,659.91 382.17,659.91 382.17,659.91 417.72,695.28 417.72,695.28 417.72,695.28 445.91,723.72 445.91,723.72 445.91,723.72 471.17,749.09 471.17,749.09 471.17,749.09 478.80,758.99 478.80,758.99 478.80,758.99 474.00,765.28 474.00,765.28 474.00,765.28 460.00,778.04 460.00,778.04 460.00,778.04 426.00,809.09 426.00,809.09 426.00,809.09 398.00,834.00 398.00,834.00 394.91,830.91 386.20,820.73 383.00,819.90 380.57,818.77 374.22,819.96 371.00,819.90 371.00,819.90 358.00,819.90 358.00,819.90 358.00,819.90 350.00,820.96 350.00,820.96 342.38,821.16 333.48,814.66 327.00,810.80 325.21,809.73 319.73,806.72 318.64,805.37 316.89,803.22 317.93,796.96 318.01,794.00 318.01,794.00 321.87,761.00 321.87,761.00 326.39,728.47 333.34,696.06 340.42,664.00 340.42,664.00 349.00,627.00 349.00,627.00 Z",
    attr: {
      id: "_AA",
      "data-name": "AA",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone AA", zoneId: "2" },
  };
  var _AA2 = {
    path:
      "M 301.00,225.00 C 306.74,229.31 310.90,235.48 315.42,241.00 315.42,241.00 339.80,272.00 339.80,272.00 339.80,272.00 387.39,330.00 387.39,330.00 387.39,330.00 397.01,343.00 397.01,343.00 397.01,343.00 417.42,368.00 417.42,368.00 417.42,368.00 445.25,402.00 445.25,402.00 447.54,404.77 454.81,412.58 454.70,415.99 454.60,419.16 446.57,425.35 444.09,428.00 436.87,435.71 426.58,447.61 422.00,457.00 415.49,454.84 406.76,447.19 401.00,442.87 401.00,442.87 361.00,412.87 361.00,412.87 361.00,412.87 224.00,310.00 224.00,310.00 226.41,302.46 238.17,288.47 243.59,282.00 264.46,257.12 276.83,245.98 301.00,225.00 Z",
    attr: {
      id: "_AA2",
      "data-name": "AA2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone AA", zoneId: "3" },
  };
  var _B = {
    path:
      "M 636.00,411.00 C 636.00,411.00 720.00,411.00 720.00,411.00 720.00,411.00 720.00,685.00 720.00,685.00 720.00,685.00 669.00,685.00 669.00,685.00 669.00,685.00 662.16,627.00 662.16,627.00 662.16,627.00 653.15,554.00 653.15,554.00 653.15,554.00 645.72,492.00 645.72,492.00 645.72,492.00 636.00,411.00 636.00,411.00 Z",
    attr: {
      id: "_B",
      "data-name": "B",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone B", zoneId: "2" },
  };
  var _B2 = {
    path:
      "M 582.00,102.00 C 593.21,99.98 629.70,95.02 640.00,95.00 640.00,95.00 661.00,94.00 661.00,94.00 661.00,94.00 710.00,94.00 710.00,94.00 710.00,94.00 710.00,343.00 710.00,343.00 710.00,343.00 628.00,343.00 628.00,343.00 628.00,343.00 622.45,312.00 622.45,312.00 622.45,312.00 611.45,255.00 611.45,255.00 611.45,255.00 582.00,102.00 582.00,102.00 Z",
    attr: {
      id: "_B2",
      "data-name": "B2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone B", zoneId: "3" },
  };
  var _BB = {
    path:
      "M 430.00,514.00 C 434.60,518.38 436.99,524.52 440.05,530.00 440.05,530.00 456.95,561.00 456.95,561.00 456.95,561.00 509.83,658.00 509.83,658.00 509.83,658.00 528.37,692.00 528.37,692.00 529.94,694.87 535.58,704.19 535.41,706.91 535.23,709.88 529.20,714.87 527.00,716.96 527.00,716.96 505.00,737.00 505.00,737.00 502.89,739.00 496.58,745.07 494.00,745.68 490.60,746.49 486.42,742.16 484.28,739.91 484.28,739.91 461.72,716.99 461.72,716.99 461.72,716.99 434.28,689.72 434.28,689.72 434.28,689.72 385.00,640.00 385.00,640.00 385.00,640.00 365.00,620.00 365.00,620.00 362.96,617.96 358.31,613.92 358.11,611.00 357.92,608.37 361.04,604.99 362.58,603.00 362.58,603.00 376.13,585.00 376.13,585.00 376.13,585.00 430.00,514.00 430.00,514.00 Z",
    attr: {
      id: "_BB",
      "data-name": "BB",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone BB", zoneId: "2" },
  };
  var _BB2 = {
    path:
      "M 469.00,406.00 C 469.00,406.00 412.61,337.00 412.61,337.00 412.61,337.00 401.39,322.00 401.39,322.00 401.39,322.00 352.20,262.00 352.20,262.00 352.20,262.00 341.71,248.00 341.71,248.00 341.71,248.00 324.58,227.00 324.58,227.00 322.47,224.43 315.60,217.49 316.45,214.17 317.14,211.50 324.59,206.42 327.00,204.72 327.00,204.72 358.00,183.81 358.00,183.81 361.74,181.51 368.51,175.76 372.61,179.36 372.61,179.36 383.21,197.00 383.21,197.00 383.21,197.00 408.29,241.00 408.29,241.00 408.29,241.00 463.13,337.00 463.13,337.00 463.13,337.00 493.00,390.00 493.00,390.00 493.00,390.00 469.00,406.00 469.00,406.00 Z",
    attr: {
      id: "_BB2",
      "data-name": "BB2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone BB", zoneId: "3" },
  };
  var _C = {
    path:
      "M 737.00,411.00 C 737.00,411.00 765.00,411.00 765.00,411.00 765.00,411.00 784.00,412.00 784.00,412.00 784.00,412.00 784.00,685.00 784.00,685.00 784.00,685.00 737.00,685.00 737.00,685.00 737.00,685.00 737.00,411.00 737.00,411.00 Z",
    attr: {
      id: "_C",
      "data-name": "C",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone C", zoneId: "2" },
  };
  var _C2 = {
    path:
      "M 727.00,94.00 C 727.00,94.00 778.00,94.00 778.00,94.00 778.00,94.00 778.00,342.00 778.00,342.00 778.00,342.00 763.00,342.00 763.00,342.00 763.00,342.00 747.00,343.00 747.00,343.00 747.00,343.00 727.00,343.00 727.00,343.00 727.00,343.00 727.00,94.00 727.00,94.00 Z",
    attr: {
      id: "_C2",
      "data-name": "C2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone C", zoneId: "3" },
  };
  var _CC = {
    path:
      "M 549.00,696.00 C 549.00,696.00 513.08,630.00 513.08,630.00 513.08,630.00 473.51,557.00 473.51,557.00 473.51,557.00 451.09,516.00 451.09,516.00 449.42,512.80 443.42,502.84 443.15,500.00 442.73,495.63 451.73,485.66 454.58,482.00 454.58,482.00 470.58,461.00 470.58,461.00 472.79,458.16 476.43,452.75 479.17,450.84 481.87,448.96 492.45,445.81 496.00,445.00 496.00,445.00 505.69,471.00 505.69,471.00 505.69,471.00 515.37,497.00 515.37,497.00 515.37,497.00 547.37,582.00 547.37,582.00 547.37,582.00 573.37,651.00 573.37,651.00 573.37,651.00 586.00,685.00 586.00,685.00 586.00,685.00 562.00,685.98 562.00,685.98 562.00,685.98 549.00,696.00 549.00,696.00 Z",
    attr: {
      id: "_CC",
      "data-name": "CC",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone CC", zoneId: "2" },
  };
  var _CC2 = {
    path:
      "M 531.00,371.00 C 524.56,373.25 520.97,375.35 515.00,378.24 512.85,379.28 509.77,381.09 507.39,379.87 505.20,378.76 501.17,370.52 499.71,368.00 499.71,368.00 481.50,336.00 481.50,336.00 481.50,336.00 456.70,293.00 456.70,293.00 456.70,293.00 417.46,224.00 417.46,224.00 417.46,224.00 393.98,183.00 393.98,183.00 392.36,180.15 386.28,171.08 387.30,168.21 388.38,165.16 401.59,159.46 405.00,157.75 417.12,151.69 448.91,136.83 461.00,134.00 461.00,134.00 475.26,182.00 475.26,182.00 475.26,182.00 492.96,241.00 492.96,241.00 492.96,241.00 513.04,309.00 513.04,309.00 513.04,309.00 531.00,371.00 531.00,371.00 Z",
    attr: {
      id: "_CC2",
      "data-name": "CC2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone CC", zoneId: "3" },
  };
  var _D = {
    path: "M 801.00,471.00 C 801.00,471.00 850.00,471.00 850.00,471.00 850.00,471.00 850.00,685.00 850.00,685.00 850.00,685.00 801.00,685.00 801.00,685.00 801.00,685.00 801.00,471.00 801.00,471.00 Z",
    attr: {
      id: "_D",
      "data-name": "D",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone D", zoneId: "2" },
  };
  var _D2 = {
    path: "M 795.00,94.00 C 795.00,94.00 817.00,93.00 817.00,93.00 817.00,93.00 846.00,93.00 846.00,93.00 846.00,93.00 846.00,342.00 846.00,342.00 846.00,342.00 795.00,342.00 795.00,342.00 795.00,342.00 795.00,94.00 795.00,94.00 Z",
    attr: {
      id: "_D2",
      "data-name": "D2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone D", zoneId: "3" },
  };
  var _DD = {
    path:
      "M 1549.00,445.00 C 1555.53,446.53 1568.69,452.60 1575.00,455.75 1595.52,465.99 1600.17,468.63 1619.00,482.00 1619.00,482.00 1597.03,527.00 1597.03,527.00 1597.03,527.00 1570.00,581.00 1570.00,581.00 1570.00,581.00 1546.69,628.00 1546.69,628.00 1546.69,628.00 1518.00,686.00 1518.00,686.00 1518.00,686.00 1458.00,686.00 1458.00,686.00 1458.00,686.00 1471.55,649.00 1471.55,649.00 1471.55,649.00 1490.58,599.00 1490.58,599.00 1490.58,599.00 1531.26,492.00 1531.26,492.00 1531.26,492.00 1549.00,445.00 1549.00,445.00 Z",
    attr: {
      id: "_DD",
      "data-name": "DD",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone DD", zoneId: "2" },
  };
  var _DD2 = {
    path:
      "M 1601.00,144.00 C 1618.34,150.06 1646.19,165.12 1663.00,174.00 1661.03,181.84 1653.30,192.82 1648.95,200.00 1648.95,200.00 1620.65,247.00 1620.65,247.00 1620.65,247.00 1559.15,350.00 1559.15,350.00 1559.15,350.00 1538.00,385.00 1538.00,385.00 1538.00,385.00 1512.00,373.00 1512.00,373.00 1512.00,373.00 1522.26,345.00 1522.26,345.00 1522.26,345.00 1542.26,294.00 1542.26,294.00 1542.26,294.00 1601.00,144.00 1601.00,144.00 Z",
    attr: {
      id: "_DD2",
      "data-name": "DD2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone DD", zoneId: "3" },
  };
  var _E = {
    path: "M 867.00,471.00 C 867.00,471.00 916.00,471.00 916.00,471.00 916.00,471.00 916.00,685.00 916.00,685.00 916.00,685.00 867.00,685.00 867.00,685.00 867.00,685.00 867.00,471.00 867.00,471.00 Z",
    attr: {
      id: "_E",
      "data-name": "E",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone E", zoneId: "2" },
  };
  var _E2 = {
    path: "M 863.00,93.00 C 863.00,93.00 914.00,93.00 914.00,93.00 914.00,93.00 914.00,342.00 914.00,342.00 914.00,342.00 863.00,342.00 863.00,342.00 863.00,342.00 863.00,93.00 863.00,93.00 Z",
    attr: {
      id: "_E2",
      "data-name": "E2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone E", zoneId: "3" },
  };
  var _EE = {
    path:
      "M 1632.00,495.00 C 1639.83,500.60 1655.21,517.31 1662.17,525.00 1662.17,525.00 1695.20,563.00 1695.20,563.00 1695.20,563.00 1708.37,580.00 1708.37,580.00 1709.83,581.95 1712.93,585.52 1712.85,588.00 1712.74,591.15 1705.74,598.33 1703.42,600.72 1703.42,600.72 1675.95,631.28 1675.95,631.28 1675.95,631.28 1658.87,651.00 1658.87,651.00 1658.87,651.00 1648.43,662.04 1648.43,662.04 1648.43,662.04 1629.72,684.00 1629.72,684.00 1629.72,684.00 1620.17,694.00 1620.17,694.00 1620.17,694.00 1604.83,712.00 1604.83,712.00 1604.83,712.00 1590.25,728.00 1590.25,728.00 1590.25,728.00 1565.41,756.00 1565.41,756.00 1563.49,758.29 1557.48,765.99 1554.96,766.66 1551.98,767.46 1548.18,763.55 1546.00,761.80 1546.00,761.80 1524.00,746.00 1524.00,746.00 1524.00,746.00 1532.63,693.00 1532.63,693.00 1532.63,693.00 1550.49,657.00 1550.49,657.00 1550.49,657.00 1589.97,578.00 1589.97,578.00 1589.97,578.00 1616.48,525.00 1616.48,525.00 1616.48,525.00 1632.00,495.00 1632.00,495.00 Z",
    attr: {
      id: "_EE",
      "data-name": "EE",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone EE", zoneId: "2" },
  };
  var _EE2 = {
    path:
      "M 1678.00,183.00 C 1678.00,183.00 1730.00,216.00 1730.00,216.00 1727.83,222.38 1720.58,229.58 1716.20,235.00 1716.20,235.00 1697.40,258.00 1697.40,258.00 1697.40,258.00 1686.80,272.00 1686.80,272.00 1686.80,272.00 1612.09,363.00 1612.09,363.00 1612.09,363.00 1605.66,372.00 1605.66,372.00 1605.66,372.00 1587.75,394.00 1587.75,394.00 1583.50,399.14 1579.33,405.00 1574.00,409.00 1574.00,409.00 1559.00,398.33 1559.00,398.33 1556.84,396.83 1553.67,395.00 1553.87,391.96 1554.02,389.63 1561.07,378.80 1562.71,376.00 1562.71,376.00 1586.00,337.00 1586.00,337.00 1586.00,337.00 1618.36,283.00 1618.36,283.00 1618.36,283.00 1660.05,213.00 1660.05,213.00 1660.05,213.00 1678.00,183.00 1678.00,183.00 Z",
    attr: {
      id: "_EE2",
      "data-name": "EE2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone EE", zoneId: "3" },
  };
  var _F = {
    path: "M 933.00,471.00 C 933.00,471.00 981.00,471.00 981.00,471.00 981.00,471.00 981.00,685.00 981.00,685.00 981.00,685.00 933.00,685.00 933.00,685.00 933.00,685.00 933.00,471.00 933.00,471.00 Z",
    attr: {
      id: "_F",
      "data-name": "F",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone F", zoneId: "2" },
  };
  var _F2 = {
    path: "M 931.00,93.00 C 931.00,93.00 979.00,93.00 979.00,93.00 979.00,93.00 979.00,342.00 979.00,342.00 979.00,342.00 931.00,342.00 931.00,342.00 931.00,342.00 931.00,93.00 931.00,93.00 Z",
    attr: {
      id: "_F2",
      "data-name": "F2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone F", zoneId: "3" },
  };
  var _FF = {
    path:
      "M 1723.00,602.00 C 1729.26,607.30 1736.43,620.64 1741.05,628.00 1754.72,649.80 1766.60,672.62 1777.31,696.00 1777.31,696.00 1787.40,719.00 1787.40,719.00 1788.30,721.21 1790.80,726.52 1790.19,728.74 1789.45,731.47 1781.56,735.30 1779.00,736.79 1779.00,736.79 1746.00,756.29 1746.00,756.29 1746.00,756.29 1714.00,774.88 1714.00,774.88 1711.05,776.66 1704.39,781.69 1701.04,780.64 1698.77,779.92 1696.43,776.79 1694.92,775.00 1694.92,775.00 1682.42,760.00 1682.42,760.00 1682.42,760.00 1635.00,702.00 1635.00,702.00 1635.00,702.00 1683.17,647.00 1683.17,647.00 1683.17,647.00 1723.00,602.00 1723.00,602.00 Z",
    attr: {
      id: "_FF",
      "data-name": "FF",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone FF", zoneId: "2" },
  };
  var _FF2 = {
    path:
      "M 1743.00,227.00 C 1749.46,229.91 1758.34,237.87 1764.00,242.59 1764.00,242.59 1799.00,274.00 1799.00,274.00 1802.18,277.18 1814.03,287.79 1813.84,292.00 1813.68,295.70 1806.68,300.75 1804.00,303.17 1804.00,303.17 1774.00,331.04 1774.00,331.04 1740.32,363.24 1705.29,394.54 1670.00,425.00 1670.00,425.00 1641.00,448.87 1641.00,448.87 1638.41,450.83 1632.12,455.88 1629.00,456.04 1625.70,456.21 1623.53,453.20 1621.58,451.00 1621.58,451.00 1610.00,439.00 1610.00,439.00 1610.00,439.00 1595.00,425.28 1595.00,425.28 1593.23,423.71 1590.24,421.49 1589.91,418.99 1589.44,415.44 1597.67,406.89 1600.08,404.00 1600.08,404.00 1619.71,380.00 1619.71,380.00 1619.71,380.00 1632.75,363.00 1632.75,363.00 1632.75,363.00 1674.58,312.00 1674.58,312.00 1674.58,312.00 1704.88,275.00 1704.88,275.00 1704.88,275.00 1715.42,261.00 1715.42,261.00 1715.42,261.00 1743.00,227.00 1743.00,227.00 Z",
    attr: {
      id: "_FF2",
      "data-name": "FF2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone FF", zoneId: "3" },
  };
  var _G = {
    path: "M 998.00,471.00 C 998.00,471.00 1049.00,471.00 1049.00,471.00 1049.00,471.00 1049.00,685.00 1049.00,685.00 1049.00,685.00 998.00,685.00 998.00,685.00 998.00,685.00 998.00,471.00 998.00,471.00 Z",
    attr: {
      id: "_G",
      "data-name": "G",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone G", zoneId: "2" },
  };
  var _G2 = {
    path:
      "M 996.00,93.00 C 996.00,93.00 1045.00,93.00 1045.00,93.00 1045.00,93.00 1045.00,341.00 1045.00,341.00 1045.00,341.00 1010.00,341.00 1010.00,341.00 1010.00,341.00 996.00,342.00 996.00,342.00 996.00,342.00 996.00,93.00 996.00,93.00 Z",
    attr: {
      id: "_G2",
      "data-name": "G2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone G", zoneId: "3" },
  };
  var _GG = {
    path:
      "M 1619.00,850.00 C 1619.00,850.00 1659.00,826.30 1659.00,826.30 1659.00,826.30 1731.00,783.95 1731.00,783.95 1731.00,783.95 1774.00,758.71 1774.00,758.71 1774.00,758.71 1797.00,746.00 1797.00,746.00 1802.02,763.31 1808.04,799.36 1810.73,818.00 1810.73,818.00 1815.00,848.00 1815.00,848.00 1815.00,848.00 1762.00,864.33 1762.00,864.33 1762.00,864.33 1751.00,867.35 1751.00,867.35 1751.00,867.35 1707.00,881.04 1707.00,881.04 1707.00,881.04 1686.00,887.86 1686.00,887.86 1686.00,887.86 1667.00,893.96 1667.00,893.96 1667.00,893.96 1655.00,897.33 1655.00,897.33 1652.22,898.26 1645.03,901.35 1642.50,899.89 1639.98,898.43 1636.78,888.08 1635.55,885.00 1635.55,885.00 1624.73,861.00 1624.73,861.00 1624.73,861.00 1619.00,850.00 1619.00,850.00 Z",
    attr: {
      id: "_GG",
      "data-name": "GG",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone GG", zoneId: "2" },
  };
  var _H = {
    path: "M 1066.00,471.00 C 1066.00,471.00 1112.00,471.00 1112.00,471.00 1112.00,471.00 1112.00,685.00 1112.00,685.00 1112.00,685.00 1066.00,685.00 1066.00,685.00 1066.00,685.00 1066.00,471.00 1066.00,471.00 Z",
    attr: {
      id: "_H",
      "data-name": "H",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone H", zoneId: "2" },
  };
  var _H2 = {
    path: "M 1062.00,93.00 C 1062.00,93.00 1115.00,93.00 1115.00,93.00 1115.00,93.00 1115.00,341.00 1115.00,341.00 1115.00,341.00 1062.00,341.00 1062.00,341.00 1062.00,341.00 1062.00,93.00 1062.00,93.00 Z",
    attr: {
      id: "_H2",
      "data-name": "H2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone H", zoneId: "3" },
  };
  var _HH = {
    path:
      "M 1647.00,917.00 C 1647.00,917.00 1682.00,906.04 1682.00,906.04 1682.00,906.04 1772.00,878.00 1772.00,878.00 1772.00,878.00 1784.00,874.65 1784.00,874.65 1784.00,874.65 1804.00,868.14 1804.00,868.14 1804.00,868.14 1815.40,866.11 1815.40,866.11 1815.40,866.11 1817.03,872.00 1817.03,872.00 1817.03,872.00 1818.42,887.00 1818.42,887.00 1818.42,887.00 1822.00,958.00 1822.00,958.00 1822.00,958.00 1787.00,961.84 1787.00,961.84 1787.00,961.84 1743.00,966.72 1743.00,966.72 1743.00,966.72 1660.00,976.00 1660.00,976.00 1660.00,976.00 1651.63,934.00 1651.63,934.00 1651.63,934.00 1647.00,917.00 1647.00,917.00 Z",
    attr: {
      id: "_HH",
      "data-name": "HH",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone HH", zoneId: "2" },
  };
  var _I = {
    path: "M 1129.00,471.00 C 1129.00,471.00 1180.00,471.00 1180.00,471.00 1180.00,471.00 1180.00,685.00 1180.00,685.00 1180.00,685.00 1129.00,685.00 1129.00,685.00 1129.00,685.00 1129.00,471.00 1129.00,471.00 Z",
    attr: {
      id: "_I",
      "data-name": "I",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone I", zoneId: "2" },
  };
  var _I2 = {
    path: "M 1132.00,93.00 C 1132.00,93.00 1185.00,93.00 1185.00,93.00 1185.00,93.00 1185.00,341.00 1185.00,341.00 1185.00,341.00 1132.00,341.00 1132.00,341.00 1132.00,341.00 1132.00,93.00 1132.00,93.00 Z",
    attr: {
      id: "_I2",
      "data-name": "I2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone I", zoneId: "3" },
  };
  var _J = {
    path: "M 1197.00,471.00 C 1197.00,471.00 1249.00,471.00 1249.00,471.00 1249.00,471.00 1249.00,685.00 1249.00,685.00 1249.00,685.00 1197.00,685.00 1197.00,685.00 1197.00,685.00 1197.00,471.00 1197.00,471.00 Z",
    attr: {
      id: "_J",
      "data-name": "J",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "J", zone: "Lower Zone", zoneId: "2" },
  };
  var _J2 = {
    path:
      "M 1202.00,93.04 C 1202.00,93.04 1215.00,93.04 1215.00,93.04 1215.00,93.04 1226.00,94.00 1226.00,94.00 1226.00,94.00 1250.00,94.00 1250.00,94.00 1250.00,94.00 1250.00,341.00 1250.00,341.00 1250.00,341.00 1202.00,341.00 1202.00,341.00 1202.00,341.00 1202.00,93.04 1202.00,93.04 Z",
    attr: {
      id: "_J2",
      "data-name": "J2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone J", zoneId: "3" },
  };
  var _JJ = {
    path:
      "M 1664.00,1047.00 C 1664.00,1047.00 1662.95,1009.00 1662.95,1009.00 1662.68,1005.60 1660.84,996.40 1662.95,993.74 1664.47,991.91 1671.53,991.43 1674.00,991.16 1674.00,991.16 1704.00,988.00 1704.00,988.00 1704.00,988.00 1719.00,986.17 1719.00,986.17 1719.00,986.17 1787.00,978.84 1787.00,978.84 1787.00,978.84 1822.00,975.00 1822.00,975.00 1822.00,975.00 1823.00,1005.00 1823.00,1005.00 1823.00,1005.00 1822.00,1022.00 1822.00,1022.00 1822.00,1022.00 1822.00,1042.00 1822.00,1042.00 1822.00,1042.00 1821.00,1061.00 1821.00,1061.00 1821.00,1061.00 1775.00,1057.17 1775.00,1057.17 1775.00,1057.17 1742.00,1054.17 1742.00,1054.17 1742.00,1054.17 1664.00,1047.00 1664.00,1047.00 Z",
    attr: {
      id: "_JJ",
      "data-name": "JJ",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone JJ", zoneId: "2" },
  };
  var _K = {
    path:
      "M 1266.00,411.00 C 1266.00,411.00 1292.00,411.00 1292.00,411.00 1292.00,411.00 1312.00,412.00 1312.00,412.00 1312.00,412.00 1312.00,538.00 1312.00,538.00 1312.00,538.00 1313.00,553.00 1313.00,553.00 1313.00,553.00 1313.00,685.00 1313.00,685.00 1313.00,685.00 1266.00,685.00 1266.00,685.00 1266.00,685.00 1266.00,411.00 1266.00,411.00 Z",
    attr: {
      id: "_K",
      "data-name": "K",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone K", zoneId: "2" },
  };
  var _K2 = {
    path:
      "M 1267.00,94.00 C 1267.00,94.00 1301.00,94.00 1301.00,94.00 1301.00,94.00 1318.00,95.00 1318.00,95.00 1318.00,95.00 1318.00,340.00 1318.00,340.00 1318.00,340.00 1304.00,340.00 1304.00,340.00 1304.00,340.00 1289.00,341.00 1289.00,341.00 1289.00,341.00 1267.00,341.00 1267.00,341.00 1267.00,341.00 1267.00,94.00 1267.00,94.00 Z",
    attr: {
      id: "_K2",
      "data-name": "K2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone K", zoneId: "3" },
  };
  var _KK = {
    path:
      "M 1663.00,1064.00 C 1663.00,1064.00 1741.00,1071.17 1741.00,1071.17 1741.00,1071.17 1820.00,1078.00 1820.00,1078.00 1820.00,1078.00 1818.09,1102.00 1818.09,1102.00 1818.09,1102.00 1814.91,1152.00 1814.91,1152.00 1814.91,1152.00 1813.00,1177.00 1813.00,1177.00 1813.00,1177.00 1777.00,1164.67 1777.00,1164.67 1777.00,1164.67 1745.00,1153.99 1745.00,1153.99 1745.00,1153.99 1734.00,1149.67 1734.00,1149.67 1734.00,1149.67 1704.00,1139.63 1704.00,1139.63 1704.00,1139.63 1651.00,1121.00 1651.00,1121.00 1651.00,1121.00 1655.85,1104.00 1655.85,1104.00 1658.60,1093.84 1662.53,1074.28 1663.00,1064.00 Z",
    attr: {
      id: "_KK",
      "data-name": "KK",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone KK", zoneId: "2" },
  };
  var _L = {
    path:
      "M 1329.00,412.00 C 1329.00,412.00 1345.00,412.00 1345.00,412.00 1345.00,412.00 1361.00,413.00 1361.00,413.00 1361.00,413.00 1415.00,413.00 1415.00,413.00 1415.00,413.00 1411.08,440.00 1411.08,440.00 1411.08,440.00 1403.41,486.00 1403.41,486.00 1403.41,486.00 1382.58,614.00 1382.58,614.00 1382.58,614.00 1371.00,685.00 1371.00,685.00 1371.00,685.00 1330.00,685.00 1330.00,685.00 1330.00,685.00 1330.00,551.00 1330.00,551.00 1330.00,551.00 1329.00,536.00 1329.00,536.00 1329.00,536.00 1329.00,412.00 1329.00,412.00 Z",
    attr: {
      id: "_L",
      "data-name": "L",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone L", zoneId: "2" },
  };
  var _L2 = {
    path:
      "M 1335.00,95.00 C 1335.00,95.00 1389.00,96.00 1389.00,96.00 1389.00,96.00 1401.00,96.96 1401.00,96.96 1401.00,96.96 1415.00,96.96 1415.00,96.96 1415.00,96.96 1430.00,98.00 1430.00,98.00 1430.00,98.00 1460.00,100.00 1460.00,100.00 1460.00,100.00 1448.80,157.00 1448.80,157.00 1448.80,157.00 1423.55,284.00 1423.55,284.00 1423.55,284.00 1415.55,324.00 1415.55,324.00 1415.55,324.00 1412.00,344.00 1412.00,344.00 1412.00,344.00 1369.00,340.00 1369.00,340.00 1369.00,340.00 1335.00,340.00 1335.00,340.00 1335.00,340.00 1335.00,95.00 1335.00,95.00 Z",
    attr: {
      id: "_L2",
      "data-name": "L2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone L", zoneId: "3" },
  };
  var _LL = {
    path:
      "M 1645.00,1136.00 C 1645.00,1136.00 1766.00,1178.33 1766.00,1178.33 1766.00,1178.33 1812.00,1194.00 1812.00,1194.00 1805.66,1228.80 1798.20,1263.40 1787.00,1297.00 1781.18,1295.09 1776.28,1291.17 1771.00,1288.13 1771.00,1288.13 1737.00,1267.53 1737.00,1267.53 1737.00,1267.53 1658.00,1219.50 1658.00,1219.50 1658.00,1219.50 1628.00,1201.26 1628.00,1201.26 1625.69,1199.85 1618.17,1196.10 1617.44,1193.74 1616.51,1190.75 1623.45,1181.02 1625.14,1178.00 1632.40,1164.99 1640.12,1150.06 1645.00,1136.00 Z",
    attr: {
      id: "_LL",
      "data-name": "LL",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone LL", zoneId: "2" },
  };
  var _M = {
    path:
      "M 1432.00,414.00 C 1449.71,414.39 1490.27,424.52 1508.00,430.02 1508.00,430.02 1533.00,438.00 1533.00,438.00 1533.00,438.00 1527.69,455.00 1527.69,455.00 1527.69,455.00 1512.69,494.00 1512.69,494.00 1512.69,494.00 1461.58,629.00 1461.58,629.00 1461.58,629.00 1446.00,670.00 1446.00,670.00 1446.00,670.00 1439.58,683.98 1439.58,683.98 1439.58,683.98 1432.00,685.00 1432.00,685.00 1432.00,685.00 1388.00,685.00 1388.00,685.00 1388.00,685.00 1397.58,627.00 1397.58,627.00 1397.58,627.00 1418.41,499.00 1418.41,499.00 1418.41,499.00 1427.25,446.00 1427.25,446.00 1427.25,446.00 1432.00,414.00 1432.00,414.00 Z",
    attr: {
      id: "_M",
      "data-name": "M",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone M", zoneId: "2" },
  };
  var _M2 = {
    path:
      "M 1428.00,347.00 C 1428.00,347.00 1440.00,287.00 1440.00,287.00 1440.00,287.00 1457.45,199.00 1457.45,199.00 1457.45,199.00 1464.58,164.00 1464.58,164.00 1464.58,164.00 1473.45,119.00 1473.45,119.00 1474.05,116.00 1475.43,105.78 1478.31,104.77 1480.10,104.14 1485.05,105.42 1487.00,105.88 1487.00,105.88 1507.00,111.12 1507.00,111.12 1507.00,111.12 1550.00,124.05 1550.00,124.05 1550.00,124.05 1586.00,137.00 1586.00,137.00 1586.00,137.00 1579.74,153.00 1579.74,153.00 1579.74,153.00 1563.74,194.00 1563.74,194.00 1563.74,194.00 1529.14,282.00 1529.14,282.00 1529.14,282.00 1496.00,366.00 1496.00,366.00 1496.00,366.00 1469.00,357.02 1469.00,357.02 1469.00,357.02 1428.00,347.00 1428.00,347.00 Z",
    attr: {
      id: "_M2",
      "data-name": "M2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone M", zoneId: "3" },
  };
  var _MM = {
    path:
      "M 1606.00,1208.00 C 1606.00,1208.00 1653.00,1235.50 1653.00,1235.50 1653.00,1235.50 1738.00,1287.26 1738.00,1287.26 1738.00,1287.26 1770.00,1306.72 1770.00,1306.72 1772.48,1308.28 1779.41,1311.57 1780.22,1314.26 1780.88,1316.46 1778.76,1321.78 1777.95,1324.00 1777.95,1324.00 1769.28,1346.00 1769.28,1346.00 1755.58,1377.77 1741.99,1404.93 1723.00,1434.00 1723.00,1434.00 1702.83,1413.00 1702.83,1413.00 1702.83,1413.00 1674.05,1381.99 1674.05,1381.99 1674.05,1381.99 1663.72,1370.09 1663.72,1370.09 1663.72,1370.09 1629.83,1333.00 1629.83,1333.00 1629.83,1333.00 1620.17,1323.00 1620.17,1323.00 1620.17,1323.00 1609.84,1311.01 1609.84,1311.01 1609.84,1311.01 1601.87,1302.91 1601.87,1302.91 1601.87,1302.91 1571.58,1269.04 1571.58,1269.04 1571.58,1269.04 1565.00,1262.00 1565.00,1262.00 1576.11,1249.21 1598.04,1222.17 1606.00,1208.00 Z",
    attr: {
      id: "_MM",
      "data-name": "MM",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone MM", zoneId: "2" },
  };
  var _MM2 = {
    path:
      "M 1624.00,1572.00 C 1630.06,1574.37 1635.02,1579.59 1640.00,1583.75 1640.00,1583.75 1668.00,1607.59 1668.00,1607.59 1668.00,1607.59 1768.00,1694.83 1768.00,1694.83 1768.00,1694.83 1801.00,1725.00 1801.00,1725.00 1803.65,1727.64 1811.39,1734.60 1811.99,1738.00 1812.74,1742.27 1802.09,1752.81 1798.99,1756.00 1798.99,1756.00 1768.00,1787.17 1768.00,1787.17 1762.59,1792.06 1757.71,1797.89 1751.00,1801.00 1751.00,1801.00 1739.59,1788.00 1739.59,1788.00 1739.59,1788.00 1710.41,1753.00 1710.41,1753.00 1710.41,1753.00 1639.71,1668.00 1639.71,1668.00 1627.10,1653.61 1600.08,1623.52 1590.00,1609.00 1590.00,1609.00 1599.96,1599.96 1599.96,1599.96 1599.96,1599.96 1624.00,1572.00 1624.00,1572.00 Z",
    attr: {
      id: "_MM2",
      "data-name": "MM2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone MM", zoneId: "3" },
  };
  var _N = {
    path:
      "M 1387.00,1353.00 C 1387.00,1353.00 1431.00,1342.00 1431.00,1342.00 1431.00,1342.00 1442.58,1373.00 1442.58,1373.00 1442.58,1373.00 1461.26,1422.00 1461.26,1422.00 1461.26,1422.00 1473.37,1455.00 1473.37,1455.00 1473.37,1455.00 1486.26,1488.00 1486.26,1488.00 1486.26,1488.00 1499.37,1524.00 1499.37,1524.00 1499.37,1524.00 1525.00,1593.00 1525.00,1593.00 1507.60,1601.09 1473.14,1611.67 1454.00,1616.85 1454.00,1616.85 1430.00,1623.00 1430.00,1623.00 1430.00,1623.00 1419.08,1554.00 1419.08,1554.00 1419.08,1554.00 1397.58,1422.00 1397.58,1422.00 1397.58,1422.00 1387.00,1353.00 1387.00,1353.00 Z",
    attr: {
      id: "_N",
      "data-name": "N",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone N", zoneId: "2" },
  };
  var _N2 = {
    path:
      "M 1432.00,1689.00 C 1445.43,1687.11 1460.12,1682.29 1473.00,1678.00 1476.24,1676.92 1485.92,1672.70 1488.73,1674.12 1491.74,1675.66 1494.51,1686.54 1495.67,1690.00 1495.67,1690.00 1510.67,1733.00 1510.67,1733.00 1510.67,1733.00 1519.99,1759.00 1519.99,1759.00 1519.99,1759.00 1530.01,1789.00 1530.01,1789.00 1530.01,1789.00 1555.33,1861.00 1555.33,1861.00 1555.33,1861.00 1571.00,1907.00 1571.00,1907.00 1571.00,1907.00 1537.00,1918.72 1537.00,1918.72 1516.94,1924.74 1496.59,1930.13 1476.00,1934.00 1476.00,1934.00 1470.42,1902.00 1470.42,1902.00 1470.42,1902.00 1462.45,1859.00 1462.45,1859.00 1462.45,1859.00 1460.54,1847.00 1460.54,1847.00 1460.54,1847.00 1443.58,1755.00 1443.58,1755.00 1443.58,1755.00 1436.55,1716.00 1436.55,1716.00 1436.55,1716.00 1432.00,1689.00 1432.00,1689.00 Z",
    attr: {
      id: "_N2",
      "data-name": "N2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone N", zoneId: "3" },
  };
  var _NN = {
    path:
      "M 1546.00,1403.00 C 1546.00,1403.00 1601.00,1365.72 1601.00,1365.72 1601.00,1365.72 1615.00,1356.09 1615.00,1356.09 1616.82,1354.82 1619.78,1352.43 1622.00,1352.19 1625.25,1351.84 1627.90,1354.95 1630.00,1357.01 1630.00,1357.01 1646.17,1375.00 1646.17,1375.00 1646.17,1375.00 1673.13,1404.09 1673.13,1404.09 1673.13,1404.09 1686.15,1418.83 1686.15,1418.83 1686.15,1418.83 1703.42,1437.96 1703.42,1437.96 1708.00,1443.04 1709.21,1442.57 1713.00,1449.00 1713.00,1449.00 1696.58,1470.00 1696.58,1470.00 1681.46,1488.45 1665.11,1505.74 1647.00,1521.27 1640.65,1526.72 1627.10,1539.22 1620.00,1542.00 1620.00,1542.00 1570.73,1451.00 1570.73,1451.00 1570.73,1451.00 1553.91,1420.00 1553.91,1420.00 1553.91,1420.00 1546.00,1403.00 1546.00,1403.00 Z",
    attr: {
      id: "_NN",
      "data-name": "NN",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone NN", zoneId: "2" },
  };
  var _NN2 = {
    path:
      "M 1577.00,1620.00 C 1577.00,1620.00 1592.25,1637.00 1592.25,1637.00 1592.25,1637.00 1616.41,1666.00 1616.41,1666.00 1616.41,1666.00 1631.59,1683.00 1631.59,1683.00 1631.59,1683.00 1689.75,1753.00 1689.75,1753.00 1689.75,1753.00 1723.08,1793.00 1723.08,1793.00 1723.08,1793.00 1739.00,1813.00 1739.00,1813.00 1739.00,1813.00 1721.00,1826.63 1721.00,1826.63 1721.00,1826.63 1690.00,1848.31 1690.00,1848.31 1686.78,1850.38 1674.47,1858.96 1671.26,1858.18 1668.77,1857.58 1664.73,1849.43 1663.26,1847.00 1663.26,1847.00 1642.54,1810.00 1642.54,1810.00 1642.54,1810.00 1574.15,1689.00 1574.15,1689.00 1574.15,1689.00 1556.12,1657.00 1556.12,1657.00 1554.57,1654.41 1549.12,1645.72 1549.28,1643.09 1549.46,1640.12 1553.78,1637.84 1556.00,1636.28 1563.27,1631.18 1570.45,1626.02 1577.00,1620.00 Z",
    attr: {
      id: "_NN2",
      "data-name": "NN2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone NN", zoneId: "3" },
  };
  var _O = {
    path:
      "M 1337.00,1359.00 C 1351.59,1359.00 1355.58,1358.63 1370.00,1356.00 1370.00,1356.00 1372.58,1373.00 1372.58,1373.00 1372.58,1373.00 1378.73,1411.00 1378.73,1411.00 1378.73,1411.00 1402.42,1557.00 1402.42,1557.00 1402.42,1557.00 1410.59,1607.00 1410.59,1607.00 1411.18,1610.54 1414.03,1622.70 1412.01,1625.27 1410.17,1627.60 1396.49,1629.21 1393.00,1629.73 1393.00,1629.73 1353.00,1634.00 1353.00,1634.00 1353.00,1634.00 1337.00,1634.00 1337.00,1634.00 1337.00,1634.00 1337.00,1359.00 1337.00,1359.00 Z",
    attr: {
      id: "_O",
      "data-name": "O",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone O", zoneId: "2" },
  };
  var _O2 = {
    path:
      "M 1414.00,1692.00 C 1414.00,1692.00 1418.80,1713.00 1418.80,1713.00 1418.80,1713.00 1427.61,1761.00 1427.61,1761.00 1427.61,1761.00 1450.54,1886.00 1450.54,1886.00 1450.54,1886.00 1460.00,1937.00 1460.00,1937.00 1438.82,1940.86 1417.45,1943.59 1396.00,1945.09 1396.00,1945.09 1386.00,1946.00 1386.00,1946.00 1386.00,1946.00 1365.00,1947.00 1365.00,1947.00 1365.00,1947.00 1341.00,1947.00 1341.00,1947.00 1341.00,1947.00 1341.00,1696.00 1341.00,1696.00 1341.00,1696.00 1376.00,1696.00 1376.00,1696.00 1376.00,1696.00 1388.00,1695.09 1388.00,1695.09 1388.00,1695.09 1414.00,1692.00 1414.00,1692.00 Z",
    attr: {
      id: "_O2",
      "data-name": "O2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone O", zoneId: "3" },
  };
  var _OO = {
    path:
      "M 1481.00,1321.00 C 1481.00,1321.00 1503.00,1360.00 1503.00,1360.00 1503.00,1360.00 1545.08,1438.00 1545.08,1438.00 1545.08,1438.00 1584.49,1511.00 1584.49,1511.00 1584.49,1511.00 1607.00,1554.00 1607.00,1554.00 1596.17,1561.05 1578.00,1569.80 1566.00,1575.31 1558.33,1578.83 1548.16,1584.52 1540.00,1586.00 1540.00,1586.00 1529.74,1558.00 1529.74,1558.00 1529.74,1558.00 1514.74,1518.00 1514.74,1518.00 1514.74,1518.00 1491.94,1457.00 1491.94,1457.00 1491.94,1457.00 1470.95,1402.00 1470.95,1402.00 1470.95,1402.00 1452.67,1354.00 1452.67,1354.00 1452.67,1354.00 1447.68,1337.41 1447.68,1337.41 1447.68,1337.41 1457.00,1332.20 1457.00,1332.20 1457.00,1332.20 1481.00,1321.00 1481.00,1321.00 Z",
    attr: {
      id: "_OO",
      "data-name": "OO",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone OO", zoneId: "2" },
  };
  var _OO2 = {
    path:
      "M 1505.00,1665.00 C 1505.00,1665.00 1534.00,1651.00 1534.00,1651.00 1534.00,1651.00 1559.44,1696.00 1559.44,1696.00 1559.44,1696.00 1619.17,1802.00 1619.17,1802.00 1619.17,1802.00 1645.08,1848.00 1645.08,1848.00 1645.08,1848.00 1656.00,1869.00 1656.00,1869.00 1656.00,1869.00 1637.00,1878.75 1637.00,1878.75 1621.65,1886.43 1603.12,1895.41 1587.00,1901.00 1587.00,1901.00 1576.94,1872.00 1576.94,1872.00 1576.94,1872.00 1563.66,1834.00 1563.66,1834.00 1563.66,1834.00 1559.34,1823.00 1559.34,1823.00 1559.34,1823.00 1537.31,1759.00 1537.31,1759.00 1537.31,1759.00 1514.33,1694.00 1514.33,1694.00 1514.33,1694.00 1505.00,1665.00 1505.00,1665.00 Z",
    attr: {
      id: "_OO2",
      "data-name": "OO2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone OO", zoneId: "3" },
  };
  var _P = {
    path:
      "M 1273.00,1358.00 C 1273.00,1358.00 1320.00,1358.00 1320.00,1358.00 1320.00,1358.00 1320.00,1634.00 1320.00,1634.00 1320.00,1634.00 1291.00,1634.00 1291.00,1634.00 1291.00,1634.00 1273.00,1635.00 1273.00,1635.00 1273.00,1635.00 1273.00,1358.00 1273.00,1358.00 Z",
    attr: {
      id: "_P",
      "data-name": "P",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone P", zoneId: "2" },
  };
  var _P2 = {
    path: "M 1272.00,1696.00 C 1272.00,1696.00 1324.00,1696.00 1324.00,1696.00 1324.00,1696.00 1324.00,1946.00 1324.00,1946.00 1324.00,1946.00 1272.00,1946.00 1272.00,1946.00 1272.00,1946.00 1272.00,1696.00 1272.00,1696.00 Z",
    attr: {
      id: "_P2",
      "data-name": "P2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone P", zoneId: "3" },
  };
  var _PDFF = {
    path:
      "M 1687.00,791.00 C 1687.00,791.00 1659.00,807.36 1659.00,807.36 1659.00,807.36 1632.00,823.05 1632.00,823.05 1632.00,823.05 1618.00,831.29 1618.00,831.29 1616.25,832.33 1613.27,834.45 1611.18,834.10 1608.62,833.67 1606.04,829.06 1604.67,827.00 1604.67,827.00 1591.11,808.00 1591.11,808.00 1586.26,801.69 1578.53,792.70 1573.00,787.00 1570.75,784.68 1566.95,781.65 1568.16,778.00 1569.09,775.23 1582.22,760.84 1585.00,758.00 1585.00,758.00 1612.85,726.91 1612.85,726.91 1616.81,722.72 1619.79,717.68 1625.00,715.00 1625.00,715.00 1670.08,770.00 1670.08,770.00 1670.08,770.00 1687.00,791.00 1687.00,791.00 Z",
    attr: {
      id: "_PDFF",
      "data-name": "PDFF",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone PDFF", zoneId: "2" },
  };
  var _PDNN = {
    path:
      "M 1537.00,1389.00 C 1537.00,1389.00 1507.42,1334.00 1507.42,1334.00 1507.42,1334.00 1499.87,1320.00 1499.87,1320.00 1499.05,1318.40 1497.57,1316.03 1497.89,1314.18 1498.44,1311.10 1506.32,1307.25 1509.00,1305.58 1520.07,1298.68 1530.79,1291.10 1541.00,1283.00 1543.80,1280.78 1550.37,1274.33 1554.00,1275.32 1557.47,1276.27 1565.22,1285.93 1567.95,1289.00 1567.95,1289.00 1599.27,1323.09 1599.27,1323.09 1601.89,1325.93 1611.12,1335.02 1609.75,1338.83 1608.99,1340.96 1605.82,1342.81 1604.00,1344.00 1604.00,1344.00 1591.00,1352.71 1591.00,1352.71 1591.00,1352.71 1556.00,1376.74 1556.00,1376.74 1550.76,1380.32 1542.56,1386.63 1537.00,1389.00 Z",
    attr: {
      id: "_PDNN",
      "data-name": "PDNN",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone PDNN", zoneId: "2" },
  };
  var _PP = {
    path:
      "M 564.00,1321.00 C 564.00,1321.00 598.00,1337.00 598.00,1337.00 598.00,1337.00 565.29,1408.00 565.29,1408.00 565.29,1408.00 522.45,1502.00 522.45,1502.00 522.45,1502.00 482.00,1590.00 482.00,1590.00 467.54,1581.56 450.22,1555.57 441.19,1541.00 439.12,1537.65 430.86,1525.23 431.08,1522.00 431.26,1519.45 440.41,1506.95 442.41,1504.00 442.41,1504.00 469.04,1464.00 469.04,1464.00 469.04,1464.00 532.82,1368.00 532.82,1368.00 532.82,1368.00 552.82,1338.00 552.82,1338.00 552.82,1338.00 564.00,1321.00 564.00,1321.00 Z",
    attr: {
      id: "_PP",
      "data-name": "PP",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone PP", zoneId: "2" },
  };
  var _PP2 = {
    path:
      "M 381.00,1870.00 C 381.00,1870.00 415.10,1812.00 415.10,1812.00 415.10,1812.00 476.95,1708.00 476.95,1708.00 476.95,1708.00 501.29,1667.00 501.29,1667.00 501.29,1667.00 511.26,1652.41 511.26,1652.41 511.26,1652.41 522.00,1655.72 522.00,1655.72 522.00,1655.72 546.00,1665.00 546.00,1665.00 546.00,1665.00 524.68,1721.00 524.68,1721.00 524.68,1721.00 472.63,1856.00 472.63,1856.00 472.63,1856.00 455.00,1903.00 455.00,1903.00 455.00,1903.00 433.00,1894.85 433.00,1894.85 433.00,1894.85 400.00,1879.75 400.00,1879.75 400.00,1879.75 381.00,1870.00 381.00,1870.00 Z",
    attr: {
      id: "_PP2",
      "data-name": "PP2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone PP", zoneId: "3" },
  };
  var _Q = {
    path: "M 1201.00,1358.00 C 1201.00,1358.00 1256.00,1358.00 1256.00,1358.00 1256.00,1358.00 1256.00,1635.00 1256.00,1635.00 1256.00,1635.00 1201.00,1635.00 1201.00,1635.00 1201.00,1635.00 1201.00,1358.00 1201.00,1358.00 Z",
    attr: {
      id: "_Q",
      "data-name": "Q",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone Q", zoneId: "2" },
  };
  var _Q2 = {
    path: "M 1208.00,1696.00 C 1208.00,1696.00 1255.00,1696.00 1255.00,1696.00 1255.00,1696.00 1255.00,1946.00 1255.00,1946.00 1255.00,1946.00 1208.00,1946.00 1208.00,1946.00 1208.00,1946.00 1208.00,1696.00 1208.00,1696.00 Z",
    attr: {
      id: "_Q2",
      "data-name": "Q2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone Q", zoneId: "3" },
  };
  var _QQ = {
    path:
      "M 517.00,1279.00 C 523.64,1283.75 534.55,1295.69 541.00,1301.99 543.48,1304.41 549.21,1308.48 549.29,1312.01 549.36,1315.06 542.24,1325.16 540.17,1328.00 540.17,1328.00 517.30,1362.00 517.30,1362.00 517.30,1362.00 456.71,1453.00 456.71,1453.00 456.71,1453.00 436.59,1483.00 436.59,1483.00 436.59,1483.00 421.00,1506.00 421.00,1506.00 421.00,1506.00 412.32,1489.00 412.32,1489.00 412.32,1489.00 387.58,1435.00 387.58,1435.00 387.58,1435.00 377.00,1409.00 377.00,1409.00 377.00,1409.00 429.00,1361.09 429.00,1361.09 429.00,1361.09 479.17,1314.42 479.17,1314.42 479.17,1314.42 488.99,1305.84 488.99,1305.84 488.99,1305.84 517.00,1279.00 517.00,1279.00 Z",
    attr: {
      id: "_QQ",
      "data-name": "QQ",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone QQ", zoneId: "2" },
  };
  var _QQ2 = {
    path:
      "M 303.00,1817.00 C 303.00,1817.00 356.29,1753.00 356.29,1753.00 356.29,1753.00 366.57,1742.00 366.57,1742.00 366.57,1742.00 421.59,1676.00 421.59,1676.00 421.59,1676.00 453.59,1639.00 453.59,1639.00 455.73,1636.43 463.58,1626.13 466.17,1625.45 468.82,1624.75 472.86,1628.19 475.00,1629.66 475.00,1629.66 496.00,1643.00 496.00,1643.00 496.00,1643.00 478.64,1673.00 478.64,1673.00 478.64,1673.00 462.05,1701.00 462.05,1701.00 462.05,1701.00 399.74,1806.00 399.74,1806.00 399.74,1806.00 376.46,1845.00 376.46,1845.00 374.89,1847.67 369.09,1858.78 366.73,1859.59 363.64,1860.64 352.96,1853.21 350.00,1851.31 350.00,1851.31 319.00,1830.15 319.00,1830.15 313.69,1826.27 307.18,1822.02 303.00,1817.00 Z",
    attr: {
      id: "_QQ2",
      "data-name": "QQ2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone QQ", zoneId: "3" },
  };
  var _R = {
    path: "M 1134.00,1358.00 C 1134.00,1358.00 1184.00,1358.00 1184.00,1358.00 1184.00,1358.00 1184.00,1635.00 1184.00,1635.00 1184.00,1635.00 1134.00,1635.00 1134.00,1635.00 1134.00,1635.00 1134.00,1358.00 1134.00,1358.00 Z",
    attr: {
      id: "_R",
      "data-name": "R",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone R", zoneId: "2" },
  };
  var _R2 = {
    path: "M 1142.00,1696.00 C 1142.00,1696.00 1191.00,1696.00 1191.00,1696.00 1191.00,1696.00 1191.00,1946.00 1191.00,1946.00 1191.00,1946.00 1142.00,1946.00 1142.00,1946.00 1142.00,1946.00 1142.00,1696.00 1142.00,1696.00 Z",
    attr: {
      id: "_R2",
      "data-name": "R2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone R", zoneId: "3" },
  };
  var _RR = {
    path:
      "M 337.00,1220.00 C 337.00,1220.00 364.00,1218.37 364.00,1218.37 364.00,1218.37 399.00,1201.75 399.00,1201.75 399.00,1201.75 422.00,1190.31 422.00,1190.31 424.08,1189.35 429.95,1186.62 431.99,1186.70 435.13,1186.82 437.23,1189.84 439.13,1192.00 439.13,1192.00 451.15,1206.00 451.15,1206.00 451.15,1206.00 483.08,1242.00 483.08,1242.00 490.27,1249.81 500.13,1259.55 506.00,1268.00 506.00,1268.00 428.00,1340.91 428.00,1340.91 428.00,1340.91 415.09,1352.16 415.09,1352.16 415.09,1352.16 384.00,1381.17 384.00,1381.17 384.00,1381.17 370.00,1394.00 370.00,1394.00 370.00,1394.00 344.67,1326.00 344.67,1326.00 341.11,1315.45 337.02,1308.40 337.00,1297.00 337.00,1297.00 337.00,1220.00 337.00,1220.00 Z",
    attr: {
      id: "_RR",
      "data-name": "RR",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone RR", zoneId: "2" },
  };
  var _RR2 = {
    path:
      "M 291.00,1807.00 C 284.12,1803.90 273.65,1793.52 268.00,1788.01 268.00,1788.01 257.00,1777.99 257.00,1777.99 257.00,1777.99 236.73,1756.00 236.73,1756.00 236.73,1756.00 226.82,1744.00 226.82,1744.00 225.39,1742.10 223.34,1739.73 224.25,1737.17 225.04,1734.93 230.98,1730.44 233.00,1728.75 233.00,1728.75 256.00,1709.75 256.00,1709.75 256.00,1709.75 342.00,1638.59 342.00,1638.59 342.00,1638.59 399.00,1591.41 399.00,1591.41 399.00,1591.41 413.00,1579.92 413.00,1579.92 414.81,1578.46 417.51,1575.94 420.00,1576.11 423.86,1576.37 428.29,1583.06 430.42,1586.00 430.42,1586.00 443.17,1602.00 443.17,1602.00 447.20,1606.46 450.36,1608.91 454.00,1614.00 454.00,1614.00 429.57,1643.00 429.57,1643.00 429.57,1643.00 418.43,1655.00 418.43,1655.00 418.43,1655.00 352.57,1734.00 352.57,1734.00 352.57,1734.00 342.29,1745.00 342.29,1745.00 342.29,1745.00 291.00,1807.00 291.00,1807.00 Z",
    attr: {
      id: "_RR2",
      "data-name": "RR2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone RR", zoneId: "3" },
  };
  var _S = {
    path: "M 1067.00,1358.00 C 1067.00,1358.00 1117.00,1358.00 1117.00,1358.00 1117.00,1358.00 1117.00,1635.00 1117.00,1635.00 1117.00,1635.00 1067.00,1635.00 1067.00,1635.00 1067.00,1635.00 1067.00,1358.00 1067.00,1358.00 Z",
    attr: {
      id: "_S",
      "data-name": "S",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone S", zoneId: "2" },
  };
  var _S2 = {
    path: "M 1073.00,1696.00 C 1073.00,1696.00 1125.00,1696.00 1125.00,1696.00 1125.00,1696.00 1125.00,1946.00 1125.00,1946.00 1125.00,1946.00 1073.00,1946.00 1073.00,1946.00 1073.00,1946.00 1073.00,1696.00 1073.00,1696.00 Z",
    attr: {
      id: "_S2",
      "data-name": "S2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone S", zoneId: "3" },
  };
  var _T = {
    path: "M 1000.00,1358.00 C 1000.00,1358.00 1050.00,1358.00 1050.00,1358.00 1050.00,1358.00 1050.00,1635.00 1050.00,1635.00 1050.00,1635.00 1000.00,1635.00 1000.00,1635.00 1000.00,1635.00 1000.00,1358.00 1000.00,1358.00 Z",
    attr: {
      id: "_T",
      "data-name": "T",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone T", zoneId: "2" },
  };
  var _T2 = {
    path:
      "M 1001.00,1696.00 C 1001.00,1696.00 1056.00,1696.00 1056.00,1696.00 1056.00,1696.00 1056.00,1946.00 1056.00,1946.00 1056.00,1946.00 1042.00,1946.00 1042.00,1946.00 1042.00,1946.00 1027.00,1945.00 1027.00,1945.00 1027.00,1945.00 1001.00,1945.00 1001.00,1945.00 1001.00,1945.00 1001.00,1696.00 1001.00,1696.00 Z",
    attr: {
      id: "_T2",
      "data-name": "T2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone T", zoneId: "3" },
  };
  var _U = {
    path: "M 935.00,1358.00 C 935.00,1358.00 983.00,1358.00 983.00,1358.00 983.00,1358.00 983.00,1635.00 983.00,1635.00 983.00,1635.00 935.00,1635.00 935.00,1635.00 935.00,1635.00 935.00,1358.00 935.00,1358.00 Z",
    attr: {
      id: "_U",
      "data-name": "U",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone U", zoneId: "2" },
  };
  var _U2 = {
    path: "M 934.00,1696.00 C 934.00,1696.00 984.00,1696.00 984.00,1696.00 984.00,1696.00 984.00,1945.00 984.00,1945.00 984.00,1945.00 934.00,1945.00 934.00,1945.00 934.00,1945.00 934.00,1696.00 934.00,1696.00 Z",
    attr: {
      id: "_U2",
      "data-name": "U2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone U", zoneId: "3" },
  };
  var _V = {
    path:
      "M 864.00,1358.00 C 864.00,1358.00 918.00,1358.00 918.00,1358.00 918.00,1358.00 918.00,1635.00 918.00,1635.00 918.00,1635.00 888.00,1635.00 888.00,1635.00 888.00,1635.00 876.00,1635.96 876.00,1635.96 876.00,1635.96 864.00,1635.96 864.00,1635.96 864.00,1635.96 864.00,1358.00 864.00,1358.00 Z",
    attr: {
      id: "_V",
      "data-name": "V",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone V", zoneId: "2" },
  };
  var _V2 = {
    path: "M 866.00,1696.00 C 866.00,1696.00 917.00,1696.00 917.00,1696.00 917.00,1696.00 917.00,1945.00 917.00,1945.00 917.00,1945.00 866.00,1945.00 866.00,1945.00 866.00,1945.00 866.00,1696.00 866.00,1696.00 Z",
    attr: {
      id: "_V2",
      "data-name": "V2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone V", zoneId: "3" },
  };
  var _W = {
    path: "M 797.00,1358.00 C 797.00,1358.00 847.00,1358.00 847.00,1358.00 847.00,1358.00 847.00,1636.00 847.00,1636.00 847.00,1636.00 797.00,1636.00 797.00,1636.00 797.00,1636.00 797.00,1358.00 797.00,1358.00 Z",
    attr: {
      id: "_W",
      "data-name": "W",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone W", zoneId: "2" },
  };
  var _W2 = {
    path: "M 801.00,1696.00 C 801.00,1696.00 849.00,1696.00 849.00,1696.00 849.00,1696.00 849.00,1945.00 849.00,1945.00 849.00,1945.00 801.00,1945.00 801.00,1945.00 801.00,1945.00 801.00,1696.00 801.00,1696.00 Z",
    attr: {
      id: "_W2",
      "data-name": "W2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone W", zoneId: "3" },
  };
  var _X = {
    path: "M 732.00,1357.00 C 732.00,1357.00 780.00,1357.00 780.00,1357.00 780.00,1357.00 780.00,1636.00 780.00,1636.00 780.00,1636.00 732.00,1636.00 732.00,1636.00 732.00,1636.00 732.00,1357.00 732.00,1357.00 Z",
    attr: {
      id: "_X",
      "data-name": "X",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone X", zoneId: "2" },
  };
  var _X2 = {
    path:
      "M 734.00,1697.00 C 734.00,1697.00 769.00,1697.00 769.00,1697.00 769.00,1697.00 784.00,1696.00 784.00,1696.00 784.00,1696.00 784.00,1945.00 784.00,1945.00 784.00,1945.00 734.00,1945.00 734.00,1945.00 734.00,1945.00 734.00,1697.00 734.00,1697.00 Z",
    attr: {
      id: "_X2",
      "data-name": "X2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone X", zoneId: "3" },
  };
  var _Y = {
    path:
      "M 631.00,1631.00 C 631.00,1631.00 634.58,1608.00 634.58,1608.00 634.58,1608.00 642.58,1561.00 642.58,1561.00 642.58,1561.00 654.25,1492.00 654.25,1492.00 654.25,1492.00 666.42,1421.00 666.42,1421.00 666.42,1421.00 674.59,1374.00 674.59,1374.00 675.15,1370.63 676.38,1358.86 678.73,1356.99 680.60,1355.51 684.78,1356.13 687.00,1356.42 687.00,1356.42 703.00,1357.00 703.00,1357.00 703.00,1357.00 715.00,1358.00 715.00,1358.00 715.00,1358.00 715.00,1636.00 715.00,1636.00 715.00,1636.00 688.00,1636.00 688.00,1636.00 688.00,1636.00 676.00,1635.09 676.00,1635.09 676.00,1635.09 631.00,1631.00 631.00,1631.00 Z",
    attr: {
      id: "_Y",
      "data-name": "Y",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone Y", zoneId: "2" },
  };
  var _Y2 = {
    path:
      "M 637.00,1688.00 C 657.79,1691.74 696.15,1696.00 717.00,1696.00 717.00,1696.00 717.00,1946.00 717.00,1946.00 717.00,1946.00 673.00,1946.00 673.00,1946.00 673.00,1946.00 656.00,1945.00 656.00,1945.00 656.00,1945.00 617.00,1942.07 617.00,1942.07 617.00,1942.07 592.00,1939.00 592.00,1939.00 589.77,1938.69 584.40,1938.30 583.06,1936.41 581.52,1934.23 584.39,1923.96 585.00,1921.00 585.00,1921.00 595.40,1875.00 595.40,1875.00 595.40,1875.00 605.58,1830.00 605.58,1830.00 605.58,1830.00 637.00,1688.00 637.00,1688.00 Z",
    attr: {
      id: "_Y2",
      "data-name": "Y2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone Y", zoneId: "3" },
  };
  var _Z = {
    path:
      "M 497.00,1597.00 C 497.00,1597.00 519.69,1547.00 519.69,1547.00 519.69,1547.00 573.92,1429.00 573.92,1429.00 573.92,1429.00 599.92,1372.00 599.92,1372.00 599.92,1372.00 612.13,1346.18 612.13,1346.18 614.87,1342.61 618.30,1344.42 622.00,1345.37 622.00,1345.37 649.00,1351.58 649.00,1351.58 651.43,1352.01 658.56,1352.59 659.89,1354.59 661.12,1356.46 658.29,1368.99 657.87,1372.00 657.87,1372.00 648.92,1424.00 648.92,1424.00 648.92,1424.00 625.92,1559.00 625.92,1559.00 625.92,1559.00 614.00,1628.00 614.00,1628.00 604.22,1627.55 584.16,1622.95 574.00,1620.65 547.80,1614.72 521.97,1606.93 497.00,1597.00 Z",
    attr: {
      id: "_Z",
      "data-name": "Z",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone Z", zoneId: "2" },
  };
  var _Z2 = {
    path:
      "M 470.00,1909.00 C 470.00,1909.00 479.26,1884.00 479.26,1884.00 479.26,1884.00 496.26,1840.00 496.26,1840.00 496.26,1840.00 538.86,1730.00 538.86,1730.00 538.86,1730.00 555.44,1687.00 555.44,1687.00 556.70,1683.98 560.07,1673.54 562.51,1672.11 564.94,1670.69 574.96,1674.42 578.00,1675.29 578.00,1675.29 620.00,1685.00 620.00,1685.00 619.70,1691.09 617.61,1697.02 616.40,1703.00 616.40,1703.00 607.42,1744.00 607.42,1744.00 607.42,1744.00 578.88,1872.00 578.88,1872.00 578.88,1872.00 568.79,1917.00 568.79,1917.00 568.13,1919.98 566.25,1931.50 564.27,1933.00 562.48,1934.34 559.02,1933.39 557.00,1933.00 557.00,1933.00 540.00,1929.42 540.00,1929.42 523.06,1925.68 485.03,1915.59 470.00,1909.00 Z",
    attr: {
      id: "_Z2",
      "data-name": "Z2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Upper Zone Z", zoneId: "3" },
  };
  var _endzone = {
    path:
      'M 1575.00,816.80 C 1578.36,816.40 1583.72,815.34 1586.96,816.80 1591.89,818.52 1597.80,832.91 1600.00,838.00 1600.00,838.00 1625.00,902.00 1625.00,902.00 1652.16,983.50 1653.96,1069.04 1623.42,1150.00 1616.32,1168.83 1608.85,1185.32 1599.23,1203.00 1597.38,1206.41 1591.88,1217.01 1588.67,1218.43 1587.20,1219.08 1584.63,1218.99 1583.00,1219.00 1583.00,1219.00 1575.00,1219.00 1575.00,1219.00 1575.00,1219.00 1575.00,816.80 1575.00,816.80 Z',
    attr: {
      id: '_endzone',
      'data-name': 'ENDZONE',
        opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
        'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'ENDZONE', zoneId: "1" },
  };

  zones.push(
    _S2,
    _AA2,
    _BB2,
    _CC2,
    _A2,
    _B2,
    _C2,
    _D2,
    _E2,
    _F2,
    _G2,
    _H2,
    _I2,
    _J2,
    _K2,
    _L2,
    _M2,
    _DD2,
    _EE2,
    _FF2,
    _AA,
    _BB,
    _CC,
    _A,
    _B,
    _C,
    _D,
    _E,
    _F,
    _G,
    _H,
    _I,
    _J,
    _K,
    _L,
    _M,
    _DD,
    _EE,
    _FF,
    _PDFF,
    _GG,
    _HH,
    _JJ,
    _KK,
    _LL,
    _MM,
    _NN,
    _PDNN,
    _OO,
    _N,
    _O,
    _P,
    _Q,
    _R,
    _S,
    _T,
    _U,
    _V,
    _W,
    _X,
    _Y,
    _Z,
    _PP,
    _QQ,
    _RR,
    _RR2,
    _QQ2,
    _PP2,
    _Z2,
    _Y2,
    _X2,
    _W2,
    _V2,
    _U2,
    _T2,
    _R2,
    _Q2,
    _P2,
    _O2,
    _N2,
    _OO2,
    _NN2,
    _MM2,
    _TwistedTeaPartyDeck,
    _CoorsPartyDeck,
    _rooftoppatio,
    _endzone
  );
  return zones;
};