import { API } from "aws-amplify";

export const getToken = async () => {
  const response = new Object();
  const localToken = getLocalToken();
  if(!localToken){
    try{
      const apiResponse = await API.get("v2", "marketplace/payment/get-token");
      const { token, expiresInSec, validTo } = apiResponse;
      setLocalToken(token, expiresInSec);
      response.token = token;
      return response;
    } 
    catch (error) {
      const apiResponse = await API.get("v2", "marketplace/payment/get-backup-token");
      const { token, expiresInSec, validTo } = apiResponse;
      setLocalToken(token, expiresInSec);
      response.token = token;
      return response;
    }
  }
  response.token = localToken;
  return response;
}

export const getLocalToken = () => {
  const itemStr = localStorage.getItem('token')
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem('token')
    return null
  }
  
  if (now.getTime() + (60 * 1000) > item.expiry) {
    return null;
  }
  return item.value
};
  
export const setLocalToken = (value, ttl) => {
  const now = new Date()
  const item = {
    value: value,
    expiry: now.getTime() + (ttl * 1000),
  }
  localStorage.setItem('token', JSON.stringify(item))
};