const skyLoungeARows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 'STR',
    label: 'Row STR',
  },
];

const skyLoungeBRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 'DRL',
    label: 'Row DRL',
  },
];

const skyLoungeCRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 'BF',
    label: 'Row BF',
  },
];

const skyLoungeDRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 'DRL',
    label: 'Row DRL',
  },
];

const skyLoungeERows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 'STR',
    label: 'Row STR',
  },
];

const skyLoungeAARows = [
  {
    value: 1,
    label: 'Row 1',
  },
];

const sportsNetRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 4,
    label: 'Row 4',
  },
  {
    value: 'STR',
    label: 'Row STR',
  },
];

const sportsNetARows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 4,
    label: 'Row 4',
  },
  {
    value: 5,
    label: 'Row 5',
  },
  {
    value: 'DRL',
    label: 'Row DRL',
  },
];

const sportsNetBRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 4,
    label: 'Row 4',
  },
  {
    value: 5,
    label: 'Row 5',
  },
  {
    value: 'DRL',
    label: 'Row DRL',
  },
];

const sportsNetCRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 'BF',
    label: 'Row BF',
  },
];

const sportsNetDRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 4,
    label: 'Row 4',
  },
  {
    value: 5,
    label: 'Row 5',
  },
  {
    value: 'DRL',
    label: 'Row DRL',
  },
];

const sportsNetERows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 4,
    label: 'Row 4',
  },
  {
    value: 5,
    label: 'Row 5',
  },
  {
    value: 'BF',
    label: 'Row BF',
  },
  {
    value: 'DRL',
    label: 'Row DRL',
  },
];

const skyLoungeRows = [
  {
    value: 1,
    label: 'Row 1',
  },
  {
    value: 2,
    label: 'Row 2',
  },
  {
    value: 3,
    label: 'Row 3',
  },
  {
    value: 4,
    label: 'Row 4',
  },
  {
    value: 'STR',
    label: 'Row STR',
  },
];

const logeRows = [
  {
    value: '1A',
    label: 'Row 1A',
  },
  {
    value: '2A',
    label: 'Row 2A',
  },
  {
    value: '3A',
    label: 'Row 3A',
  },
  {
    value: '1B',
    label: 'Row 1B',
  },
  {
    value: '2B',
    label: 'Row 2B',
  },
  {
    value: '3B',
    label: 'Row 3B',
  },
  {
    value: '1C',
    label: 'Row 1C',
  },
  {
    value: '2C',
    label: 'Row 2C',
  },
  {
    value: '3C',
    label: 'Row 3C',
  },
];

const ozoneClubRows = () => {
  const arr = [];

  for (let i = 1; i <= 30; i++) {
    arr.push({
      value: i,
      label: `Row ${i} ${[23, 24, 25].includes(i) ? 'Ozone Club' : ''}`,
    });
  }

  arr.push({ value: 'DRL', label: 'Row DRL' });
  arr.push({ value: 'BF', label: 'Row BF' });
  arr.push({ value: 'STR', label: 'Row STR' });

  return arr;
};

const defaultRows = () => {
  const arr = [];

  for (let i = 1; i <= 30; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  arr.push({ value: 'DRL', label: 'Row DRL' });
  arr.push({ value: 'BF', label: 'Row BF' });
  arr.push({ value: 'STR', label: 'Row STR' });

  return arr;
};

export default {
  SKY_LOUNGE_ROWS: skyLoungeRows,
  SKY_LOUNGE_AA_ROWS: skyLoungeAARows,
  SKY_LOUNGE_A_ROWS: skyLoungeARows,
  SKY_LOUNGE_B_ROWS: skyLoungeBRows,
  SKY_LOUNGE_C_ROWS: skyLoungeCRows,
  SKY_LOUNGE_D_ROWS: skyLoungeDRows,
  SKY_LOUNGE_E_ROWS: skyLoungeERows,
  SPORTSNET_ROWS: sportsNetRows,
  SPORTSNET_A_ROWS: sportsNetARows,
  SPORTSNET_B_ROWS: sportsNetBRows,
  SPORTSNET_C_ROWS: sportsNetCRows,
  SPORTSNET_D_ROWS: sportsNetDRows,
  SPORTSNET_E_ROWS: sportsNetERows,
  LOGE_ROWS: logeRows,
  OZONE_CLUB_ROWS: ozoneClubRows(),
  DEFAULT_ROWS: defaultRows(),
};
