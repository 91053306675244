const defaultRows = () => {
    const arr = [];
    for (let i = 1; i <= 34; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
    return arr;
  };
  
  const lowerZoneRows = () => {
    const arr = [];
    for (let i = 1; i <= 34; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
    return arr;
  };
  
  const upperZoneRows = () => {
    const arr = [];
    for (let i = 1; i <= 28; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
    return arr;
  };
  
  const middleZoneRows = () => {
    const arr = [];
    for (let i = 1; i <= 34; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
    return arr;
  };

  const pilCountryRow = () => {
    const arr = [];
  
    for (let i = 1; i <= 1; i++) {
      arr.push({
        value: `GA`,
        label: `Row GA`,
      });
    }
  
    return arr;
  };
    
  export default {
    LOWER_ZONE_ROWS: lowerZoneRows(),
    UPPER_ZONE_ROWS: upperZoneRows(),
    MIDDLE_ZONE_ROWS: middleZoneRows(),
    PIL_COUNTRY_ROWS: pilCountryRow(),
    DEFAULT_ROWS: defaultRows(),
  };
  