import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import validator from 'validator';
import Helmet from "react-helmet";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  [theme.breakpoints.up('md')]: {
    formContainer: {
      margin: '5rem 0'
    }
  },
  [theme.breakpoints.down('sm')]: {
    formContainer: {
      marginTop: '3rem',
      padding: '0 1rem'
    }
  },
  textField: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    border: 'solid 1px #e5e5ea',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  textFieldError: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 23, 68, .05)',
    border: 'solid 1px rgba(255, 23, 68, .5)',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  form: {
    marginTop: '2rem'
  },
  input: {
    padding: 0,
    fontSize: '0.875rem'
  },
  button: {
    padding: '.875rem',
    marginTop: '.5rem'
  }
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isValidEmail: true,
      isSubmitting: false,
      isSubmitted: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  isValidEmail = email => {
    const isValidEmail = validator.isEmail(email);

    this.setState({
      isValidEmail
    });

    return isValidEmail;
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    const loweredTrimedEmail = email.toLowerCase().replaceAll(" ", "");
    try {
      this.setState({
        isSubmitted: false,
        isSubmitting: true,
        isExisting: true
      });

      if (this.isValidEmail(loweredTrimedEmail)) {
        await Auth.forgotPassword(loweredTrimedEmail);

        this.setState({
          isSubmitted: true,
          isSubmitting: false,
          isExisting: true
        });
      } else {
        this.setState({
          isSubmitted: false,
          isSubmitting: false,
          isExisting: true
        });
      }
    } catch (e) {
      this.setState({
        isSubmitted: false,
        isSubmitting: false
      });
      
      switch (e.code) {
        case 'LimitExceededException': {
          alert('Attempt limit exceeded. Please try again later.');
          console.error(email+" failed to sent email"+e.code)
          break;
        }

        case 'UserNotFoundException': {
          alert(
            'The email address you provided does not exist. Contact support by text at 1-403-768-2298 or email at tix@fansfirst.ca for assistance.'
          );
          console.error(email+" failed to sent email"+e.code)
          break;
        }

        case 'NotAuthorizedException': {
          alert(
            'The account we created for you initially has expired. Contact support by text at 1-403-768-2298 or email at tix@fansfirst.ca for assistance.'
          );
          console.error(email+" failed to sent email"+e.code)
          break;
        }

        default: {
          alert(
            'Sorry something went wrong. Contact support by text at 1-403-768-2298 or email at tix@fansfirst.ca for assistance.'
          );
          console.error(email+" failed to sent email"+e.code)
          break;
        }
      }

      console.error(e);
    }
  }

  renderEmailForm() {
    const { classes } = this.props;
    const { isValidEmail, isSubmitting, email } = this.state;

    return (
      <React.Fragment>
        <Typography
          style={{
            marginBottom: '1rem'
          }}
          variant="title"
          color="textPrimary"
        >
          Forgot Password
        </Typography>
        <form onSubmit={this.onSubmit} className={classes.form}>
          <FormControl error={!isValidEmail} fullWidth={true}>
            <TextField
              id="email"
              name="email"
              type="email"
              placeholder="Email Address"
              className={
                isValidEmail ? classes.textField : classes.textFieldError
              }
              value={email}
              onChange={this.handleChange}
              margin="dense"
              disabled={isSubmitting}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input
                }
              }}
            />
            {!isValidEmail && (
              <FormHelperText>Please enter your email address</FormHelperText>
            )}
          </FormControl>

          <Button
            className={classes.button}
            color="primary"
            disabled={isSubmitting}
            fullWidth={true}
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const { isSubmitted } = this.state;
    const currentURL = window.location.href;
    return (
      <div>
        <Helmet>
          <title>Forgot Password | fansfirst.ca</title>
          <meta
            name="description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta
            name="keywords"
            content={`forgot password, sell tickets, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`}
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Forgot Password | fansfirst.ca" />
          <meta
            property="og:description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Typography variant="headline" style={{display: 'none'}}>
          Forgot Password
        </Typography>
        <Grid
          className={classes.formContainer}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            {!isSubmitted && this.renderEmailForm()}
            {isSubmitted && (
              <React.Fragment>
                <Typography
                  style={{
                    marginBottom: '1rem',
                    color: '#4caf50'
                  }}
                  variant="title"
                >
                  We have sent a link to your email.
                </Typography>
                <Typography variant="body2">
                  Please click on the link we have sent to your email to reset
                  your password.
                </Typography>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
