import React, { Component } from 'react';
import { Button, Hidden, withStyles } from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';

const styles = (theme) => ({
  clearButton: {
    fontSize: "0.6125rem",
    padding: "5px 6px",
    minHeight: "22px"
  }
});

class ButtonClear extends Component {
  render() {
    const { classes, className, onClick } = this.props;
    return (
      <div className={className}>
        <Button className={classes.clearButton} variant="outlined" size="small" color="secondary" onClick={() => onClick()}>
          Clear <Hidden smDown>sections</Hidden>
          <CloseOutlined style={{ fontSize: "0.8125rem" }} />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ButtonClear);