import React from "react";
import { Route, Switch } from "react-router-dom";
import BlogPage from "./components/blog/BlogPage";
import Blogs from "./components/blog/Blogs";
import Games from "./containers/Games";
import Seats from "./containers/Seats";
import Ticket from "./containers/Ticket";
import Checkout from "./containers/Checkout";
import Home from "./containers/home";
import Sell from "./containers/Sell";
import ThankYou from "./containers/ThankYou";
import WhyUsPage from "./containers/WhyUsPage";
import FaqPage from "./containers/FaqPage";
import Legal from "./containers/Legal";
import HowToAcceptMobileTransfer from "./containers/HowToAcceptMobileTransfer";
import HowToMobileTransfer from "./containers/HowToMobileTransfer";
import NotFound from "./containers/NotFound";
// import Maintenance from './containers/Maintenance';
import SignUp from "./containers/SignUp";
import Login from "./containers/Login";
import SetPrices from "./containers/SetPrices";
import Listings from "./containers/Listings";
import Orders from "./containers/Orders";
import NewListing from "./containers/NewListing";
import AccountVerification from "./containers/AccountVerification";
import EmailVerification from "./containers/EmailVerification";
import AccountSettings from "./containers/AccountSettings";
import ChangePassword from "./containers/ChangePassword";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import NewPassword from "./containers/NewPassword";
import EmergencyMode from "./containers/emergency-mode";
import SellerPage from "./containers/SellerPage";
import SellerSeats from "./containers/SellerSeats";
import TrackOrder from "./containers/TrackOrder";
import Dashboard from "./containers/Dashboard";

import WithHeaderLayout from "./layouts/WithHeaderLayout";
import PlainLayout from "./layouts/PlainLayout";
import V2Layout from "./layouts/v2-layout";
import V2HomeLayout from "./layouts/v2-home-layout";

const WithNewLayoutRoute = ({
  component: Component,
  props: cProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <V2Layout {...props} {...cProps}>
          <Component {...props} {...cProps} />
        </V2Layout>
      )}
    />
  );
};
const WithNewHomeLayoutRoute = ({
  component: Component,
  props: cProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <V2HomeLayout {...props} {...cProps}>
          <Component {...props} {...cProps} />
        </V2HomeLayout>
      )}
    />
  );
};

const WithHeaderLayoutRoute = ({
  component: Component,
  props: cProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <WithHeaderLayout {...props} {...cProps}>
          <Component {...props} {...cProps} />
        </WithHeaderLayout>
      )}
    />
  );
};

const PlainLayoutRoute = ({ component: Component, props: cProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PlainLayout {...props} {...cProps}>
          <Component {...props} {...cProps} />
        </PlainLayout>
      )}
    />
  );
};

export default ({ childProps }) => (
  <Switch>
    <WithNewHomeLayoutRoute
      path="/"
      exact
      component={Home}
      props={childProps}
    />
    <WithNewLayoutRoute
      path="/sell"
      exact
      component={Sell}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/:teamSlug/games"
      exact
      component={Games}
      props={childProps}
    />
    <PlainLayoutRoute
      path="/seats/:slug/:id/"
      exact
      component={Seats}
      props={childProps}
    />
    <PlainLayoutRoute
      path="/games/:id/seats"
      exact
      component={Seats}
      props={childProps}
    />
    <WithNewLayoutRoute
      path="/seats/:slug/:gameId/:seatId"
      exact
      component={Ticket}
      props={childProps}
    />
    <WithNewLayoutRoute
      path="/games/:gameId/seats/:seatId"
      exact
      component={Ticket}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/listings"
      exact
      component={Listings}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/listings/new"
      exact
      component={NewListing}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/orders"
      exact
      component={Orders}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/dashboard"
      exact
      component={Dashboard}
      props={childProps}
    />
    <PlainLayoutRoute
      path="/checkout"
      exact
      component={Checkout}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/thankyou/:id"
      exact
      component={ThankYou}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/why-us"
      exact
      component={WhyUsPage}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/faqs"
      exact
      component={FaqPage}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/legal"
      exact
      component={Legal}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/how-tos/accept-mobile-transfer"
      exact
      component={HowToAcceptMobileTransfer}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/how-tos/mobile-transfer"
      exact
      component={HowToMobileTransfer}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/sell/set-prices"
      exact
      component={SetPrices}
      props={childProps}
    />
    <WithNewLayoutRoute
      path="/signup"
      exact
      component={SignUp}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/account-verification"
      exact
      component={AccountVerification}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/account"
      exact
      component={AccountSettings}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/account/password"
      exact
      component={ChangePassword}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/forgot-password"
      exact
      component={ForgotPassword}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/reset-password"
      exact
      component={ResetPassword}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/new-password"
      exact
      component={NewPassword}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/blogs/:blogName"
      exact
      component={BlogPage}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/blogs"
      exact
      component={Blogs}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/under-maintenance"
      exact
      component={EmergencyMode}
      props={childProps}
    />
    <WithHeaderLayoutRoute
      path="/email-verification"
      exact
      component={EmailVerification}
      props={childProps}
    />
    <WithNewLayoutRoute 
      path="/not-found/"
      exact
      component={NotFound} 
    />
    <WithHeaderLayoutRoute
      path="/track-order"
      exact
      component={TrackOrder}
      props={childProps}
    />
    <WithNewHomeLayoutRoute
      path="/:sellerSlug"
      exact
      component={SellerPage}
      props={childProps}
    />
    <PlainLayoutRoute
      path="/:sellerSlug/seats/:slug/:id/"
      exact
      component={SellerSeats}
      props={childProps}
    />
    <WithNewLayoutRoute component={NotFound} />
  </Switch>
);
