import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Helmet from 'react-helmet';

const NotFound = () => {
  const currentURL = window.location.href;
  return (
    <main style={{ margin: '3rem 0' }}>
      <Helmet>
        <title>Page Not Found</title>
        <meta name="description" content="Page Not Found" />
        <meta name="keywords" content={`page not found, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Page Not Found" />
        <meta property="og:description" content="Page Not Found" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentURL} />
        <link rel="canonical" href={currentURL} />
      </Helmet>
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Typography
            gutterBottom={true}
            variant="headline"
            color="secondary"
            style={{ fontWeight: 'bold' }}
          >
            Sorry we can't find that page.
          </Typography>

          <Typography variant="body2">
            Click{' '}
            <Link to="/" style={{ color: '#2196f3' }}>
              here
            </Link>{' '}
            to check on upcoming games.
          </Typography>
        </Grid>
      </Grid>
    </main>
  );
};

export default NotFound;
