import React from "react";
import { Typography } from "@material-ui/core";

import AcceptOffer from '../images/how-tos/accept-mobile-transfer/accept-offer.png';
import AcceptTicket from '../images/how-tos/accept-mobile-transfer/accept-ticket.png';
import AcceptTicketsEmail from '../images/how-tos/accept-mobile-transfer/accept-tickets-email.png';
import CalgaryFlameTicketPage from '../images/how-tos/accept-mobile-transfer/calgary-flame-ticket-page.png';
import CompleteTransfer from '../images/how-tos/accept-mobile-transfer/complete-transfer.png';
import CreateAccountPage from '../images/how-tos/accept-mobile-transfer/create-account-page.png';
import MainFlamesPage from '../images/how-tos/accept-mobile-transfer/main-flames-page.png';
import ViewOfferSection from '../images/how-tos/accept-mobile-transfer/view-offer-section.png';

const AcceptMobileTransferDesktop = ({ classes }) => {
  return (
    <ol
      style={{
        margin: 0,
        marginBottom: "1rem",
        padding: "0 1rem"
      }}
    >
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          All FansFirst transactions have tickets delivered digitally from the
          seller to the buyer. Click "Accept Tickets" to begin the process.
        </Typography>
        <img width="50%" src={AcceptTicketsEmail} alt="Accept Tickets Email" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          You’ll be taken to the Calgary Flame ticket page. It will ask to you
          login. If you don’t have an existing account, under "I don’t have a My
          Calgary Flames Account Manager account" and enter your email address.
          If you have an account, under "I have an account" fill in the "Account
          ID or E-mail Address" and "Password" and skip to Step 6.
        </Typography>
        <img
          width="75%"
          src={CalgaryFlameTicketPage}
          alt="Calgary Flame Ticket Page"
          style={{ marginBottom: "1.5rem" }}
        />
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
          color="secondary"
        >
          Note: If you have ever signed up for Flames promotions or entered a
          Flames contest before they may have created an account for you. Some
          people have had problems logging in as a result of this. If you have
          issues logging in, and the system indicates an account already exists,
          then try setting up a new account with a new email and accepting the
          tickets into that account instead.
        </Typography>
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Fill-out the form
        </Typography>
        <img width="75%" src={CreateAccountPage} alt="Create Account Page" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          You may be taken to the main Flames page. If that happens, click HERE
          or click "Tickets", then "My Flames Account".
        </Typography>
        <img width="100%" src={MainFlamesPage} alt="Main Flames Page" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Next you’ll see your transfer offer. Click "View Offer".
        </Typography>
        <img width="100%" src={ViewOfferSection} alt="View Offer" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Next click "Accept Offer".
        </Typography>
        <img width="100%" src={AcceptOffer} alt="Accept Offer" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Click "Next Step" to proceed. If you want to print your tickets,
          select the delivery type. If you leave the delivery type as "Select
          Delivery Type", it will default to Mobile Transfer.
        </Typography>
        <img width="75%" src={AcceptTicket} alt="Accept Ticket" />
      </li>
      <li className={classes.li}>
        <Typography
          variant="body1"
          style={{ marginBottom: "1.5rem" }}
          gutterBottom={true}
        >
          Agree to the Terms of Use and click "Complete Transfer" and you’re
          done.
        </Typography>
        <img width="75%" src={CompleteTransfer} alt="Complete Transfer" />
      </li>
    </ol>
  );
};

export default AcceptMobileTransferDesktop;
