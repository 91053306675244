import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";

const DialogConfirmInput = ({
  title,
  description,
  value,
  inputLabel,
  inputType,
  inputRequired,
  destroy,
  onConfirm,
  onCancel,
  cancelLabel, 
  confirmLabel
}) => {
  const [open, setOpen] = useState(true);
  const [fieldValue, setFieldValue] = useState(value);

  const handleConfirm = () => {
    setOpen(false);
    destroy();
    onConfirm(fieldValue);
  }

  const handleCancel = () => {
    setOpen(false);
    destroy();
    onCancel();
  }

  const checkError = () => {
    if (inputRequired) {
      if (isNil(fieldValue) || isEmpty(fieldValue)) {
        return "This field is required";
      }
    }

    if (inputType === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue)) {
      return "Email is not valid";
    }

    return "";
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="dialog-input-value"
          label={inputLabel}
          type={inputType}
          value={fieldValue}
          onChange={e => setFieldValue(e.target.value)}
          fullWidth
          error={!isEmpty(checkError())}
          required={inputRequired}
          autoComplete="off"
        />
        {checkError() && <Typography variant="caption" color="error" gutterBottom>{checkError()}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={() => handleConfirm()} color="primary" disabled={!isEmpty(checkError())} autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogConfirmInput.defaultProps = {
  title: "Confirm",
  description: "",
  value: "",
  inputRequired: false,
  inputLabel: "",
  inputType: "text",
  cancelLabel: "Cancel",
  confirmLabel: "Ok",
}

export default DialogConfirmInput;