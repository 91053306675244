import React, { Component } from "react";
import { API } from "aws-amplify";
import _ from "lodash";
import Helmet from "react-helmet";
import moment from "moment-timezone";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as Sentry from "@sentry/browser";

import Button from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

import RowSelect from "../components/RowSelect";
import SectionSelect from "../components/SectionSelect";
import SellRequestWarningPopup from "../components/sell-request-warning-popup";

import { getSectionsBySlug, getSpecialSectionsBySlug } from "../libs/sectionHelper";
import { getRows, getSpecialRows } from "../libs/rowHelper";
import GrayTextInput from "../components/shared/GrayTextInput";
import { newTeams } from "../libs/shared/team-constants";
import normalizedZoneMapSpecial from "../constants/NormalizedZoneMapSpecial";
import normalizedZoneMap from "../constants/NormalizedZoneMap";
import { isTimeTBD } from "../libs/shared/grid-helper";
import cdialog from "../components/cdialog";

const styles = (theme) => ({
  header: {
    backgroundColor: "#f5f5f5",
  },
  gamesList: {
    marginBottom: "2rem",
  },
  aisleToggleContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "4px",
    justifyContent: "space-between",
    border: "1px solid rgb(229, 229, 234)",
    borderRadius: "4px",

    fontSize: "14px",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  priceInput: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".5rem",
    fontSize: "0.875rem",
    fontFamily: "Roboto",
  },
  game: {
    alignItems: "flex-start",
    paddingRight: "0",
  },
  bottomActions: {
    position: "sticky",
    bottom: 0,
    padding: "2rem 0",
    zIndex: 999,
    backgroundColor: "#fafafa",
  },
  [theme.breakpoints.up("xl")]: {
    header: {
      padding: "2rem 0 3rem",
      marginBottom: "2rem",
    },
    bottomActions: {
      padding: "2rem 0",
    },
  },
  [theme.breakpoints.up("md")]: {
    header: {
      padding: "2rem 0 3rem",
      marginBottom: "2rem",
    },
    bottomActions: {
      padding: "1rem 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    header: {
      padding: "5rem 1rem",
    },
    bottomActions: {
      padding: "1rem 0 0",
    },
  },
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  formControl: {
    marginTop: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  formControlLabel: {
    fontSize: ".875rem",
  },
});

class SetPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 0,
      row: 0,
      isAisleSeat: false,
      isObstructedView: false,
      noOfSeats: "-",
      sellData: null,
      games: null,
      isSubmitting: false,
      isSubmitted: false,
      isValidSection: true,
      isValidRow: true,
      isValidNoOfSeats: true,
      sellOption: 2,
      priceWarning: false,
      gamesBelowMinPrice: [],
      largeQuantityWarning: false,
      singleQuantityWarning: false,
      warningOpen: false,
      ignoreWarning: false,
      autoapproved: false,
      seatNumbers: {
        start: "",
        end: "",
      },
      isWheelchair: false,
      sellReqLowestList: [],
    };

    this.submitListing = this.submitListing.bind(this);
    this.onNoOfSeatsSelection = this.onNoOfSeatsSelection.bind(this);
    this.setSeatNumbers = this.setSeatNumbers.bind(this);
  }

  async componentWillMount() {
    try {
      let sellData = JSON.parse(localStorage.getItem("sellListingData"));
      let regularGames = sellData.games.filter((game) => game.isTbd !== true);
      let tbdGames = sellData.games.filter((game) => game.isTbd === true);
      sellData.games = [...regularGames, ...tbdGames];
      // TbdGames is an array of objects, we need to sort it by id
      /* tbdGamesSellData = tbdGamesSellData.sort((a, b) => a.game.playoffSequence - b.game.playoffSequence); */
      this.setState({
        selectedTeam: sellData.selectedTeam,
        isSpecialGame: sellData && sellData.games[0].isSpecial,
      });

      const games = await this.games(sellData.games.map((item) => item.id).toString(), sellData.selectedTeam);

      this.setState({
        sellData,
        games: _.sortBy(games, "date"),
      });
    } catch (e) { }
  }

  games(ids, slug) {
    return API.get("v2", `marketplace/events/by/eventIds/${ids}?includeMinPrices=true`);
  }
  _sellReqLowest(ids) {
    return API.get("v2", `marketplace/events/by/sellReq/${ids}`);
  }
  _saveSellReqLowest(lowestData) {
    return API.post("v2", "marketplace/sellRequests/minPrices", {
      body: { minPricesData: lowestData },
    });
  }

  getGST(game) {
    const { noOfSeats } = this.state;
    if (newTeams[game.homeTeamSlug] && game.date < 1667282400000) {
      return 0;
    }
    return noOfSeats * game.price * 0.09 * 0.05 + 0.0000001;
  }

  getNormalizedZone(game, sectionName) {
    if (game.isSpecial && game.homeTeamSlug in normalizedZoneMapSpecial) {
      return normalizedZoneMapSpecial[game.homeTeamSlug][sectionName];
    }
    return normalizedZoneMap[game.homeTeamSlug][sectionName];
  }

  handleChange(game, { target }) {
    const { sellData, noOfSeats, section } = this.state;
    let price = target.value.replace(/[^\d.]+/g, "");
    const sectionName = section.replace(/[0-9]/g, "").trim();
    const updatedGames = sellData.games.map((item) => {
      // convert format "Press Level" to "pressLevel"

      if (item.id === game) {
        const gameObject = this.state.games.find((event) => item.id === event.id);
        const normalizedZone = this.getNormalizedZone(gameObject, sectionName);
        var sectionMinPrice = gameObject.minPrices[`${normalizedZone}MinPrice`];
        var sectionMinPrice = sectionMinPrice ? sectionMinPrice : 0;
        item.price = price;
        item.homeTeamSlug = gameObject.homeTeamSlug;
        const isNewTeam = newTeams[gameObject.homeTeamSlug] && gameObject.date < 1667282400000; // apply discounted rate for new team games occurring before Nov 1
        const priceToProceed = isNewTeam
          ? (noOfSeats * parseFloat(price)).toFixed(2) // proceeds = price for new team; no fees or GST as a result
          : (noOfSeats * parseFloat(price) * 0.91 - noOfSeats * parseFloat(price) * 0.09 * 0.05).toFixed(2); // deduct fees + GST from price for other teams
        item.proceeds = price ? priceToProceed : 0;
        item.priceDifference = parseFloat(price) / parseFloat(sectionMinPrice) - 1;
      }

      return item;
    });

    this.setState({
      sellData: {
        ...sellData,
        ...{
          games: updatedGames,
        },
      },
    });
  }

  setSeatNumbers(start) {
    if (start.length <= 5) {
      const { noOfSeats } = this.state;
      this.setState({
        ...this.state,
        seatNumbers: {
          start,
          end: Number(start) > 0 && Number(noOfSeats) + Number(start) - 1,
        },
      });
    }
  }

  isValidSection(section) {
    const isValidSection = section !== 0;
    this.setState({ isValidSection });
    return isValidSection;
  }

  isValidRow(row) {
    const isValidRow = row !== 0;
    this.setState({ isValidRow });
    return isValidRow;
  }

  isValidNoOfSeats(noOfSeats) {
    const isValidNoOfSeats = noOfSeats !== "-";
    this.setState({ isValidNoOfSeats });
    return isValidNoOfSeats;
  }

  onSectionSelection = (section) => {
    this.setState({
      section,
      isValidSection: true,
    });
  };

  onRowSelection = (row) => {
    this.setState({
      row,
      isValidRow: true,
    });
  };

  onNoOfSeatsSelection(event) {
    const qty = event.target.value;
    const { sellData } = this.state;

    const updatedGames = sellData.games.map((game) => {
      const isNewTeam = newTeams[game.homeTeamSlug] && game.date < 1667282400000; // apply discounted rate for new team games occurring before Nov 1;
      const priceToProceed = isNewTeam
        ? (qty * parseFloat(game.price)).toFixed(2) // proceeds = price for new team; no fees or GST as a result
        : (qty * parseFloat(game.price) * 0.91 - qty * parseFloat(game.price) * 0.09 * 0.05).toFixed(2); // deduct fees + GST from price for other teams
      game.proceeds = game.price ? priceToProceed : 0;

      return game;
    });

    this.setState({
      seatNumbers: {
        start: "",
        end: "",
      },
      noOfSeats: qty,
      isValidNoOfSeats: true,
      sellOption: qty <= 2 ? 2 : 2,
      sellData: {
        ...sellData,
        ...{
          games: updatedGames,
        },
      },
    });
  }

  isValidSeatDetails({ section, row, noOfSeats }) {
    const isValidRow = this.isValidRow(row);
    const isValidSection = this.isValidSection(section);
    const isValidNoOfSeats = this.isValidNoOfSeats(noOfSeats);

    return isValidSection && isValidRow && isValidNoOfSeats;
  }

  isValid({ games }) {
    let gameWithoutPrice = 0;

    games.forEach((game) => {
      gameWithoutPrice = parseInt(game.price) === 0 ? gameWithoutPrice + 1 : gameWithoutPrice;
    });

    return gameWithoutPrice === 0;
  }

  goUpdate = () => {
    this.setState({
      isSubmitting: false,
      isSubmitted: false,
      priceWarning: false,
      singleQuantityWarning: false,
      largeQuantityWarning: false,
      warningOpen: false,
      ignoreWarning: false,
    });
  };
  submitAnyway = () => {
    this.setState(
      {
        isSubmitting: false,
        isSubmitted: false,
        warningOpen: false,
        ignoreWarning: true,
      },
      () => {
        this.submitListing();
      }
    );
  };

  async submitListing() {
    const {
      sellData,
      section,
      isAisleSeat,
      isObstructedView,
      row,
      noOfSeats,
      selectedTeam,
      sellOption,
      ignoreWarning,
      seatNumbers,
      isWheelchair,
    } = this.state;
    // const sectionNameState = section.replace(/[0-9]/g, "").trim();

    // Loop through sellData.games and if a games priceDifference is <= -0.25
    // we should give the user a warning that allows them to choose to proceed or go back.
    // Each game in sellData.games already has a priceDifference property.
    const gamesBelowMinPrice = sellData.games.filter((game) => {
      return game.priceDifference <= -0.25;
    });
    const anyGameBelowMinPrice = gamesBelowMinPrice.length > 0;

    if ((anyGameBelowMinPrice || noOfSeats < 1 || noOfSeats > 6) && !ignoreWarning) {
      this.setState({
        warningOpen: true,
        priceWarning: anyGameBelowMinPrice,
        singleQuantityWarning: noOfSeats < 1,
        largeQuantityWarning: noOfSeats > 6,
        gamesBelowMinPrice: gamesBelowMinPrice
      });
      return;
    }

    let sectionName;
    let sectionNo;

    if (section.includes("WC")) {
      sectionName = section;
      sectionNo = null;
    } else {
      sectionName = section.replace(/[0-9]/g, "").trim();
      sectionNo = Number(section.replace(/[^0-9\.]+/g, ""));
    }

    const { currUser } = this.props;

    const newSellData = {
      ...sellData,
      ...{
        section: sectionName,
        sectionNo: sectionNo === 0 ? null : sectionNo,
        row,
        isAisleSeat,
        isObstructedView,
        noOfSeats: Number(noOfSeats),
        email: currUser.email,
        fullName: currUser.name,
        mobileNo: currUser.phone_number,
        notes: "-",
        slug: selectedTeam,
        sellerId: currUser.sub,
        sellOption,
        seatNumberStart: Number(seatNumbers.start),
        seatNumberEnd: Number(seatNumbers.end),
        isWheelchair,
      },
    };

    if (this.isValid(newSellData)) {
      this.setState({
        isSubmitting: true,
        isSubmitted: false,
        ignoreWarning: false,
      });

      try {
        const { autoapproved } = await API.post("v2", "marketplace/sellRequests/create", {
          body: newSellData,
        });

        const updatedsellData = {
          ...newSellData,
          ...{
            games: [],
          },
        };

        localStorage.setItem("sellListingData", JSON.stringify(updatedsellData));

        this.setState({
          isSubmitting: false,
          isSubmitted: true,
          autoapproved,
        });

        setTimeout(() => {
          this.props.history.push(`/listings`);
        }, 3000);
      } catch (e) {
        this.setState({
          isSubmitting: false,
          isSubmitted: false,
        });

        Sentry.captureException(e);

        cdialog.error("Error", "Sorry we're experiencing a technical issue. Please email tix@fansfirst.ca or text 1-403-768-2298 for assistance.");
      }
    } else {
      alert("Price must be at least $1");
    }
  }

  numberOfGamesWithoutPrices() {
    const { sellData } = this.state;

    return sellData.games.filter((game) => !game.price).length;
  }

  handleAisleToggle = (event) => {
    this.setState({ isAisleSeat: event.target.checked });
  };

  renderIsAisleToggle() {
    const { classes } = this.props;
    return (
      <div className={classes.aisleToggleContainer}>
        <span style={{ paddingLeft: "12px" }}>Aisle Seat(s)?</span>
        <Checkbox onChange={this.handleAisleToggle} />
      </div>
    );
  }

  renderIsObstructedViewToggle() {
    const { classes } = this.props;
    return (
      <div className={classes.aisleToggleContainer}>
        <span style={{ paddingLeft: "12px" }}>Obstructed View?</span>
        <Checkbox onChange={e => this.setState({ isObstructedView: e.target.checked })} />
      </div>
    );
  }

  renderIsWheelchairToggle() {
    const { classes } = this.props;
    return (
      <div className={classes.aisleToggleContainer}>
        <span style={{ paddingLeft: "12px" }}>Wheelchair?</span>
        <Checkbox onChange={(e) => this.setState({ isWheelchair: e.target.checked })} />
      </div>
    );
  }

  renderTextHelper() {
    const { isSubmitted, isSubmitting, autoapproved } = this.state;

    if (isSubmitting) {
      return (
        <Typography variant="caption" align="center" component="p" style={{ fontWeight: "bold", color: "#4caf50" }}>
          Submitting your sell request. Please wait...
        </Typography>
      );
    } else if (isSubmitted) {
      const message = autoapproved ? "Congratulations! Your listing is approved and is live on the site." : "We are reviewing your listing. It should be live within 30 mins.";
      return (
        <Typography variant="caption" align="center" component="p" style={{ fontWeight: "bold", color: "#4caf50" }}>
          {message}
        </Typography>
      );
    } else {
      return (
        <Typography variant="caption" align="center" component="p" style={{ fontWeight: "bold", color: "#4caf50" }}>
          Click on the Submit Listing button below to finalize your listings.
        </Typography>
      );
    }
  }

  renderGames() {
    const { classes } = this.props;
    const { sellData, isSubmitting, isSubmitted, games, noOfSeats, row, section } = this.state;

    const numberMask = createNumberMask({
      prefix: "CAD$ ",
      suffix: "",
      integerLimit: 5,
      decimalLimit: 2,
      allowDecimal: true,
      allowLeadingZeroes: false,
    });

    if (row === 0 || section === 0 || noOfSeats === "-") {
      return (
        <Typography align="center" style={{ marginTop: "2rem" }}>
          Enter your seat details above to proceed entering prices for the games...
        </Typography>
      );
    }

    if (!games) {
      return (
        <Typography align="center" style={{ marginTop: "2rem" }}>
          Loading games... Please wait...
        </Typography>
      );
    }
    return (
      games && (
        <div>
          <List className={classes.gamesList}>
            {sellData.games.map((game) => {
              const regularGameDate = `${isTimeTBD(game) ? moment.tz(game.date, game.timezone).format("ddd MMM DD ") + "TBD" : moment.tz(game.date, game.timezone).format("ddd MMM DD h:mm A")}`;
              const gameDate = game.isTbd ? "TBD" : regularGameDate;

              return (
                <ListItem key={game.id} className={classes.game}>
                  <ListItemText primary={game.longName} secondary={gameDate} />
                  <div>
                    <MaskedInput disabled={isSubmitted} mask={numberMask} className={classes.priceInput} style={{ width: "100%" }} placeholder="Price/Ticket" guide={true} onChange={(e) => this.handleChange(game.id, e)} />
                    <Typography variant="caption" align="right" style={{}}>
                      Total Proceeds: CAD$ {game.proceeds ? game.proceeds : "-"}
                    </Typography>
                    <Typography
                      variant="caption"
                      align="right"
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      {game.proceeds ? `* includes $${this.getGST(game).toFixed(2)} GST` : ""}
                    </Typography>
                  </div>
                  <ListItemSecondaryAction />
                </ListItem>
              );
            })}
          </List>
          <Grid container spacing={16} className={classes.bottomActions}>
            <Grid item xs={12}>
              {this.numberOfGamesWithoutPrices() ? (
                <Typography variant="caption" align="center" component="p">
                  You have <strong>{this.numberOfGamesWithoutPrices()}</strong> more field{this.numberOfGamesWithoutPrices() > 1 ? "s" : ""} to fill-out.
                </Typography>
              ) : (
                this.renderTextHelper()
              )}
            </Grid>
            <Grid item xs={6} md={6}>
              <Button href="/listings/new" variant="contained" color="secondary" fullWidth>
                Back to Games
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                fullWidth={true}
                style={{
                  marginBottom: "1rem",
                }}
                variant="contained"
                color="primary"
                onClick={this.submitListing}
                disabled={this.state.isSubmitting || this.state.isSubmitted || this.numberOfGamesWithoutPrices() > 0}
              >
                {isSubmitting ? "Submitting..." : isSubmitted ? "Submitted" : "Submit Listing"}
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    );
  }

  renderSellOptions() {
    const { sellOption, noOfSeats } = this.state;
    const { classes } = this.props;

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">How do you want to sell your tickets?</FormLabel>
        <RadioGroup aria-label="Sell Option" name="sellOption" className={classes.group} value={sellOption} onChange={(e) => this.setState({ sellOption: parseInt(e.target.value) })}>
          <FormControlLabel value={2} className={classes.formControlLabel} control={<Radio />} label="Sell any, but don't leave a single ticket" disabled={noOfSeats <= 2} />
          <FormControlLabel value={3} className={classes.formControlLabel} control={<Radio />} label="Sell all (keep my tickets together as a set)" disabled={noOfSeats <= 2} />
          <FormControlLabel value={1} className={classes.formControlLabel} control={<Radio />} label="Sell any, even if it leaves a single remaining" disabled={noOfSeats <= 2} />

          <FormControlLabel value={4} className={classes.formControlLabel} control={<Radio />} label="Sell in pairs" disabled={noOfSeats <= 2 || noOfSeats % 2 !== 0} />
        </RadioGroup>
      </FormControl>
    );
  }

  renderForm() {
    const { classes } = this.props;
    const { section, noOfSeats, selectedTeam, isSpecialGame } = this.state;

    const rows = isSpecialGame ? getSpecialRows(selectedTeam, section) : getRows(selectedTeam, section);
    const selectedRow = rows.length > 0 ? rows[0].value : 0;

    // get the min price for that section and zone and game

    return (
      <form className={classes.form} noValidate autoComplete="off" onSubmit={this.onSubmit}>
        {selectedTeam && (
          <React.Fragment>
            <SectionSelect
              error={!this.state.isValidSection}
              classes={classes}
              onSectionSelection={this.onSectionSelection}
              sections={isSpecialGame ? getSpecialSectionsBySlug(selectedTeam) : getSectionsBySlug(selectedTeam)}
              homeTeamSlug={selectedTeam}
            />

            {this.state.isValidSection ? "" : <FormHelperText error={true}>Please select seat section</FormHelperText>}

            <RowSelect error={!this.state.isValidRow} classes={classes} onRowSelection={this.onRowSelection} rows={rows} section={section} row={selectedRow} />
            {this.state.isValidRow ? "" : <FormHelperText error={true}>Please select seat row</FormHelperText>}

            <div
              style={{
                padding: "0.5rem 0.75rem",
                backgroundColor: !this.state.isValidNoOfSeats ? "rgba(255, 23, 68, .05)" : "#f5f5f5",
                border: !this.state.isValidNoOfSeats ? "solid 1px rgba(255, 23, 68, .5)" : "solid 1px #e5e5ea",
                borderRadius: "4px",
                marginTop: "8px",
                marginBottom: "4px",
              }}
            >
              <Select
                error={this.state.isValidNoOfSeats}
                disableUnderline={true}
                value={noOfSeats}
                onChange={this.onNoOfSeatsSelection}
                inputProps={{
                  name: "noOfSeats",
                  id: "noOfSeats",
                }}
                style={{
                  marginTop: 0,
                }}
                placeholder="How many seats?"
                className={classes.select}
              >
                <MenuItem value="-" disabled>
                  How many seats?
                </MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
                <MenuItem value="13">13</MenuItem>
                <MenuItem value="14">14</MenuItem>
                <MenuItem value="15">15</MenuItem>
                <MenuItem value="16">16</MenuItem>
                <MenuItem value="17">17</MenuItem>
                <MenuItem value="18">18</MenuItem>
                <MenuItem value="19">19</MenuItem>
                <MenuItem value="20">20</MenuItem>
              </Select>
            </div>
            {this.state.isValidNoOfSeats ? "" : <FormHelperText error={true}>Please select number of seats</FormHelperText>}
            <Grid>
              <Typography
                variant="subheading"
                style={{
                  fontSize: "14px",
                  lineHeight: "16.8px",
                  color: "rgba(0, 0, 0, 0.87)",
                  padding: "12px",
                }}
              >
                Seat numbers (optional)
              </Typography>
              <Grid
                style={{
                  paddingTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <GrayTextInput disabled={noOfSeats !== "-" && Number(noOfSeats) > 0 ? false : true} onChange={(e) => this.setSeatNumbers(e.target.value)} value={this.state.seatNumbers.start} placeholder="Start" type="number" />
                <Grid style={{ padding: "0.8rem 0rem" }}>--</Grid>
                <GrayTextInput placeholder="End" disabled value={this.state.seatNumbers.end} type="number" />
              </Grid>
            </Grid>
            {this.state.noOfSeats > 2 && this.state.sellOption === 2 ? (
              <Typography variant="body2" style={{ marginTop: "1rem" }} color="secondary">
                Note: Buyers can purchase any number of seats that does not leave a single seat remaining. Your seats will not be guaranteed to sell all at once.{" "}
                {this.state.sellOption !== 1 ? "You will never be left with a single seat." : ""}
              </Typography>
            ) : (
              ""
            )}

            {noOfSeats > 2 ? this.renderSellOptions() : null}
            {this.renderIsAisleToggle()}
            {this.state.isAisleSeat === true &&
              this.state.noOfSeats > 2 &&
              this.state.sellOption != 3 ? (
              <Typography
                variant="body2"
                style={{ marginTop: "1rem" }}
                color="secondary"
              >
                Note: If any of your tickets are purchased, the remainder of
                your tickets will lose the "Aisle Seats" tag.{" "}
              </Typography>
            ) : (
              ""
            )}
            {this.renderIsObstructedViewToggle()}
            {this.renderIsWheelchairToggle()}
          </React.Fragment>
        )}
      </form>
    );
  }

  renderHeader = () => {
    const { classes } = this.props;

    return (
      <div className={classes.header}>
        <Grid container justify="center">
          <Grid item xs={12} md={6} xl={3}>
            <Typography
              align="center"
              variant="headline"
              style={{
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Enter your seat details
            </Typography>

            <Typography
              align="center"
              variant="subheading"
              style={{
                marginBottom: "2rem",
              }}
            >
              Enter your seat details and the prices for each listings
            </Typography>

            {this.renderForm()}
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const currentURL = window.location.href;
    return (
      <React.Fragment>
        <Helmet>
          <title>Sell Your Tickets at fansfirst.ca | fansfirst.ca</title>
          <meta
            name="description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta
            name="keywords"
            content={`sell tickets, set prices, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`}
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Sell Your Tickets at fansfirst.ca" />
          <meta
            property="og:description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        {this.renderHeader()}
        <Grid container justify="center">
          <Grid item xs={12} md={10} xl={4} className={classes.games}>
            <main>{this.renderGames()}</main>
          </Grid>
        </Grid>
        <SellRequestWarningPopup
          open={this.state.warningOpen}
          onProceed={this.submitAnyway}
          onUpdate={this.goUpdate}
          priceWarning={this.state.priceWarning}
          singleQuantityWarning={this.state.singleQuantityWarning}
          largeQuantityWarning={this.state.largeQuantityWarning}
          quantity={this.state.noOfSeats}
          games={this.state.gamesBelowMinPrice}
        />
      </React.Fragment>
    );
  }

  // componentDidMount() {
  //   let sellData = JSON.parse(localStorage.getItem("sellListingData"));
  //   this._sellReqLowest(sellData.games.map((item) => item.id).toString()).then((data) => {
  //     this.setState({...this.state, sellReqLowestList: data});
  //   }).catch((err) => {
  //     console.error(err);
  //   });
  // }

  // componentDidUpdate(prevProps, prevState) {
    // const { games, sellReqLowestList } = this.state;
    // const isGamesChanges = JSON.stringify(games) !== JSON.stringify(prevState.games);
    // const isSellReqLowestListChanges = JSON.stringify(sellReqLowestList) !== JSON.stringify(prevState.sellReqLowestList)
    // if (isGamesChanges || isSellReqLowestListChanges) {
    //   if (games && sellReqLowestList.length > 0) {
    //     const newGames = games.map((item) => {
    //       const lowest = sellReqLowestList.find((lowestItem) => {
    //         return item.id === lowestItem.gameId
    //       });
    //       if (lowest) {
    //         const sellReqMins = {
    //           lowerBowlMinPrice: Number(lowest.lowerBowlMinPrice),
    //           pressLevelMinPrice: Number(lowest.pressLevelMinPrice),
    //           secondLevelMinPrice: Number(lowest.secondLevelMinPrice),
    //         };
    //         const newMinPrices = {
    //           ...item.minPrices,
    //           lowerBowlMinPrice:
    //             (sellReqMins.lowerBowlMinPrice > 0 && sellReqMins.lowerBowlMinPrice < item.minPrices.lowerBowlMinPrice && item.minPrices.lowerBowlMinPrice) || !item.minPrices.lowerBowlMinPrice
    //               ? sellReqMins.lowerBowlMinPrice
    //               : item.minPrices.lowerBowlMinPrice,
    //               pressLevelMinPrice:
    //             (sellReqMins.pressLevelMinPrice > 0 && sellReqMins.pressLevelMinPrice < item.minPrices.pressLevelMinPrice && item.minPrices.pressLevelMinPrice) || !item.minPrices.lowerBowlMinPrice
    //               ? sellReqMins.pressLevelMinPrice
    //               : item.minPrices.pressLevelMinPrice,
    //               secondLevelMinPrice:
    //             (sellReqMins.secondLevelMinPrice > 0 && sellReqMins.secondLevelMinPrice < item.minPrices.secondLevelMinPrice && item.minPrices.secondLevelMinPrice) || !item.minPrices.lowerBowlMinPrice
    //               ? sellReqMins.secondLevelMinPrice
    //               : item.minPrices.secondLevelMinPrice,
    //         };
    //         return {...item, minPrices: newMinPrices};
    //       } else {
    //         return item;
    //       }
    //     });
    //     this.setState({...this.state, games: newGames});
    //   }
    // }
  // }
}


export default withStyles(styles)(SetPrices);
