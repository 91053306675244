export const getZones = (rsr) => {
  return [
    {
      path: "M1652,868c5,1.333,48,10,48,10s-16.08,60.639-28,92c-32.26-12.639-48-18-48-18S1647.33,892.333,1652,868Z",
      attr: {
        "data-name": "417",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "417", zone: "Upper Bowl - Balcony", zoneId: 1 },
    },
    {
      path: "M1622,956c14,5.333,46,18,46,18s-21.08,52.64-40,80c-26.92-19.31-42-28-42-28S1614.67,977,1622,956Z",
      attr: {
        "data-name": "418",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "418", zone: "Upper Bowl - Balcony", zoneId: 1 },
    },
    {
      path: "M1584,1028c21.33,14.33,42,30,42,30s-40.35,58.6-60,78c-17.98-17.6-36-36-36-36S1571,1047.67,1584,1028Z",
      attr: {
        "data-name": "419",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "419", zone: "Upper Bowl - Balcony", zoneId: 1 },
    },
    {
      path: "M428,0l20,170s-76.44,6.246-118,34c-36.131-96.817-60-162-60-162S340.286,4.286,428,0Z",
      attr: {
        "data-name": "305",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "305", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M266,44l60,160s-54.2,27.673-80,62c-48.366-43.673-122-114-122-114S178,83.286,266,44Z",
      attr: {
        "data-name": "304",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "304", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M120,156L240,268s-44.672,47.938-60,90C85.529,326.348,26,306,26,306S60,217.571,120,156Z",
      attr: {
        "data-name": "303",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "303", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M24,310l152,52s-26,54.938-26,126c-100.571-8.652-111.143-9.714-112-10,4.286-57.143,14-84,14-84L0,380S13.714,333.714,24,310Z",
      attr: {
        "data-name": "302",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "302", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M39,485.018L151,494.6V621.042H37Z",
      attr: {
        "data-name": "301",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "301", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M428,1250l20-170s-76.44-6.25-118-34c-36.131,96.82-60,162-60,162S340.286,1245.71,428,1250Z",
      attr: {
        "data-name": "326",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "326", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M266,1206l60-160s-54.2-27.67-80-62c-48.366,43.67-122,114-122,114S178,1166.71,266,1206Z",
      attr: {
        "data-name": "327",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "327", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M120,1094L240,982s-44.672-47.938-60-90C85.529,923.652,26,944,26,944S60,1032.43,120,1094Z",
      attr: {
        "data-name": "328",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "328", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M51.021,855.468s-7.69-23.9-11.962-78.833c0.854-.275,9.4-1.3,109.652-9.614,0,68.318,25.918,119.211,25.918,119.211L74.945,918.918C53.9,864.043,51.021,855.468,51.021,855.468Z",
      attr: {
        "data-name": "329",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "329", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M38,768l112-10V626H36Z",
      attr: {
        "data-name": "330",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "330", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M438,38l14,130H586V38H438Z",
      attr: {
        "data-name": "306",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "306", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M590,38V168H742V38H590Z",
      attr: {
        "data-name": "307",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "307", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M746,38V168H898V38H746Z",
      attr: {
        "data-name": "308",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "308", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M902,38V168h152V38H902Z",
      attr: {
        "data-name": "309",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "309", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1060,38V168h134l14-130H1060Z",
      attr: {
        "data-name": "310",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "310", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1218,0l-18,170s44.54,1.364,62,12c11.59-38.616,12-42,12-42,0.06-.019,28.12,10.969,54.06,23.982C1370.34,53.134,1374,44,1374,44S1309.5,6.5,1218,0Z",
      attr: {
        "data-name": "311",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "311", zone: "Upper Bowl - No Alcohol", zoneId: 1 },
    },
    {
      path: "M1218,0l-18,170s44.54,1.364,62,12c11.59-38.616,12-42,12-42,0.06-.019,28.12,10.969,54.06,23.982C1370.34,53.134,1374,44,1374,44S1309.5,6.5,1218,0Z",
      attr: {
        "data-name": "311",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "311", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1276,146c-2,0-12,38-12,38s178.34,59.312,224,240c36.25-7.718,36-8,36-8S1494.8,230,1276,146Z",
      attr: {
        "data-name": "311A",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "311A", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1380,46c1.71-1.143-46,120-46,120s56.36,28.089,98,72c55.84-52.993,88-86,88-86S1471.71,89.714,1380,46Z",
      attr: {
        "data-name": "312",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "312", zone: "Upper Bowl - No Alcohol", zoneId: 1 },
    },
    {
      path: "M1380,46c1.71-1.143-46,120-46,120s56.36,28.089,98,72c55.84-52.993,88-86,88-86S1471.71,89.714,1380,46Z",
      attr: {
        "data-name": "312",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "312", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1524,158l-90,84s41.36,36.4,70,102c81.39-28.153,112-38,112-38S1582,220,1524,158Z",
      attr: {
        "data-name": "313",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "313", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1508,348s16.59,33.986,24,72c-32.59,6.264-42,8-42,8s6.59,33.236,6,58c82.41-6.236,160-14,160-14s-3.91-96.264-38-162C1555.66,331.764,1508,348,1508,348Z",
      attr: {
        "data-name": "314",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "314", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1656,478l-160,14V622h160V478Z",
      attr: {
        "data-name": "315",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "315", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M432,1250l20-168H586v168H432Z",
      attr: {
        "data-name": "325",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "325", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M590,1250V1082H742v168H590Z",
      attr: {
        "data-name": "324",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "324", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M746,1250V1082H898v168H746Z",
      attr: {
        "data-name": "323",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "323", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M902,1250V1082h152v168H902Z",
      attr: {
        "data-name": "322",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "322", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1060,1250V1082h134l18,168H1060Z",
      attr: {
        "data-name": "321",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "321", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1508,902s16.59-33.986,24-72c-32.59-6.264-42-8-42-8s6.59-33.236,6-58c82.41,6.236,160,14,160,14s-3.91,96.264-38,162C1555.66,918.236,1508,902,1508,902Z",
      attr: {
        "data-name": "317",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "317", zone: "Upper Bowl - No Alcohol", zoneId: 1 },
    },
    {
      path: "M1508,902s16.59-33.986,24-72c-32.59-6.264-42-8-42-8s6.59-33.236,6-58c82.41,6.236,160,14,160,14s-3.91,96.264-38,162C1555.66,918.236,1508,902,1508,902Z",
      attr: {
        "data-name": "317",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "317", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1276,1104c-2,0-12-38-12-38s178.34-59.31,224-240c36.25,7.717,36,8,36,8S1494.8,1020,1276,1104Z",
      attr: {
        "data-name": "317A",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "317A", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1218,1248l-18-168s44.54-1.36,62-12c11.59,38.62,12,42,12,42,0.06,0.02,28.12-10.97,54.06-23.98C1370.34,1196.87,1374,1206,1374,1206S1309.5,1241.5,1218,1248Z",
      attr: {
        "data-name": "320",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "320", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1380,1204c1.71,1.14-46-120-46-120s56.36-28.09,98-72c55.84,52.99,88,86,88,86S1471.71,1160.29,1380,1204Z",
      attr: {
        "data-name": "319",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "319", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1524,1092l-90-84s41.36-36.4,70-102c81.39,28.153,112,38,112,38S1582,1030,1524,1092Z",
      attr: {
        "data-name": "318",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "318", zone: "Upper Bowl - No Alcohol", zoneId: 1 },
    },
    {
      path: "M1524,1092l-90-84s41.36-36.4,70-102c81.39,28.153,112,38,112,38S1582,1030,1524,1092Z",
      attr: {
        "data-name": "318",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "318", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M1656,772l-160-14V628h160V772Z",
      attr: {
        "data-name": "316",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "316", zone: "Upper Bowl", zoneId: 1 },
    },
    {
      path: "M477.312,530.607s-10.011,9.4-10.011,21.147v51.9H383.21v15.38H189V488.313s1.3-16.07,4-21.147c125.172,35.935,138.149,40.372,138.149,40.372l28.03-44.216Z",
      attr: {
        "data-name": "101",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "101", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M272,298L380,408l-50,94L192,460S205.333,357,272,298Z",
      attr: {
        "data-name": "102",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "102", zone: "Lower Bowl - No Alcohol", zoneId: 2 },
    },
    {
      path: "M272,298L380,408l-50,94L192,460S205.333,357,272,298Z",
      attr: {
        "data-name": "102",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "102", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M444,206s-108.317,16.746-170,90c64.984,66.254,112,112,112,112l-28,48,120,70s16.016-30.254,50-40C494.984,372.587,444,206,444,206Z",
      attr: {
        "data-name": "103",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "103", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M448.973,207.1s4.566-3.82,21.449-3.977c23.384-.216,122.844,0,122.844,0l11.7,274.369h-50.7s-20.918,1.688-23.4,5.965C493.289,355.515,448.973,207.1,448.973,207.1Z",
      attr: {
        "data-name": "104",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "104", zone: "Club Lower", zoneId: 3 },
    },
    {
      path: "M600,202H746V458h22v20H612Z",
      attr: {
        "data-name": "105",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "105", zone: "Club Lower", zoneId: 3 },
    },
    {
      path: "M752,203.992V452.008h18v-5.859H872v5.859h24V203.992H752Z",
      attr: {
        "data-name": "106",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "106", zone: "Club Lower", zoneId: 3 },
    },
    {
      path: "M1044,202H898V458H870v20h162Z",
      attr: {
        "data-name": "107",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "107", zone: "Club Lower", zoneId: 3 },
    },
    {
      path: "M1195.07,207.132s-4.62-3.848-21.72-4.006c-23.69-.218-124.45,0-124.45,0l-11.85,276.363h51.36s21.19,1.7,23.7,6.008C1150.18,356.63,1195.07,207.132,1195.07,207.132Z",
      attr: {
        "data-name": "108",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "108", zone: "Club Lower", zoneId: 3 },
    },
    {
      path: "M1200,206s108.32,16.746,170,90c-64.98,66.254-112,112-112,112l28,48-120,70s-16.02-30.254-50-40C1149.02,372.587,1200,206,1200,206Z",
      attr: {
        "data-name": "109",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "109", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1372,298L1264,408l50,94,138-42S1438.67,357,1372,298Z",
      attr: {
        "data-name": "110",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "110", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1261.42,619.015H1455V486.658s-1.29-16.27-3.99-21.41c-124.77,36.383-137.7,40.874-137.7,40.874l-27.94-44.767-117.75,68.124s9.98,9.522,9.98,21.411v68.125h83.82Z",
      attr: {
        "data-name": "111",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "111", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1177.6,625.047v68.927c0,12.04-9.98,21.682-9.98,21.682l117.75,68.989,27.94-45.336s12.93,4.549,137.7,41.394c2.7-5.206,3.99-21.683,3.99-21.683V624.985H1261.42Z",
      attr: {
        "data-name": "112",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "112", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1373.6,947.937l-105.84-107.8,49-92.12L1452,789.177S1438.93,890.117,1373.6,947.937Z",
      attr: {
        "data-name": "113",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "113", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1200,1039.94s108.32-16.75,170-90c-64.98-66.253-112-112-112-112l28-48-120-70s-16.02,30.254-50,40C1149.02,873.35,1200,1039.94,1200,1039.94Z",
      attr: {
        "data-name": "114",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "114", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1196,1039.94s0.04,1.83-22,4c-41.5,4.08-128,4.06-128,4.06l-10-280.063h52s21.45-1.7,24-6C1150.55,890.635,1196,1039.94,1196,1039.94Z",
      attr: {
        "data-name": "115",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "115", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1030,768H946v30H902v48h-6v202h148Z",
      attr: {
        "data-name": "116",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "116", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M888,798H756v48h-6v202H894V846h-6V798Z",
      attr: {
        "data-name": "117",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "117", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M612,768h84v30h44v48h6v202H598Z",
      attr: {
        "data-name": "118",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "118", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M448,1042s5.521,1.04,22,2c32.8,1.91,126,4,126,4l12-280.063H556s-21.455-1.7-24-6C493.455,890.635,448,1042,448,1042Z",
      attr: {
        "data-name": "119",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "119", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M444,1039.94s-108.317-16.75-170-90c64.984-66.253,112-112,112-112l-28-48,120-70s16.016,30.254,50,40C494.984,873.35,444,1039.94,444,1039.94Z",
      attr: {
        "data-name": "120",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "120", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M271.2,947.937l106.92-108.9-49.5-93.06L192,787.557S205.2,889.527,271.2,947.937Z",
      attr: {
        "data-name": "121",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "121", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M477.312,715.393s-10.011-9.4-10.011-21.147v-51.9H383.21v-15.38H189V757.687s1.3,16.07,4,21.146c125.172-35.935,138.149-40.371,138.149-40.371l28.03,44.216Z",
      attr: {
        "data-name": "122",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "122", zone: "Lower Bowl", zoneId: 2 },
    },
    {
      path: "M1276,146c-2,0-12,38-12,38s178.34,59.312,224,240c36.25-7.718,36-8,36-8S1494.8,230,1276,146Z",
      attr: {
        "data-name": "311",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: 't-519.934 -93.181 t-0.117 0.843',
      data: { zoneNo: "", zone: "WC 311", zoneId: 2 },
    },
    {
      path: "M1276,1104C1274,1104,1264,1066,1264,1066S1442.34,1006.69,1488,826C1524.25,833.717,1524,834,1524,834S1494.8,1020,1276,1104Z",
      attr: {
        "data-name": "317",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "", zone: "WC 317", zoneId: 2 },
    },
    {
      path: "m1653.2 863.36c16.601-75.82 9.5058-232.77 12.591-234.48l46.048 0.53349c1.829 90.327-3.0343 180.33-10.995 243.6z",
      attr: {
        "data-name": "400E",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "", zone: "WC 400E", zoneId: 2 },
    },
    {
      path: "m117.6 1103.6c63.927 71.164 142.33 107.95 142.33 107.95l-18.093 47.04c-50.206-20.097-105.33-52.341-165.24-118.81z",
      attr: {
        "data-name": "400S",
        opacity: 0,
        fill: "#2196f3",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      data: { zoneNo: "", zone: "WC 400S", zoneId: 2 },
    },
  ];
};
