import React from "react";

import { Typography, withStyles } from "@material-ui/core";

import Reason from "./reason";
import { WHY_US_CONTENTS } from "../../constants/why-us";

const styles = (theme) => ({
  whyFansfirstContainer: {
    maxWidth: 1366,
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "1rem",
  },

  [theme.breakpoints.down("sm")]: {
    whyFansfirstContainer: {
      padding: "0",
    },
  },
});

const WhyFansfirst = ({ classes }) => {
  return (
    <>
      <Typography
        variant="headline"
        align="center"
        color="error"
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
          fontSize: "2rem",
          fontWeight: "bold",
        }}
      >
        <span>WHY FANSFIRST?</span>
      </Typography>
      <div className={classes.whyFansfirstContainer}>
        {WHY_US_CONTENTS.map(({ icon, title, content }, index) => {
          return (
            <Reason key={index} heading={title} content={content} icon={icon} />
          );
        })}
      </div>
    </>
  );
};

export default withStyles(styles)(WhyFansfirst);
