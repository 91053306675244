const defaultRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 5; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };
  
  const lowerZoneRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 31; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  const upperZoneRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 25; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  const middleZoneRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 5; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  export default {
    MIDDLE_ZONE_ROWS: middleZoneRows(),
    LOWER_ZONE_ROWS: lowerZoneRows(),
    UPPER_ZONE_ROWS: upperZoneRows(),
    DEFAULT_ROWS: defaultRows(),
  };
  