import React from "react";

import { TextField } from "@material-ui/core";

/**
 * props type:
 * onChange: () => void
 */

const GrayTextInput = (props) => {
  const { width } = props;
  return (
    <TextField
      InputProps={{
        disableUnderline: true,
      }}
      style={{
        padding: "0.5rem 0.75rem",
        border: "solid 1px #e5e5ea",
        borderRadius: "4px",
        width: width || "45%",
      }}
      {...props}
    />
  );
};

export default GrayTextInput;
