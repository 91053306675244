import React, { Component } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import Select from "react-select";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
    fontSize: ".875rem",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: ".875rem",
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: ".875rem",
    color: theme.palette.text.primary,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Select Section First
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },

        disableUnderline: true,
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  NoOptionsMessage,
};

class RowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  componentWillMount() {
    this.setState({
      rows: this.props.rows,
      section: this.props.section,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rows !== this.props.rows) {
      this.setState({ rows: nextProps.rows });
    }

    if (nextProps.row !== this.props.row) {
      this.setState({ value: nextProps.row });
    }

    if (nextProps.section !== this.props.section) {
      this.setState({ section: nextProps.section });
    }
  }

  handleSelection = (menu) => {
    this.setState({
      value: menu,
    });

    if (menu && this.state.section !== 0) {
      this.props.onRowSelection(menu.value);
    }
  };

  options() {
    const { rows } = this.state;

    if (rows.length === 0) {
      return rows;
    }

    return rows.map((section) => ({
      value: section.value,
      label: section.label,
    }));
  }

  renderSelect() {
    const { value, rows } = this.state;
    const { classes, theme } = this.props;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
    };

    return (
      <Select
        classes={classes}
        styles={selectStyles}
        options={this.options()}
        components={components}
        value={value}
        onChange={(menu) => this.handleSelection(menu)}
        placeholder="Seat Row"
        isClearable
        isDisabled={rows.length === 0}
      />
    );
  }

  render() {
    return (
      <div
        style={{
          padding: "0.5rem 0.75rem",
          backgroundColor: this.props.error
            ? "rgba(255, 23, 68, .05)"
            : "#f5f5f5",
          border: this.props.error
            ? "solid 1px rgba(255, 23, 68, .5)"
            : "solid 1px #e5e5ea",
          borderRadius: "4px",
          marginTop: "8px",
          marginBottom: "4px",
        }}
      >
        {this.renderSelect()}
      </div>
    );
  }
}

RowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(RowSelect);
