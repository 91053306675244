export const getZones = (rsr) => {
  let zones = [];
  var _101 = {
    path:
      "M 494.00,506.00 C 494.00,506.00 494.00,482.00 494.00,482.00 494.00,482.00 493.00,468.00 493.00,468.00 493.00,468.00 493.00,457.00 493.00,457.00 493.00,455.05 492.74,451.29 493.99,449.74 495.60,447.73 507.86,446.18 511.00,445.61 511.00,445.61 565.00,436.92 565.00,436.92 565.00,436.92 581.00,434.79 581.00,434.79 583.07,434.29 586.48,433.56 588.26,434.79 590.59,436.86 589.89,443.11 590.17,446.00 590.17,446.00 592.09,471.00 592.09,471.00 592.09,471.00 592.99,480.00 592.99,480.00 593.04,482.10 593.27,486.76 591.98,488.40 590.64,490.09 587.94,489.94 586.00,490.16 586.00,490.16 571.00,491.95 571.00,491.95 571.00,491.95 520.00,500.00 520.00,500.00 520.00,500.00 494.00,506.00 494.00,506.00 Z",
    attr: {
      id: "_101",
      "data-name": "101",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "101", zone: "Middle Zone", zoneId: "2" },
  };
  var _102 = {
    path:
      "M 606.00,431.00 C 606.00,431.00 630.00,428.17 630.00,428.17 630.00,428.17 660.00,425.91 660.00,425.91 660.00,425.91 670.00,425.00 670.00,425.00 673.07,424.97 679.13,424.51 681.40,426.74 683.76,429.04 682.86,437.43 683.28,441.00 683.28,441.00 686.00,483.00 686.00,483.00 686.00,483.00 663.00,484.00 663.00,484.00 663.00,484.00 652.00,484.91 652.00,484.91 652.00,484.91 610.00,488.00 610.00,488.00 610.00,488.00 606.00,431.00 606.00,431.00 Z",
    attr: {
      id: "_102",
      "data-name": "102",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "102", zone: "Middle Zone", zoneId: "2" },
  };

  var _103 = {
    path:
      "M 700.00,427.00 C 700.00,427.00 794.00,427.00 794.00,427.00 794.00,427.00 794.00,482.00 794.00,482.00 794.00,482.00 703.00,482.00 703.00,482.00 703.00,482.00 702.00,468.00 702.00,468.00 702.00,468.00 702.00,459.00 702.00,459.00 702.00,459.00 700.00,427.00 700.00,427.00 Z",
    attr: {
      id: "_103",
      "data-name": "103",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "103", zone: "Middle Zone", zoneId: "2" },
  };

  var _104 = {
    path: "M 811.00,427.00 C 811.00,427.00 901.00,427.00 901.00,427.00 901.00,427.00 901.00,482.00 901.00,482.00 901.00,482.00 811.00,482.00 811.00,482.00 811.00,482.00 811.00,427.00 811.00,427.00 Z",
    attr: {
      id: "_104",
      "data-name": "104",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "104", zone: "Middle Zone", zoneId: "2" },
  };

  var _105 = {
    path: "M 918.00,427.00 C 918.00,427.00 1007.00,427.00 1007.00,427.00 1007.00,427.00 1007.00,482.00 1007.00,482.00 1007.00,482.00 918.00,482.00 918.00,482.00 918.00,482.00 918.00,427.00 918.00,427.00 Z",
    attr: {
      id: "_105",
      "data-name": "105",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "105", zone: "Middle Zone", zoneId: "2" },
  };

  var _106 = {
    path: "M 1024.00,427.00 C 1024.00,427.00 1130.00,427.00 1130.00,427.00 1130.00,427.00 1130.00,482.00 1130.00,482.00 1130.00,482.00 1024.00,482.00 1024.00,482.00 1024.00,482.00 1024.00,427.00 1024.00,427.00 Z",
    attr: {
      id: "_106",
      "data-name": "106",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "106", zone: "Middle Zone", zoneId: "2" },
  };

  var _107 = {
    path: "M 1147.00,427.00 C 1147.00,427.00 1241.00,427.00 1241.00,427.00 1241.00,427.00 1241.00,482.00 1241.00,482.00 1241.00,482.00 1147.00,482.00 1147.00,482.00 1147.00,482.00 1147.00,427.00 1147.00,427.00 Z",
    attr: {
      id: "_107",
      "data-name": "107",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "107", zone: "Middle Zone", zoneId: "2" },
  };

  var _108 = {
    path: "M 1258.00,428.00 C 1258.00,428.00 1350.00,428.00 1350.00,428.00 1350.00,428.00 1350.00,482.00 1350.00,482.00 1350.00,482.00 1258.00,482.00 1258.00,482.00 1258.00,482.00 1258.00,428.00 1258.00,428.00 Z",
    attr: {
      id: "_108",
      "data-name": "108",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "108", zone: "Middle Zone", zoneId: "2" },
  };

  var _109 = {
    path: "M 1367.00,428.00 C 1367.00,428.00 1458.00,428.00 1458.00,428.00 1458.00,428.00 1458.00,482.00 1458.00,482.00 1458.00,482.00 1367.00,482.00 1367.00,482.00 1367.00,482.00 1367.00,428.00 1367.00,428.00 Z",
    attr: {
      id: "_109",
      "data-name": "109",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "109", zone: "Middle Zone", zoneId: "2" },
  };

  var _110 = {
    path:
      "M 1475.00,429.00 C 1475.00,429.00 1536.00,435.28 1536.00,435.28 1536.00,435.28 1558.00,438.00 1558.00,438.00 1558.00,438.00 1557.09,449.00 1557.09,449.00 1557.09,449.00 1554.91,483.00 1554.91,483.00 1554.91,483.00 1554.00,493.00 1554.00,493.00 1554.00,493.00 1505.00,485.84 1505.00,485.84 1505.00,485.84 1475.00,483.00 1475.00,483.00 1475.00,483.00 1475.00,429.00 1475.00,429.00 Z",
    attr: {
      id: "_110",
      "data-name": "110",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "110", zone: "Middle Zone", zoneId: "2" },
  };

  var _111 = {
    path:
      "M 1575.00,441.00 C 1575.00,441.00 1622.00,449.79 1622.00,449.79 1622.00,449.79 1655.00,458.00 1655.00,458.00 1655.00,458.00 1655.00,456.00 1655.00,456.00 1661.82,459.36 1659.68,461.20 1659.00,469.00 1659.00,469.00 1656.02,497.00 1656.02,497.00 1655.84,499.68 1655.41,509.50 1653.40,510.94 1651.02,512.65 1639.22,509.14 1636.00,508.42 1636.00,508.42 1589.00,498.80 1589.00,498.80 1585.36,498.07 1573.10,496.88 1571.80,493.69 1570.62,491.60 1571.96,484.02 1571.80,481.00 1571.80,481.00 1575.00,441.00 1575.00,441.00 Z",
    attr: {
      id: "_111",
      "data-name": "111",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "111", zone: "Middle Zone", zoneId: "2" },
  };

  var _A = {
    path:
      "M 1771.00,1282.00 C 1771.00,1282.00 1772.28,1294.00 1772.28,1294.00 1772.28,1294.00 1773.00,1334.00 1773.00,1334.00 1773.00,1334.00 1773.00,1446.00 1773.00,1446.00 1773.00,1446.00 1686.00,1446.00 1686.00,1446.00 1686.00,1446.00 1686.00,1290.00 1686.00,1290.00 1686.00,1290.00 1771.00,1290.00 1771.00,1290.00 1771.00,1290.00 1771.00,1282.00 1771.00,1282.00 Z",
    attr: {
      id: "_A",
      "data-name": "A",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone A", zoneId: "1" },
  };

  var _AA = {
    path: "M 1686.00,1462.00 C 1686.00,1462.00 1774.00,1462.00 1774.00,1462.00 1774.00,1462.00 1774.00,1608.00 1774.00,1608.00 1774.00,1608.00 1686.00,1608.00 1686.00,1608.00 1686.00,1608.00 1686.00,1462.00 1686.00,1462.00 Z",
    attr: {
      id: "_AA",
      "data-name": "AA",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone AA", zoneId: "3" },
  };

  var _B = {
    path:
      "M 1533.00,1291.00 C 1533.00,1291.00 1550.00,1290.00 1550.00,1290.00 1550.00,1290.00 1669.00,1290.00 1669.00,1290.00 1669.00,1290.00 1669.00,1446.00 1669.00,1446.00 1669.00,1446.00 1533.00,1446.00 1533.00,1446.00 1533.00,1446.00 1533.00,1291.00 1533.00,1291.00 Z",
    attr: {
      id: "_B",
      "data-name": "B",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone B", zoneId: "1" },
  };

  var _BB = {
    path: "M 1532.00,1462.00 C 1532.00,1462.00 1669.00,1462.00 1669.00,1462.00 1669.00,1462.00 1669.00,1608.00 1669.00,1608.00 1669.00,1608.00 1532.00,1608.00 1532.00,1608.00 1532.00,1608.00 1532.00,1462.00 1532.00,1462.00 Z",
    attr: {
      id: "_BB",
      "data-name": "BB",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone BB", zoneId: "3" },
  };

  var _C = {
    path: "M 1353.00,1291.00 C 1353.00,1291.00 1516.00,1291.00 1516.00,1291.00 1516.00,1291.00 1516.00,1446.00 1516.00,1446.00 1516.00,1446.00 1353.00,1446.00 1353.00,1446.00 1353.00,1446.00 1353.00,1291.00 1353.00,1291.00 Z",
    attr: {
      id: "_C",
      "data-name": "C",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone C", zoneId: "1" },
  };

  var _CC = {
    path: "M 1353.00,1462.00 C 1353.00,1462.00 1515.00,1462.00 1515.00,1462.00 1515.00,1462.00 1515.00,1608.00 1515.00,1608.00 1515.00,1608.00 1353.00,1608.00 1353.00,1608.00 1353.00,1608.00 1353.00,1462.00 1353.00,1462.00 Z",
    attr: {
      id: "_CC",
      "data-name": "CC",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone CC", zoneId: "3" },
  };

  var _D = {
    path: "M 1175.00,1291.00 C 1175.00,1291.00 1336.00,1291.00 1336.00,1291.00 1336.00,1291.00 1336.00,1446.00 1336.00,1446.00 1336.00,1446.00 1175.00,1446.00 1175.00,1446.00 1175.00,1446.00 1175.00,1291.00 1175.00,1291.00 Z",
    attr: {
      id: "_D",
      "data-name": "D",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone D", zoneId: "1" },
  };

  var _DD = {
    path: "M 1176.00,1462.00 C 1176.00,1462.00 1336.00,1462.00 1336.00,1462.00 1336.00,1462.00 1336.00,1608.00 1336.00,1608.00 1336.00,1608.00 1176.00,1608.00 1176.00,1608.00 1176.00,1608.00 1176.00,1462.00 1176.00,1462.00 Z",
    attr: {
      id: "_DD",
      "data-name": "DD",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone DD", zoneId: "3" },
  };

  var _E = {
    path:
      "M 997.00,1292.00 C 997.00,1292.00 1076.00,1292.00 1076.00,1292.00 1076.00,1292.00 1091.00,1291.00 1091.00,1291.00 1091.00,1291.00 1158.00,1291.00 1158.00,1291.00 1158.00,1291.00 1158.00,1446.00 1158.00,1446.00 1158.00,1446.00 997.00,1446.00 997.00,1446.00 997.00,1446.00 997.00,1292.00 997.00,1292.00 Z",
    attr: {
      id: "_E",
      "data-name": "E",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone E", zoneId: "1" },
  };

  var _EE = {
    path:
      "M 997.00,1462.00 C 997.00,1462.00 1121.00,1462.00 1121.00,1462.00 1121.00,1462.00 1147.00,1462.00 1147.00,1462.00 1147.00,1462.00 1157.40,1463.02 1157.40,1463.02 1157.40,1463.02 1159.00,1471.00 1159.00,1471.00 1159.00,1471.00 1159.00,1608.00 1159.00,1608.00 1159.00,1608.00 1143.00,1607.00 1143.00,1607.00 1143.00,1607.00 997.00,1607.00 997.00,1607.00 997.00,1607.00 997.00,1462.00 997.00,1462.00 Z",
    attr: {
      id: "_EE",
      "data-name": "EE",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone EE", zoneId: "3" },
  };

  var _F = {
    path: "M 816.00,1292.00 C 816.00,1292.00 980.00,1292.00 980.00,1292.00 980.00,1292.00 980.00,1446.00 980.00,1446.00 980.00,1446.00 816.00,1446.00 816.00,1446.00 816.00,1446.00 816.00,1292.00 816.00,1292.00 Z",
    attr: {
      id: "_F",
      "data-name": "F",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone F", zoneId: "1" },
  };

  var _FF = {
    path: "M 817.00,1462.00 C 817.00,1462.00 980.00,1462.00 980.00,1462.00 980.00,1462.00 980.00,1607.00 980.00,1607.00 980.00,1607.00 817.00,1607.00 817.00,1607.00 817.00,1607.00 817.00,1462.00 817.00,1462.00 Z",
    attr: {
      id: "_FF",
      "data-name": "FF",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone FF", zoneId: "3" },
  };

  var _G = {
    path: "M 638.00,1292.00 C 638.00,1292.00 799.00,1292.00 799.00,1292.00 799.00,1292.00 799.00,1446.00 799.00,1446.00 799.00,1446.00 638.00,1446.00 638.00,1446.00 638.00,1446.00 638.00,1292.00 638.00,1292.00 Z",
    attr: {
      id: "_G",
      "data-name": "G",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone G", zoneId: "1" },
  };

  var _GG = {
    path: "M 638.00,1462.00 C 638.00,1462.00 800.00,1462.00 800.00,1462.00 800.00,1462.00 800.00,1607.00 800.00,1607.00 800.00,1607.00 638.00,1607.00 638.00,1607.00 638.00,1607.00 638.00,1462.00 638.00,1462.00 Z",
    attr: {
      id: "_GG",
      "data-name": "GG",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone GG", zoneId: "3" },
  };

  var _H = {
    path: "M 485.00,1293.00 C 485.00,1293.00 621.00,1293.00 621.00,1293.00 621.00,1293.00 621.00,1446.00 621.00,1446.00 621.00,1446.00 485.00,1446.00 485.00,1446.00 485.00,1446.00 485.00,1293.00 485.00,1293.00 Z",
    attr: {
      id: "_H",
      "data-name": "H",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone H", zoneId: "1" },
  };

  var _HH = {
    path: "M 486.00,1462.00 C 486.00,1462.00 621.00,1462.00 621.00,1462.00 621.00,1462.00 621.00,1607.00 621.00,1607.00 621.00,1607.00 486.00,1607.00 486.00,1607.00 486.00,1607.00 486.00,1462.00 486.00,1462.00 Z",
    attr: {
      id: "_HH",
      "data-name": "HH",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone HH", zoneId: "3" },
  };

  var _J = {
    path:
      "M 394.00,1284.00 C 394.00,1284.00 395.00,1293.00 395.00,1293.00 395.00,1293.00 468.00,1293.00 468.00,1293.00 468.00,1293.00 468.00,1446.00 468.00,1446.00 468.00,1446.00 417.00,1446.00 417.00,1446.00 410.15,1446.00 399.95,1444.68 394.00,1448.00 394.00,1448.00 394.00,1284.00 394.00,1284.00 Z",
    attr: {
      id: "_J",
      "data-name": "J",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone J", zoneId: "1" },
  };

  var _JJ = {
    path:
      "M 394.00,1460.00 C 399.95,1463.32 410.15,1462.00 417.00,1462.00 417.00,1462.00 469.00,1462.00 469.00,1462.00 469.00,1462.00 469.00,1607.00 469.00,1607.00 469.00,1607.00 454.00,1606.00 454.00,1606.00 454.00,1606.00 394.00,1606.00 394.00,1606.00 394.00,1606.00 394.00,1460.00 394.00,1460.00 Z",
    attr: {
      id: "_JJ",
      "data-name": "JJ",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone JJ", zoneId: "3" },
  };

  var _K = {
    path:
      "M 383.00,586.00 C 383.00,586.00 412.40,625.00 412.40,625.00 412.40,625.00 434.92,656.00 434.92,656.00 434.92,656.00 444.12,668.00 444.12,668.00 445.90,670.63 453.47,680.35 453.09,682.79 452.69,685.37 448.09,687.18 446.00,688.26 446.00,688.26 428.00,699.19 428.00,699.19 425.45,700.91 422.29,703.67 419.00,703.05 415.71,702.44 403.50,692.26 400.00,689.63 400.00,689.63 354.00,654.35 354.00,654.35 354.00,654.35 340.00,643.58 340.00,643.58 338.33,642.24 335.28,640.04 334.87,637.83 334.20,634.20 342.23,624.90 344.61,622.00 358.15,605.52 366.88,599.08 383.00,586.00 Z",
    attr: {
      id: "_K",
      "data-name": "K",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone K", zoneId: "1" },
  };

  var _L = {
    path:
      "M 397.00,576.00 C 397.00,576.00 416.00,564.86 416.00,564.86 432.14,555.85 450.46,546.86 468.00,541.00 468.00,541.00 480.04,579.00 480.04,579.00 480.04,579.00 492.65,618.00 492.65,618.00 492.65,618.00 496.19,630.00 496.19,630.00 496.19,630.00 503.33,653.00 503.33,653.00 504.12,655.38 506.23,660.21 504.89,662.49 503.73,664.45 499.12,665.62 497.00,666.33 497.00,666.33 478.00,673.20 478.00,673.20 475.23,674.30 472.13,676.05 469.29,674.30 466.24,672.42 461.10,664.22 458.91,661.00 458.91,661.00 445.03,642.00 445.03,642.00 445.03,642.00 436.51,631.00 436.51,631.00 436.51,631.00 424.09,614.00 424.09,614.00 424.09,614.00 417.98,605.00 417.98,605.00 417.98,605.00 397.00,576.00 397.00,576.00 Z",
    attr: {
      id: "_L",
      "data-name": "L",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone L", zoneId: "1" },
  };

  var _M = {
    path:
      "M 484.00,535.00 C 505.18,528.07 539.18,518.89 561.00,516.00 561.00,516.00 562.83,536.00 562.83,536.00 562.83,536.00 568.83,597.00 568.83,597.00 568.83,597.00 574.00,647.00 574.00,647.00 574.00,647.00 544.00,653.21 544.00,653.21 544.00,653.21 522.00,658.00 522.00,658.00 522.00,658.00 512.34,627.00 512.34,627.00 512.34,627.00 494.98,571.00 494.98,571.00 494.98,571.00 484.00,535.00 484.00,535.00 Z",
    attr: {
      id: "_M",
      "data-name": "M",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone M", zoneId: "1" },
  };

  var _MM = {
    path:
      "M 492.00,433.00 C 492.00,433.00 491.00,417.00 491.00,417.00 491.00,417.00 490.00,390.00 490.00,390.00 490.00,390.00 489.00,373.00 489.00,373.00 489.00,373.00 488.00,355.00 488.00,355.00 488.00,355.00 488.00,351.00 488.00,351.00 488.00,348.66 487.80,345.26 489.02,343.22 490.93,340.05 504.91,335.05 509.00,333.28 509.00,333.28 554.00,314.00 554.00,314.00 554.00,314.00 579.00,304.00 579.00,304.00 579.00,304.00 582.09,340.00 582.09,340.00 582.09,340.00 586.01,391.00 586.01,391.00 586.01,391.00 587.42,409.00 587.42,409.00 587.74,411.45 588.61,415.15 586.40,416.99 584.92,418.22 580.01,418.72 578.00,419.00 578.00,419.00 559.00,421.87 559.00,421.87 559.00,421.87 492.00,433.00 492.00,433.00 Z",
    attr: {
      id: "_MM",
      "data-name": "MM",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone MM", zoneId: "3" },
  };

  var _N = {
    path:
      "M 578.00,513.00 C 578.00,513.00 619.00,508.17 619.00,508.17 619.00,508.17 677.00,506.00 677.00,506.00 677.00,506.00 677.00,638.00 677.00,638.00 677.00,638.00 657.00,639.00 657.00,639.00 634.68,639.03 612.13,642.08 590.00,645.00 590.00,645.00 587.17,613.00 587.17,613.00 587.17,613.00 581.83,556.00 581.83,556.00 581.83,556.00 578.00,513.00 578.00,513.00 Z",
    attr: {
      id: "_N",
      "data-name": "N",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone N", zoneId: "1" },
  };

  var _NN = {
    path:
      "M 605.00,415.00 C 605.00,415.00 603.17,394.00 603.17,394.00 603.17,394.00 601.17,367.00 601.17,367.00 601.17,367.00 598.58,331.00 598.58,331.00 598.58,331.00 596.91,306.00 596.91,306.00 596.91,306.00 596.91,296.65 596.91,296.65 596.91,296.65 612.00,289.28 612.00,289.28 612.00,289.28 624.00,284.53 624.00,284.53 624.00,284.53 641.00,284.00 641.00,284.00 641.00,284.00 673.00,284.00 673.00,284.00 673.00,284.00 674.00,294.00 674.00,294.00 674.00,294.00 674.00,303.00 674.00,303.00 674.00,303.00 675.91,324.00 675.91,324.00 675.91,324.00 679.09,378.00 679.09,378.00 679.09,378.00 681.00,409.00 681.00,409.00 681.00,409.00 639.00,411.17 639.00,411.17 639.00,411.17 605.00,415.00 605.00,415.00 Z",
    attr: {
      id: "_NN",
      "data-name": "NN",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone NN", zoneId: "3" },
  };

  var _P = {
    path:
      "M 694.00,506.00 C 694.00,506.00 768.00,506.00 768.00,506.00 768.00,506.00 782.00,506.00 782.00,506.00 782.00,506.00 790.01,507.74 790.01,507.74 790.01,507.74 791.00,515.00 791.00,515.00 791.00,515.00 791.00,638.00 791.00,638.00 791.00,638.00 694.00,638.00 694.00,638.00 694.00,638.00 694.00,506.00 694.00,506.00 Z",
    attr: {
      id: "_P",
      "data-name": "P",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone P", zoneId: "1" },
  };

  var _PP = {
    path:
      "M 690.00,284.00 C 690.00,284.00 794.00,284.00 794.00,284.00 794.00,284.00 794.00,411.00 794.00,411.00 794.00,411.00 707.00,411.00 707.00,411.00 707.00,411.00 699.60,409.98 699.60,409.98 699.60,409.98 697.96,401.00 697.96,401.00 697.96,401.00 697.09,393.00 697.09,393.00 697.09,393.00 696.58,381.00 696.58,381.00 696.58,381.00 695.91,369.00 695.91,369.00 695.91,369.00 694.00,348.00 694.00,348.00 694.00,348.00 694.00,339.00 694.00,339.00 694.00,339.00 691.00,302.00 691.00,302.00 691.00,302.00 691.00,293.00 691.00,293.00 691.00,293.00 690.00,284.00 690.00,284.00 Z",
    attr: {
      id: "_PP",
      "data-name": "PP",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone PP", zoneId: "3" },
  };

  var _Q = {
    path:
      "M 808.00,507.00 C 808.00,507.00 864.00,507.00 864.00,507.00 864.00,507.00 901.00,508.00 901.00,508.00 901.00,508.00 901.00,639.00 901.00,639.00 901.00,639.00 878.00,639.00 878.00,639.00 878.00,639.00 861.00,638.00 861.00,638.00 861.00,638.00 808.00,638.00 808.00,638.00 808.00,638.00 808.00,507.00 808.00,507.00 Z",
    attr: {
      id: "_Q",
      "data-name": "Q",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone Q", zoneId: "1" },
  };

  var _QQ = {
    path: "M 811.00,284.00 C 811.00,284.00 901.00,284.00 901.00,284.00 901.00,284.00 901.00,411.00 901.00,411.00 901.00,411.00 811.00,411.00 811.00,411.00 811.00,411.00 811.00,284.00 811.00,284.00 Z",
    attr: {
      id: "_QQ",
      "data-name": "QQ",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone QQ", zoneId: "3" },
  };

  var _R = {
    path:
      "M 918.00,508.00 C 918.00,508.00 975.00,508.00 975.00,508.00 975.00,508.00 1009.00,509.00 1009.00,509.00 1009.00,509.00 1009.00,639.00 1009.00,639.00 1009.00,639.00 918.00,639.00 918.00,639.00 918.00,639.00 918.00,508.00 918.00,508.00 Z",
    attr: {
      id: "_R",
      "data-name": "R",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone R", zoneId: "1" },
  };

  var _RR = {
    path: "M 918.00,284.00 C 918.00,284.00 1007.00,284.00 1007.00,284.00 1007.00,284.00 1007.00,411.00 1007.00,411.00 1007.00,411.00 918.00,411.00 918.00,411.00 918.00,411.00 918.00,284.00 918.00,284.00 Z",
    attr: {
      id: "_RR",
      "data-name": "RR",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone RR", zoneId: "3" },
  };

  var _S = {
    path:
      "M 1026.00,509.00 C 1026.00,509.00 1099.00,509.00 1099.00,509.00 1099.00,509.00 1115.00,510.00 1115.00,510.00 1115.00,510.00 1115.00,639.00 1115.00,639.00 1115.00,639.00 1026.00,639.00 1026.00,639.00 1026.00,639.00 1026.00,509.00 1026.00,509.00 Z",
    attr: {
      id: "_S",
      "data-name": "S",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone S", zoneId: "1" },
  };

  var _SS = {
    path: "M 1024.00,284.00 C 1024.00,284.00 1130.00,284.00 1130.00,284.00 1130.00,284.00 1130.00,411.00 1130.00,411.00 1130.00,411.00 1024.00,411.00 1024.00,411.00 1024.00,411.00 1024.00,284.00 1024.00,284.00 Z",
    attr: {
      id: "_SS",
      "data-name": "SS",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone SS", zoneId: "3" },
  };

  var _T = {
    path:
      "M 1132.00,510.00 C 1132.00,510.00 1200.00,510.00 1200.00,510.00 1200.00,510.00 1212.00,510.96 1212.00,510.96 1212.00,510.96 1225.00,510.96 1225.00,510.96 1225.00,510.96 1225.00,639.00 1225.00,639.00 1225.00,639.00 1132.00,639.00 1132.00,639.00 1132.00,639.00 1132.00,510.00 1132.00,510.00 Z",
    attr: {
      id: "_T",
      "data-name": "T",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone T", zoneId: "1" },
  };

  var _TT = {
    path: "M 1147.00,284.00 C 1147.00,284.00 1241.00,284.00 1241.00,284.00 1241.00,284.00 1241.00,411.00 1241.00,411.00 1241.00,411.00 1147.00,411.00 1147.00,411.00 1147.00,411.00 1147.00,284.00 1147.00,284.00 Z",
    attr: {
      id: "_TT",
      "data-name": "TT",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone TT", zoneId: "3" },
  };

  var _U = {
    path:
      "M 1242.00,511.00 C 1242.00,511.00 1301.00,511.00 1301.00,511.00 1301.00,511.00 1316.00,512.00 1316.00,512.00 1316.00,512.00 1333.00,512.00 1333.00,512.00 1333.00,512.00 1333.00,639.00 1333.00,639.00 1333.00,639.00 1242.00,639.00 1242.00,639.00 1242.00,639.00 1242.00,511.00 1242.00,511.00 Z",
    attr: {
      id: "_U",
      "data-name": "U",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone U", zoneId: "1" },
  };

  var _UU = {
    path: "M 1258.00,284.00 C 1258.00,284.00 1350.00,284.00 1350.00,284.00 1350.00,284.00 1350.00,412.00 1350.00,412.00 1350.00,412.00 1258.00,412.00 1258.00,412.00 1258.00,412.00 1258.00,284.00 1258.00,284.00 Z",
    attr: {
      id: "_UU",
      "data-name": "UU",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone UU", zoneId: "3" },
  };

  var _V = {
    path:
      "M 1350.00,512.00 C 1350.00,512.00 1402.00,512.00 1402.00,512.00 1402.00,512.00 1418.00,513.00 1418.00,513.00 1418.00,513.00 1441.00,513.00 1441.00,513.00 1441.00,513.00 1441.00,640.00 1441.00,640.00 1441.00,640.00 1373.00,640.00 1373.00,640.00 1373.00,640.00 1350.00,639.00 1350.00,639.00 1350.00,639.00 1350.00,512.00 1350.00,512.00 Z",
    attr: {
      id: "_V",
      "data-name": "V",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone V", zoneId: "1" },
  };

  var _VV = {
    path: "M 1367.00,284.00 C 1367.00,284.00 1458.00,284.00 1458.00,284.00 1458.00,284.00 1458.00,412.00 1458.00,412.00 1458.00,412.00 1367.00,412.00 1367.00,412.00 1367.00,412.00 1367.00,284.00 1367.00,284.00 Z",
    attr: {
      id: "_VV",
      "data-name": "VV",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone VV", zoneId: "3" },
  };

  var _W = {
    path:
      "M 1458.00,513.00 C 1458.00,513.00 1506.00,513.00 1506.00,513.00 1506.00,513.00 1523.00,514.00 1523.00,514.00 1523.00,514.00 1551.00,516.00 1551.00,516.00 1551.00,516.00 1548.17,545.00 1548.17,545.00 1548.17,545.00 1545.17,578.00 1545.17,578.00 1545.17,578.00 1541.17,620.00 1541.17,620.00 1541.17,620.00 1539.00,642.00 1539.00,642.00 1539.00,642.00 1510.00,640.00 1510.00,640.00 1510.00,640.00 1494.00,639.00 1494.00,639.00 1494.00,639.00 1478.00,639.00 1478.00,639.00 1478.00,639.00 1458.00,640.00 1458.00,640.00 1458.00,640.00 1458.00,513.00 1458.00,513.00 Z",
    attr: {
      id: "_W",
      "data-name": "W",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone W", zoneId: "1" },
  };

  var _WW = {
    path:
      "M 1475.00,284.00 C 1475.00,284.00 1524.00,284.00 1524.00,284.00 1534.57,284.02 1539.49,287.10 1549.00,291.15 1552.55,292.66 1565.22,296.94 1567.20,299.50 1568.68,301.97 1567.80,307.17 1567.20,310.00 1567.20,310.00 1566.01,329.00 1566.01,329.00 1566.01,329.00 1564.09,354.00 1564.09,354.00 1564.09,354.00 1559.00,422.00 1559.00,422.00 1559.00,422.00 1537.00,419.28 1537.00,419.28 1537.00,419.28 1475.00,413.00 1475.00,413.00 1475.00,413.00 1475.00,284.00 1475.00,284.00 Z",
    attr: {
      id: "_WW",
      "data-name": "WW",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone WW", zoneId: "3" },
  };

  var _X = {
    path:
      "M 1568.00,518.00 C 1580.34,519.00 1607.57,524.31 1620.00,527.37 1620.00,527.37 1646.00,534.00 1646.00,534.00 1646.00,534.00 1634.74,576.00 1634.74,576.00 1634.74,576.00 1619.74,629.00 1619.74,629.00 1619.74,629.00 1612.00,657.00 1612.00,657.00 1612.00,657.00 1572.00,647.20 1572.00,647.20 1572.00,647.20 1557.07,643.41 1557.07,643.41 1557.07,643.41 1557.07,634.00 1557.07,634.00 1557.07,634.00 1559.72,608.00 1559.72,608.00 1559.72,608.00 1564.17,557.00 1564.17,557.00 1564.17,557.00 1568.00,518.00 1568.00,518.00 Z",
    attr: {
      id: "_X",
      "data-name": "X",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone X", zoneId: "1" },
  };

  var _XX = {
    path:
      "M 1585.00,306.00 C 1585.00,306.00 1672.00,341.00 1672.00,341.00 1672.00,341.00 1669.17,372.00 1669.17,372.00 1669.17,372.00 1662.00,443.00 1662.00,443.00 1662.00,443.00 1626.00,434.42 1626.00,434.42 1626.00,434.42 1576.00,425.00 1576.00,425.00 1576.00,425.00 1577.83,403.00 1577.83,403.00 1577.83,403.00 1579.83,377.00 1579.83,377.00 1579.83,377.00 1581.09,354.00 1581.09,354.00 1581.09,354.00 1585.00,306.00 1585.00,306.00 Z",
    attr: {
      id: "_XX",
      "data-name": "XX",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Upper Zone XX", zoneId: "3" },
  };

  var _Y = {
    path:
      "M 1662.00,540.00 C 1662.00,540.00 1683.00,547.00 1683.00,547.00 1683.00,547.00 1712.00,559.81 1712.00,559.81 1712.00,559.81 1731.00,570.00 1731.00,570.00 1731.00,570.00 1711.20,601.00 1711.20,601.00 1711.20,601.00 1685.59,640.00 1685.59,640.00 1685.59,640.00 1669.35,665.00 1669.35,665.00 1669.35,665.00 1663.45,673.58 1663.45,673.58 1660.71,675.95 1657.86,674.19 1655.00,673.00 1655.00,673.00 1636.00,665.20 1636.00,665.20 1633.77,664.32 1629.56,663.23 1628.78,660.73 1628.15,658.68 1630.21,653.25 1630.86,651.00 1630.86,651.00 1637.97,626.00 1637.97,626.00 1637.97,626.00 1649.03,586.00 1649.03,586.00 1649.03,586.00 1662.00,540.00 1662.00,540.00 Z",
    attr: {
      id: "_Y",
      "data-name": "Y",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone Y", zoneId: "1" },
  };

  var _Z = {
    path:
      "M 1744.00,580.00 C 1750.46,581.75 1757.77,588.00 1763.00,592.26 1775.11,602.11 1786.68,612.68 1796.33,625.00 1799.54,629.10 1802.39,633.52 1805.03,638.00 1805.99,639.62 1807.50,641.84 1807.13,643.82 1806.70,646.07 1803.73,647.79 1802.00,649.02 1802.00,649.02 1787.00,659.42 1787.00,659.42 1787.00,659.42 1739.00,692.86 1739.00,692.86 1735.39,695.43 1720.18,706.59 1717.00,707.08 1713.31,707.64 1708.09,702.76 1705.00,700.67 1696.81,695.12 1685.97,687.89 1677.00,684.00 1677.00,684.00 1691.46,661.00 1691.46,661.00 1691.46,661.00 1725.29,609.00 1725.29,609.00 1725.29,609.00 1744.00,580.00 1744.00,580.00 Z",
    attr: {
      id: "_Z",
      "data-name": "Z",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "Lower Zone Z", zoneId: "1" },
  };
  var _field1 = {
    path:
      "M 1387.00,1240.00 C 1387.00,1240.00 1409.00,1239.00 1409.00,1239.00 1409.00,1239.00 1582.00,1239.00 1582.00,1239.00 1582.00,1239.00 1583.00,1253.00 1583.00,1253.00 1583.00,1253.00 1583.00,1274.00 1583.00,1274.00 1583.00,1274.00 1387.00,1274.00 1387.00,1274.00 1387.00,1274.00 1387.00,1240.00 1387.00,1240.00 Z",
    attr: {
      id: "_field1",
      "data-name": "field1",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "1", zone: "Field", zoneId: "1" },
  };
  var _field2 = {
    path:
      "M 1154.00,1238.00 C 1154.00,1238.00 1218.00,1238.00 1218.00,1238.00 1218.00,1238.00 1235.00,1239.00 1235.00,1239.00 1235.00,1239.00 1360.00,1239.00 1360.00,1239.00 1360.00,1239.00 1360.00,1274.00 1360.00,1274.00 1360.00,1274.00 1336.00,1274.00 1336.00,1274.00 1336.00,1274.00 1319.00,1275.00 1319.00,1275.00 1319.00,1275.00 1154.00,1275.00 1154.00,1275.00 1154.00,1275.00 1154.00,1238.00 1154.00,1238.00 Z",
    attr: {
      id: "_field2",
      "data-name": "field2",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "2", zone: "Field", zoneId: "1" },
  };
  var _field3 = {
    path:
      "M 786.00,1238.00 C 786.00,1238.00 979.00,1238.00 979.00,1238.00 979.00,1238.00 979.00,1275.00 979.00,1275.00 979.00,1275.00 876.00,1275.00 876.00,1275.00 876.00,1275.00 861.00,1276.00 861.00,1276.00 861.00,1276.00 786.00,1276.00 786.00,1276.00 786.00,1276.00 786.00,1238.00 786.00,1238.00 Z",
    attr: {
      id: "_field3",
      "data-name": "field3",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "3", zone: "Field", zoneId: "1" },
  };
  var _field4 = {
    path:
      "M 534.00,1238.00 C 534.00,1238.00 756.00,1238.00 756.00,1238.00 756.00,1238.00 756.00,1276.00 756.00,1276.00 756.00,1276.00 533.00,1276.00 533.00,1276.00 533.00,1276.00 534.00,1258.00 534.00,1258.00 534.00,1258.00 534.00,1238.00 534.00,1238.00 Z",
    attr: {
      id: "_field4",
      "data-name": "field4",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "4", zone: "Field", zoneId: "1" },
  };

  zones.push(
    _field1,
    _field2,
    _field3,
    _field4,
    _XX,
    _WW,
    _VV,
    _UU,
    _TT,
    _SS,
    _RR,
    _QQ,
    _PP,
    _NN,
    _MM,
    _101,
    _102,
    _103,
    _104,
    _105,
    _106,
    _107,
    _108,
    _109,
    _110,
    _111,
    _Z,
    _Y,
    _X,
    _W,
    _V,
    _U,
    _T,
    _S,
    _R,
    _Q,
    _P,
    _N,
    _M,
    _L,
    _K,
    _JJ,
    _HH,
    _GG,
    _FF,
    _EE,
    _DD,
    _CC,
    _BB,
    _AA,
    _J,
    _H,
    _G,
    _F,
    _E,
    _D,
    _C,
    _B,
    _A
  );
  return zones;
};
