export const styles = (theme) => ({
  bannerContainer: {
    maxWidth: 1366,
    margin: "0 auto",
    display: "flex",
  },
  bannerImageContainer: {
    position: "relative",
    padding: "0 2rem",
    display: "flex",
    justifyContents: "center",
    alignItems: "center",
  },
  bannerImage: {
    width: "90%",
    marginLeft: "35px",
    [theme.breakpoints.up('sm')]: {
      width: "70%",
    },
    [theme.breakpoints.up('md')]: {
      width: "90%",
    },
  },
  bannerHeadlineContainer: {
    display: "block",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    verticalAlign: "middle",
  },
  bannerHeadline: {
    display: "block",
    width: "90%",
    marginTop: "110px",
  },
  savingsHeadline: {
    color: "#00DE94",
    fontWeight: "bold",
    width: "90%",
    marginTop: "110px",
  },
  subheading: {
    //display: "block",
    width: "90%",
    fontSize: theme.spacing.unit * 2,
    textAlign: "center",
    fontFamily: '"Nunito Sans", sans-serif',
    color: "#5F666F",
    paddingTop: "-250px",
  },
  [theme.breakpoints.down("sm")]: {
    bannerContainer: {
      flexDirection: "column-reverse",
    },
    bannerHeadlineContainer: {
      padding: "0rem 1rem",
      paddingTop: "0",
      alignItems: "center",
      justifyContent: "center",
    },
    bannerHeadline: {
      fontFamily: '"Nunito Sans", sans-serif',
      fontSize: theme.spacing.unit * 4,
      //fontWeight: 300,
      textAlign: "center",
      marginBottom: "0rem",
      lineHeight: "32.78px",
      marginTop: "100px",
      width: "100%",
    },
    savingsHeadline: {
      fontFamily: '"Nunito Sans", sans-serif',
      fontSize: 24,
      //fontWeight: 300,
      textAlign: "center",
      marginBottom: "1rem",
      lineHeight: "32.78px",
      marginTop: "20px",
      width: "100%",
    },

    subheading: {
      fontSize: theme.spacing.unit * 2,
      textAlign: "center",
      fontFamily: '"Nunito Sans", sans-serif',
      color: "#5F666F",
      width: "100%",
    },
    bannerImageContainer: {
      padding: "0",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "0px",
    },
    bannerImage: {
      marginTop: "0px",
      marginLeft: "0",
      marginBottom: "20px",
    },
    lineBreaker: {
      display: "none",
    },
  },
  [theme.breakpoints.up("sm")]: {
    bannerContainer: {
      padding: "2.5rem 0",
    },
    bannerHeadlineContainer: { justifyContent: "center", alignItems: "center" },
    bannerHeadline: {
      marginBottom: "1rem",
      fontSize: theme.spacing.unit * 5,
      fontWeight: 500,
      marginTop: "60px",
      lineHeight: "1.30357em",
    },
    savingsHeadline: {
      marginBottom: "1rem",
      fontSize: theme.spacing.unit * 5,
      fontWeight: 500,
      marginTop: "50px",
    },
    subheading: {
      fontSize: theme.spacing.unit * 2.5,
      //lineHeight: 1.5,
    },
    bannerImageContainer: {
      //marginTop: "40px",
    },
    lineBreaker: {
      display: "block",
    },
  },
  [theme.breakpoints.up("md")]: {
    bannerHeadline: {
      fontSize: theme.spacing.unit * 4.5,
      marginBottom: "1rem",
      marginTop: "0rem",
      subheading: {
        fontSize: theme.spacing.unit * 4,
        textAlign: "left",
      },
    },
    bannerContainer: {
      padding: "2rem 0",
      marginBottom: "1rem",
    },
    savingsHeadline: {
      fontSize: theme.spacing.unit * 4.5,
      marginBottom: "0rem",
    },
    bannerHeadlineContainer: {
      justifyContent: "center",
      alignItems: "center",
      verticalAlign: "center",
      alignContent: "center",
      display: "grid",
    },
    lineBreaker: {
      display: "none",
    },
    subheading: { textAlign: "left" },
  },
  [theme.breakpoints.up("lg")]: {
    bannerHeadline: {
      fontSize: theme.spacing.unit * 6,
      marginBottom: "1rem",
      marginTop: "0rem",
    },
    bannerImageContainer: {
      marginTop: "0px",
    },
    subheading: {
      fontSize: theme.spacing.unit * 2.85,
      textAlign: "left",
    },
    savingsHeadline: {
      fontSize: theme.spacing.unit * 6,
      marginBottom: "0rem",
    },
    lineBreaker: {
      display: "block",
    },
    bannerHeadlineContainer: {
      justifyContent: "center",
      alignItems: "center",
      verticalAlign: "center",
      alignContent: "center",
      display: "grid",
    },
  },
  bannerContainer: {
    marginBottom: "0",
  },
  searchContainer: {
    paddingRight: "10%",
    [theme.breakpoints.down('sm')]: {
      display: "contents",
    },
  }
});
