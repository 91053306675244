import React, { Component } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import BuyIcon from '@material-ui/icons/ShoppingCart';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { withStyles, Button } from "@material-ui/core";
import { renderSeatNumbers, renderZoneName, renderRowName } from "../libs/shared/seat-helpers";
import { getFormattedTime } from "../libs/shared/grid-helper";
import { getTeamName } from "../libs/shared/team-helper";
import BuyerTrustSeal from "../images/buyertrust-seal.svg";

const styles = (theme) => ({
  buyButton: {
    border: "1px solid #2196f3",
    boxSizing: "border-box",
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    '& a': {
      color: "#000000",
      textDecoration: "none",
    },
    '& a:hover': { 
      color: "#2196f3",
    },
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  finePrint: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  [theme.breakpoints.up("md")]: {
    teamSelectorContainer: {
      borderTop: "1px solid #D5DEE9",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 20,
      fontSize: "1.5rem",
      padding: "1rem",
    },
    finePrint: {
      padding: "1.5rem",
    },
  },
  [theme.breakpoints.down("md")]: {
    teamSelectorContainer: {
      boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.05)",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 8,
      padding: ".75rem",
    },
    finePrint: {
      padding: ".5rem",
    },
  },
});

class SellerListingTable extends Component {
  constructor(props) {
    super(props);
    const url = window.location.pathname;
    const sellerSlug = url.split("/")[1];
    this.state = {
      seats: null,
      sellerSlug: sellerSlug
    };
  }

  componentWillMount() {
    const { seats } = this.props;
    this.setState({
      seats,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { seats } = nextProps;

    this.setState({ seats });
  }

  getGameDate(game) {
    return moment.tz(game.date, game.timezone).format("MMM D");
  }

  getGameTime(game) {
    return getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);
  }

  getGameName(seat) {
    if (seat.gameDetails.isTbd || seat.gameDetails.isPlayoffs) {
      return seat.gameDetails.longName;
    }

    const team1 = seat.gameDetails.opponent;
    const team2 = getTeamName(seat.gameDetails.homeTeamSlug);

    return `${team1} vs. ${team2 ? team2 : "-"}`;
  }

  renderTable(seats) {
    const { classes } = this.props;
    const { sellerSlug } = this.state;
    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow style={{ fontFamily: "Nunito Sans" }}>
              <TableCell style={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Game
                </Typography>
              </TableCell>
              <TableCell padding="checkbox" style={{ textAlign: "center", fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seat Details
                </Typography>
              </TableCell>
              <TableCell padding="checkbox" style={{ textAlign: "center", fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Price (CAD){" "}
                </Typography>
              </TableCell>
              <TableCell padding="checkbox" style={{ textAlign: "center", fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seat Numbers{" "}
                </Typography>
              </TableCell>
              <TableCell padding="checkbox" style={{ textAlign: "center", fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Available{" "}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {seats.map((seat, index) => {
              const { gameDetails: game } = seat;
              const defaultSeatNumbers = {
                start: typeof this.state.seats.seatNumberStart === "undefined" ? seat.seatNumberStart : "",
                end: typeof this.state.seats.seatNumberEnd === "undefined" ? seat.seatNumberEnd : "",
              };
              const slug = `${game.homeTeamSlug}-tickets`;

              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" style={{ padding: "10px 24px" }}>
                    <Typography className={classes.titleText} style={{ color: "#00A0FF", textTransform: "uppercase" }}>
                      {seat.gameDetails.isTbd ? "TBD" : this.getGameDate(seat.gameDetails)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {seat.gameDetails.isTbd ? null : this.getGameTime(seat.gameDetails)}
                    </Typography>
                    <Typography className={classes.titleText}>
                      <a href={`/${sellerSlug}/seats/${slug}/${game.id}`}>
                        {this.getGameName(seat)}
                      </a>
                    </Typography>
                  </TableCell>

                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.titleText}>{renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}</Typography>
                    <Typography className={classes.subTitleText}>Row {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)} </Typography>

                    {seats.isAisleSeat && parseInt(seats.seatsSold) === 0 ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Aisle Seats
                      </Typography>
                    ) : null}

                    {seats.isWheelchair ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Wheelchair
                      </Typography>
                    ) : null}

                    {seats.isObstructedView ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Obstructed View
                      </Typography>
                    ) : null}
                  </TableCell>

                  <TableCell padding="checkbox" className={classes.subTitleText} style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>${seat.price.toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell padding="checkbox" className={classes.subTitleText} style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>{renderSeatNumbers(seat, false, "-", true)}</Typography>
                  </TableCell>
                  <TableCell padding="checkbox" className={classes.subTitleText} style={{ textAlign: "center" }}>
                    <Typography className={classes.subTitleText}>{seat.noOfSeats - seat.seatsSold}</Typography>
                  </TableCell>

                  <TableCell padding="checkbox">
                    <a href={`/seats/${slug}/${game.id}/${seat.seatId}`}>                    
                      <Button 
                        variant="text"
                        color="primary" 
                        size="small" 
                        className={classes.buyButton}
                      >
                        Buy Ticket{" "}
                        <BuyIcon color="primary" className={classes.rightIcon} />
                      </Button>
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    const { seats } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.finePrint}>
          <img src={BuyerTrustSeal} style={{ marginRight: ".5rem" }} />
          <Typography variant="caption" align="center" color="textSecondary">
            <strong>100% Guaranteed</strong>. All Prices CAD.
          </Typography>
        </div>
        {seats.length > 0 ? (
          this.renderTable(seats)
        ) : (
          <Typography
            style={{ margin: "2rem 0" }}
            variant="subheading"
            align="center"
            color="textSecondary"
          >
            No Active Listings
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SellerListingTable);
