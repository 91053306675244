import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import validator from "validator";
import DOMPurify from "dompurify";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import { ReCaptcha } from "react-recaptcha-google";

import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import GradeIcon from '@material-ui/icons/Grade';
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles, FormControl, FormHelperText } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API } from "aws-amplify";

import TextLogo from "../images/checkout-logo-ff.png";
import StubHubLogo from "../images/checkout-logo-stubhub.png";
import TicketMasterLogo from "../images/checkout-logo-ticketmaster.png";
import SeatGeekLogo from "../images/checkout-logo-seatgeek.png";
import { renderZoneName, renderRowName } from "../libs/shared/seat-helpers";
import { getFormattedTime, getTimeAtLabels } from "../libs/shared/grid-helper";
import { checkoutReviewsData } from "./data/checkoutReviews";
import { randomIntFromInterval } from "../utils/randomNumber";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const styles = (theme) => ({
  logo: {
    height: "4rem",
    position: "relative",
    marginBottom: "3rem",
  },
  logoImage: {
    height: "100%",
  },
  form: {
    marginTop: "2rem",
  },
  deleteButton: {
    boxSizing: "border-box",
  },
  textField: {
    padding: ".75rem",
    marginBottom: 0,
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    width: "100%",
  },
  textFieldError: {
    padding: ".75rem",
    marginBottom: 0,
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
  },
  input: {
    padding: 0,
    margin: 0,
    fontSize: "0.875rem",
  },
  cardFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  cardField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    width: "100%",
    marginTop: "1rem",
    marginBottom: ".8px",
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  paperReviews: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: "8px",
    border: "solid 1px rgba(0, 0, 0, 0.54)",
    marginBottom: '12px',
    padding: '8px',
  },
  paperReviewsQuotes: {
    fontFamily: "cursive",
    fontSize: "56px",
    color: "rgba(0, 0, 0, 0.54)",
    padding: '12px',
    lineHeight: '0.5',
  },
  progress: {
    margin: "0 1rem",
  },
  couponContainer: {
    borderRadius: "4px",
    border: "1px solid #e5e5ea",
    marginTop: "1rem",
    padding: "0.75rem",
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    fontSize: 16,
  },
  loginButton: {
    padding: ".75rem",
    marginTop: ".75rem",
    marginBottom: 0,
    borderRadius: "4px",
    height: "1rem",
    width: '40%',
    backgroundColor: "#00a0ff",
    color: "white",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#0098f3",
    },
    textTransform: "none",
  },
});

function ExpiryDateCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="##/##"
      thousandSeparator=""
      prefix=""
    />
  );
}

function CVVCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="####"
      thousandSeparator=""
      prefix=""
    />
  );
}

function CardCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="#### #### #### ####"
      thousandSeparator=""
      prefix=""
    />
  );
}

function MobileNoCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            id: "mobileNo",
          },
        });
      }}
      format="(###) ### ####"
      thousandSeparator=""
      prefix=""
    />
  );
}

function CustomZipCodeInput(props) {
  const { inputRef, value, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]}
      pipe={(conformedValue) => conformedValue.toUpperCase()}
      placeholderChar={"\u2000"}
    />
  );
}

const preventCopyPaste = (event) => {
  event.preventDefault();
};

class CheckoutFormWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seat: props.seat,
      game: props.game,
      noOfSeats: props.seat.noOfSeats,
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      mobileNo: "",
      address: "",
      zipCode: "",
      card: "",
      expiryDate: "",
      cvv: "",
      isProcessing: false,
      isCardComplete: false,
      isValidFirstName: true,
      isValidLastName: true,
      isValidEmail: true,
      isValidConfirmEmail: true,
      isValidMobileNo: true,
      isValidZipCode: true,
      isValidAddress: true,
      isValidCard: true,
      isValidExpiryDate: true,
      isValidCVV: true,
      isLoading: false,
      subTotal: 0,
      stubHubTotal: 0,
      ticketMasterTotal: 0,
      service: 0,
      ticketMasterService: 0,
      fulfillmentFee: 0,
      usdConversion: 0,
      othersTotal: 0,
      checkoutSuccess: false,
      stubHubSubTotal: 0,
      ticketMasterSubTotal: 0,
      gameName: "",
      gameDate: "",
      couponId: "",
      coupon: {},
      isCaptchaPassed: false,
      competitorFees: {
        ff: 0,
        stubhub: 0,
        ticketmaster: 0,
        seatgeek: 0,
      },
      dataReviews: [
        {
          name: "",
          profile_pic: "",
          comments: "",
        },
        {
          name: "",
          profile_pic: "",
          comments: "",
        },
      ],
      error: false,
    };

    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleQtyChange = this.handleQtyChange.bind(this);
    this.handleCustomTextFieldChange = this.handleCustomTextFieldChange.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  sanitizeInput = (input) => {
    return DOMPurify.sanitize(input, {
      USE_PROFILES: { html: false, svg: false, mathMl: false }
    });
  };

  handleEmailChange = (event) => {
    const { confirmEmail } = this.state;
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.validateEmail(event.target.value);
    this.validateEmails(event.target.value, confirmEmail);
  };

  handleConfirmEmailChange = (event) => {
    const { email } = this.state;
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.validateEmail(event.target.value, 'confirm');
    this.validateEmails(email, event.target.value);
  };
  
  validateEmail = (email, field = 'email') => {
    if (!validator.isEmail(email)) {
      if (field === 'email') {
        this.setState({
          isValidEmail: validator.isEmail(email.trim())
        });
      } else {
        this.setState({
          isValidConfirmEmail: validator.isEmail(email.trim())
        });
      }
    }else{
      this.setState({
        isValidEmail: true,
        isValidConfirmEmail: true,
      });
    }
  };

  validateEmails = (email, confirmEmail) => {
    if (email && confirmEmail) {
      this.setState({
        error: email.trim() !== confirmEmail.trim(),
      });
    } else {
      this.setState({
        error: false,
      });
    }
  };

  hideInstaDeliveryText = () => {
    const currentTime = moment().tz("America/Edmonton").format("HH:mm");
    const startHideTime = "00:00"; // 12:00 am
    const endHideTime = "06:00"; // 06:00 am
  
    if (currentTime >= startHideTime && currentTime <= endHideTime) {
      return null;
    } else {
      return "  Insta-Delivery";
    }
  }

  calculateTotal(total, discount) {
    if (!discount) {
      return total;
    } else if (total - discount < 0) {
      return 0;
    } else {
      return total - discount;
    }
  }

  getUserValue(user, key) {
    if (user && key in user && user[key] !== null && user[key] !== undefined) {
      return user[key];
    }
    return "";
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }

    window.fbq("track", "InitiateCheckout");

    const { isAuthenticated, currUser } = this.props;
    const name = isAuthenticated ? this.getUserValue(currUser, "name") : "";
    const names = name.split(" ");
    const firstName = names.shift();
    const lastName = names.join(" ");
    const email = isAuthenticated ? this.getUserValue(currUser, "email") : "";
    const confirmEmail = email;
    let mobileNo = isAuthenticated ? this.getUserValue(currUser, "phone_number") : "";
    if (mobileNo !== "") {
      const parsedPhoneNumber = parsePhoneNumberFromString(currUser.phone_number);
      mobileNo = parsedPhoneNumber.formatNational().replace(/[^\d]+/g, "");
    }

    const { seat, noOfSeats, game } = this.state;

    const subTotal = noOfSeats * seat.price;

    const regularGameDate = `${moment
      .tz(game.date, game.timezone)
      .format("ddd, MMM D YYYY")}${getTimeAtLabels(game)}${getFormattedTime(moment
      .tz(game.date, game.timezone)
      .format("h:mm A"), game)}`;

    const gameDate = game.isTbd ? "TBD" : regularGameDate;
    const competitorFees = this.getCompetitorFees(seat, noOfSeats);
    const randomArray = randomIntFromInterval(checkoutReviewsData,2);
    this.setState({
      noOfSeats,
      subTotal,
      gameName: game.longName,
      gameDate,
      competitorFees,
      dataReviews: randomArray,
      firstName,
      lastName,
      email,
      confirmEmail,
      mobileNo
    });
  }

  getCompetitorFees(seat, noOfSeats) {
    const { game } = this.state;
    const { tier, price } = seat;
    // case when tier is 1, then: 0.22 * Math.max(price, 22) * noOfSeats
    // case when tier is 2, then: 0.22 * Math.max(price, 44) * noOfSeats
    // case when tier is 3, then: 0.22 * Math.max(price, 142) * noOfSeats
    // Hey copilot, get me started on the case statement from the comment above.
    // I'll be happy to help you out.
    const competitorFees = {
      fansfirst: 0,
      stubhub: price * noOfSeats * 0.37,
      seatgeek: price * noOfSeats * 0.35,
      ticketmaster: null,
    };
    if (game.homeTeamSlug === "toronto-blue-jays") {
      competitorFees.ticketmaster = price * noOfSeats * 0.22;
    } else {
      switch (tier) {
        case "3": {
          competitorFees.ticketmaster = 0.22 * Math.max(price, 25) * noOfSeats;
          break;
        }
        case "2": {
          competitorFees.ticketmaster = 0.22 * Math.max(price, 44) * noOfSeats;
          break;
        }
        case "1": {
          competitorFees.ticketmaster = 0.22 * Math.max(price, 142) * noOfSeats;
          break;
        }
        default: {
          competitorFees.ticketmaster = 0.22 * Math.max(price, 44) * noOfSeats;
          break;
        }
      }
    }
    return competitorFees;
  }
  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  verifyCallback(recaptchaToken) {
    this.setState({
      isCaptchaPassed: true,
    });
  }

  ticketMasterSubTotal(subTotal, qty, { zone, price }) {
    switch (zone) {
      case "Press Level": {
        return price > 25 ? subTotal : 25 * qty;
      }
      case "Second Level": {
        return price > 44 ? subTotal : 44 * qty;
      }
      case "Lower Bowl": {
        return price > 142 ? subTotal : 142 * qty;
      }
      default: {
        return price * qty;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }

    if (nextProps.checkoutSuccess !== this.props.checkoutSuccess) {
      this.setState({ checkoutSuccess: nextProps.checkoutSuccess });
    }
  }

  handleCardFieldChange = (event) => {
    this.setState({
      isCardComplete: event.complete,
      isValidCard: event.complete,
    });
  };

  isValidName = (name) => {
    const isValidName = name.trim() !== "" && /^[a-zA-Z\s]+$/.test(name);
    return isValidName;
  };

  isValidMobileNo = (number) => {
    const re =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return re.test(number);
  };

  isValidZipCode = (zipCode) => {
    const re = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/gi;
    return re.test(zipCode);
  };

  isValidAddress = (address) => {
    return address !== "";
  };

  isValid() {
    const {
      email,
      confirmEmail,
      firstName,
      lastName,
      mobileNo,
      card,
      expiryDate,
      cvv,
      address,
      zipCode,
    } = this.state;
    const isValidFirstName = this.isValidName(firstName);
    const isValidLastName = this.isValidName(lastName);
    const isValidEmail = validator.isEmail(email.trim());
    const isValidConfirmEmail = validator.isEmail(confirmEmail.trim());
    const isValidMobileNo = this.isValidMobileNo(mobileNo);
    const isValidCard = validator.isCreditCard(card);
    const isValidExpiryDate = RegExp(
      /(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])/
    ).test(expiryDate);
    const isValidCVV = RegExp(/^[0-9]{3,4}$/).test(cvv);
    const isValidZipCode = this.isValidZipCode(zipCode);
    // const isValidAddress = this.isValidAddress(address);

    this.setState({
      isValidEmail,
      isValidConfirmEmail,
      isValidFirstName,
      isValidLastName,
      isValidMobileNo,
      isValidCard,
      isValidExpiryDate,
      isValidCVV,
      isValidZipCode,
      // isValidAddress,
    });

    return (
      isValidFirstName &&
      isValidLastName &&
      isValidEmail &&
      isValidConfirmEmail &&
      isValidMobileNo &&
      ((isValidCard && isValidExpiryDate && isValidCVV) ||
        this.calculateTotal(
          parseFloat(this.state.subTotal),
          parseFloat(this.state.coupon.value)
        ) === 0) &&
      isValidZipCode
      // isValidAddress
    );
  }
  async applyCoupon(couponId) {
    couponId = couponId.trim();
    try {
      const coupon = await API.get("v2", `marketplace/coupons/${couponId}`);
      if (coupon.isActive) {
        this.props.onCouponApplied(coupon);
        this.setState({ coupon });
      } else {
        alert("Sorry, the coupon you entered is no longer valid.");
      }
    } catch (error) {
      this.setState({ coupon: {} });
      alert("Sorry, we couldn't find the coupon code you entered.");
    }
  }

  async removeCoupon() {
    this.setState({
      couponId: "",
      coupon: {},
    });
  }

  handleSubmitClick = async (event) => {
    event.preventDefault();

    this.setState({
      isProcessing: true,
    });

    const {
      firstName,
      lastName,
      email,
      mobileNo,
      subTotal,
      noOfSeats,
      card,
      cvv,
      expiryDate,
      address,
      zipCode,
    } = this.state;

    const sanitizedFirstName = this.sanitizeInput(firstName);
    const sanitizedLastName = this.sanitizeInput(lastName);

    if (!this.isValid()) {
      this.setState({
        isProcessing: false,
      });
      return false;
    }

    this.props.onSubmit({
      firstName: sanitizedFirstName.trim(),
      lastName: sanitizedLastName.trim(),
      email: email.toLowerCase().trim(),
      mobileNo: mobileNo.trim(),
      subTotal,
      noOfSeats,
      card,
      cvv,
      expiryDate,
      address,
      zipCode,
    });

    this.setState({
      isProcessing: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleQtyChange = (name) => (event) => {
    const { seat } = this.state;
    const qty = event.target.value;
    const subTotal = seat.price * qty;

    const competitorFees = this.getCompetitorFees(seat, qty);
    this.setState({
      [name]: qty,
      subTotal,
      competitorFees,
    });
  };

  quantityMenus = () => {
    const { noOfSeats, quantityOptions } = this.state.seat;
    const teSeatSplit = localStorage.getItem('teSeatSplit');
    const finalQuantityOptions = teSeatSplit
      ? JSON.parse(teSeatSplit)
      : quantityOptions;
    // loop through quantityOptions array and create a menu item for each
    return finalQuantityOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option} {noOfSeats > 1 ? "Tickets" : "Ticket"}
      </MenuItem>
    ));
  };

  renderCheckoutBtn() {
    const { classes } = this.props;
    const { checkoutSuccess } = this.state;

    if (checkoutSuccess) {
      return (
        <Button
          disableRipple={true}
          fullWidth
          variant="contained"
          onClick={this.handleSubmitClick}
          disabled={true}
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            padding: "1rem",
            backgroundColor: "#4caf50",
            color: "white",
          }}
        >
          Checkout Successful. Please Wait While We Redirect You.
        </Button>
      );
    } else {
      return (
        <React.Fragment>
          <Button
            disableRipple={true}
            fullWidth
            variant="contained"
            onClick={this.handleSubmitClick}
            disabled={
              this.state.isProcessing ||
              this.state.isLoading ||
              !this.state.isCaptchaPassed
            }
            style={{
              marginTop: "2rem",
              marginBottom: "1rem",
              padding: "1rem",
            }}
            color="primary"
          >
            {this.state.isProcessing || this.state.isLoading ? (
              <React.Fragment>
                <CircularProgress className={classes.progress} size={14} />
                Submitting Payment...
              </React.Fragment>
            ) : (
              `Pay Now $${this.calculateTotal(
                this.state.subTotal,
                parseFloat(this.state.coupon.value)
              ).toFixed(2)} CAD`
            )}
          </Button>

          <Typography
            variant="caption"
            align="center"
            style={{ fontSize: "12px" }}
          >
            By proceeding with this transaction you agree to{" "}
            <Link to="/legal" style={{ color: "#2196f3" }} target="_blank">
              FansFirst Terms and Conditions
            </Link>
          </Typography>
        </React.Fragment>
      );
    }
  }

  handleCustomTextFieldChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderCheckoutForm() {
    const { classes, isAuthenticated, handleLoginForm } = this.props;

    return (
      <React.Fragment>
        <Typography
          variant="headline"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Checkout
        </Typography>

        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={this.handleSubmitClick}
        >
          <Typography variant="subheading" style={{ marginTop: "1.5rem" }}>
            Number of Seats
          </Typography>
          <Typography variant="caption" style={{ marginBottom: ".5rem" }}>
            Your seat(s) will be together
          </Typography>
          <Grid  container spacing={16}>
            <Grid item md={6}>
              <Select
                disabled={this.state.isProcessing || this.props.isLoading}
                disableUnderline={true}
                value={this.state.noOfSeats}
                onChange={this.handleQtyChange("noOfSeats")}
                inputProps={{
                  name: "noOfSeats",
                  id: "noOfSeats",
                  style: {},
                }}
                SelectDisplayProps={{
                  className: classes.textField,
                  style: {
                    width: "100%",
                    cursor: "pointer",
                  },
                }}
                style={{
                  width: "100%",
                  borderBottom: "none",
                  fontSize: "0.875rem",
                  marginTop: ".5rem",
                  marginBottom: ".25rem",
                }}
              >
                {this.quantityMenus()}
              </Select>
            </Grid>
            <Grid item md={6}>
              {!isAuthenticated && (
                <FormControl
                  error={false}
                  fullWidth={true}
                  style={{ verticalAlign: "bottom" }}
                >
                  <Button
                    className={classes.loginButton} 
                    onClick={handleLoginForm}
                  >
                    <Typography style={{ color: "white" }}>Log In</Typography>
                  </Button>{" "}
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Typography variant="subheading" style={{ marginTop: "1.5rem" }}>
            Customer Information
          </Typography>
          <Grid container spacing={16}>
            <Grid item md={6}>
              <FormControl
                error={!this.state.isValidFirstName}
                fullWidth={true}
              >
                <TextField
                  id="firstName"
                  placeholder="First Name"
                  className={
                    this.state.isValidFirstName
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
                {!this.state.isValidFirstName ? (
                  <FormHelperText>Enter your first name</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl error={!this.state.isValidLastName} fullWidth={true}>
                <TextField
                  id="lastName"
                  placeholder="Last Name"
                  className={
                    this.state.isValidLastName
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
                {!this.state.isValidLastName ? (
                  <FormHelperText>Enter your last name</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="subheading" style={{ marginTop: "1.5rem" }}>
            Contact Information
          </Typography>
          <Grid container spacing={16}>
            <Grid item md={6}>
              <FormControl error={!this.state.isValidEmail} fullWidth={true} onCopy={preventCopyPaste} onPaste={preventCopyPaste}>
                <TextField
                  id="email"
                  placeholder="Email Address"
                  className={
                    this.state.isValidEmail
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                    endAdornment: this.state.email && (
                      <InputAdornment position="end">
                        {!this.state.isValidEmail ? (
                          <CloseIcon color="error" className={classes.icon} />
                        ):(
                          <CheckIcon color="success" className={classes.icon} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {!this.state.isValidEmail ? (
                  <FormHelperText>Enter valid email</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl error={!this.state.isValidConfirmEmail || this.state.error} fullWidth={true} onCopy={preventCopyPaste} onPaste={preventCopyPaste}>
                <TextField
                  id="confirmEmail"
                  placeholder="Retype Email Address"
                  className={
                    this.state.isValidConfirmEmail
                      ? this.state.error 
                      ? classes.textFieldError : classes.textField 
                      : classes.textFieldError
                  }
                  value={this.state.confirmEmail}
                  onChange={this.handleConfirmEmailChange}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                    endAdornment: this.state.confirmEmail && (
                      <InputAdornment position="end">
                        {!this.state.isValidConfirmEmail ? (
                          <CloseIcon color="error" className={classes.icon} />
                        ) : this.state.error ? (
                          <CloseIcon color="error" className={classes.icon} />
                        ):(
                          <CheckIcon color="success" className={classes.icon} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {!this.state.isValidConfirmEmail ? (
                  <FormHelperText>Enter valid email</FormHelperText>
                ) : this.state.error ? (
                  <FormHelperText>Email addresses do not match</FormHelperText>
                ): null}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl error={!this.state.isValidMobileNo} fullWidth={true}>
                <TextField
                  id="mobileNo"
                  placeholder="Mobile Number"
                  fullWidth={true}
                  className={
                    this.state.isValidMobileNo
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.mobileNo}
                  onChange={this.handleChange}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                    startAdornment: (
                      <span
                        style={{ fontSize: ".875rem", marginRight: ".5rem" }}
                      >
                        +1
                      </span>
                      // <InputAdornment  variant="filled" position="start">
                      //   +1
                      // </InputAdornment>
                    ),
                    inputComponent: MobileNoCustomInput,
                  }}
                />
                {!this.state.isValidMobileNo ? (
                  <FormHelperText>Enter valid mobile number</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            {/* <Grid item md={12}>
              <FormControl error={!this.state.isValidAddress} fullWidth={true}>
                <TextField
                  multiline={true}
                  rows={3}
                  rowsMax={3}
                  id="address"
                  placeholder="Address"
                  className={
                    this.state.isValidAddress
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.address}
                  onChange={this.handleChange}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                    style: { padding: 0 },
                  }}
                />
                {!this.state.isValidAddress ? (
                  <FormHelperText>Enter your address</FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
          </Grid>
          <Typography
            variant="subheading"
            style={{ margin: "1.5rem 0 .25rem" }}
          >
            Card Information
          </Typography>
          <Typography
            variant="caption"
            style={{ marginBottom: ".5rem" }}
            color="primary"
          >
            All payments are secured and encrypted via SSL / TLS technology
          </Typography>
          <FormControl error={!this.state.isValidMobileNo} fullWidth={true}>
            <TextField
              id="card"
              placeholder="Card Number"
              fullWidth={true}
              style={{ marginBottom: "8px" }}
              className={
                this.state.isValidCard ||
                this.calculateTotal(
                  parseFloat(this.state.subTotal),
                  parseFloat(this.state.coupon.value)
                ) === 0
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.card}
              onChange={this.handleCustomTextFieldChange("card")}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                inputComponent: CardCustomInput,
              }}
            />
            {!this.state.isValidCard &&
            this.calculateTotal(
              parseFloat(this.state.subTotal),
              parseFloat(this.state.coupon.value)
            ) > 0 ? (
              <FormHelperText>Enter valid card number</FormHelperText>
            ) : null}
          </FormControl>
          <Grid container spacing={16}>
            <Grid item md={6}>
              <FormControl
                error={
                  !this.state.isValidExpiryDate &&
                  this.calculateTotal(
                    parseFloat(this.state.subTotal),
                    parseFloat(this.state.coupon.value)
                  ) > 0
                }
                fullWidth={true}
              >
                <TextField
                  id="expiryDate"
                  placeholder="Expiry Date (MM/YY)"
                  className={
                    this.state.isValidExpiryDate ||
                    this.calculateTotal(
                      parseFloat(this.state.subTotal),
                      parseFloat(this.state.coupon.value)
                    ) === 0
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.expiryDate}
                  onChange={this.handleCustomTextFieldChange("expiryDate")}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                    inputComponent: ExpiryDateCustomInput,
                  }}
                />
                {!this.state.isValidExpiryDate &&
                this.calculateTotal(
                  parseFloat(this.state.subTotal),
                  parseFloat(this.state.coupon.value)
                ) > 0 ? (
                  <FormHelperText>
                    Enter your card's expiry date (i.e. 01/12)
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl
                error={
                  !this.state.isValidCVV &&
                  this.calculateTotal(
                    parseFloat(this.state.subTotal),
                    parseFloat(this.state.coupon.value)
                  ) > 0
                }
                fullWidth={true}
              >
                <TextField
                  id="cvv"
                  placeholder="Card Security No."
                  className={
                    this.state.isValidCVV ||
                    this.calculateTotal(
                      parseFloat(this.state.subTotal),
                      parseFloat(this.state.coupon.value)
                    ) === 0
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.cvv}
                  onChange={this.handleCustomTextFieldChange("cvv")}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                    inputComponent: CVVCustomInput,
                  }}
                />
                {!this.state.isValidCVV &&
                this.calculateTotal(
                  parseFloat(this.state.subTotal),
                  parseFloat(this.state.coupon.value)
                ) > 0 ? (
                  <FormHelperText>Enter your 3 or 4 digit CVV</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl error={!this.state.isValidZipCode} fullWidth={true}>
                <Input
                  id="zipCode"
                  placeholder="Postal Code"
                  fullWidth={true}
                  className={
                    this.state.isValidZipCode
                      ? classes.textField
                      : classes.textFieldError
                  }
                  value={this.state.zipCode}
                  margin="dense"
                  disabled={this.state.isProcessing || this.props.isLoading}
                  disableUnderline
                  classes={{
                    input: classes.input,
                  }}
                  onChange={(e) =>
                    this.setState({
                      [e.target.id]: e.target.value.toUpperCase(),
                    })
                  }
                  inputComponent={CustomZipCodeInput}
                />

                {!this.state.isValidZipCode ? (
                  <FormHelperText>Enter valid postal code</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
          <Typography
            variant="subheading"
            style={{ margin: "1.5rem 0 .25rem" }}
          >
            Coupon Information
          </Typography>
          {this.state.coupon.value
            ? this.renderCouponCard()
            : this.renderCouponInput()}
        </form>
      </React.Fragment>
    );
  }

  renderCouponCard() {
    const { classes } = this.props;
    return (
      <div className={classes.couponContainer}>
        {/* <Typography
          variant="caption"
          style={{ marginBottom: ".5rem" }}
          color="primary"
        >
          {this.state.coupon.value &&
          this.state.coupon.value - this.state.coupon.valueRedeemed >
            this.state.subTotal
            ? `$${this.state.subTotal.toFixed(
                2
              )}/$${this.state.coupon.value.toFixed(
                2
              )} coupon applied! - $${(
                this.state.coupon.value -
                this.state.coupon.valueRedeemed -
                this.state.subTotal
              ).toFixed(2)} remaining`
            : `$${this.state.coupon.value.toFixed(2)} coupon applied!`}
        </Typography> */}
        <div className={classes.couponTextContainer}>
          <Typography variant="body1" style={{ marginBottom: "0.5rem" }}>
            Coupon Applied!{" "}
          </Typography>
          <Typography variant="caption">
            {this.state.coupon.value &&
            this.state.coupon.value - this.state.coupon.valueRedeemed >
              this.state.subTotal
              ? `$${this.state.subTotal.toFixed(2)} redeemed - $${(
                  this.state.coupon.value -
                  this.state.coupon.valueRedeemed -
                  this.state.subTotal
                ).toFixed(2)} remaining on coupon`
              : `$${
                  this.state.coupon.value.toFixed(2) -
                  this.state.coupon.valueRedeemed.toFixed(2)
                } coupon applied!`}
          </Typography>
          <Typography
            variant="caption"
            style={{ color: "rgba(0, 0, 0, 0.54)", display: "inline" }}
          >
            New Total:{" "}
          </Typography>
          <Typography
            variant="caption"
            style={{
              color: "#f44336",
              textDecoration: "line-through",
              display: "inline",
            }}
          >
            ${this.state.subTotal.toFixed(2)}
          </Typography>{" "}
          <Typography
            variant="caption"
            style={{ color: "green", display: "inline" }}
          >
            $
            {this.calculateTotal(
              this.state.subTotal,
              parseFloat(this.state.coupon.value)
            ).toFixed(2)}
          </Typography>
          <Typography
            variant="caption"
            color="primary"
            style={{ marginTop: "0.5rem" }}
          >
            {this.state.coupon.id}
          </Typography>
        </div>
        <IconButton
          onClick={() => this.removeCoupon()}
          variant="text"
          color="default"
          size="small"
          className={classes.deleteButton}
        >
          <DeleteIcon color="error"></DeleteIcon>
        </IconButton>
      </div>
    );
  }

  renderCouponInput() {
    const { classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item md={6}>
          <FormControl error={false} fullWidth={true}>
            <TextField
              id="couponId"
              placeholder="Coupon Code"
              className={classes.textField}
              value={this.state.couponId}
              onChange={this.handleChange}
              margin="dense"
              disabled={this.state.isProcessing || this.props.isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl
            error={false}
            fullWidth={false}
            style={{ verticalAlign: "bottom" }}
          >
            <Button
              onClick={() => this.applyCoupon(this.state.couponId)}
              style={{
                padding: ".75rem",
                marginTop: ".75rem",
                marginBottom: 0,
                borderRadius: "4px",
                backgroundColor: "#00de94",
                border: "solid 1px #e5e5ea",
                height: "1rem",
              }}
            >
              <Typography style={{ color: "white" }}>Apply</Typography>
            </Button>{" "}
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  renderSeatDetails() {
    const { classes } = this.props;
    const { seat, gameName, gameDate, game } = this.state;
    const zoneLabel = renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial);
    const rowLabel = renderRowName(seat.zone, seat.row, game.homeTeamSlug, game.isSpecial);

    return (
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="caption"
          style={{
            textTransform: "uppercase",
            marginBottom: "1.5rem",
          }}
        >
          Seat Details
        </Typography>
        <Typography variant="subheading" style={{ fontWeight: "bold" }}>
          {gameName}
        </Typography>
        <Typography variant="body1">
          {game.isSpecial && (
            <React.Fragment>{game.venue}</React.Fragment>
          )}
          {gameDate}
        </Typography>
        <Typography variant="body1">
          ${seat.price.toFixed(2)} CAD/Ticket
        </Typography>
        <Typography variant="body1">
          {zoneLabel} • Row {rowLabel}
          {"   "}
          <Typography
            style={{
              display: "inline-block",
              color: "rgb(33, 150, 243)",
              fontStyle: "oblique",
            }}
            variant="caption"
          >
            {seat.isAisleSeat && parseInt(seat.seatsSold) === 0
              ? "  Aisle Seats"
              : null}
          </Typography>
          {seat.isAisleSeat &&
          parseInt(seat.seatsSold) === 0 &&
          seat.isInstantDelivery ? (
            <Typography
              style={{
                display: "inline-block",
                color: "#e03a3e",
                fontStyle: "oblique",
              }}
              variant="caption"
            >
              &nbsp;•&nbsp;
            </Typography>
          ) : null}
          <Typography
            style={{
              display: "inline-block",
              color: "#e03a3e",
              fontStyle: "oblique",
            }}
            variant="caption"
          >
            {seat.isInstantDelivery ? this.hideInstaDeliveryText() : null}
          </Typography>
          <Typography
          style={{
            display: "inline-block",
            color: "rgb(33, 150, 243)",
            fontStyle: "oblique",
          }}
          variant="caption"
        >
          {seat.isWheelchair ? "Wheelchair?" : null}
        </Typography>
        </Typography>
        <Typography
          style={{
            display: "inline-block",
            color: "rgb(33, 150, 243)",
            fontStyle: "oblique",
          }}
          variant="caption"
        >
          {seat.isObstructedView ? "Obstructed View" : null}
        </Typography>
        <Typography
          style={{
            display: "inline-block",
            color: "rgb(33, 150, 243)",
            fontStyle: "oblique",
          }}
          variant="caption"
        >
          {seat.isObstructedView ? "Obstructed View" : null}
        </Typography>
        <Typography
          style={{
            display: "inline-block",
            color: "rgb(33, 150, 243)",
            fontStyle: "oblique",
          }}
          variant="caption"
        >
          {seat.isObstructedView ? "Obstructed View" : null}
        </Typography>
      </Paper>
    );
  }

  priceComparison() {
    const { competitorFees, game } = this.state;
    const subTotal = this.calculateTotal(
      this.state.subTotal,
      this.state.coupon.value
    );
    return (
      <Grid container spacing={8}>
        <Grid item xs={3} />
        <Grid
          item
          xs={3}
          style={{ borderBottom: "1px solid #e1e1e1", marginBottom: ".5rem" }}
        >
          <Typography variant="body2" color="secondary" align="right">
            <img src={TextLogo} alt="FansFirst" width="100%" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ borderBottom: "1px solid #e1e1e1", marginBottom: ".5rem" }}
        >
          <Typography variant="body2" color="secondary" align="right">
            <img src={StubHubLogo} alt="StubHub" width="100%" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ borderBottom: "1px solid #e1e1e1", marginBottom: ".5rem" }}
        >
          {game.homeTeamSlug === "toronto-blue-jays" ? (
            <Typography variant="body2" color="secondary" align="center">
              <img src={SeatGeekLogo} alt="SeatGeek" width="100%" />
            </Typography>
          ) : (
            <Typography variant="body2" color="secondary" align="right">
              <img src={TicketMasterLogo} alt="TicketMaster" width="100%" />
            </Typography>
          )}
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            Buyer Fee
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            align="right"
          >
            -
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "14px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            ${competitorFees.stubhub.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            $
            {game.homeTeamSlug === "toronto-blue-jays"
              ? competitorFees.seatgeek.toFixed(2)
              : competitorFees.ticketmaster.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2">Total</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="body2"
            align="right"
            style={{
              color: "#2196f3",
              paddingRight: "16px",
            }}
          >
            ${subTotal.toFixed(2)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            style={{ paddingRight: "14px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            ${(subTotal + competitorFees.stubhub).toFixed(2)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            $
            {game.homeTeamSlug === "toronto-blue-jays"
              ? (Number(subTotal) + Number(competitorFees.seatgeek)).toFixed(2)
              : (
                  Number(subTotal) + Number(competitorFees.ticketmaster)
                ).toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={3} />
        <Grid item xs={3}>
          <Typography variant="body2">You saved:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "14px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            ${competitorFees.stubhub.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            $
            {game.homeTeamSlug === "toronto-blue-jays"
              ? competitorFees.seatgeek.toFixed(2)
              : competitorFees.ticketmaster.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12} style={{ marginTop: ".5rem" }}>
          <Typography
            style={{ fontSize: "10px" }}
            variant="caption"
            align="right"
          >
            * Minimum ticket prices and fees for secondary market resale tickets
            on competing sites. Competitior prices are calculations based on
            respective fee structures currently in place.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderOrderSummary() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="caption"
          style={{
            textTransform: "uppercase",
            marginBottom: ".5rem",
          }}
        >
          Order Summary
        </Typography>

        {this.priceComparison()}
      </Paper>
    );
  }

  renderTicketInstructions() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="caption"
          style={{
            textTransform: "uppercase",
            marginBottom: ".5rem",
          }}
        >
          Important Instructions
        </Typography>

        <Typography variant="body1">
          We guarantee all digitally scanned tickets. Printed copies do not fall
          under the guarantee. We encourage you to <strong>NOT</strong> print
          your tickets.
        </Typography>
      </Paper>
    );
  }
  
  _renderReviews() {
    const { classes } = this.props;
    const { dataReviews } = this.state;
    const currentReview1 = dataReviews[0];
    const currentReview2 = dataReviews[1];

    return (
      <Paper className={classes.paper} elevation={1}>

      <Typography
          variant="caption"
          style={{
            textTransform: "uppercase",
            marginBottom: ".5rem",
          }}
        >
          REVIEWS
        </Typography>
        <div className={classes.paperReviews}>
          <Grid>
            <Typography
              variant="caption"
              style={{
                textTransform: "capitalize",
                marginBottom: ".5rem",
              }}
            >
              <GradeIcon style={{color: "rgb(34, 150, 243)", fontSize: "32px", marginBottom: "-12px"}} />
              <b>5.0 &nbsp;</b>
              {currentReview1.name}
            </Typography>

            <Typography variant="body1">{currentReview1.comments}</Typography>
          </Grid>
        </div>

        {currentReview2 !== currentReview1 && <div className={classes.paperReviews}>
          <Grid>
            <Typography
              variant="caption"
              style={{
                textTransform: "capitalize",
                marginBottom: ".5rem",
              }}
            >
              <GradeIcon style={{color: "rgb(34, 150, 243)", fontSize: "32px", marginBottom: "-12px"}} />
              <b>5.0 &nbsp;</b>
              {currentReview2.name}
            </Typography>

            <Typography variant="body1">{currentReview2.comments}</Typography>
          </Grid>
        </div>}
      </Paper>
    );
  }

  render() {
    const { game, seat } = this.state;

    return (
      game &&
      seat && (
        <div>
          <Grid container spacing={32} style={{ paddingTop: "2rem" }}>
            <Grid item md={2} xl={3} />
            <Grid item md={5} xl={4} style={{ position: "relative" }}>
              {this.renderCheckoutForm()}
              <div style={{ marginTop: "2rem" }}>
                <ReCaptcha
                  ref={(el) => {
                    this.captchaDemo = el;
                  }}
                  size="normal"
                  render="explicit"
                  sitekey="6LfzJ94UAAAAAFjlPyI3OhRU7YQehfJpP0nOk2VW"
                  onloadCallback={this.onLoadRecaptcha}
                  verifyCallback={this.verifyCallback}
                  data-badge="bottomleft"
                />
              </div>
              {this.renderCheckoutBtn()}
            </Grid>

            <Grid item md={4} xl={3} style={{ padding: "0 2rem" }}>
              {this.renderSeatDetails()}
              {this.renderOrderSummary()}
              {this.renderTicketInstructions()}
              {this._renderReviews()}
            </Grid>
          </Grid>
        </div>
      )
    );
  }
}

CheckoutFormWeb.propTypes = {
  seat: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

// CheckoutFormWeb = injectStripe(CheckoutFormWeb);
export default withStyles(styles)(CheckoutFormWeb);
