import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import { Chip, List, ListItem, Grid, Typography } from "@material-ui/core";

import {renderSeatNumbers, renderZoneName, renderRowName} from '../libs/shared/seat-helpers';

import TicketIcon from "../images/tickets.svg";
import TEAMS from "../constants/Teams";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  MuiListItem: {
    borderBottomColor: "#eceff1",
    cursor: "pointer",
  },
});

class SeatsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedZone: props.zone,
      seats: props.seats,
      isLoading: true,
      game: props.game,
      isAisleSeat: props.isAisleSeat,
      isWheelchair: props.isWheelchair,
      isInstantDelivery: props.isInstantDelivery
    };
  }

  componentDidMount() {
    const { zone, seats, isLoading, isAisleSeat, isWheelchair, isInstantDelivery } = this.props;

    this.setState({
      selectedZone: zone,
      seats,
      isLoading,
      isAisleSeat: isAisleSeat,
      isWheelchair: isWheelchair,
      isInstantDelivery: isInstantDelivery
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.zone !== this.props.zone) {
      this.setState({ selectedZone: nextProps.zone });
    }

    if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }
    
    if (nextProps.isAisleSeat !== this.props.isAisleSeat) {
      this.setState({ isAisleSeat: nextProps.isAisleSeat });
    }

    if (nextProps.isWheelchair !== this.props.isWheelchair) {
      this.setState({ isWheelchair: nextProps.isWheelchair });
    }

    if (nextProps.isInstantDelivery !== this.props.isInstantDelivery) {
      this.setState({ isInstantDelivery: nextProps.isInstantDelivery });
    }
  }

  handleZoneClick = (selectedZone) => {
    const zone = this.state.selectedZone === selectedZone ? 0 : selectedZone;
    this.setState({
      selectedZone: zone,
    });

    this.props.zoneFilter(zone);
  };

  handleAisleSeatClick = (selectedAisleSeat) => {
    const isAisleSeat = this.state.isAisleSeat === selectedAisleSeat ? 0 : selectedAisleSeat;

    this.setState({
      isAisleSeat: isAisleSeat,
    });

    this.props.aisleSeatFilter(isAisleSeat);
  };
  
  handleWheelchairClick = (selectedWheelchair) => {
    const isWheelchair = this.state.isWheelchair === selectedWheelchair ? 0 : selectedWheelchair;

    this.setState({
      isWheelchair: isWheelchair,
    });
    
    this.props.wheelchairFilter(isWheelchair);
  };

  handleInstantDeliveryClick = (selectedInstantDelivery) => {
    const isInstantDelivery = this.state.isInstantDelivery === selectedInstantDelivery ? 0 : selectedInstantDelivery;

    this.setState({
      isInstantDelivery: isInstantDelivery,
    });
    
    this.props.instantDeliveryFilter(isInstantDelivery);
  };

  handleSeatSelection = (selectedSeat) => {
    this.props.onSeatSelection(selectedSeat);
  };

  renderZoneFilterBtn(teamSlug, isSpecialGame) {
    if (isSpecialGame) {
      switch (teamSlug) {
        case "vancouver-whitecaps-fc": {
            return ["Upper Zone", "Club Seats", "Lower Zone"];
        }
        case "bc-lions": {
          return ["", "Other", "Lower Zone"];
        }
        case "vancouver-canucks": {
          return ["-", "-", "Lower Zone"];
        }
        case "toronto-marlies": {
          return ["Upper Zone", "Lower Zone", "Suites"];
        }
      }
    } else {
      const team = TEAMS.find(team => team.homeTeamSlug === teamSlug);
      if (team === undefined) {
        return ["", "", ""];
      }
      return team.zones;
    }
  }

  renderZones = () => {
    const { classes } = this.props;
    const { selectedZone, game } = this.state;
    const { homeTeamSlug } = game;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {this.renderZoneFilterBtn(homeTeamSlug, game.isSpecial)[0] && (
          <Chip
            label={this.renderZoneFilterBtn(homeTeamSlug, game.isSpecial)[0]}
            onClick={() => this.handleZoneClick(1)}
            color={selectedZone === 1 ? "secondary" : "default"}
            className={classes.chip}
          />
        )}
        {this.renderZoneFilterBtn(homeTeamSlug, game.isSpecial)[1] && (
          <Chip
            label={this.renderZoneFilterBtn(homeTeamSlug, game.isSpecial)[1]}
            onClick={() => this.handleZoneClick(2)}
            color={selectedZone === 2 ? "secondary" : "default"}
            className={classes.chip}
          />
        )}
        {this.renderZoneFilterBtn(homeTeamSlug, game.isSpecial)[2] && (
          <Chip
            label={this.renderZoneFilterBtn(homeTeamSlug, game.isSpecial)[2]}
            onClick={() => this.handleZoneClick(3)}
            color={selectedZone === 3 ? "secondary" : "default"}
            className={classes.chip}
          />
        )}
      </div>
    );
  };

  renderFilters = () => {
    const { isAisleSeat, isWheelchair, isInstantDelivery } = this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Chip
          label="Instant Delivery"
          style={{ boxShadow: "none", marginTop: "20px", width: "30%" }}
          onClick={() => this.handleInstantDeliveryClick(1)}
          color={isInstantDelivery === 1 ? "secondary" : "default"}
        >
        </Chip>
        <Chip
          label="Aisle Seat"  
          style={{ boxShadow: "none", marginTop: "20px", width: "30%" }}
          onClick={() => this.handleAisleSeatClick(1)}
          color={isAisleSeat === 1 ? "secondary" : "default"}
        >
        </Chip>
        <Chip
          label="Wheelchair"
          style={{ boxShadow: "none", marginTop: "20px", width: "30%" }}
          onClick={() => this.handleWheelchairClick(1)}
          color={isWheelchair === 1 ? "secondary" : "default"}
        >
        </Chip>
      </div>
      
    );
  };

  renderAisleSeat(seat) {
    if (seat.isAisleSeat && parseInt(seat.seatsSold) === 0) {
      return(
        <Typography
          style={{
            color: "rgb(33, 150, 243)",
            fontStyle: "oblique",
          }}
          variant="caption"
        >
          Aisle Seats
        </Typography>
      )
    } else {
      return null;
    }
  }
  
  renderObstructedView(seat) {
    if (seat.isObstructedView) {
      return(
        <Typography
          style={{
            color: "rgb(33, 150, 243)",
            fontStyle: "oblique",
          }}
          variant="caption"
        >
          Obstructed View
        </Typography>
      )
    } else {
      return null;
    }
  }

  renderInstantDelivery(seat) {
    const currentTime = moment().tz("America/Edmonton").format("HH:mm");
    const startHideTime = "00:00"; // 12:00 am
    const endHideTime = "06:00"; // 06:00 am
  
    if (seat.isInstantDelivery) {
      if (currentTime >= startHideTime && currentTime <= endHideTime) {
        return null;
      } else {
        return (
          <Typography
            style={{
              color: "#e03a3e",
              fontStyle: "oblique",
            }}
            variant="caption"
          >
            Instant Delivery
          </Typography>
        );
      }
    } else {
      return null;
    }
  }

  renderWheelchair(seat) {
    if (seat.isWheelchair) {
      return(
        <Typography
          style={{
            color: "rgb(33, 150, 243)",
            fontStyle: "oblique",
          }}
          variant="caption"
        >
          Wheelchair
        </Typography>
      )
    } else {
      return null;
    }
  }

  // this function turns ticket quantity into "2 Tickets" or "1 Ticket" etc.
  renderAvailableQty(seat) {
    if (seat.sellerEmail === "te@fansfirst.ca") {
      const startNumber = seat.teSeatSplit[0];
      const maxNumber = seat.teSeatSplit[seat.teSeatSplit.length - 1];
      return `${startNumber}${seat.teSeatSplit.length > 1 ? " - " + maxNumber : ""} Tickets`;
    }
    else if (seat.sellOption === 1) { //sell any, even if it leaves a single remaining
      if(seat.noOfSeats > 1) {
        var firstItem = seat.quantityOptions[0];
        var lastItem = seat.quantityOptions[seat.quantityOptions.length-1];
        return `${firstItem} - ${lastItem} Tickets`;
      } else {
        return "1 Ticket";
      }
    } else if(seat.sellOption === 2){ //sell any, but don't leave a single ticket
      if (seat.quantityOptions.length === 1) {
        var firstItem = seat.quantityOptions[0];
        return `${firstItem} Ticket${Number(firstItem) > 1 ? "s" : ""}`;
      } else if (seat.quantityOptions.length > 1) {
        var firstItem = seat.quantityOptions[0];
        var lastItem = seat.quantityOptions[seat.quantityOptions.length-1];
        return `${firstItem} - ${lastItem} Tickets`;
      } else {
        return "2 Ticket";
      }
    } else if (seat.sellOption === 3) { //sell together (keep my tickets together as a set)
      return `${seat.noOfSeats} Ticket${seat.noOfSeats > 1 ? "s" : ""}`;
    } else if (seat.sellOption === 4) { //sell in pairs
      if (seat.quantityOptions.length === 1) {
        var firstItem = seat.quantityOptions[0];
        return `${firstItem} Ticket${Number(firstItem) > 1 ? "s" : ""}`;
      } else if (seat.quantityOptions.length > 1) {
        var firstItem = seat.quantityOptions[0];
        var lastItem = seat.quantityOptions[seat.quantityOptions.length-1];
        return `${firstItem} - ${lastItem} Tickets`;
      } else {
        return "2 Ticket";
      }
    } else {
      if (seat.noOfSeats > 1) {
        var firstItem = seat.quantityOptions[0];
        var lastItem = seat.quantityOptions[seat.quantityOptions.length-1];
        return `${firstItem} - ${lastItem} Tickets`;
      } else {
        return "1 Ticket";
      }
    }
  }

  seatItem = (seat) => {
    const { classes, game } = this.props;
    return (
      <ListItem
        button
        style={{}}
        key={seat.seatId}
        divider={true}
        className={classes.MuiListItem}
        onClick={() => this.handleSeatSelection(seat)}
      >
        <img
          src={TicketIcon}
          style={{
            marginRight: "1rem",
            verticalAlign: "top",
            height: "1rem",
          }}
          alt="Ticket Icon"
        />
        
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={6}>
            <Typography variant="body2" style={{textTransform: "capitalize"}}>
            {renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial)}
            </Typography>
            <Typography style={{ display: "inline-block"}} variant="caption">
              {this.renderAvailableQty(seat)}
            </Typography>
            <Typography style={{ display: "inline-block",}} variant="caption">
              {renderSeatNumbers(seat)}
            </Typography>
            {this.renderAisleSeat(seat)}
            {this.renderObstructedView(seat)}
            {this.renderInstantDelivery(seat)}
            {this.renderWheelchair(seat)}
          </Grid>
          <Grid item xs={3} align="center">
            <Typography variant="body2">Row {renderRowName(seat.zone, seat.row, game.homeTeamSlug, game.isSpecial)}</Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography
              variant="body2"
              align="right"
              style={{
                color: "#2196f3",
              }}
            >
              ${parseFloat(seat.price).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  zone = (zone) => {
    const { homeTeamSlug, isSpecial } = this.state.game;

    let label;
    switch (zone) {
      case 1: {
        if (homeTeamSlug === "vancouver-canucks") {
          return "Upper Bowl";
        }

        if (homeTeamSlug === "calgary-stampeders") {
          return "Lower Zone";
        }

        if (homeTeamSlug === "bc-lions" && isSpecial) {
          return "";
        }

        label =
          homeTeamSlug && homeTeamSlug === ("calgary-flames" || "calgary-wranglers")
            ? "Press Level"
            : "Upper Zone";

        break;
      }
      case 2: {
        if (homeTeamSlug === ("calgary-flames" || "calgary-wranglers")) {
          return "Second Level";
        } else if (homeTeamSlug === "winnipeg-jets" || homeTeamSlug === "toronto-blue-jays" || homeTeamSlug === "manitoba-moose") {
          return "Middle Zone";
        } else if (homeTeamSlug === "edmonton-oilers") {
          return "Lower Bowl";
        } else if (homeTeamSlug === "vancouver-canucks") {
          return "Lower Bowl";
        } else if (homeTeamSlug === "toronto-maple-leafs" || homeTeamSlug === "toronto-raptors") {
          return "Lower Zone"
        }

        break;
      }
      case 3: {
        if (homeTeamSlug === "vancouver-canucks") {
          return "Club Lower";
        }

        label =
          homeTeamSlug && homeTeamSlug === ("calgary-flames" || "calgary-wranglers")
            ? "Lower"
            : "Others";
        break;
      }

      case 4: {
        label = "Loge";
        break;
      }

      default: {
        label = null;
      }
    }

    return label;
  };

  renderSeats = () => {
    const { seats, isLoadingTESeats } = this.props;
    const { selectedZone, isLoading } = this.state;

    if (isLoading && seats.length === 0) {
      return (
        <Typography
          variant="subheading"
          style={{
            padding: "2rem",
            fontWeight: "bold",
            color: "#2196f3",
          }}
          align="center"
        >
         Loading Seats...
        </Typography>
      );
    }
    
    if (seats.length > 0) {
      return seats.map((seat) => {
        if (seat.zone === selectedZone) {
          return this.seatItem(seat);
        } else {
          return this.seatItem(seat);
        }
      });
    } else {
      const zone = this.zone(selectedZone) || '';
      
      return (
        <Typography
          variant="headline"
          color="secondary"
          style={{
            padding: "2rem",
            fontWeight: "bold",
          }}
          align="center"
        >
          {selectedZone === 0
            ? isLoadingTESeats ? "" : "No Tickets Available"
            : <span>{zone === "Others" ? "Tickets are" : zone} Sold Out</span>}
        </Typography>
      );
    }
  };

  render() {
    const { isLoadingTESeats } = this.props;

    return (
      <Grid container id="seatsList">
        <Grid item xs={12}>
          {this.renderZones()}
          {this.renderFilters()}
          <List disablePadding={true}>
            {this.renderSeats()}
          </List>
          {isLoadingTESeats && 
            <Typography
              variant="subheading"
              style={{
                padding: "2rem",
                fontWeight: "bold",
                color: "#2196f3",
              }}
              align="center"
            >
              Loading...
            </Typography>}
        </Grid>
      </Grid>
    );
  }
}

SeatsList.propTypes = {
  seats: PropTypes.array,
  onSeatSelection: PropTypes.func.isRequired,
  zone: PropTypes.number.isRequired,
  zoneFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  game: PropTypes.bool.isRequired,
  isAisleSeat: PropTypes.number,
  isWheelchair: PropTypes.number,
  isInstantDelivery: PropTypes.number,
  aisleSeatFilter: PropTypes.func.isRequired,
  wheelchairFilter: PropTypes.func.isRequired,
  instantDeliveryFilter: PropTypes.func.isRequired
};

export default withStyles(styles)(SeatsList);
