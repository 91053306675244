const zones = [
  {
    label: 'Upper Zone 301',
  },
  {
    label: 'Upper Zone 302',
  },
  {
    label: 'Upper Zone 303',
  },
  {
    label: 'Upper Zone 304',
  },
  {
    label: 'Upper Zone 305',
  },
  {
    label: 'Upper Zone 306',
  },
  {
    label: 'Upper Zone 307',
  },
  {
    label: 'Upper Zone 308',
  },
  {
    label: 'Upper Zone 309',
  },
  {
    label: 'Upper Zone 310',
  },
  {
    label: 'Upper Zone 311',
  },
  {
    label: 'Upper Zone 312',
  },
  {
    label: 'Upper Zone 313',
  },
  {
    label: 'Upper Zone 314',
  },
  {
    label: 'Upper Zone 315',
  },
  {
    label: 'Upper Zone 316',
  },
  {
    label: 'Upper Zone 317',
  },
  {
    label: 'Upper Zone 318',
  },
  {
    label: 'Upper Zone 319',
  },
  {
    label: 'Upper Zone 320',
  },
  {
    label: 'Upper Zone 321',
  },
  {
    label: 'Upper Zone 322',
  },
  {
    label: 'Upper Zone 323',
  },
  {
    label: 'Upper Zone 324',
  },
  {
    label: 'Upper Zone 325',
  },
  {
    label: 'Upper Zone 326',
  },
  {
    label: 'Upper Zone 327',
  },
  {
    label: 'Upper Zone 328',
  },
  {
    label: 'Upper Zone 329',
  },
  {
    label: 'Upper Zone 330',
  },
  {
    label: 'Loge 301',
  },
  {
    label: 'Loge 302',
  },
  {
    label: 'Loge 303',
  },
  {
    label: 'Loge 304',
  },
  {
    label: 'Loge 305',
  },
  {
    label: 'Loge 306',
  },
  {
    label: 'Loge 307',
  },
  {
    label: 'Loge 308',
  },
  {
    label: 'Loge 309',
  },
  {
    label: 'Loge 310',
  },
  {
    label: 'Loge 311',
  },
  {
    label: 'Loge 312',
  },
  {
    label: 'Loge 313',
  },
  {
    label: 'Loge 314',
  },
  {
    label: 'Loge 315',
  },
  {
    label: 'Loge 316',
  },
  {
    label: 'Loge 317',
  },
  {
    label: 'Loge 318',
  },
  {
    label: 'Loge 319',
  },
  {
    label: 'Loge 320',
  },
  {
    label: 'Loge 321',
  },
  {
    label: 'Loge 322',
  },
  {
    label: 'Loge 323',
  },
  {
    label: 'Loge 324',
  },
  {
    label: 'Loge 325',
  },
  {
    label: 'Loge 326',
  },
  {
    label: 'Loge 327',
  },
  {
    label: 'Loge 328',
  },
  {
    label: 'Loge 329',
  },
  {
    label: 'Loge 330',
  },
  {
    label: 'Middle Zone 202',
  },
  {
    label: 'Middle Zone 203',
  },
  {
    label: 'Middle Zone 204',
  },
  {
    label: 'Middle Zone 205',
  },
  {
    label: 'Middle Zone 206',
  },
  {
    label: 'Middle Zone 207',
  },
  {
    label: 'Middle Zone 208',
  },
  {
    label: 'Middle Zone 209',
  },
  {
    label: 'Middle Zone 210',
  },
  {
    label: 'Middle Zone 211',
  },
  {
    label: 'Middle Zone 212',
  },
  {
    label: 'Middle Zone 213',
  },
  {
    label: 'Middle Zone 214',
  },
  {
    label: 'Middle Zone 215',
  },
  {
    label: 'Middle Zone 216',
  },
  {
    label: 'Middle Zone 217',
  },
  {
    label: 'Middle Zone 218',
  },
  {
    label: 'Middle Zone 219',
  },
  {
    label: 'Middle Zone 220',
  },
  {
    label: 'Middle Zone 221',
  },
  {
    label: 'Middle Zone 222',
  },
  {
    label: 'Middle Zone 223',
  },
  {
    label: 'Middle Zone 224',
  },
  {
    label: 'Middle Zone 225',
  },
  {
    label: 'Middle Zone 226',
  },
  {
    label: 'Middle Zone 227',
  },
  {
    label: 'Middle Zone 228',
  },
  {
    label: 'Lower Zone 101',
  },
  {
    label: 'Lower Zone 103',
  },
  {
    label: 'Lower Zone 104',
  },
  {
    label: 'Lower Zone 105',
  },
  {
    label: 'Lower Zone 106',
  },
  {
    label: 'Lower Zone 107',
  },
  {
    label: 'Lower Zone 109',
  },
  {
    label: 'Lower Zone 111',
  },
  {
    label: 'Lower Zone 112',
  },
  {
    label: 'Lower Zone 113',
  },
  {
    label: 'Lower Zone 115',
  },
  {
    label: 'Lower Zone 116',
  },
  {
    label: 'Lower Zone 117',
  },
  {
    label: 'Lower Zone 118',
  },
  {
    label: 'Lower Zone 119',
  },
  {
    label: 'Lower Zone 120',
  },
  {
    label: 'Lower Zone 121',
  },
  {
    label: 'Lower Zone 123',
  },
  {
    label: 'Lower Zone 124',
  },
  {
    label: 'Lower Zone 125',
  },
  {
    label: 'Lower Zone 126',
  },
  {
    label: 'Lower Zone 127',
  },
  {
    label: 'PREMIUM SUITE LOUNGE',
  }
];

export default zones;
