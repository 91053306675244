import React, { useState } from "react";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  withStyles,
  Divider,
} from "@material-ui/core";
import { 
  Dashboard,
  AccountCircle,
  PersonOutline,
  ConfirmationNumber as TicketTrackIcon,
  Receipt,
  List,
  ExitToApp
} from "@material-ui/icons";

const styles = (theme) => ({
  accountMenuContainer: {
    cursor: "pointer",
  },
  button: {
    minHeight: 48,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  menuItem: {
    width: 'auto',
  },
  listItemText: {
    fontSize: '0.8rem',
    padding: '0 16px 0 0'
  },
  listIcon: {
    color: '#2196f3'
  }
});

const accountMenu = ({ classes, currUser, handleLogout }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (e) => {
    setOpen(true);
  };
  const handleCloseOut = (e) => {
    setOpen(false);
  };
  const handleClose = (url) => {
    setOpen(false);
    window.location.href = url;
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        onClick={handleMenu}
        buttonRef={(node) => {
          setAnchorEl(node);
        }}
      >
        {currUser.name}
        <AccountCircle className={classes.rightIcon} />
      </Button>

      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseOut}>
                <MenuList className={classes.menuItem}>
                  <MenuItem onClick={() => handleClose("/dashboard")}>
                    <ListItemIcon className={classes.listIcon}>
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Dashboard" 
                      className={classes.listItemText}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("/account")}>
                    <ListItemIcon className={classes.listIcon}>
                      <PersonOutline />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Profile" 
                      className={classes.listItemText}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("/track-order")}>
                    <ListItemIcon className={classes.listIcon}>
                      <TicketTrackIcon />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Track Your Tickets" 
                      className={classes.listItemText}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("/orders")}>
                    <ListItemIcon className={classes.listIcon}>
                      <Receipt />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Orders" 
                      className={classes.listItemText}  
                    />
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("/listings")}>
                    <ListItemIcon className={classes.listIcon}>
                      <List />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Listings (Sell)" 
                      className={classes.listItemText}  
                    />
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => handleLogout()} >
                    <ListItemIcon className={classes.listIcon}>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Logout" 
                      className={classes.listItemText}  
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default withStyles(styles)(accountMenu);
