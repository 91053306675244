import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import * as Sentry from "@sentry/browser";

import App from "./App";
import * as serviceWorker from "./registerServiceWorker";
import config from "./config";
import TagManager from "react-gtm-module";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const tagManagerArgs = {
  gtmId: "GTM-MQM7TN8",
};

TagManager.initialize(tagManagerArgs);
Sentry.init({
  dsn: "https://5cb1ea727c8a4d97b4d4744a2fbf69ad@sentry.io/1395809",
  release: "1.4.8",
});

const breakpoints = createBreakpoints({});

const pxToRem = (value) => {
  return `${value / 16}rem`;
};

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: "#e03a3e",
    },
    tertiary: {
      main: "#e03a3e",
    },
  },
  status: {
    danger: "orange",
  },
  breakpoints,
  overrides: {
    MuiTypography: {
      headline: {
        fontSize: pxToRem(24),
        marginBottom: ".25rem",
        [breakpoints.up("md")]: {
          fontSize: pxToRem(32),
        },
      },
      title: {
        fontSize: pxToRem(21),
        [breakpoints.up("md")]: {
          fontSize: pxToRem(24),
        },
      },
      body1: {
        fontSize: pxToRem(14),
        lineHeight: 1.5,
        [breakpoints.up("md")]: {
          fontSize: pxToRem(16),
        },
      },
      body2: {
        fontSize: pxToRem(14),
        lineHeight: 1.5,
        [breakpoints.up("md")]: {
          fontSize: pxToRem(16),
        },
      },
      button: {
        fontSize: pxToRem(14),
        [breakpoints.up("md")]: {
          fontSize: pxToRem(16),
        },
      },
      caption: {
        fontSize: pxToRem(12),
        [breakpoints.up("md")]: {
          fontSize: pxToRem(14),
        },
      },
    },
  },
  shadows: [
    "none",
    "0px 2px 3px 0px rgba(0, 0, 0, 0.07),0px 4px 5px 0px rgba(0, 0, 0, 0.03),0px 2px 1px -1px rgba(0, 0, 0, 0.02)",
    "0px 2px 5px 0px rgba(0, 0, 0, 0.07),0px 2px 2px 0px rgba(0, 0, 0, 0.03),0px 3px 1px -2px rgba(0, 0, 0, 0.02)",
    "0px 2px 9px 0px rgba(0, 0, 0, 0.07),0px 1px 3px 0px rgba(0, 0, 0, 0.03),0px 3px 3px -2px rgba(0, 0, 0, 0.02)",
    "0px 4px 4px -1px rgba(0, 0, 0, 0.07),0px 0px 5px 0px rgba(0, 0, 0, 0.03),0px 1px 10px 0px rgba(0, 0, 0, 0.02)",
    "0px 6px 6px -1px rgba(0, 0, 0, 0.07),0px -1px 10px 0px rgba(0, 0, 0, 0.03),0px 1px 14px 0px rgba(0, 0, 0, 0.02)",
    "0px 6px 6px -1px rgba(0, 0, 0, 0.07),0px -2px 12px 0px rgba(0, 0, 0, 0.03),0px 1px 18px 0px rgba(0, 0, 0, 0.02)",
    "0px 7px 6px -2px rgba(0, 0, 0, 0.07),0px -1px 12px 1px rgba(0, 0, 0, 0.03),0px 2px 16px 1px rgba(0, 0, 0, 0.02)",
    "0px 10px 6px -3px rgba(0, 0, 0, 0.07),0px 0px 12px 1px rgba(0, 0, 0, 0.03),0px 3px 14px 2px rgba(0, 0, 0, 0.02)",
    "0px 10px 7px -3px rgba(0, 0, 0, 0.07),0px 1px 14px 1px rgba(0, 0, 0, 0.03),0px 3px 16px 2px rgba(0, 0, 0, 0.02)",
    "0px 11px 7px -3px rgba(0, 0, 0, 0.07),0px 2px 16px 1px rgba(0, 0, 0, 0.03),0px 4px 18px 3px rgba(0, 0, 0, 0.02)",
    "0px 11px 8px -4px rgba(0, 0, 0, 0.07),0px 3px 17px 1px rgba(0, 0, 0, 0.03),0px 4px 20px 3px rgba(0, 0, 0, 0.02)",
    "0px 13px 9px -4px rgba(0, 0, 0, 0.07),0px 4px 19px 2px rgba(0, 0, 0, 0.03),0px 5px 22px 4px rgba(0, 0, 0, 0.02)",
    "0px 13px 9px -4px rgba(0, 0, 0, 0.07),0px 5px 21px 2px rgba(0, 0, 0, 0.03),0px 5px 24px 4px rgba(0, 0, 0, 0.02)",
    "0px 13px 10px -4px rgba(0, 0, 0, 0.07),0px 6px 23px 2px rgba(0, 0, 0, 0.03),0px 5px 26px 4px rgba(0, 0, 0, 0.02)",
    "0px 15px 10px -5px rgba(0, 0, 0, 0.07),0px 7px 24px 2px rgba(0, 0, 0, 0.03),0px 6px 28px 5px rgba(0, 0, 0, 0.02)",
    "0px 15px 12px -5px rgba(0, 0, 0, 0.07),0px 8px 26px 2px rgba(0, 0, 0, 0.03),0px 6px 30px 5px rgba(0, 0, 0, 0.02)",
    "0px 15px 13px -5px rgba(0, 0, 0, 0.07),0px 9px 28px 2px rgba(0, 0, 0, 0.03),0px 6px 32px 5px rgba(0, 0, 0, 0.02)",
    "0px 17px 13px -5px rgba(0, 0, 0, 0.07),0px 10px 30px 2px rgba(0, 0, 0, 0.03),0px 7px 34px 6px rgba(0, 0, 0, 0.02)",
    "0px 17px 14px -6px rgba(0, 0, 0, 0.07),0px 11px 31px 2px rgba(0, 0, 0, 0.03),0px 7px 36px 6px rgba(0, 0, 0, 0.02)",
    "0px 19px 15px -6px rgba(0, 0, 0, 0.07),0px 12px 33px 3px rgba(0, 0, 0, 0.03),0px 8px 38px 7px rgba(0, 0, 0, 0.02)",
    "0px 19px 15px -6px rgba(0, 0, 0, 0.07),0px 13px 35px 3px rgba(0, 0, 0, 0.03),0px 8px 40px 7px rgba(0, 0, 0, 0.02)",
    "0px 19px 16px -6px rgba(0, 0, 0, 0.07),0px 14px 37px 3px rgba(0, 0, 0, 0.03),0px 8px 42px 7px rgba(0, 0, 0, 0.02)",
    "0px 20px 16px -7px rgba(0, 0, 0, 0.07),0px 15px 38px 3px rgba(0, 0, 0, 0.03),0px 9px 44px 8px rgba(0, 0, 0, 0.02)",
    "0px 20px 18px -7px rgba(0, 0, 0, 0.07),0px 16px 40px 3px rgba(0, 0, 0, 0.03),0px 9px 46px 8px rgba(0, 0, 0, 0.02)",
  ],
});

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "v2",
        endpoint: process.env.REACT_APP_NEW_API_BASE_URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
