import React from "react";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";

import TermsAndConditions from "../components/TermsAndConditions";

const Legal = () => {
  const currentURL = window.location.href;
  return (
    <Grid container justify="center">
      <Helmet>
        <title>FansFirst &mdash; Terms and Conditions</title>
        <meta name="description" content="Terms and Conditions" />
        <meta name="keywords" content={`terms and conditions, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="FansFirst &mdash; Terms and Conditions" />
        <meta property="og:description" content="Terms and Conditions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentURL} />
        <link rel="canonical" href={currentURL} />
      </Helmet>
      <Grid item xs={12} md={6}>
        <main style={{ marginTop: "5rem" }}>
          <TermsAndConditions />
        </main>
      </Grid>
    </Grid>
  );
};

export default Legal;
