
  const aToS = () => {
    const arr = [];
  
    for (let i = 65; i <= 83; i++) {
      const character = String.fromCharCode(i)
      arr.push({
        value: character,
        label: `Row ${character}`,
      });
    }
  
    return arr;
  };

  const cToS = () => {
    const arr = [];
  
    for (let i = 67; i <= 83; i++) {
      const character = String.fromCharCode(i)
      arr.push({
        value: character,
        label: `Row ${character}`,
      });
    }
  
    return arr;
  };

  const aToF = () => {
    const arr = [];
  
    for (let i = 65; i <= 70; i++) {
      const character = String.fromCharCode(i)
      arr.push({
        value: character,
        label: `Row ${character}`,
      });
    }
  
    return arr;
  };
  
  export default {
    A_TO_S: aToS(),
    C_TO_S: cToS(),
    A_TO_F: aToF(),
  };
  