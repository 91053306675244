import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Legal from '../constants/LegalContent';

const styles = {
  icon: {
    height: 32,
    marginBottom: '1.5rem'
  },
  details: {
    padding: '2rem 0 1rem'
  },
  detailsTitle: {
    color: '#2196f3',
    marginBottom: '1rem'
  }
};

const TermsAndConditions = ({ classes }) => {

  return (
    <React.Fragment>
      <Typography
        variant="headline"
        color="secondary"
        style={{fontWeight: 'bold'}}>
        Terms and Conditions
      </Typography>

      {
        Legal.map((terms, index) => {
          return (
            <div
              className={classes.details}
              key={index}>
              <Typography
                variant="title"
                className={classes.detailsTitle} >
                { terms.title }
              </Typography>
              <Typography
                variant="body1"
                align="justify"
                dangerouslySetInnerHTML={{__html: terms.description}}>
              </Typography>
            </div>
          )
        })
      }
    </React.Fragment>
  );
};

export default withStyles(styles)(TermsAndConditions);