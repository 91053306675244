// Pathname checking for the Sidenav
export const sideNavPath = (currentPath) => {
  const pathNames = ['dashboard','account', 'track-order', 'orders', 'listings', 'sell'];
  return pathNames.some(word => currentPath.includes(word));
}

export const getInitialName = name => {
  if (typeof name !== 'string') {
    return '';
  }

  const words = name.split(' ');

  if (words.length === 1) {
    return name.substring(0, 2).toUpperCase();
  } else {
    return name
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }
}

export const rgbaToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const kebabToTitleCase = str => {
  return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}