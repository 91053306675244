export const getZones = rsr => {
  let zones = [];

  var _106 = {
    path: 'M2263.02,2296.25c-164.09,130.06-292.08,311.26-345.57,505.39l455.4,148.27c28.76-76.85,72.4-149.98,126.6-215.08l-77.48-78.75a933.649,933.649,0,0,1,78.08-81.07Z',
    attr: {
      id: '106',
      'data-name': '106',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 106,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _107 = {
    path: 'M2586.98,2077.1l-232.41,154.32-.01.01-42.29,28.09q-25.125,17.595-49.27,36.73l237.03,278.77q11.235-10.35,22.8-20.3a916.505,916.505,0,0,1,73.68-57.18l69.63,85.6c37.49-25.55,79.99-47.65,120.71-65.64l36.9-94.1Z',
    attr: {
      id: '107',
      'data-name': '107',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 107,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _108 = {
    path: 'M2865.01,1892.5,2587,2077.1l236.75,346.25,146.15-359.3Z',
    attr: {
      id: '108',
      'data-name': '108',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 108,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _109 = {
    path: 'M3103.45,1734.6,2865,1892.48l104.9,171.62Z',
    attr: {
      id: '109',
      'data-name': '109',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 109,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _110 = {
    path: 'M3025.94,1925.7,2799,2486.38l331.08-23.82Z',
    attr: {
      id: '110',
      'data-name': '110',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 110,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _111 = {
    path: 'M3451.02,1566.25l-322.74,63.72,10.88,15.86L3025.9,1925.66l104.15,536.86,488.79-35.16Z',
    attr: {
      id: '111',
      'data-name': '111',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 111,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _112 = {
    path: 'M3797.49,1498l-346.54,68.27,167.88,861.15,223.47-16.07h106.2V1498Z',
    attr: {
      id: '112',
      'data-name': '112',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 112,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _113 = {
    path: 'M3948.55,1498v913.35H4384.6V1498Z',
    attr: {
      id: '113',
      'data-name': '113',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 113,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _114 = {
    path: 'M4384.6,1498v913.35h435.05V1498Z',
    attr: {
      id: '114',
      'data-name': '114',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 114,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _115 = {
    path: 'M4819.65,1498v913.35H5255.5V1498Z',
    attr: {
      id: '115',
      'data-name': '115',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 115,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _116 = {
    path: 'M5255.5,1498v913.35h435.8V1498Z',
    attr: {
      id: '116',
      'data-name': '116',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 116,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _117 = {
    path: 'M5691.3,1498v913.35h107.25l222.43,16,176.71-864.88L5870.92,1498Z',
    attr: {
      id: '117',
      'data-name': '117',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 117,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _118 = {
    path: 'M6197.66,1562.45l-176.71,864.88,455.93,32.8,113.1-594.86-99.36-245.03Z',
    attr: {
      id: '118',
      'data-name': '118',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 118,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _119 = {
    path: 'M6590,1865.25l-113.1,594.86,245.33,17.65-119.19-292.79,125.33,21.49Z',
    attr: {
      id: '119',
      'data-name': '119',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 119,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _120 = {
    path: 'M6348.75,1270.8l275.55,679.67,118.25-201.27-59.06-39.66,99.39-148.23Z',
    attr: {
      id: '120',
      'data-name': '120',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 120,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _121 = {
    path: 'M6782.87,1561.3l-99.4,148.2,58.95,39.65.03.08-117.7,202.45,173.91,428.98,276.14-416.3-31.11-20.98,96.44-142.98Z',
    attr: {
      id: '121',
      'data-name': '121',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 121,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _122 = {
    path: 'M7140.2,1800.45l-96.45,142.85,31.05,21.05-276.15,416.3,50.98,125.65c86.57,34,168.36,86.94,238.93,152.42l324.89-353.21q-7.92-7.335-15.95-14.53l-.02-.05,166.93-206.63Z',
    attr: {
      id: '122',
      'data-name': '122',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 122,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _123 = {
    path: 'M7413.44,2305.5l-324.89,353.22c76.95,71.41,140.57,157.74,182.51,250.74l444.35-181.55a1197.943,1197.943,0,0,0-66.07-129.47,1271.165,1271.165,0,0,0-134.15-187.52A1298,1298,0,0,0,7413.44,2305.5Z',
    attr: {
      id: '123',
      'data-name': '123',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 123,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _124 = {
    path: 'M7715.32,2727.95l-444.44,181.49q7.23,16.065,13.59,32.4l-.06-.01v.06l-62.36,22.37,17.46,154.46v11.51l564.07-46.47C7795.08,2962.96,7764.81,2842.56,7715.32,2727.95Z',
    attr: {
      id: '124',
      'data-name': '124',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 124,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _125 = {
    path: 'M7803.57,3083.75l-564.07,46.47v291.92h566.54V3153.72Q7806.04,3118.755,7803.57,3083.75Z',
    attr: {
      id: '125',
      'data-name': '125',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 125,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _126 = {
    path: 'M7239.5,3422.15V3840.7h566.54V3422.15Z',
    attr: {
      id: '126',
      'data-name': '126',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 126,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _127 = {
    path: 'M7239.5,3840.7v347.1h566.55l-.01-347.1Z',
    attr: {
      id: '127',
      'data-name': '127',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 127,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _128 = {
    path: 'M7239.5,4187.8v367.74l563.68,52.57q2.865-37.74,2.87-75.48V4187.8Z',
    attr: {
      id: '128',
      'data-name': '128',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 128,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _129 = {
    path: 'M7239.52,4555.5v5.67l-17.47,154.53,64.61,23.18q-6.33,16.695-13.57,33.15l444.4,181.5c47.51-111.43,76.84-228.22,85.71-345.47Z',
    attr: {
      id: '129',
      'data-name': '129',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 129,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _130 = {
    path: 'M7273.07,4772.05h-.02c-41.86,95.1-106.19,183.27-184.4,255.94l324.91,353.28a1300.975,1300.975,0,0,0,101.95-105.6,1271.491,1271.491,0,0,0,134.15-187.52,1194.857,1194.857,0,0,0,67.82-134.66l-.06-.04Z',
    attr: {
      id: '130',
      'data-name': '130',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 130,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _131 = {
    path: 'M7088.58,5027.95c-70.4,65.42-152.05,118.27-238.7,152.31L6792,5323.01l268.78,406.32-41.02,27.55,97.33,144.94,447.65-299.52-167.01-206.68.02-.05q7.92-7.095,15.74-14.33Z',
    attr: {
      id: '131',
      'data-name': '131',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 131,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _132 = {
    path: 'M6792.03,5322l-161.98,400.6,132.03,203.21-105.28,70.7,98,145.94,362.35-240.55-.14-.21-97.19-144.73,41.12-27.61Z',
    attr: {
      id: '132',
      'data-name': '132',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 132,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _133 = {
    path: 'M6629.97,5721.5l-280.82,694.23L6754.8,6142.5l-98.01-145.94,105.5-70.84Z',
    attr: {
      id: '133',
      'data-name': '133',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 133,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _134 = {
    path: 'M6842.03,5200.15l-324.68,23.35,102.29,525.05Z',
    attr: {
      id: '134',
      'data-name': '134',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 134,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _135 = {
    path: 'M6517.36,5223.5l-496.41,35.71,176.68,864.91,293.16-57.83,128.86-317.75Z',
    attr: {
      id: '135',
      'data-name': '135',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 135,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _136 = {
    path: 'M6020.96,5259.2l-222.26,15.99h-79.9v913.35h152.27l326.57-64.43Z',
    attr: {
      id: '136',
      'data-name': '136',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 136,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _137 = {
    path: 'M5255.5,5275.2v913.35h463.3V5275.2Z',
    attr: {
      id: '137',
      'data-name': '137',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 137,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _138 = {
    path: 'M4819.75,5275.2v913.35H5255.5V5275.2Z',
    attr: {
      id: '138',
      'data-name': '138',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 138,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _139 = {
    path: 'M4384.6,5275.2v913.35h435.15V5275.2Z',
    attr: {
      id: '139',
      'data-name': '139',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 139,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _140 = {
    path: 'M3921.05,5275.2v913.35H4384.6V5275.2Z',
    attr: {
      id: '140',
      'data-name': '140',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 140,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _141 = {
    path: 'M3618.88,5259.1,3443.3,6118.62l354.36,69.91h123.36V5275.18h-78.55Z',
    attr: {
      id: '141',
      'data-name': '141',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 141,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _142 = {
    path: 'M3130.04,5223.95l-104.09,536.62,113.36,280.08-10.88,15.85,314.87,62.13,175.58-859.52Z',
    attr: {
      id: '142',
      'data-name': '142',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 142,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _143 = {
    path: 'M2799.15,5200.15l226.84,560.42,104.08-536.62Z',
    attr: {
      id: '143',
      'data-name': '143',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 143,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _144 = {
    path: 'M2967.15,5615.85,2865,5794.08l238.09,158.13v-.44Z',
    attr: {
      id: '144',
      'data-name': '144',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 144,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _145 = {
    path: 'M2825.23,5265.2,2587,5609.43l278,184.64,102.14-178.23Z',
    attr: {
      id: '145',
      'data-name': '145',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 145,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _146 = {
    path: 'M2571.34,5027.6l-307.99,362.26q30.7,24.375,63.03,46.29v.15l260.63,173.1,238.24-344.23-38.31-94.66C2708.88,5136.44,2635.43,5087.23,2571.34,5027.6Z',
    attr: {
      id: '146',
      'data-name': '146',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 146,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _147 = {
    path: 'M2370.74,4730.8,1916.3,4879.21c52.84,196,181.62,379.36,347.07,510.69l307.98-362.26C2482.12,4944.63,2410.99,4841.43,2370.74,4730.8Z',
    attr: {
      id: '147',
      'data-name': '147',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 147,
      zone: 'Lower Zone',
      zoneId: 3
    }
  };
  
  var _224 = {
    path: 'M8274.62,2671.5,7751.3,2822.52c35.81,108.14,54.71,219.97,54.71,331.23v30.35H8330.19V2970.13Z',
    attr: {
      id: '224',
      'data-name': '224',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 224,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _225 = {
    path: 'M7806.05,3184.1v470H8330.23v-470Z',
    attr: {
      id: '225',
      'data-name': '225',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 225,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _226 = {
    path: 'M7806.05,3654.1V4027H8330.23V3654.1Z',
    attr: {
      id: '226',
      'data-name': '226',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 226,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _227 = {
    path: 'M7806.05,4027v469.8h524.17V4027Z',
    attr: {
      id: '227',
      'data-name': '227',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 227,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _228 = {
    path: 'M7802.407,4496.8v35.85c0,112.17-17.9,224.89-51.857,333.85l527.831,164.16,51.839-302.5V4496.81Z',
    attr: {
      id: '228',
      'data-name': '228',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 228,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _235 = {
    path: 'M6490.72,6066.25l-293.07,57.93,71.48,349.9,61.13-12.16Z',
    attr: {
      id: '235',
      'data-name': '235',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 235,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _236 = {
    path: 'M6197.6,6124.1l-326.61,64.43-53.12.05-.02,348,134.05.01,317.19-62.58Z',
    attr: {
      id: '236',
      'data-name': '236',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 236,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _237 = {
    path: 'M5817.97,6188.5l-300.42.08.08,348,300.34-.03Z',
    attr: {
      id: '237',
      'data-name': '237',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 237,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _238 = {
    path: 'M5517.55,6323.95h-450.1v212.6h450.1Z',
    attr: {
      id: '238',
      'data-name': '238',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 238,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _239 = {
    path: 'M5067.45,6323.95h-496.6v212.6h496.6Z',
    attr: {
      id: '239',
      'data-name': '239',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 239,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _240 = {
    path: 'M4570.85,6323.95h-449.7v212.6h449.7Z',
    attr: {
      id: '240',
      'data-name': '240',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 240,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _241 = {
    path: 'M4121.15,6188.5h-300.7v348.05h300.7Z',
    attr: {
      id: '241',
      'data-name': '241',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 241,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _242 = {
    path: 'M3443.34,6118.6l-71.49,349.92,344.82,68.04,103.75.01V6188.52l-22.78-.01Z',
    attr: {
      id: '242',
      'data-name': '242',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 242,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _243 = {
    path: 'M3128.42,6056.5l-220.07,320.61,463.53,91.52,71.5-350Z',
    attr: {
      id: '243',
      'data-name': '243',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 243,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _308 = {
    path: 'M3241.92,1243.5l-173.37,34.23-12.5-34.17-170.55,33.1,242.56,353.38,182.25-36Z',
    attr: {
      id: '308',
      'data-name': '308',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 308,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _309 = {
    path: 'M3611.12,1170.8l-369.22,72.74,68.4,350.53,367.46-72.46Z',
    attr: {
      id: '309',
      'data-name': '309',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 309,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _310 = {
    path: 'M3716.23,1150l-105.13,20.81,66.64,350.81,119.46-23.57h210.2V1150Z',
    attr: {
      id: '310',
      'data-name': '310',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 310,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _311 = {
    path: 'M4007.5,1150v348.05h138.25V1150Z',
    attr: {
      id: '311',
      'data-name': '311',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 311,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _312 = {
    path: 'M4145.75,1284.9v162.95h226.9V1284.9Z',
    attr: {
      id: '312',
      'data-name': '312',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 312,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _313 = {
    path: 'M4372.65,1284.9v162.95H4593.5V1284.9Z',
    attr: {
      id: '313',
      'data-name': '313',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 313,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _314 = {
    path: 'M4593.5,1284.9v162.95h226.15V1284.9Z',
    attr: {
      id: '314',
      'data-name': '314',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 314,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _315 = {
    path: 'M4819.65,1284.9v162.95h225V1284.9Z',
    attr: {
      id: '315',
      'data-name': '315',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 315,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _316 = {
    path: 'M5044.65,1284.9v162.95H5265.5V1284.9Z',
    attr: {
      id: '316',
      'data-name': '316',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 316,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _317 = {
    path: 'M5265.5,1284.9v162.95h226.75V1284.9Z',
    attr: {
      id: '317',
      'data-name': '317',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 317,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _318 = {
    path: 'M5492.25,1150v348.05H5630.5V1150Z',
    attr: {
      id: '318',
      'data-name': '318',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 318,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _319 = {
    path: 'M5630.5,1150v348.05h240.2l106.4,21,62.6-351.67L5951.61,1150Z',
    attr: {
      id: '319',
      'data-name': '319',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 319,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _320 = {
    path: 'M6045.86,1132.95,5977.1,1519l513.3,101.26-176.94-435.2Z',
    attr: {
      id: '320',
      'data-name': '320',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 320,
      zone: 'Middle Zone',
      zoneId: 2
    }
  };
  
  var _510 = {
    path: 'M3056.01,1037.5,2809,1086.22l115.27,167.87,168.22-33.13Z',
    attr: {
      id: '510',
      'data-name': '510',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 510,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _511 = {
    path: 'M3535.46,947.75l-466.66,87.29-.08-.05L3056,1037.5l36.48,183.45,478.56-94.26Z',
    attr: {
      id: '511',
      'data-name': '511',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 511,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _512 = {
    path: 'M3916.84,913.9l-200.54.009-180.85,33.827,35.59,178.944,167.66-33.02h178.14Z',
    attr: {
      id: '512',
      'data-name': '512',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 512,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _513 = {
    path: 'M4419.45,913.9l-502.6.022V1093.68h502.6Z',
    attr: {
      id: '513',
      'data-name': '513',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 513,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _514 = {
    path: 'M4822.1,913.9l-402.65.018V1093.7H4822.1Z',
    attr: {
      id: '514',
      'data-name': '514',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 514,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _515 = {
    path: 'M5190.5,913.85l-368.4.017V1093.66h368.4Z',
    attr: {
      id: '515',
      'data-name': '515',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 515,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _516 = {
    path: 'M5708.95,913.85l-518.45.023V1093.69h518.45Z',
    attr: {
      id: '516',
      'data-name': '516',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 516,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _517 = {
    path: 'M5954.61,913.85l-245.66.011V1093.7h222.19l.26.01,122.16,23.98,35.58-178.728Z',
    attr: {
      id: '517',
      'data-name': '517',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 517,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _518 = {
    path: 'M6089.13,938.95l-35.58,178.73,396.24,77.77v.1l85.08,17.48,38.42-183.7Z',
    attr: {
      id: '518',
      'data-name': '518',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 518,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _519 = {
    path: 'M6573.27,1029.3,6534.85,1213l218.94,43.55,93.53-139.65-70.98-47.5-203.07-40.06v-.04Z',
    attr: {
      id: '519',
      'data-name': '519',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 519,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _520 = {
    path: 'M6449.79,1195.55l-.04,78.3,202.1,134.96,101.96-152.23-218.94-43.55-.01.03.01-.02Z',
    attr: {
      id: '520',
      'data-name': '520',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 520,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _521 = {
    path: 'M6847.34,1116.9l-195.49,291.88,361.71,241.56,195.29-291.52Z',
    attr: {
      id: '521',
      'data-name': '521',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 521,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _522 = {
    path: 'M7208.83,1358.85l-195.28,291.52,338.9,226.32,195.05-291.2Z',
    attr: {
      id: '522',
      'data-name': '522',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 522,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _523 = {
    path: 'M7547.51,1585.45l-195.06,291.2,188.71,126.02,221.01-273.57Z',
    attr: {
      id: '523',
      'data-name': '523',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 523,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _530 = {
    path: 'M7540.98,5686.85l-180.33,120.42,195.02,291.22,206.32-138.07Z',
    attr: {
      id: '530',
      'data-name': '530',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 530,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _531 = {
    path: 'M7360.64,5807.25l-331.79,221.57,195.23,291.54,331.58-221.89Z',
    attr: {
      id: '531',
      'data-name': '531',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 531,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _532 = {
    path: 'M7028.84,6028.8l-364.79,243.61,195.47,291.88,364.55-243.96Z',
    attr: {
      id: '532',
      'data-name': '532',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 532,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _533 = {
    path: 'M6664.05,6272.45l-214.5,143.25.04,78.34,96-22.34h0l.04-.01h.01l.01-.01,222.23-44.2Z',
    attr: {
      id: '533',
      'data-name': '533',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 533,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _534 = {
    path: 'M6767.89,6427.45l-222.29,44.22,37.11,186.58,193.46-38.16,83.36-55.79Z',
    attr: {
      id: '534',
      'data-name': '534',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 534,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _535 = {
    path: 'M6545.6,6471.7l-96,22.34v.05l-382.05,74.99,35.57,178.87,469.98-87.73v-.04l9.61-1.9Z',
    attr: {
      id: '535',
      'data-name': '535',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 535,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _536 = {
    path: 'M6067.55,6569.05l-136.35,26.76-.26.02H5721.3v179.84l233.11.01,148.71-27.76Z',
    attr: {
      id: '536',
      'data-name': '536',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 536,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _537 = {
    path: 'M5197.6,6595.85v179.81l523.7.03V6595.85Z',
    attr: {
      id: '537',
      'data-name': '537',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 537,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _538 = {
    path: 'M4837.1,6595.85v179.8l360.5.01V6595.85Z',
    attr: {
      id: '538',
      'data-name': '538',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 538,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _539 = {
    path: 'M4437.1,6595.85v179.78l400,.02v-179.8Z',
    attr: {
      id: '539',
      'data-name': '539',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 539,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _540 = {
    path: 'M3923.95,6595.85v179.76l513.15.02V6595.85Z',
    attr: {
      id: '540',
      'data-name': '540',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 540,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _541 = {
    path: 'M3583.16,6565.25l-35.56,178.83,168.48,31.51,207.83.01V6595.84H3738.48Z',
    attr: {
      id: '541',
      'data-name': '541',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 541,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _542 = {
    path: 'M3103.82,6470.85l-35.32,183.68.07-.04,479.02,89.6,35.57-178.83Z',
    attr: {
      id: '542',
      'data-name': '542',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 542,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _543 = {
    path: 'M2924.07,6435.45,2808.8,6603.32l259.73,51.23.01-.01,35.32-183.68Z',
    attr: {
      id: '543',
      'data-name': '543',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 543,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _610 = {
    path: 'M3171.32,781q-69.39,29.884-137.12,62.185l34.51,191.835h0l.08.04,147.57-27.6Z',
    attr: {
      id: '610',
      'data-name': '610',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 610,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _611 = {
    path: 'M3576.56,631.9A4011.713,4011.713,0,0,0,3171.3,780.986l45.03,226.464,419.37-78.441Z',
    attr: {
      id: '611',
      'data-name': '611',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 611,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _612 = {
    path: 'M4007.87,524.2a4125.344,4125.344,0,0,0-431.32,107.739l59.14,297.109,80.57-15.071,291.61-.013Z',
    attr: {
      id: '612',
      'data-name': '612',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 612,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _613 = {
    path: 'M4328.95,474.85Q4166.095,493.5,4007.9,524.177V913.942l321.05-.015Z',
    attr: {
      id: '613',
      'data-name': '613',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 613,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _614 = {
    path: 'M4805.25,447a4310.979,4310.979,0,0,0-476.3,27.858V913.935l476.3-.021Z',
    attr: {
      id: '614',
      'data-name': '614',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 614,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _615 = {
    path: 'M4819.73,447q-7.245,0-14.48.024V913.938l473.7-.021V471.388A4316.569,4316.569,0,0,0,4819.73,447Z',
    attr: {
      id: '615',
      'data-name': '615',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 615,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _616 = {
    path: 'M5278.95,471.35V913.879l339.45-.015V521.649Q5451.36,489.89,5278.95,471.35Z',
    attr: {
      id: '616',
      'data-name': '616',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 616,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _617 = {
    path: 'M5618.4,521.65V913.865l336.19-.015,32.9,6.141,58.32-293.3A4128.225,4128.225,0,0,0,5618.4,521.65Z',
    attr: {
      id: '617',
      'data-name': '617',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 617,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _618 = {
    path: 'M6045.78,626.7l-58.33,293.295,416.81,77.8,44.77-224.947A4017.281,4017.281,0,0,0,6045.78,626.7Z',
    attr: {
      id: '618',
      'data-name': '618',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 618,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _619 = {
    path: 'M6449.02,772.85,6404.25,997.8l168.98,31.543,33.37-185.49Q6528.93,806.773,6449.02,772.85Z',
    attr: {
      id: '619',
      'data-name': '619',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 619,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _634 = {
    path: 'M6573.08,6660.25l-154.68,28.87,44.11,221.8q72.885-31.26,143.93-65.18Z',
    attr: {
      id: '634',
      'data-name': '634',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 634,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _635 = {
    path: 'M6418.37,6689.1l-415.72,77.6,57.98,291.57a4013.944,4013.944,0,0,0,401.85-147.37Z',
    attr: {
      id: '635',
      'data-name': '635',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 635,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _636 = {
    path: 'M6002.68,6766.7l-48.29,9.01-321.79-.01v389.45a4126.707,4126.707,0,0,0,428.07-106.88Z',
    attr: {
      id: '636',
      'data-name': '636',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 636,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _637 = {
    path: 'M5295.8,6775.7v440.67q170.955-18.99,336.8-51.2V6775.71Z',
    attr: {
      id: '637',
      'data-name': '637',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 637,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _638 = {
    path: 'M4819.45,6775.7v466.94h.08a4313.938,4313.938,0,0,0,476.27-26.25V6775.72Z',
    attr: {
      id: '638',
      'data-name': '638',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 638,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _639 = {
    path: 'M4344.6,6775.65v440.86a4313.254,4313.254,0,0,0,474.85,26.1V6775.67Z',
    attr: {
      id: '639',
      'data-name': '639',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 639,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _640 = {
    path: 'M4015.65,6775.65v391.29q161.94,31,328.95,49.59V6775.66Z',
    attr: {
      id: '640',
      'data-name': '640',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 640,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _641 = {
    path: 'M3648.76,6763.05l-59.36,298.62a4128.279,4128.279,0,0,0,426.25,105.28V6775.66l-299.56-.02Z',
    attr: {
      id: '641',
      'data-name': '641',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 641,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _642 = {
    path: 'M3232.18,6685.15l-45.78,230.01a4017.321,4017.321,0,0,0,402.98,146.53l59.36-298.62Z',
    attr: {
      id: '642',
      'data-name': '642',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 642,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };
  
  var _643 = {
    path: 'M3068.59,6654.55l-.08.05h0L3034,6846.43q75.165,35.85,152.42,68.74l45.78-230.02Z',
    attr: {
      id: '643',
      'data-name': '643',
      fill: '#2196f3',
      opacity: '0',
      stroke: '#fafafa',
      'stroke-width': '4',
      'stroke-opacity': '1'
    },
    data: {
      zoneNo: 643,
      zone: 'Upper Zone',
      zoneId: 1
    }
  };

  zones.push(
    _106,
    _107,
    _108,
    _109,
    _110,
    _111,
    _112,
    _113,
    _114,
    _115,
    _116,
    _117,
    _118,
    _119,
    _120,
    _121,
    _122,
    _123,
    _124,
    _125,
    _126,
    _127,
    _128,
    _129,
    _130,
    _131,
    _132,
    _133,
    _134,
    _135,
    _136,
    _137,
    _138,
    _139,
    _140,
    _141,
    _142,
    _143,
    _144,
    _145,
    _146,
    _147,
    _224,
    _225,
    _226,
    _227,
    _228,
    _235,
    _236,
    _237,
    _238,
    _239,
    _240,
    _241,
    _242,
    _243,
    _308,
    _309,
    _310,
    _311,
    _312,
    _313,
    _314,
    _315,
    _316,
    _317,
    _318,
    _319,
    _320,
    _510,
    _511,
    _512,
    _513,
    _514,
    _515,
    _516,
    _517,
    _518,
    _519,
    _520,
    _521,
    _522,
    _523,
    _530,
    _531,
    _532,
    _533,
    _534,
    _535,
    _536,
    _537,
    _538,
    _539,
    _540,
    _541,
    _542,
    _543,
    _610,
    _611,
    _612,
    _613,
    _614,
    _615,
    _616,
    _617,
    _618,
    _619,
    _634,
    _635,
    _636,
    _637,
    _638,
    _639,
    _640,
    _641,
    _642,
    _643,
  );

  return zones;
};
