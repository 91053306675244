import React, { Component } from "react";
import moment from "moment-timezone";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { calculateGST } from "../libs/shared/gst-helpers";
import { renderZoneName, renderRowName } from "../libs/shared/seat-helpers";
import { getFormattedTime } from "../libs/shared/grid-helper";
import { getGameName } from "../libs/shared/team-helper";

const styles = (theme) => ({
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    //fontSize: "14px"
  },
  paper: {
    padding: "1.5rem",
    maxHeight: "60vh",
    overflow: "auto",
  },
});

class PendingSeatsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      games: null,
    };
  }

  componentWillMount() {
    const { games } = this.props;

    this.setState({ games });
  }

  componentWillReceiveProps(nextProps) {
    const { games } = nextProps;

    this.setState({ games });
  }

  getGameDate(game) {
    return moment.tz(game.date, game.timezone).format("MMM D");
  }

  getGameTime(game) {
    return getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);
  }

  renderTable(games) {
    const { classes } = this.props;

    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow style={{ fontFamily: "Nunito Sans" }}>
              <TableCell style={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Game
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seat Details
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Price (CAD){" "}
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Qty{" "}
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Proceeds
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {games.map((game, index) => {
              const slug = `${game.homeTeamSlug}-tickets`;
              return (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: "10px 24px" }}
                  >
                    <Typography
                      className={classes.titleText}
                      style={{ color: "#00A0FF", textTransform: "uppercase" }}
                    >
                      {game.isTbd ? "TBD" : this.getGameDate(game)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {game.isTbd ? null : this.getGameTime(game)}
                    </Typography>
                    <a href={`/seats/${slug}/${game.id}`}>
                      <Typography className={classes.titleText}>
                        {getGameName(game)}
                      </Typography>
                    </a>
                  </TableCell>

                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.titleText}>
                      {renderZoneName(game.section, game.sectionNo, game.row, game.homeTeamSlug, game.isSpecial)}
                    </Typography>
                    <Typography className={classes.subTitleText}>
                      Row {renderRowName(game.section, game.row, game.homeTeamSlug, game.isSpecial)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    <Typography className={classes.subTitleText}>
                      ${parseFloat(game.price).toFixed(2)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    <Typography className={classes.subTitleText}>
                      {game.noOfSeats}
                    </Typography>
                  </TableCell>

                  {/* {!isSoldOut && (
                  <TableCell padding="checkbox" numeric>
                    {seat.seatsSold}
                  </TableCell>
                )} */}

                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    {!game.isArchived ? (
                      <Typography className={classes.subTitleText}>
                        $
                        {calculateGST(
                          game.noOfSeats,
                          parseFloat(game.price)
                        ).toFixed(2)}
                        {/* {(
                          game.noOfSeats *
                          parseFloat(game.price) *
                          0.91
                        ).toFixed(2)} */}
                      </Typography>
                    ) : (
                      <Typography className={classes.subTitleText}>
                        -
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    const { games } = this.state;

    return (
      <React.Fragment>
        {games.length > 0 ? (
          this.renderTable(games)
        ) : (
          <Typography
            style={{ margin: "2rem 0" }}
            variant="subheading"
            align="center"
            color="textSecondary"
          >
            No Pending for Approval
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PendingSeatsTable);
