import React, { Component } from "react";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import _ from "lodash";
import moment from "moment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import UpcomingOrdersTable from "../components/UpcomingOrdersTable";
import PastOrdersTable from "../components/PastOrdersTable";

const styles = (theme) => ({
  stickyTabs: {
    position: "-webkit-sticky",
    position: "sticky",
    backgroundColor: "#fafafa",
    zIndex: "1",
    top: "110px",
    "@media (max-width: 959px)": {
      top: "148px",
    },
  },
  header: {
    position: "-webkit-sticky",
    position: "sticky",
    top: "0px",
    backgroundColor: "#fafafa",
    zIndex: "1",
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  indicator: {
    backgroundColor: "#00a0ff",
    height: "5px",
    borderRadius: "25px 25px 0px 0px",
  },
  [theme.breakpoints.up("md")]: {
    header: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
      position: "-webkit-sticky",
      position: "sticky",
      top: "0px",
      backgroundColor: "#fafafa",
    },
    tabs: {
      borderBottom: "1px solid #D5DEE9",
      fontFamily: "Nunito Sans",
      "& button": {
        minWidth: 150,
        fontFamily: "Nunito Sans",
        fontWeight: "600",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    header: {
      paddingTop: "6rem",
      paddingRight: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
    },
    flexContainer: {
      justifyContent: "space-around",
    },
    tabs: {
      borderBottom: "1px solid #D5DEE9",
      "& button": {
        minWidth: 100,
      },
    },
    main: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    }
  },
});

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "upcoming",
      isLoading: true,
      upcomingOrders: null,
      lastEvaluatedKey: null,
      loadMoreLoading: false,
      isRefunded: "false",
      limit: 20,
    };

    this.setUpcomingOrders = this.setUpcomingOrders.bind(this);
  }

  componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.currUser) {
      this.props.history.push("/login");
    }
    if (this.props.currUser && !this.props.currUser.email_verified) {
      this.props.history.push(`/email-verification`);
    }
    
    this.setUpcomingOrders()
    .then(() => {
      if (this.state.upcomingOrders && this.state.upcomingOrders.length === 0) {
        this.setState({ isLoading: false });
      }
    });
  }

  currentDateString() {
    const parseDateTz = moment.tz('America/Edmonton').startOf('day').valueOf();
    return parseDateTz;
  }
  
  async getOrders() {
    const { currUser } = this.props;
    const { lastEvaluatedKey, isRefunded, limit } = this.state;
  
    const params = [];
    if(currUser){
      params.push(`buyerEmail=${encodeURIComponent(currUser.email.toLowerCase().replace(/\s+/g, ''))}`);
      params.push('gameStartDate=' + this.currentDateString());
      params.push('isRefunded=' + isRefunded);
      params.push('limit=' + limit);
      const queryString = params.join('&');
    
      try {
        const response = await API.get(
          "v2",
          `marketplace/orders/search?${queryString}${lastEvaluatedKey ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}` : ""}`
        );
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  }

  async setUpcomingOrders(tab = "upcoming", lastKey = null) {
    const ordersData = await this.getOrders();
    const upcomingLists = ordersData.orders;
    const sortedUpcoming = _.chain(upcomingLists)
    .filter(
      (order) => !order.isRefunded
    )
    .orderBy("game.date", "asc")
    .value();

    const {
      upcomingOrders: upcomingOrdersState,
      lastEvaluatedKey,
    } = this.state;

    // Reset the state if lastKey is null
    if (lastKey === null) {
      this.setState({
        upcomingOrders: [],
        lastEvaluatedKey: null,
      });
    }

    const isUpcoming = tab === "upcoming";
    const preUpcomingOrderState = upcomingOrdersState ? upcomingOrdersState : [];

    this.setState({
      upcomingOrders: [...preUpcomingOrderState, ...sortedUpcoming],
      isLoading: false,
      loadMoreLoading: false,
      lastEvaluatedKey: isUpcoming ? ordersData.lastEvaluatedKey : lastEvaluatedKey,
    });
  }
  
  renderMain() {
    const { tab } = this.state;
    const { classes } = this.props;
    return (
      <Grid container className={classes.main}>
        <Grid item xs={12} className={classes.stickyTabs}>
          <Tabs
            value={tab}
            variant="fullWidth"
            onChange={(event, newValue) => {
              if (newValue === "upcoming" && this.state.tab === "upcoming") {
                this.setState({ tab: newValue });
              } else {
                this.setState({ tab: newValue });
                if (newValue === "upcoming") {
                  this.setState({
                    ...this.state,
                    tab: newValue,
                    isLoading: true,
                    upcomingOrders: null,
                    lastEvaluatedKey: null,
                    loadMoreLoading: false,
                  });
                  this.setUpcomingOrders(newValue, null);
                }
              }
            }}
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
              flexContainer: classes.flexContainer,
            }}
          >
            <Tab
              value="upcoming"
              label={
                <Typography
                  style={{
                    fontWeight: "600",
                    color: tab === "upcoming" ? "#00A0FF" : "#5F666F",
                    fontSize: "18px",
                  }}
                >
                  Upcoming
                </Typography>
              }
              style={{
                textTransform: "none",
                color: tab === "upcoming" ? "#00A0FF" : "#5F666F",
                fontSize: "1rem",
              }}
            />
            <Tab
              value="past"
              label={
                <Typography
                  style={{
                    fontWeight: "600",
                    color: tab === "past" ? "#00A0FF" : "#5F666F",
                    fontSize: "18px",
                  }}
                >
                  Past
                </Typography>
              }
              style={{
                textTransform: "none",
                color: tab === "past" ? "#00A0FF" : "#5F666F",
              }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} md={12}>
          {this.renderOrders()}
        </Grid>
      </Grid>
    );
  }

  renderOrders() {
    const {
      isLoading,
      upcomingOrders,
      tab,
      lastEvaluatedKey,
      loadMoreLoading,
    } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <Typography
            align="center"
            variant="body2"
            style={{ marginTop: "2rem" }}
          > 
            <CircularProgress
              color="inherit"
              style={{ width: 16, height: 16, marginRight: 16 }}
            />
            Loading your {tab} orders...
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {tab === "upcoming" && (
          <UpcomingOrdersTable
            orders={upcomingOrders ? upcomingOrders : null}
          />
        )}
        {tab === "past" && (
          <PastOrdersTable
            currUser={this.props.currUser}
          />
        )}

        {tab === "upcoming" && lastEvaluatedKey && (
          <Button
            onClick={() => {
              this.setState({ ...this.state, loadMoreLoading: true });
              this.setUpcomingOrders();
            }}
            disabled={isLoading || loadMoreLoading}
            variant="contained"
            style={{ marginTop: "16px", marginLeft: "25%", width: "250px" }}
            color="secondary"
          >
            {isLoading || loadMoreLoading
              ? "Loading..."
              : "Load More"}
          </Button>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const currentURL = window.location.href;
    return (
      <React.Fragment>
        <Helmet>
          <title>Orders</title>
          <meta name="description" content="Ticket Orders by buyers" />
          <meta name="keywords" content={`orders, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Orders" />
          <meta property="og:description" content="Ticket Orders by buyers" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Grid container justify="center">
          <Grid item xs={12} md={10} className={classes.header}>
            <Typography
              variant="headline"
              color="primary"
              className={classes.titleText}
            >
              Orders
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            {this.renderMain()}
          </Grid>
        </Grid>        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Orders);
