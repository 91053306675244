import React from "react";
import { withStyles } from "@material-ui/core";
import MediaQuery from "react-responsive";

import DesktopNav from "./desktop";
import MobileNav from "./mobile";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing * 2,
  },
});

const nav = ({ isAuthenticated, currUser, handleLogout, ...props }) => {
  return (
    <React.Fragment>
      <MediaQuery minWidth={960}>
        <DesktopNav
          isAuthenticated={isAuthenticated}
          currUser={currUser}
          handleLogout={handleLogout}
          {...props}
        />
      </MediaQuery>
      <MediaQuery maxWidth={959}>
        <MobileNav
          isAuthenticated={isAuthenticated}
          currUser={currUser}
          handleLogout={handleLogout}
          style={{
            backgroundColor: "#2faaf3",
            width: "300px",
          }}
        />
      </MediaQuery>
    </React.Fragment>
  );
};

export default withStyles(styles)(nav);
