export const getZones = rsr => {
  let zones = [];
  var _101 = {
    path:
      'M 440.00,440.00 C 440.00,440.00 443.42,458.00 443.42,458.00 443.42,458.00 449.15,504.00 449.15,504.00 449.15,504.00 462.16,608.00 462.16,608.00 462.16,608.00 467.00,649.00 467.00,649.00 467.00,649.00 442.00,655.13 442.00,655.13 442.00,655.13 413.00,662.42 413.00,662.42 409.52,663.39 396.27,667.86 393.60,665.94 391.63,664.52 390.82,653.80 390.42,651.00 390.42,651.00 385.00,605.00 385.00,605.00 385.00,605.00 333.00,616.00 333.00,616.00 333.00,616.00 333.00,595.00 333.00,595.00 333.00,595.00 334.00,580.00 334.00,580.00 334.00,580.00 334.00,564.00 334.00,564.00 334.00,564.00 335.00,549.00 335.00,549.00 335.00,549.00 335.00,533.00 335.00,533.00 335.00,533.00 336.00,518.00 336.00,518.00 336.00,518.00 336.00,502.00 336.00,502.00 336.00,502.00 337.00,487.00 337.00,487.00 337.00,487.00 337.00,477.00 337.00,477.00 337.00,477.00 338.02,469.51 338.02,469.51 338.02,469.51 345.00,466.29 345.00,466.29 345.00,466.29 361.00,462.00 361.00,462.00 361.00,462.00 440.00,440.00 440.00,440.00 Z',
    attr: {
      id: '_101',
      'data-name': '101',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "101", zone: 'Lower Zone', zoneId: "1" },
  };

  var _102 = {
    path:
      'M 458.00,438.00 C 458.00,438.00 591.00,438.00 591.00,438.00 591.00,438.00 591.00,646.00 591.00,646.00 591.00,646.00 484.00,646.00 484.00,646.00 484.00,646.00 480.28,615.00 480.28,615.00 480.28,615.00 473.15,558.00 473.15,558.00 473.15,558.00 463.28,479.00 463.28,479.00 463.28,479.00 458.00,438.00 458.00,438.00 Z',
    attr: {
      id: '_102',
      'data-name': '102',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "102", zone: 'Lower Zone', zoneId: "1" },
  };

  var _103 = {
    path:
      'M 608.00,438.00 C 608.00,438.00 752.00,438.00 752.00,438.00 752.00,438.00 752.00,646.00 752.00,646.00 752.00,646.00 608.00,646.00 608.00,646.00 608.00,646.00 608.00,438.00 608.00,438.00 Z',
    attr: {
      id: '_103',
      'data-name': '103',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "103", zone: 'Lower Zone', zoneId: "1" },
  };

  var _104 = {
    path:
      'M 769.00,438.00 C 769.00,438.00 832.00,438.00 832.00,438.00 832.00,438.00 849.00,437.00 849.00,437.00 849.00,437.00 915.00,437.00 915.00,437.00 915.00,437.00 915.00,645.00 915.00,645.00 915.00,645.00 821.00,645.00 821.00,645.00 821.00,645.00 806.00,646.00 806.00,646.00 806.00,646.00 769.00,646.00 769.00,646.00 769.00,646.00 769.00,438.00 769.00,438.00 Z',
    attr: {
      id: '_104',
      'data-name': '104',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "104", zone: 'Lower Zone', zoneId: "1" },
  };

  var _105 = {
    path:
      'M 932.00,437.00 C 932.00,437.00 1071.00,437.00 1071.00,437.00 1071.00,437.00 1071.00,644.00 1071.00,644.00 1071.00,644.00 1052.00,643.00 1052.00,643.00 1052.00,643.00 1030.00,643.00 1030.00,643.00 1030.00,643.00 1030.00,612.00 1030.00,612.00 1030.00,612.00 1029.00,597.00 1029.00,597.00 1029.00,597.00 1029.00,581.00 1029.00,581.00 1029.00,581.00 999.00,581.00 999.00,581.00 999.00,581.00 987.00,580.04 987.00,580.04 987.00,580.04 974.00,580.04 974.00,580.04 974.00,580.04 974.00,645.00 974.00,645.00 974.00,645.00 932.00,645.00 932.00,645.00 932.00,645.00 932.00,437.00 932.00,437.00 Z',
    attr: {
      id: '_105',
      'data-name': '105',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "105", zone: 'Lower Zone', zoneId: "1" },
  };

  var _106 = {
    path:
      'M 1088.00,437.00 C 1088.00,437.00 1232.00,437.00 1232.00,437.00 1232.00,437.00 1232.00,644.00 1232.00,644.00 1232.00,644.00 1088.00,644.00 1088.00,644.00 1088.00,644.00 1088.00,437.00 1088.00,437.00 Z',
    attr: {
      id: '_106',
      'data-name': '106',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "106", zone: 'Lower Zone', zoneId: "1" },
  };

  var _107 = {
    path:
      'M 1249.00,437.00 C 1249.00,437.00 1390.00,437.00 1390.00,437.00 1390.00,437.00 1390.00,645.00 1390.00,645.00 1390.00,645.00 1365.00,645.00 1365.00,645.00 1365.00,645.00 1348.00,644.00 1348.00,644.00 1348.00,644.00 1249.00,644.00 1249.00,644.00 1249.00,644.00 1249.00,437.00 1249.00,437.00 Z',
    attr: {
      id: '_107',
      'data-name': '107',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "107", zone: 'Lower Zone', zoneId: "1" },
  };

  var _108 = {
    path:
      'M 1407.00,437.00 C 1407.00,437.00 1545.00,437.00 1545.00,437.00 1545.00,437.00 1538.28,489.00 1538.28,489.00 1538.28,489.00 1530.72,547.00 1530.72,547.00 1530.72,547.00 1523.85,597.00 1523.85,597.00 1523.85,597.00 1519.28,630.00 1519.28,630.00 1519.28,630.00 1516.98,643.40 1516.98,643.40 1516.98,643.40 1509.00,645.00 1509.00,645.00 1509.00,645.00 1407.00,645.00 1407.00,645.00 1407.00,645.00 1407.00,437.00 1407.00,437.00 Z',
    attr: {
      id: '_108',
      'data-name': '108',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "108", zone: 'Lower Zone', zoneId: "1" },
  };

  var _109F = {
    path:
      'M 1562.00,439.00 C 1562.00,439.00 1605.00,449.58 1605.00,449.58 1605.00,449.58 1661.00,464.15 1661.00,464.15 1661.00,464.15 1704.00,475.00 1704.00,475.00 1704.00,475.00 1698.42,498.00 1698.42,498.00 1698.42,498.00 1687.71,537.00 1687.71,537.00 1687.71,537.00 1663.58,625.00 1663.58,625.00 1663.58,625.00 1650.00,675.00 1650.00,675.00 1650.00,675.00 1584.00,658.87 1584.00,658.87 1584.00,658.87 1568.00,654.87 1568.00,654.87 1568.00,654.87 1562.03,652.28 1562.03,652.28 1562.03,652.28 1562.87,638.00 1562.87,638.00 1562.87,638.00 1567.42,606.00 1567.42,606.00 1567.42,606.00 1567.96,592.30 1567.96,592.30 1567.96,592.30 1561.00,588.66 1561.00,588.66 1561.00,588.66 1543.00,583.00 1543.00,583.00 1543.00,583.00 1546.28,558.00 1546.28,558.00 1546.28,558.00 1555.15,492.00 1555.15,492.00 1555.15,492.00 1562.00,439.00 1562.00,439.00 Z',
    attr: {
      id: '_109F',
      'data-name': '109F',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "109", zone: 'Lower Zone', zoneId: "1" },
  };

  var _111 = {
    path:
      'M 1566.00,1581.00 C 1566.00,1581.00 1558.85,1528.00 1558.85,1528.00 1558.85,1528.00 1546.58,1434.00 1546.58,1434.00 1546.58,1434.00 1541.72,1396.00 1541.72,1396.00 1541.72,1396.00 1541.03,1382.72 1541.03,1382.72 1541.03,1382.72 1549.00,1379.42 1549.00,1379.42 1549.00,1379.42 1569.00,1374.00 1569.00,1374.00 1569.00,1374.00 1621.00,1359.58 1621.00,1359.58 1621.00,1359.58 1655.00,1351.00 1655.00,1351.00 1655.00,1351.00 1661.12,1375.00 1661.12,1375.00 1661.12,1375.00 1674.42,1424.00 1674.42,1424.00 1674.42,1424.00 1681.00,1451.00 1681.00,1451.00 1681.00,1451.00 1662.00,1458.58 1662.00,1458.58 1662.00,1458.58 1654.34,1463.27 1654.34,1463.27 1654.34,1463.27 1654.34,1471.00 1654.34,1471.00 1654.34,1471.00 1658.35,1488.00 1658.35,1488.00 1658.35,1488.00 1667.42,1529.00 1667.42,1529.00 1667.42,1529.00 1672.00,1553.00 1672.00,1553.00 1672.00,1553.00 1644.00,1560.12 1644.00,1560.12 1644.00,1560.12 1589.00,1574.88 1589.00,1574.88 1589.00,1574.88 1566.00,1581.00 1566.00,1581.00 Z',
    attr: {
      id: '_111',
      'data-name': '111',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "111", zone: 'Lower Zone', zoneId: "1" },
  };

  var _112 = {
    path:
      'M 1414.00,1384.00 C 1414.00,1384.00 1488.00,1384.00 1488.00,1384.00 1488.00,1384.00 1503.00,1385.00 1503.00,1385.00 1503.00,1385.00 1523.00,1385.00 1523.00,1385.00 1523.00,1385.00 1525.72,1406.00 1525.72,1406.00 1525.72,1406.00 1530.72,1445.00 1530.72,1445.00 1530.72,1445.00 1542.28,1534.00 1542.28,1534.00 1542.28,1534.00 1549.00,1590.00 1549.00,1590.00 1549.00,1590.00 1414.00,1590.00 1414.00,1590.00 1414.00,1590.00 1414.00,1384.00 1414.00,1384.00 Z',
    attr: {
      id: '_112',
      'data-name': '112',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "112", zone: 'Lower Zone', zoneId: "1" },
  };

  var _113 = {
    path:
      'M 1253.00,1384.00 C 1253.00,1384.00 1397.00,1384.00 1397.00,1384.00 1397.00,1384.00 1397.00,1590.00 1397.00,1590.00 1397.00,1590.00 1253.00,1590.00 1253.00,1590.00 1253.00,1590.00 1253.00,1384.00 1253.00,1384.00 Z',
    attr: {
      id: '_113',
      'data-name': '113',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "113", zone: 'Lower Zone', zoneId: "1" },
  };

  var _114 = {
    path:
      'M 1091.00,1383.00 C 1091.00,1383.00 1136.00,1383.00 1136.00,1383.00 1136.00,1383.00 1153.00,1384.00 1153.00,1384.00 1153.00,1384.00 1236.00,1384.00 1236.00,1384.00 1236.00,1384.00 1236.00,1590.00 1236.00,1590.00 1236.00,1590.00 1091.00,1590.00 1091.00,1590.00 1091.00,1590.00 1091.00,1383.00 1091.00,1383.00 Z',
    attr: {
      id: '_114',
      'data-name': '114',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "114", zone: 'Lower Zone', zoneId: "1" },
  };

  var _115 = {
    path:
      'M 931.00,1383.00 C 931.00,1383.00 1074.00,1383.00 1074.00,1383.00 1074.00,1383.00 1074.00,1591.00 1074.00,1591.00 1074.00,1591.00 931.00,1591.00 931.00,1591.00 931.00,1591.00 931.00,1383.00 931.00,1383.00 Z',
    attr: {
      id: '_115',
      'data-name': '115',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "115", zone: 'Lower Zone', zoneId: "1" },
  };

  var _116 = {
    path:
      'M 768.00,1382.00 C 768.00,1382.00 786.00,1382.00 786.00,1382.00 786.00,1382.00 802.00,1383.00 802.00,1383.00 802.00,1383.00 914.00,1383.00 914.00,1383.00 914.00,1383.00 914.00,1591.00 914.00,1591.00 914.00,1591.00 768.00,1591.00 768.00,1591.00 768.00,1591.00 768.00,1382.00 768.00,1382.00 Z',
    attr: {
      id: '_116',
      'data-name': '116',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "116", zone: 'Lower Zone', zoneId: "1" },
  };

  var _117 = {
    path:
      'M 607.00,1382.00 C 607.00,1382.00 751.00,1382.00 751.00,1382.00 751.00,1382.00 751.00,1591.00 751.00,1591.00 751.00,1591.00 607.00,1591.00 607.00,1591.00 607.00,1591.00 607.00,1382.00 607.00,1382.00 Z',
    attr: {
      id: '_117',
      'data-name': '117',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "117", zone: 'Lower Zone', zoneId: "1" },
  };

  var _118 = {
    path:
      'M 454.00,1591.00 C 454.00,1591.00 462.58,1530.00 462.58,1530.00 462.58,1530.00 477.41,1430.00 477.41,1430.00 477.41,1430.00 482.27,1397.00 482.27,1397.00 482.27,1397.00 485.01,1383.60 485.01,1383.60 485.01,1383.60 493.00,1382.00 493.00,1382.00 493.00,1382.00 590.00,1382.00 590.00,1382.00 590.00,1382.00 590.00,1591.00 590.00,1591.00 590.00,1591.00 454.00,1591.00 454.00,1591.00 Z',
    attr: {
      id: '_118',
      'data-name': '118',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "118", zone: 'Lower Zone', zoneId: "1" },
  };

  var _119 = {
    path:
      'M 336.00,1344.00 C 336.00,1344.00 375.00,1353.85 375.00,1353.85 375.00,1353.85 435.00,1370.15 435.00,1370.15 435.00,1370.15 468.00,1379.00 468.00,1379.00 468.00,1379.00 455.73,1459.00 455.73,1459.00 455.73,1459.00 438.00,1578.00 438.00,1578.00 438.00,1578.00 362.00,1560.12 362.00,1560.12 362.00,1560.12 335.00,1554.00 335.00,1554.00 335.33,1546.83 339.31,1531.62 341.12,1524.00 341.12,1524.00 349.88,1485.00 349.88,1485.00 349.88,1485.00 355.39,1459.04 355.39,1459.04 354.75,1453.46 346.78,1452.94 343.48,1450.01 340.28,1447.16 341.04,1440.94 341.00,1437.00 341.00,1437.00 340.00,1427.00 340.00,1427.00 340.00,1427.00 340.00,1417.00 340.00,1417.00 340.00,1417.00 339.00,1402.00 339.00,1402.00 339.00,1402.00 338.00,1388.00 338.00,1388.00 338.00,1388.00 337.00,1372.00 337.00,1372.00 337.00,1372.00 336.00,1344.00 336.00,1344.00 Z',
    attr: {
      id: '_119',
      'data-name': '119',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "119", zone: 'Lower Zone', zoneId: "1" },
  };

  var _202 = {
    path:
      'M 490.00,178.00 C 490.00,178.00 590.00,178.00 590.00,178.00 590.00,178.00 590.00,336.00 590.00,336.00 590.00,336.00 490.00,336.00 490.00,336.00 490.00,336.00 490.00,178.00 490.00,178.00 Z',
    attr: {
      id: '_202',
      'data-name': '202',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "202", zone: 'Upper Zone', zoneId: "2" },
  };

  var _203 = {
    path:
      'M 607.00,178.00 C 607.00,178.00 748.00,178.00 748.00,178.00 748.00,178.00 748.00,336.00 748.00,336.00 748.00,336.00 607.00,336.00 607.00,336.00 607.00,336.00 607.00,178.00 607.00,178.00 Z',
    attr: {
      id: '_203',
      'data-name': '203',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "203", zone: 'Upper Zone', zoneId: "2" },
  };

  var _204 = {
    path:
      'M 765.00,178.00 C 765.00,178.00 912.00,178.00 912.00,178.00 912.00,178.00 912.00,336.00 912.00,336.00 912.00,336.00 765.00,336.00 765.00,336.00 765.00,336.00 765.00,178.00 765.00,178.00 Z',
    attr: {
      id: '_204',
      'data-name': '204',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "204", zone: 'Upper Zone', zoneId: "2" },
  };

  var _205 = {
    path:
      'M 929.00,178.00 C 929.00,178.00 1069.00,178.00 1069.00,178.00 1069.00,178.00 1069.00,336.00 1069.00,336.00 1069.00,336.00 929.00,336.00 929.00,336.00 929.00,336.00 929.00,178.00 929.00,178.00 Z',
    attr: {
      id: '_205',
      'data-name': '205',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "205", zone: 'Upper Zone', zoneId: "2" },
  };

  var _206 = {
    path:
      'M 1086.00,178.00 C 1086.00,178.00 1233.00,178.00 1233.00,178.00 1233.00,178.00 1233.00,336.00 1233.00,336.00 1233.00,336.00 1086.00,336.00 1086.00,336.00 1086.00,336.00 1086.00,178.00 1086.00,178.00 Z',
    attr: {
      id: '_206',
      'data-name': '206',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "206", zone: 'Upper Zone', zoneId: "2" },
  };

  var _207 = {
    path:
      'M 1250.00,178.00 C 1250.00,178.00 1392.00,178.00 1392.00,178.00 1392.00,178.00 1392.00,336.00 1392.00,336.00 1392.00,336.00 1250.00,336.00 1250.00,336.00 1250.00,336.00 1250.00,178.00 1250.00,178.00 Z',
    attr: {
      id: '_207',
      'data-name': '207',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "207", zone: 'Upper Zone', zoneId: "2" },
  };

  var _208 = {
    path:
      'M 1409.00,178.00 C 1409.00,178.00 1514.00,178.00 1514.00,178.00 1514.00,178.00 1514.00,336.00 1514.00,336.00 1514.00,336.00 1409.00,336.00 1409.00,336.00 1409.00,336.00 1409.00,178.00 1409.00,178.00 Z',
    attr: {
      id: '_208',
      'data-name': '208',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "208", zone: 'Upper Zone', zoneId: "2" },
  };

  var _212 = {
    path:
      'M 1409.00,1606.00 C 1409.00,1606.00 1513.00,1606.00 1513.00,1606.00 1513.00,1606.00 1513.00,1840.00 1513.00,1840.00 1513.00,1840.00 1488.00,1840.00 1488.00,1840.00 1488.00,1840.00 1471.00,1841.00 1471.00,1841.00 1471.00,1841.00 1409.00,1841.00 1409.00,1841.00 1409.00,1841.00 1409.00,1606.00 1409.00,1606.00 Z',
    attr: {
      id: '_212',
      'data-name': '212',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "212", zone: 'Upper Zone', zoneId: "2" },
  };

  var _213 = {
    path:
      'M 1251.00,1606.00 C 1251.00,1606.00 1392.00,1606.00 1392.00,1606.00 1392.00,1606.00 1392.00,1841.00 1392.00,1841.00 1392.00,1841.00 1251.00,1841.00 1251.00,1841.00 1251.00,1841.00 1251.00,1606.00 1251.00,1606.00 Z',
    attr: {
      id: '_213',
      'data-name': '213',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "213", zone: 'Upper Zone', zoneId: "2" },
  };

  var _214 = {
    path:
      'M 1089.00,1607.00 C 1089.00,1607.00 1191.00,1607.00 1191.00,1607.00 1191.00,1607.00 1206.00,1606.00 1206.00,1606.00 1206.00,1606.00 1234.00,1606.00 1234.00,1606.00 1234.00,1606.00 1234.00,1841.00 1234.00,1841.00 1234.00,1841.00 1147.00,1841.00 1147.00,1841.00 1147.00,1841.00 1132.00,1842.00 1132.00,1842.00 1132.00,1842.00 1089.00,1842.00 1089.00,1842.00 1089.00,1842.00 1089.00,1607.00 1089.00,1607.00 Z',
    attr: {
      id: '_214',
      'data-name': '214',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "214", zone: 'Upper Zone', zoneId: "2" },
  };

  var _215 = {
    path:
      'M 931.00,1607.00 C 931.00,1607.00 1072.00,1607.00 1072.00,1607.00 1072.00,1607.00 1072.00,1842.00 1072.00,1842.00 1072.00,1842.00 931.00,1842.00 931.00,1842.00 931.00,1842.00 931.00,1607.00 931.00,1607.00 Z',
    attr: {
      id: '_215',
      'data-name': '215',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "215", zone: 'Upper Zone', zoneId: "2" },
  };

  var _216 = {
    path:
      'M 767.00,1607.00 C 767.00,1607.00 914.00,1607.00 914.00,1607.00 914.00,1607.00 914.00,1842.00 914.00,1842.00 914.00,1842.00 806.00,1842.00 806.00,1842.00 806.00,1842.00 791.00,1843.00 791.00,1843.00 791.00,1843.00 767.00,1843.00 767.00,1843.00 767.00,1843.00 767.00,1607.00 767.00,1607.00 Z',
    attr: {
      id: '_216',
      'data-name': '216',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "216", zone: 'Upper Zone', zoneId: "2" },
  };

  var _217 = {
    path:
      'M 610.00,1607.00 C 610.00,1607.00 750.00,1607.00 750.00,1607.00 750.00,1607.00 750.00,1843.00 750.00,1843.00 750.00,1843.00 610.00,1843.00 610.00,1843.00 610.00,1843.00 610.00,1607.00 610.00,1607.00 Z',
    attr: {
      id: '_217',
      'data-name': '217',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "217", zone: 'Upper Zone', zoneId: "2" },
  };

  var _218 = {
    path:
      'M 489.00,1607.00 C 489.00,1607.00 593.00,1607.00 593.00,1607.00 593.00,1607.00 593.00,1843.00 593.00,1843.00 593.00,1843.00 490.00,1843.00 490.00,1843.00 490.00,1843.00 490.00,1829.00 490.00,1829.00 490.00,1829.00 489.00,1814.00 489.00,1814.00 489.00,1814.00 489.00,1607.00 489.00,1607.00 Z',
    attr: {
      id: '_218',
      'data-name': '218',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "218", zone: 'Upper Zone', zoneId: "2" },
  };

  var _C1 = {
    path:
      'M 536.00,353.00 C 536.00,353.00 583.00,353.00 583.00,353.00 583.00,353.00 583.00,423.00 583.00,423.00 583.00,423.00 536.00,423.00 536.00,423.00 536.00,423.00 536.00,353.00 536.00,353.00 Z',
    attr: {
      id: '_C1',
      'data-name': 'C1',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "1", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C2 = {
    path:
      'M 600.00,353.00 C 600.00,353.00 658.00,353.00 658.00,353.00 658.00,353.00 658.00,423.00 658.00,423.00 658.00,423.00 600.00,423.00 600.00,423.00 600.00,423.00 600.00,353.00 600.00,353.00 Z',
    attr: {
      id: '_C2',
      'data-name': 'C2',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "2", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C3 = {
    path:
      'M 675.00,353.00 C 675.00,353.00 720.00,353.00 720.00,353.00 720.00,353.00 720.00,396.00 720.00,396.00 720.00,396.00 721.00,423.00 721.00,423.00 721.00,423.00 675.00,423.00 675.00,423.00 675.00,423.00 675.00,353.00 675.00,353.00 Z',
    attr: {
      id: '_C3',
      'data-name': 'C3',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "3", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C4 = {
    path:
      'M 737.00,353.00 C 737.00,353.00 829.00,353.00 829.00,353.00 829.00,353.00 829.00,422.00 829.00,422.00 829.00,422.00 801.00,422.00 801.00,422.00 801.00,422.00 784.00,423.00 784.00,423.00 784.00,423.00 736.00,423.00 736.00,423.00 736.00,423.00 737.00,396.00 737.00,396.00 737.00,396.00 737.00,353.00 737.00,353.00 Z',
    attr: {
      id: '_C4',
      'data-name': 'C4',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "4", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C5 = {
    path:
      'M 846.00,353.00 C 846.00,353.00 945.00,353.00 945.00,353.00 945.00,353.00 945.00,422.00 945.00,422.00 945.00,422.00 846.00,422.00 846.00,422.00 846.00,422.00 846.00,353.00 846.00,353.00 Z',
    attr: {
      id: '_C5',
      'data-name': 'A',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "5", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C6 = {
    path:
      'M 962.00,353.00 C 962.00,353.00 1054.00,353.00 1054.00,353.00 1054.00,353.00 1054.00,422.00 1054.00,422.00 1054.00,422.00 962.00,422.00 962.00,422.00 962.00,422.00 962.00,353.00 962.00,353.00 Z',
    attr: {
      id: '_C6',
      'data-name': 'C6',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "6", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C7 = {
    path:
      'M 1071.00,353.00 C 1071.00,353.00 1168.00,353.00 1168.00,353.00 1168.00,353.00 1168.00,422.00 1168.00,422.00 1168.00,422.00 1071.00,422.00 1071.00,422.00 1071.00,422.00 1071.00,353.00 1071.00,353.00 Z',
    attr: {
      id: '_C7',
      'data-name': 'C7',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "7", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C8 = {
    path:
      'M 1185.00,353.00 C 1185.00,353.00 1275.00,353.00 1275.00,353.00 1275.00,353.00 1275.00,422.00 1275.00,422.00 1275.00,422.00 1185.00,422.00 1185.00,422.00 1185.00,422.00 1185.00,353.00 1185.00,353.00 Z',
    attr: {
      id: '_C8',
      'data-name': 'C8',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "8", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C9 = {
    path:
      'M 1292.00,353.00 C 1292.00,353.00 1343.00,353.00 1343.00,353.00 1343.00,353.00 1343.00,397.00 1343.00,397.00 1343.00,397.00 1344.00,422.00 1344.00,422.00 1344.00,422.00 1292.00,422.00 1292.00,422.00 1292.00,422.00 1292.00,353.00 1292.00,353.00 Z',
    attr: {
      id: '_C9',
      'data-name': 'C9',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "9", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C10 = {
    path:
      'M 1360.00,353.00 C 1360.00,353.00 1426.00,353.00 1426.00,353.00 1426.00,353.00 1426.00,422.00 1426.00,422.00 1426.00,422.00 1359.00,422.00 1359.00,422.00 1359.00,422.00 1360.00,397.00 1360.00,397.00 1360.00,397.00 1360.00,353.00 1360.00,353.00 Z',
    attr: {
      id: '_C10',
      'data-name': 'C10',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "10", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _C11 = {
    path:
      'M 1443.00,353.00 C 1443.00,353.00 1490.00,353.00 1490.00,353.00 1490.00,353.00 1490.00,422.00 1490.00,422.00 1490.00,422.00 1443.00,422.00 1443.00,422.00 1443.00,422.00 1443.00,353.00 1443.00,353.00 Z',
    attr: {
      id: '_C11',
      'data-name': 'C11',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "11", zone: 'Premium Zone C', zoneId: "3" },
  };

  var _StelcoNorthEnd = {
    path:
      'M 1674.00,738.00 C 1674.00,738.00 1690.00,737.00 1690.00,737.00 1690.00,737.00 1736.00,737.00 1736.00,737.00 1736.00,737.00 1753.00,736.00 1753.00,736.00 1753.00,736.00 1804.00,736.00 1804.00,736.00 1804.00,736.00 1805.00,757.00 1805.00,757.00 1805.00,757.00 1805.00,890.00 1805.00,890.00 1805.00,890.00 1806.00,905.00 1806.00,905.00 1806.00,905.00 1806.00,1061.00 1806.00,1061.00 1806.00,1061.00 1807.00,1076.00 1807.00,1076.00 1807.00,1076.00 1807.00,1143.00 1807.00,1143.00 1807.00,1143.00 1807.00,1257.00 1807.00,1257.00 1807.00,1259.01 1807.24,1262.79 1805.98,1264.41 1803.97,1266.99 1795.16,1267.00 1792.00,1267.00 1792.00,1267.00 1742.00,1267.00 1742.00,1267.00 1742.00,1267.00 1727.00,1268.00 1727.00,1268.00 1727.00,1268.00 1676.00,1268.00 1676.00,1268.00 1676.00,1268.00 1676.00,1253.00 1676.00,1253.00 1676.00,1253.00 1675.00,1237.00 1675.00,1237.00 1675.00,1237.00 1675.00,1066.00 1675.00,1066.00 1675.00,1066.00 1675.00,988.00 1675.00,988.00 1675.00,988.00 1674.00,973.00 1674.00,973.00 1674.00,973.00 1674.00,738.00 1674.00,738.00 Z',
    attr: {
      id: '_StelcoNorthEnd',
      'data-name': 'StelcoNorthEnd',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'Stelco Northend', zoneId: "1" },
  };

  var _TheStepley = {
    path:
      'M 179.00,736.00 C 179.00,736.00 270.00,736.00 270.00,736.00 270.00,736.00 270.00,1268.00 270.00,1268.00 270.00,1268.00 253.00,1268.00 253.00,1268.00 253.00,1268.00 237.00,1267.00 237.00,1267.00 237.00,1267.00 179.00,1267.00 179.00,1267.00 179.00,1267.00 179.00,736.00 179.00,736.00 Z',
    attr: {
      id: '_TheStepley',
      'data-name': 'The Striply',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'The Striply', zoneId: "1" },
  };

  var _TouchDownLounge = {
    path:
      'M 285.65048,1003.5603 V 790.78424 h 27.19777 27.19775 l -2.48979,71.242 c -1.36941,39.18308 -2.49463,134.93233 -2.50054,212.77606 l -0.0114,141.5341 h -24.69721 -24.69723 z',
    attr: {
      id: '_TouchDownLounge',
      'data-name': 'TouchDownLounge',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'Touchdown Lounge', zoneId: "1" },
  };



  zones.push(
    _StelcoNorthEnd,
    _TheStepley,
    _TouchDownLounge,
    _218,
    _217,
    _216,
    _215,
    _214,
    _213,
    _212,
    _119,
    _118,
    _117,
    _116,
    _115,
    _114,
    _113,
    _112,
    _111,
    _208,
    _207,
    _206,
    _205,
    _204,
    _203,
    _202,
    _C11,
    _C10,
    _C9,
    _C8,
    _C7,
    _109F,
    _C6,
    _C5,
    _C4,
    _C3,
    _C2,
    _C1,
    _108,
    _107,
    _106,
    _105,
    _104,
    _103,
    _102,
    _101
  );
  return zones;
};