import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";

const V2Layout = ({
  children,
  currUser,
  isAuthenticated,
  handleLogout,
  ...props
}) => {
  return (
    <React.Fragment>
      <Nav
        isAuthenticated={isAuthenticated}
        currUser={currUser}
        handleLogout={handleLogout}
        {...props}
      />

      {children}
      <Footer />
    </React.Fragment>
  );
};

export default V2Layout;
