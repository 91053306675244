import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogConfirm = ({ title, description, destroy, onConfirm, onCancel }) => {
  const [open, setOpen] = useState(true);

  const handleConfirm = () => {
    setOpen(false);
    destroy();
    onConfirm();
  }

  const handleCancel = () => {
    setOpen(false);
    destroy();
    onCancel();
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleConfirm()} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogConfirm.defaultProps = {
  title: "Confirm",
  description: "",
}

export default DialogConfirm;