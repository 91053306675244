const defaultRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 70; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };
  
  const lowerZoneRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 70; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  
  const clubhouseRows = () => [
    {
      value: '1',
      label: 'Row 1',
    }
  ];

  export default {
    LOWER_ZONE_ROWS: lowerZoneRows(),
    DEFAULT_ROWS: defaultRows(),
    CLUBHOUSE_ROWS: clubhouseRows(),
  };
  