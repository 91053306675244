export const getZones = (rsr) => {
    let zones = [];
  
    var _clubhouse = {
      path:
        "M 175.00,1328.00 C 116.85,1256.89 72.16,1168.73 55.26,1078.00 52.80,1064.77 48.15,1033.70 48.00,1021.00 48.00,1021.00 47.00,1002.00 47.00,1002.00 47.00,1002.00 47.00,986.00 47.00,986.00 47.00,986.00 48.00,968.00 48.00,968.00 48.00,968.00 48.91,958.00 48.91,958.00 50.74,932.98 55.60,906.23 62.15,882.00 76.36,829.48 99.53,781.20 129.67,736.00 145.31,712.54 162.71,690.21 181.28,669.00 181.28,669.00 198.83,650.00 198.83,650.00 198.83,650.00 220.00,628.00 220.00,628.00 224.88,623.12 229.62,617.88 236.00,615.00 236.00,615.00 262.00,649.00 262.00,649.00 262.00,649.00 296.40,693.00 296.40,693.00 296.40,693.00 320.00,724.00 320.00,724.00 260.59,775.58 212.56,846.39 195.42,924.00 191.38,942.32 188.03,962.25 188.00,981.00 188.00,981.00 187.03,995.00 187.03,995.00 187.03,995.00 187.96,1004.00 187.96,1004.00 187.96,1004.00 187.96,1016.00 187.96,1016.00 187.96,1016.00 188.92,1026.00 188.92,1026.00 190.93,1051.20 196.65,1076.92 204.34,1101.00 214.91,1134.07 230.56,1165.34 248.45,1195.00 248.45,1195.00 265.42,1221.00 265.42,1221.00 267.15,1223.45 272.71,1230.30 272.68,1233.00 272.64,1235.86 268.90,1239.09 267.00,1241.00 267.00,1241.00 250.00,1257.00 250.00,1257.00 250.00,1257.00 210.00,1295.00 210.00,1295.00 210.00,1295.00 175.00,1328.00 175.00,1328.00 Z",
      attr: {
        id: "_clubhouse",
        "data-name": "clubhouse",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "", zone: "CLUBHOUSE", zoneId: "2" },
    };
    var _A = {
      path:
        "M 177.00,373.00 C 177.00,373.00 199.00,360.20 199.00,360.20 199.00,360.20 242.00,334.00 242.00,334.00 242.00,334.00 262.00,322.07 262.00,322.07 262.00,322.07 287.00,317.25 287.00,317.25 287.00,317.25 333.00,309.00 333.00,309.00 333.00,309.00 346.85,360.00 346.85,360.00 346.85,360.00 374.85,463.00 374.85,463.00 374.85,463.00 416.58,616.00 416.58,616.00 416.58,616.00 437.00,691.00 437.00,691.00 437.00,691.00 404.00,697.93 404.00,697.93 404.00,697.93 391.00,704.86 391.00,704.86 387.41,706.87 375.93,714.62 372.33,712.87 370.28,711.88 368.17,707.97 367.01,706.00 367.01,706.00 357.85,690.00 357.85,690.00 357.85,690.00 322.85,629.00 322.85,629.00 322.85,629.00 221.15,452.00 221.15,452.00 221.15,452.00 190.72,399.00 190.72,399.00 186.95,392.38 178.58,379.65 177.00,373.00 Z",
      attr: {
        id: "_A",
        "data-name": "A",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "A", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _B = {
      path:
        "M 344.00,307.00 C 344.00,307.00 398.00,297.75 398.00,297.75 398.00,297.75 424.00,294.00 424.00,294.00 424.00,294.00 504.00,294.00 504.00,294.00 504.00,294.00 504.00,684.00 504.00,684.00 504.00,684.00 477.00,684.00 477.00,684.00 477.00,684.00 457.00,686.90 457.00,686.90 457.00,686.90 448.72,686.90 448.72,686.90 448.72,686.90 445.71,680.00 445.71,680.00 445.71,680.00 440.85,662.00 440.85,662.00 440.85,662.00 421.85,592.00 421.85,592.00 421.85,592.00 372.15,410.00 372.15,410.00 372.15,410.00 353.42,341.00 353.42,341.00 353.42,341.00 344.00,307.00 344.00,307.00 Z",
      attr: {
        id: "_B",
        "data-name": "B",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "B", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _C = {
      path:
        'M 515.00,294.00 C 515.00,294.00 591.00,294.00 591.00,294.00 593.31,294.00 596.63,293.78 598.62,295.02 601.12,296.58 603.87,302.31 605.24,305.00 610.35,314.97 613.98,317.41 614.00,329.00 614.00,329.00 614.00,685.00 614.00,685.00 614.00,685.00 515.00,685.00 515.00,685.00 515.00,685.00 515.00,294.00 515.00,294.00 Z"',
      attr: {
        id: "_C",
        "data-name": "C",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "C", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _D = {
      path: "M 625.00,329.00 C 625.00,329.00 720.00,329.00 720.00,329.00 720.00,329.00 720.00,685.00 720.00,685.00 720.00,685.00 625.00,685.00 625.00,685.00 625.00,685.00 625.00,329.00 625.00,329.00 Z",
      attr: {
        id: "_D",
        "data-name": "D",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "D", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _E = {
      path: " M734.000000,475.000000   C734.000000,427.225403 734.000000,379.950806 734.000000,332.339294   C761.803162,332.339294 789.028870,332.339294 816.627808,332.339294   C816.627808,449.389618 816.627808,566.266968 816.627808,683.572388   C789.376648,683.572388 762.150024,683.572388 734.000000,683.572388   C734.000000,614.278625 734.000000,544.889343 734.000000,475.000000  z",
      attr: {
        id: "_E",
        "data-name": "E",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "E", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _F = {
      path: "M 829.00,329.00 C 829.00,329.00 942.00,329.00 942.00,329.00 942.00,329.00 942.00,685.00 942.00,685.00 942.00,685.00 829.00,685.00 829.00,685.00 829.00,685.00 829.00,329.00 829.00,329.00 Z",
      attr: {
        id: "_F",
        "data-name": "F",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "F", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _G = {
      path: "M 953.00,329.00 C 953.00,329.00 1039.00,329.00 1039.00,329.00 1039.00,329.00 1039.00,685.00 1039.00,685.00 1039.00,685.00 953.00,685.00 953.00,685.00 953.00,685.00 953.00,329.00 953.00,329.00 Z",
      attr: {
        id: "_G",
        "data-name": "G",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "G", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _H = {
      path: "M 1050.00,329.00 C 1050.00,329.00 1147.00,329.00 1147.00,329.00 1147.00,329.00 1147.00,685.00 1147.00,685.00 1147.00,685.00 1050.00,685.00 1050.00,685.00 1050.00,685.00 1050.00,329.00 1050.00,329.00 Z",
      attr: {
        id: "_H",
        "data-name": "H",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "H", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _I = {
      path:
        "M 1158.00,685.00 C 1158.00,685.00 1158.00,384.00 1158.00,384.00 1158.00,384.00 1158.00,322.00 1158.00,322.00 1158.14,314.21 1162.61,308.66 1166.23,302.00 1167.42,299.82 1169.60,295.34 1171.61,293.99 1173.46,292.75 1176.81,293.00 1179.00,293.00 1179.00,293.00 1255.00,293.00 1255.00,293.00 1255.00,293.00 1255.00,685.00 1255.00,685.00 1255.00,685.00 1158.00,685.00 1158.00,685.00 Z",
      attr: {
        id: "_I",
        "data-name": "I",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "I", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _J = {
      path:
        "M 1266.00,293.00 C 1266.00,293.00 1314.00,293.00 1314.00,293.00 1324.53,293.02 1360.63,299.75 1373.00,302.00 1373.00,302.00 1366.42,338.00 1366.42,338.00 1366.42,338.00 1349.42,431.00 1349.42,431.00 1349.42,431.00 1302.00,690.00 1302.00,690.00 1302.00,690.00 1266.00,685.00 1266.00,685.00 1266.00,685.00 1266.00,293.00 1266.00,293.00 Z",
      attr: {
        id: "_J",
        "data-name": "J",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "J", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _K = {
      path:
        "M 1384.00,304.00 C 1384.00,304.00 1431.00,311.59 1431.00,311.59 1431.00,311.59 1463.00,317.22 1463.00,317.22 1463.00,317.22 1492.00,330.74 1492.00,330.74 1492.00,330.74 1524.00,346.00 1524.00,346.00 1524.00,346.00 1507.85,385.00 1507.85,385.00 1507.85,385.00 1475.42,460.00 1475.42,460.00 1475.42,460.00 1368.00,709.00 1368.00,709.00 1368.00,709.00 1342.00,697.45 1342.00,697.45 1335.89,694.99 1319.75,692.31 1313.00,692.00 1313.00,692.00 1326.42,620.00 1326.42,620.00 1326.42,620.00 1349.42,494.00 1349.42,494.00 1349.42,494.00 1373.42,363.00 1373.42,363.00 1373.42,363.00 1384.00,304.00 1384.00,304.00 Z",
      attr: {
        id: "_K",
        "data-name": "K",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "K", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _L = {
      path:
        "M 1484.00,468.00 C 1484.00,468.00 1510.00,480.22 1510.00,480.22 1510.00,480.22 1553.00,500.74 1553.00,500.74 1553.00,500.74 1603.00,525.00 1603.00,525.00 1600.31,531.32 1588.62,545.68 1583.87,552.00 1583.87,552.00 1544.87,604.00 1544.87,604.00 1544.87,604.00 1476.37,695.00 1476.37,695.00 1476.37,695.00 1453.87,725.00 1453.87,725.00 1453.87,725.00 1442.54,739.68 1442.54,739.68 1437.98,743.76 1432.53,739.45 1428.00,737.26 1428.00,737.26 1392.00,720.69 1392.00,720.69 1388.09,718.90 1378.59,716.23 1379.68,710.96 1379.68,710.96 1388.58,690.00 1388.58,690.00 1388.58,690.00 1407.58,646.00 1407.58,646.00 1407.58,646.00 1458.85,527.00 1458.85,527.00 1458.85,527.00 1474.85,490.00 1474.85,490.00 1474.85,490.00 1484.00,468.00 1484.00,468.00 Z",
      attr: {
        id: "_L",
        "data-name": "L",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "L", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _M = {
      path:
        "M 1379.00,1291.00 C 1379.00,1291.00 1422.00,1270.24 1422.00,1270.24 1425.66,1268.44 1435.21,1262.83 1438.96,1263.78 1442.81,1264.76 1449.30,1274.61 1451.88,1278.00 1451.88,1278.00 1481.37,1316.00 1481.37,1316.00 1481.37,1316.00 1566.65,1426.00 1566.65,1426.00 1566.65,1426.00 1591.42,1458.00 1591.42,1458.00 1595.57,1463.32 1602.48,1471.09 1605.00,1477.00 1605.00,1477.00 1576.00,1491.26 1576.00,1491.26 1576.00,1491.26 1524.00,1516.31 1524.00,1516.31 1524.00,1516.31 1486.00,1534.00 1486.00,1534.00 1486.00,1534.00 1466.70,1491.00 1466.70,1491.00 1466.70,1491.00 1426.14,1399.00 1426.14,1399.00 1426.14,1399.00 1395.70,1330.00 1395.70,1330.00 1395.70,1330.00 1379.00,1291.00 1379.00,1291.00 Z",
      attr: {
        id: "_M",
        "data-name": "M",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "M", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _N = {
      path:
        "M 1315.00,1313.00 C 1315.00,1313.00 1341.00,1308.48 1341.00,1308.48 1341.00,1308.48 1368.00,1296.00 1368.00,1296.00 1368.00,1296.00 1404.86,1379.00 1404.86,1379.00 1404.86,1379.00 1472.86,1533.00 1472.86,1533.00 1472.86,1533.00 1509.42,1616.00 1509.42,1616.00 1509.42,1616.00 1519.23,1638.00 1519.23,1638.00 1520.22,1640.18 1523.82,1648.11 1524.07,1649.98 1524.63,1654.22 1521.18,1655.60 1518.00,1657.24 1518.00,1657.24 1496.00,1668.25 1496.00,1668.25 1496.00,1668.25 1463.00,1684.37 1463.00,1684.37 1463.00,1684.37 1415.00,1690.72 1415.00,1690.72 1415.00,1690.72 1395.00,1693.08 1395.00,1693.08 1392.74,1693.42 1388.64,1694.50 1386.74,1693.08 1385.08,1691.70 1384.77,1688.00 1384.39,1686.00 1384.39,1686.00 1380.80,1667.00 1380.80,1667.00 1380.80,1667.00 1366.92,1592.00 1366.92,1592.00 1366.92,1592.00 1359.42,1552.00 1359.42,1552.00 1359.42,1552.00 1334.42,1417.00 1334.42,1417.00 1334.42,1417.00 1321.61,1348.00 1321.61,1348.00 1321.61,1348.00 1315.00,1313.00 1315.00,1313.00 Z",
      attr: {
        id: "_N",
        "data-name": "N",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "N", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _O = {
      path:
        "M 1266.00,1320.00 C 1266.00,1320.00 1304.00,1315.00 1304.00,1315.00 1304.00,1315.00 1351.42,1571.00 1351.42,1571.00 1351.42,1571.00 1366.61,1653.00 1366.61,1653.00 1366.61,1653.00 1374.00,1696.00 1374.00,1696.00 1374.00,1696.00 1339.00,1674.70 1339.00,1674.70 1339.00,1674.70 1331.00,1674.00 1331.00,1674.00 1331.00,1674.00 1266.00,1674.00 1266.00,1674.00 1266.00,1674.00 1266.00,1320.00 1266.00,1320.00 Z",
      attr: {
        id: "_O",
        "data-name": "O",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "O", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _P = {
      path: "M 1156.00,1320.00 C 1156.00,1320.00 1255.00,1320.00 1255.00,1320.00 1255.00,1320.00 1255.00,1674.00 1255.00,1674.00 1255.00,1674.00 1156.00,1674.00 1156.00,1674.00 1156.00,1674.00 1156.00,1320.00 1156.00,1320.00 Z",
      attr: {
        id: "_P",
        "data-name": "P",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "P", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _Q = {
      path: "M 1051.00,1320.00 C 1051.00,1320.00 1145.00,1320.00 1145.00,1320.00 1145.00,1320.00 1145.00,1674.00 1145.00,1674.00 1145.00,1674.00 1051.00,1674.00 1051.00,1674.00 1051.00,1674.00 1051.00,1320.00 1051.00,1320.00 Z",
      attr: {
        id: "_Q",
        "data-name": "Q",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "Q", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _R = {
      path: "M 952.00,1320.00 C 952.00,1320.00 1040.00,1320.00 1040.00,1320.00 1040.00,1320.00 1040.00,1674.00 1040.00,1674.00 1040.00,1674.00 952.00,1674.00 952.00,1674.00 952.00,1674.00 952.00,1320.00 952.00,1320.00 Z",
      attr: {
        id: "_R",
        "data-name": "R",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "R", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _S = {
      path:
        "M 828.00,1320.00 C 828.00,1320.00 941.00,1320.00 941.00,1320.00 941.00,1320.00 941.00,1674.00 941.00,1674.00 941.00,1674.00 898.00,1673.00 898.00,1673.00 898.00,1673.00 828.00,1673.00 828.00,1673.00 828.00,1673.00 828.00,1320.00 828.00,1320.00 Z",
      attr: {
        id: "_S",
        "data-name": "S",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "S", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _T = {
      path: "M 730.00,1320.00 C 730.00,1320.00 817.00,1320.00 817.00,1320.00 817.00,1320.00 817.00,1673.00 817.00,1673.00 817.00,1673.00 730.00,1673.00 730.00,1673.00 730.00,1673.00 730.00,1320.00 730.00,1320.00 Z",
      attr: {
        id: "_T",
        "data-name": "T",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "T", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _U = {
      path: "M 624.00,1320.00 C 624.00,1320.00 719.00,1320.00 719.00,1320.00 719.00,1320.00 719.00,1673.00 719.00,1673.00 719.00,1673.00 624.00,1673.00 624.00,1673.00 624.00,1673.00 624.00,1320.00 624.00,1320.00 Z",
      attr: {
        id: "_U",
        "data-name": "U",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "U", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _V = {
      path:
        "M 516.00,1320.00 C 516.00,1320.00 613.00,1320.00 613.00,1320.00 613.00,1320.00 613.00,1673.00 613.00,1673.00 613.00,1673.00 531.00,1673.00 531.00,1673.00 531.00,1673.00 516.00,1672.00 516.00,1672.00 516.00,1672.00 516.00,1320.00 516.00,1320.00 Z",
      attr: {
        id: "_V",
        "data-name": "V",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "V", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _W = {
      path:
        "M 436.00,1314.00 C 436.00,1314.00 449.00,1316.42 449.00,1316.42 449.00,1316.42 473.00,1320.00 473.00,1320.00 473.00,1320.00 505.00,1320.00 505.00,1320.00 505.00,1320.00 505.00,1707.00 505.00,1707.00 505.00,1707.00 433.00,1707.00 433.00,1707.00 433.00,1707.00 407.00,1703.41 407.00,1703.41 407.00,1703.41 348.00,1693.00 348.00,1693.00 348.00,1693.00 354.58,1664.00 354.58,1664.00 354.58,1664.00 371.35,1592.00 371.35,1592.00 371.35,1592.00 412.88,1413.00 412.88,1413.00 412.88,1413.00 429.65,1341.00 429.65,1341.00 429.65,1341.00 436.00,1314.00 436.00,1314.00 Z",
      attr: {
        id: "_W",
        "data-name": "W",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "W", zone: "Lower Zone", zoneId: "3" },
    };
  
    var _X = {
      path:
        "M 373.00,1291.00 C 381.31,1292.98 394.52,1303.26 403.00,1307.48 407.80,1309.88 419.43,1311.55 425.00,1312.00 425.00,1312.00 417.35,1344.00 417.35,1344.00 417.35,1344.00 404.35,1400.00 404.35,1400.00 404.35,1400.00 370.35,1546.00 370.35,1546.00 370.35,1546.00 349.63,1635.00 349.63,1635.00 349.63,1635.00 337.00,1691.00 337.00,1691.00 337.00,1691.00 285.00,1681.75 285.00,1681.75 285.00,1681.75 255.00,1676.01 255.00,1676.01 255.00,1676.01 233.00,1662.42 233.00,1662.42 233.00,1662.42 196.00,1638.95 196.00,1638.95 196.00,1638.95 177.00,1627.00 177.00,1627.00 178.66,1620.05 190.35,1602.27 194.58,1595.00 194.58,1595.00 232.15,1531.00 232.15,1531.00 232.15,1531.00 338.42,1350.00 338.42,1350.00 338.42,1350.00 373.00,1291.00 373.00,1291.00 Z",
      attr: {
        id: "_X",
        "data-name": "X",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "X", zone: "Lower Zone", zoneId: "3" },
    };
  
    zones.push(_A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _clubhouse);
    return zones;
  };
  