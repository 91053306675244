
const defaultRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 40; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };
  
  const lowerZoneRows1 = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "AA", "BB", "T(Standing room only)"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const lowerZoneRows2 = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "AA", "BB", "T(Standing room only)"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const lowerZoneRows3 = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "AA", "BB", "CC", "DD", "EE", "FF", "GG", "T(Standing room only)"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const lowerZoneRows4 = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "AA", "BB", "CC", "DD", "EE", "FF", "GG", "T(Standing room only)"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const lowerZoneRows5 = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "BB", "CC", "DD", "EE", "FF", "GG", "T(Standing room only)"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const lowerZoneRows6 = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "CC", "DD", "EE", "FF", "GG", "T(Standing room only)"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  const lowerZoneRows7 = () => {
    const arr = [];
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "EE", "FF", "GG", "T(Standing room only)"];

    alphabet.map((item) => {
      arr.push({
        value: item,
        label: `Row ${item}`,
      });
    });
  
    return arr;
  };
  
  export default {
    DEFAULT_ROWS: defaultRows(),
    LOWER_ZONE_ROWS1: lowerZoneRows1(),
    LOWER_ZONE_ROWS2: lowerZoneRows2(),
    LOWER_ZONE_ROWS3: lowerZoneRows3(),
    LOWER_ZONE_ROWS4: lowerZoneRows4(),
    LOWER_ZONE_ROWS5: lowerZoneRows5(),
    LOWER_ZONE_ROWS6: lowerZoneRows6(),
    LOWER_ZONE_ROWS7: lowerZoneRows7(),
  };
  