import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, withStyles } from "@material-ui/core";
import BlogCard from "./blogCard";

const styles = (theme) => ({
    container: {
      padding: "0px 90px 30px 90px"
    },
    [theme.breakpoints.down("md")]: {
      container: {
        padding: "0px 90px 30px 90px"
      },
    },
    [theme.breakpoints.down("sm")]: {
      container: {
        padding: "0px 25px 0px 25px"
      },
    },
  });

const DesktopLatestBlogs = ({
    classes,
    blogUrls
}) => {
    return (
    <div className={classes.container}>
        <Typography
          variant="headline"
          align="center"
          color="error"
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
            fontSize: "2rem",
            fontWeight: "bold"
          }}
        >
          <span>LATEST BLOG POSTS</span>
        </Typography>
      <Grid 
        container 
        justify="center"
        spacing={40}
      >
        {blogUrls.map(url => (
            <Grid item key={url} xs={4}>
                <BlogCard source={url}></BlogCard>
            </Grid>
        ))}
      </Grid>
      <div style={{
        textAlign: "center",
        marginTop: "30px",
        padding: "20px",
        borderTop: "1px solid #D5DEE9",
        borderBottom: "1px solid #D5DEE9",
      }}>
          <Button
            variant="text"
            href={`/blogs`}
            color="primary"
            style={{textTransform: "none"}}
          >
            See All Blog Posts
          </Button>
      </div>
    </div>
    )
}
export default withStyles(styles)(DesktopLatestBlogs);
