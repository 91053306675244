import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

import GamesList from './games-list';
import TeamSelector from './team-selector';

const styles = (theme) => ({
  gamesGridContainer: {
    maxWidth: 1366,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

const homeGamesList = ({
  classes,
  selectedTeam,
  selectedParent,
  isLoadingGames,
  games,
  onTeamSelection,
  onParentSelection,
  teIdList,
  isGamesEqual,
}) => {
  return (
    <div className={classes.gamesGridContainer}>
      <TeamSelector
        selectedTeam={selectedTeam}
        selectedParent={selectedParent}
        onParentSelection={onParentSelection}
        onTeamSelection={onTeamSelection}
        isLoadingGames={isLoadingGames || !isGamesEqual}
        games={games[selectedTeam]}
      />
      <GamesList
        games={games[selectedTeam]}
        isLoadingGames={isLoadingGames}
        selectedTeam={selectedTeam}
        teIdList={teIdList}
        selectedParent={selectedParent}
      />
    </div>
  );
};

export default withStyles(styles)(homeGamesList);
