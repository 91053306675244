import React, { useState } from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { List, ListItem, ListItemIcon, ListItemText, IconButton, SwipeableDrawer, withStyles, Typography, Button } from "@material-ui/core";

import Dashboard from "@material-ui/icons/Dashboard";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonOutline from "@material-ui/icons/PersonOutline";
import HomeIcon from "@material-ui/icons/HomeRounded";
import GavelIcon from "@material-ui/icons/GavelRounded";
import TicketIcon from "@material-ui/icons/ConfirmationNumberRounded";
import TicketTrackIcon from "@material-ui/icons/ConfirmationNumber";
import DescriptionIcon from "@material-ui/icons/DescriptionRounded";
import ListIcon from "@material-ui/icons/ListRounded";
import MenuIcon from "@material-ui/icons/Menu";
import Receipt from "@material-ui/icons/Receipt";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Divider from "@material-ui/core/Divider";
import Logo from "../../images/fansfirst-logo-mobile.svg";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import TEAMS from "../../constants/Teams";

const styles = (theme) => ({
  navContainer: {
    boxShadow: "0 4px 6px 0 rgba(0,0,0,0.03)",
    position: "fixed",
    width: "100%",
    backgroundColor: "#fafafa",
    zIndex: "13",
  },
  nav: {
    display: "flex",
  },
  callUsContainer: {
    position: "relative",
    width: "auto",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "5px",
  },
  questionsText: {
    color: "#00A0FF",
    fontWeight: "bold",
    display: "inline",
    fontSize: "14px",
  },
  sellButton: {
    minWidth: theme.spacing.unit * 0.5,
    height: theme.spacing.unit * 0.5,
    backgroundColor: "#00de94",
    color: "#ffffff",
    verticalAlign: "middle",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#00c281",
    },
    textTransform: "none",
  },
  phoneNumberText: {
    color: "#5F666F",
    fontWeight: "bold",
    display: "inline",
    marginLeft: "0.5rem",
    fontSize: "14px",
  },
  menuContainer: {
    maxWidth: theme.spacing.unit * 6,
    flex: 1,
    position: "relative",
    top: "14px",
  },
  logoContainer: {
    paddingTop: `${theme.spacing.unit}px`,
    position: "relative",
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    verticalAlign: "middle",
    height: "36px",
  },
  icon: {
    color: "#fff",
  },
  divider: {
    backgroundColor: "white",
  },
  listItemText: {
    padding: '0 16px 0 0'
  }
});

const mobileNav = ({ classes, isAuthenticated, currUser, handleLogout }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleAccountDrawer = () => {
    setIsAccountDrawerOpen(!isAccountDrawerOpen);
  };

  const renderCallUs = () => {
    return (
      <MediaQuery maxWidth={959}>
        <a className={classes.callUsContainer} href="tel:+1-403-768-2298" style={{ textDecoration: "none" }}>
          <Typography className={classes.questionsText}>Questions? Call us: </Typography>
          <Typography className={classes.phoneNumberText}>1-403-768-2298</Typography>
        </a>
      </MediaQuery>
    );
  };

  const renderTeams = () => {
    return TEAMS.map((game, index) => (
      <Link to={`/${game.homeTeamSlug}/games`} style={{ textDecoration: "none" }} key={index}>
        <ListItem button>
          <ListItemIcon>
            <TicketIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                style={{
                  color: "#FFFFFF",
                  font: "Avenir",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Buy {game.name} Tickets
              </Typography>
            }
          />
        </ListItem>
      </Link>
    ));
  }

  const renderDrawer = () => {
    return (
      <SwipeableDrawer open={isDrawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer}>
        <div tabIndex={0} role="button" onClick={() => toggleDrawer()} onKeyDown={() => toggleDrawer()} style={{ backgroundColor: "#2faaf3", width: "300px", height: "100%" }}>
          <div className={classes.list}>
            <List style={{ backgroundColor: "#2faaf3"}}>
              <ListItem button>
                <ListItemIcon>
                  <CloseRoundedIcon className={classes.icon} />
                </ListItemIcon>
              </ListItem>
              <Link to="/" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Home
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              {isAuthenticated ? (
                <>
                  <Link to="/dashboard" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <Dashboard className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography
                          type="body2"
                          style={{
                            color: "#FFFFFF",
                            font: "Avenir",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Dashboard
                        </Typography>} />
                    </ListItem>
                  </Link>
                  <Link to="/account" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <PersonOutline className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography
                          type="body2"
                          style={{
                            color: "#FFFFFF",
                            font: "Avenir",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Profile
                        </Typography>} />
                    </ListItem>
                  </Link>
                  <Link to="/track-order" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <TicketTrackIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color: "#FFFFFF",
                              font: "Avenir",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            Track Your Tickets
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Link>
                  <Link to="/orders" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <Receipt className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography
                          type="body2"
                          style={{
                            color: "#FFFFFF",
                            font: "Avenir",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Orders
                        </Typography>} />
                    </ListItem>
                  </Link>
                  <Link to="/listings" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <ListIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography
                          type="body2"
                          style={{
                            color: "#FFFFFF",
                            font: "Avenir",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Listings (Sell)
                        </Typography>} />
                    </ListItem>
                  </Link>
                </>
              ):(
                <>
                  <Link to="/sell" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <TicketIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color: "#FFFFFF",
                              font: "Avenir",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            Sell Tickets
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Link>
                  <Link to="/signup" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <ExitToApp className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color: "#FFFFFF",
                              font: "Avenir",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                          Sign Up
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Link>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <ListItem button>
                      <ListItemIcon>
                        <PersonOutline className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color: "#FFFFFF",
                              font: "Avenir",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                          Log In
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Link>
                </>
              )}
            
              <Divider className={classes.divider}/>
              {renderTeams()}
              <Divider className={classes.divider}/>
              {/* <Link to="/sth" style={{ textDecoration: 'none' }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon />
                  </ListItemIcon>
                  <ListItemText primary="Season Ticket Holders Forum" />
                </ListItem>
              </Link> */}
              <Link to="/legal" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <GavelIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Legal
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/why-us" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <DescriptionIcon  className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        About FansFirst
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/faqs" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <ListIcon className={classes.icon}/>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: "#FFFFFF",
                          font: "Avenir",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        FAQs
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
            </List>
          </div>
        </div>
      </SwipeableDrawer>
    );
  };

  const renderAccountDrawer = () => {
    return (
      <SwipeableDrawer anchor="right" open={isAccountDrawerOpen} onClose={toggleAccountDrawer} onOpen={toggleAccountDrawer}>
        <div tabIndex={0} role="button" onClick={() => toggleAccountDrawer()} onKeyDown={() => toggleAccountDrawer()}>
          <div className={classes.list}>
            <List>
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Dashboard" 
                    className={classes.listItemText}
                  />
                </ListItem>
              </Link>
              <Link to="/account" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <PersonOutline />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Profile" 
                    className={classes.listItemText}
                  />
                </ListItem>
              </Link>
              <Link to="/track-order" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <TicketIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Track Your Tickets" 
                    className={classes.listItemText}  
                  />
                </ListItem>
              </Link>
              <Link to="/orders" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <Receipt />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Orders" 
                    className={classes.listItemText}  
                  />
                </ListItem>
              </Link>
              <Link to="/listings" style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Listings (Sell)" 
                    className={classes.listItemText}  
                  />
                </ListItem>
              </Link>
              <Divider />
              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText 
                  primary="Logout" 
                  className={classes.listItemText}  
                />
              </ListItem>
            </List>
          </div>
        </div>
      </SwipeableDrawer>
    );
  };

  return (
    <div className={classes.navContainer}>
      <nav className={classes.nav}>
        <div className={classes.menuContainer}>
          <IconButton color="primary" aria-label="Menu" onClick={() => toggleDrawer()}>
            <MenuIcon />
          </IconButton>
        </div>
        <div className={classes.logoContainer}>
          <a href="/">
            <img src={Logo} alt="FansFirst Logo" className={classes.logo} />
          </a>
        </div>
        <div className={classes.menuContainer}>
          {isAuthenticated ? (
            <IconButton color="primary" aria-label="Menu" onClick={() => toggleAccountDrawer()}>
              <AccountCircle />
            </IconButton>
          ) : (
            <Button className={classes.sellButton} href="/sell" variant="extendedFab">
              <Typography style={{ margin: "-8px", color: "white", fontSize: "12px" }}>SELL</Typography>
            </Button>
          )}
        </div>
      </nav>
      {renderDrawer()}
      {isAuthenticated && 
        renderAccountDrawer()
      }
      {renderCallUs()}
    </div>
  );
};

export default withStyles(styles)(mobileNav);
