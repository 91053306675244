import React from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core"; //

const CancelOkPopup = (props) => {
  const { isActive, onCancel, onOk, title, message, cancelLabel, okLabel } =
    props;
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={isActive} maxWidth="sm">
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="grey" autoFocus>
          {cancelLabel}
        </Button>
        <Button onClick={onOk} color="primary" autoFocus>
          {okLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CancelOkPopup;
