export const getZones = (rsr) => {
    let zones = [];
    var _101 = { 
      path:
        'M 1937.00,477.00 C 1937.00,477.00 2034.00,477.00 2034.00,477.00 2036.90,477.04 2049.30,477.64 2050.83,479.57 2052.25,481.38 2052.00,485.74 2052.00,488.00 2052.00,488.00 2052.00,759.00 2052.00,759.00 2052.00,759.00 2029.00,759.00 2029.00,759.00 2029.00,759.00 2012.00,758.00 2012.00,758.00 2012.00,758.00 1937.00,758.00 1937.00,758.00 1937.00,758.00 1937.00,477.00 1937.00,477.00 Z', //replace path data with the path. Make sure it's all on one line and there's no errors.
      attr: {
        id: '_101',
        'data-name': '101',
         opacity: 0,
        fill: '#2196f3',
        stroke: '#d7d7d7',
        'stroke-miterlimit': '10',
        'stroke-width': '0',
         'stroke-opacity': '1',
      },
      transform: "",
      data: { zoneNo: "101", zone: 'Lower Zone', zoneId: "1" }, 
    };
    var _101L = {
        path:
          'M 1937.00,797.00 C 1937.00,797.00 2006.00,797.00 2006.00,797.00 2021.53,797.02 2022.76,800.24 2035.00,808.66 2047.26,817.09 2059.25,826.75 2070.00,837.04 2073.64,840.52 2082.81,849.53 2084.41,854.00 2085.12,855.99 2085.00,858.87 2085.13,861.00 2085.13,861.00 2085.13,883.00 2085.13,883.00 2085.01,887.36 2086.65,897.76 2085.13,900.83 2084.67,902.64 2078.49,908.98 2076.72,911.00 2076.72,911.00 2053.00,937.00 2053.00,937.00 2053.00,937.00 2051.00,937.00 2051.00,937.00 2048.25,929.13 2043.93,921.93 2039.33,915.00 2023.14,890.60 2003.18,874.39 1978.00,860.00 1969.73,855.27 1960.84,851.35 1952.00,847.80 1949.57,846.83 1939.91,843.45 1938.60,842.16 1936.63,840.23 1937.01,836.56 1937.00,834.00 1937.00,834.00 1937.00,797.00 1937.00,797.00 Z',
        attr: {
          id: '_101L',
          'data-name': '101L',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "101", zone: 'Lower Zone L', zoneId: "1" },
      };
      var _102 = {
        path:'M 1705.00,477.00 C 1705.00,477.00 1898.00,477.00 1898.00,477.00   1898.00,477.00 1898.00,833.00 1898.00,833.00   1898.00,833.00 1705.00,833.00 1705.00,833.00   1705.00,833.00 1705.00,477.00 1705.00,477.00 Z',
        attr: {
          id: '_102',
          'data-name': '102',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "102", zone: 'Lower Zone', zoneId: "1" },
      };
      var _103 = {
        path:
          'M 1466.00,476.00 C 1466.00,476.00 1666.00,476.00 1666.00,476.00   1666.00,476.00 1666.00,833.00 1666.00,833.00   1666.00,833.00 1478.00,833.00 1478.00,833.00   1478.00,833.00 1478.00,792.00 1478.00,792.00   1478.00,792.00 1479.00,778.00 1479.00,778.00   1479.00,778.00 1466.00,778.00 1466.00,778.00   1466.00,778.00 1466.00,476.00 1466.00,476.00 Z',
        attr: {
          id: '_103',
          'data-name': '103',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "103", zone: 'Lower Zone', zoneId: "1" },
      };
      var _104 = {
        path:'M 1228.00,475.00 C 1228.00,475.00 1317.00,475.00 1317.00,475.00   1317.00,475.00 1332.00,476.00 1332.00,476.00   1332.00,476.00 1427.00,476.00 1427.00,476.00   1427.00,476.00 1427.00,777.00 1427.00,777.00   1427.00,777.00 1228.00,777.00 1228.00,777.00   1228.00,777.00 1228.00,475.00 1228.00,475.00 Z',
        attr: {
          id: '_104',
          'data-name': '104',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "104", zone: 'Lower Zone', zoneId: "1" },
      };
      var _105 = {
        path:'M 995.00,475.00 C 995.00,475.00 1189.00,475.00 1189.00,475.00   1189.00,475.00 1189.00,777.00 1189.00,777.00   1189.00,777.00 1182.00,777.00 1182.00,777.00   1182.00,777.00 1182.00,833.00 1182.00,833.00   1182.00,833.00 995.00,833.00 995.00,833.00   995.00,833.00 995.00,475.00 995.00,475.00 Z',
        attr: {
          id: '_105',
          'data-name': '105',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "105", zone: 'Lower Zone', zoneId: "1" },
      };
      var _106 = {
        path:'M 760.00,474.00 C 760.00,474.00 956.00,474.00 956.00,474.00   956.00,474.00 956.00,833.00 956.00,833.00   956.00,833.00 761.00,833.00 761.00,833.00   761.00,833.00 761.00,589.00 761.00,589.00   761.00,589.00 761.00,513.00 761.00,513.00   761.00,513.00 760.00,474.00 760.00,474.00 Z',
        attr: {
          id: '_106',
          'data-name': '106',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "106", zone: 'Lower Zone', zoneId: "1" },
      };
      var _107 = {
        path:'M 640.00,474.00 C 640.00,474.00 723.00,474.00 723.00,474.00   723.00,474.00 722.00,508.00 722.00,508.00   722.00,508.00 722.00,571.00 722.00,571.00   722.00,571.00 722.00,773.00 722.00,773.00   722.00,773.00 638.00,773.00 638.00,773.00   638.00,773.00 638.00,708.00 638.00,708.00   638.00,708.00 639.00,691.00 639.00,691.00   639.00,691.00 639.00,533.00 639.00,533.00   639.00,533.00 640.00,518.00 640.00,518.00   640.00,518.00 640.00,474.00 640.00,474.00 Z',
        attr: {
          id: '_107',
          'data-name': '107',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "107", zone: 'Lower Zone', zoneId: "1" },
      };
      var _107L = {
        path:'M 581.00,965.00 C 581.00,965.00 565.58,938.00 565.58,938.00   563.99,935.22 558.32,925.41 557.89,923.00   556.81,919.91 557.95,914.66 557.89,911.00   557.89,911.00 557.89,869.00 557.89,869.00   558.00,866.87 557.88,863.99 558.59,862.00   559.73,858.83 564.63,853.53 567.04,851.00   567.04,851.00 584.00,833.96 584.00,833.96   588.83,829.34 604.43,814.37 610.00,812.56   612.05,811.89 614.83,812.00 617.00,812.00   617.00,812.00 722.00,812.00 722.00,812.00   722.00,812.00 722.00,830.00 722.00,830.00   722.00,832.08 722.23,835.74 720.98,837.44   719.64,839.25 716.10,839.82 714.00,840.43   714.00,840.43 700.00,845.20 700.00,845.20   677.97,853.85 659.01,865.00 641.00,880.43   622.14,896.58 606.72,916.52 594.43,938.00   594.43,938.00 581.00,965.00 581.00,965.00 Zpath data',
        attr: {
          id: '_107L',
          'data-name': '107L',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "107", zone: 'Lower Zone L', zoneId: "1" },
      };
      var _107Box = {
        path:'M593.482910,731.548523   C597.687012,731.548523 600.965210,731.548523 604.742798,731.548523   C604.742798,747.156494 604.742798,761.981750 604.742798,776.863770   C598.019653,778.726074 551.851929,779.249817 536.917114,777.456726   C536.917114,770.069275 536.917114,762.531006 536.917114,754.992676   C536.917114,747.689636 536.917114,740.386658 536.917114,731.789062   C546.347168,731.707214 555.490295,731.596375 564.633789,731.557312   C573.941406,731.517578 583.249268,731.548523 593.482910,731.548523  z',
        attr: {
          id: '_107Box',
          'data-name': '107Box',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "107", zone: 'Lower Zone Box', zoneId: "1" },
      };
      var _108 = {
        path:'M 152.00,757.00 C 152.00,757.00 352.00,757.00 352.00,757.00   352.00,757.00 367.00,758.00 367.00,758.00   367.00,758.00 497.00,758.00 497.00,758.00   497.00,758.00 497.00,977.00 497.00,977.00   497.00,977.00 152.00,977.00 152.00,977.00   152.00,977.00 152.00,757.00 152.00,757.00 Z',
        attr: {
          id: '_108',
          'data-name': '108',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "108", zone: 'Lower Zone', zoneId: "1" },
      };
      var _109 = {
        path:'M 152.00,1016.00 C 152.00,1016.00 497.00,1016.00 497.00,1016.00   497.00,1016.00 497.00,1099.00 497.00,1099.00   497.00,1099.00 296.00,1099.00 296.00,1099.00   296.00,1099.00 296.00,1210.00 296.00,1210.00   296.00,1210.00 497.00,1210.00 497.00,1210.00   497.00,1210.00 497.00,1303.00 497.00,1303.00   497.00,1303.00 152.00,1303.00 152.00,1303.00   152.00,1303.00 152.00,1016.00 152.00,1016.00 Z',
        attr: {
          id: '_109',
          'data-name': '109',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "109", zone: 'Lower Zone', zoneId: "1" },
      };
      var _110 = {
        path:'M 152.00,1342.00 C 152.00,1342.00 497.00,1342.00 497.00,1342.00   497.00,1342.00 497.00,1550.00 497.00,1550.00   497.00,1550.00 306.00,1550.00 306.00,1550.00   306.00,1550.00 210.00,1550.00 210.00,1550.00   210.00,1550.00 195.00,1551.00 195.00,1551.00   195.00,1551.00 152.00,1551.00 152.00,1551.00   152.00,1551.00 152.00,1342.00 152.00,1342.00 Z',
        attr: {
          id: '_110',
          'data-name': '110',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "110", zone: 'Lower Zone', zoneId: "1" },
      };
      var _111 = {
        path:'M 642.00,1534.00 C 642.00,1534.00 724.00,1534.00 724.00,1534.00   724.00,1534.00 724.00,1828.00 724.00,1828.00   724.00,1828.00 642.00,1828.00 642.00,1828.00   642.00,1828.00 642.00,1534.00 642.00,1534.00 Z',
        attr: {
          id: '_111',
          'data-name': '111',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "111", zone: 'Lower Zone', zoneId: "1" },
      };
      var _111L = {
        path:'M 581.00,1334.00 C 581.00,1334.00 595.31,1364.00 595.31,1364.00   605.39,1382.64 619.50,1401.47 635.00,1415.91   653.79,1433.42 676.09,1446.95 700.00,1456.19   700.00,1456.19 716.00,1461.57 716.00,1461.57   718.10,1462.18 721.64,1462.75 722.98,1464.56   724.23,1466.26 724.00,1469.92 724.00,1472.00   724.00,1472.00 724.00,1495.00 724.00,1495.00   724.00,1495.00 624.00,1495.00 624.00,1495.00   621.81,1495.00 619.08,1495.11 617.00,1494.44   611.09,1492.55 594.20,1477.80 589.00,1473.07   581.08,1465.89 574.62,1459.23 567.75,1451.00   565.27,1448.02 559.96,1441.48 558.72,1438.00   557.87,1435.59 558.00,1432.55 558.00,1430.00   558.00,1430.00 558.00,1382.00 558.00,1382.00   558.02,1372.18 561.00,1369.31 565.72,1361.00   565.72,1361.00 581.00,1334.00 581.00,1334.00 Z',
        attr: {
          id: '_111L',
          'data-name': '111L',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "111", zone: 'Lower Zone L', zoneId: "1" },
      };
      var _111Box = {
        path:' M589.891907,1534.015381   C595.861572,1534.028687 600.851440,1534.025024 605.840759,1534.075806   C606.486633,1534.082275 607.129639,1534.369995 608.413208,1534.683716   C608.413208,1549.324829 608.413208,1564.134277 608.413208,1579.590820   C584.983032,1579.590820 561.578369,1579.590820 537.835266,1579.590820   C537.835266,1564.572876 537.835266,1549.685425 537.835266,1534.015381   C555.112183,1534.015381 572.012024,1534.015381 589.891907,1534.015381  z',
        attr: {
          id: '_111Box',
          'data-name': '111Box',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "111", zone: 'Lower Zone Box', zoneId: "1" },
      };
      var _112 = {
        path:'M 763.00,1467.00 C 763.00,1467.00 956.00,1467.00 956.00,1467.00   956.00,1467.00 956.00,1828.00 956.00,1828.00   956.00,1828.00 763.00,1828.00 763.00,1828.00   763.00,1828.00 763.00,1467.00 763.00,1467.00 Z',
        attr: {
          id: '_112',
          'data-name': '112',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "112", zone: 'Lower Zone', zoneId: "1" },
      };
      var _113 = {
        path:'M 995.00,1467.00 C 995.00,1467.00 1055.00,1467.00 1055.00,1467.00   1055.00,1467.00 1055.00,1526.00 1055.00,1526.00   1055.00,1526.00 1193.00,1526.00 1193.00,1526.00   1193.00,1526.00 1193.00,1827.00 1193.00,1827.00   1193.00,1827.00 1055.00,1827.00 1055.00,1827.00   1055.00,1827.00 1040.00,1828.00 1040.00,1828.00   1040.00,1828.00 995.00,1828.00 995.00,1828.00   995.00,1828.00 995.00,1467.00 995.00,1467.00 Z',
        attr: {
          id: '_113',
          'data-name': '113',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "113", zone: 'Lower Zone', zoneId: "1" },
      };
      var _114 = {
        path:'M 1232.00,1526.00 C 1232.00,1526.00 1427.00,1526.00 1427.00,1526.00   1427.00,1526.00 1427.00,1827.00 1427.00,1827.00   1427.00,1827.00 1232.00,1827.00 1232.00,1827.00   1232.00,1827.00 1232.00,1526.00 1232.00,1526.00 Z',
        attr: {
          id: '_114',
          'data-name': '114',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "114", zone: 'Upper Zone', zoneId: "2" },
      };
      var _115 = {
        path:'M 1609.00,1468.00 C 1609.00,1468.00 1667.00,1468.00 1667.00,1468.00   1667.00,1468.00 1667.00,1827.00 1667.00,1827.00   1667.00,1827.00 1466.00,1827.00 1466.00,1827.00   1466.00,1827.00 1466.00,1526.00 1466.00,1526.00   1466.00,1526.00 1485.00,1527.00 1485.00,1527.00   1485.00,1527.00 1609.00,1527.00 1609.00,1527.00   1609.00,1527.00 1609.00,1468.00 1609.00,1468.00 Z',
        attr: {
          id: '_115',
          'data-name': '115',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "115", zone: 'Lower Zone', zoneId: "1" },
      };
      var _116 = {
        path:'M 1706.00,1468.00 C 1706.00,1468.00 1875.00,1468.00 1875.00,1468.00   1875.00,1468.00 1890.00,1469.00 1890.00,1469.00   1890.00,1469.00 1900.00,1469.00 1900.00,1469.00   1900.00,1469.00 1900.00,1827.00 1900.00,1827.00   1900.00,1827.00 1706.00,1827.00 1706.00,1827.00   1706.00,1827.00 1706.00,1468.00 1706.00,1468.00 Z',
        attr: {
          id: '_116',
          'data-name': '116',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "116", zone: 'Lower Zone', zoneId: "2" },
      };
      var _117 = {
        path:'M 1939.00,1545.00 C 1939.00,1545.00 2001.00,1545.00 2001.00,1545.00   2001.00,1545.00 2018.00,1546.00 2018.00,1546.00   2018.00,1546.00 2053.00,1546.00 2053.00,1546.00   2053.00,1546.00 2053.00,1762.00 2053.00,1762.00   2053.00,1762.00 2053.00,1818.00 2053.00,1818.00   2052.95,1826.66 2052.32,1826.99 2044.00,1827.00   2044.00,1827.00 1939.00,1827.00 1939.00,1827.00   1939.00,1827.00 1939.00,1545.00 1939.00,1545.00 Z',
        attr: {
          id: '_117',
          'data-name': '117',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "117", zone: 'Lower Zone', zoneId: "1" },
      };
      var _117L = {
        path: 'M 2049.00,1374.00 C 2055.57,1378.27 2071.37,1395.42 2077.42,1401.91   2082.87,1407.75 2086.83,1408.23 2087.00,1417.00   2087.00,1417.00 2087.00,1437.00 2087.00,1437.00   2087.00,1439.12 2087.09,1442.00 2086.49,1444.00   2085.08,1448.71 2076.63,1457.36 2073.00,1461.00   2073.00,1461.00 2052.00,1481.13 2052.00,1481.13   2045.34,1486.85 2024.68,1503.05 2017.00,1505.35   2014.48,1506.11 2011.61,1506.00 2009.00,1506.00   2009.00,1506.00 1939.00,1506.00 1939.00,1506.00   1939.00,1506.00 1939.00,1467.00 1939.00,1467.00   1939.00,1464.83 1938.77,1461.32 1940.02,1459.50   1941.51,1457.34 1947.45,1455.43 1950.00,1454.40   1950.00,1454.40 1973.00,1443.69 1973.00,1443.69   1990.00,1434.49 2009.45,1420.78 2022.91,1406.96   2032.87,1396.73 2040.64,1385.44 2049.00,1374.00 Z',
        attr: {
          id: '_117L',
          'data-name': '117L',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "117", zone: 'Lower Zone L', zoneId: "1" },
      };
      var _301 = {
        path:'M 1830.00,374.00 C 1830.00,374.00 2049.00,374.00 2049.00,374.00   2049.00,374.00 2049.00,453.00 2049.00,453.00   2049.00,453.00 1830.00,453.00 1830.00,453.00   1830.00,453.00 1830.00,374.00 1830.00,374.00 Z',
        attr: {
          id: '_301',
          'data-name': '301',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "301", zone: 'Upper Zone', zoneId: "3" },
      };
      var _302 = {
        path:'M 1468.00,374.00 C 1468.00,374.00 1791.00,374.00 1791.00,374.00   1791.00,374.00 1791.00,453.00 1791.00,453.00   1791.00,453.00 1468.00,453.00 1468.00,453.00   1468.00,453.00 1468.00,374.00 1468.00,374.00 Z',
        attr: {
          id: '_302',
          'data-name': '302',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "302", zone: 'Upper Zone', zoneId: "3" },
      };
      var _303 = {
        path:'M 1228.00,374.00 C 1228.00,374.00 1429.00,374.00 1429.00,374.00   1429.00,374.00 1429.00,453.00 1429.00,453.00   1429.00,453.00 1228.00,453.00 1228.00,453.00   1228.00,453.00 1228.00,374.00 1228.00,374.00 Z',
        attr: {
          id: '_303',
          'data-name': '303',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "303", zone: 'Upper Zone', zoneId: "3" },
      };
      var _304 = {
        path:'M 862.00,374.00 C 862.00,374.00 1189.00,374.00 1189.00,374.00   1189.00,374.00 1189.00,453.00 1189.00,453.00   1189.00,453.00 862.00,453.00 862.00,453.00   862.00,453.00 862.00,374.00 862.00,374.00 Z',
        attr: {
          id: '_304',
          'data-name': '304',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "304", zone: 'Upper Zone', zoneId: "3" },
      };
      var _305 = {
        path:'M 608.00,374.00 C 608.00,374.00 823.00,374.00 823.00,374.00   823.00,374.00 823.00,453.00 823.00,453.00   823.00,453.00 608.00,453.00 608.00,453.00   608.00,453.00 608.00,374.00 608.00,374.00 Z',
        attr: {
          id: '_305',
          'data-name': '305',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "305", zone: 'Upper Zone', zoneId: "3" },
      };
      var _306 = {
        path:'M 611.00,1856.00 C 611.00,1856.00 683.00,1856.00 683.00,1856.00   683.00,1856.00 683.00,1931.00 683.00,1931.00   683.00,1931.00 611.00,1931.00 611.00,1931.00   611.00,1931.00 611.00,1856.00 611.00,1856.00 Z',
        attr: {
          id: '_306',
          'data-name': '306',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "306", zone: 'Upper Zone', zoneId: "3" },
      };
      var _307 = {
        path:'M 722.00,1856.00 C 722.00,1856.00 795.00,1856.00 795.00,1856.00   795.00,1856.00 795.00,1931.00 795.00,1931.00   795.00,1931.00 722.00,1931.00 722.00,1931.00   722.00,1931.00 722.00,1856.00 722.00,1856.00 Z',
        attr: {
          id: '_307',
          'data-name': '307',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "307", zone: 'Upper Zone', zoneId: "3" },
      };
      var _308 = {
        path:'M 834.00,1856.00 C 834.00,1856.00 913.00,1856.00 913.00,1856.00   913.00,1856.00 913.00,1931.00 913.00,1931.00   913.00,1931.00 834.00,1931.00 834.00,1931.00   834.00,1931.00 834.00,1856.00 834.00,1856.00 Z',
        attr: {
          id: '_308',
          'data-name': '308',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "308", zone: 'Upper Zone', zoneId: "3" },
      };
      var _309 = {
        path:'M 952.00,1856.00 C 952.00,1856.00 1015.00,1856.00 1015.00,1856.00   1015.00,1856.00 1015.00,1931.00 1015.00,1931.00   1015.00,1931.00 952.00,1931.00 952.00,1931.00   952.00,1931.00 952.00,1856.00 952.00,1856.00 Z',
        attr: {
          id: '_309',
          'data-name': '309',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "309", zone: 'Upper Zone', zoneId: "3" },
      };
      var _310 = {
        path:'M 1054.00,1856.00 C 1054.00,1856.00 1109.00,1856.00 1109.00,1856.00   1109.00,1856.00 1109.00,1931.00 1109.00,1931.00   1109.00,1931.00 1054.00,1931.00 1054.00,1931.00   1054.00,1931.00 1054.00,1856.00 1054.00,1856.00 Z',
        attr: {
          id: '_310',
          'data-name': '310',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "310", zone: 'Upper Zone', zoneId: "3" },
      };
      var _311 = {
        path:'M 1148.00,1856.00 C 1148.00,1856.00 1209.00,1856.00 1209.00,1856.00   1209.00,1856.00 1209.00,1931.00 1209.00,1931.00   1209.00,1931.00 1148.00,1931.00 1148.00,1931.00   1148.00,1931.00 1148.00,1856.00 1148.00,1856.00 Z',
        attr: {
          id: '311',
          'data-name': '311',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "311", zone: 'Upper Zone', zoneId: "3" },
      };
      var _312 = {
        path:'M 1248.00,1856.00 C 1248.00,1856.00 1311.00,1856.00 1311.00,1856.00   1311.00,1856.00 1311.00,1931.00 1311.00,1931.00   1311.00,1931.00 1248.00,1931.00 1248.00,1931.00   1248.00,1931.00 1248.00,1856.00 1248.00,1856.00 Z',
        attr: {
          id: '_312',
          'data-name': '312',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "312", zone: 'Upper Zone', zoneId: "3" },
      };
      var _313 = {
        path:'M 1350.00,1856.00 C 1350.00,1856.00 1411.00,1856.00 1411.00,1856.00   1411.00,1856.00 1411.00,1931.00 1411.00,1931.00   1411.00,1931.00 1350.00,1931.00 1350.00,1931.00   1350.00,1931.00 1350.00,1856.00 1350.00,1856.00 Z',
        attr: {
          id: '_313',
          'data-name': '313',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "313", zone: 'Upper Zone', zoneId: "3" },
      };
      var _314 = {
        path:'M 1450.00,1856.00 C 1450.00,1856.00 1511.00,1856.00 1511.00,1856.00   1511.00,1856.00 1511.00,1931.00 1511.00,1931.00   1511.00,1931.00 1450.00,1931.00 1450.00,1931.00   1450.00,1931.00 1450.00,1856.00 1450.00,1856.00 Z',
        attr: {
          id: '_314',
          'data-name': '314',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "314", zone: 'Upper Zone', zoneId: "3" },
      };
      var _315 = {
        path:'M 1550.00,1856.00 C 1550.00,1856.00 1611.00,1856.00 1611.00,1856.00   1611.00,1856.00 1611.00,1931.00 1611.00,1931.00   1611.00,1931.00 1550.00,1931.00 1550.00,1931.00   1550.00,1931.00 1550.00,1856.00 1550.00,1856.00 Z',
        attr: {
          id: '_315',
          'data-name': '315',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "315", zone: 'Upper Zone', zoneId: "3" },
      };
      var _316 = {
        path:'M 1650.00,1856.00 C 1650.00,1856.00 1709.00,1856.00 1709.00,1856.00   1709.00,1856.00 1709.00,1931.00 1709.00,1931.00   1709.00,1931.00 1650.00,1931.00 1650.00,1931.00   1650.00,1931.00 1650.00,1856.00 1650.00,1856.00 Z',
        attr: {
          id: '_316',
          'data-name': '316',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "316", zone: 'Upper Zone', zoneId: "3" },
      };
      var _317 = {
        path:'M 1748.00,1856.00 C 1748.00,1856.00 1833.00,1856.00 1833.00,1856.00   1833.00,1856.00 1833.00,1931.00 1833.00,1931.00   1833.00,1931.00 1748.00,1931.00 1748.00,1931.00   1748.00,1931.00 1748.00,1856.00 1748.00,1856.00 Z',
        attr: {
          id: '_317',
          'data-name': '317',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "317", zone: 'Upper Zone', zoneId: "3" },
      };
      var _318 = {
        path:'M 1872.00,1856.00 C 1872.00,1856.00 1953.00,1856.00 1953.00,1856.00   1953.00,1856.00 1953.00,1931.00 1953.00,1931.00   1953.00,1931.00 1872.00,1931.00 1872.00,1931.00   1872.00,1931.00 1872.00,1856.00 1872.00,1856.00 Z',
        attr: {
          id: '_318',
          'data-name': '318',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "318", zone: 'Upper Zone', zoneId: "3" },
      };
      var _319 = {
        path:'M 1992.00,1856.00 C 1992.00,1856.00 2054.00,1856.00 2054.00,1856.00   2054.00,1856.00 2054.00,1931.00 2054.00,1931.00   2054.00,1931.00 1992.00,1931.00 1992.00,1931.00   1992.00,1931.00 1992.00,1856.00 1992.00,1856.00 Zpath data',
        attr: {
          id: '_319',
          'data-name': '319',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "319", zone: 'Upper Zone', zoneId: "319" },
      };
      var _Fanzone = {
        path:'M 2198.00,862.00 C 2198.00,862.00 2250.00,862.00 2250.00,862.00   2250.00,862.00 2250.00,1442.00 2250.00,1442.00   2250.00,1442.00 2198.00,1442.00 2198.00,1442.00   2198.00,1442.00 2198.00,862.00 2198.00,862.00 Z',
        attr: {
          id: '_Fanzone',
          'data-name': 'Fan Zone',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Fan Zone', zoneId: "4" },
      };
      var _Mezzanine = {
        path:'M 2120.00,662.00 C 2120.00,662.00 2180.00,662.00 2180.00,662.00   2180.00,662.00 2180.00,1656.00 2180.00,1656.00   2180.00,1656.00 2120.00,1656.00 2120.00,1656.00   2120.00,1656.00 2120.00,662.00 2120.00,662.00 Z',
        attr: {
          id: '_Mezzanine',
          'data-name': 'Mezzanine',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Mezzanine', zoneId: "4" },
      };
      var _North3 = {
        path:'M 63.00,757.00 C 63.00,757.00 113.00,757.00 113.00,757.00   113.00,757.00 113.00,1551.00 113.00,1551.00   113.00,1551.00 63.00,1551.00 63.00,1551.00   63.00,1551.00 63.00,757.00 63.00,757.00 Z',
        attr: {
          id: '_North3',
          'data-name': 'North',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'North', zoneId: "4" },
      };
      var _South = {
        path:'M 2104.00,940.00 C 2104.00,940.00 2105.00,954.00 2105.00,954.00   2105.00,954.00 2105.00,979.00 2105.00,979.00   2105.00,979.00 2105.00,1066.00 2105.00,1066.00   2105.00,1066.00 2105.00,1145.00 2105.00,1145.00   2105.00,1145.00 2104.00,1160.00 2104.00,1160.00   2104.00,1160.00 2104.00,1371.00 2104.00,1371.00   2096.80,1365.85 2077.97,1345.59 2070.83,1338.04   2063.21,1329.98 2060.02,1331.28 2060.00,1319.00   2060.00,1319.00 2060.00,1243.00 2060.00,1243.00   2060.00,1243.00 2061.00,1228.00 2061.00,1228.00   2061.00,1228.00 2061.00,1127.00 2061.00,1127.00   2061.00,1127.00 2062.00,1112.00 2062.00,1112.00   2062.00,1112.00 2062.00,993.00 2062.00,993.00   2062.02,982.46 2066.90,980.77 2073.72,973.00   2073.72,973.00 2090.58,954.91 2090.58,954.91   2090.58,954.91 2104.00,940.00 2104.00,940.00 Z',
        attr: {
          id: '_South',
          'data-name': 'South',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'South', zoneId: "4" },
      };
      


zones.push(
_101, 
_101L, 
_102, 
_103,
_104,
_105,
_106,
_107,
_107L,
_107Box,
_108,
_109,
_110,
_111,
_111L,
_111Box,
_112,
_113,
_114,
_115,
_116,
_117,
_117L,
_301,
_302,
_303,
_304,
_305,
_306,
_307,
_308,
_309,
_310,
_311,
_312,
_313,
_314,
_315,
_316,
_317,
_318,
_319,
_Fanzone,
_Mezzanine,
_North3,
_South,


);
return zones;
};