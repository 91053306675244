export const getZones = (rsr) => {
    let zones = [];
  
    var _100 = {
      path:
        "M 991.00,1386.00 C 991.00,1386.00 1155.00,1386.00 1155.00,1386.00 1155.00,1386.00 1155.00,1433.00 1155.00,1433.00 1155.00,1440.37 1153.77,1448.73 1158.64,1454.90 1164.39,1462.20 1174.51,1463.00 1183.00,1463.00 1183.00,1463.00 1183.00,1780.00 1183.00,1780.00 1183.00,1780.00 991.00,1780.00 991.00,1780.00 991.00,1780.00 991.00,1386.00 991.00,1386.00 Z",
      attr: {
        id: "_100",
        "data-name": "100",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "100", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _100A = {
      path: "M 889.00,1386.00 C 889.00,1386.00 960.00,1386.00 960.00,1386.00 960.00,1386.00 960.00,1780.00 960.00,1780.00 960.00,1780.00 889.00,1780.00 889.00,1780.00 889.00,1780.00 889.00,1386.00 889.00,1386.00 Z",
      attr: {
        id: "_100A",
        "data-name": "100A",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "100", zone: "Lower Zone A", zoneId: "1" },
    };
  
    var _100B = {
      path: "M 1214.00,1463.00 C 1214.00,1463.00 1285.00,1463.00 1285.00,1463.00 1285.00,1463.00 1285.00,1780.00 1285.00,1780.00 1285.00,1780.00 1214.00,1780.00 1214.00,1780.00 1214.00,1780.00 1214.00,1463.00 1214.00,1463.00 Z",
      attr: {
        id: "_100B",
        "data-name": "100B",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "100", zone: "Lower Zone B", zoneId: "1" },
    };
  
    var _101 = {
      path:
        "M 1316.00,1386.00 C 1316.00,1386.00 1486.00,1386.00 1486.00,1386.00 1486.00,1386.00 1486.00,1760.00 1486.00,1760.00 1486.00,1760.00 1438.00,1760.00 1438.00,1760.00 1438.00,1760.00 1421.00,1761.00 1421.00,1761.00 1421.00,1761.00 1316.00,1761.00 1316.00,1761.00 1316.00,1761.00 1316.00,1386.00 1316.00,1386.00 Z",
      attr: {
        id: "_101",
        "data-name": "101",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "101", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _102 = {
      path:
        "M 1517.00,1386.00 C 1517.00,1386.00 1573.00,1386.00 1573.00,1386.00 1584.49,1385.98 1596.02,1383.21 1607.00,1380.00 1607.00,1380.00 1613.45,1397.00 1613.45,1397.00 1613.45,1397.00 1627.55,1431.00 1627.55,1431.00 1627.55,1431.00 1632.06,1444.00 1632.06,1444.00 1632.06,1444.00 1636.55,1454.00 1636.55,1454.00 1636.55,1454.00 1651.45,1493.00 1651.45,1493.00 1651.45,1493.00 1655.55,1502.00 1655.55,1502.00 1655.55,1502.00 1670.45,1541.00 1670.45,1541.00 1670.45,1541.00 1674.55,1550.00 1674.55,1550.00 1674.55,1550.00 1689.45,1589.00 1689.45,1589.00 1689.45,1589.00 1693.55,1598.00 1693.55,1598.00 1693.55,1598.00 1708.45,1637.00 1708.45,1637.00 1708.45,1637.00 1712.55,1646.00 1712.55,1646.00 1712.55,1646.00 1717.20,1659.00 1717.20,1659.00 1717.20,1659.00 1731.55,1694.00 1731.55,1694.00 1731.55,1694.00 1736.20,1707.00 1736.20,1707.00 1736.20,1707.00 1746.00,1732.00 1746.00,1732.00 1746.00,1732.00 1697.00,1751.00 1697.00,1751.00 1687.57,1754.77 1680.30,1758.98 1670.00,1759.00 1670.00,1759.00 1582.00,1759.00 1582.00,1759.00 1582.00,1759.00 1568.00,1760.00 1568.00,1760.00 1568.00,1760.00 1517.00,1760.00 1517.00,1760.00 1517.00,1760.00 1517.00,1386.00 1517.00,1386.00 Z",
      attr: {
        id: "_102",
        "data-name": "102",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "102", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _103 = {
      path:
        "M 1714.00,1287.00 C 1714.00,1287.00 1743.00,1299.13 1743.00,1299.13 1743.00,1299.13 1799.00,1323.13 1799.00,1323.13 1799.00,1323.13 1904.00,1367.87 1904.00,1367.87 1904.00,1367.87 1992.00,1405.45 1992.00,1405.45 1992.00,1405.45 2014.00,1415.13 2014.00,1415.13 2014.00,1415.13 2036.00,1424.31 2036.00,1424.31 2036.00,1424.31 2070.00,1439.00 2070.00,1439.00 2070.00,1439.00 2053.60,1481.00 2053.60,1481.00 2053.60,1481.00 2035.15,1526.00 2035.15,1526.00 2035.15,1526.00 2026.35,1546.00 2026.35,1546.00 2026.35,1546.00 1993.00,1579.09 1993.00,1579.09 1993.00,1579.09 1951.00,1620.09 1951.00,1620.09 1951.00,1620.09 1913.00,1657.17 1913.00,1657.17 1913.00,1657.17 1898.00,1670.93 1898.00,1670.93 1898.00,1670.93 1858.00,1687.00 1858.00,1687.00 1858.00,1687.00 1775.00,1720.00 1775.00,1720.00 1775.00,1720.00 1769.55,1706.00 1769.55,1706.00 1769.55,1706.00 1765.45,1697.00 1765.45,1697.00 1765.45,1697.00 1760.94,1684.00 1760.94,1684.00 1760.94,1684.00 1756.45,1674.00 1756.45,1674.00 1756.45,1674.00 1750.55,1658.00 1750.55,1658.00 1750.55,1658.00 1746.45,1649.00 1746.45,1649.00 1746.45,1649.00 1741.55,1635.00 1741.55,1635.00 1741.55,1635.00 1737.45,1626.00 1737.45,1626.00 1737.45,1626.00 1731.55,1610.00 1731.55,1610.00 1731.55,1610.00 1727.06,1600.00 1727.06,1600.00 1727.06,1600.00 1722.55,1587.00 1722.55,1587.00 1722.55,1587.00 1718.45,1578.00 1718.45,1578.00 1718.45,1578.00 1703.55,1539.00 1703.55,1539.00 1703.55,1539.00 1688.81,1503.00 1688.81,1503.00 1688.81,1503.00 1683.55,1488.00 1683.55,1488.00 1683.55,1488.00 1670.20,1456.00 1670.20,1456.00 1670.20,1456.00 1665.55,1443.00 1665.55,1443.00 1665.55,1443.00 1651.20,1408.00 1651.20,1408.00 1651.20,1408.00 1646.55,1395.00 1646.55,1395.00 1646.55,1395.00 1642.45,1386.00 1642.45,1386.00 1642.45,1386.00 1636.00,1368.00 1636.00,1368.00 1673.04,1346.67 1692.38,1323.04 1714.00,1287.00 Z",
      attr: {
        id: "_103",
        "data-name": "103",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "103", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _104 = {
      path:
        "M 1732.00,1127.00 C 1732.00,1127.00 2123.00,1127.00 2123.00,1127.00 2123.00,1127.00 2123.00,1306.00 2123.00,1306.00 2122.98,1317.65 2116.72,1328.39 2112.40,1339.00 2112.40,1339.00 2092.99,1386.00 2092.99,1386.00 2092.99,1386.00 2083.00,1411.00 2083.00,1411.00 2083.00,1411.00 1838.00,1306.13 1838.00,1306.13 1838.00,1306.13 1768.00,1276.13 1768.00,1276.13 1768.00,1276.13 1727.00,1259.00 1727.00,1259.00 1729.48,1249.64 1728.99,1247.37 1729.00,1238.00 1729.00,1238.00 1730.00,1223.00 1730.00,1223.00 1730.00,1223.00 1730.00,1209.00 1730.00,1209.00 1730.00,1209.00 1731.00,1195.00 1731.00,1195.00 1731.00,1195.00 1732.00,1127.00 1732.00,1127.00 Z",
      attr: {
        id: "_104",
        "data-name": "104",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "104", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _105 = {
      path:
        "M 1732.00,1096.00 C 1732.00,1096.00 1732.00,1038.00 1732.00,1038.00 1732.00,1038.00 1731.00,1023.00 1731.00,1023.00 1731.00,1023.00 1730.00,998.00 1730.00,998.00 1730.00,998.00 1727.99,974.00 1727.99,974.00 1727.81,971.51 1726.65,966.64 1727.99,964.65 1729.28,962.77 1732.94,961.75 1735.00,960.94 1735.00,960.94 1750.00,954.58 1750.00,954.58 1750.00,954.58 1776.00,943.43 1776.00,943.43 1776.00,943.43 1803.00,932.55 1803.00,932.55 1803.00,932.55 1825.00,922.87 1825.00,922.87 1825.00,922.87 1835.00,919.13 1835.00,919.13 1835.00,919.13 1870.00,903.87 1870.00,903.87 1870.00,903.87 1929.00,879.55 1929.00,879.55 1929.00,879.55 1951.00,869.87 1951.00,869.87 1951.00,869.87 1977.00,859.55 1977.00,859.55 1977.00,859.55 2005.00,847.55 2005.00,847.55 2005.00,847.55 2027.00,837.87 2027.00,837.87 2027.00,837.87 2055.00,826.55 2055.00,826.55 2055.00,826.55 2067.00,821.32 2067.00,821.32 2071.00,819.79 2083.77,812.67 2087.30,815.03 2089.23,816.32 2093.11,826.36 2094.30,829.00 2094.30,829.00 2111.30,867.00 2111.30,867.00 2111.30,867.00 2121.88,892.00 2121.88,892.00 2123.55,899.57 2123.00,910.03 2123.00,918.00 2123.00,918.00 2123.00,962.00 2123.00,962.00 2123.00,962.00 2123.00,1096.00 2123.00,1096.00 2123.00,1096.00 1732.00,1096.00 1732.00,1096.00 Z",
      attr: {
        id: "_105",
        "data-name": "105",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "105", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _106 = {
      path:
        "M 1783.00,496.00 C 1783.00,496.00 1851.00,524.40 1851.00,524.40 1851.00,524.40 1887.00,539.85 1887.00,539.85 1887.00,539.85 1906.00,557.83 1906.00,557.83 1906.00,557.83 1926.00,576.91 1926.00,576.91 1926.00,576.91 1955.00,604.04 1955.00,604.04 1955.00,604.04 1969.00,617.83 1969.00,617.83 1969.00,617.83 2010.00,656.91 2010.00,656.91 2010.00,656.91 2033.19,680.00 2033.19,680.00 2033.19,680.00 2048.20,717.00 2048.20,717.00 2048.20,717.00 2075.00,785.00 2075.00,785.00 2067.99,786.64 2052.37,793.91 2045.00,797.14 2045.00,797.14 2017.00,808.45 2017.00,808.45 2017.00,808.45 1994.00,818.55 1994.00,818.55 1994.00,818.55 1815.00,893.45 1815.00,893.45 1815.00,893.45 1792.00,903.55 1792.00,903.55 1792.00,903.55 1749.00,921.31 1749.00,921.31 1749.00,921.31 1730.00,929.25 1730.00,929.25 1727.63,930.38 1722.82,932.90 1720.22,932.52 1717.76,932.17 1717.10,929.92 1716.12,927.98 1716.12,927.98 1708.99,915.00 1708.99,915.00 1699.54,898.87 1688.49,886.77 1675.00,874.03 1667.01,866.48 1655.53,858.59 1646.00,853.01 1643.15,851.34 1634.57,847.79 1633.81,844.79 1633.23,842.51 1635.27,839.05 1636.22,837.00 1636.22,837.00 1643.55,820.00 1643.55,820.00 1643.55,820.00 1672.69,752.00 1672.69,752.00 1672.69,752.00 1699.87,688.00 1699.87,688.00 1699.87,688.00 1752.13,567.00 1752.13,567.00 1752.13,567.00 1783.00,496.00 1783.00,496.00 Z",
      attr: {
        id: "_106",
        "data-name": "106",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "106", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _107 = {
      path:
        "M 1520.00,451.00 C 1520.00,451.00 1667.00,451.00 1667.00,451.00 1678.75,451.02 1689.35,457.11 1700.00,461.58 1700.00,461.58 1754.00,484.00 1754.00,484.00 1754.00,484.00 1652.87,719.00 1652.87,719.00 1652.87,719.00 1603.00,835.00 1603.00,835.00 1594.13,832.44 1583.21,830.01 1574.00,830.00 1574.00,830.00 1520.00,830.00 1520.00,830.00 1520.00,830.00 1520.00,451.00 1520.00,451.00 Z",
      attr: {
        id: "_107",
        "data-name": "107",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "107", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _108 = {
      path:
        "M 1316.00,451.00 C 1316.00,451.00 1489.00,451.00 1489.00,451.00 1489.00,451.00 1489.00,830.00 1489.00,830.00 1489.00,830.00 1366.00,830.00 1366.00,830.00 1366.00,830.00 1351.00,829.00 1351.00,829.00 1351.00,829.00 1316.00,829.00 1316.00,829.00 1316.00,829.00 1316.00,451.00 1316.00,451.00 Z",
      attr: {
        id: "_108",
        "data-name": "108",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "108", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _109 = {
      path: "M 983.00,451.00 C 983.00,451.00 1191.00,451.00 1191.00,451.00 1191.00,451.00 1191.00,829.00 1191.00,829.00 1191.00,829.00 983.00,829.00 983.00,829.00 983.00,829.00 983.00,451.00 983.00,451.00 Z",
      attr: {
        id: "_109",
        "data-name": "109",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "109", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _109A = {
      path: "M 1222.00,451.00 C 1222.00,451.00 1285.00,451.00 1285.00,451.00 1285.00,451.00 1285.00,829.00 1285.00,829.00 1285.00,829.00 1222.00,829.00 1222.00,829.00 1222.00,829.00 1222.00,451.00 1222.00,451.00 Z",
      attr: {
        id: "_109A",
        "data-name": "109A",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "109", zone: "Lower Zone A", zoneId: "1" },
    };
  
    var _109B = {
      path: "M 889.00,451.00 C 889.00,451.00 952.00,451.00 952.00,451.00 952.00,451.00 952.00,829.00 952.00,829.00 952.00,829.00 889.00,829.00 889.00,829.00 889.00,829.00 889.00,451.00 889.00,451.00 Z",
      attr: {
        id: "_109B",
        "data-name": "109B",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "109", zone: "Lower Zone B", zoneId: "1" },
    };
  
    var _110 = {
      path:
        "M 685.00,451.00 C 685.00,451.00 858.00,451.00 858.00,451.00 858.00,451.00 858.00,829.00 858.00,829.00 858.00,829.00 734.00,829.00 734.00,829.00 734.00,829.00 719.00,830.00 719.00,830.00 719.00,830.00 687.00,830.00 687.00,830.00 687.00,830.00 687.00,699.00 687.00,699.00 687.00,699.00 686.00,684.00 686.00,684.00 686.00,684.00 686.00,520.00 686.00,520.00 686.00,520.00 685.00,505.00 685.00,505.00 685.00,505.00 685.00,451.00 685.00,451.00 Z",
      attr: {
        id: "_110",
        "data-name": "110",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "110", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _111 = {
      path:
        "M 424.00,488.00 C 424.00,488.00 481.00,462.85 481.00,462.85 491.27,458.44 501.64,452.02 513.00,452.00 513.00,452.00 557.00,452.00 557.00,452.00 557.00,452.00 574.00,451.00 574.00,451.00 574.00,451.00 654.00,451.00 654.00,451.00 654.00,451.00 654.00,510.00 654.00,510.00 654.00,510.00 655.00,527.00 655.00,527.00 655.00,527.00 655.00,691.00 655.00,691.00 655.00,691.00 656.00,706.00 656.00,706.00 656.00,706.00 656.00,831.00 656.00,831.00 656.00,831.00 598.00,831.00 598.00,831.00 591.20,831.01 582.69,832.06 576.00,833.40 573.44,833.91 567.69,835.42 565.39,834.41 561.88,832.87 558.09,819.02 556.22,815.00 556.22,815.00 549.55,798.00 549.55,798.00 549.55,798.00 538.45,772.00 538.45,772.00 538.45,772.00 530.78,753.00 530.78,753.00 530.78,753.00 525.55,739.00 525.55,739.00 525.55,739.00 511.55,705.00 511.55,705.00 511.55,705.00 483.45,637.00 483.45,637.00 483.45,637.00 470.55,604.00 470.55,604.00 470.55,604.00 466.45,595.00 466.45,595.00 466.45,595.00 462.55,584.00 462.55,584.00 462.55,584.00 451.45,558.00 451.45,558.00 451.45,558.00 439.13,527.00 439.13,527.00 435.34,518.14 425.90,496.34 424.00,488.00 Z",
      attr: {
        id: "_111",
        "data-name": "111",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "111", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _112 = {
      path:
        "M 379.00,463.00 C 379.00,463.00 399.06,512.00 399.06,512.00 399.06,512.00 410.55,539.00 410.55,539.00 410.55,539.00 426.55,578.00 426.55,578.00 426.55,578.00 430.45,589.00 430.45,589.00 430.45,589.00 450.55,637.00 450.55,637.00 450.55,637.00 454.45,648.00 454.45,648.00 454.45,648.00 482.55,716.00 482.55,716.00 482.55,716.00 486.45,727.00 486.45,727.00 486.45,727.00 496.55,750.00 496.55,750.00 496.55,750.00 509.45,783.00 509.45,783.00 509.45,783.00 513.55,792.00 513.55,792.00 513.55,792.00 517.45,803.00 517.45,803.00 517.45,803.00 528.55,829.00 528.55,829.00 528.55,829.00 536.00,848.00 536.00,848.00 511.73,861.98 493.40,875.96 476.74,899.00 466.67,912.93 464.71,919.46 457.00,933.00 457.00,933.00 437.00,924.31 437.00,924.31 437.00,924.31 412.00,913.31 412.00,913.31 412.00,913.31 326.00,875.31 326.00,875.31 326.00,875.31 251.00,842.31 251.00,842.31 251.00,842.31 226.00,831.00 226.00,831.00 226.00,831.00 240.00,795.00 240.00,795.00 240.00,795.00 245.03,780.09 245.03,780.09 245.03,780.09 238.00,773.12 238.00,773.12 238.00,773.12 216.00,757.29 216.00,757.29 216.00,757.29 142.00,703.88 142.00,703.88 142.00,703.88 123.00,690.29 123.00,690.29 123.00,690.29 116.34,683.91 116.34,683.91 116.34,683.91 118.78,678.00 118.78,678.00 118.78,678.00 129.99,666.00 129.99,666.00 129.99,666.00 173.96,619.00 173.96,619.00 173.96,619.00 210.09,580.00 210.09,580.00 210.09,580.00 247.91,540.00 247.91,540.00 247.91,540.00 274.00,512.00 274.00,512.00 274.00,512.00 283.09,502.70 283.09,502.70 283.09,502.70 308.00,492.15 308.00,492.15 308.00,492.15 379.00,463.00 379.00,463.00 Z",
      attr: {
        id: "_112",
        "data-name": "112",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "112", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _113 = {
      path:
        "M 214.00,861.00 C 214.00,861.00 268.00,884.13 268.00,884.13 268.00,884.13 399.00,941.43 399.00,941.43 399.00,941.43 447.00,962.00 447.00,962.00 447.00,962.00 444.00,985.00 444.00,985.00 444.00,985.00 443.00,1001.00 443.00,1001.00 443.00,1001.00 443.00,1020.00 443.00,1020.00 443.00,1020.00 442.00,1038.00 442.00,1038.00 442.00,1038.00 189.00,1038.00 189.00,1038.00 189.00,1038.00 189.00,931.00 189.00,931.00 189.02,920.70 193.23,913.43 197.00,904.00 197.00,904.00 214.00,861.00 214.00,861.00 Z",
      attr: {
        id: "_113",
        "data-name": "113",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "113", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _114 = {
      path:
        "M 189.00,1069.00 C 189.00,1069.00 227.00,1069.00 227.00,1069.00 227.00,1069.00 227.00,1163.00 227.00,1163.00 227.00,1163.00 440.00,1163.00 440.00,1163.00 440.00,1163.00 440.00,1220.00 440.00,1220.00 440.00,1220.00 445.00,1255.00 445.00,1255.00 445.00,1255.00 393.00,1278.69 393.00,1278.69 393.00,1278.69 342.00,1301.31 342.00,1301.31 342.00,1301.31 274.00,1331.87 274.00,1331.87 274.00,1331.87 213.00,1359.00 213.00,1359.00 213.00,1359.00 197.32,1318.00 197.32,1318.00 193.46,1307.57 189.02,1299.31 189.00,1288.00 189.00,1288.00 189.00,1211.00 189.00,1211.00 189.00,1211.00 189.00,1069.00 189.00,1069.00 Z",
      attr: {
        id: "_114",
        "data-name": "114",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "114", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _115 = {
      path:
        "M 224.00,1389.00 C 224.00,1389.00 292.00,1358.13 292.00,1358.13 292.00,1358.13 320.00,1345.31 320.00,1345.31 320.00,1345.31 373.00,1321.69 373.00,1321.69 373.00,1321.69 426.00,1297.69 426.00,1297.69 433.86,1294.09 449.11,1286.47 457.00,1285.00 469.22,1313.35 495.38,1338.81 521.00,1355.31 521.00,1355.31 545.00,1369.00 545.00,1369.00 545.00,1369.00 500.87,1473.00 500.87,1473.00 500.87,1473.00 426.87,1646.00 426.87,1646.00 426.87,1646.00 423.13,1656.00 423.13,1656.00 423.13,1656.00 393.13,1726.00 393.13,1726.00 393.13,1726.00 380.00,1757.00 380.00,1757.00 380.00,1757.00 333.00,1736.31 333.00,1736.31 333.00,1736.31 300.00,1721.31 300.00,1721.31 300.00,1721.31 282.00,1712.51 282.00,1712.51 282.00,1712.51 251.00,1682.00 251.00,1682.00 251.00,1682.00 190.00,1621.00 190.00,1621.00 190.00,1621.00 151.01,1582.00 151.01,1582.00 151.01,1582.00 140.00,1570.00 140.00,1570.00 140.00,1570.00 110.00,1539.00 110.00,1539.00 110.00,1539.00 207.00,1467.15 207.00,1467.15 207.00,1467.15 232.00,1448.63 232.00,1448.63 232.00,1448.63 241.46,1439.96 241.46,1439.96 241.46,1439.96 237.19,1425.00 237.19,1425.00 237.19,1425.00 224.00,1389.00 224.00,1389.00 Z",
      attr: {
        id: "_115",
        "data-name": "115",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "115", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _116 = {
      path:
        "M 574.00,1381.00 C 574.00,1381.00 601.00,1386.00 601.00,1386.00 601.00,1386.00 670.00,1386.00 670.00,1386.00 670.00,1386.00 670.00,1780.00 670.00,1780.00 670.00,1780.00 514.00,1780.00 514.00,1780.00 501.99,1779.98 490.97,1773.60 480.00,1769.20 480.00,1769.20 447.00,1755.60 447.00,1755.60 447.00,1755.60 419.00,1744.00 419.00,1744.00 419.00,1744.00 574.00,1381.00 574.00,1381.00 Z",
      attr: {
        id: "_116",
        "data-name": "116",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "116", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _117 = {
      path: "M 701.00,1386.00 C 701.00,1386.00 858.00,1386.00 858.00,1386.00 858.00,1386.00 858.00,1780.00 858.00,1780.00 858.00,1780.00 701.00,1780.00 701.00,1780.00 701.00,1780.00 701.00,1386.00 701.00,1386.00 Z",
      attr: {
        id: "_117",
        "data-name": "117",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "117", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _ADA = {
      path: "M 1186.00,1386.00 C 1186.00,1386.00 1285.00,1386.00 1285.00,1386.00 1285.00,1386.00 1285.00,1432.00 1285.00,1432.00 1285.00,1432.00 1186.00,1432.00 1186.00,1432.00 1186.00,1432.00 1186.00,1386.00 1186.00,1386.00 Z",
      attr: {
        id: "ADA",
        "data-name": "ADA",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "ADA", zone: "Lower Zone", zoneId: "1" },
    };
  
    var _100B = {
      path: "",
      attr: {
        id: "_100B",
        "data-name": "1",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "100", zone: "Lower Zone B", zoneId: "1" },
    };
  
    zones.push(_100, _100A, _100B, _101, _102, _103, _104, _105, _106, _107, _108, _109, _109A, _109B, _110, _111, _112, _113, _114, _115, _116, _117, _ADA);
    return zones;
  };
  