import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Helmet from 'react-helmet';
import Hidden from '@material-ui/core/Hidden';

import AcceptMobileTransferDesktop from '../components/AcceptMobileTransferDesktop';
import AcceptMobileTransferMobile from '../components/AcceptMobileTransferMobile';

const styles = theme => ({
  li: {
    marginBottom: '4rem'
  }
});

const HowToAcceptMobileTransfer = ({ classes }) => {
  const currentURL = window.location.href;
  return (
    <main>
      <Helmet>
        <title>How To Accept Mobile Transfer</title>
        <meta name="description" content="You've bought your tickets! What's next?" />
        <meta name="keywords" content={`games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="How To Accept Mobile Transfer" />
        <meta property="og:description" content="You've bought your tickets! What's next?" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentURL} />
        <link rel="canonical" href={currentURL} />
      </Helmet>
      <Grid
        container
        justify="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          xl={5}
        >
          <Typography
            variant="display1"
            color="primary"
            style={{
              marginBottom: '1rem',
              marginTop: '2rem'
            }}
          >
            You’ve Bought Your Tickets!  What’s Next? 
          </Typography>
          <Typography
            variant="subheading"
            style={{marginBottom: '3rem'}}
          >
            Here’s a step-by-step explanation:
          </Typography>

          <Hidden xsDown>
            <AcceptMobileTransferDesktop classes={classes} />
          </Hidden>
          <Hidden mdUp>
            <AcceptMobileTransferMobile classes={classes} />
          </Hidden>
        </Grid>
      </Grid>
    </main>
  );
};

export default withStyles(styles)(HowToAcceptMobileTransfer);