import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Helmet from 'react-helmet';

import CompleteTransfer from '../images/how-tos/mobile-transfer/complete-transfer.png';
import ConfirmTransfer from '../images/how-tos/mobile-transfer/confirm-transfer.png';
import Login from '../images/how-tos/mobile-transfer/login.png';
import ManageMyTickets from '../images/how-tos/mobile-transfer/manage-my-tickets.png';
import NewContact from '../images/how-tos/mobile-transfer/new-contact.png';
import SelectContact from '../images/how-tos/mobile-transfer/select-contact.png';
import SelectTickets from '../images/how-tos/mobile-transfer/select-tickets.png';
import TransferTickets from '../images/how-tos/mobile-transfer/transfer-tickets.png';
import Transfer from '../images/how-tos/mobile-transfer/transfer.png';

const styles = theme => ({
  li: {
    marginBottom: '4rem'
  }
});

const HowToMobileTransfer = ({ classes }) => {
  const currentURL = window.location.href;
  return (
    <main>
      <Helmet>
        <title>How To Mobile Transfer</title>
        <meta name="description" content="You've sold your tickets! What's next?" />
        <meta name="keywords" content={`games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="How To Mobile Transfer" />
        <meta property="og:description" content="You've sold your tickets! What's next?" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentURL} />
        <link rel="canonical" href={currentURL} />
      </Helmet>
      <Grid
        container
        justify="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          xl={5}
        >
          <Typography
            variant="display1"
            color="primary"
            style={{
              marginBottom: '1rem',
              marginTop: '2rem'
            }}
          >
            You’ve Sold Your Tickets! What’s Next? 
          </Typography>
          <Typography
            variant="subheading"
            style={{marginBottom: '3rem'}}
          >
            Here’s a step-by-step explanation:
          </Typography>

          <ol
            style={{
              margin: 0,
              marginBottom: '1rem',
              padding: '0 1rem'
            }}
          >
            <li style={{marginBottom: '1.5rem'}}>
              <Typography
                variant="body1"
                gutterBottom={true}
              >
                Log onto your Calgary Flames ticket account: <a style={{color: '#2196f3'}} href="https://oss.ticketmaster.com/html/home.htmI?team=flames&l=EN&STAGE=1&camefrom=EMCL_2609072_119957138" target="_blank" rel="noopener noreferrer">HERE</a>
              </Typography>
            </li>
            <li className={classes.li}>
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Enter your Account ID / Email Address and Password:
              </Typography>
              <img width="100%" src={Login} alt="Login" />
            </li>
            <li className={classes.li}>
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Click "manage my tickets":
              </Typography>
              <img width="100%" src={ManageMyTickets} alt="Manage My Tickets" style={{marginBottom: '1rem'}} />
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                A calendar will appear and you can select the game you are selling.
              </Typography>
            </li>
            <li className={classes.li}>
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Then select “Transfer Tickets”:
                <br/>
                This will allow you the select games.  Note: if you do not select “Transfer Tickets”, the system won’t let you select your tickets.
              </Typography>
              <img width="75%" src={TransferTickets} alt="Transfer Tickets" />
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Then select your tickets.
              </Typography>
              <img width="100%" src={SelectTickets} alt="Select Tickets" />
            </li>
            <li className={classes.li}>
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Click "transfer":
              </Typography>
              <img width="50%" src={Transfer} alt="Transfer" style={{marginBottom: '1rem'}} />
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                You'll be asked to confirm your transfer. Click "Continue to transfer".
              </Typography>
              <img width="100%" src={ConfirmTransfer} alt="Confirm Transfer" />
            </li>
            <li className={classes.li}>
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Click the dropdown arrow to “Enter a New Contact”.  Here you will include the buyer’s information.  Then click "add":
              </Typography>
              <img width="100%" src={NewContact} alt="New Contact" />
            </li>
            <li className={classes.li}>
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Select the new contact you just created:
              </Typography>
              <img width="100%" src={SelectContact} alt="Select Contact" />
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                And click "Next Step"
              </Typography>
            </li>
            <li className={classes.li}>
              <Typography
                variant="body1"
                style={{marginBottom: '1.5rem'}}
                gutterBottom={true}
              >
                Agree to the “Terms of Use”, click “complete transfer” and you’re done!
              </Typography>
              <img width="100%" src={CompleteTransfer} alt="Complete Transfer" />
            </li>
          </ol>
        </Grid>
      </Grid>
    </main>
  );
};

export default withStyles(styles)(HowToMobileTransfer);