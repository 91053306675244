import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import { ArrowUpwardSharp, ArrowDownwardSharp } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    marginLeft: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
  active: {
    fontSize: 20,
    transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  inactive: {
    color: theme.palette.grey[400],
    fontSize: 14,
  },
  up: {
    transform: "rotate(180deg)",
  },
  down: {
    transform: "rotate(0deg)",
  }
});

const CustomTableSortLabel = ({ active, direction, children, onClick, classes }) => {
  return (
    <div className={classes.root} onClick={onClick}>
      {children}
      <div className={classes.icon}>
        {active ? (
          <ArrowDownwardSharp
            className={`${classes.active} ${direction === "asc" ? classes.up : classes.down}`}
          />
        ) : (
          <>
            <ArrowUpwardSharp
              className={classes.inactive}
            />
            <ArrowDownwardSharp
              className={classes.inactive}
            />
          </>
        )}
      </div>
    </div>
  );
};

CustomTableSortLabel.propTypes = {
  active: PropTypes.bool,
  direction: PropTypes.oneOf(['asc', 'desc']),
  onClick: PropTypes.func.isRequired,
};

CustomTableSortLabel.defaultProps = {
  direction: "desc"
}

export default withStyles(styles)(CustomTableSortLabel);
