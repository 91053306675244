const flamesGames = [
  '76ad7d50-97ef-11e9-907b-130afab5b563',
  '76ad7d58-97ef-11e9-907b-130afab5b563',
  '76adcb71-97ef-11e9-907b-130afab5b563',
  '76ada461-97ef-11e9-907b-130afab5b563',
  '76adcb75-97ef-11e9-907b-130afab5b563',
  '76ad5641-97ef-11e9-907b-130afab5b563',
  '76ad5642-97ef-11e9-907b-130afab5b563',
  '76ae1992-97ef-11e9-907b-130afab5b563',
  '76adf286-97ef-11e9-907b-130afab5b563',
  '76ae1990-97ef-11e9-907b-130afab5b563',
  '76ada467-97ef-11e9-907b-130afab5b563',
  '76adcb77-97ef-11e9-907b-130afab5b563',
  '76ada468-97ef-11e9-907b-130afab5b563',
  '76adf285-97ef-11e9-907b-130afab5b563',
  '76ada465-97ef-11e9-907b-130afab5b563',
  '76adcb79-97ef-11e9-907b-130afab5b563',
  '76ad7d53-97ef-11e9-907b-130afab5b563',
  '76ada464-97ef-11e9-907b-130afab5b563',
  '76adf280-97ef-11e9-907b-130afab5b563',
  '76adf283-97ef-11e9-907b-130afab5b563',
  '76ada460-97ef-11e9-907b-130afab5b563',
  '76adcb70-97ef-11e9-907b-130afab5b563',
  '76adcb76-97ef-11e9-907b-130afab5b563',
  '76ae1993-97ef-11e9-907b-130afab5b563',
  '76adf284-97ef-11e9-907b-130afab5b563',
  '76ad7d56-97ef-11e9-907b-130afab5b563',
  '76ada466-97ef-11e9-907b-130afab5b563',
  '76ada469-97ef-11e9-907b-130afab5b563',
  '76ad5643-97ef-11e9-907b-130afab5b563',
  '76ad7d51-97ef-11e9-907b-130afab5b563',
  '76adcb78-97ef-11e9-907b-130afab5b563',
  '76ad7d55-97ef-11e9-907b-130afab5b563',
  '76ad5640-97ef-11e9-907b-130afab5b563',
  '76ada462-97ef-11e9-907b-130afab5b563',
  '76ada463-97ef-11e9-907b-130afab5b563',
  '76ad7d59-97ef-11e9-907b-130afab5b563',
  '76ae1991-97ef-11e9-907b-130afab5b563',
  '76adcb73-97ef-11e9-907b-130afab5b563',
  '76ad7d52-97ef-11e9-907b-130afab5b563',
  '76adcb74-97ef-11e9-907b-130afab5b563',
  '76ad7d57-97ef-11e9-907b-130afab5b563',
  '76adf282-97ef-11e9-907b-130afab5b563',
  '76adcb72-97ef-11e9-907b-130afab5b563',
  '76adf281-97ef-11e9-907b-130afab5b563',
  '76ad7d54-97ef-11e9-907b-130afab5b563'
];

const jetsGames = [
  '85f9d5f2-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2416-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9d5f5-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2417-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9d5f0-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9aee1-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa9943-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2415-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa7231-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa7230-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b20-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9d5f7-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b24-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd06-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9d5f3-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9aee0-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b23-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b21-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2411-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa7232-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2413-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9d5f1-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa9941-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9d5f6-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa7234-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f987d0-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b26-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd00-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd03-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd02-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9d5f4-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa9940-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd07-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9aee2-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2414-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b25-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa7233-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd01-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd05-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b27-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2410-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa4b22-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa9942-c4d2-11e9-9c7d-3d27e9d2a774',
  '85fa2412-c4d2-11e9-9c7d-3d27e9d2a774',
  '85f9fd04-c4d2-11e9-9c7d-3d27e9d2a774'
];

export default id => {
  if (flamesGames.findIndex(game => game === id) >= 0) {
    return 'America/Edmonton';
  } else {
    return 'America/Regina';
  }
};
