export const getZones = (rsr) => {
  let zones = [];
  var _TheDen = {
    path:
      "M 427.00,1229.00 C 427.00,1229.00 405.00,1237.66 405.00,1237.66 402.67,1238.46 398.71,1240.14 396.39,1238.89 394.04,1237.61 392.38,1232.46 391.40,1230.00 388.15,1221.87 385.61,1213.54 383.65,1205.00 377.94,1180.10 375.64,1157.33 373.91,1132.00 373.91,1132.00 373.00,1120.00 373.00,1120.00 373.00,1120.00 372.00,1097.00 372.00,1097.00 372.00,1097.00 372.00,1039.00 372.00,1039.00 372.00,1039.00 371.00,1022.00 371.00,1022.00 371.00,1022.00 371.00,862.00 371.00,862.00 371.00,862.00 371.91,850.00 371.91,850.00 373.60,825.81 377.98,790.98 385.02,768.00 385.02,768.00 391.60,748.00 391.60,748.00 392.55,745.63 393.69,741.81 396.25,740.78 399.31,739.56 403.28,741.95 406.00,743.22 406.00,743.22 430.00,754.00 430.00,754.00 430.00,754.00 422.34,777.00 422.34,777.00 422.34,777.00 415.42,806.00 415.42,806.00 411.77,826.26 410.97,845.50 411.00,866.00 411.00,866.00 412.00,887.00 412.00,887.00 415.47,887.63 426.05,890.70 427.98,893.50 429.52,895.73 429.00,904.00 429.00,907.00 429.00,907.00 429.00,942.00 429.00,942.00 429.00,942.00 428.00,959.00 428.00,959.00 428.00,959.00 428.00,1084.00 428.00,1084.00 428.00,1086.10 428.25,1089.61 427.01,1091.35 424.86,1094.36 417.54,1095.08 414.56,1098.41 412.15,1101.11 412.04,1105.58 411.96,1109.00 411.96,1109.00 411.96,1121.00 411.96,1121.00 411.96,1121.00 411.00,1136.00 411.00,1136.00 411.00,1136.00 411.96,1146.00 411.96,1146.00 411.96,1146.00 411.96,1158.00 411.96,1158.00 412.15,1170.64 415.94,1194.76 419.43,1207.00 419.43,1207.00 427.00,1229.00 427.00,1229.00 Z",
    attr: {
      id: "_TheDen",
      "data-name": "TheDen",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "", zone: "The Den", zoneId: "1" },
  };
  var _201 = {
    path:
      "M 1548.00,998.00 C 1548.00,998.00 1615.00,998.00 1615.00,998.00 1615.00,998.00 1615.00,1047.00 1615.00,1047.00 1615.00,1047.00 1453.00,1047.00 1453.00,1047.00 1453.00,1047.00 1454.00,1019.00 1454.00,1019.00 1454.00,1019.00 1548.00,1019.00 1548.00,1019.00 1548.00,1019.00 1548.00,998.00 1548.00,998.00 Z",
    attr: {
      id: "_201",
      "data-name": "201",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "201", zone: "Lower Zone", zoneId: "2" },
  };
  var _202 = {
    path:
      "M 1453.00,1064.00 C 1453.00,1064.00 1615.00,1064.00 1615.00,1064.00 1615.00,1064.00 1615.00,1120.00 1615.00,1120.00 1615.00,1120.00 1499.00,1102.72 1499.00,1102.72 1499.00,1102.72 1453.00,1096.00 1453.00,1096.00 1453.00,1096.00 1453.00,1064.00 1453.00,1064.00 Z",
    attr: {
      id: "_202",
      "data-name": "202",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "202", zone: "Lower Zone", zoneId: "2" },
  };
  var _203 = {
    path:
      "M 1453.00,1113.00 C 1453.00,1113.00 1499.00,1119.72 1499.00,1119.72 1499.00,1119.72 1614.00,1137.00 1614.00,1137.00 1614.00,1137.00 1608.00,1192.00 1608.00,1192.00 1608.00,1192.00 1562.00,1181.58 1562.00,1181.58 1562.00,1181.58 1522.00,1172.42 1522.00,1172.42 1522.00,1172.42 1489.00,1165.27 1489.00,1165.27 1489.00,1165.27 1461.00,1158.88 1461.00,1158.88 1461.00,1158.88 1450.02,1155.28 1450.02,1155.28 1450.02,1155.28 1450.02,1144.00 1450.02,1144.00 1450.02,1144.00 1453.00,1113.00 1453.00,1113.00 Z",
    attr: {
      id: "_203",
      "data-name": "203",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "203", zone: "Lower Zone", zoneId: "2" },
  };

  var _204 = {
    path:
      "M 1445.00,1173.00 C 1445.00,1173.00 1498.00,1184.35 1498.00,1184.35 1498.00,1184.35 1575.00,1201.42 1575.00,1201.42 1575.00,1201.42 1604.00,1208.00 1604.00,1208.00 1603.73,1213.86 1602.11,1219.55 1599.93,1225.00 1599.01,1227.30 1597.76,1230.50 1594.90,1230.77 1593.21,1230.93 1581.72,1225.94 1579.00,1225.00 1575.64,1239.32 1566.91,1251.76 1561.00,1265.00 1561.00,1265.00 1528.00,1247.28 1528.00,1247.28 1528.00,1247.28 1471.00,1215.87 1471.00,1215.87 1471.00,1215.87 1448.00,1203.30 1448.00,1203.30 1448.00,1203.30 1439.35,1196.79 1439.35,1196.79 1439.35,1196.79 1440.67,1189.00 1440.67,1189.00 1440.67,1189.00 1445.00,1173.00 1445.00,1173.00 Z",
    attr: {
      id: "_204",
      "data-name": "204",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "204", zone: "Lower Zone", zoneId: "2" },
  };
  var _206 = {
    path:
      "M 1404.00,1198.00 C 1404.00,1198.00 1448.00,1221.95 1448.00,1221.95 1448.00,1221.95 1511.00,1256.54 1511.00,1256.54 1511.00,1256.54 1551.00,1278.00 1551.00,1278.00 1549.44,1284.38 1544.03,1290.69 1540.12,1296.00 1533.34,1305.19 1515.44,1327.94 1507.00,1334.00 1507.00,1334.00 1485.42,1310.01 1485.42,1310.01 1485.42,1310.01 1476.73,1300.96 1476.73,1300.96 1476.73,1300.96 1460.58,1282.04 1460.58,1282.04 1460.58,1282.04 1421.75,1239.00 1421.75,1239.00 1421.75,1239.00 1402.00,1217.00 1402.00,1217.00 1402.00,1217.00 1396.18,1208.09 1396.18,1208.09 1396.18,1208.09 1404.00,1198.00 1404.00,1198.00 Z",
    attr: {
      id: "_206",
      "data-name": "206",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "206", zone: "Lower Zone", zoneId: "2" },
  };
  var _207 = {
    path:
      "M 1356.00,1235.00 C 1356.00,1235.00 1374.00,1223.98 1374.00,1223.98 1374.00,1223.98 1381.91,1220.02 1381.91,1220.02 1381.91,1220.02 1391.42,1229.00 1391.42,1229.00 1391.42,1229.00 1412.87,1253.00 1412.87,1253.00 1412.87,1253.00 1450.83,1295.00 1450.83,1295.00 1450.83,1295.00 1472.96,1320.00 1472.96,1320.00 1472.96,1320.00 1496.00,1345.00 1496.00,1345.00 1491.88,1350.75 1481.32,1361.01 1475.00,1364.00 1475.00,1364.00 1485.00,1384.00 1485.00,1384.00 1476.09,1389.58 1457.19,1403.81 1448.00,1406.00 1448.00,1406.00 1421.67,1357.00 1421.67,1357.00 1421.67,1357.00 1379.83,1280.00 1379.83,1280.00 1379.83,1280.00 1356.00,1235.00 1356.00,1235.00 Z",
    attr: {
      id: "_207",
      "data-name": "207",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "207", zone: "Lower Zone", zoneId: "2" },
  };
  var _209 = {
    path:
      "M 1312.00,1254.00 C 1312.00,1254.00 1341.00,1243.00 1341.00,1243.00 1341.00,1243.00 1397.67,1347.00 1397.67,1347.00 1397.67,1347.00 1420.49,1389.00 1420.49,1389.00 1420.49,1389.00 1434.00,1415.00 1434.00,1415.00 1414.88,1423.24 1387.93,1437.46 1368.00,1442.00 1368.00,1442.00 1351.26,1387.00 1351.26,1387.00 1351.26,1387.00 1332.26,1324.00 1332.26,1324.00 1332.26,1324.00 1325.04,1301.00 1325.04,1301.00 1325.04,1301.00 1318.97,1280.00 1318.97,1280.00 1318.97,1280.00 1312.00,1254.00 1312.00,1254.00 Z",
    attr: {
      id: "_209",
      "data-name": "209",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "209", zone: "Lower Zone", zoneId: "2" },
  };

  var _210 = {
    path:
      "M 1256.00,1268.00 C 1256.00,1268.00 1296.00,1260.00 1296.00,1260.00 1296.00,1260.00 1302.26,1281.00 1302.26,1281.00 1302.26,1281.00 1319.96,1340.00 1319.96,1340.00 1319.96,1340.00 1337.96,1400.00 1337.96,1400.00 1337.96,1400.00 1352.00,1447.00 1352.00,1447.00 1352.00,1447.00 1326.00,1454.37 1326.00,1454.37 1326.00,1454.37 1292.00,1461.00 1292.00,1461.00 1292.00,1461.00 1266.54,1324.00 1266.54,1324.00 1266.54,1324.00 1256.00,1268.00 1256.00,1268.00 Z",
    attr: {
      id: "_210",
      "data-name": "210",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "210", zone: "Lower Zone", zoneId: "2" },
  };
  var _211 = {
    path:
      "M 1194.00,1270.00 C 1194.00,1270.00 1239.00,1270.00 1239.00,1270.00 1239.00,1270.00 1262.45,1395.00 1262.45,1395.00 1262.45,1395.00 1270.61,1439.00 1270.61,1439.00 1270.61,1439.00 1275.00,1464.00 1275.00,1464.00 1266.89,1463.55 1263.65,1459.94 1257.00,1455.81 1257.00,1455.81 1231.00,1439.42 1231.00,1439.42 1220.88,1433.09 1219.15,1430.01 1207.00,1430.00 1207.00,1430.00 1205.17,1408.00 1205.17,1408.00 1205.17,1408.00 1202.17,1371.00 1202.17,1371.00 1202.17,1371.00 1199.83,1338.00 1199.83,1338.00 1199.83,1338.00 1194.00,1270.00 1194.00,1270.00 Z",
    attr: {
      id: "_211",
      "data-name": "211",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "211", zone: "Lower Zone", zoneId: "2" },
  };
  var _212 = {
    path:
      "M 1135.00,1270.00 C 1135.00,1270.00 1177.00,1270.00 1177.00,1270.00 1177.00,1270.00 1180.91,1316.00 1180.91,1316.00 1180.91,1316.00 1183.91,1352.00 1183.91,1352.00 1183.91,1352.00 1186.17,1384.00 1186.17,1384.00 1186.17,1384.00 1188.17,1409.00 1188.17,1409.00 1188.17,1409.00 1190.00,1430.00 1190.00,1430.00 1190.00,1430.00 1166.00,1430.00 1166.00,1430.00 1166.00,1430.00 1151.00,1431.00 1151.00,1431.00 1151.00,1431.00 1135.00,1431.00 1135.00,1431.00 1135.00,1431.00 1135.00,1270.00 1135.00,1270.00 Z",
    attr: {
      id: "_212",
      "data-name": "212",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "212", zone: "Lower Zone", zoneId: "2" },
  };
  var _213 = {
    path: "M 1060.00,1270.00 C 1060.00,1270.00 1118.00,1270.00 1118.00,1270.00 1118.00,1270.00 1118.00,1431.00 1118.00,1431.00 1118.00,1431.00 1060.00,1431.00 1060.00,1431.00 1060.00,1431.00 1060.00,1270.00 1060.00,1270.00 Z",
    attr: {
      id: "_213",
      "data-name": "213",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "213", zone: "Lower Zone", zoneId: "2" },
  };
  var _214 = {
    path:
      "M 984.00,1270.00 C 984.00,1270.00 1043.00,1270.00 1043.00,1270.00 1043.00,1270.00 1043.00,1431.00 1043.00,1431.00 1043.00,1431.00 999.00,1431.00 999.00,1431.00 990.16,1431.06 991.34,1432.80 985.00,1438.00 985.00,1438.00 985.00,1375.00 985.00,1375.00 985.00,1375.00 984.00,1358.00 984.00,1358.00 984.00,1358.00 984.00,1270.00 984.00,1270.00 Z",
    attr: {
      id: "_214",
      "data-name": "214",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "214", zone: "Lower Zone", zoneId: "2" },
  };
  var _215 = {
    path:
      "M 918.00,1270.00 C 918.00,1270.00 967.00,1270.00 967.00,1270.00 967.00,1270.00 967.00,1358.00 967.00,1358.00 967.00,1358.00 968.00,1373.00 968.00,1373.00 968.00,1373.00 968.00,1416.00 968.00,1416.00 968.00,1416.00 968.00,1448.00 968.00,1448.00 967.99,1451.44 968.33,1455.03 965.69,1457.69 962.44,1460.95 954.39,1460.99 950.00,1461.00 941.69,1461.01 925.31,1460.22 918.00,1462.00 918.00,1462.00 918.00,1270.00 918.00,1270.00 Z",
    attr: {
      id: "_215",
      "data-name": "215",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "215", zone: "Lower Zone", zoneId: "2" },
  };

  var _216 = {
    path:
      "M 842.00,1481.00 C 842.00,1481.00 846.09,1433.00 846.09,1433.00 846.09,1433.00 847.83,1416.00 847.83,1416.00 847.83,1416.00 851.83,1366.00 851.83,1366.00 851.83,1366.00 854.83,1330.00 854.83,1330.00 854.83,1330.00 855.58,1319.00 855.58,1319.00 855.58,1319.00 858.58,1283.00 858.58,1283.00 858.58,1283.00 860.17,1271.57 860.17,1271.57 860.17,1271.57 868.00,1270.00 868.00,1270.00 868.00,1270.00 901.00,1270.00 901.00,1270.00 901.00,1270.00 901.00,1462.00 901.00,1462.00 896.02,1462.00 884.93,1461.09 881.00,1463.60 872.68,1468.92 870.17,1480.79 859.00,1481.00 859.00,1481.00 842.00,1481.00 842.00,1481.00 Z",
    attr: {
      id: "_216",
      "data-name": "216",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "216", zone: "Lower Zone", zoneId: "2" },
  };
  var _217 = {
    path:
      "M 798.00,1266.00 C 798.00,1266.00 842.00,1270.00 842.00,1270.00 842.00,1270.00 839.17,1309.00 839.17,1309.00 839.17,1309.00 836.83,1342.00 836.83,1342.00 836.83,1342.00 834.09,1371.00 834.09,1371.00 834.09,1371.00 828.17,1444.00 828.17,1444.00 828.17,1444.00 825.00,1481.00 825.00,1481.00 825.00,1481.00 812.00,1480.00 812.00,1480.00 812.00,1480.00 778.00,1477.28 778.00,1477.28 774.85,1476.91 764.87,1476.86 763.04,1474.40 761.37,1472.17 763.58,1462.95 764.08,1460.00 764.08,1460.00 771.20,1420.00 771.20,1420.00 771.20,1420.00 789.25,1317.00 789.25,1317.00 789.25,1317.00 798.00,1266.00 798.00,1266.00 Z",
    attr: {
      id: "_217",
      "data-name": "217",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "217", zone: "Lower Zone", zoneId: "2" },
  };
  var _218 = {
    path:
      "M 746.00,1255.00 C 746.00,1255.00 781.00,1263.00 781.00,1263.00 781.00,1263.00 776.42,1292.00 776.42,1292.00 776.42,1292.00 769.26,1333.00 769.26,1333.00 769.26,1333.00 754.92,1416.00 754.92,1416.00 754.92,1416.00 745.00,1473.00 745.00,1473.00 732.36,1471.32 696.20,1464.12 685.00,1460.00 685.00,1460.00 700.04,1408.00 700.04,1408.00 700.04,1408.00 730.65,1306.00 730.65,1306.00 730.65,1306.00 746.00,1255.00 746.00,1255.00 Z",
    attr: {
      id: "_218",
      "data-name": "218",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "218", zone: "Lower Zone", zoneId: "2" },
  };
  var _219 = {
    path:
      "M 697.00,1237.00 C 697.00,1237.00 730.00,1250.00 730.00,1250.00 730.00,1250.00 717.96,1291.00 717.96,1291.00 717.96,1291.00 695.04,1367.00 695.04,1367.00 695.04,1367.00 684.00,1405.00 684.00,1405.00 684.00,1405.00 673.26,1442.00 673.26,1442.00 672.48,1444.79 670.44,1453.30 667.70,1454.31 664.92,1455.34 652.42,1450.47 649.00,1449.33 649.00,1449.33 597.00,1428.00 597.00,1428.00 597.00,1428.00 622.57,1379.00 622.57,1379.00 622.57,1379.00 675.03,1279.00 675.03,1279.00 675.03,1279.00 697.00,1237.00 697.00,1237.00 Z",
    attr: {
      id: "_219",
      "data-name": "219",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "219", zone: "Lower Zone", zoneId: "2" },
  };
  var _221 = {
    path:
      "M 518.00,1379.00 C 523.21,1370.79 527.63,1367.91 533.72,1361.00 533.72,1361.00 563.17,1328.00 563.17,1328.00 563.17,1328.00 574.58,1316.00 574.58,1316.00 574.58,1316.00 614.42,1272.00 614.42,1272.00 614.42,1272.00 625.84,1259.99 625.84,1259.99 625.84,1259.99 636.17,1248.00 636.17,1248.00 636.17,1248.00 645.83,1238.00 645.83,1238.00 649.48,1233.97 658.26,1222.90 662.00,1220.37 666.78,1217.13 671.90,1222.41 676.00,1225.00 677.87,1226.19 680.69,1227.70 681.11,1230.10 681.48,1232.29 677.83,1238.79 676.69,1241.00 676.69,1241.00 663.00,1267.00 663.00,1267.00 663.00,1267.00 620.46,1348.00 620.46,1348.00 620.46,1348.00 610.30,1367.00 610.30,1367.00 610.30,1367.00 590.34,1405.00 590.34,1405.00 588.99,1407.70 584.24,1417.75 581.74,1418.59 578.76,1419.58 567.05,1412.03 564.00,1410.20 564.00,1410.20 518.00,1379.00 518.00,1379.00 Z",
    attr: {
      id: "_221",
      "data-name": "221",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "221", zone: "Lower Zone", zoneId: "2" },
  };

  var _222 = {
    path:
      "M 609.00,1207.00 C 610.31,1208.71 611.62,1210.86 614.02,1211.13 616.37,1211.39 619.24,1208.93 621.00,1207.57 624.10,1205.20 634.79,1196.12 638.00,1195.87 641.85,1195.58 649.81,1204.97 652.00,1208.00 652.00,1208.00 634.42,1226.91 634.42,1226.91 634.42,1226.91 608.16,1255.04 608.16,1255.04 608.16,1255.04 596.91,1268.00 596.91,1268.00 596.91,1268.00 550.09,1319.00 550.09,1319.00 550.09,1319.00 540.58,1329.83 540.58,1329.83 540.58,1329.83 506.00,1368.00 506.00,1368.00 500.33,1364.96 491.73,1356.71 487.00,1352.00 487.00,1352.00 461.59,1325.00 461.59,1325.00 457.14,1319.72 450.17,1312.41 448.00,1306.00 448.00,1306.00 504.00,1271.28 504.00,1271.28 504.00,1271.28 568.00,1231.70 568.00,1231.70 568.00,1231.70 594.00,1215.47 594.00,1215.47 594.00,1215.47 609.00,1207.00 609.00,1207.00 Z",
    attr: {
      id: "_222",
      "data-name": "222",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "222", zone: "Lower Zone", zoneId: "2" },
  };
  var _224 = {
    path:
      "M 438.00,1205.00 C 438.00,1205.00 464.00,1197.58 464.00,1197.58 464.00,1197.58 522.00,1181.52 522.00,1181.52 522.00,1181.52 586.00,1164.00 586.00,1164.00 586.00,1164.00 599.00,1193.00 599.00,1193.00 599.00,1193.00 553.00,1221.65 553.00,1221.65 553.00,1221.65 497.00,1256.53 497.00,1256.53 497.00,1256.53 458.00,1281.15 458.00,1281.15 458.00,1281.15 437.00,1293.00 437.00,1293.00 428.71,1279.23 421.52,1269.13 415.00,1254.00 423.97,1249.83 433.76,1247.23 442.00,1241.64 445.76,1239.09 449.78,1236.00 449.79,1231.00 449.79,1226.99 446.60,1223.34 444.63,1220.00 442.05,1215.64 439.68,1209.81 438.00,1205.00 Z",
    attr: {
      id: "_224",
      "data-name": "224",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "224", zone: "Lower Zone", zoneId: "2" },
  };
  var _225 = {
    path:
      "M 434.00,1189.00 C 434.00,1189.00 430.00,1152.00 430.00,1152.00 430.00,1152.00 430.00,1140.00 430.00,1140.00 430.00,1140.00 430.99,1132.74 430.99,1132.74 430.99,1132.74 440.00,1130.41 440.00,1130.41 440.00,1130.41 465.00,1127.05 465.00,1127.05 465.00,1127.05 531.00,1118.28 531.00,1118.28 531.00,1118.28 578.00,1112.00 578.00,1112.00 578.00,1112.00 578.00,1130.00 578.00,1130.00 578.00,1130.00 578.97,1147.28 578.97,1147.28 578.97,1147.28 568.00,1151.23 568.00,1151.23 568.00,1151.23 541.00,1158.86 541.00,1158.86 541.00,1158.86 465.00,1180.26 465.00,1180.26 465.00,1180.26 434.00,1189.00 434.00,1189.00 Z",
    attr: {
      id: "_225",
      "data-name": "225",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "225", zone: "Lower Zone", zoneId: "2" },
  };
  var _226 = {
    path:
      "M 446.00,1067.00 C 446.00,1067.00 578.00,1067.00 578.00,1067.00 578.00,1067.00 578.00,1095.00 578.00,1095.00 578.00,1095.00 490.00,1106.85 490.00,1106.85 490.00,1106.85 430.00,1115.00 430.00,1115.00 431.52,1106.12 442.54,1109.36 445.40,1102.79 446.28,1100.77 446.00,1093.57 446.00,1091.00 446.00,1091.00 446.00,1067.00 446.00,1067.00 Z",
    attr: {
      id: "_226",
      "data-name": "226",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "226", zone: "Lower Zone", zoneId: "2" },
  };
  var _227 = {
    path:
      "M 446.00,995.00 C 446.00,995.00 492.00,995.00 492.00,995.00 491.98,1000.04 489.06,1015.14 493.31,1017.98 495.16,1019.21 498.81,1019.00 501.00,1019.00 501.00,1019.00 536.00,1019.00 536.00,1019.00 536.00,1019.00 552.00,1020.00 552.00,1020.00 552.00,1020.00 578.00,1020.00 578.00,1020.00 578.00,1020.00 578.00,1050.00 578.00,1050.00 578.00,1050.00 446.00,1050.00 446.00,1050.00 446.00,1050.00 446.00,995.00 446.00,995.00 Z",
    attr: {
      id: "_227",
      "data-name": "227",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "227", zone: "Lower Zone", zoneId: "2" },
  };
  var _228 = {
    path:
      "M 447.00,932.00 C 447.00,932.00 580.00,932.00 580.00,932.00 580.00,932.00 580.00,959.00 580.00,959.00 580.00,959.00 560.00,960.00 560.00,960.00 560.00,960.00 492.00,960.00 492.00,960.00 492.00,960.00 492.00,978.00 492.00,978.00 492.00,978.00 447.00,978.00 447.00,978.00 447.00,978.00 447.00,932.00 447.00,932.00 Z",
    attr: {
      id: "_228",
      "data-name": "228",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "228", zone: "Lower Zone", zoneId: "2" },
  };
  var _229 = {
    path:
      "M 429.00,860.00 C 429.00,860.00 472.00,864.83 472.00,864.83 472.00,864.83 531.00,871.16 531.00,871.16 531.00,871.16 580.00,876.00 580.00,876.00 580.00,876.00 580.00,915.00 580.00,915.00 580.00,915.00 447.00,915.00 447.00,915.00 447.00,915.00 447.00,887.00 447.00,887.00 446.76,874.39 436.18,878.02 431.59,873.30 429.18,870.81 429.03,863.39 429.00,860.00 Z",
    attr: {
      id: "_229",
      "data-name": "229",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "229", zone: "Lower Zone", zoneId: "2" },
  };
  var _230 = {
    path:
      "M 439.00,786.00 C 439.00,786.00 476.00,796.74 476.00,796.74 476.00,796.74 545.00,816.65 545.00,816.65 545.00,816.65 569.00,823.52 569.00,823.52 569.00,823.52 580.00,827.70 580.00,827.70 580.00,827.70 580.00,842.00 580.00,842.00 580.00,842.00 580.00,859.00 580.00,859.00 580.00,859.00 532.00,854.17 532.00,854.17 532.00,854.17 455.00,845.83 455.00,845.83 455.00,845.83 430.00,843.00 430.00,843.00 430.00,824.97 433.94,803.31 439.00,786.00 Z",
    attr: {
      id: "_230",
      "data-name": "230",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "230", zone: "Lower Zone", zoneId: "2" },
  };
  var _231 = {
    path:
      "M 436.00,686.00 C 436.00,686.00 484.00,713.46 484.00,713.46 484.00,713.46 558.00,756.55 558.00,756.55 558.00,756.55 600.00,781.00 600.00,781.00 600.00,781.00 586.00,811.00 586.00,811.00 586.00,811.00 481.00,781.26 481.00,781.26 481.00,781.26 445.00,771.00 445.00,771.00 446.00,761.39 452.17,753.60 456.00,745.00 456.00,745.00 415.00,727.00 415.00,727.00 417.39,716.82 429.99,695.20 436.00,686.00 Z",
    attr: {
      id: "_231",
      "data-name": "231",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "231", zone: "Lower Zone", zoneId: "2" },
  };
  var _233 = {
    path:
      "M 498.00,617.00 C 498.00,617.00 538.17,657.96 538.17,657.96 538.17,657.96 556.96,677.00 556.96,677.00 556.96,677.00 598.17,719.91 598.17,719.91 598.17,719.91 632.28,754.99 632.28,754.99 637.18,760.42 641.75,763.00 645.00,770.00 642.40,771.84 638.15,776.12 635.00,775.72 630.13,775.10 621.40,763.87 617.00,764.00 614.97,764.06 611.73,766.81 610.00,768.00 610.00,768.00 600.00,762.35 600.00,762.35 600.00,762.35 554.00,735.38 554.00,735.38 554.00,735.38 447.00,673.00 447.00,673.00 448.81,666.35 455.72,659.21 460.29,654.00 474.20,638.13 482.92,630.86 498.00,617.00 Z",
    attr: {
      id: "_233",
      "data-name": "233",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "233", zone: "Lower Zone", zoneId: "2" },
  };
  var _234 = {
    path:
      "M 678.00,746.00 C 678.00,746.00 665.00,754.33 665.00,754.33 663.10,755.56 660.43,757.54 658.04,757.13 655.33,756.65 651.11,751.18 649.02,749.09 649.02,749.09 626.72,726.01 626.72,726.01 626.72,726.01 597.04,696.00 597.04,696.00 597.04,696.00 549.58,646.72 549.58,646.72 549.58,646.72 521.83,617.99 521.83,617.99 519.71,615.70 513.20,609.99 513.20,607.04 513.20,603.55 522.21,598.14 525.00,596.14 525.00,596.14 564.00,570.80 564.00,570.80 567.31,568.82 576.82,561.81 580.25,564.64 582.09,566.16 589.67,580.94 591.17,584.00 591.17,584.00 609.30,618.00 609.30,618.00 609.30,618.00 643.69,682.00 643.69,682.00 643.69,682.00 678.00,746.00 678.00,746.00 Z",
    attr: {
      id: "_234",
      "data-name": "234",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "234", zone: "Lower Zone", zoneId: "2" },
  };
  var _236 = {
    path:
      "M 594.00,554.00 C 594.00,554.00 629.00,539.00 629.00,539.00 629.00,539.00 649.25,580.00 649.25,580.00 650.88,583.27 655.09,593.42 658.21,594.65 660.49,595.56 665.61,593.69 668.00,593.00 668.00,593.00 688.00,588.00 688.00,588.00 688.00,588.00 709.03,667.00 709.03,667.00 709.03,667.00 719.48,706.00 719.48,706.00 719.48,706.00 724.00,724.00 724.00,724.00 724.00,724.00 692.00,738.00 692.00,738.00 692.00,738.00 664.13,686.00 664.13,686.00 664.13,686.00 618.87,601.00 618.87,601.00 618.87,601.00 594.00,554.00 594.00,554.00 Z",
    attr: {
      id: "_236",
      "data-name": "236",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "236", zone: "Lower Zone", zoneId: "2" },
  };
  var _237 = {
    path:
      "M 704.00,584.00 C 704.00,584.00 727.00,578.35 727.00,578.35 727.00,578.35 756.00,573.00 756.00,573.00 756.00,573.00 760.72,607.00 760.72,607.00 760.72,607.00 770.28,674.00 770.28,674.00 770.28,674.00 773.95,698.00 773.95,698.00 774.36,700.69 775.71,706.12 773.95,708.42 772.55,710.31 766.39,711.27 764.00,711.79 764.00,711.79 740.00,718.00 740.00,718.00 740.00,718.00 718.27,637.00 718.27,637.00 718.27,637.00 704.00,584.00 704.00,584.00 Z",
    attr: {
      id: "_237",
      "data-name": "237",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "237", zone: "Lower Zone", zoneId: "2" },
  };
  var _238 = {
    path:
      "M 772.00,570.00 C 772.00,570.00 809.00,564.23 809.00,564.23 809.00,564.23 822.40,564.23 822.40,564.23 822.40,564.23 824.58,574.00 824.58,574.00 824.58,574.00 827.28,603.00 827.28,603.00 827.28,603.00 832.17,654.00 832.17,654.00 832.17,654.00 839.00,721.00 839.00,721.00 839.00,721.00 818.00,723.00 818.00,723.00 818.00,723.00 813.27,706.61 813.27,706.61 813.27,706.61 806.00,705.83 806.00,705.83 806.00,705.83 792.00,707.00 792.00,707.00 792.00,707.00 783.28,646.00 783.28,646.00 783.28,646.00 772.00,570.00 772.00,570.00 Z",
    attr: {
      id: "_238",
      "data-name": "238",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "238", zone: "Lower Zone", zoneId: "2" },
  };
  var _239 = {
    path:
      "M 840.00,562.00 C 840.00,562.00 862.00,561.00 862.00,561.00 862.00,561.00 902.00,561.00 902.00,561.00 902.00,561.00 902.00,720.00 902.00,720.00 902.00,720.00 856.00,720.00 856.00,720.00 856.00,720.00 850.83,667.00 850.83,667.00 850.83,667.00 845.17,612.00 845.17,612.00 845.17,612.00 840.00,562.00 840.00,562.00 Z",
    attr: {
      id: "_239",
      "data-name": "239",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "239", zone: "Lower Zone", zoneId: "2" },
  };
  var _240 = {
    path: "M 919.00,560.00 C 919.00,560.00 978.00,560.00 978.00,560.00 978.00,560.00 978.00,719.00 978.00,719.00 963.00,720.98 935.32,720.00 919.00,720.00 919.00,720.00 919.00,560.00 919.00,560.00 Z",
    attr: {
      id: "_240",
      "data-name": "240",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "240", zone: "Lower Zone", zoneId: "2" },
  };
  var _241 = {
    path: "M 995.00,560.00 C 995.00,560.00 1049.00,560.00 1049.00,560.00 1049.00,560.00 1049.00,719.00 1049.00,719.00 1049.00,719.00 995.00,719.00 995.00,719.00 995.00,719.00 995.00,560.00 995.00,560.00 Z",
    attr: {
      id: "_241",
      "data-name": "241",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "241", zone: "Lower Zone", zoneId: "2" },
  };
  var _242 = {
    path: "M 1066.00,560.00 C 1066.00,560.00 1117.00,560.00 1117.00,560.00 1117.00,560.00 1117.00,719.00 1117.00,719.00 1117.00,719.00 1066.00,719.00 1066.00,719.00 1066.00,719.00 1066.00,560.00 1066.00,560.00 Z",
    attr: {
      id: "_242",
      "data-name": "242",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "242", zone: "Lower Zone", zoneId: "2" },
  };
  var _243 = {
    path:
      "M 1134.00,560.00 C 1134.00,560.00 1190.00,560.00 1190.00,560.00 1190.00,560.00 1180.91,671.00 1180.91,671.00 1180.91,671.00 1177.91,707.00 1177.91,707.00 1177.91,707.00 1177.00,719.00 1177.00,719.00 1177.00,719.00 1134.00,719.00 1134.00,719.00 1134.00,719.00 1134.00,560.00 1134.00,560.00 Z",
    attr: {
      id: "_243",
      "data-name": "243",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "243", zone: "Lower Zone", zoneId: "2" },
  };
  var _244 = {
    path:
      "M 1207.00,562.00 C 1207.00,562.00 1263.00,569.00 1263.00,569.00 1263.00,569.00 1254.58,622.00 1254.58,622.00 1254.58,622.00 1241.00,708.00 1241.00,708.00 1241.00,708.00 1220.00,705.00 1220.00,705.00 1219.89,707.32 1218.99,719.13 1217.98,720.40 1216.40,722.39 1213.19,721.85 1211.00,721.58 1211.00,721.58 1194.00,720.00 1194.00,720.00 1194.00,720.00 1195.83,696.00 1195.83,696.00 1195.83,696.00 1198.91,659.00 1198.91,659.00 1198.91,659.00 1203.83,600.00 1203.83,600.00 1203.83,600.00 1207.00,562.00 1207.00,562.00 Z",
    attr: {
      id: "_244",
      "data-name": "244",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "244", zone: "Lower Zone", zoneId: "2" },
  };
  var _245 = {
    path:
      "M 1279.00,572.00 C 1289.44,572.23 1309.11,576.99 1320.00,579.20 1323.02,579.81 1332.31,581.13 1333.94,583.56 1335.48,585.88 1333.46,590.54 1332.72,593.00 1332.72,593.00 1325.48,618.00 1325.48,618.00 1325.48,618.00 1304.26,692.00 1304.26,692.00 1304.26,692.00 1296.00,721.00 1296.00,721.00 1296.00,721.00 1269.00,713.87 1269.00,713.87 1269.00,713.87 1259.14,710.42 1259.14,710.42 1259.14,710.42 1259.14,700.00 1259.14,700.00 1259.14,700.00 1263.41,674.00 1263.41,674.00 1263.41,674.00 1273.43,609.00 1273.43,609.00 1273.43,609.00 1279.00,572.00 1279.00,572.00 Z",
    attr: {
      id: "_245",
      "data-name": "245",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "245", zone: "Lower Zone", zoneId: "2" },
  };
  var _246 = {
    path:
      "M 1401.00,552.00 C 1409.62,553.62 1427.62,563.57 1436.00,568.00 1434.42,574.17 1429.80,581.30 1426.67,587.00 1426.67,587.00 1413.17,612.00 1413.17,612.00 1413.17,612.00 1368.37,695.00 1368.37,695.00 1368.37,695.00 1351.77,726.00 1351.77,726.00 1350.44,728.37 1346.82,735.83 1344.73,736.88 1342.11,738.19 1337.55,735.82 1335.00,734.80 1335.00,734.80 1311.00,726.00 1311.00,726.00 1311.00,726.00 1325.74,676.00 1325.74,676.00 1325.74,676.00 1338.26,634.00 1338.26,634.00 1338.26,634.00 1352.00,587.00 1352.00,587.00 1352.00,587.00 1369.00,592.33 1369.00,592.33 1371.04,593.01 1374.85,594.53 1376.91,594.08 1380.34,593.32 1383.67,586.91 1385.40,584.00 1390.40,575.59 1397.34,561.16 1401.00,552.00 Z",
    attr: {
      id: "_246",
      "data-name": "246",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "246", zone: "Lower Zone", zoneId: "2" },
  };
  var _248 = {
    path:
      "M 1451.00,577.00 C 1462.20,582.40 1483.75,597.76 1494.00,605.65 1499.97,610.25 1508.61,616.15 1513.00,622.00 1513.00,622.00 1404.00,731.00 1404.00,731.00 1404.00,731.00 1387.00,748.00 1387.00,748.00 1384.89,750.10 1380.89,754.60 1378.00,755.18 1375.15,755.75 1371.38,753.01 1369.00,751.60 1366.87,750.34 1362.22,748.14 1361.40,745.79 1360.34,742.75 1364.57,736.75 1366.05,734.00 1366.05,734.00 1382.55,703.00 1382.55,703.00 1382.55,703.00 1413.05,647.00 1413.05,647.00 1413.05,647.00 1451.00,577.00 1451.00,577.00 Z",
    attr: {
      id: "_248",
      "data-name": "248",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "248", zone: "Lower Zone", zoneId: "2" },
  };

  var _249 = {
    path:
      "M 1391.00,767.00 C 1391.00,767.00 1426.00,731.00 1426.00,731.00 1426.00,731.00 1492.00,665.00 1492.00,665.00 1492.00,665.00 1516.00,641.00 1516.00,641.00 1518.09,638.91 1523.04,633.20 1526.00,633.20 1529.30,633.20 1536.47,642.35 1538.92,645.00 1547.59,654.44 1563.88,675.54 1569.00,687.00 1569.00,687.00 1519.00,715.70 1519.00,715.70 1519.00,715.70 1446.00,757.54 1446.00,757.54 1446.00,757.54 1429.00,766.94 1429.00,766.94 1429.00,766.94 1420.00,765.58 1420.00,765.58 1420.00,765.58 1402.00,775.01 1402.00,775.01 1402.00,775.01 1391.00,767.00 1391.00,767.00 Z",
    attr: {
      id: "_249",
      "data-name": "249",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "249", zone: "Lower Zone", zoneId: "2" },
  };
  var _251 = {
    path:
      "M 1435.00,783.00 C 1435.00,783.00 1468.00,763.77 1468.00,763.77 1468.00,763.77 1534.00,726.10 1534.00,726.10 1534.00,726.10 1564.00,708.88 1564.00,708.88 1566.34,707.58 1573.74,702.90 1575.90,702.82 1579.83,702.67 1584.15,714.63 1585.69,718.00 1591.73,731.19 1597.97,748.94 1601.63,763.00 1602.50,766.33 1605.98,775.47 1603.36,778.15 1602.01,779.52 1593.34,781.52 1591.00,782.11 1591.00,782.11 1561.00,789.73 1561.00,789.73 1561.00,789.73 1482.00,810.27 1482.00,810.27 1482.00,810.27 1445.00,820.00 1445.00,820.00 1445.00,820.00 1439.28,796.00 1439.28,796.00 1439.28,796.00 1435.00,783.00 1435.00,783.00 Z",
    attr: {
      id: "_251",
      "data-name": "251",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "251", zone: "Lower Zone", zoneId: "2" },
  };

  var _252 = {
    path:
      "M 1451.00,866.00 C 1451.00,866.00 1448.78,845.00 1448.78,845.00 1448.78,845.00 1448.78,836.73 1448.78,836.73 1448.78,836.73 1461.00,832.79 1461.00,832.79 1461.00,832.79 1490.00,825.27 1490.00,825.27 1490.00,825.27 1561.00,806.99 1561.00,806.99 1561.00,806.99 1609.00,795.00 1609.00,795.00 1609.00,795.00 1614.00,842.00 1614.00,842.00 1614.00,842.00 1539.00,852.72 1539.00,852.72 1539.00,852.72 1491.00,859.72 1491.00,859.72 1491.00,859.72 1451.00,866.00 1451.00,866.00 Z",
    attr: {
      id: "_252",
      "data-name": "252",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "252", zone: "Lower Zone", zoneId: "2" },
  };
  var _253 = {
    path:
      "M 1453.00,919.00 C 1453.00,919.00 1452.00,900.00 1452.00,900.00 1452.00,900.00 1453.02,883.59 1453.02,883.59 1453.02,883.59 1464.00,880.73 1464.00,880.73 1464.00,880.73 1492.00,876.72 1492.00,876.72 1492.00,876.72 1575.00,864.57 1575.00,864.57 1575.00,864.57 1614.00,859.00 1614.00,859.00 1614.00,859.00 1614.00,919.00 1614.00,919.00 1614.00,919.00 1453.00,919.00 1453.00,919.00 Z",
    attr: {
      id: "_253",
      "data-name": "253",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "253", zone: "Lower Zone", zoneId: "2" },
  };
  var _254 = {
    path:
      "M 1453.00,936.00 C 1453.00,936.00 1614.00,936.00 1614.00,936.00 1614.00,936.00 1614.00,981.00 1614.00,981.00 1614.00,981.00 1547.00,981.00 1547.00,981.00 1547.00,981.00 1547.00,961.00 1547.00,961.00 1547.00,961.00 1452.00,961.00 1452.00,961.00 1452.00,961.00 1453.00,936.00 1453.00,936.00 Z",
    attr: {
      id: "_254",
      "data-name": "254",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "254", zone: "Lower Zone", zoneId: "2" },
  };
  var _336 = {
    path:
      "M 644.00,532.00 C 644.00,532.00 662.00,526.00 662.00,526.00 663.31,528.71 665.63,533.65 668.21,535.23 671.66,537.35 686.42,532.86 691.00,532.00 691.00,532.00 702.00,568.00 702.00,568.00 702.00,568.00 666.00,577.00 666.00,577.00 666.00,577.00 652.75,551.00 652.75,551.00 652.75,551.00 644.00,532.00 644.00,532.00 Z",
    attr: {
      id: "_336",
      "data-name": "336",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "336", zone: "Club Seats", zoneId: "3" },
  };
  var _337 = {
    path:
      "M 708.00,528.00 C 708.00,528.00 739.00,522.61 739.00,522.61 739.00,522.61 751.26,522.01 751.26,522.01 751.26,522.01 753.95,532.00 753.95,532.00 753.95,532.00 758.00,556.00 758.00,556.00 758.00,556.00 718.00,564.00 718.00,564.00 718.00,564.00 708.00,528.00 708.00,528.00 Z",
    attr: {
      id: "_337",
      "data-name": "337",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "337", zone: "Club Seats", zoneId: "3" },
  };
  var _338 = {
    path:
      "M 769.00,518.00 C 769.00,518.00 805.00,513.52 805.00,513.52 807.71,513.06 816.23,511.49 817.98,513.52 819.21,515.11 819.03,519.90 819.28,522.00 819.28,522.00 822.00,547.00 822.00,547.00 822.00,547.00 774.00,553.00 774.00,553.00 774.00,553.00 769.00,518.00 769.00,518.00 Z",
    attr: {
      id: "_338",
      "data-name": "338",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "338", zone: "Club Seats", zoneId: "3" },
  };
  var _339 = {
    path:
      "M 836.00,510.00 C 836.00,510.00 859.00,509.00 859.00,509.00 859.00,509.00 902.00,509.00 902.00,509.00 902.00,509.00 902.00,544.00 902.00,544.00 902.00,544.00 866.00,544.00 866.00,544.00 866.00,544.00 847.00,545.00 847.00,545.00 847.00,545.00 839.74,544.01 839.74,544.01 839.74,544.01 837.83,535.00 837.83,535.00 837.83,535.00 836.00,510.00 836.00,510.00 Z",
    attr: {
      id: "_339",
      "data-name": "339",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "339", zone: "Club Seats", zoneId: "3" },
  };
  var _340 = {
    path: "M 919.00,509.00 C 919.00,509.00 978.00,509.00 978.00,509.00 978.00,509.00 978.00,544.00 978.00,544.00 978.00,544.00 919.00,544.00 919.00,544.00 919.00,544.00 919.00,509.00 919.00,509.00 Z",
    attr: {
      id: "_340",
      "data-name": "340",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "340", zone: "Club Seats", zoneId: "3" },
  };
  var _341 = {
    path: "M 995.00,509.00 C 995.00,509.00 1049.00,509.00 1049.00,509.00 1049.00,509.00 1049.00,544.00 1049.00,544.00 1049.00,544.00 995.00,544.00 995.00,544.00 995.00,544.00 995.00,509.00 995.00,509.00 Z",
    attr: {
      id: "_341",
      "data-name": "341",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "341", zone: "Club Seats", zoneId: "3" },
  };
  var _342 = {
    path: "M 1066.00,509.00 C 1066.00,509.00 1117.00,510.00 1117.00,510.00 1117.00,510.00 1117.00,544.00 1117.00,544.00 1117.00,544.00 1066.00,544.00 1066.00,544.00 1066.00,544.00 1066.00,509.00 1066.00,509.00 Z",
    attr: {
      id: "_342",
      "data-name": "342",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "342", zone: "Club Seats", zoneId: "3" },
  };
  var _343 = {
    path:
      "M 1134.00,510.00 C 1134.00,510.00 1194.00,510.00 1194.00,510.00 1194.00,510.00 1191.00,544.00 1191.00,544.00 1191.00,544.00 1174.00,543.00 1174.00,543.00 1174.00,543.00 1148.00,543.00 1148.00,543.00 1148.00,543.00 1134.00,544.00 1134.00,544.00 1134.00,544.00 1134.00,510.00 1134.00,510.00 Z",
    attr: {
      id: "_343",
      "data-name": "343",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "343", zone: "Club Seats", zoneId: "3" },
  };
  var _344 = {
    path:
      "M 1211.00,510.00 C 1211.00,510.00 1225.00,511.00 1225.00,511.00 1225.00,511.00 1260.00,514.00 1260.00,514.00 1260.00,514.00 1256.00,551.00 1256.00,551.00 1256.00,551.00 1208.00,546.00 1208.00,546.00 1208.00,546.00 1211.00,510.00 1211.00,510.00 Z",
    attr: {
      id: "_344",
      "data-name": "344",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "344", zone: "Club Seats", zoneId: "3" },
  };
  var _345 = {
    path: "M 1277.00,516.00 C 1277.00,516.00 1320.00,525.00 1320.00,525.00 1320.00,525.00 1312.00,561.00 1312.00,561.00 1312.00,561.00 1273.00,554.00 1273.00,554.00 1273.00,554.00 1277.00,516.00 1277.00,516.00 Z",
    attr: {
      id: "_345",
      "data-name": "345",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "345", zone: "Club Seats", zoneId: "3" },
  };
  var _346 = {
    path: "M 1336.00,529.00 C 1346.37,530.46 1378.06,541.38 1388.00,546.00 1388.00,546.00 1372.00,576.00 1372.00,576.00 1372.00,576.00 1328.00,565.00 1328.00,565.00 1328.00,565.00 1336.00,529.00 1336.00,529.00 Z",
    attr: {
      id: "_346",
      "data-name": "346",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "346", zone: "Club Seats", zoneId: "3" },
  };
  var _401 = {
    path:
      "M 1678.00,984.00 C 1678.00,984.00 1858.00,984.00 1858.00,984.00 1858.00,984.00 1858.00,1058.00 1858.00,1058.00 1858.00,1058.00 1679.00,1058.00 1679.00,1058.00 1679.00,1058.00 1679.00,1020.00 1679.00,1020.00 1679.00,1020.00 1678.00,1005.00 1678.00,1005.00 1678.00,1005.00 1678.00,984.00 1678.00,984.00 Z",
    attr: {
      id: "_401",
      "data-name": "401",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "401", zone: "Upper Zone", zoneId: "4" },
  };
  var _402 = {
    path:
      "M 1679.00,1075.00 C 1679.00,1075.00 1858.00,1075.00 1858.00,1075.00 1858.00,1075.00 1858.00,1152.00 1858.00,1152.00 1858.00,1154.69 1858.52,1160.08 1855.57,1161.26 1853.99,1162.09 1850.73,1161.37 1849.00,1161.26 1849.00,1161.26 1834.00,1159.00 1834.00,1159.00 1834.00,1159.00 1776.00,1150.95 1776.00,1150.95 1776.00,1150.95 1751.00,1147.28 1751.00,1147.28 1751.00,1147.28 1703.00,1140.59 1703.00,1140.59 1703.00,1140.59 1687.00,1138.42 1687.00,1138.42 1685.14,1138.13 1682.26,1137.98 1681.02,1136.40 1679.76,1134.80 1680.00,1131.00 1680.00,1129.00 1680.00,1129.00 1680.00,1093.00 1680.00,1093.00 1680.00,1093.00 1679.00,1075.00 1679.00,1075.00 Z",
    attr: {
      id: "_402",
      "data-name": "402",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "402", zone: "Upper Zone", zoneId: "4" },
  };
  var _403 = {
    path:
      "M 1679.00,1155.00 C 1679.00,1155.00 1737.00,1162.57 1737.00,1162.57 1737.00,1162.57 1822.00,1174.28 1822.00,1174.28 1822.00,1174.28 1856.00,1179.00 1856.00,1179.00 1856.00,1179.00 1850.58,1213.00 1850.58,1213.00 1850.58,1213.00 1840.00,1258.00 1840.00,1258.00 1840.00,1258.00 1808.00,1248.04 1808.00,1248.04 1808.00,1248.04 1719.00,1220.96 1719.00,1220.96 1719.00,1220.96 1670.00,1206.00 1670.00,1206.00 1673.39,1189.17 1678.95,1172.33 1679.00,1155.00 Z",
    attr: {
      id: "_403",
      "data-name": "403",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "403", zone: "Upper Zone", zoneId: "4" },
  };
  var _404 = {
    path:
      "M 1665.00,1221.00 C 1665.00,1221.00 1749.00,1247.04 1749.00,1247.04 1749.00,1247.04 1780.00,1256.96 1780.00,1256.96 1780.00,1256.96 1835.00,1274.00 1835.00,1274.00 1832.37,1285.53 1821.66,1313.53 1816.72,1325.00 1815.36,1328.15 1811.54,1339.27 1808.61,1340.33 1806.12,1341.23 1801.37,1338.71 1799.00,1337.71 1799.00,1337.71 1775.00,1327.56 1775.00,1327.56 1775.00,1327.56 1682.00,1288.27 1682.00,1288.27 1682.00,1288.27 1656.00,1277.27 1656.00,1277.27 1653.61,1276.31 1647.24,1274.05 1646.23,1271.73 1645.04,1268.99 1649.72,1260.96 1651.00,1258.00 1655.83,1246.82 1662.32,1232.75 1665.00,1221.00 Z",
    attr: {
      id: "_404",
      "data-name": "404",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "404", zone: "Upper Zone", zoneId: "4" },
  };
  var _405 = {
    path:
      "M 1636.00,1287.00 C 1636.00,1287.00 1664.00,1298.35 1664.00,1298.35 1664.00,1298.35 1712.00,1318.86 1712.00,1318.86 1712.00,1318.86 1764.00,1340.86 1764.00,1340.86 1764.00,1340.86 1802.00,1357.00 1802.00,1357.00 1802.00,1357.00 1796.26,1368.00 1796.26,1368.00 1784.76,1390.23 1770.93,1410.29 1757.00,1431.00 1757.00,1431.00 1734.00,1414.24 1734.00,1414.24 1734.00,1414.24 1725.00,1407.96 1725.00,1407.96 1725.00,1407.96 1711.00,1396.84 1711.00,1396.84 1711.00,1396.84 1680.00,1373.96 1680.00,1373.96 1680.00,1373.96 1666.00,1362.84 1666.00,1362.84 1666.00,1362.84 1656.00,1355.79 1656.00,1355.79 1656.00,1355.79 1613.00,1324.00 1613.00,1324.00 1613.00,1324.00 1636.00,1287.00 1636.00,1287.00 Z",
    attr: {
      id: "_405",
      "data-name": "405",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "405", zone: "Upper Zone", zoneId: "4" },
  };
  var _406 = {
    path:
      "M 1602.00,1336.00 C 1602.00,1336.00 1650.00,1371.39 1650.00,1371.39 1650.00,1371.39 1660.00,1378.44 1660.00,1378.44 1660.00,1378.44 1696.00,1406.16 1696.00,1406.16 1696.00,1406.16 1705.00,1412.44 1705.00,1412.44 1705.00,1412.44 1718.00,1422.76 1718.00,1422.76 1718.00,1422.76 1729.00,1430.60 1729.00,1430.60 1729.00,1430.60 1746.00,1443.00 1746.00,1443.00 1741.12,1451.37 1716.54,1479.37 1709.00,1487.00 1706.52,1489.50 1698.27,1498.80 1695.09,1498.80 1692.85,1498.81 1687.95,1493.41 1686.28,1491.72 1686.28,1491.72 1664.73,1467.83 1664.73,1467.83 1664.73,1467.83 1651.83,1453.00 1651.83,1453.00 1651.83,1453.00 1621.58,1419.91 1621.58,1419.91 1621.58,1419.91 1607.83,1404.00 1607.83,1404.00 1607.83,1404.00 1574.00,1366.00 1574.00,1366.00 1574.00,1366.00 1602.00,1336.00 1602.00,1336.00 Z",
    attr: {
      id: "_406",
      "data-name": "406",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "406", zone: "Upper Zone", zoneId: "4" },
  };
  var _407 = {
    path:
      "M 1683.00,1512.00 C 1670.00,1524.41 1653.67,1536.39 1639.00,1546.86 1636.08,1548.94 1625.12,1557.16 1622.09,1556.90 1618.80,1556.61 1613.33,1546.89 1611.55,1544.00 1611.55,1544.00 1586.41,1505.00 1586.41,1505.00 1586.41,1505.00 1540.34,1434.00 1540.34,1434.00 1540.34,1434.00 1528.45,1416.00 1528.45,1416.00 1527.32,1414.17 1524.29,1410.10 1524.32,1408.09 1524.36,1404.97 1530.61,1401.59 1533.00,1399.85 1539.65,1395.02 1557.19,1380.28 1563.00,1378.00 1563.00,1378.00 1574.83,1391.00 1574.83,1391.00 1574.83,1391.00 1590.28,1408.96 1590.28,1408.96 1590.28,1408.96 1598.13,1417.04 1598.13,1417.04 1598.13,1417.04 1633.42,1456.96 1633.42,1456.96 1633.42,1456.96 1641.27,1465.04 1641.27,1465.04 1641.27,1465.04 1654.15,1479.96 1654.15,1479.96 1654.15,1479.96 1683.00,1512.00 1683.00,1512.00 Z",
    attr: {
      id: "_407",
      "data-name": "407",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "407", zone: "Upper Zone", zoneId: "4" },
  };
  var _408 = {
    path:
      "M 1466.00,1438.00 C 1466.00,1438.00 1509.00,1416.00 1509.00,1416.00 1509.00,1416.00 1520.71,1434.00 1520.71,1434.00 1520.71,1434.00 1562.29,1498.00 1562.29,1498.00 1562.29,1498.00 1596.66,1551.00 1596.66,1551.00 1596.66,1551.00 1607.00,1567.00 1607.00,1567.00 1594.45,1576.16 1556.39,1594.47 1541.00,1601.00 1541.00,1601.00 1518.55,1553.00 1518.55,1553.00 1518.55,1553.00 1490.49,1493.00 1490.49,1493.00 1490.49,1493.00 1474.98,1460.00 1474.98,1460.00 1471.96,1453.69 1466.88,1444.73 1466.00,1438.00 Z",
    attr: {
      id: "_408",
      "data-name": "408",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "408", zone: "Upper Zone", zoneId: "4" },
  };
  var _409 = {
    path:
      "M 1399.00,1467.00 C 1399.00,1467.00 1431.00,1455.69 1431.00,1455.69 1431.00,1455.69 1451.00,1447.00 1451.00,1447.00 1451.00,1447.00 1464.91,1477.00 1464.91,1477.00 1464.91,1477.00 1506.16,1565.00 1506.16,1565.00 1506.16,1565.00 1520.21,1595.00 1520.21,1595.00 1521.32,1597.53 1525.07,1604.18 1524.30,1606.61 1523.28,1609.80 1510.41,1613.65 1507.00,1615.00 1496.25,1619.27 1460.15,1631.95 1450.00,1634.00 1450.00,1634.00 1445.65,1619.00 1445.65,1619.00 1445.65,1619.00 1430.96,1572.00 1430.96,1572.00 1430.96,1572.00 1415.96,1524.00 1415.96,1524.00 1412.32,1511.74 1399.92,1477.23 1399.00,1467.00 Z",
    attr: {
      id: "_409",
      "data-name": "409",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "409", zone: "Upper Zone", zoneId: "4" },
  };
  var _410 = {
    path:
      "M 1329.00,1483.00 C 1329.00,1483.00 1344.00,1480.20 1344.00,1480.20 1344.00,1480.20 1382.00,1472.00 1382.00,1472.00 1382.00,1472.00 1393.04,1507.00 1393.04,1507.00 1393.04,1507.00 1405.65,1546.00 1405.65,1546.00 1405.65,1546.00 1408.35,1556.00 1408.35,1556.00 1408.35,1556.00 1421.65,1597.00 1421.65,1597.00 1421.65,1597.00 1424.35,1607.00 1424.35,1607.00 1424.35,1607.00 1434.00,1639.00 1434.00,1639.00 1416.82,1643.98 1370.56,1655.67 1354.00,1658.00 1354.00,1658.00 1345.28,1595.00 1345.28,1595.00 1345.28,1595.00 1334.27,1519.00 1334.27,1519.00 1334.27,1519.00 1329.00,1483.00 1329.00,1483.00 Z",
    attr: {
      id: "_410",
      "data-name": "410",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "410", zone: "Upper Zone", zoneId: "4" },
  };
  var _411 = {
    path:
      "M 1251.00,1492.00 C 1251.00,1492.00 1306.00,1486.17 1306.00,1486.17 1308.45,1485.98 1311.09,1485.86 1312.44,1488.31 1313.53,1490.30 1314.04,1497.39 1314.43,1500.00 1314.43,1500.00 1318.41,1526.00 1318.41,1526.00 1318.41,1526.00 1325.28,1575.00 1325.28,1575.00 1325.28,1575.00 1331.72,1618.00 1331.72,1618.00 1331.72,1618.00 1338.00,1660.00 1338.00,1660.00 1338.00,1660.00 1272.00,1666.00 1272.00,1666.00 1272.00,1666.00 1261.00,1666.00 1261.00,1666.00 1261.00,1666.00 1259.00,1627.00 1259.00,1627.00 1259.00,1627.00 1258.00,1617.00 1258.00,1617.00 1258.00,1617.00 1258.00,1607.00 1258.00,1607.00 1258.00,1607.00 1257.00,1593.00 1257.00,1593.00 1257.00,1593.00 1256.00,1583.00 1256.00,1583.00 1256.00,1583.00 1256.00,1573.00 1256.00,1573.00 1256.00,1573.00 1255.00,1559.00 1255.00,1559.00 1255.00,1559.00 1254.00,1545.00 1254.00,1545.00 1254.00,1545.00 1252.00,1507.00 1252.00,1507.00 1252.00,1507.00 1251.00,1492.00 1251.00,1492.00 Z",
    attr: {
      id: "_411",
      "data-name": "411",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "411", zone: "Upper Zone", zoneId: "4" },
  };
  var _412 = {
    path:
      "M 1167.00,1498.00 C 1167.00,1498.00 1189.00,1497.00 1189.00,1497.00 1189.00,1497.00 1202.00,1495.96 1202.00,1495.96 1202.00,1495.96 1211.00,1495.96 1211.00,1495.96 1211.00,1495.96 1234.00,1494.00 1234.00,1494.00 1234.00,1494.00 1236.00,1528.00 1236.00,1528.00 1236.00,1528.00 1237.00,1543.00 1237.00,1543.00 1237.00,1543.00 1238.00,1559.00 1238.00,1559.00 1238.00,1559.00 1239.00,1580.00 1239.00,1580.00 1239.00,1580.00 1240.00,1593.00 1240.00,1593.00 1240.00,1593.00 1241.00,1612.00 1241.00,1612.00 1241.00,1612.00 1242.00,1632.00 1242.00,1632.00 1242.00,1632.00 1243.00,1642.00 1243.00,1642.00 1243.00,1642.00 1243.00,1652.00 1243.00,1652.00 1243.00,1652.00 1244.00,1667.00 1244.00,1667.00 1244.00,1667.00 1167.00,1667.00 1167.00,1667.00 1167.00,1667.00 1167.00,1498.00 1167.00,1498.00 Z",
    attr: {
      id: "_412",
      "data-name": "412",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "412", zone: "Upper Zone", zoneId: "4" },
  };
  var _413 = {
    path: "M 1077.00,1499.00 C 1077.00,1499.00 1150.00,1499.00 1150.00,1499.00 1150.00,1499.00 1150.00,1667.00 1150.00,1667.00 1150.00,1667.00 1077.00,1667.00 1077.00,1667.00 1077.00,1667.00 1077.00,1499.00 1077.00,1499.00 Z",
    attr: {
      id: "_413",
      "data-name": "413",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "413", zone: "Upper Zone", zoneId: "4" },
  };
  var _414 = {
    path: "M 981.00,1499.00 C 981.00,1499.00 1060.00,1499.00 1060.00,1499.00 1060.00,1499.00 1060.00,1667.00 1060.00,1667.00 1060.00,1667.00 981.00,1667.00 981.00,1667.00 981.00,1667.00 981.00,1499.00 981.00,1499.00 Z",
    attr: {
      id: "_414",
      "data-name": "414",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "414", zone: "Upper Zone", zoneId: "4" },
  };
  var _415 = {
    path:
      "M 892.00,1499.00 C 892.00,1499.00 964.00,1499.00 964.00,1499.00 964.00,1499.00 964.00,1667.00 964.00,1667.00 964.00,1667.00 911.00,1667.00 911.00,1667.00 911.00,1667.00 892.00,1668.00 892.00,1668.00 892.00,1668.00 892.00,1499.00 892.00,1499.00 Z",
    attr: {
      id: "_415",
      "data-name": "415",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "415", zone: "Upper Zone", zoneId: "4" },
  };
  var _416 = {
    path:
      "M 801.00,1668.00 C 801.00,1668.00 801.91,1655.00 801.91,1655.00 801.91,1655.00 804.91,1614.00 804.91,1614.00 804.91,1614.00 808.91,1562.00 808.91,1562.00 808.91,1562.00 810.90,1538.00 810.90,1538.00 810.90,1538.00 810.90,1529.00 810.90,1529.00 810.90,1529.00 812.91,1509.00 812.91,1509.00 813.09,1506.32 812.39,1500.77 814.60,1499.02 816.37,1497.62 822.62,1497.98 825.00,1498.00 825.00,1498.00 840.00,1499.00 840.00,1499.00 840.00,1499.00 875.00,1499.00 875.00,1499.00 875.00,1499.00 875.00,1668.00 875.00,1668.00 875.00,1668.00 801.00,1668.00 801.00,1668.00 Z",
    attr: {
      id: "_416",
      "data-name": "416",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "416", zone: "Upper Zone", zoneId: "4" },
  };
  var _417 = {
    path:
      "M 738.00,1490.00 C 738.00,1490.00 757.00,1492.57 757.00,1492.57 757.00,1492.57 775.00,1494.72 775.00,1494.72 775.00,1494.72 797.00,1497.00 797.00,1497.00 797.00,1497.00 794.91,1521.00 794.91,1521.00 794.91,1521.00 791.91,1562.00 791.91,1562.00 791.91,1562.00 788.09,1608.00 788.09,1608.00 788.09,1608.00 786.83,1629.00 786.83,1629.00 786.83,1629.00 784.91,1654.00 784.91,1654.00 784.91,1654.00 784.00,1667.00 784.00,1667.00 784.00,1667.00 734.00,1663.28 734.00,1663.28 734.00,1663.28 709.00,1660.00 709.00,1660.00 709.00,1660.00 727.28,1553.00 727.28,1553.00 727.28,1553.00 738.00,1490.00 738.00,1490.00 Z",
    attr: {
      id: "_417",
      "data-name": "417",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "417", zone: "Upper Zone", zoneId: "4" },
  };
  var _418 = {
    path:
      "M 666.00,1475.00 C 666.00,1475.00 680.00,1478.35 680.00,1478.35 680.00,1478.35 722.00,1487.00 722.00,1487.00 722.00,1487.00 715.58,1523.00 715.58,1523.00 715.58,1523.00 701.08,1607.00 701.08,1607.00 701.08,1607.00 692.00,1658.00 692.00,1658.00 664.67,1653.02 636.72,1648.74 610.00,1641.00 610.00,1641.00 666.00,1475.00 666.00,1475.00 Z",
    attr: {
      id: "_418",
      "data-name": "418",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "418", zone: "Upper Zone", zoneId: "4" },
  };
  var _419 = {
    path:
      "M 598.00,1452.00 C 610.77,1457.41 636.86,1467.00 650.00,1470.00 650.00,1470.00 623.67,1549.00 623.67,1549.00 623.67,1549.00 604.67,1606.00 604.67,1606.00 604.67,1606.00 594.00,1637.00 594.00,1637.00 594.00,1637.00 543.00,1621.00 543.00,1621.00 543.00,1621.00 520.00,1613.00 520.00,1613.00 520.00,1613.00 546.58,1558.00 546.58,1558.00 546.58,1558.00 598.00,1452.00 598.00,1452.00 Z",
    attr: {
      id: "_419",
      "data-name": "419",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "419", zone: "Upper Zone", zoneId: "4" },
  };
  var _420 = {
    path:
      "M 436.00,1573.00 C 436.00,1573.00 459.70,1537.00 459.70,1537.00 459.70,1537.00 504.79,1469.00 504.79,1469.00 504.79,1469.00 525.46,1438.00 525.46,1438.00 527.12,1435.46 532.85,1425.93 534.61,1424.42 537.77,1421.70 542.01,1425.15 545.00,1426.77 545.00,1426.77 572.00,1440.74 572.00,1440.74 574.31,1441.86 580.56,1443.93 581.29,1446.39 581.93,1448.60 578.31,1454.71 577.23,1457.00 577.23,1457.00 562.71,1487.00 562.71,1487.00 562.71,1487.00 505.00,1606.00 505.00,1606.00 505.00,1606.00 475.00,1593.25 475.00,1593.25 475.00,1593.25 436.00,1573.00 436.00,1573.00 Z",
    attr: {
      id: "_420",
      "data-name": "420",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "420", zone: "Upper Zone", zoneId: "4" },
  };
  var _421 = {
    path:
      "M 487.00,1390.00 C 493.09,1391.29 497.11,1395.46 502.00,1399.13 502.00,1399.13 522.00,1413.00 522.00,1413.00 522.00,1413.00 499.79,1447.00 499.79,1447.00 499.79,1447.00 447.29,1526.00 447.29,1526.00 447.29,1526.00 428.65,1554.00 428.65,1554.00 426.91,1556.55 423.99,1562.56 420.91,1563.13 417.69,1563.71 406.05,1555.30 403.00,1553.14 390.71,1544.42 364.20,1525.54 355.00,1515.00 355.00,1515.00 385.00,1487.04 385.00,1487.04 385.00,1487.04 430.17,1444.42 430.17,1444.42 430.17,1444.42 472.00,1405.00 472.00,1405.00 472.00,1405.00 487.00,1390.00 487.00,1390.00 Z",
    attr: {
      id: "_421",
      "data-name": "421",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "421", zone: "Upper Zone", zoneId: "4" },
  };
  var _422 = {
    path:
      "M 297.00,1453.00 C 297.00,1453.00 354.00,1411.49 354.00,1411.49 354.00,1411.49 395.00,1382.10 395.00,1382.10 395.00,1382.10 426.00,1359.61 426.00,1359.61 431.92,1355.29 438.91,1348.92 446.00,1347.00 446.00,1347.00 476.00,1379.00 476.00,1379.00 469.90,1385.87 467.27,1387.47 461.09,1393.28 461.09,1393.28 435.00,1418.09 435.00,1418.09 435.00,1418.09 377.00,1473.04 377.00,1473.04 377.00,1473.04 353.00,1495.96 353.00,1495.96 350.95,1497.93 345.91,1502.89 343.00,1502.82 339.71,1502.74 332.50,1494.50 330.00,1492.00 330.00,1492.00 308.85,1469.00 308.85,1469.00 304.48,1464.04 299.44,1459.23 297.00,1453.00 Z",
    attr: {
      id: "_422",
      "data-name": "422",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "422", zone: "Upper Zone", zoneId: "4" },
  };
  var _423 = {
    path:
      "M 240.00,1367.00 C 240.00,1367.00 269.00,1355.14 269.00,1355.14 269.00,1355.14 339.00,1326.86 339.00,1326.86 339.00,1326.86 367.00,1315.37 367.00,1315.37 367.00,1315.37 398.00,1302.74 398.00,1302.74 400.58,1301.74 408.16,1298.79 410.62,1299.67 412.93,1300.49 415.13,1304.93 416.42,1307.00 416.42,1307.00 429.15,1326.00 429.15,1326.00 430.80,1328.29 434.07,1331.66 432.26,1334.62 432.26,1334.62 422.00,1342.58 422.00,1342.58 422.00,1342.58 395.00,1361.97 395.00,1361.97 395.00,1361.97 317.00,1418.37 317.00,1418.37 317.00,1418.37 298.00,1432.24 298.00,1432.24 293.24,1435.82 290.98,1438.52 285.00,1440.00 285.00,1440.00 267.69,1415.00 267.69,1415.00 267.69,1415.00 240.00,1367.00 240.00,1367.00 Z",
    attr: {
      id: "_423",
      "data-name": "423",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "423", zone: "Upper Zone", zoneId: "4" },
  };
  var _424 = {
    path:
      "M 210.00,1292.00 C 210.00,1292.00 273.00,1272.65 273.00,1272.65 273.00,1272.65 344.00,1250.96 344.00,1250.96 344.00,1250.96 376.00,1241.00 376.00,1241.00 376.00,1241.00 388.73,1272.00 388.73,1272.00 388.73,1272.00 394.00,1287.00 394.00,1287.00 394.00,1287.00 341.00,1308.44 341.00,1308.44 341.00,1308.44 319.00,1317.14 319.00,1317.14 319.00,1317.14 266.00,1338.80 266.00,1338.80 266.00,1338.80 233.00,1352.00 233.00,1352.00 226.77,1340.76 212.87,1304.59 210.00,1292.00 Z",
    attr: {
      id: "_424",
      "data-name": "424",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "424", zone: "Upper Zone", zoneId: "4" },
  };
  var _425 = {
    path:
      "M 189.00,1195.00 C 189.00,1195.00 206.00,1192.43 206.00,1192.43 206.00,1192.43 238.00,1187.43 238.00,1187.43 238.00,1187.43 317.00,1175.57 317.00,1175.57 317.00,1175.57 359.00,1169.00 359.00,1169.00 359.42,1188.36 366.21,1207.23 370.00,1226.00 370.00,1226.00 359.00,1229.26 359.00,1229.26 359.00,1229.26 326.00,1239.33 326.00,1239.33 326.00,1239.33 292.00,1249.65 292.00,1249.65 292.00,1249.65 220.00,1271.65 220.00,1271.65 220.00,1271.65 205.00,1276.00 205.00,1276.00 201.03,1266.92 197.99,1251.97 196.00,1242.00 196.00,1242.00 189.00,1195.00 189.00,1195.00 Z",
    attr: {
      id: "_425",
      "data-name": "425",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "425", zone: "Upper Zone", zoneId: "4" },
  };
  var _426 = {
    path:
      "M 183.00,1093.00 C 183.00,1093.00 354.00,1093.00 354.00,1093.00 354.00,1093.00 354.00,1107.00 354.00,1107.00 354.00,1107.00 355.00,1123.00 355.00,1123.00 355.00,1123.00 357.00,1152.00 357.00,1152.00 357.00,1152.00 329.00,1156.42 329.00,1156.42 329.00,1156.42 265.00,1166.27 265.00,1166.27 265.00,1166.27 187.00,1178.00 187.00,1178.00 187.00,1178.00 185.00,1146.00 185.00,1146.00 185.00,1146.00 184.00,1130.00 184.00,1130.00 184.00,1130.00 183.00,1093.00 183.00,1093.00 Z",
    attr: {
      id: "_426",
      "data-name": "426",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "426", zone: "Upper Zone", zoneId: "4" },
  };
  var _427 = {
    path:
      "M 184.00,1010.00 C 184.00,1010.00 353.00,1010.00 353.00,1010.00 353.00,1010.00 353.00,1059.00 353.00,1059.00 353.00,1059.00 354.00,1076.00 354.00,1076.00 354.00,1076.00 184.00,1076.00 184.00,1076.00 184.00,1076.00 184.00,1010.00 184.00,1010.00 Z",
    attr: {
      id: "_427",
      "data-name": "427",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "427", zone: "Upper Zone", zoneId: "4" },
  };
  var _428 = {
    path:
      "M 183.00,917.00 C 183.00,917.00 353.00,917.00 353.00,917.00 353.00,917.00 353.00,993.00 353.00,993.00 353.00,993.00 184.00,993.00 184.00,993.00 184.00,993.00 184.00,960.00 184.00,960.00 184.00,960.00 183.00,944.00 183.00,944.00 183.00,944.00 183.00,917.00 183.00,917.00 Z",
    attr: {
      id: "_428",
      "data-name": "428",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "428", zone: "Upper Zone", zoneId: "4" },
  };
  var _429 = {
    path:
      "M 187.00,801.00 C 187.00,801.00 202.00,804.35 202.00,804.35 202.00,804.35 227.00,809.79 227.00,809.79 227.00,809.79 316.00,829.42 316.00,829.42 316.00,829.42 354.00,838.00 354.00,838.00 354.00,838.00 353.00,857.00 353.00,857.00 353.00,857.00 353.00,900.00 353.00,900.00 353.00,900.00 183.00,900.00 183.00,900.00 183.00,900.00 183.00,859.00 183.00,859.00 183.00,859.00 183.91,847.00 183.91,847.00 183.91,847.00 186.09,814.00 186.09,814.00 186.09,814.00 187.00,801.00 187.00,801.00 Z",
    attr: {
      id: "_429",
      "data-name": "429",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "429", zone: "Upper Zone", zoneId: "4" },
  };
  var _430 = {
    path:
      "M 202.00,718.00 C 202.00,718.00 236.00,728.26 236.00,728.26 236.00,728.26 282.00,742.65 282.00,742.65 282.00,742.65 336.00,759.04 336.00,759.04 336.00,759.04 365.00,768.00 365.00,768.00 365.00,768.00 360.61,792.00 360.61,792.00 360.61,792.00 356.00,821.00 356.00,821.00 356.00,821.00 311.00,811.21 311.00,811.21 311.00,811.21 208.00,788.58 208.00,788.58 208.00,788.58 189.00,784.00 189.00,784.00 189.00,784.00 194.80,752.00 194.80,752.00 194.80,752.00 202.00,718.00 202.00,718.00 Z",
    attr: {
      id: "_430",
      "data-name": "430",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "430", zone: "Upper Zone", zoneId: "4" },
  };
  var _431 = {
    path:
      "M 237.00,623.00 C 237.00,623.00 285.00,649.94 285.00,649.94 285.00,649.94 357.00,691.55 357.00,691.55 357.00,691.55 389.00,710.00 389.00,710.00 385.00,721.69 375.25,733.38 371.00,752.00 360.99,751.10 326.21,738.66 314.00,735.04 314.00,735.04 266.00,720.04 266.00,720.04 266.00,720.04 219.00,705.35 219.00,705.35 219.00,705.35 207.00,702.00 207.00,702.00 213.05,675.49 226.26,647.97 237.00,623.00 Z",
    attr: {
      id: "_431",
      "data-name": "431",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "431", zone: "Upper Zone", zoneId: "4" },
  };
  var _432 = {
    path:
      "M 245.00,608.00 C 252.66,593.50 261.30,579.42 270.72,566.00 272.59,563.34 279.50,553.15 282.17,552.45 284.75,551.78 289.87,555.85 292.00,557.37 292.00,557.37 318.00,578.40 318.00,578.40 318.00,578.40 341.00,597.16 341.00,597.16 341.00,597.16 351.00,604.26 351.00,604.26 351.00,604.26 422.00,661.00 422.00,661.00 422.00,661.00 410.01,681.00 410.01,681.00 410.01,681.00 401.00,698.00 401.00,698.00 401.00,698.00 362.00,675.56 362.00,675.56 362.00,675.56 282.00,629.29 282.00,629.29 282.00,629.29 245.00,608.00 245.00,608.00 Z",
    attr: {
      id: "_432",
      "data-name": "432",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "432", zone: "Upper Zone", zoneId: "4" },
  };
  var _433 = {
    path:
      "M 293.00,537.00 C 293.00,537.00 311.17,516.00 311.17,516.00 311.17,516.00 334.00,492.00 334.00,492.00 339.70,486.30 346.54,477.92 354.00,475.00 354.00,475.00 364.32,488.00 364.32,488.00 364.32,488.00 408.42,543.00 408.42,543.00 408.42,543.00 436.37,578.00 436.37,578.00 436.37,578.00 455.00,601.00 455.00,601.00 455.00,601.00 461.66,611.00 461.66,611.00 461.66,611.00 454.28,621.00 454.28,621.00 454.28,621.00 432.00,648.00 432.00,648.00 432.00,648.00 412.00,632.66 412.00,632.66 412.00,632.66 356.00,587.84 356.00,587.84 356.00,587.84 347.00,581.54 347.00,581.54 347.00,581.54 314.00,554.61 314.00,554.61 314.00,554.61 293.00,537.00 293.00,537.00 Z",
    attr: {
      id: "_433",
      "data-name": "433",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "433", zone: "Upper Zone", zoneId: "4" },
  };
  var _434 = {
    path:
      "M 415.00,424.00 C 420.34,428.84 435.32,453.35 440.29,461.00 440.29,461.00 487.91,534.00 487.91,534.00 487.91,534.00 505.00,560.00 505.00,560.00 506.42,562.20 510.46,567.41 510.41,569.82 510.34,572.80 504.26,576.42 502.00,578.13 502.00,578.13 475.00,600.00 475.00,600.00 475.00,600.00 447.83,566.00 447.83,566.00 447.83,566.00 426.58,540.00 426.58,540.00 426.58,540.00 379.16,481.00 379.16,481.00 379.16,481.00 365.00,463.00 365.00,463.00 365.00,463.00 398.00,436.37 398.00,436.37 398.00,436.37 415.00,424.00 415.00,424.00 Z",
    attr: {
      id: "_434",
      "data-name": "434",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "434", zone: "Upper Zone", zoneId: "4" },
  };
  var _435 = {
    path:
      "M 430.00,414.00 C 430.00,414.00 473.00,389.22 473.00,389.22 480.29,385.37 487.77,380.54 496.00,379.00 496.00,379.00 514.13,420.00 514.13,420.00 514.13,420.00 549.09,498.00 549.09,498.00 549.09,498.00 567.00,538.00 567.00,538.00 567.00,538.00 544.00,550.42 544.00,550.42 544.00,550.42 525.00,561.00 525.00,561.00 525.00,561.00 503.29,528.00 503.29,528.00 503.29,528.00 458.46,459.00 458.46,459.00 458.46,459.00 440.21,431.00 440.21,431.00 440.21,431.00 430.00,414.00 430.00,414.00 Z",
    attr: {
      id: "_435",
      "data-name": "435",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "435", zone: "Upper Zone", zoneId: "4" },
  };
  var _436 = {
    path:
      "M 582.00,531.00 C 582.00,531.00 565.91,495.00 565.91,495.00 565.91,495.00 532.91,422.00 532.91,422.00 532.91,422.00 517.99,389.00 517.99,389.00 517.99,389.00 511.60,374.11 511.60,374.11 511.09,369.57 522.42,366.61 526.00,365.20 526.00,365.20 567.00,351.58 567.00,351.58 570.24,350.66 579.71,347.29 582.49,349.06 584.85,350.57 586.17,357.26 587.03,360.00 587.03,360.00 596.03,390.00 596.03,390.00 596.03,390.00 616.96,459.00 616.96,459.00 616.96,459.00 633.00,512.00 633.00,512.00 621.04,514.73 594.15,525.85 582.00,531.00 Z",
    attr: {
      id: "_436",
      "data-name": "436",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "436", zone: "Upper Zone", zoneId: "4" },
  };
  var _437 = {
    path:
      "M 600.00,343.00 C 600.00,343.00 675.00,324.00 675.00,324.00 675.00,324.00 683.58,377.00 683.58,377.00 683.58,377.00 695.92,450.00 695.92,450.00 695.92,450.00 701.27,482.00 701.27,482.00 701.27,482.00 701.94,493.41 701.94,493.41 701.94,493.41 687.00,497.60 687.00,497.60 687.00,497.60 649.00,507.00 649.00,507.00 649.00,507.00 638.96,475.00 638.96,475.00 638.96,475.00 620.96,415.00 620.96,415.00 620.96,415.00 606.97,369.00 606.97,369.00 606.97,369.00 600.00,343.00 600.00,343.00 Z",
    attr: {
      id: "_437",
      "data-name": "437",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "437", zone: "Upper Zone", zoneId: "4" },
  };
  var _438 = {
    path:
      "M 691.00,320.00 C 691.00,320.00 710.00,316.42 710.00,316.42 727.22,313.32 754.94,309.00 772.00,309.00 772.00,309.00 775.00,366.00 775.00,366.00 775.00,366.00 776.91,392.00 776.91,392.00 776.91,392.00 778.00,413.00 778.00,413.00 778.00,413.00 780.00,443.00 780.00,443.00 780.00,443.00 782.00,485.00 782.00,485.00 782.00,485.00 763.00,486.83 763.00,486.83 763.00,486.83 736.00,489.17 736.00,489.17 736.00,489.17 720.00,491.00 720.00,491.00 720.00,491.00 700.72,377.00 700.72,377.00 700.72,377.00 691.00,320.00 691.00,320.00 Z",
    attr: {
      id: "_438",
      "data-name": "438",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "438", zone: "Upper Zone", zoneId: "4" },
  };
  var _439 = {
    path:
      "M 799.00,483.00 C 799.00,483.00 797.00,443.00 797.00,443.00 797.00,443.00 795.00,413.00 795.00,413.00 795.00,413.00 794.00,400.00 794.00,400.00 794.00,400.00 794.00,391.00 794.00,391.00 794.00,391.00 792.00,365.00 792.00,365.00 792.00,365.00 790.00,336.00 790.00,336.00 790.00,336.00 790.00,327.00 790.00,327.00 790.00,327.00 789.00,314.00 789.00,314.00 789.16,305.63 791.51,307.13 803.00,307.00 803.00,307.00 826.00,305.82 826.00,305.82 832.56,305.03 829.60,304.02 840.00,304.00 840.00,304.00 863.00,304.00 863.00,304.00 863.00,304.00 863.00,480.00 863.00,480.00 863.00,480.00 843.00,481.00 843.00,481.00 843.00,481.00 799.00,483.00 799.00,483.00 Z",
    attr: {
      id: "_439",
      "data-name": "439",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "439", zone: "Upper Zone", zoneId: "4" },
  };
  var _440 = {
    path: "M 880.00,304.00 C 880.00,304.00 949.00,304.00 949.00,304.00 949.00,304.00 949.00,481.00 949.00,481.00 949.00,481.00 880.00,481.00 880.00,481.00 880.00,481.00 880.00,304.00 880.00,304.00 Z",
    attr: {
      id: "_440",
      "data-name": "440",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "440", zone: "Upper Zone", zoneId: "4" },
  };
  var _441 = {
    path:
      "M 966.00,304.00 C 966.00,304.00 1049.00,304.00 1049.00,304.00 1047.00,319.17 1048.00,351.12 1048.00,368.00 1048.00,368.00 1048.00,481.00 1048.00,481.00 1048.00,481.00 966.00,481.00 966.00,481.00 966.00,481.00 966.00,304.00 966.00,304.00 Z",
    attr: {
      id: "_441",
      "data-name": "441",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "441", zone: "Upper Zone", zoneId: "4" },
  };
  var _442 = {
    path:
      "M 1064.00,304.00 C 1064.00,304.00 1138.00,304.00 1138.00,304.00 1138.00,304.00 1138.00,481.00 1138.00,481.00 1138.00,481.00 1065.00,481.00 1065.00,481.00 1065.00,481.00 1065.00,368.00 1065.00,368.00 1065.00,351.12 1066.00,319.17 1064.00,304.00 Z",
    attr: {
      id: "_442",
      "data-name": "442",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "442", zone: "Upper Zone", zoneId: "4" },
  };
  var _443 = {
    path:
      "M 1155.00,304.00 C 1155.00,304.00 1237.00,304.00 1237.00,304.00 1237.00,304.00 1234.09,342.00 1234.09,342.00 1234.09,342.00 1228.17,409.00 1228.17,409.00 1228.17,409.00 1225.83,439.00 1225.83,439.00 1225.83,439.00 1223.83,462.00 1223.83,462.00 1223.83,462.00 1222.00,482.00 1222.00,482.00 1222.00,482.00 1207.00,481.00 1207.00,481.00 1207.00,481.00 1155.00,481.00 1155.00,481.00 1155.00,481.00 1155.00,304.00 1155.00,304.00 Z",
    attr: {
      id: "_443",
      "data-name": "443",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "443", zone: "Upper Zone", zoneId: "4" },
  };
  var _444 = {
    path:
      "M 1254.00,305.00 C 1274.63,305.06 1318.50,312.95 1340.00,317.00 1340.00,317.00 1328.23,368.00 1328.23,368.00 1328.23,368.00 1315.11,427.00 1315.11,427.00 1315.11,427.00 1301.00,490.00 1301.00,490.00 1301.00,490.00 1239.00,483.00 1239.00,483.00 1239.00,483.00 1241.91,451.00 1241.91,451.00 1241.91,451.00 1251.09,342.00 1251.09,342.00 1251.09,342.00 1254.00,305.00 1254.00,305.00 Z",
    attr: {
      id: "_444",
      "data-name": "444",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "444", zone: "Upper Zone", zoneId: "4" },
  };
  var _445 = {
    path:
      "M 1356.00,321.00 C 1356.00,321.00 1392.00,329.58 1392.00,329.58 1392.00,329.58 1432.00,342.00 1432.00,342.00 1432.00,342.00 1417.69,383.00 1417.69,383.00 1417.69,383.00 1384.31,475.00 1384.31,475.00 1384.31,475.00 1373.00,507.00 1373.00,507.00 1373.00,507.00 1317.00,494.00 1317.00,494.00 1317.00,494.00 1331.77,429.00 1331.77,429.00 1331.77,429.00 1345.23,369.00 1345.23,369.00 1345.23,369.00 1356.00,321.00 1356.00,321.00 Z",
    attr: {
      id: "_445",
      "data-name": "445",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "445", zone: "Upper Zone", zoneId: "4" },
  };
  var _446 = {
    path:
      "M 1448.00,347.00 C 1462.62,351.88 1506.76,369.06 1519.00,376.00 1519.00,376.00 1489.52,436.00 1489.52,436.00 1489.52,436.00 1442.00,531.00 1442.00,531.00 1442.00,531.00 1388.00,512.00 1388.00,512.00 1388.00,512.00 1399.63,481.00 1399.63,481.00 1399.63,481.00 1424.37,412.00 1424.37,412.00 1424.37,412.00 1448.00,347.00 1448.00,347.00 Z",
    attr: {
      id: "_446",
      "data-name": "446",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "446", zone: "Upper Zone", zoneId: "4" },
  };
  var _447 = {
    path:
      "M 1534.00,383.00 C 1556.68,392.77 1582.68,408.21 1604.00,421.00 1604.00,421.00 1592.77,438.00 1592.77,438.00 1592.77,438.00 1571.09,468.00 1571.09,468.00 1571.09,468.00 1532.49,522.00 1532.49,522.00 1532.49,522.00 1505.00,560.00 1505.00,560.00 1505.00,560.00 1494.00,554.27 1494.00,554.27 1494.00,554.27 1484.00,549.74 1484.00,549.74 1484.00,549.74 1458.00,538.00 1458.00,538.00 1458.00,538.00 1466.94,518.00 1466.94,518.00 1466.94,518.00 1481.97,488.00 1481.97,488.00 1481.97,488.00 1518.31,415.00 1518.31,415.00 1518.31,415.00 1534.00,383.00 1534.00,383.00 Z",
    attr: {
      id: "_447",
      "data-name": "447",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "447", zone: "Upper Zone", zoneId: "4" },
  };
  var _448 = {
    path:
      "M 1618.00,430.00 C 1638.15,441.31 1656.20,456.65 1675.00,470.00 1672.65,475.86 1667.41,479.61 1663.00,484.00 1663.00,484.00 1636.01,509.28 1636.01,509.28 1636.01,509.28 1613.91,530.72 1613.91,530.72 1613.91,530.72 1578.09,564.28 1578.09,564.28 1568.77,573.05 1559.59,583.90 1549.00,591.00 1541.83,584.80 1534.84,579.92 1527.00,574.67 1524.89,573.26 1520.26,570.72 1520.18,567.91 1520.12,565.39 1524.85,560.27 1526.41,558.00 1526.41,558.00 1546.56,530.00 1546.56,530.00 1546.56,530.00 1552.69,521.00 1552.69,521.00 1552.69,521.00 1586.69,474.00 1586.69,474.00 1586.69,474.00 1618.00,430.00 1618.00,430.00 Z",
    attr: {
      id: "_448",
      "data-name": "448",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "448", zone: "Upper Zone", zoneId: "4" },
  };
  var _449 = {
    path:
      "M 1687.00,482.00 C 1695.91,488.18 1717.29,508.61 1724.58,517.00 1728.94,522.03 1733.54,525.73 1736.00,532.00 1736.00,532.00 1683.00,568.62 1683.00,568.62 1683.00,568.62 1631.00,604.09 1631.00,604.09 1631.00,604.09 1604.00,622.85 1604.00,622.85 1601.64,624.53 1594.96,629.56 1592.17,629.10 1589.92,628.74 1585.71,623.73 1584.00,622.00 1584.00,622.00 1563.00,601.00 1563.00,601.00 1563.00,601.00 1598.96,566.92 1598.96,566.92 1598.96,566.92 1648.09,519.84 1648.09,519.84 1648.09,519.84 1674.00,495.01 1674.00,495.01 1674.00,495.01 1687.00,482.00 1687.00,482.00 Z",
    attr: {
      id: "_449",
      "data-name": "449",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "449", zone: "Upper Zone", zoneId: "4" },
  };
  var _450 = {
    path:
      "M 1603.00,643.00 C 1603.00,643.00 1661.00,602.97 1661.00,602.97 1661.00,602.97 1705.00,572.88 1705.00,572.88 1705.00,572.88 1735.00,552.37 1735.00,552.37 1737.59,550.67 1743.20,545.80 1745.96,545.74 1750.01,545.67 1755.49,554.85 1757.66,558.00 1757.66,558.00 1783.30,599.00 1783.30,599.00 1785.22,602.49 1792.38,612.68 1789.36,616.24 1787.90,617.95 1777.70,622.04 1775.00,623.21 1775.00,623.21 1743.00,637.13 1743.00,637.13 1743.00,637.13 1669.00,669.65 1669.00,669.65 1669.00,669.65 1631.00,686.00 1631.00,686.00 1631.00,686.00 1614.28,659.00 1614.28,659.00 1614.28,659.00 1603.00,643.00 1603.00,643.00 Z",
    attr: {
      id: "_450",
      "data-name": "450",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "450", zone: "Upper Zone", zoneId: "4" },
  };
  var _451 = {
    path:
      "M 1640.00,700.00 C 1640.00,700.00 1680.00,682.65 1680.00,682.65 1680.00,682.65 1752.00,651.15 1752.00,651.15 1752.00,651.15 1800.00,631.00 1800.00,631.00 1800.00,631.00 1811.58,657.00 1811.58,657.00 1811.58,657.00 1826.00,694.00 1826.00,694.00 1826.00,694.00 1791.00,703.74 1791.00,703.74 1791.00,703.74 1725.00,721.48 1725.00,721.48 1725.00,721.48 1680.00,733.73 1680.00,733.73 1680.00,733.73 1656.00,740.00 1656.00,740.00 1656.00,740.00 1647.14,718.00 1647.14,718.00 1647.14,718.00 1640.00,700.00 1640.00,700.00 Z",
    attr: {
      id: "_451",
      "data-name": "451",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "451", zone: "Upper Zone", zoneId: "4" },
  };
  var _452 = {
    path:
      "M 1662.00,756.00 C 1662.00,756.00 1686.00,749.52 1686.00,749.52 1686.00,749.52 1719.00,740.52 1719.00,740.52 1719.00,740.52 1759.00,729.48 1759.00,729.48 1759.00,729.48 1831.00,710.00 1831.00,710.00 1831.00,710.00 1842.71,747.00 1842.71,747.00 1842.71,747.00 1854.00,792.00 1854.00,792.00 1854.00,792.00 1826.00,793.83 1826.00,793.83 1826.00,793.83 1804.00,795.09 1804.00,795.09 1804.00,795.09 1784.00,796.91 1784.00,796.91 1784.00,796.91 1674.00,805.00 1674.00,805.00 1674.00,805.00 1668.58,781.00 1668.58,781.00 1668.58,781.00 1662.00,756.00 1662.00,756.00 Z",
    attr: {
      id: "_452",
      "data-name": "452",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "452", zone: "Upper Zone", zoneId: "4" },
  };
  var _453 = {
    path:
      "M 1856.00,808.00 C 1856.00,808.00 1857.00,831.00 1857.00,831.00 1857.00,831.00 1857.00,881.00 1857.00,881.00 1857.00,881.00 1676.96,881.00 1676.96,881.00 1676.96,881.00 1676.96,867.00 1676.96,867.00 1676.96,867.00 1676.00,855.00 1676.00,855.00 1676.00,855.00 1676.00,822.00 1676.00,822.00 1676.00,822.00 1730.00,817.91 1730.00,817.91 1730.00,817.91 1804.00,812.09 1804.00,812.09 1804.00,812.09 1843.00,809.28 1843.00,809.28 1843.00,809.28 1856.00,808.00 1856.00,808.00 Z",
    attr: {
      id: "_453",
      "data-name": "453",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "453", zone: "Upper Zone", zoneId: "4" },
  };
  var _454 = {
    path:
      "M 1677.00,898.00 C 1677.00,898.00 1858.00,898.00 1858.00,898.00 1858.00,898.00 1858.00,967.00 1858.00,967.00 1858.00,967.00 1678.00,967.00 1678.00,967.00 1678.00,967.00 1678.00,946.00 1678.00,946.00 1678.00,946.00 1677.00,930.00 1677.00,930.00 1677.00,930.00 1677.00,898.00 1677.00,898.00 Z",
    attr: {
      id: "_454",
      "data-name": "454",
      opacity: 0,
      fill: "#2196f3",
      stroke: "#d7d7d7",
      "stroke-miterlimit": "10",
      "stroke-width": "0",
      "stroke-opacity": "1",
    },
    transform: "",
    data: { zoneNo: "454", zone: "Upper Zone", zoneId: "4" },
  };

  zones.push(
    _201,
    _202,
    _203,
    _204,
    _206,
    _207,
    _209,
    _210,
    _211,
    _212,
    _213,
    _214,
    _215,
    _216,
    _217,
    _218,
    _219,
    _221,
    _222,
    _224,
    _225,
    _226,
    _227,
    _228,
    _229,
    _230,
    _231,
    _233,
    _234,
    _236,
    _237,
    _238,
    _239,
    _240,
    _241,
    _242,
    _243,
    _244,
    _245,
    _246,
    _248,
    _249,
    _251,
    _252,
    _253,
    _254,
    _336,
    _337,
    _338,
    _339,
    _340,
    _341,
    _342,
    _343,
    _344,
    _345,
    _346,
    _401,
    _402,
    _403,
    _404,
    _405,
    _406,
    _407,
    _408,
    _409,
    _410,
    _411,
    _412,
    _413,
    _414,
    _415,
    _416,
    _417,
    _418,
    _419,
    _420,
    _421,
    _422,
    _423,
    _424,
    _425,
    _426,
    _427,
    _428,
    _429,
    _430,
    _431,
    _432,
    _433,
    _434,
    _435,
    _436,
    _437,
    _438,
    _439,
    _440,
    _441,
    _442,
    _443,
    _444,
    _445,
    _446,
    _447,
    _448,
    _449,
    _450,
    _451,
    _452,
    _453,
    _454,
    _TheDen
  );
  return zones;
};
