import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import moment from 'moment';

const styles = {
  MuiButton: {
    fontSize: '12px !important',
  },
};

class Footer extends Component {
  render() {
    const { classes } = this.props;
    const styles = {
      marginTop: '5rem',
      padding: '1rem',
      paddingBottom: '5rem',
      ...this.props.style,
    };

    return (
      <Grid container style={styles}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant='body2'
            color='secondary'
            style={{
              marginBottom: '1rem',
            }}
          >
            {moment().format('YYYY')} FansFirst Tickets Inc. All Rights
            Reserved.
          </Typography>
        </Grid>
        <Grid item md={2} lg={2} xl={2}></Grid>
        <Grid
          item
          xs={3}
          md={2}
          lg={2}
          xl={2}
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            className={classes.MuiButton}
            disableRipple={true}
            href='/sell'
          >
            Sell
          </Button>
        </Grid>

        <Grid
          item
          xs={3}
          md={2}
          lg={2}
          xl={2}
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            className={classes.MuiButton}
            disableRipple={true}
            href='/legal'
          >
            Legal
          </Button>
        </Grid>

        <Grid
          item
          xs={3}
          md={2}
          lg={2}
          xl={2}
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            className={classes.MuiButton}
            disableRipple={true}
            href='/why-us'
          >
            About
          </Button>
        </Grid>

        <Grid
          item
          xs={3}
          md={2}
          lg={2}
          xl={2}
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            className={classes.MuiButton}
            disableRipple={true}
            href='/faqs'
          >
            FAQs
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Footer);
