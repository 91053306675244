import React, { Component } from "react";
import Faqs from "../components/Faqs";
import Helmet from "react-helmet";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class FaqPage extends Component {
  render() {
    const currentURL = window.location.href;
    return (
      <Grid container justify="center">
        <Helmet>
          <title>Frequently Asked Questions</title>
          <meta name="description" content="Frequently Asked Questions" />
          <meta name="keywords" content={`faqs, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Frequently Asked Questions" />
          <meta property="og:description" content="Frequently Asked Questions" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Grid item xs={12} md={6}>
          <main style={{ marginTop: "5rem" }}>
            <Typography variant="headline">
              Frequently Asked Questions
            </Typography>
            <Faqs />
          </main>
        </Grid>
      </Grid>
    );
  }
}

export default FaqPage;
