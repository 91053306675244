import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import SeatMapImg from '../images/seatmap.png';

import JetSeatmap from '../images/jets-seatmap.png';
import FlamesSeatmap from '../images/saddledome.png';
import ReginaSeatmap from '../images/regina-stadium-full.png';
import OilersSeatmap from '../images/oilers-seatmap.png';
import CanucksSeatmap from '../images/seatmaps/canucks.png';
import MapleLeafsSeatmap from '../images/scotiabank-arena-hockey.png';
import SeantorsSeatmap from '../images/canadian-tire-centre.png';
import RaptorsSeatmap from '../images/scotiabank-arena-basketball.png';
import BlueJaysSeatmap from '../images/bluejays-seat-map.png';
import ElksSeatmap from '../images/elks-seat-map.png';
import BlueBombersSeatmap from '../images/bluebombers-seat-map.png';
import ArgonautssSeatmap from '../images/argonauts-seat-map.png';
import StampederssSeatmap from '../images/stampeders-seat-map.png';
import RedblacksSeatmap from '../images/redblacks-seat-map.png';
import LionsSeatmap from '../images/lions-seat-map.png';
import TigercatsSeatMap from '../images/tigercats-seat-map-v2.png';
import RidersSeatMap from '../images/roughriders-seat-map.png';
import WranglersSeatmap from '../images/wranglers-seat-map.png';
import AbbotsfordCanucksSeatmap from '../images/abbotsford-canucks-seat-map.png';
import MooseSeatmap from '../images/moose-seat-map.png';
import WhitecapsSeatmap from '../images/whitecaps-seat-map.png';
import TorontoFcSeatmap from '../images/torontofc-seat-map.png';
import BellevilleSenatorsSeatmap from '../images/belleville-senators-seat-map.png';
import TorontoMarliesSeatmap from '../images/toronto-marlies-seat-map.png';

const styles = theme => ({
  [theme.breakpoints.down('sm')]: {
    seatmapImg: {
      height: 'auto',
      width: '100%'
    }
  },
  [theme.breakpoints.up('md')]: {
    seatmapImg: {
      height: '80vh',
      width: 'auto'
    }
  }
});

class SeatMap extends Component {
  handleCancel = () => {
    this.props.onClose();
  };

  renderName(team, isSpecialGame) {
    if (isSpecialGame) {
      if (team === 'toronto-marlies') {
        return 'Scotiabank Arena';
      }
      return 'Regina, Sask Mosaic Stadium';
    } else if (team === 'calgary-flames') {
      return 'Saddledome';
    } else if (team === 'winnipeg-jets') {
      return 'Bell MTS Place';
    } else if (team === 'edmonton-oilers') {
      return "Rogers Place"
    } else if (team === 'vancouver-canucks') {
      return "Rogers Arena"
    } else if (team === 'toronto-maple-leafs') {
      return "Scotiabank Arena"
    } else if (team === 'ottawa-senators') {
      return "Canadian Tire Centre"
    } else if (team === 'toronto-raptors') {
      return "Scotiabank Arena"
    } else if (team === 'toronto-blue-jays') {
      return "Rogers Centre"
    } else if (team === 'edmonton-elks') {
      return "Commonwealth Stadium"
    } else if (team === 'winnipeg-blue-bombers') {
      return "IG Field"
    } else if (team === 'toronto-argonauts') {
      return "BMO Field"
    } else if (team === 'calgary-stampeders') {
      return "McMahon Stadium"
    } else if (team === 'ottawa-redblacks') {
      return "TD Place"
    } else if (team === 'bc-lions') {
      return "BC Place"
    } else if (team === 'calgary-wranglers') {
      return 'Saddledome';
    } else if (team === 'abbotsford-canucks') {
      return 'Abbotsford Centre';
    } else if (team === 'vancouver-whitecaps-fc') {
      return 'BC Place';
    } else if (team === 'toronto-fc') {
      return 'BMO Field';
    } else if (team === 'toronto-marlies') {
      return ' Coca-Cola Coliseum';
    } else if (team === 'belleville-senators') {
      return 'CAA Arena';
    }
  }

  renderSeatmap(team, isSpecialGame) {
    if (isSpecialGame) {
      return ReginaSeatmap;
    } else if (team === 'calgary-flames') {
      return FlamesSeatmap;
    } else if (team === 'winnipeg-jets') {
      return JetSeatmap;
    } else if (team === 'edmonton-oilers') {
      return OilersSeatmap
    } else if (team === 'vancouver-canucks') {
      return CanucksSeatmap
    } else if (team === 'toronto-maple-leafs') {
      return MapleLeafsSeatmap
    } else if (team === 'ottawa-senators') {
      return SeantorsSeatmap
    } else if (team === 'toronto-raptors') {
      return RaptorsSeatmap
    } else if (team === 'toronto-blue-jays') {
      return BlueJaysSeatmap
    } else if (team === 'edmonton-elks') {
      return ElksSeatmap
    } else if (team === 'winnipeg-blue-bombers') {
      return BlueBombersSeatmap
    } else if (team === 'toronto-argonauts') {
      return ArgonautssSeatmap
    } else if (team === 'calgary-stampeders') {
      return StampederssSeatmap
    } else if (team === 'ottawa-redblacks') {
      return RedblacksSeatmap
    } else if (team === 'bc-lions') {
      return LionsSeatmap
    } else if (team === 'hamilton-tigercats') {
      return TigercatsSeatMap
    } else if (team === 'saskatchewan-roughriders') {
      return RidersSeatMap
    } else if (team === 'grey-cup') {
      return LionsSeatmap
    } else if (team === 'calgary-wranglers') {
      return WranglersSeatmap;
    } else if (team === 'manitoba-moose') {
      return MooseSeatmap;
    } else if (team === 'vancouver-whitecaps-fc') {
      return WhitecapsSeatmap
    } else if (team === 'toronto-fc') {
      return TorontoFcSeatmap
    } else if (team === 'abbotsford-canucks') {
      return AbbotsfordCanucksSeatmap;
    } else if (team === 'belleville-senators') {
      return BellevilleSenatorsSeatmap;
    } else if (team === 'toronto-marlies') {
      return TorontoMarliesSeatmap;
    }

  }

  render() {
    const { onClose, classes, team, isSpecialGame, ...other } = this.props;

    return (
      <Dialog
        fullScreen={true}
        onClose={this.handleCancel}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">
          {this.renderName(team, isSpecialGame)}
          <IconButton
            disableRipple={true}
            disableTouchRipple={true}
            onClick={this.handleCancel}
            style={{
              padding: '0.5rem',
              position: 'absolute',
              top: '12px',
              right: '8px'
            }}
          >
            <CloseIcon style={{ fontSize: '1rem' }} />
          </IconButton>
        </DialogTitle>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <div
              style={{
                padding: '1rem',
                textAlign: 'center'
              }}
            >
              <img
                src={this.renderSeatmap(team, isSpecialGame)}
                className={classes.seatmapImg}
                alt="Seat Map"
              />
            </div>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Close Seat Map
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SeatMap.propTypes = {
  onClose: PropTypes.func
};

export default withStyles(styles)(SeatMap);
