import React from 'react';

import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  reasonContainer: {
    padding: '2rem 3rem',
    flex: '33.33%',
  },
  reasonHeadingContainer: {
    marginBottom: '2rem',
    display: 'flex',
  },
  iconContainer: {
    marginRight: '1rem',
    height: '2rem',
    width: '2rem',
  },
  icon: {
    height: '100%',
  },
  heading: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.down('sm')]: {
    reasonContainer: {
      flex: '50%',
      padding: '1rem',
      marginBottom: '1rem',
    },
    reasonHeadingContainer: {},
  },
  [theme.breakpoints.down('xs')]: {
    reasonContainer: {
      flex: '100%',
      padding: '1rem',
      marginBottom: '1rem',
    },
    reasonHeadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '.5rem',
    },
    iconContainer: {
      marginBottom: '1rem',
      marginRight: '0',
    },
    heading: {
      fontSize: '1rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    content: {
      textAlign: 'center',
    },
  },
});

const Reason = ({ classes, icon, heading, content }) => {
  return (
    <div className={classes.reasonContainer}>
      <div className={classes.reasonHeadingContainer}>
        <div className={classes.iconContainer}>
          <img src={icon} className={classes.icon} />
        </div>
        <Typography variant='body1' className={classes.heading} color='primary'>
          {heading()}
        </Typography>
      </div>
      <Typography variant='body1' className={classes.content}>
        {content}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Reason);
