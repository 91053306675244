export const getZones = rsr => {
    let zones = [];
    var _101 = {
      path:
        'M 903.00,661.00 C 903.00,661.00 936.00,651.42 936.00,651.42 939.95,650.29 950.04,647.72 952.90,645.49 957.19,642.15 960.11,634.14 963.76,630.30 966.35,627.58 967.82,628.21 971.42,624.78 971.42,624.78 986.09,609.00 986.09,609.00 986.09,609.00 995.83,599.00 995.83,599.00 995.83,599.00 1012.72,581.00 1012.72,581.00 1015.65,577.67 1023.74,568.54 1027.00,566.37 1035.10,560.99 1040.87,579.15 1048.72,575.24 1048.72,575.24 1064.00,562.41 1064.00,562.41 1064.00,562.41 1098.00,533.92 1098.00,533.92 1100.57,531.77 1111.90,521.87 1114.00,521.16 1117.30,520.06 1119.70,522.83 1121.72,525.02 1121.72,525.02 1135.35,541.00 1135.35,541.00 1151.12,561.46 1162.09,581.76 1170.95,606.00 1170.95,606.00 1176.73,623.00 1176.73,623.00 1177.07,624.81 1177.97,627.72 1176.73,629.44 1175.46,631.70 1164.04,633.87 1161.00,634.63 1161.00,634.63 1114.00,646.37 1114.00,646.37 1114.00,646.37 1017.00,670.37 1017.00,670.37 1006.23,673.07 974.96,681.89 966.00,682.00 966.00,682.00 916.00,682.00 916.00,682.00 914.10,681.99 911.14,682.15 909.56,680.98 907.88,679.74 907.38,676.91 906.85,675.00 906.85,675.00 903.00,661.00 903.00,661.00 Z',
      attr: {
        id: '_101',
        'data-name': '101',
         opacity: 0,
        fill: '#2196f3',
        stroke: '#d7d7d7',
        'stroke-miterlimit': '10',
        'stroke-width': '0',
         'stroke-opacity': '1',
      },
      transform: "",
      data: { zoneNo: "101", zone: 'Lower Zone', zoneId: "1" },
    };
    var _102 = {
        path:
          'M 910.00,690.00 C 910.00,690.00 961.00,690.00 961.00,690.00 974.43,689.98 998.13,682.84 1012.00,679.37 1012.00,679.37 1106.00,656.12 1106.00,656.12 1106.00,656.12 1154.00,644.13 1154.00,644.13 1154.00,644.13 1181.00,638.00 1181.00,638.00 1181.00,638.00 1186.00,674.00 1186.00,674.00 1186.00,674.00 1186.00,746.00 1186.00,746.00 1186.00,746.00 974.00,746.00 974.00,746.00 974.00,746.00 959.00,747.00 959.00,747.00 959.00,747.00 910.00,747.00 910.00,747.00 910.00,747.00 910.00,690.00 910.00,690.00 Z',
        attr: {
          id: '_102',
          'data-name': '102',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "102", zone: 'Lower Zone', zoneId: "1" },
      };
      var _103 = {
        path:
          'M 910.00,755.00 C 910.00,755.00 928.00,754.00 928.00,754.00 928.00,754.00 1186.00,754.00 1186.00,754.00 1186.00,754.00 1186.00,811.00 1186.00,811.00 1186.00,824.10 1186.11,841.36 1183.00,854.00 1183.00,854.00 1123.00,844.42 1123.00,844.42 1123.00,844.42 986.00,822.73 986.00,822.73 986.00,822.73 910.00,811.00 910.00,811.00 910.00,811.00 910.00,755.00 910.00,755.00 Z',
        attr: {
          id: '_103',
          'data-name': '103',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "103", zone: 'Lower Zone', zoneId: "1" },
      };
      var _104 = {
        path:
          'M 902.00,836.00 C 903.04,832.88 906.54,820.51 908.70,819.03 911.05,817.42 922.71,820.25 926.00,820.73 926.00,820.73 973.00,828.13 973.00,828.13 973.00,828.13 1125.00,852.27 1125.00,852.27 1125.00,852.27 1182.00,861.00 1182.00,861.00 1182.00,861.00 1168.98,912.00 1168.98,912.00 1168.98,912.00 1155.60,949.00 1155.60,949.00 1152.04,957.51 1139.17,965.59 1132.00,971.95 1129.58,974.10 1125.48,978.34 1122.00,977.85 1119.51,977.50 1113.28,972.76 1111.00,971.14 1111.00,971.14 1090.00,956.42 1090.00,956.42 1090.00,956.42 1011.00,901.28 1011.00,901.28 1011.00,901.28 989.00,885.86 989.00,885.86 986.64,884.17 980.56,879.39 978.00,879.00 974.52,878.46 972.43,881.69 966.00,883.00  963.82,879.12 955.04,867.77 955.82,864.04 956.38,861.36 960.95,857.89 963.00,856.00 963.00,856.00 902.00,836.00 902.00,836.00 Z',
        attr: {
          id: '_104',
          'data-name': '104',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "104", zone: 'Lower Zone', zoneId: "1" },
      };
      var _105 = {
        path:
        'M 960.00,898.00 C 963.24,895.84 973.78,888.05 977.00,887.92 980.40,887.78 986.20,892.59 989.00,894.58 989.00,894.58 1014.00,912.00 1014.00,912.00 1014.00,912.00 1088.00,963.72 1088.00,963.72 1088.00,963.72 1095.75,970.25 1095.75,970.25 1092.09,975.18 1078.36,989.73 1070.62,998.50 1070.62,998.50 1058.18,1009.91 1058.18,1009.91 1048.83,1017.99 1024.64,1036.82 1013.73,1040.91 1013.73,1040.91 999.69,1011.00 999.69,1011.00 999.69,1011.00 978.69,965.00 978.69,965.00 978.69,965.00 958.26,920.00 958.26,920.00 956.17,913.16 961.21,912.57 962.77,908.00 963.78,905.04 961.20,900.78 960.00,898.00 Z',
        attr: {
          id: '_105',
          'data-name': '105',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "105", zone: 'Lower Zone', zoneId: "1" },
      };
      var _106 = {
        path:
          'M 887.00,879.00 C 887.00,879.00 917.00,879.00 917.00,879.00 920.41,878.99 922.62,879.10 926.00,878.24 930.97,876.98 936.19,873.04 940.33,877.19 942.36,879.22 950.10,890.36 951.17,893.00 951.17,893.00 952.97,900.00 952.97,900.00 953.37,901.43 954.24,903.57 954.24,905.00 954.24,908.97 950.49,911.34 949.66,915.00 949.01,917.91 950.87,921.35 952.00,924.00 952.00,924.00 971.69,967.00 971.69,967.00 971.69,967.00 989.31,1006.00 989.31,1006.00 993.25,1014.60 1005.58,1039.42 1007.00,1047.00 1007.00,1047.00 989.00,1055.69 989.00,1055.69 972.82,1063.12 941.47,1074.97 924.00,1075.00 924.00,1075.00 889.00,1075.00 889.00,1075.00 889.00,1075.00 889.00,1040.00 889.00,1040.00 889.00,1040.00 888.00,1023.00 888.00,1023.00 888.00,1023.00 888.00,920.00 888.00,920.00 888.00,920.00 887.00,905.00 887.00,905.00 887.00,905.00 887.00,879.00 887.00,879.00 Z',
        attr: {
          id: '_106',
          'data-name': '106',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "106", zone: 'Lower Zone', zoneId: "1" },
      };
      var _107 = {
        path:
          'M 775.00,879.00 C 775.00,879.00 880.00,879.00 880.00,879.00 880.00,879.00 880.00,892.00 880.00,892.00 880.00,892.00 880.00,939.00 880.00,939.00 880.00,939.00 880.00,995.00 880.00,995.00 880.00,995.00 881.00,1012.00 881.00,1012.00 881.00,1012.00 881.00,1075.00 881.00,1075.00 881.00,1075.00 775.00,1075.00 775.00,1075.00 775.00,1075.00 775.00,879.00 775.00,879.00 Z',
        attr: {
          id: '_107',
          'data-name': '107',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "107", zone: 'Lower Zone', zoneId: "1" },
      };
      var _108 = {
        path:
          'M 668.00,879.00 C 668.00,879.00 767.00,879.00 767.00,879.00 767.00,879.00 767.00,1075.00 767.00,1075.00 767.00,1075.00 668.00,1075.00 668.00,1075.00 668.00,1075.00 668.00,879.00 668.00,879.00 Z',
        attr: {
          id: '_108',
          'data-name': '108',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "108", zone: 'Lower Zone', zoneId: "1" },
      };
      var _109 = {
        path:
          'M 550.00,879.00 C 550.00,879.00 660.00,879.00 660.00,879.00 660.00,879.00 660.00,1075.00 660.00,1075.00 660.00,1075.00 549.00,1075.00 549.00,1075.00 549.00,1075.00 549.00,1001.00 549.00,1001.00 549.00,1001.00 550.00,986.00 550.00,986.00 550.00,986.00 550.00,879.00 550.00,879.00 Z',
        attr: {
          id: '_109',
          'data-name': '109',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "109", zone: 'Lower Zone', zoneId: "1" },
      };
      var _110 = {
        path:
          'M 431.00,1053.00 C 432.34,1045.81 441.19,1028.63 444.69,1021.00 444.69,1021.00 470.78,964.00 470.78,964.00 470.78,964.00 485.68,932.00 485.68,932.00 488.27,923.61 473.75,922.74 476.33,914.00 477.80,909.01 492.60,890.26 496.65,885.00 498.22,882.96 503.50,875.83 505.30,874.81 508.77,872.83 515.34,877.18 519.00,878.21 523.49,879.48 536.61,879.00 542.00,879.00 542.00,879.00 542.00,1051.00 542.00,1051.00 542.00,1051.00 541.00,1066.00 541.00,1066.00 541.00,1066.00 541.00,1075.00 541.00,1075.00 541.00,1075.00 529.00,1075.00 529.00,1075.00 512.81,1074.97 479.03,1068.14 463.00,1063.57 463.00,1063.57 448.00,1058.95 448.00,1058.95 448.00,1058.95 431.00,1053.00 431.00,1053.00 Z',
        attr: {
          id: '_110',
          'data-name': '110',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "110", zone: 'Lower Zone', zoneId: "1" },
      };
      var _111 = {
        path:
          'M 360.00,944.00 C 362.24,947.15 369.68,958.53 373.09,959.13 376.47,959.72 386.88,951.13 390.00,948.85 390.00,948.85 434.00,916.85 434.00,916.85 434.00,916.85 449.00,905.88 449.00,905.88 451.04,904.40 454.35,901.70 457.00,902.01 460.70,902.46 465.90,909.07 468.00,912.00 460.32,923.43 479.82,923.75 476.59,934.00 476.59,934.00 462.31,965.00 462.31,965.00 462.31,965.00 436.69,1021.00 436.69,1021.00 436.69,1021.00 424.00,1049.00 424.00,1049.00 416.37,1046.82 402.96,1039.05 396.00,1034.68 378.07,1023.44 360.55,1010.12 346.73,994.00 346.73,994.00 332.88,977.00 332.88,977.00 331.46,975.05 328.69,971.61 329.01,969.09 329.39,966.16 334.72,962.85 337.00,961.12 337.00,961.12 360.00,944.00 360.00,944.00 Z',
        attr: {
          id: '_101',
          'data-name': '111',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "111", zone: 'Lower Zone', zoneId: "1" },
      };
      var _112 = {
        path:
          'M 291.00,874.00 C 291.00,874.00 340.00,862.88 340.00,862.88 340.00,862.88 413.00,846.12 413.00,846.12 413.00,846.12 459.00,835.46 459.00,835.46 459.00,835.46 489.00,832.91 489.00,832.91 489.00,832.91 531.00,829.00 531.00,829.00 531.00,829.00 536.00,849.00 536.00,849.00 536.00,849.00 496.00,859.13 496.00,859.13 496.00,859.13 480.01,863.73 480.01,863.73 480.01,863.73 451.91,894.70 451.91,894.70 451.91,894.70 410.00,925.42 410.00,925.42 410.00,925.42 374.00,951.00 374.00,951.00 371.80,947.90 364.27,936.42 360.91,935.90 357.95,935.44 352.37,940.34 350.00,942.13 343.19,947.28 329.70,958.55 323.00,962.00 311.71,944.65 303.00,923.88 297.29,904.00 294.89,895.65 291.39,882.48 291.00,874.00 Z',
        attr: {
          id: '_112',
          'data-name': '112',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "112", zone: 'Lower Zone', zoneId: "1" },
      };
      var _113 = {
        path:
          'M 288.00,765.00 C 288.00,765.00 423.00,765.00 423.00,765.00 423.00,765.00 438.00,764.00 438.00,764.00 438.00,764.00 529.00,764.00 529.00,764.00 529.00,764.00 529.00,822.00 529.00,822.00 529.00,822.00 495.00,824.83 495.00,824.83 495.00,824.83 457.00,828.43 457.00,828.43 457.00,828.43 432.00,834.12 432.00,834.12 432.00,834.12 392.00,843.35 392.00,843.35 392.00,843.35 327.00,858.35 327.00,858.35 327.00,858.35 290.00,867.00 290.00,867.00 287.59,858.51 288.00,839.45 288.00,830.00 288.00,830.00 288.00,765.00 288.00,765.00 Z',
        attr: {
          id: '_113',
          'data-name': '113',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "113", zone: 'Lower Zone', zoneId: "1" },
      };
      var _114 = {
        path:
          'M 287.76,758.00 C 287.76,758.00 287.76,688.00 287.76,688.00 287.76,688.00 289.42,663.00 289.42,663.00 289.71,660.93 290.02,656.29 291.74,655.03 293.90,653.44 301.39,655.97 304.00,656.63 304.00,656.63 336.00,664.35 336.00,664.35 336.00,664.35 418.00,683.88 418.00,683.88 418.00,683.88 458.00,693.54 458.00,693.54 458.00,693.54 466.00,694.28 466.00,694.28 466.00,694.28 509.00,699.07 509.00,699.07 512.01,699.35 525.68,700.63 527.40,702.02 529.38,703.62 529.00,707.67 529.00,710.00 529.00,710.00 529.00,756.00 529.00,756.00 529.00,756.00 476.00,756.00 476.00,756.00 476.00,756.00 459.00,757.00 459.00,757.00 459.00,757.00 313.00,757.00 313.00,757.00 313.00,757.00 298.00,758.00 298.00,758.00 298.00,758.00 287.76,758.00 287.76,758.00 Z',
        attr: {
          id: '_114',
          'data-name': '114',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "114", zone: 'Lower Zone', zoneId: "1" },
      };
      var _115 = {
        path:
          'M 331.00,551.00 C 331.00,551.00 378.00,587.56 378.00,587.56 384.97,591.74 386.56,585.49 390.17,584.16 392.57,583.28 394.22,584.71 396.00,586.11 396.00,586.11 408.00,596.27 408.00,596.27 408.00,596.27 442.00,625.72 442.00,625.72 444.56,627.95 452.34,635.72 455.00,636.10 458.05,636.54 462.64,632.05 468.00,630.00 468.00,630.00 482.00,651.00 482.00,651.00 482.00,651.00 472.00,662.00 472.00,662.00 472.00,662.00 508.00,666.73 508.00,666.73 508.00,666.73 536.00,671.00 536.00,671.00 536.00,671.00 530.00,694.00 530.00,694.00 530.00,694.00 458.00,685.74 458.00,685.74 458.00,685.74 429.00,678.88 429.00,678.88 429.00,678.88 379.00,666.88 379.00,666.88 379.00,666.88 329.00,654.88 329.00,654.88 329.00,654.88 292.00,646.00 292.00,646.00 295.27,628.61 301.96,606.04 309.31,590.00 309.31,590.00 319.43,570.00 319.43,570.00 319.43,570.00 331.00,551.00 331.00,551.00 Z',
        attr: {
          id: '_115',
          'data-name': '115',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "115", zone: 'Lower Zone', zoneId: "1" },
      };
      var _116 = {
        path:
        'M 425.00,474.00 C 425.00,474.00 439.58,508.00 439.58,508.00 439.58,508.00 465.30,567.00 465.30,567.00 465.30,567.00 474.58,588.00 474.58,588.00 475.55,590.24 477.74,594.56 477.19,596.91 476.41,600.28 471.65,602.14 470.81,606.00 469.96,609.90 474.26,613.08 472.19,616.68 470.52,619.58 458.34,627.06 455.00,626.27 452.39,625.65 445.41,619.08 443.00,617.00 443.00,617.00 410.00,588.58 410.00,588.58 407.14,586.14 396.49,576.61 394.00,575.49 387.20,572.43 385.88,582.60 379.00,579.24 379.00,579.24 368.00,570.80 368.00,570.80 368.00,570.80 347.00,553.80 347.00,553.80 344.54,551.83 337.55,547.31 337.90,543.91 338.19,541.06 346.07,532.51 348.28,530.00 358.31,518.61 373.63,505.20 386.00,496.29 394.76,489.99 414.96,475.88 425.00,474.00 Z',
        attr: {
          id: '_116',
          'data-name': '116',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "116", zone: 'Lower Zone', zoneId: "1" },
      };
      var _117 = {
        path:
          'M 431.00,470.00 C 442.11,463.70 457.77,458.41 470.00,454.33 483.12,449.96 503.34,444.02 517.00,444.00 517.00,444.00 543.00,444.00 543.00,444.00 543.00,444.00 543.00,613.00 543.00,613.00 543.00,613.00 542.00,628.00 542.00,628.00 542.00,628.00 542.00,636.00 542.00,636.00 542.00,636.00 521.00,635.01 521.00,635.01 512.69,635.21 504.55,638.80 497.00,642.00 497.00,642.00 483.57,622.00 483.57,622.00 481.79,618.54 482.33,617.14 481.31,614.00 481.31,614.00 479.38,609.04 479.38,609.04 478.81,605.10 483.39,603.25 485.13,600.27 486.57,597.80 485.49,595.44 484.55,593.00 484.55,593.00 471.86,564.00 471.86,564.00 471.86,564.00 446.58,506.00 446.58,506.00 446.58,506.00 431.00,470.00 431.00,470.00 Z',
        attr: {
          id: '_117',
          'data-name': '117',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "117", zone: 'Lower Zone', zoneId: "1" },
      };
      var _118 = {
        path:
         'M 551.00,444.00 C 551.00,444.00 649.00,444.00 649.00,444.00 649.00,444.00 649.00,636.00 649.00,636.00 649.00,636.00 550.00,636.00 550.00,636.00 550.00,636.00 550.00,563.00 550.00,563.00 550.00,563.00 551.00,548.00 551.00,548.00 551.00,548.00 551.00,444.00 551.00,444.00 Z',
        attr: {
          id: '_118',
          'data-name': '118',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "118", zone: 'Lower Zone', zoneId: "1" },
      };
      var _119 = {
        path:
          'M 657.00,444.00 C 657.00,444.00 764.00,444.00 764.00,444.00 764.00,444.00 764.00,636.00 764.00,636.00 764.00,636.00 657.00,636.00 657.00,636.00 657.00,636.00 657.00,444.00 657.00,444.00 Z',
        attr: {
          id: '_119',
          'data-name': '119',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "119", zone: 'Lower Zone', zoneId: "1" },
      };
      var _120 = {
        path:
          'M 772.00,444.00 C 772.00,444.00 880.00,444.00 880.00,444.00 880.00,444.00 880.00,635.00 880.00,635.00 880.00,635.00 851.00,635.00 851.00,635.00 851.00,635.00 834.00,636.00 834.00,636.00 834.00,636.00 772.00,636.00 772.00,636.00 772.00,636.00 772.00,444.00 772.00,444.00 Z',
        attr: {
          id: '_120',
          'data-name': '120',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "120", zone: 'Lower Zone', zoneId: "1" },
      };
      var _121 = {
        path:
          'M 887.00,444.00 C 887.00,444.00 898.00,444.00 898.00,444.00 898.00,444.00 917.00,445.85 917.00,445.85 944.76,449.16 968.56,455.19 995.00,464.00 995.00,464.00 978.25,499.00 978.25,499.00 978.25,499.00 949.75,557.00 949.75,557.00 949.75,557.00 936.02,586.00 936.02,586.00 934.14,593.16 943.26,593.55 942.16,600.00 941.87,601.71 939.20,606.17 938.26,608.00 938.26,608.00 929.26,625.00 929.26,625.00 927.96,627.51 925.92,632.51 923.61,633.98 921.66,635.22 918.27,635.00 916.00,635.00 916.00,635.00 888.00,635.00 888.00,635.00 888.00,635.00 888.00,507.00 888.00,507.00 888.00,507.00 888.00,467.00 888.00,467.00 888.00,467.00 887.00,444.00 887.00,444.00 Z',
        attr: {
          id: '_121',
          'data-name': '121',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "121", zone: 'Lower Zone', zoneId: "1" },
      };
      var _122 = {
        path:
          'M 1002.00,467.00 C 1019.58,474.03 1032.44,481.57 1047.00,493.75 1047.00,493.75 1061.00,507.00 1061.00,507.00 1062.60,508.59 1065.96,512.21 1068.00,512.89 1070.83,513.83 1073.06,511.63 1075.00,509.90 1077.88,507.33 1083.67,501.09 1087.00,499.64 1090.23,498.24 1092.38,499.94 1095.00,501.73 1100.45,505.43 1106.10,509.67 1110.00,515.00 1110.00,515.00 1067.00,550.75 1067.00,550.75 1063.93,553.32 1050.42,565.23 1048.00,566.21 1041.55,568.84 1036.53,556.15 1030.00,555.82 1026.94,555.67 1024.01,559.03 1022.00,561.00 1022.00,561.00 1007.00,577.00 1007.00,577.00 1007.00,577.00 975.00,611.00 975.00,611.00 972.49,613.53 966.79,621.20 962.96,619.83 959.77,618.70 949.53,609.42 949.23,606.00 948.96,602.80 952.46,599.91 950.87,596.00 948.88,591.09 942.96,590.53 944.98,584.00 944.98,584.00 962.75,547.00 962.75,547.00 962.75,547.00 986.26,499.00 986.26,499.00 986.26,499.00 1002.00,467.00 1002.00,467.00 Z',
        attr: {
          id: '_122',
          'data-name': '122',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "122", zone: 'Lower Zone', zoneId: "1" },
      };
      var _201 = {
        path:
          'M 1050.00,1026.00 C 1050.00,1026.00 1066.00,1012.83 1066.00,1012.83 1066.00,1012.83 1074.00,1007.32 1074.00,1007.32 1074.00,1007.32 1081.00,1011.00 1081.00,1011.00 1077.87,1017.59 1061.01,1031.63 1054.00,1034.00 1054.00,1034.00 1050.00,1026.00 1050.00,1026.00 Z',
        attr: {
          id: '_201',
          'data-name': '201',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "201", zone: 'Suite', zoneId: "2" },
      };
      var _202 = {
        path:
          'M 1028.00,1053.00 C 1028.00,1053.00 1025.81,1045.18 1025.81,1045.18 1025.81,1045.18 1031.00,1040.00 1031.00,1040.00 1031.00,1040.00 1044.00,1032.00 1044.00,1032.00 1044.00,1032.00 1046.99,1038.91 1046.99,1038.91 1046.99,1038.91 1042.00,1044.33 1042.00,1044.33 1042.00,1044.33 1028.00,1053.00 1028.00,1053.00 Z',
        attr: {
          id: '_202',
          'data-name': '202',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "202", zone: 'Suite', zoneId: "2" },
      };
      var _203 = {
        path:
          'M 995.00,1060.00 C 995.00,1060.00 1018.00,1049.00 1018.00,1049.00 1018.00,1049.00 1022.00,1057.00 1022.00,1057.00 1015.56,1061.19 1005.44,1067.60 998.00,1069.00 998.00,1069.00 995.00,1060.00 995.00,1060.00 Z',
        attr: {
          id: '_203',
          'data-name': '203',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "203", zone: 'Suite', zoneId: "2" },
      };
      var _204 = {
        path:
          'M 965.00,1072.00 C 965.00,1072.00 988.00,1064.00 988.00,1064.00 988.00,1064.00 990.22,1071.79 990.22,1071.79 990.22,1071.79 984.00,1076.40 984.00,1076.40 984.00,1076.40 968.00,1082.00 968.00,1082.00 968.00,1082.00 965.00,1072.00 965.00,1072.00 Z',
        attr: {
          id: '_204',
          'data-name': '204',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "204", zone: 'Suite', zoneId: "2" },
      };
      var _205 = {
        path:
          'M 936.02,1090.00 C 936.02,1090.00 936.02,1081.72 936.02,1081.72 936.02,1081.72 942.00,1079.13 942.00,1079.13 942.00,1079.13 958.00,1075.00 958.00,1075.00 958.00,1075.00 960.00,1085.00 960.00,1085.00 960.00,1085.00 936.02,1090.00 936.02,1090.00 Z',
        attr: {
          id: '_205',
          'data-name': '205',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "205", zone: 'Suite', zoneId: "2" },
      };
      var _206 = {
        path:
          'M 904.00,1082.00 C 904.00,1082.00 928.00,1082.00 928.00,1082.00 928.00,1082.00 929.00,1091.00 929.00,1091.00 929.00,1091.00 904.00,1091.00 904.00,1091.00 904.00,1091.00 904.00,1082.00 904.00,1082.00 Z',
        attr: {
          id: '_206',
          'data-name': '206',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "206", zone: 'Suite', zoneId: "2" },
      };
      var _207 = {
        path:
          'M 871.00,1082.00 C 871.00,1082.00 896.00,1082.00 896.00,1082.00 896.00,1082.00 897.00,1091.04 897.00,1091.04 897.00,1091.04 884.00,1091.04 884.00,1091.04 884.00,1091.04 871.00,1092.00 871.00,1092.00 871.00,1092.00 871.00,1082.00 871.00,1082.00 Z',
        attr: {
          id: '_207',
          'data-name': '207',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "207", zone: 'Suite', zoneId: "2" },
      };
      var _208 = {
        path:
          'M 839.00,1082.00 C 839.00,1082.00 864.00,1082.00 864.00,1082.00 864.00,1082.00 864.00,1092.00 864.00,1092.00 864.00,1092.00 846.00,1092.00 846.00,1092.00 846.00,1092.00 840.02,1090.40 840.02,1090.40 840.02,1090.40 839.00,1082.00 839.00,1082.00 Z',
        attr: {
          id: '_208',
          'data-name': '208',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "208", zone: 'Suite', zoneId: "2" },
      };
      var _209 = {
        path:
          'M 807.00,1082.00 C 807.00,1082.00 832.00,1082.00 832.00,1082.00 832.00,1082.00 832.00,1092.00 832.00,1092.00 832.00,1092.00 807.00,1092.00 807.00,1092.00 807.00,1092.00 807.00,1082.00 807.00,1082.00 Z',
        attr: {
          id: '_209',
          'data-name': '209',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "209", zone: 'Suite', zoneId: "2" },
      };
      var _210 = {
        path:
          'M 775.00,1082.00 C 775.00,1082.00 799.00,1082.00 799.00,1082.00 799.00,1082.00 799.00,1092.00 799.00,1092.00 799.00,1092.00 775.00,1092.00 775.00,1092.00 775.00,1092.00 775.00,1082.00 775.00,1082.00 Z',
        attr: {
          id: '_210',
          'data-name': '210',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "210", zone: 'Suite', zoneId: "2" },
      };
      var _211 = {
        path:
          'M 742.00,1082.00 C 742.00,1082.00 767.00,1082.00 767.00,1082.00 767.00,1082.00 767.00,1092.00 767.00,1092.00 767.00,1092.00 742.00,1092.00 742.00,1092.00 742.00,1092.00 742.00,1082.00 742.00,1082.00 Z',
        attr: {
          id: '_211',
          'data-name': '211',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "211", zone: 'Suite', zoneId: "2" },
      };
      var _212 = {
        path:
          'M 710.00,1082.00 C 710.00,1082.00 734.00,1082.00 734.00,1082.00 734.00,1082.00 734.00,1092.00 734.00,1092.00 734.00,1092.00 710.00,1092.00 710.00,1092.00 710.00,1092.00 710.00,1082.00 710.00,1082.00 Z',
        attr: {
          id: '_212',
          'data-name': '212',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "212", zone: 'Suite', zoneId: "2" },
      };
      var _213 = {
        path:
          'M 677.00,1082.00 C 677.00,1082.00 702.00,1082.00 702.00,1082.00 702.00,1082.00 702.00,1092.00 702.00,1092.00 702.00,1092.00 677.00,1092.00 677.00,1092.00 677.00,1092.00 677.00,1082.00 677.00,1082.00 Z',
        attr: {
          id: '_213',
          'data-name': '213',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "213", zone: 'Suite', zoneId: "2" },
      };
      var _214 = {
        path:
          'M 645.00,1082.00 C 645.00,1082.00 669.00,1082.00 669.00,1082.00 669.00,1082.00 669.00,1092.00 669.00,1092.00 669.00,1092.00 645.00,1092.00 645.00,1092.00 645.00,1092.00 645.00,1082.00 645.00,1082.00 Z',
        attr: {
          id: '_214',
          'data-name': '214',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "214", zone: 'Suite', zoneId: "2" },
      };
      var _215 = {
        path:
          'M 614.00,1082.00 C 614.00,1082.00 638.00,1082.00 638.00,1082.00 638.00,1082.00 637.00,1092.00 637.00,1092.00 637.00,1092.00 614.00,1091.00 614.00,1091.00 614.00,1091.00 614.00,1082.00 614.00,1082.00 Z',
        attr: {
          id: '_215',
          'data-name': '215',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "215", zone: 'Suite', zoneId: "2" },
      };
      var _216 = {
        path:
          'M 580.00,1082.00 C 580.00,1082.00 606.00,1082.00 606.00,1082.00 606.00,1082.00 606.00,1091.00 606.00,1091.00 606.00,1091.00 580.00,1091.00 580.00,1091.00 580.00,1091.00 580.00,1082.00 580.00,1082.00 Z',
        attr: {
          id: '_216',
          'data-name': '216',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "216", zone: 'Suite', zoneId: "2" },
      };
      var _217 = {
        path:
          'M 549.00,1082.00 C 549.00,1082.00 572.00,1082.00 572.00,1082.00 572.00,1082.00 572.00,1091.00 572.00,1091.00 572.00,1091.00 549.00,1091.00 549.00,1091.00 549.00,1091.00 549.00,1082.00 549.00,1082.00 Z',
        attr: {
          id: '_217',
          'data-name': '217',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "217", zone: 'Suite', zoneId: "2" },
      };
      var _218 = {
        path:
          'M 517.00,1081.00 C 517.00,1081.00 541.00,1082.00 541.00,1082.00 541.00,1082.00 541.00,1091.00 541.00,1091.00 541.00,1091.00 516.00,1091.00 516.00,1091.00 516.00,1091.00 517.00,1081.00 517.00,1081.00 Z',
        attr: {
          id: '_218',
          'data-name': '218',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "218", zone: 'Suite', zoneId: "2" },
      };
      var _219 = {
        path:
          'M 486.00,1076.00 C 486.00,1076.00 509.00,1080.00 509.00,1080.00 509.00,1082.29 509.48,1087.47 507.40,1088.89 505.31,1090.32 492.95,1087.97 490.00,1087.41 488.45,1087.11 486.06,1086.84 485.11,1085.41 483.86,1083.51 485.55,1078.25 486.00,1076.00 Z',
        attr: {
          id: '_219',
          'data-name': '219',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "219", zone: 'Suite', zoneId: "2" },
      };
      var _220 = {
        path:
          'M 455.00,1069.00 C 455.00,1069.00 478.00,1074.00 478.00,1074.00 478.00,1074.00 476.00,1085.00 476.00,1085.00 476.00,1085.00 460.00,1080.87 460.00,1080.87 460.00,1080.87 453.67,1077.57 453.67,1077.57 453.67,1077.57 455.00,1069.00 455.00,1069.00 Z',
        attr: {
          id: '_220',
          'data-name': '220',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "220", zone: 'Suite', zoneId: "2" },
      };
      var _221 = {
        path:
          'M 426.00,1058.00 C 426.00,1058.00 448.00,1066.00 448.00,1066.00 448.00,1066.00 445.00,1076.00 445.00,1076.00 445.00,1076.00 422.00,1068.00 422.00,1068.00 422.00,1068.00 426.00,1058.00 426.00,1058.00 Z',
        attr: {
          id: '_221',
          'data-name': '221',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "221", zone: 'Suite', zoneId: "2" },
      };
      var _222 = {
        path:
          'M 398.00,1045.00 C 398.00,1045.00 419.00,1054.00 419.00,1054.00 419.00,1054.00 415.00,1064.00 415.00,1064.00 408.12,1062.71 399.88,1057.83 394.00,1054.00 394.00,1054.00 398.00,1045.00 398.00,1045.00 Z',
        attr: {
          id: '_222',
          'data-name': '222',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "222", zone: 'Suite', zoneId: "2" },
      };
      var _223 = {
        path:
          'M 371.00,1027.00 C 371.00,1027.00 392.00,1040.00 392.00,1040.00 392.00,1040.00 387.00,1050.00 387.00,1050.00 387.00,1050.00 372.00,1040.57 372.00,1040.57 372.00,1040.57 367.11,1034.91 367.11,1034.91 367.11,1034.91 371.00,1027.00 371.00,1027.00 Z',
        attr: {
          id: '_223',
          'data-name': '223',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "223", zone: 'Suite', zoneId: "2" },
      };
      var _224 = {
        path:
          'M 340.00,1013.00 C 341.87,1011.16 345.25,1007.25 348.00,1007.18 351.35,1007.10 359.43,1015.25 361.94,1017.72 363.17,1018.93 364.75,1020.21 364.84,1022.09 364.91,1023.45 364.05,1024.89 363.26,1025.94 360.14,1030.08 357.70,1029.34 354.00,1026.51 354.00,1026.51 348.00,1021.16 348.00,1021.16 344.33,1017.88 343.01,1017.12 340.00,1013.00 Z',
        attr: {
          id: '_224',
          'data-name': '224',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "224", zone: 'Suite', zoneId: "2" },
      };
      var _225 = {
        path:
          'M 318.00,987.00 C 318.00,987.00 328.00,981.00 328.00,981.00 328.00,981.00 343.00,1000.00 343.00,1000.00 343.00,1000.00 335.00,1005.80 335.00,1005.80 335.00,1005.80 329.29,1001.98 329.29,1001.98 329.29,1001.98 318.00,987.00 318.00,987.00 Z',
        attr: {
          id: '_225',
          'data-name': '225',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "225", zone: 'Suite', zoneId: "2" },
      };
      var _226 = {
        path:
          'M 303.00,960.00 C 303.00,960.00 313.00,956.00 313.00,956.00 313.00,956.00 323.00,977.00 323.00,977.00 321.03,978.09 318.43,979.82 316.09,979.54 311.21,978.96 304.13,964.52 303.00,960.00 Z',
        attr: {
          id: '_226',
          'data-name': '226',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "226", zone: 'Suite', zoneId: "2" },
      };
      var _227 = {
        path:
          'M 290.00,932.00 C 290.00,932.00 299.00,928.00 299.00,928.00 299.00,928.00 308.00,950.00 308.00,950.00 308.00,950.00 299.00,954.00 299.00,954.00 299.00,954.00 290.00,932.00 290.00,932.00 Z',
        attr: {
          id: '_227',
          'data-name': '227',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "227", zone: 'Suite', zoneId: "2" },
      };
      var _228 = {
        path:
          'M 287.00,925.00 C 287.00,925.00 282.27,910.00 282.27,910.00 281.90,908.06 281.04,905.29 282.27,903.42 283.54,900.90 287.44,900.40 290.00,900.00 290.00,900.00 296.00,921.00 296.00,921.00 296.00,921.00 287.00,925.00 287.00,925.00 Z',
        attr: {
          id: '_228',
          'data-name': '228',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "228", zone: 'Suite', zoneId: "2" },
      };
      var _229 = {
        path:
          'M 274.00,870.00 C 274.00,870.00 284.00,868.00 284.00,868.00 284.00,868.00 288.00,893.00 288.00,893.00 288.00,893.00 278.00,896.00 278.00,896.00 278.00,896.00 274.00,870.00 274.00,870.00 Z',
        attr: {
          id: '_229',
          'data-name': '229',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "229", zone: 'Suite', zoneId: "2" },
      };
      var _230 = {
        path:
          'M 270.00,837.00 C 270.00,837.00 281.00,837.00 281.00,837.00 281.00,837.00 282.00,861.00 282.00,861.00 282.00,861.00 272.00,863.00 272.00,863.00 272.00,863.00 270.00,837.00 270.00,837.00 Z',
        attr: {
          id: '_230',
          'data-name': '230',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "230", zone: 'Suite', zoneId: "2" },
      };
      var _231 = {
        path:
          'M 270.00,808.00 C 270.00,808.00 281.00,807.00 281.00,807.00 281.00,807.00 281.00,829.00 281.00,829.00 281.00,829.00 270.00,829.00 270.00,829.00 270.00,829.00 270.00,808.00 270.00,808.00 Z',
        attr: {
          id: '_231',
          'data-name': '231',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "231", zone: 'Suite', zoneId: "2" },
      };
      var _232 = {
        path:
          'M 270.00,777.00 C 270.00,777.00 281.00,777.00 281.00,777.00 281.00,777.00 281.00,800.00 281.00,800.00 281.00,800.00 270.00,800.00 270.00,800.00 270.00,800.00 270.00,777.00 270.00,777.00 Z',
        attr: {
          id: '_232',
          'data-name': '232',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "232", zone: 'Suite', zoneId: "2" },
      };
      var _200 = {
        path:
          'M 1081.00,999.00 C 1081.00,999.00 1095.72,983.00 1095.72,983.00 1095.72,983.00 1103.00,976.91 1103.00,976.91 1103.00,976.91 1110.00,980.00 1110.00,980.00 1106.36,985.43 1091.93,1002.30 1087.00,1006.00 1087.00,1006.00 1081.00,999.00 1081.00,999.00 Z',
        attr: {
          id: '_200',
          'data-name': '200',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "200", zone: 'Suite', zoneId: "2" },
      };
      var _233 = {
        path:
          'M 269.00,767.00 C 269.00,767.00 269.00,745.00 269.00,745.00 269.00,745.00 270.74,737.99 270.74,737.99 270.74,737.99 281.00,737.00 281.00,737.00 281.00,737.00 281.00,767.00 281.00,767.00 281.00,767.00 269.00,767.00 269.00,767.00 Z',
        attr: {
          id: '_233',
          'data-name': '233',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "233", zone: 'Suite', zoneId: "2" },
      };
      var _234 = {
        path:
          'M 270.00,699.00 C 270.00,699.00 281.00,699.00 281.00,699.00 281.00,699.00 281.00,729.00 281.00,729.00 281.00,729.00 270.00,729.00 270.00,729.00 270.00,729.00 270.00,699.00 270.00,699.00 Z',
        attr: {
          id: '_234',
          'data-name': '234',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "234", zone: 'Suite', zoneId: "2" },
      };
      var _235 = {
        path:
          'M 271.00,666.00 C 271.00,666.00 282.00,667.00 282.00,667.00 282.00,667.00 281.04,676.00 281.04,676.00 281.04,676.00 281.04,691.00 281.04,691.00 281.04,691.00 270.04,691.00 270.04,691.00 270.04,691.00 270.04,677.00 270.04,677.00 270.04,677.00 271.00,666.00 271.00,666.00 Z',
        attr: {
          id: '_235',
          'data-name': '235',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "235", zone: 'Suite', zoneId: "2" },
      };
      var _236 = {
        path:
          'M 272.00,658.00 C 272.00,658.00 276.65,636.29 276.65,636.29 278.27,633.58 284.06,633.15 285.88,635.74 287.22,637.43 286.18,641.08 285.88,643.00 285.88,643.00 283.00,660.00 283.00,660.00 283.00,660.00 272.00,658.00 272.00,658.00 Z',
        attr: {
          id: '_236',
          'data-name': '236',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "236", zone: 'Suite', zoneId: "2" },
      };
      var _237 = {
        path:
          'paM 279.00,626.20 C 279.00,626.20 281.98,615.00 281.98,615.00 281.98,615.00 284.08,608.00 284.08,608.00 285.49,604.00 286.87,600.18 291.95,602.16 293.08,602.61 294.28,603.31 294.88,604.42 296.11,606.71 293.87,611.53 293.14,614.00 292.20,617.23 290.90,624.98 287.72,626.20 285.44,627.42 281.41,626.43 279.00,626.20 Zth',
        attr: {
          id: '_237',
          'data-name': '237',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "237", zone: 'Suite', zoneId: "2" },
      };
      var _238 = {
        path:
          'M 300.00,570.00 C 300.00,570.00 309.00,574.00 309.00,574.00 309.00,574.00 299.00,597.00 299.00,597.00 299.00,597.00 290.00,594.00 290.00,594.00 290.00,594.00 300.00,570.00 300.00,570.00 Z',
        attr: {
          id: '_238',
          'data-name': '238',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "238", zone: 'Suite', zoneId: "2" },
      };
      var _239 = {
        path:
          'M 317.00,542.00 C 317.00,542.00 326.00,547.00 326.00,547.00 326.00,547.00 313.00,568.00 313.00,568.00 313.00,568.00 304.00,563.00 304.00,563.00 304.00,563.00 317.00,542.00 317.00,542.00 Z',
        attr: {
          id: '_239',
          'data-name': '239',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "239", zone: 'Suite', zoneId: "2" },
      };
      var _240 = {
        path:
          'M 337.00,516.00 C 337.00,516.00 346.00,523.00 346.00,523.00 346.00,523.00 331.00,541.00 331.00,541.00 331.00,541.00 322.00,536.00 322.00,536.00 322.00,536.00 337.00,516.00 337.00,516.00 Z',
        attr: {
          id: '_240',
          'data-name': '240',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "240", zone: 'Suite', zoneId: "2" },
      };
      var _241 = {
        path:
          'M 361.00,493.00 C 371.16,500.95 366.54,503.41 359.00,510.17 357.04,511.92 353.75,515.70 351.00,515.66 348.59,515.63 341.67,512.06 348.06,505.00 348.06,505.00 353.00,500.28 353.00,500.28 353.00,500.28 361.00,493.00 361.00,493.00 Z',
        attr: {
          id: '_241',
          'data-name': '241',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "241", zone: 'Suite', zoneId: "2" },
      };
      var _242 = {
        path:
        'M 368.00,488.00 C 368.00,488.00 388.00,474.00 388.00,474.00 388.00,474.00 393.00,484.00 393.00,484.00 393.00,484.00 373.00,496.00 373.00,496.00 373.00,496.00 368.00,488.00 368.00,488.00 Z',
        attr: {
          id: '_242',
          'data-name': '242',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "242", zone: 'Suite', zoneId: "2" },
      };
      var _243 = {
        path:
          'M 400.00,479.00 C 398.47,476.89 395.32,472.95 395.87,470.18 396.33,467.88 399.14,466.37 401.00,465.31 405.80,462.56 410.67,459.52 416.00,458.00 416.00,458.00 420.00,468.00 420.00,468.00 420.00,468.00 400.00,479.00 400.00,479.00 Z',
        attr: {
          id: '_243',
          'data-name': '243',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "243", zone: 'Suite', zoneId: "2" },
      };
      var _244 = {
        path:
          'M 425.09,455.09 C 424.96,454.87 429.00,462.88 429.00,462.88 429.00,462.88 447.75,455.75 447.88,455.75 448.00,455.75 445.50,446.62 445.50,446.62 445.50,446.62 424.91,454.71 424.91,454.71 424.91,454.71 425.09,455.21 425.09,455.09 Z',
        attr: {
          id: '_244',
          'data-name': '244',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "244", zone: 'Suite', zoneId: "2" },
      };
      var _245 = {
        path:
          'M 455.00,452.00 C 455.00,452.00 453.69,443.27 453.69,443.27 453.69,443.27 460.00,439.33 460.00,439.33 460.00,439.33 476.19,435.00 476.19,435.00 476.19,435.00 476.19,443.58 476.19,443.58 470.00,447.14 455.00,452.00 455.00,452.00 Z',
        attr: {
          id: '_245',
          'data-name': '245',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "245", zone: 'Suite', zoneId: "2" },
      };
      var _246 = {
        path:
          'M 483.00,433.00 C 483.00,433.00 507.00,427.00 507.00,427.00 507.00,427.00 507.00,438.00 507.00,438.00 507.00,438.00 484.00,443.00 484.00,443.00 484.00,443.00 483.00,433.00 483.00,433.00 Z',
        attr: {
          id: '_246',
          'data-name': '246',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "246", zone: 'Suite', zoneId: "2" },
      };
      var _247 = {
        path:
          'M 515.00,426.00 C 515.00,426.00 541.00,426.00 541.00,426.00 541.00,426.00 541.00,437.00 541.00,437.00 541.00,437.00 515.00,437.00 515.00,437.00 515.00,437.00 515.00,426.00 515.00,426.00 Z',
        attr: {
          id: '_247',
          'data-name': '247',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "247", zone: 'Suite', zoneId: "2" },
      };
      var _248 = {
        path:
          'M 548.00,426.00 C 548.00,426.00 573.00,426.00 573.00,426.00 573.00,426.00 573.00,437.00 573.00,437.00 573.00,437.00 548.00,437.00 548.00,437.00 548.00,437.00 548.00,426.00 548.00,426.00 Zpath',
        attr: {
          id: '_248',
          'data-name': '248',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "248", zone: 'Suite', zoneId: "2" },
      };
      var _249 = {
        path:
          'M 581.00,426.00 C 581.00,426.00 605.00,426.00 605.00,426.00 605.00,426.00 605.00,437.00 605.00,437.00 605.00,437.00 581.00,437.00 581.00,437.00 581.00,437.00 581.00,426.00 581.00,426.00 Z',
        attr: {
          id: '_249',
          'data-name': '249',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "249", zone: 'Suite', zoneId: "2" },
      };
      var _250 = {
        path:
          'M 613.00,426.00 C 613.00,426.00 637.00,426.00 637.00,426.00 637.00,426.00 637.00,437.00 637.00,437.00 637.00,437.00 613.00,437.00 613.00,437.00 613.00,437.00 613.00,426.00 613.00,426.00 Z',
        attr: {
          id: '_250',
          'data-name': '250',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "250", zone: 'Suite', zoneId: "2" },
      };
      var _251 = {
        path:
          'M 645.00,426.00 C 645.00,426.00 669.00,426.00 669.00,426.00 669.00,426.00 669.00,437.00 669.00,437.00 669.00,437.00 645.00,437.00 645.00,437.00 645.00,437.00 645.00,426.00 645.00,426.00 Z',
        attr: {
          id: '_251',
          'data-name': '251',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "251", zone: 'Suite', zoneId: "2" },
      };
      var _252 = {
        path:
          'M 677.00,426.00 C 677.00,426.00 702.00,426.00 702.00,426.00 702.00,426.00 702.00,437.00 702.00,437.00 702.00,437.00 677.00,437.00 677.00,437.00 677.00,437.00 677.00,426.00 677.00,426.00 Z',
        attr: {
          id: '_252',
          'data-name': '252',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "252", zone: 'Suite', zoneId: "2" },
      };
      var _253 = {
        path:
          'M 710.00,426.00 C 710.00,426.00 734.00,426.00 734.00,426.00 734.00,426.00 734.00,437.00 734.00,437.00 734.00,437.00 710.00,437.00 710.00,437.00 710.00,437.00 710.00,426.00 710.00,426.00 Z',
        attr: {
          id: '_253',
          'data-name': '253',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "253", zone: 'Suite', zoneId: "2" },
      };
      
      var _254 = {
        path:
          'M 742.00,426.00 C 742.00,426.00 768.00,426.00 768.00,426.00 768.00,426.00 768.00,437.00 768.00,437.00 768.00,437.00 742.00,437.00 742.00,437.00 742.00,437.00 742.00,426.00 742.00,426.00 Z',
        attr: {
          id: '_254',
          'data-name': '254',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "254", zone: 'Suite', zoneId: "2" },
      };
      
      var _255 = {
        path:
          'M 775.00,437.00 C 775.00,437.00 776.02,427.60 776.02,427.60 776.02,427.60 784.00,426.00 784.00,426.00 784.00,426.00 800.00,426.00 800.00,426.00 800.00,426.00 800.00,437.00 800.00,437.00 800.00,437.00 775.00,437.00 775.00,437.00 Z',
        attr: {
          id: '_255',
          'data-name': '255',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "255", zone: 'Suite', zoneId: "2" },
      };
      
      var _256 = {
        path:
          'M 807.00,426.00 C 807.00,426.00 831.00,426.00 831.00,426.00 831.00,426.00 831.00,437.00 831.00,437.00 831.00,437.00 807.00,437.00 807.00,437.00 807.00,437.00 807.00,426.00 807.00,426.00 Z',
        attr: {
          id: '_256',
          'data-name': '256',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "256", zone: 'Suite', zoneId: "2" },
      };
      var _257 = {
        path:
          'M 839.00,426.00 C 839.00,426.00 864.00,426.00 864.00,426.00 864.00,426.00 864.00,437.00 864.00,437.00 864.00,437.00 839.00,437.00 839.00,437.00 839.00,437.00 839.00,426.00 839.00,426.00 Z',
        attr: {
          id: '_257',
          'data-name': '257',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "257", zone: 'Suite', zoneId: "2" },
      };
      var _258 = {
        path:
          'M 872.00,426.00 C 872.00,426.00 897.00,426.00 897.00,426.00 897.00,426.00 897.00,437.00 897.00,437.00 897.00,437.00 871.00,437.00 871.00,437.00 871.00,437.00 872.00,426.00 872.00,426.00 Z',
        attr: {
          id: '_258',
          'data-name': '258',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "258", zone: 'Suite', zoneId: "2" },
      };  
      var _259 = {
        path:
          'M 905.00,426.00 C 905.00,426.00 922.00,427.42 922.00,427.42 922.00,427.42 927.94,429.59 927.94,429.59 927.94,429.59 927.94,440.00 927.94,440.00 927.94,440.00 911.00,438.15 911.00,438.15 911.00,438.15 905.00,436.26 905.00,436.26 905.00,436.26 905.00,426.00 905.00,426.00 Z',
        attr: {
          id: '_259',
          'data-name': '259',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "259", zone: 'Suite', zoneId: "2" },
      };  
      var _260 = {
        path:
          'M 937.00,430.00 C 937.00,430.00 960.00,435.00 960.00,435.00 960.00,435.00 958.00,446.00 958.00,446.00 958.00,446.00 935.00,442.00 935.00,442.00 935.00,442.00 937.00,430.00 937.00,430.00 Z',
        attr: {
          id: '_260',
          'data-name': '260',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "260", zone: 'Suite', zoneId: "2" },
      };
      var _261 = {
        path:
          'M 965.00,448.00 C 965.00,448.00 968.51,439.11 968.51,439.11 968.51,439.11 976.00,439.71 976.00,439.71 976.00,439.71 993.00,445.00 993.00,445.00 993.00,445.00 989.00,455.00 989.00,455.00 989.00,455.00 965.00,448.00 965.00,448.00 Z',
        attr: {
          id: '_261',
          'data-name': '261',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "261", zone: 'Suite', zoneId: "2" },
      };
      var _262 = {
        path:
          'M 997.00,458.00 C 997.28,455.32 997.90,450.62 1000.50,449.11 1002.57,447.92 1005.94,449.31 1008.00,450.05 1008.00,450.05 1025.00,457.00 1025.00,457.00 1023.94,459.76 1021.88,464.59 1018.82,465.59 1016.76,466.26 1013.90,464.79 1012.00,464.00 1012.00,464.00 997.00,458.00 997.00,458.00 Z',
        attr: {
          id: '_262',
          'data-name': '262',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "262", zone: 'Suite', zoneId: "2" },
      };
      var _263 = {
        path:
          'M 1031.00,462.00 C 1038.75,463.64 1046.57,472.19 1053.00,477.00 1053.00,477.00 1048.00,485.00 1048.00,485.00 1048.00,485.00 1026.00,471.00 1026.00,471.00 1026.00,471.00 1031.00,462.00 1031.00,462.00 Z',
        attr: {
          id: '_263',
          'data-name': '263',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "263", zone: 'Suite', zoneId: "2" },
      }; 
      var _264 = {
        path:
          'M 1059.00,483.00 C 1059.00,483.00 1077.00,499.00 1077.00,499.00 1077.00,499.00 1069.00,506.00 1069.00,506.00 1069.00,506.00 1053.00,491.00 1053.00,491.00 1053.00,491.00 1059.00,483.00 1059.00,483.00 Z',
        attr: {
          id: '_264',
          'data-name': '264',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "264", zone: 'Suite', zoneId: "2" },
      };
      var _301 = {
        path:
          'M 1198.00,589.00 C 1198.00,589.00 1184.74,556.00 1184.74,556.00 1179.29,544.82 1167.97,527.98 1160.35,518.00 1155.12,511.16 1149.06,504.10 1143.00,498.00 1140.98,495.97 1136.29,491.93 1136.16,489.00 1136.01,485.59 1145.57,475.91 1148.16,473.00 1148.16,473.00 1183.72,433.00 1183.72,433.00 1183.72,433.00 1214.72,398.00 1214.72,398.00 1217.16,395.21 1222.91,386.67 1227.04,388.17 1229.13,388.92 1232.32,392.36 1234.00,393.96 1234.00,393.96 1246.96,406.00 1246.96,406.00 1250.97,410.19 1252.07,414.44 1258.00,413.00 1258.00,413.00 1244.77,438.00 1244.77,438.00 1244.77,438.00 1214.00,493.00 1214.00,493.00 1220.37,496.57 1225.32,504.98 1229.33,511.00 1229.33,511.00 1246.31,540.00 1246.31,540.00 1250.67,548.07 1255.59,556.07 1258.00,565.00 1258.00,565.00 1198.00,589.00 1198.00,589.00 Z',
        attr: {
          id: '_301',
          'data-name': '301',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "301", zone: 'Upper Zone', zoneId: "3" },
      };
      var _302 = {
        path:
          'M 1201.00,596.00 C 1201.00,596.00 1239.00,580.40 1239.00,580.40 1239.00,580.40 1262.00,572.00 1262.00,572.00 1262.00,572.00 1291.05,644.00 1291.05,644.00 1295.13,655.16 1301.98,670.44 1302.00,682.00 1302.00,682.00 1302.00,701.00 1302.00,701.00 1302.00,701.00 1260.00,701.00 1260.00,701.00 1260.00,701.00 1248.00,701.96 1248.00,701.96 1248.00,701.96 1233.98,701.96 1233.98,701.96 1233.98,701.96 1233.98,687.00 1233.98,687.00 1233.98,687.00 1233.98,678.00 1233.98,678.00 1233.98,678.00 1224.20,653.00 1224.20,653.00 1224.20,653.00 1201.00,596.00 1201.00,596.00 Z',
        attr: {
          id: '_302',
          'data-name': '302',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "302", zone: 'Upper Zone', zoneId: "3" },
      };
      var _303 = {
        path:
          'M 1234.00,709.00 C 1234.00,709.00 1302.00,709.00 1302.00,709.00 1302.00,709.00 1302.00,805.00 1302.00,805.00 1302.00,805.00 1235.00,805.00 1235.00,805.00 1235.00,805.00 1235.00,729.00 1235.00,729.00 1235.00,729.00 1234.00,709.00 1234.00,709.00 Z',
        attr: {
          id: '_303',
          'data-name': '303',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "303", zone: 'Upper Zone', zoneId: "3" },
      };
      var _304 = {
        path:
          'M 1234.91,813.00 C 1234.91,813.00 1302.00,813.00 1302.00,813.00 1302.00,813.00 1302.00,827.00 1302.00,827.00 1301.98,838.82 1296.71,850.86 1293.00,862.00 1293.00,862.00 1274.00,914.00 1274.00,914.00 1274.00,914.00 1262.00,944.00 1262.00,944.00 1262.00,944.00 1241.00,936.42 1241.00,936.42 1241.00,936.42 1201.00,921.00 1201.00,921.00 1201.00,921.00 1209.58,897.00 1209.58,897.00 1209.58,897.00 1224.42,857.00 1224.42,857.00 1224.42,857.00 1234.91,826.00 1234.91,826.00 1234.91,826.00 1234.91,813.00 1234.91,813.00 Z',
        attr: {
          id: '_304',
          'data-name': '304',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "304", zone: 'Upper Zone', zoneId: "3" },
      };
      var _305 = {
        path:
          'M 1197.00,928.00 C 1197.00,928.00 1221.00,936.58 1221.00,936.58 1221.00,936.58 1258.00,951.00 1258.00,951.00 1255.19,960.85 1236.94,995.22 1231.22,1006.00 1228.95,1010.27 1220.58,1023.07 1220.66,1027.00 1220.71,1029.81 1227.45,1044.35 1229.00,1048.00 1229.00,1048.00 1246.42,1088.00 1246.42,1088.00 1248.94,1093.78 1252.87,1100.96 1254.00,1107.00 1254.00,1107.00 1253.00,1107.00 1253.00,1107.00 1253.00,1107.00 1251.00,1105.00 1251.00,1105.00 1246.79,1110.87 1231.45,1125.09 1225.00,1128.00 1225.00,1128.00 1204.80,1103.00 1204.80,1103.00 1204.80,1103.00 1161.20,1049.00 1161.20,1049.00 1161.20,1049.00 1145.00,1029.00 1145.00,1029.00 1143.40,1027.01 1139.16,1022.49 1139.19,1020.01 1139.23,1017.25 1143.06,1014.56 1145.00,1012.92 1151.70,1007.25 1152.25,1006.41 1156.95,999.00 1172.86,973.93 1187.00,956.64 1197.00,928.00 Z',
        attr: {
          id: '_305',
          'data-name': '305',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "305", zone: 'Upper Zone', zoneId: "3" },
      };
      var _306 = {
        path:
          'M 1053.00,1076.00 C 1063.44,1073.05 1088.71,1060.58 1097.00,1053.71 1097.00,1053.71 1119.00,1035.00 1119.00,1035.00 1123.37,1031.23 1127.47,1026.88 1133.00,1025.00 1133.00,1025.00 1196.61,1104.00 1196.61,1104.00 1196.61,1104.00 1212.80,1124.00 1212.80,1124.00 1214.42,1126.00 1218.64,1130.47 1218.66,1132.96 1218.69,1135.72 1214.93,1138.23 1213.00,1139.80 1213.00,1139.80 1195.00,1154.40 1195.00,1154.40 1195.00,1154.40 1167.00,1175.91 1167.00,1175.91 1167.00,1175.91 1128.00,1195.42 1128.00,1195.42 1121.72,1198.16 1115.51,1202.14 1109.00,1204.00 1109.00,1204.00 1070.70,1118.00 1070.70,1118.00 1070.70,1118.00 1053.00,1076.00 1053.00,1076.00 Z',
        attr: {
          id: '_306',
          'data-name': '306',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "306", zone: 'Upper Zone', zoneId: "3" },
      };
      var _307 = {
        path:
          'M 942.00,1097.00 C 966.38,1097.00 989.24,1095.06 1013.00,1089.13 1013.00,1089.13 1035.00,1082.81 1035.00,1082.81 1037.39,1082.04 1042.29,1079.82 1044.61,1080.67 1047.10,1081.58 1049.12,1087.58 1050.23,1090.00 1050.23,1090.00 1062.30,1117.00 1062.30,1117.00 1062.30,1117.00 1111.00,1226.00 1111.00,1226.00 1103.33,1229.97 1094.17,1232.78 1086.00,1235.69 1066.05,1242.79 1045.69,1248.83 1025.00,1253.42 1013.66,1255.94 999.44,1259.98 988.00,1260.00 988.00,1260.00 941.00,1260.00 941.00,1260.00 942.80,1249.93 942.00,1231.93 942.00,1221.00 942.00,1221.00 942.00,1153.00 942.00,1153.00 942.00,1153.00 942.00,1127.00 942.00,1127.00 942.02,1116.91 944.46,1107.12 942.00,1097.00 Z',
        attr: {
          id: '_307',
          'data-name': '307',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "307", zone: 'Upper Zone', zoneId: "3" },
      };
      var _308 = {
        path:
          'M 810.00,1099.00 C 810.00,1099.00 871.00,1099.00 871.00,1099.00 871.00,1099.00 888.00,1098.00 888.00,1098.00 888.00,1098.00 935.00,1098.00 935.00,1098.00 935.00,1098.00 935.00,1148.00 935.00,1148.00 935.00,1148.00 934.00,1165.00 934.00,1165.00 934.00,1165.00 934.00,1260.00 934.00,1260.00 934.00,1260.00 808.00,1260.00 808.00,1260.00 808.00,1260.00 809.00,1241.00 809.00,1241.00 809.00,1241.00 809.00,1148.00 809.00,1148.00 809.00,1148.00 810.00,1133.00 810.00,1133.00 810.00,1133.00 810.00,1099.00 810.00,1099.00 Z',
        attr: {
          id: '_308',
          'data-name': '308',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "308", zone: 'Upper Zone', zoneId: "3" },
      };
      var _309 = {
        path:
          'M 678.00,1099.00 C 678.00,1099.00 802.00,1099.00 802.00,1099.00 802.00,1099.00 802.00,1158.00 802.00,1158.00 802.00,1158.00 801.00,1175.00 801.00,1175.00 801.00,1175.00 801.00,1260.00 801.00,1260.00 801.00,1260.00 678.00,1260.00 678.00,1260.00 678.00,1260.00 678.00,1099.00 678.00,1099.00 Z',
        attr: {
          id: '_309',
          'data-name': '309',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "309", zone: 'Upper Zone', zoneId: "3" },
      };
      var _310 = {
        path:
          'M 545.00,1098.00 C 545.00,1098.00 610.00,1098.00 610.00,1098.00 610.00,1098.00 627.00,1099.00 627.00,1099.00 627.00,1099.00 671.00,1099.00 671.00,1099.00 669.04,1109.92 670.00,1141.97 670.00,1155.00 670.00,1155.00 670.00,1260.00 670.00,1260.00 670.00,1260.00 545.00,1260.00 545.00,1260.00 545.00,1260.00 545.00,1098.00 545.00,1098.00 Z',
        attr: {
          id: '_310',
          'data-name': '310',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "310", zone: 'Upper Zone', zoneId: "3" },
      };
      var _311 = {
        path:
          'M 425.00,1077.00 C 425.00,1077.00 442.00,1082.98 442.00,1082.98 475.37,1093.36 502.05,1098.00 537.00,1098.00 537.00,1098.00 537.00,1260.00 537.00,1260.00 537.00,1260.00 516.00,1260.00 516.00,1260.00 516.00,1260.00 502.00,1259.00 502.00,1259.00 474.06,1258.68 436.02,1248.83 409.00,1240.72 395.27,1236.60 370.47,1228.80 358.00,1223.00 358.00,1223.00 376.69,1182.00 376.69,1182.00 376.69,1182.00 411.31,1107.00 411.31,1107.00 411.31,1107.00 425.00,1077.00 425.00,1077.00 Z',
        attr: {
          id: '_311',
          'data-name': '311',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "311", zone: 'Upper Zone', zoneId: "3" },
      };
      var _312 = {
        path:
          'M 195.00,1101.00 C 195.00,1101.00 230.00,1075.58 230.00,1075.58 230.00,1075.58 291.00,1031.86 291.00,1031.86 291.00,1031.86 315.00,1014.58 315.00,1014.58 317.29,1012.94 322.19,1008.88 325.00,1009.10 327.79,1009.33 331.08,1013.10 333.00,1015.00 333.00,1015.00 351.00,1032.57 351.00,1032.57 372.58,1051.05 391.91,1062.94 418.00,1074.00 418.00,1074.00 397.31,1120.00 397.31,1120.00 397.31,1120.00 368.69,1182.00 368.69,1182.00 368.69,1182.00 351.00,1220.00 351.00,1220.00 351.00,1220.00 331.00,1211.31 331.00,1211.31 296.01,1195.24 263.27,1174.29 235.00,1148.09 220.87,1134.99 204.48,1117.88 195.00,1101.00 Z',
        attr: {
          id: '_312',
          'data-name': '312',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },transform: "",
        data: { zoneNo: "312", zone: 'Upper Zone', zoneId: "3" },
      };
      var _313 = {
        path:
          'M 242.00,924.00 C 242.00,924.00 274.00,910.00 274.00,910.00 278.55,930.14 290.53,956.32 301.20,974.00 301.20,974.00 313.37,992.00 313.37,992.00 314.85,993.98 318.71,998.51 318.68,1000.91 318.64,1003.81 314.13,1006.48 312.00,1008.00 312.00,1008.00 289.00,1024.58 289.00,1024.58 289.00,1024.58 223.00,1071.86 223.00,1071.86 215.91,1076.94 196.91,1091.71 190.00,1094.00 186.49,1087.90 177.81,1074.79 178.48,1068.00 178.48,1068.00 183.92,1038.00 183.92,1038.00 184.65,1033.57 186.32,1019.25 188.59,1016.33 190.88,1013.40 204.87,1006.35 209.00,1004.00 209.00,1004.00 244.00,984.70 244.00,984.70 249.58,981.63 257.13,976.71 263.00,975.00 263.00,975.00 242.00,924.00 242.00,924.00 Z',
        attr: {
          id: '_313',
          'data-name': '313',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "313", zone: 'Upper Zone', zoneId: "3" },
      };
      var _314 = {
        path:
          'M 214.00,812.00 C 214.00,812.00 246.00,812.00 246.00,812.00 246.00,812.00 246.00,822.00 246.00,822.00 246.05,830.86 250.27,838.98 253.72,847.00 253.72,847.00 263.85,872.00 263.85,872.00 267.57,880.75 267.48,884.12 269.42,893.00 269.92,895.25 271.25,899.49 270.39,901.61 269.56,903.63 266.84,904.82 265.00,905.74 262.45,907.01 248.97,913.37 247.13,913.30 245.31,913.23 244.59,912.28 243.68,910.85 243.68,910.85 232.72,885.00 232.72,885.00 232.72,885.00 220.58,856.00 220.58,856.00 217.20,847.96 214.05,842.94 214.00,834.00 214.00,834.00 214.00,812.00 214.00,812.00 Z',
        attr: {
          id: '_314',
          'data-name': '314',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "314", zone: 'Upper Zone', zoneId: "3" },
      };
      var _315 = {
        path:
          'M 213.00,711.00 C 213.00,711.00 247.00,711.00 247.00,711.00 247.00,711.00 247.00,739.00 247.00,739.00 247.00,739.00 246.00,756.00 246.00,756.00 246.00,756.00 246.00,804.00 246.00,804.00 246.00,804.00 213.00,804.00 213.00,804.00 213.00,804.00 213.00,711.00 213.00,711.00 Z',
        attr: {
          id: '_315',
          'data-name': '315',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "315", zone: 'Upper Zone', zoneId: "3" },
      };
      var _316 = {
        path:
          'M 247.00,602.0 C 252.67,603.62 261.45,608.43 267.00,611.25 269.15,612.34 272.50,613.74 273.30,616.21 274.04,618.49 272.44,621.87 271.60,624.00 271.60,624.00 264.60,642.00 264.60,642.00 264.60,642.00 253.40,671.00 253.40,671.00 250.58,678.12 247.10,685.24 247.00,693.00 247.00,693.00 247.00,703.00 247.00,703.00 247.00,703.00 213.00,703.00 213.00,703.00 213.00,703.00 213.00,686.00 213.00,686.00 213.02,675.06 218.79,665.84 223.14,656.00 223.14,656.00 247.00,602.00 247.00,602.00 Z',
        attr: {
          id: '_316',
          'data-name': '316',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "316", zone: 'Upper Zone', zoneId: "3" },
      };
      var _317 = {
        path:
          'M 197.00,420.00 C 197.00,420.00 241.00,454.00 241.00,454.00 241.00,454.00 294.00,495.42 294.00,495.42 294.00,495.42 323.00,518.00 323.00,518.00 321.38,522.89 317.18,528.46 314.32,533.00 314.32,533.00 300.42,556.00 300.42,556.00 289.41,574.98 284.90,588.14 277.00,608.00 277.00,608.00 243.00,591.00 243.00,591.00 243.00,591.00 265.00,543.00 265.00,543.00 258.23,540.50 251.09,535.06 245.00,531.00 245.00,531.00 215.00,511.33 215.00,511.33 211.14,508.76 196.90,500.30 194.95,497.48 193.07,494.76 190.95,479.22 190.25,475.00 189.13,468.31 184.49,446.12 185.33,441.00 186.41,434.43 192.93,425.27 197.00,420.00 Z',
        attr: {
          id: '_317',
          'data-name': '317',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "317", zone: 'Upper Zone', zoneId: "3" },
      };
      var _318 = {
        path:
          'M 418.00,442.00 C 392.25,454.15 369.65,469.39 349.01,489.09 349.01,489.09 333.75,506.00 333.75,506.00 332.15,507.88 329.71,511.38 326.91,511.10 324.80,510.89 320.77,507.48 319.00,506.12 319.00,506.12 300.00,491.21 300.00,491.21 300.00,491.21 230.00,436.40 230.00,436.40 230.00,436.40 210.00,420.65 210.00,420.65 207.77,418.92 203.33,416.11 203.33,412.99 203.34,410.15 208.86,404.28 210.75,402.00 221.42,389.20 222.06,389.19 234.00,378.09 260.24,353.69 288.42,330.95 320.00,313.77 327.81,309.52 345.01,299.50 353.00,298.00 353.00,298.00 373.69,344.00 373.69,344.00 373.69,344.00 418.00,442.00 418.00,442.00 Z',
        attr: {
          id: '_318',
          'data-name': '318',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "318", zone: 'Upper Zone', zoneId: "3" },
      };
      var _319 = {
        path:
          'M 360.00,294.00 C 391.23,280.76 423.81,269.95 457.00,262.79 468.23,260.37 484.92,256.02 496.00,256.00 496.00,256.00 538.00,256.00 538.00,256.00 538.00,256.00 538.00,367.00 538.00,367.00 538.00,367.00 538.00,393.00 538.00,393.00 538.00,393.00 538.00,417.00 538.00,417.00 538.00,417.00 520.00,417.00 520.00,417.00 520.00,417.00 502.00,418.72 502.00,418.72 473.21,422.47 451.94,428.22 425.00,439.00 425.00,439.00 410.69,408.00 410.69,408.00 410.69,408.00 382.14,345.00 382.14,345.00 382.14,345.00 367.14,312.00 367.14,312.00 367.14,312.00 360.00,294.00 360.00,294.00 Z',
        attr: {
          id: '_319',
          'data-name': '319',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "319", zone: 'Upper Zone', zoneId: "3" },
      };
      var _320 = {
        path:
          'M 546.00,256.00 C 546.00,256.00 565.00,256.00 565.00,256.00 565.00,256.00 581.00,257.00 581.00,257.00 581.00,257.00 671.00,257.00 671.00,257.00 671.00,257.00 671.00,417.00 671.00,417.00 671.00,417.00 545.00,417.00 545.00,417.00 545.00,417.00 545.00,354.00 545.00,354.00 545.00,354.00 546.00,337.00 546.00,337.00 546.00,337.00 546.00,256.00 546.00,256.00 Z',
        attr: {
          id: '_320',
          'data-name': '320',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "320", zone: 'Upper Zone', zoneId: "3" },
      };
      var _321 = {
        path:
          'M 678.00,257.00 C 678.00,257.00 802.00,257.00 802.00,257.00 802.00,257.00 802.00,284.00 802.00,284.00 802.00,284.00 803.00,301.00 803.00,301.00 803.00,301.00 803.00,417.00 803.00,417.00 803.00,417.00 679.00,417.00 679.00,417.00 679.00,417.00 679.00,319.00 679.00,319.00 679.00,319.00 678.00,304.00 678.00,304.00 678.00,304.00 678.00,257.00 678.00,257.00 Z',
        attr: {
          id: '_321',
          'data-name': '321',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "321", zone: 'Upper Zone', zoneId: "3" },
      };
      var _322 = {
        path:
          'M 810.00,257.00 C 810.00,257.00 847.00,257.00 847.00,257.00 847.00,257.00 864.00,258.00 864.00,258.00 864.00,258.00 933.00,258.00 933.00,258.00 933.00,258.00 933.00,417.00 933.00,417.00 933.00,417.00 811.00,417.00 811.00,417.00 811.00,417.00 811.00,330.00 811.00,330.00 811.00,330.00 810.00,315.00 810.00,315.00 810.00,315.00 810.00,257.00 810.00,257.00 Z',
        attr: {
          id: '_322',
          'data-name': '322',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "322", zone: 'Upper Zone', zoneId: "3" },
      };
      var _323 = {
        path:
          'M 941.00,258.00 C 941.00,258.00 984.00,258.00 984.00,258.00 996.22,258.02 1016.61,262.12 1029.00,264.60 1051.45,269.09 1090.70,279.56 1111.00,289.00 1111.00,289.00 1099.31,314.00 1099.31,314.00 1099.31,314.00 1064.69,389.00 1064.69,389.00 1064.69,389.00 1045.00,432.00 1045.00,432.00 1045.00,432.00 1022.00,425.42 1022.00,425.42 1022.00,425.42 996.00,419.61 996.00,419.61 976.88,416.16 960.22,417.00 941.00,417.00 941.00,417.00 941.00,258.00 941.00,258.00 Z',
        attr: {
          id: '_323',
          'data-name': '323',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "323", zone: 'Upper Zone', zoneId: "3" },
      };
      var _324 = {
        path:
          'M 1110.00,309.00 C 1110.00,309.00 1123.00,315.74 1123.00,315.74 1123.00,315.74 1146.00,327.69 1146.00,327.69 1146.00,327.69 1170.00,340.22 1170.00,340.22 1170.00,340.22 1179.00,344.96 1179.00,344.96 1179.00,344.96 1196.00,359.28 1196.00,359.28 1196.00,359.28 1213.98,375.28 1213.98,375.28 1216.15,377.29 1218.95,379.72 1217.83,383.00 1217.22,384.80 1213.80,388.41 1212.42,390.00 1212.42,390.00 1199.15,405.00 1199.15,405.00 1199.15,405.00 1151.28,459.00 1151.28,459.00 1151.28,459.00 1136.28,476.00 1136.28,476.00 1134.51,478.01 1131.89,481.71 1129.00,481.84 1126.59,481.95 1123.73,479.32 1122.00,477.87 1122.00,477.87 1107.00,466.00 1107.00,466.00 1093.93,456.68 1067.08,440.27 1052.00,436.00 1052.00,436.00 1060.22,416.00 1060.22,416.00 1060.22,416.00 1072.69,389.00 1072.69,389.00 1072.69,389.00 1110.00,309.00 1110.00,309.00 Z',
        attr: {
          id: '_324',
          'data-name': '324',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "324", zone: 'Upper Zone', zoneId: "3" },
      };
      var _GBOX1 = {
        path:
          'M 472.00,224.00 C 472.00,224.00 520.00,224.00 520.00,224.00 534.04,224.00 535.87,222.56 536.00,232.00 536.00,232.00 536.00,247.00 536.00,247.00 536.00,247.00 472.00,247.00 472.00,247.00 472.00,247.00 472.00,224.00 472.00,224.00 Z',
        attr: {
          id: '_GBOX1',
          'data-name': 'GBOX1',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "1", zone: 'GBOX', zoneId: "3" },
      };
      var _GBOX2 = {
        path:
          'M 543.00,224.00 C 543.00,224.00 623.00,224.00 623.00,224.00 623.00,224.00 623.00,247.00 623.00,247.00 623.00,247.00 543.00,247.00 543.00,247.00 543.00,247.00 543.00,224.00 543.00,224.00 Z',
        attr: {
          id: '_GBOX2',
          'data-name': 'GBOX2',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "2", zone: 'GBOX', zoneId: "3" },
      };
      var _GBOX3 = {
        path:
          'M 631.00,224.00 C 631.00,224.00 727.00,224.00 727.00,224.00 727.00,224.00 727.00,247.00 727.00,247.00 727.00,247.00 631.00,247.00 631.00,247.00 631.00,247.00 631.00,224.00 631.00,224.00 Z',
        attr: {
          id: '_GBOX3',
          'data-name': 'GBOX3',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "3", zone: 'GBOX', zoneId: "3" },
      };
      var _GBOX4 = {
        path:
          'M 734.00,247.00 C 734.00,247.00 734.00,233.00 734.00,233.00 734.00,233.00 735.74,224.99 735.74,224.99 735.74,224.99 743.00,224.00 743.00,224.00 743.00,224.00 829.00,224.00 829.00,224.00 829.00,224.00 829.00,247.00 829.00,247.00 829.00,247.00 734.00,247.00 734.00,247.00 Z',
        attr: {
          id: '_GBOX4',
          'data-name': 'GBOX4',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "4", zone: 'GBOX', zoneId: "3" },
      };
      var _GBOX5 = {
        path:
          'M 837.00,224.00 C 837.00,224.00 916.00,224.00 916.00,224.00 916.00,224.00 916.00,247.00 916.00,247.00 916.00,247.00 837.00,247.00 837.00,247.00 837.00,247.00 837.00,224.00 837.00,224.00 Z',
        attr: {
          id: '_GBOX5',
          'data-name': 'GBOX5',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "5", zone: 'GBOX', zoneId: "3" },
      };
      var _GBOX6 = {
        path:
          'M 924.00,224.00 C 924.00,224.00 987.00,224.00 987.00,224.00 987.00,224.00 987.00,247.00 987.00,247.00 987.00,247.00 924.00,247.00 924.00,247.00 924.00,247.00 924.00,224.00 924.00,224.00 Z',
        attr: {
          id: '_GBOX6',
          'data-name': 'GBOX6',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "6", zone: 'GBOX', zoneId: "3" },
      };
      var _MOLSON = {
        path:
          'M 223.00,927.00 C 223.00,927.00 214.60,906.00 214.60,906.00 214.60,906.00 198.58,867.00 198.58,867.00 194.13,856.41 188.02,845.66 188.00,834.00 188.00,834.00 188.00,766.00 188.00,766.00 188.00,766.00 189.00,749.00 189.00,749.00 189.00,749.00 189.00,678.00 189.00,678.00 189.02,667.51 192.51,663.38 196.58,654.00 196.58,654.00 215.00,612.00 215.00,612.00 215.00,612.00 221.70,597.00 221.70,597.00 222.51,595.22 223.63,592.07 225.41,591.13 228.59,589.44 235.22,594.33 238.00,596.00 238.00,596.00 213.77,651.00 213.77,651.00 213.77,651.00 203.13,675.00 203.13,675.00 202.93,677.12 203.00,679.81 203.13,682.00 203.13,682.00 203.13,831.00 203.13,831.00 203.02,841.27 207.04,848.61 210.80,858.00 210.80,858.00 227.60,900.00 227.60,900.00 229.09,903.73 235.46,917.44 234.33,920.62 233.08,924.16 226.33,926.37 223.00,927.00 Z',
        attr: {
          id: '_MOLSON',
          'data-name': 'MOLSON',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Molson', zoneId: "3" },
      };
      var _601A = {
        path:
          'M 1118.00,292.00 C 1126.98,294.54 1144.15,303.33 1153.00,307.75 1184.80,323.65 1217.89,346.89 1243.00,372.00 1243.00,372.00 1261.42,391.70 1261.42,391.70 1265.99,396.13 1267.40,394.70 1271.00,400.00 1271.00,400.00 1266.00,399.00 1266.00,399.00 1266.00,399.00 1261.00,410.00 1261.00,410.00 1254.57,406.95 1239.92,390.79 1234.00,385.01 1234.00,385.01 1185.00,341.32 1185.00,341.32 1179.48,336.83 1169.62,332.71 1163.00,329.22 1163.00,329.22 1131.00,312.26 1131.00,312.26 1131.00,312.26 1113.00,303.00 1113.00,303.00 1113.00,303.00 1118.00,292.00 1118.00,292.00 Z',
        attr: {
          id: '_601A',
          'data-name': '601A',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "601", zone: 'Suite A', zoneId: "3" },
      };
      var _613A = {
        path:
          'M 1254.00,1111.00 C 1254.00,1111.00 1257.00,1111.00 1257.00,1111.00 1257.96,1113.74 1260.66,1119.45 1260.28,1122.00 1259.80,1125.23 1255.56,1129.50 1253.42,1132.00 1246.87,1139.65 1239.54,1146.51 1232.00,1153.15 1207.13,1175.05 1179.62,1193.44 1150.00,1208.25 1141.66,1212.42 1126.31,1220.65 1118.00,1223.00 1118.00,1223.00 1113.00,1210.00 1113.00,1210.00 1113.00,1210.00 1141.00,1196.26 1141.00,1196.26 1150.36,1191.73 1160.22,1187.95 1169.00,1182.30 1169.00,1182.30 1217.00,1144.75 1217.00,1144.75 1224.86,1138.20 1248.94,1117.91 1254.00,1111.00 Z',
        attr: {
          id: '_613A',
          'data-name': '613A',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "613", zone: 'Suite A', zoneId: "3" },
      };
      var _COURTSIDEEAST = {
        path:
          'M 870.00,690.00 C 870.00,690.00 896.00,690.00 896.00,690.00 896.00,690.00 896.00,824.00 896.00,824.00 896.00,824.00 870.00,824.00 870.00,824.00 870.00,824.00 870.00,690.00 870.00,690.00 Z',
        attr: {
          id: '_COURTSIDEEAST',
          'data-name': 'COURTSIDEEAST',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Courtside East', zoneId: "1" },
      };
      var _COURTSIDENORTH = {
        path:
          'M 575.00,643.00 C 575.00,643.00 692.00,643.00 692.00,643.00 692.00,643.00 707.00,644.00 707.00,644.00 707.00,644.00 866.00,644.00 866.00,644.00 866.00,644.00 866.00,676.00 866.00,676.00 866.00,676.00 575.00,676.00 575.00,676.00 575.00,676.00 575.00,643.00 575.00,643.00 Z',
        attr: {
          id: '_COURTSIDENORTH',
          'data-name': 'COURTSIDENORTH',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Courtside North', zoneId: "1" },
      };
      var _COURTSIDESOUTH = {
        path:
          'M 569.00,836.00 C 569.00,836.00 602.00,836.00 602.00,836.00 602.00,836.00 619.00,837.00 619.00,837.00 619.00,837.00 871.00,837.00 871.00,837.00 871.00,837.00 871.00,864.00 871.00,864.00 871.00,864.00 643.00,864.00 643.00,864.00 643.00,864.00 588.00,864.00 588.00,864.00 584.52,864.00 572.01,864.92 570.02,862.40 568.77,860.81 569.00,856.99 569.00,855.00 569.00,855.00 569.00,836.00 569.00,836.00 Z',
        attr: {
          id: '_COURTSIDESOUTH',
          'data-name': 'COURTSIDESOUTH',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Courtside South', zoneId: "1" },
      };
      var _COURTSIDEWEST = {
        path:
          'M 542.00,690.00 C 542.00,690.00 562.00,690.00 562.00,690.00 562.00,690.00 567.98,691.60 567.98,691.60 567.98,691.60 569.00,699.00 569.00,699.00 569.00,699.00 569.00,823.00 569.00,823.00 569.00,823.00 559.00,823.96 559.00,823.96 559.00,823.96 543.00,823.96 543.00,823.96 543.00,823.96 543.00,748.00 543.00,748.00 543.00,748.00 542.00,733.00 542.00,733.00 542.00,733.00 542.00,690.00 542.00,690.00 Z',
        attr: {
          id: '_COURTSIDEWEST',
          'data-name': 'COURTSIDEWEST',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Courtside West', zoneId: "1" },
      };
      var _401 = {
        path:
          'M 138.00,952.00 C 138.00,952.00 170.00,944.00 170.00,944.00 170.85,954.15 177.33,973.42 181.43,983.00 183.59,988.07 186.48,992.67 188.00,998.00 188.00,998.00 158.00,1013.00 158.00,1013.00 158.00,1013.00 146.34,985.00 146.34,985.00 146.34,985.00 138.00,952.00 138.00,952.00 Z',
        attr: {
          id: '_401',
          'data-name': '401',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "401", zone: 'Suite', zoneId: "3" },
      };
      var _402 = {
        path:
          'M 134.00,893.00 C 134.00,893.00 165.00,893.00 165.00,893.00 165.00,893.00 166.17,922.00 166.17,922.00 166.17,922.00 168.00,937.00 168.00,937.00 168.00,937.00 137.00,944.00 137.00,944.00 134.75,926.92 134.00,910.22 134.00,893.00 Z',
        attr: {
          id: '_402',
          'data-name': '402',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "402", zone: 'Suite', zoneId: "3" },
      };
      var _403 = {
        path:
          'M 134.00,826.00 C 134.00,826.00 165.00,826.00 165.00,826.00 165.00,826.00 165.00,886.00 165.00,886.00 165.00,886.00 134.00,886.00 134.00,886.00 134.00,886.00 134.00,826.00 134.00,826.00 Z',
        attr: {
          id: '_403',
          'data-name': '403',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "403", zone: 'Suite', zoneId: "3" },
      };
      var _404 = {
        path:
          'M 134.00,758.00 C 134.00,758.00 165.00,758.00 165.00,758.00 165.00,758.00 165.00,819.00 165.00,819.00 165.00,819.00 134.00,819.00 134.00,819.00 134.00,819.00 134.00,758.00 134.00,758.00 Z',
        attr: {
          id: '_404',
          'data-name': '404',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "404", zone: 'Suite', zoneId: "3" },
      };
      var _405 = {
        path:
          'M 134.00,690.00 C 134.00,690.00 165.00,690.00 165.00,690.00 165.00,690.00 165.00,751.00 165.00,751.00 165.00,751.00 134.00,751.00 134.00,751.00 134.00,751.00 134.00,690.00 134.00,690.00 Z',
        attr: {
          id: '_405',
          'data-name': '405',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "405", zone: 'Suite', zoneId: "3" },
      };
      var _406 = {
        path:
          'M 135.00,623.00 C 135.00,623.00 165.00,623.00 165.00,623.00 165.00,623.00 165.00,683.00 165.00,683.00 165.00,683.00 134.00,683.00 134.00,683.00 134.00,683.00 134.00,654.00 134.00,654.00 134.00,654.00 135.00,639.00 135.00,639.00 135.00,639.00 135.00,623.00 135.00,623.00 Z',
        attr: {
          id: '_406',
          'data-name': '406',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "406", zone: 'Suite', zoneId: "3" },
      };
      var _407 = {
        path:
          'M 135.00,616.00 C 135.00,616.00 136.73,579.00 136.73,579.00 136.73,579.00 139.60,567.06 139.60,567.06 139.60,567.06 148.00,567.63 148.00,567.63 148.00,567.63 170.00,573.00 170.00,573.00 167.32,587.24 165.00,601.48 165.00,616.00 165.00,616.00 135.00,616.00 135.00,616.00 Z',
        attr: {
          id: '_407',
          'data-name': '407',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "407", zone: 'Suite', zoneId: "3" },
      };
      var _408 = {
        path:
          'M 160.00,501.00 C 160.00,501.00 189.00,517.00 189.00,517.00 182.33,532.51 175.36,548.36 172.00,565.00 172.00,565.00 140.00,558.00 140.00,558.00 145.20,538.37 149.84,518.85 160.00,501.00 Z',
        attr: {
          id: '_408',
          'data-name': '408',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "408", zone: 'Suite', zoneId: "3" },
      };
      var _501 = {
        path:
          'M 1248.00,453.00 C 1254.25,457.58 1259.05,464.73 1263.58,471.00 1270.46,480.53 1278.29,491.79 1284.00,502.00 1284.00,502.00 1254.00,516.00 1254.00,516.00 1254.00,516.00 1238.71,493.00 1238.71,493.00 1238.71,493.00 1232.69,482.00 1232.69,482.00 1232.69,482.00 1236.85,473.00 1236.85,473.00 1236.85,473.00 1248.00,453.00 1248.00,453.00 Z',
        attr: {
          id: '_501',
          'data-name': '501',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "501", zone: 'Suite', zoneId: "3" },
      };
      var _502 = {
        path:
          'M 1259.00,522.00 C 1259.00,522.00 1288.00,509.00 1288.00,509.00 1293.19,516.98 1307.39,543.39 1309.00,552.00 1309.00,552.00 1288.00,557.71 1288.00,557.71 1285.79,558.34 1281.37,559.90 1279.21,559.23 1276.26,558.32 1274.08,552.65 1272.75,550.00 1269.59,543.68 1260.24,527.23 1259.00,522.00 Z',
        attr: {
          id: '_502',
          'data-name': '502',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "502", zone: 'Suite', zoneId: "3" },
      };
      var _503 = {
        path:
          'M 1282.00,567.00 C 1282.00,567.00 1303.00,561.14 1303.00,561.14 1305.09,560.54 1309.62,558.94 1311.61,559.67 1314.57,560.74 1316.37,567.22 1317.42,570.00 1320.61,578.45 1327.24,594.58 1328.00,603.00 1328.00,603.00 1307.00,606.20 1307.00,606.20 1304.45,606.35 1299.57,607.42 1297.27,606.20 1294.32,605.08 1292.98,598.93 1292.00,596.00 1289.47,588.40 1282.72,573.76 1282.00,567.00 Z',
        attr: {
          id: '_503',
          'data-name': '503',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "503", zone: 'Suite', zoneId: "3" },
      };
      var _504 = {
        path:
          'M 1298.00,615.00 C 1298.00,615.00 1331.00,610.00 1331.00,610.00 1334.58,625.86 1339.68,641.71 1341.00,658.00 1341.00,658.00 1308.00,659.00 1308.00,659.00 1308.00,659.00 1298.00,615.00 1298.00,615.00 Z',
        attr: {
          id: '_504',
          'data-name': '504',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "504", zone: 'Suite', zoneId: "3" },
      };
      var _505 = {
        path:
          'M 1309.00,665.96 C 1309.00,665.96 1324.00,665.96 1324.00,665.96 1324.00,665.96 1333.00,665.01 1333.00,665.01 1335.42,664.96 1340.35,664.56 1341.98,666.60 1343.61,668.64 1343.88,678.99 1344.17,682.00 1344.17,682.00 1346.00,704.00 1346.00,704.00 1346.00,704.00 1346.00,724.00 1346.00,724.00 1346.00,724.00 1314.00,724.00 1314.00,724.00 1314.00,724.00 1313.09,712.00 1313.09,712.00 1313.09,712.00 1309.00,665.96 1309.00,665.96 Z',
        attr: {
          id: '_505',
          'data-name': '505',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "504", zone: 'Suite', zoneId: "3" },
      };
      var _506 = {
        path:
          'M 1314.00,731.00 C 1314.00,731.00 1346.00,731.00 1346.00,731.00 1346.00,731.00 1346.00,784.00 1346.00,784.00 1346.00,784.00 1313.00,784.00 1313.00,784.00 1313.00,784.00 1313.00,761.00 1313.00,761.00 1313.00,761.00 1314.00,746.00 1314.00,746.00 1314.00,746.00 1314.00,731.00 1314.00,731.00 Z',
        attr: {
          id: '_506',
          'data-name': '506',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "506", zone: 'Suite', zoneId: "3" },
      };
      var _507 = {
        path:
          'M 1313.00,791.00 C 1313.00,791.00 1346.00,791.00 1346.00,791.00 1346.00,791.00 1346.00,815.00 1346.00,815.00 1346.00,815.00 1343.00,851.00 1343.00,851.00 1343.00,851.00 1310.00,848.00 1310.00,848.00 1313.49,828.52 1313.00,810.61 1313.00,791.00 Z',
        attr: {
          id: '_507',
          'data-name': '507',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "507", zone: 'Suite', zoneId: "3" },
      };
      var _508 = {
        path:
          'M 1309.00,855.00 C 1309.00,855.00 1341.00,858.00 1341.00,858.00 1341.00,858.00 1337.40,881.00 1337.40,881.00 1337.40,881.00 1331.00,906.00 1331.00,906.00 1331.00,906.00 1300.00,899.00 1300.00,899.00 1302.93,888.88 1308.17,865.17 1309.00,855.00 Z',
        attr: {
          id: '_508',
          'data-name': '508',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "508", zone: 'Suite', zoneId: "3" },
      };
      var _509 = {
        path:
          'M 1298.00,906.00 C 1298.00,906.00 1329.00,913.00 1329.00,913.00 1327.46,923.95 1318.37,947.07 1314.00,958.00 1314.00,958.00 1284.00,948.00 1284.00,948.00 1284.00,948.00 1288.95,934.00 1288.95,934.00 1288.95,934.00 1298.00,906.00 1298.00,906.00 Z',
        attr: {
          id: '_509',
          'data-name': '509',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "509", zone: 'Suite', zoneId: "3" },
      };
      var _510 = {
        path:
          'M 1280.00,955.00 C 1280.00,955.00 1311.00,965.00 1311.00,965.00 1311.00,965.00 1290.00,1008.00 1290.00,1008.00 1290.00,1008.00 1262.00,992.00 1262.00,992.00 1262.00,992.00 1280.00,955.00 1280.00,955.00 Z',
        attr: {
          id: '_510',
          'data-name': '510',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "510", zone: 'Suite', zoneId: "3" },
      };
      var _511 = {
        path:
          'M 1254.00,1060.00 C 1250.45,1055.76 1244.00,1043.40 1241.24,1038.00 1239.99,1035.55 1237.54,1031.75 1238.00,1029.00 1238.46,1026.16 1246.50,1016.17 1248.66,1013.00 1250.58,1010.17 1255.76,1000.45 1259.10,1000.29 1261.15,1000.20 1266.07,1003.32 1268.00,1004.42 1268.00,1004.42 1286.00,1014.00 1286.00,1014.00 1276.80,1030.45 1265.11,1044.89 1254.00,1060.00 Z',
        attr: {
          id: '_511',
          'data-name': '511',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "511", zone: 'Suite', zoneId: "3" },
      };
      var _512 = {
        path:
          'M 130.00,953.00 C 130.00,953.00 138.72,986.00 138.72,986.00 138.72,986.00 151.00,1017.00 151.00,1017.00 151.00,1017.00 126.00,1030.00 126.00,1030.00 117.23,1014.59 110.61,995.38 107.00,978.00 106.31,974.67 103.33,963.12 105.06,960.56 106.26,958.78 110.00,958.13 112.00,957.63 112.00,957.63 130.00,953.00 130.00,953.00 Z',
        attr: {
          id: '_512',
          'data-name': '512',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "512", zone: 'Suite', zoneId: "3" },
      };
      var _513 = {
        path:
          'M 100.00,893.00 C 100.00,893.00 127.00,893.00 127.00,893.00 127.00,910.41 126.93,928.85 130.00,946.00 130.00,946.00 103.00,952.00 103.00,952.00 99.33,939.21 100.00,907.48 100.00,893.00 Z',
        attr: {
          id: '_513',
          'data-name': '513',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "513", zone: 'Suite', zoneId: "3" },
      };
      var _514 = {
        path:
          'M 100.00,826.00 C 100.00,826.00 127.00,826.00 127.00,826.00 127.00,826.00 127.00,886.00 127.00,886.00 127.00,886.00 100.00,886.00 100.00,886.00 100.00,886.00 100.00,826.00 100.00,826.00 Z',
        attr: {
          id: '_514',
          'data-name': '514',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "514", zone: 'Suite', zoneId: "3" },
      };
      var _515 = {
        path:
          'M 100.00,758.00 C 100.00,758.00 127.00,758.00 127.00,758.00 127.00,758.00 127.00,819.00 127.00,819.00 127.00,819.00 100.00,819.00 100.00,819.00 100.00,819.00 100.00,758.00 100.00,758.00 Z',
        attr: {
          id: '_515',
          'data-name': '515',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "515", zone: 'Suite', zoneId: "3" },
      };
      var _516 = {
        path:
          'M 100.00,690.00 C 100.00,690.00 127.00,690.00 127.00,690.00 127.00,690.00 127.00,751.00 127.00,751.00 127.00,751.00 100.00,751.00 100.00,751.00 100.00,751.00 100.00,690.00 100.00,690.00 Z',
        attr: {
          id: '_516',
          'data-name': '516',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "516", zone: 'Suite', zoneId: "3" },
      };
      var _517 = {
        path:
          'M 100.00,623.00 C 100.00,623.00 127.96,623.00 127.96,623.00 127.96,623.00 127.96,639.00 127.96,639.00 127.96,639.00 127.00,651.00 127.00,651.00 127.00,651.00 127.00,683.00 127.00,683.00 127.00,683.00 100.00,683.00 100.00,683.00 100.00,683.00 100.00,623.00 100.00,623.00 Z',
        attr: {
          id: '_517',
          'data-name': '517',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "517", zone: 'Suite', zoneId: "3" },
      };
      var _518 = {
        path:
          'M 100.00,616.00 C 100.00,616.00 100.00,591.00 100.00,591.00 100.00,591.00 100.97,579.00 100.97,579.00 101.15,575.67 102.23,562.70 103.56,560.32 104.99,557.75 107.57,558.39 110.00,558.81 110.00,558.81 125.00,562.21 125.00,562.21 127.07,562.71 129.42,563.13 130.39,565.30 131.45,567.69 129.37,577.76 129.07,581.00 129.07,581.00 128.00,616.00 128.00,616.00 128.00,616.00 100.00,616.00 100.00,616.00 Z',
        attr: {
          id: '_518',
          'data-name': '518',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "518", zone: 'Suite', zoneId: "3" },
      };
      var _519 = {
        path:
          'M 127.00,482.00 C 127.00,482.00 153.00,496.00 153.00,496.00 153.00,496.00 143.81,518.00 143.81,518.00 139.36,529.49 135.28,543.89 133.00,556.00 133.00,556.00 105.00,550.00 105.00,550.00 108.65,531.82 113.84,515.30 120.42,498.00 120.42,498.00 127.00,482.00 127.00,482.00 Z',
        attr: {
          id: '_519',
          'data-name': '519',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "519", zone: 'Suite', zoneId: "3" },
      };
      var _601 = {
        path:
          'M 1269.00,417.00 C 1275.93,420.28 1280.65,427.17 1285.39,433.00 1292.42,441.66 1310.36,464.49 1314.00,474.00 1314.00,474.00 1285.00,489.00 1285.00,489.00 1285.00,489.00 1263.00,458.00 1263.00,458.00 1263.00,458.00 1254.06,445.00 1254.06,445.00 1254.06,445.00 1258.15,436.00 1258.15,436.00 1258.15,436.00 1269.00,417.00 1269.00,417.00 Z',
        attr: {
          id: '_601',
          'data-name': '601',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "601", zone: 'Suite', zoneId: "3" },
      };
      var _602 = {
        path:
          'M 1290.00,496.00 C 1290.00,496.00 1309.00,485.58 1309.00,485.58 1311.20,484.34 1315.35,481.56 1317.90,481.99 1321.11,482.52 1323.78,488.36 1325.28,491.00 1325.28,491.00 1340.00,519.00 1340.00,519.00 1340.00,519.00 1308.00,530.00 1308.00,530.00 1308.00,530.00 1290.00,496.00 1290.00,496.00 Z',
        attr: {
          id: '_602',
          'data-name': '602',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "602", zone: 'Suite', zoneId: "3" },
      };
      var _603 = {
        path:
          'M 1312.00,537.00 C 1312.00,537.00 1344.00,526.00 1344.00,526.00 1347.84,539.46 1356.82,551.91 1359.00,566.00 1359.00,566.00 1326.00,572.00 1326.00,572.00 1326.00,572.00 1312.00,537.00 1312.00,537.00 Z',
        attr: {
          id: '_603',
          'data-name': '603',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "603", zone: 'Suite', zoneId: "3" },
      };
      var _604 = {
        path:
          'M 1329.00,579.00 C 1329.00,579.00 1351.00,575.00 1351.00,575.00 1353.16,574.57 1358.85,573.13 1360.72,573.77 1363.65,574.75 1364.79,581.22 1365.66,584.00 1368.23,592.23 1373.63,610.99 1374.00,619.00 1374.00,619.00 1341.00,621.00 1341.00,621.00 1340.24,611.91 1332.22,588.67 1329.00,579.00 Z',
        attr: {
          id: '_604',
          'data-name': '604',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "604", zone: 'Suite', zoneId: "3" },
      };
      var _605 = {
        path:
          'M 1343.00,628.00 C 1343.00,628.00 1376.00,626.00 1376.00,626.00 1378.30,638.62 1381.89,662.63 1382.00,675.00 1382.00,675.00 1351.00,675.00 1351.00,675.00 1350.18,664.84 1345.76,637.55 1343.00,628.00 Z',
        attr: {
          id: '_605',
          'data-name': '605',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "605", zone: 'Suite', zoneId: "3" },
      };
      var _606 = {
        path:
          'M 1352.00,682.00 C 1352.00,682.00 1382.00,682.00 1382.00,682.00 1382.00,682.00 1383.00,727.00 1383.00,727.00 1383.00,727.00 1353.00,727.00 1353.00,727.00 1353.00,727.00 1353.00,702.00 1353.00,702.00 1353.00,702.00 1352.00,682.00 1352.00,682.00 Z',
        attr: {
          id: '_606',
          'data-name': '606',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "606", zone: 'Suite', zoneId: "3" },
      };
      var _607 = {
        path:
          'M 1353.00,734.00 C 1353.00,734.00 1383.00,734.00 1383.00,734.00 1383.00,734.00 1384.00,747.00 1384.00,747.00 1384.00,747.00 1384.00,782.00 1384.00,782.00 1384.00,782.00 1353.00,782.00 1353.00,782.00 1353.00,782.00 1353.00,734.00 1353.00,734.00 Z',
        attr: {
          id: '_607',
          'data-name': '607',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "607", zone: 'Suite', zoneId: "3" },
      };
      var _608 = {
        path:
          'M 1353.00,789.00 C 1353.00,789.00 1384.00,789.00 1384.00,789.00 1384.00,789.00 1384.00,814.00 1384.00,814.00 1384.00,814.00 1383.00,834.00 1383.00,834.00 1383.00,834.00 1352.00,834.00 1352.00,834.00 1352.00,834.00 1353.00,819.00 1353.00,819.00 1353.00,819.00 1353.00,789.00 1353.00,789.00 Z',
        attr: {
          id: '_608',
          'data-name': '608',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "608", zone: 'Suite', zoneId: "3" },
      };
      var _609 = {
        path:
          'M 1351.00,841.00 C 1351.00,841.00 1382.00,841.00 1382.00,841.00 1382.00,853.63 1379.08,877.77 1376.00,890.00 1376.00,890.00 1361.00,889.00 1361.00,889.00 1361.00,889.00 1344.00,888.00 1344.00,888.00 1344.00,888.00 1347.20,870.00 1347.20,870.00 1347.20,870.00 1351.00,841.00 1351.00,841.00 Z',
        attr: {
          id: '_609',
          'data-name': '609',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "609", zone: 'Suite', zoneId: "3" },
      };
      var _610 = {
        path:
          'M 1342.00,895.00 C 1342.00,895.00 1375.00,897.00 1375.00,897.00 1375.00,897.00 1370.11,918.00 1370.11,918.00 1370.11,918.00 1363.00,943.00 1363.00,943.00 1363.00,943.00 1330.00,937.00 1330.00,937.00 1330.00,937.00 1342.00,895.00 1342.00,895.00 Z',
        attr: {
          id: '_610',
          'data-name': '610',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "610", zone: 'Suite', zoneId: "3" },
      };
      var _611 = {
        path:
          'M 1314.00,978.00 C 1314.00,978.00 1323.80,954.00 1323.80,954.00 1323.80,954.00 1328.50,945.27 1328.50,945.27 1328.50,945.27 1337.00,945.27 1337.00,945.27 1337.00,945.27 1360.00,950.00 1360.00,950.00 1358.64,958.82 1349.11,981.28 1345.00,990.00 1345.00,990.00 1314.00,978.00 1314.00,978.00 Z',
        attr: {
          id: '_611',
          'data-name': '611',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "611", zone: 'Suite', zoneId: "3" },
      };
      var _612 = {
        path:
          'M 1310.00,985.00 C 1310.00,985.00 1341.00,997.00 1341.00,997.00 1341.00,997.00 1322.00,1035.00 1322.00,1035.00 1322.00,1035.00 1292.00,1020.00 1292.00,1020.00 1292.00,1020.00 1310.00,985.00 1310.00,985.00 Z',
        attr: {
          id: '_612',
          'data-name': '612',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "612", zone: 'Suite', zoneId: "3" },
      };
      var _613 = {
        path:
          'M 1287.00,1027.00 C 1287.00,1027.00 1317.00,1041.00 1317.00,1041.00 1317.00,1041.00 1307.33,1057.00 1307.33,1057.00 1307.33,1057.00 1286.55,1085.00 1286.55,1085.00 1286.55,1085.00 1276.00,1098.00 1276.00,1098.00 1276.00,1098.00 1274.00,1098.00 1274.00,1098.00 1274.00,1098.00 1263.00,1078.00 1263.00,1078.00 1261.64,1075.61 1258.64,1070.69 1258.70,1068.00 1258.78,1064.41 1264.87,1057.94 1267.20,1055.00 1267.20,1055.00 1287.00,1027.00 1287.00,1027.00 Z',
        attr: {
          id: '_613',
          'data-name': '613',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "613", zone: 'Suite', zoneId: "3" },
      };
      var _614 = {
        path:
          'M 63.00,969.00 C 63.00,969.00 97.00,962.00 97.00,962.00 97.69,977.09 105.66,1003.82 111.45,1018.00 111.45,1018.00 119.00,1034.00 119.00,1034.00 119.00,1034.00 97.00,1045.86 97.00,1045.86 94.77,1047.09 90.81,1049.84 88.21,1049.19 84.42,1048.24 79.56,1034.76 78.01,1031.00 70.19,1011.98 65.69,989.37 63.00,969.00 Z',
        attr: {
          id: '_614',
          'data-name': '614',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "614", zone: 'Suite', zoneId: "3" },
      };
      var _615 = {
        path:
          'M 61.00,893.00 C 61.00,893.00 93.00,893.00 93.00,893.00 93.00,893.00 93.00,926.00 93.00,926.00 93.00,926.00 95.00,954.00 95.00,954.00 95.00,954.00 62.00,962.00 62.00,962.00 62.00,962.00 61.00,942.00 61.00,942.00 61.00,942.00 61.00,893.00 61.00,893.00 Z',
        attr: {
          id: '_615',
          'data-name': '615',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "615", zone: 'Suite', zoneId: "3" },
      };
      var _616 = {
        path:
          'M 61.00,826.00 C 61.00,826.00 93.00,826.00 93.00,826.00 93.00,826.00 93.00,886.00 93.00,886.00 93.00,886.00 61.00,886.00 61.00,886.00 61.00,886.00 61.00,826.00 61.00,826.00 Z',
        attr: {
          id: '_616',
          'data-name': '616',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "616", zone: 'Suite', zoneId: "3" },
      };
      var _617 = {
        path:
          'M 60.00,757.00 C 60.00,757.00 93.00,758.00 93.00,758.00 93.00,758.00 93.00,819.00 93.00,819.00 93.00,819.00 61.00,819.00 61.00,819.00 61.00,819.00 60.00,757.00 60.00,757.00 Z',
        attr: {
          id: '_617',
          'data-name': '617',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "617", zone: 'Suite', zoneId: "3" },
      };
      var _618 = {
        path:
          'M 60.00,690.00 C 60.00,690.00 93.00,690.00 93.00,690.00 93.00,690.00 93.00,751.00 93.00,751.00 93.00,751.00 60.00,752.00 60.00,752.00 60.00,752.00 60.00,690.00 60.00,690.00 Z',
        attr: {
          id: '_618',
          'data-name': '618',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "618", zone: 'Suite', zoneId: "3" },
      };
      var _619 = {
        path:
          'M 60.00,623.00 C 60.00,623.00 93.00,623.00 93.00,623.00 93.00,623.00 93.00,683.00 93.00,683.00 93.00,683.00 60.00,683.00 60.00,683.00 60.00,683.00 60.00,623.00 60.00,623.00 Z',
        attr: {
          id: '_619',
          'data-name': '619',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "619", zone: 'Suite', zoneId: "3" },
      };
      var _620 = {
        path:
          'M 63.00,548.00 C 63.00,548.00 96.00,555.00 96.00,555.00 96.00,555.00 93.00,589.00 93.00,589.00 93.00,589.00 93.00,616.00 93.00,616.00 93.00,616.00 60.00,616.00 60.00,616.00 60.00,599.07 59.31,563.16 63.00,548.00 Z',
        attr: {
          id: '_620',
          'data-name': '620',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "620", zone: 'Suite', zoneId: "3" },
      };
      var _621 = {
        path:
          'M 89.00,461.00 C 89.00,461.00 121.00,478.00 121.00,478.00 111.91,497.01 100.92,527.25 98.00,548.00 98.00,548.00 64.00,541.00 64.00,541.00 64.33,533.86 67.32,521.21 69.13,514.00 73.45,496.72 80.20,476.47 89.00,461.00 Z',
        attr: {
          id: '_621',
          'data-name': '621',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "621", zone: 'Suite', zoneId: "3" },
      };
      
      
    zones.push(
       _101,
       _102,
       _103,
       _104,
       _105,
       _106,
       _107,
       _108,
       _109,
       _110,
       _111,
       _112,
       _113,
       _114,
       _115,
       _116,
       _117,
       _118,
       _119,
       _120,
       _121,
       _122,
       _201,
       _202,
       _203,
       _204,
       _205,
       _206,
       _207,
       _208,
       _209,
       _210,
       _211,
       _212,
       _213,
       _214,
       _215,
       _216,
       _217,
       _218,
       _219,
       _220,
       _221,
       _222,
       _223,
       _224,
       _225,
       _226,
       _228,
       _229,
       _230,
       _232,
       _233,
       _234,
       _235,
       _236,
       _237,
       _238,
       _239,
       _240,
       _241,
       _242,
       _243,
       _244,
       _245,
       _246,
       _247,
       _248,
       _249,
       _250,
       _251,
       _252,
       _253,
       _254,
       _255,
       _256,
       _257,
       _258,
       _259,
       _260,
       _261,
       _262,
       _263,
       _264,
       _301,
       _302,
       _303,
       _304,
       _305,
       _306,
       _307,
       _308,
       _309,
       _310,
       _311,
       _312,
       _313,
       _314,
       _315,
       _316,
       _317,
       _318,
       _319,
       _320,
       _321,
       _322,
       _323,
       _324,
       _601A,
       _613A,
       _COURTSIDEEAST,
       _COURTSIDENORTH,
       _COURTSIDESOUTH,
       _COURTSIDEWEST,
       _GBOX1,
       _GBOX2,
       _GBOX3,
       _GBOX4,
       _GBOX5,
       _GBOX6,
       _MOLSON,
       _621,
       _620,
       _619,
       _618,
       _617,
       _616,
       _615,
       _614,
       _613,
       _612,
       _611,
       _610,
       _609,
       _608,
       _607,
       _606,
       _605,
       _604,
       _603,
       _602,
       _601,
       _519,
       _518,
       _517,
       _516,
       _515,
       _514,
       _513,
       _512,
       _511,
       _510,
       _509,
       _508,
       _507,
       _506,
       _505,
       _504,
       _503,
       _502,
       _501,
       _408,
       _407,
       _406,
       _405,
       _404,
       _403,
       _402,
       _401,
       _200,
       _227,
       _231       
    );
    return zones;
 };
 