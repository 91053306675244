import React from "react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const LoadingDialog = () => {
  const [isOpen, _setIsOpen] = useState(true);


  return (
    <Dialog aria-labelledby="simple-dialog-title" open={isOpen} maxWidth="sm">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Processing...
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
