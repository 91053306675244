
const defaultRows = () => {
  const arr = [];

  for (let i = 1; i <= 40; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneRows = () => {
  const arr = [];

  for (let i = 1; i <= 26; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneOtherRows = () => {
  const arr = [];
  const numbers = [
    ...["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
    ...["15", "16", "17", "18", "19", "20", "21", "22", "23", "24"]
  ]

  numbers.map(number => {
    arr.push({
      value: number,
      label: `Row ${number}`,
    });
  })

  return arr;
};

const premiumZoneRows1 = () => {
  const arr = [];

  for (let i = 1; i <= 6; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const premiumZoneRows2 = () => {
  const arr = [];

  for (let i = 1; i <= 7; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const upperZoneRows1 = () => {
  const arr = [];

  for (let i = 1; i <= 19; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const upperZoneRows2 = () => {
  const arr = [];

  for (let i = 1; i <= 30; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const otherRows = () => [
  {
    value: 'GA',
    label: 'Row GA',
  }
];

const touchdownLoungeRows = () => [
  {
    value: 'STL1',
    label: 'Row STL1',
  }
];

export default {
  LOWER_ZONE_ROWS: lowerZoneRows(),
  LOWER_ZONE_OTHER_ROWS: lowerZoneOtherRows(),
  PREMIUM_ZONE_ROWS1: premiumZoneRows1(),
  PREMIUM_ZONE_ROWS2: premiumZoneRows2(),
  UPPER_ZONE_ROWS1: upperZoneRows1(),
  UPPER_ZONE_ROWS2: upperZoneRows2(),
  OTHER_ROWS: otherRows(),
  TOUCHDOWN_LOUNGE_ROWS: touchdownLoungeRows(),
  DEFAULT_ROWS: defaultRows(),
};
