import React from "react";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";

import { Typography, withStyles } from "@material-ui/core";
import { getLocalizedDateTime, handleGameClickHelper } from "../../libs/shared/seat-helpers";
import { findGridLowestPrice, getFormattedTime } from "../../libs/shared/grid-helper";
import TEAMS from "../../constants/Teams";

const styles = (theme) => ({
  gamesGridContainer: {
    display: "flex",
    flexDirection: "column",
  },
  gridHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #D5DEE9",
  },
  gridHeader: {
    padding: "1rem 0",
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  gameDataContainer: {
    display: "flex",
    flexDirection: "row",
  },
  gameData: {
    flex: 1,
    padding: "1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  gameDate: {
    flex: 2,
    display: "flex",
  },
  seeAllButton: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderBottom: "1px solid #D5DEE9",
    marginBottom: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    gamesGridContainer: {
      padding: "0 10rem",
    },
  },
  [theme.breakpoints.up("md")]: {
    gridHeaderContainer: {
      padding: "0 10rem",
    },
    upcomingGamesHeader: {
      flex: 2,
    },
  },
  [theme.breakpoints.down("md")]: {
    gridHeaderContainer: {
      padding: "0 1rem",
    },
    gameDataContainer: {
      padding: "0 1rem",
    },

    gameDate: {
      flexDirection: "column",
      paddingRight: "1rem",
      flex: 2.5,
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
});

const GamesList = ({
  classes,
  history,
  games,
  selectedTeam,
  isLoadingGames,
  teIdList,
}) => {
  const teamDetails = TEAMS.find((team) => team.homeTeamSlug === selectedTeam);

  const handleGameClick = ({ game, zone, teId }) => {
    handleGameClickHelper({ game, zone, history, teId });
  };

  const renderGames = () => {
    if (isLoadingGames) {
      return (
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          style={{ padding: "2rem", fontSize: "1.5rem" }}
        >
          Loading {teamDetails.name} games...
        </Typography>
      );
    } else {
      let regularGames = games.filter((game) => game.isTbd != true);
      let TbdGames = games.filter((game) => game.isTbd === true);
      // TbdGames is an array of objects, we need to sort it by id
      TbdGames = TbdGames.sort((a, b) => a.playoffSequence - b.playoffSequence);
      games = [...regularGames, ...TbdGames];
      return (
        games &&
        Array.isArray(games) &&
        games.map((game, index) => {
          const {date, timezone, isPlayoffs, playoffSequence } = game;
          const gameDate = getLocalizedDateTime(date, timezone).stringDate;
          const findIDDate = teIdList.find((item) => {
            let teKey = isPlayoffs ? playoffSequence : gameDate;
            return item[teKey] ? true : false;
          });
          const gameKey = isPlayoffs ? playoffSequence : gameDate;
          const itemIDDate = findIDDate ? findIDDate : {fallback: ''};
          const selectedTeId = itemIDDate[gameKey] || "";
          const gridLowestPrices = findGridLowestPrice(game);
          return (
            <div className={classes.gameDataContainer} key={index}>
              <div
                className={`${classes.gameData} ${classes.gameDate}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 0,
                    teId: selectedTeId,
                  })
                }
              >
                <div style={{ flex: 1 }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ cursor: "pointer" }}
                  >
                    {!game.isTbd
                      ? moment.tz(game.date, game.timezone).format("MMM DD")
                      : "TBD"}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ cursor: "pointer" }}
                  >
                    {!game.isTbd
                      ? getFormattedTime(moment.tz(game.date, game.timezone).format("ddd h:mm A"), game)
                      : "TBD"}
                  </Typography>
                </div>
                <div style={{ flex: 1 }}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ cursor: "pointer" }}
                  >
                    {game.shortName}
                  </Typography>
                </div>
              </div>
              <div
                className={`${classes.gameData}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 1,
                    teId: selectedTeId,
                  })
                }
              >
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ cursor: "pointer" }}
                >
                  {gridLowestPrices.upperLevelMinPrice}
                </Typography>
              </div>
              <div
                className={`${classes.gameData}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 2,
                    teId: selectedTeId,
                  })
                }
              >
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ cursor: "pointer" }}
                >
                  {gridLowestPrices.secondLevelMinPrice}
                </Typography>
              </div>
              <div
                className={`${classes.gameData}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 3,
                    teId: selectedTeId,
                  })
                }
              >
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ cursor: "pointer" }}
                >
                  {gridLowestPrices.lowerLevelMinPrice}
                </Typography>
              </div>
            </div>
          );
        })
      );
    }
  };

  return (
    <div style={{ marginBottom: "-32px" }}>
      <div className={classes.gridHeaderContainer}>
        <div className={`${classes.gridHeader} ${classes.gameDate}`}>
          {selectedTeam !== 'grey-cup' && <Typography variant="caption" style={{ fontWeight: "bold" }}>
            Upcoming Games
          </Typography>}
        </div>
        {teamDetails.zones.map((zone, index) => {
          return (
            <div
              key={index}
              className={classes.gridHeader}
              style={{ justifyContent: "center" }}
            >
              <Typography
                variant="caption"
                style={{ fontWeight: "bold" }}
                align="center"
              >
                {zone}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className={classes.gamesGridContainer}>{renderGames()}</div>
    </div>
  );
};

export default withRouter(withStyles(styles)(GamesList));
