import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';

import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  [theme.breakpoints.up('md')]: {
    formContainer: {
      padding: '3rem 5rem',
    }
  },
  [theme.breakpoints.down('sm')]: {
    formContainer: {
      justifyContent: "center",
      padding: '5rem 3rem',
    },
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  textField: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    border: 'solid 1px #e5e5ea',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  textFieldError: {
    padding: '.75rem',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 23, 68, .05)',
    border: 'solid 1px rgba(255, 23, 68, .5)',
    marginBottom: '.25rem',
    fontSize: '0.875rem'
  },
  button: {
    padding: '.875rem',
    marginTop: '1.5rem'
  },
  successMessage: {
    marginTop: '1rem',
    color: '#4caf50'
  },
  input: {
    padding: 0,
    fontSize: '0.875rem'
  }
});

class AccountVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isValidCurrentPassword: true,
      currentPasswordErr: '',
      isValidNewPassword: true,
      isValidConfirmPassword: true,
      isSubmitting: false,
      isPasswordChanged: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {}

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  isValidPasswords(currentPassword, newPassword, confirmPassword) {
    const isValidCurrentPassword = currentPassword !== '';
    const isValidNewPassword = newPassword !== '' && newPassword.length >= 6;
    const isValidConfirmPassword =
      confirmPassword !== '' && newPassword === confirmPassword;

    this.setState({
      isValidCurrentPassword,
      isValidNewPassword,
      isValidConfirmPassword
    });

    return (
      isValidCurrentPassword && isValidNewPassword && isValidConfirmPassword
    );
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { currUser } = this.props;

    try {
      const { currentPassword, newPassword, confirmPassword } = this.state;

      this.setState({
        invalidCurrentPassword: false,
        isSubmitting: true
      });

      if (
        this.isValidPasswords(currentPassword, newPassword, confirmPassword)
      ) {
        const sess = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(sess, currentPassword, newPassword);

        API.post("v2", "marketplace/activities/create", {
          body: {
            email: currUser.email,
            user_group: "seller",
            activity_type: "Profile Reset Password",
            description: `Seller ${currUser.email} changed password.`,
            data: {}
          },
        });

        this.setState({
          isPasswordChanged: true,
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      }
      this.setState({
        isSubmitting: false
      });
    } catch (e) {
      this.setState({
        isSubmitting: false
      });

      switch (e.code) {
        case 'NotAuthorizedException': {
          this.setState({
            isValidCurrentPassword: false
          });
          break;
        }

        default: 
          this.setState({
            isValidCurrentPassword: false
          });
      }

    }
  }

  renderForm() {
    const { classes } = this.props;
    const {
      isSubmitting,
      currentPassword,
      newPassword,
      confirmPassword,
      isPasswordChanged
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Typography
          style={{
            marginBottom: '1rem'
          }}
          variant="headline"
          color="primary"
          className={classes.titleText}
        >
          Change Password
        </Typography>

        <TextField
          autoFocus={true}
          className={
            this.state.isValidCurrentPassword
              ? classes.textField
              : classes.textFieldError
          }
          disabled={isSubmitting}
          error={true}
          id="currentPassword"
          placeholder="Current Password"
          name="currentPassword"
          fullWidth={true}
          value={currentPassword}
          onChange={this.handleChange}
          margin="dense"
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.input
            }
          }}
          type="password"
        />
        {!this.state.isValidCurrentPassword && (
          <FormHelperText error={true}>
            Please enter your current password
          </FormHelperText>
        )}

        <TextField
          className={
            this.state.isValidNewPassword
              ? classes.textField
              : classes.textFieldError
          }
          disabled={isSubmitting}
          error={true}
          id="newPassword"
          placeholder="New Password"
          name="newPassword"
          fullWidth={true}
          value={newPassword}
          onChange={this.handleChange}
          margin="dense"
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.input
            }
          }}
          type="password"
        />

        {this.state.isValidNewPassword ? (
          ''
        ) : (
          <FormHelperText error={true}>
            Your new password must be at least 6 characters
          </FormHelperText>
        )}

        <TextField
          className={
            this.state.isValidConfirmPassword
              ? classes.textField
              : classes.textFieldError
          }
          disabled={isSubmitting}
          error={true}
          id="confirmPassword"
          placeholder="Confirm New Password"
          name="confirmPassword"
          fullWidth={true}
          value={confirmPassword}
          onChange={this.handleChange}
          margin="dense"
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.input
            }
          }}
          type="password"
        />

        {this.state.isValidConfirmPassword ? (
          ''
        ) : (
          <FormHelperText error={true}>
            Passwords do not match
          </FormHelperText>
        )}

        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting}
          variant="contained"
          fullWidth={true}
          onClick={this.handleSubmit}
          type="submit"
        >
          Submit
        </Button>
        {isPasswordChanged && (
          <Typography
            variant="caption"
            align="center"
            className={classes.successMessage}
          >
            You have successfully changed your password! { ' ' }
            <a href="/account" style={{ color: '#4caf50' }}>
              Click here to go back to your account
            </a>
          </Typography>
        )}
      </form>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid className={classes.formContainer} container>
        <Grid item xs={12} md={4}>
          { this.renderForm() }
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AccountVerification);
