import React, { Component } from "react";
import moment from "moment-timezone";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import querySearch from "stringquery";
import * as Sentry from "@sentry/browser";
import { checkoutReviewsData } from "../components/data/checkoutReviews";
import LoginForm from "../containers/LoginForm";
import _ from 'lodash';

import { withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import MapIcon from "@material-ui/icons/MapRounded";
// import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography";
import EventSeatRoundedIcon from "@material-ui/icons/EventSeatRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import chair from "../images/chair.jpg";
import TicketIcon from "../images/tickets.svg";
import { Hidden, Grid } from "@material-ui/core";
import SeatMap from "../components/SeatMap";
import CheckoutFormWeb from "../components/CheckoutFormWeb";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GradeIcon from '@material-ui/icons/Grade';

import { computeStubhubFees } from "../libs/fee-calcs";

import "./Ticket.css";
import cdialog from "../components/cdialog";
import creditCardIcon from "../images/icons/credit-card.png";
import { nextDaysGameCheck, renderZoneName, renderRowName, testDataCheck } from "../libs/shared/seat-helpers";
import { getToken } from "../services/payment";
import Setting from "../constants/Setting";
import { getFormattedTime, getTimeAtLabels } from "../libs/shared/grid-helper";
import { randomIntFromInterval } from "../utils/randomNumber";
import { getThreeDSStatus } from "../services/setting";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  menuButton: {
    marginLeft: -12,
  },
  appBar: {
    color: "white",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  heroUnit: {
    position: "relative",
  },
  heroContent: {
    position: "absolute",
    bottom: 0,
    padding: "1rem",
    color: "white",
  },
  heroImage: {
    width: "100%",
    height: 200,
    backgroundImage: `url(${chair})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  seatDetails: {},
  leftIcon: {
    fontSize: "1rem",
    marginRight: ".5rem",
  },
  [theme.breakpoints.up("md")]: {
    main: {
      minHeight: "calc(100vh - 175px - 112px)",
      paddingTop: "2rem",
    },
    loadingContainer: {
      paddingTop: "2rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    main: {
      paddingTop: "1rem",
    },
    loadingContainer: {
      paddingTop: "5rem",
    },
  },
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  paper: {
    paddingTop: theme.spacing.unit * 2,
  },
  paperReviews: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: "8px",
    border: "solid 1px rgba(0, 0, 0, 0.54)",
    marginBottom: '12px',
    padding: '8px',
  },
  paperReviewsQuotes: {
    fontFamily: "cursive",
    fontSize: "56px",
    color: "rgba(0, 0, 0, 0.54)",
    padding: '12px',
    lineHeight: '0.5',
  },
});

class Ticket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seat: null,
      game: null,
      noOfSeats: 0,
      savings: 0,
      seatMapOpen: false,
      checkoutSuccess: false,
      gameName: "",
      gameDate: "",
      showWarningDialog: false,
      failedTransactions: 0,
      msgOpen: true,
      checkoutAlert: false,
      alertTitle: "",
      alertContent: "",
      refresh: false,
      refreshURL: "",
      paymentSdk: null,
      dataReviews: [
        {
          name: "",
          profile_pic: "",
          comments: "",
        },
        {
          name: "",
          profile_pic: "",
          comments: "",
        },
      ],
      threeDSStatus: "on",
      loginFormOpen: false
    };

    this.toggleSeatMap = this.toggleSeatMap.bind(this);
    this.toggleCheckoutAlert = this.toggleCheckoutAlert.bind(this);
    this.pay = this.pay.bind(this);
    this.authenticatePayment = this.authenticatePayment.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleLoginForm = this.handleLoginForm.bind(this);
  }

  async componentWillMount() {
    const { teId, teListingId } = querySearch(
      this.props.location.search
    );
    const isLoadTE = teId && teId !== "";
    const teQuery = isLoadTE
      ? `&teId=${teId}&teListingId=${teListingId}`
      : "";
    try {
      const game = await this.game();
      if (
        (moment.now() > moment(game.date).add(1, "hour").valueOf() &&
          !game.isTbd) ||
        game.isArchived
      ) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      if (testDataCheck(game)) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      var seatDetails = await this.seat();
      const failedTransactions = parseInt(localStorage.getItem("tft"));
      const nextCheckoutDateTime = parseInt(localStorage.getItem("ncdt"));

      this.setState({
        failedTransactions: failedTransactions ? failedTransactions : 0,
      });

      if (
        failedTransactions >= 3 &&
        !moment().isAfter(nextCheckoutDateTime, "second")
      ) {
        this.setState({
          checkoutAlert: true,
          alertTitle: "Too Many Failed Transactions",
          alertContent:
            "You have too many failed transactions. Please try again later or call/text 1-403-768-2298 and we will help you get the seats you want.",
          refresh: true,
          refreshURL: `/games/${game.id}/seats?zone=0${teQuery}`,
        });

        /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
      } else if (
        failedTransactions >= 3 &&
        moment().isAfter(nextCheckoutDateTime, "second")
      ) {
        localStorage.removeItem("tft");
        localStorage.removeItem("ncdt");
      }

      const remainingTickets = seatDetails.noOfSeats - seatDetails.seatsSold;
      /* console.log(remainingTickets); */
      const seat = { ...seatDetails, ...{ noOfSeats: remainingTickets } };

      const savings = this.computeSavings(seat, remainingTickets, game);
      /* console.log(savings); */
      if (seat.noOfSeats === 0 || seat.isArchived) {
        /* alert("This seat is not available anymore."); */
        this.setState({
          checkoutAlert: true,
          alertTitle: "Seat Not Available",
          alertContent: "This seat is not available anymore.",
          refresh: true,
          refreshURL: `/games/${game.id}/seats?zone=0${teQuery}`,
        });
        /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
      }

      const regularGameDate = `${moment
        .tz(game.date, game.timezone)
        .format("ddd, MMM D YYYY")}${getTimeAtLabels(game)}${getFormattedTime(moment
        .tz(game.date, game.timezone)
        .format("h:mm A"), game)}`;

      const gameDate = game.isTbd ? "TBD" : regularGameDate;

      const threeDSStatus = await getThreeDSStatus();

      // API Call
      this.setState({
        seat,
        savings,
        game,
        gameName: game.longName,
        gameDate,
        noOfSeats: remainingTickets,
        quantityOptions: seatDetails.quantityOptions,
        showWarningDialog: game.isTBA ? game.isTBA : false,
        teamName: game.homeTeam,
        threeDSStatus
      });
      /* console.log("state", this.state); */
    } catch (e) {}
  }

  seat() {
    const { gameId, seatId } = this.props.match.params;
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${gameId}/?seatId=${seatId}`
    );
  }

  async token() {
    try {
      const response = await getToken();
      const { token } = response;
      this.setState({
        paymentSdk: new window.Elavon3DSWebSDK({
          // baseUrl: "https://uat.gw.fraud.eu.elavonaws.com/3ds2",
          baseUrl: "https://gw.fraud.elavon.com/3ds2",
          token: token,
        }),
      });
    } catch (err) {
      this.setState({
        checkoutAlert: true,
        alertTitle: "Error",
        alertContent: "There was a technical issue on our end with your transaction. We are trying to resolve the situation. Please try again in thirty minutes or email tix@fansfirst.ca for immediate assistance.",
        refresh: true,
        refreshURL: `/`,
      });
    }
  }

  game = () => {
    const gameId = this.props.match.params.gameId;
    return API.get("v2", `marketplace/events/by/eventId/${gameId}`);
  };

  computeSavings = (seat, qty, game) => {
    const stubhubFees = computeStubhubFees({
      price: seat.price,
      qty,
      zone: seat.zone,
      homeTeamSlug: game.homeTeamSlug,
    });
    return stubhubFees;
  };

  toggleSeatMap() {
    this.setState({
      seatMapOpen: !this.state.seatMapOpen,
    });
  }

  handleLoginForm() {
    this.setState({
      loginFormOpen: !this.state.loginFormOpen,
    });
  }

  checkout = () => {
    const { teId, teListingId } = querySearch(
      this.props.location.search
    );
    const isLoadTE = teId && teId !== "";
    const teQuery = isLoadTE
      ? `&teId=${teId}&teListingId=${teListingId}`
      : "";
    this.props.history.push(
      `/checkout?game=${this.state.game.id}&seat=${this.state.seat.seatId}&seats=${this.state.noOfSeats}${teQuery}`
    );
  };

  handleChange = (name) => (event) => {
    let savings = this.state.savings;

    if (name === "noOfSeats") {
      const seats = event.target.value;

      savings = this.computeSavings(this.state.seat, seats, this.state.game);
    }

    this.setState({
      [name]: event.target.value,
      savings,
    });
  };

  toggleCheckoutAlert() {
    const { refresh, refreshURL } = this.state;
    this.setState({ checkoutAlert: !this.state.checkoutAlert });
    if (refresh) {
      this.props.history.push(refreshURL);
    }
  }

  quantityMenus = () => {
    const { noOfSeats, quantityOptions } = this.state.seat;
    const teSeatSplit = localStorage.getItem('teSeatSplit');
    const finalQuantityOptions = teSeatSplit
      ? JSON.parse(teSeatSplit)
      : quantityOptions;
    // loop through quantityOptions array and create a menu item for each
    return finalQuantityOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option} {noOfSeats > 1 ? "Tickets" : "Ticket"}
      </MenuItem>
    ));
  };

  renderSelectSeats = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="body2">Select number of seats</Typography>
        <div
          style={{
            padding: "0.5rem 0.75rem",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            border: "solid 1px #e5e5ea",
            marginBottom: "1.5rem",
            width: "100%",
            marginTop: ".5rem",
          }}
        >
          <Select
            displayEmpty
            fullWidth={true}
            disableUnderline={true}
            value={this.state.noOfSeats}
            onChange={this.handleChange("noOfSeats")}
            inputProps={{
              name: "noOfSeats",
              id: "noOfSeats",
              placeholder: "Select number of seats",
            }}
            style={{
              marginTop: 0,
            }}
            placeholder="No. of Seats"
            className={classes.select}
          >
            {this.quantityMenus()}
          </Select>
        </div>
      </React.Fragment>
    );
  };

  _renderReviews() {
    const { classes } = this.props;
    const { dataReviews } = this.state;
    const currentReview1 = dataReviews[0];
    const currentReview2 = dataReviews[1];

    return (
      <div className={classes.paper}>
        <Typography
          variant="caption"
          style={{
            textTransform: "uppercase",
            marginBottom: ".5rem",
          }}
        >
          REVIEWS
        </Typography>
        <div className={classes.paperReviews}>
          <Grid>
            <Typography
              variant="caption"
              style={{
                textTransform: "capitalize",
                marginBottom: ".5rem",
              }}
            >
              <GradeIcon style={{color: "rgb(34, 150, 243)", fontSize: "32px", marginBottom: "-12px"}} />
              <b>5.0 &nbsp;</b>
              {currentReview1.name}
            </Typography>

            <Typography variant="body1" style={{color: "rgba(0, 0, 0, 0.54)",}}>{currentReview1.comments}</Typography>
          </Grid>
        </div>

        {currentReview2 !== currentReview1 && <div className={classes.paperReviews}>
          <Grid>
            <Typography
              variant="caption"
              style={{
                textTransform: "capitalize",
                marginBottom: ".5rem",
              }}
            >
              <GradeIcon style={{color: "rgb(34, 150, 243)", fontSize: "32px", marginBottom: "-12px"}} />
              <b>5.0 &nbsp;</b>
              {currentReview2.name}
            </Typography>

            <Typography variant="body1" style={{color: "rgba(0, 0, 0, 0.54)",}}>{currentReview2.comments}</Typography>
          </Grid>
        </div>}
      </div>
    );
  }

  hideInstaDeliveryText = () => {
    const currentTime = moment().tz("America/Edmonton").format("HH:mm");
    const startHideTime = "00:00"; // 12:00 am
    const endHideTime = "06:00"; // 06:00 am
  
    if (currentTime >= startHideTime && currentTime <= endHideTime) {
      return null;
    } else {
      return "  Insta-Delivery";
    }
  }

  renderMobileSeat() {
    const { seat, noOfSeats, gameName, gameDate, game } = this.state;
    const { classes } = this.props;

    if (seat) {
      const teSellerID = "c843acc5-aa23-49a6-9634-6c5415064fa7"; // exclude ticket evolution listing check
      const zoneLabel =
        seat.sellerId === teSellerID
          ? renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial)
          : renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial);
      return (
        <div>
          <Grid container style={{ position: "relative" }}>
            <Grid item xs={6}>
              <div
                style={{
                  marginBottom: "2rem",
                }}
              >
                <Typography
                  variant="caption"
                  color="primary"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Seat Details
                </Typography>
                <Typography variant="body2" color="inherit">
                  {gameName}
                </Typography>
                <Typography variant="caption" color="inherit">
                  {gameDate}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="text"
                color="default"
                size="small"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "9px",
                }}
                disableFocusRipple={true}
                disableRipple={true}
                onClick={this.toggleSeatMap}
              >
                <MapIcon className={classes.leftIcon} />
                View Seat Map
              </Button>
            </Grid>
          </Grid>

          <div className="seat-details">
            {this.renderSelectSeats()}

            <div
              style={{
                display: "flex",
              }}
            >
              <EventSeatRoundedIcon
                color="primary"
                style={{
                  marginRight: "1rem",
                }}
                fontSize="default"
              />
              <div style={{ width: "100%" }}>
                <Typography
                  variant="body1"
                  color="inherit"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {zoneLabel} • Row {seat.row}{" "}
                  <Typography
                    style={{
                      display: "inline-block",
                      color: "rgb(33, 150, 243)",
                      fontStyle: "oblique",
                    }}
                    variant="caption"
                  >
                    {seat.isAisleSeat && parseInt(seat.seatsSold) === 0
                      ? "  Aisle Seats"
                      : null}
                  </Typography>
                  {seat.isAisleSeat &&
                  parseInt(seat.seatsSold) === 0 &&
                  seat.isInstantDelivery ? (
                    <Typography
                      style={{
                        display: "inline-block",
                        color: "#e03a3e",
                        fontStyle: "oblique",
                      }}
                      variant="caption"
                    >
                      &nbsp;•&nbsp;
                    </Typography>
                  ) : null}
                  <Typography
                    style={{
                      display: "inline-block",
                      color: "#e03a3e",
                      fontStyle: "oblique",
                    }}
                    variant="caption"
                  >
                    {seat.isInstantDelivery ? this.hideInstaDeliveryText() : null}
                  </Typography>
                </Typography>
                <Typography
                  style={{
                    display: "inline-block",
                    color: "rgb(33, 150, 243)",
                    fontStyle: "oblique",
                  }}
                >
                  {seat.isWheelchair ? " Wheelchair" : null}
                </Typography>
                <Typography
                  style={{
                    display: "inline-block",
                    color: "rgb(33, 150, 243)",
                    fontStyle: "oblique",
                  }}
                >
                  {seat.isObstructedView ? "  Obstructed View" : null}
                </Typography>
                {noOfSeats > 1 ? (
                  <Typography variant="body1" color="textSecondary">
                    Your seats will be together
                  </Typography>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "1.5rem" }}>
              <img
                src={TicketIcon}
                style={{
                  marginRight: "1.5rem",
                  verticalAlign: "top",
                  height: "1rem",
                  color: "#2196f3",
                }}
                alt="Ticket Icon"
              />
              <div>
                <Typography variant="body2" color="primary">
                  {this.state.noOfSeats > 0 ? (
                    <span>
                      {this.state.noOfSeats} x $
                      {parseFloat(seat.price).toFixed(2)} CAD ea
                    </span>
                  ) : (
                    <span>Select number of seats</span>
                  )}
                </Typography>

                {this.state.noOfSeats > 0 ? (
                  <React.Fragment>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Total:{" "}
                      <span
                        style={{
                          color: "#2196f3",
                        }}
                      >
                        ${(this.state.noOfSeats * seat.price).toFixed(2)} CAD
                      </span>
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      No fees! Save up to{" "}
                      <span style={{ color: "#2196f3" }}>
                        ${this.state.savings.toFixed(2)}
                      </span>{" "}
                      compared to full-fee competitors
                    </Typography>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <EmailRoundedIcon
                color="error"
                style={{
                  marginRight: "1rem",
                }}
                fontSize="default"
              />
              <div>
                <Typography variant="body2" color="secondary">
                  Delivered via mobile transfer
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Instructions on how to accept your ticket
                  {noOfSeats > 1 ? "s" : ""} will be sent via email.
                </Typography>
              </div>
            </div>
          </div>
          <Hidden mdUp>
            <Button
              disableRipple={true}
              variant="contained"
              disabled={noOfSeats < 1}
              onClick={() => this.checkout()}
              fullWidth={true}
              color="primary"
              style={{
                padding: "1.25rem",
                color: "white",
                borderRadius: 0,
              }}
            >
              Proceed to Checkout
            </Button>
            {this._renderReviews()}
          </Hidden>
        </div>
      );
    } else {
      return null;
    }
  }

  renderBanner() {
    const { game, gameName, gameDate } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AppBar
          className={classes.appBar}
          color="default"
          position="absolute"
        ></AppBar>
        <div className={classes.heroUnit}>
          <div className={classes.heroImage} />
        </div>
      </React.Fragment>
    );
  }

  _getTEDetails = async (details) => {
    const {game} = this.state;
    const { teListingId } = querySearch(this.props.location.search);
    const isNexDaysValid = nextDaysGameCheck(game);
    if (!isNexDaysValid) {
      this.setState({
        checkoutAlert: true,
        alertTitle: "No Longer Available",
        alertContent:
          "Sorry, those tickets are no longer available or someone else got them.",
        refresh: true,
        refreshURL: `/`,
      });
      this.setState({ isLoading: false, checkoutSuccess: false });
      return;
    }
    try {
      const teDetails = await API.get(
        "v2",
        `marketplace/ticketEvo/listings/show?listingId=${teListingId}`
      );
      if (
        teDetails &&
        teDetails.available_quantity &&
        teDetails.available_quantity >= details.noOfSeats
      ) {
        this.pay(details);
      } else {
        this.setState({
          checkoutAlert: true,
          alertTitle: "No Longer Available",
          alertContent:
            "Sorry, those tickets are no longer available or someone else got them.",
          refresh: true,
          refreshURL: `/`,
        });
        this.setState({ isLoading: false, checkoutSuccess: false });
      }
    } catch (error) {
      this.setState({
        checkoutAlert: true,
        alertTitle: "No Longer Available",
        alertContent:
          "Sorry, those tickets are no longer available or someone else got them.",
        refresh: true,
        refreshURL: `/`,
      });
      this.setState({ isLoading: false, checkoutSuccess: false });
    }
  };

  notifyFailedTransaction(details) {
    const { game, seat, gameName, gameDate } = this.state;
    const section = renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial);
    const row = renderRowName(seat.zone, seat.row, game.homeTeamSlug, game.isSpecial);
    const description = `${details.noOfSeats} ticket(s): ${gameName} - ${gameDate}, ${section.trim()} Row ${row.trim()}`;

    API.post("v2", "marketplace/orders/failed-transaction-notification", {
      body: {
        seat: seat.seatId,
        amount: Number(details.subTotal.toFixed(2)),
        email: details.email,
        mobileNo: "+1" + details.mobileNo.replace(/\D/g, ""),
        name: `${details.firstName} ${details.lastName}`,
        description,
        card: details.card,
        error_code: details.error_code,
        reason: "3DS Authentication Failed",
      },
    });
  }

  handleCheckout(details) {
    this.setState({ isLoading: true });
    if (this.state.threeDSStatus === "on") {
      this.authenticatePayment(details);
    } else {
      const orderDetail = {
        ...details,
        threeDSecureValue: null,
        threeDSMessageVersion: null,
        threeDSServerTransID: null,
        threeDSTransStatus: null,
        dsTransID: null,
        eci: null,
        messageType: null,
      };
      const { teId } = querySearch(this.props.location.search);
      const isLoadTE = teId && teId !== "";
      if (isLoadTE) {
        this._getTEDetails(orderDetail);
      } else {
        this.pay(orderDetail);
      }
    }
  }

  async authenticatePayment(details) {
    try {
      await this.token();
      const { paymentSdk } = this.state;
      const { teId } = querySearch(this.props.location.search);
      const isLoadTE = teId && teId !== "";

      const purchaseAmount = Number(details.subTotal.toFixed(2));
      const authRequest = {
        acctNumber: details.card,
        cardExpiryDate: moment(details.expiryDate, "MMYY").format("YYMM"),
        purchaseAmount: parseInt(purchaseAmount * 100),
        purchaseCurrency: Setting.ELAVON_PURCHASE_CURRENCY,
        purchaseExponent: Setting.ELAVON_PURCHASE_EXPONENT,
        challengeWindowSize: "04",
        messageCategory: Setting.ELAVON_MESSAGE_CATEGORY,
        transType: Setting.ELAVON_TRANS_TYPE,
        threeDSRequestorAuthenticationInd:
          Setting.ELAVON_3DS_REQUEST_OR_AUTHENTICATION_IND,
        threeDSRequestorChallengeInd: Setting.ELAVON_3DS_REQUESTOR_CHALLENGE_IND,
        clientStartProtocolVersion: Setting.ELAVON_3DS_CLIENT_START_PROTOCOL_VERSION,
        clientEndProtocolVersion: Setting.ELAVON_3DS_CLIENT_END_PROTOCOL_VERSION,
        cardholderName: `${details.firstName} ${details.lastName}`,
        mobilePhone: {
          cc: "1",
          subscriber: details.mobileNo.replace(/\D/g, ""),
        },
        billAddrPostCode: details.zipCode,
        merchantRiskIndicator: {
          deliveryEmailAddress: details.email,
        },
      };

      paymentSdk
        .web3dsFlow(authRequest)
        .then((response) => {
          var allowedTransStatus = ['Y', 'I', 'A'];
          if ( (allowedTransStatus.indexOf(response.transStatus) !== -1) && (response.authenticationValue) && (response.eci) ) {
            const orderDetail = {
              ...details,
              threeDSecureValue: response.authenticationValue,
              threeDSMessageVersion: response.messageVersion,
              threeDSServerTransID: response.threeDSServerTransID,
              threeDSTransStatus: response.transStatus,
              dsTransID: response.dsTransID,
              eci: response.eci,
              messageType: response.messageType,
            };

            if (isLoadTE) {
              this._getTEDetails(orderDetail);
            } else {
              this.pay(orderDetail);
            }
          } else {
            this.setState({ isLoading: false });
            cdialog.error("Error", "Sorry we're experiencing a technical issue. Please email tix@fansfirst.ca or text 1-403-768-2298 for assistance.");
            this.notifyFailedTransaction({
              ...details,
              error_code: `${'transStatusReason' in response ? `${response.transStatusReason} - ` : '' }${response.message}, transStatus = ${response.transStatus}`
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          cdialog.error("Error", "The card authentication failed.");
        });
      } catch(err) {
        this.setState({
          checkoutAlert: true,
          alertTitle: "Error",
          alertContent: "There was a technical issue on our end with your transaction. We are trying to resolve the situation. Please try again in thirty minutes or email tix@fansfirst.ca for immediate assistance.",
          refresh: true,
          refreshURL: `/`,
        });
      }
  }

  async pay(details) {
    const { game, seat, gameName, gameDate, coupon } = this.state;
    const subTotal = details.subTotal;
    const noOfSeats = details.noOfSeats;
    const { teId, teListingId } = querySearch(
      this.props.location.search
    );
    const isLoadTE = teId && teId !== "";
    const teQuery = isLoadTE
      ? `teId=${teId}&teListingId=${teListingId}`
      : "";

    // this.setState({ isLoading: true });
    const section = renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial);
    const row = renderRowName(seat.zone, seat.row, game.homeTeamSlug, game.isSpecial);
    const description = `${noOfSeats} ticket(s): ${gameName} - ${gameDate}, ${section.trim()} Row ${row.trim()}`;
    var mobileNo = details.mobileNo;
    // mobileNo is a string containing the phone number entered by a user.
    // There is currently no validated to check if it is in format +14033070847
    // We should strip all non numeric characters except for the + sign.
    // If the number is in format 4033070847 we should add "+1". If it is
    // in format +14033070847 we should accept it as is.
    // otherwise, we should raise an error

    // first, strip all non numeric characters
    mobileNo = mobileNo.replace(/\D/g, "");

    // if the number is in format 4033070847 we should add "+1"
    // else we should alert the user that the number is not in the correct format
    // use regex to test this
    if (mobileNo.match(/^\d{10}$/)) {
      mobileNo = "+1" + mobileNo;
    } else if (mobileNo.match(/^\+\d{11}$/)) {
      mobileNo = mobileNo = "+" + mobileNo;
    } else {
      /* alert("Please enter a valid phone number"); */
      this.setState({
        checkoutAlert: true,
        alertTitle: "Invalid Phone Number",
        alertContent:
          "Please enter a valid Candian mobile phone number (no VOIP or landlines).",
        refresh: true,
        refreshURL: `/games/${seat.game}/seats/${seat.seatId}?${teQuery}`,
      });
      this.setState({ isLoading: false, checkoutSuccess: false });
      /* this.props.history.push(`/games/${seat.game}/seats/${seat.seatId}`); */
      return;
    }
    // whitelist Denton for testing
    const isWhitelisted =
      String(details.email).toLowerCase() === "dentron@gmail.com" ||
      String(details.email).toLowerCase() === "denton@fansfirst.ca" ||
      String(details.email).toLowerCase() === "kylec4111@gmail.com";
    try {
      const game = await this.game();
      if (
        moment.now() > moment(game.date).add(1, "hour").valueOf() &&
        !game.isTbd
      ) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      if (testDataCheck(game)) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      const { order, show_popup } = await API.post(
        "v2",
        "marketplace/orders/checkout",
        {
          body: {
            amount: Number(subTotal.toFixed(2)),
            email: details.email,
            mobileNo: mobileNo,
            name: details.firstName + " " + details.lastName,
            description,
            noOfSeats,
            ticketPrice: seat.price,
            game: seat.game,
            seat: seat.seatId,
            card: details.card,
            expiryDate: details.expiryDate,
            cvv: details.cvv,
            postalCode: details.zipCode,
            couponId: coupon ? coupon.id : null,
            threeDSecureValue: details.threeDSecureValue,
            threeDSMessageVersion: details.threeDSMessageVersion,
            threeDSServerTransID: details.threeDSServerTransID,
            threeDSTransStatus: details.threeDSTransStatus,
            dsTransID: details.dsTransID,
            eci: details.eci,
            messageType: details.messageType,
            teListingId: isLoadTE ? teListingId : null,
          },
        }
      );

      // Work, We are facing weird issue, order created but user still got unexpected error message
      // when user have addblocker this function will lead to exception error
      // window.fbq("track", "Purchase", {
      //   value: order.ticketPrice * order.noOfSeats,
      //   currency: "CAD",
      // });

      if (show_popup) {
        cdialog.info(
          "Follow-up Identification",
          <>
            Thanks for using FansFirst! Due to our ongoing security measures, we
            may have to verify that you are the physical credit card holder. If
            that's the case, you <b>may</b> receive a text from{" "}
            <b>403.768.2298</b> asking for a photo of the card, emphasizing you
            block out <b>EVERYTHING</b> except the name and last four digits
            (three if AMEX). We already know these details, so the picture
            proves you physically hold the card.
          </>,
          {
            html: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={creditCardIcon}
                  alt="Credit Card Icon"
                  style={{ maxWidth: "200px", marginTop: "10px" }}
                />
              </div>
            ),
          }
        );
      }

      this.setState({ isLoading: false, checkoutSuccess: true });
      localStorage.removeItem("teSeatSplit");
      setTimeout(() => {
        this.props.history.push(`/thankyou/${order.id}`);
      }, 2500);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Sentry.captureMessage(error);
        Sentry.captureException(error);

        if (data.cardError) {
          const { failedTransactions } = this.state;

          const newFailedTxnsTotal = failedTransactions + 1;

          this.setState({
            failedTransactions: newFailedTxnsTotal,
          });

          localStorage.setItem("tft", JSON.stringify(newFailedTxnsTotal));

          if (failedTransactions === 2 && !isWhitelisted) {
            localStorage.setItem("ncdt", moment().add(1, "hours").valueOf());

            this.setState({
              checkoutAlert: true,
              alertTitle: "Too many failed transactions",
              alertContent:
                "You have too many failed transactions. Please try again later or call/text 1-403-768-2298 and we will help you get the seats you want via Interac e-transfer.",
              refresh: true,
              refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
            });

            /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
            return;
          }
          /* alert(data.message); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Error",
            alertContent: data.message,
            refresh: false,
          });
        } else if (data.invalidNumber) {
          /* alert("Please enter a valid phone number"); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Invalid Phone Number",
            alertContent:
              "Please enter a valid Candian mobile phone number (no VOIP or landlines).",
            refresh: true,
            refreshURL: `/games/${seat.game}/seats/${seat.seatId}?${teQuery}`,
          });
          this.setState({ isLoading: false, checkoutSuccess: false });
          /* this.props.history.push(`/games/${seat.game}/seats/${seat.seatId}`); */
          return;
        } else if (data.isBlocked) {
          this.setState({
            checkoutAlert: true,
            alertTitle: "Error",
            alertContent: data.message,
            refresh: true,
            refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
          });
        } else if (!data.isSeatAvailable) {
          /* alert(data.message); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Error",
            alertContent: data.message,
            refresh: true,
            refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
          });
          /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
        } else if (data.isVPN) {
          /* alert(data.message); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Error",
            alertContent: data.message,
            refresh: true,
            refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
          });
          /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
        }
      } else {
        this.setState({
          checkoutAlert: true,
          alertTitle: "Error",
          alertContent:
            "We are having troubles processing your order. Please call or text 1-403-768-2298 and we will help you get the seats you want.",
          refresh: false,
        });
      }

      this.setState({ isLoading: false, checkoutSuccess: false });
    }
  }
  calculateTotal(total, discount) {
    if (!discount) {
      return total;
    } else {
      return total - discount < 0 ? 0 : total - discount;
    }
  }
  
  renderWarningDialog() {
    const { showWarningDialog, teamName } = this.state;
    return (
      <Dialog
        open={showWarningDialog}
        onClose={() => this.setState({ showWarningDialog: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You Are Buying {teamName} Playoffs Tickets
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: ".875rem" }}
          >
            The date and time are all subject to change and will be
            updated as soon as the official schedule is release by the league.
            <br />
            <br />
            If the game you have purchased doesn't occur (the series ends early
            or the {teamName} don't make it to a particular round of the playoffs)
            then your transaction is cancelled and you get a full refund.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ showWarningDialog: false })}
            color="primary"
            autoFocus
          >
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDialog() {
    return (
      <Dialog
        open={this.state.msgOpen}
        onClose={() => this.setState({ msgOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Season Postponed</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Hi,
            <br />
            <br />
            Thanks again for using FansFirst. Your business and your trust in us
            means more than you know. Today the NHL postponsed the season. We
            don't know what this will mean yet as far as rescheduling vs
            cancellations. As soon as we know more we will let you know. Right
            now we need to focus on looking after our families, hockey will have
            to wait.
            <br />
            <br />
            As soon as games are officially cancelled we will begin processing
            refunds. As soon as we know more we will send additional updates.
            <br />
            <br />
            If you sold tickets on FansFirst we will contact you as soon as we
            have any additional information. We're doing our best. This is a
            crazy situation. Take care.
            <br />
            <br />
            All ticket holders should keep their existing tickets for games that
            have been suspended and they will be valid for the rescheduled
            dates.
            <br />
            <br />
            <br />
            <strong>The FansFirst Team</strong>
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
  // hey copilot, the top of the compononent is getting cutoff by the header of the layout
  // how do I fix this?
  //

  renderCheckoutAlert() {
    const { checkoutAlert, alertTitle, alertContent } = this.state;
    return (
      <Dialog open={checkoutAlert} onClose={this.toggleCheckoutAlert}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>{alertContent}</DialogContent>
        <DialogActions>
          <Button onClick={this.toggleCheckoutAlert} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  onCouponApplied = (coupon) => {
    this.setState({ coupon });
  };
  render() {
    const { classes } = this.props;
    const { seat, game, gameName, gameDate, loginFormOpen } = this.state;
    const { teId, teSeatSplit } = querySearch(this.props.location.search);
    const isLoadTE = teId && teId !== "";
    const currentURL = window.location.href;
    // Hey copilot the top of this page gets cut off by the layout header. How do I fix this?
    //
    /* console.log({ gameName, gameDate }); */
    return seat && game ? (
      <div className={classes.root}>
        <Helmet>
          <title>
            {gameName} &mdash; {gameDate}
          </title>
          <meta name="description" content={`Get your ticket for the ${gameName} - ${gameDate}`} />
          <meta name="keywords" content={`checkout, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets, ${gameName}`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content={`${gameName} &mdash; ${gameDate}`} />
          <meta property="og:description" content={`Get your ticket for the ${gameName} - ${gameDate}`} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Hidden mdUp>{this.renderBanner()}</Hidden>

        <main className={classes.main} style={{ position: "relative" }}>
          <Hidden mdUp>{this.renderMobileSeat()}</Hidden>

          <Hidden smDown>
            <CheckoutFormWeb
              game={game}
              seat={seat}
              onSubmit={this.handleCheckout}
              isLoading={this.state.isLoading}
              teSeatSplit={teSeatSplit}
              checkoutSuccess={this.state.checkoutSuccess}
              onCouponApplied={this.onCouponApplied}
              isAuthenticated={this.props.isAuthenticated}
              currUser={this.props.currUser}
              handleLoginForm={this.handleLoginForm}
            />
            {this.renderWarningDialog()}
            {this.renderCheckoutAlert()}
            {/* {this.renderDialog()} */}
            {loginFormOpen && (
              <LoginForm
                isAuthenticated={this.props.isAuthenticated}
                currUser={this.props.currUser}
                handleLogout={this.props.handleLogout}
                isOpen={loginFormOpen}
                handleClose={this.handleLoginForm}
                {...Object.fromEntries(Object.entries(_.omit(this.props, 'classes')))}
              />
            )}
          </Hidden>
        </main>

        <SeatMap
          open={this.state.seatMapOpen}
          onClose={this.toggleSeatMap}
          team={game.homeTeamSlug}
          isSpecialGame={game.isSpecial}
        />
      </div>
    ) : (
      <div className={classes.loadingContainer}>
        <Typography variant="display1" gutterBottom align="center">
          Loading...
        </Typography>
      </div>
    );
  }

  componentDidMount() {
    const randomArray = randomIntFromInterval(checkoutReviewsData,2);
    this.setState({
      dataReviews: randomArray,
    });
  }
}

export default withStyles(styles)(Ticket);
