import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, withStyles } from "@material-ui/core";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import BlogCard from "./blogCard";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
    container: {
        padding: "30px 300px 30px 300px"
    },
    [theme.breakpoints.down("md")]: {
        container: {
            padding: "60px 30px 30px 30px"
        },
        title: {
            marginTop: '2rem',
            marginBottom: '2rem',
            fontSize: '2.5rem',
            fontWeight: "bold",
            fontFamily: "Nunito Sans",
        },
    },
    [theme.breakpoints.up("md")]: {
        title: {
            marginTop: '2rem',
            marginBottom: '5rem',
            fontSize: '3rem',
            fontWeight: "bold",
            fontFamily: "Nunito Sans",
        },
    }
})

const Blogs = ({
    classes
}) => {
    const [blogUrls, setBlogUrls] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        fetchUrls()
    }, []);

    async function fetchUrls() {
        try {
        const allBlogUrls = await API.get(
            "v2",
            `blogs/get/all`
        );
        setBlogUrls(allBlogUrls)
        setLoading(false)
        } catch (err) { console.log('error fetching blog URLs: ', err) }
    }

    if (loading) {
        return (
            <React.Fragment>
                <Typography
                    align="center"
                    variant="body2"
                    style={{ marginTop: "2rem" }}
                >
                    <CircularProgress
                    color="inherit"
                    style={{ width: 16, height: 16, marginRight: 16 }}
                    />
                    Loading blogs...
                </Typography>
            </React.Fragment>
        )
    }
    const currentURL = window.location.href;

    return (
        <div className={classes.container}>
            <Helmet>
                <title>
                    FansFirst - All Blog Posts
                </title>
                <meta name="description" content="FansFirst - All Blog Posts" />
                <meta name="keywords" content={`blogs, games, tickets, buy tickets, no fees, buy cheap NHL/NBA/MLB/CFL tickets`} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="FansFirst - All Blog Posts" />
                <meta property="og:description" content="FansFirst - All Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentURL} />
                <link rel="canonical" href={currentURL} />
            </Helmet>
            <Typography
                variant='headline'
                align='left'
                color='error'
                className={classes.title}
            >
                <span>ALL BLOG POSTS</span>
            </Typography>
            <Grid container spacing={40}>
                {blogUrls.map(url => (
                    <Grid item key={url} xs={12} lg={6}>
                        <BlogCard source={url}></BlogCard>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
export default withStyles(styles)(Blogs);
