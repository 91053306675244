export const checkoutReviewsData = [
    {
        "name": "Jamie",
        "profile_pic": "",
        "comments": "Thanks! We have never had an issue before. We love your site!"
    },
    {
        "name": "Richard",
        "profile_pic": "",
        "comments": "Thanks for your deligent effort to resolve this issue. Happy customer. Thanks"
    },
    {
        "name": "Moh",
        "profile_pic": "",
        "comments": "It's my first time using your site and btw I really loved how easy it is to purchase through you guys."
    },
    {
        "name": "Mohsin",
        "profile_pic": "",
        "comments": "Wow! Awesome customer service"
    },
    {
        "name": "Gille",
        "profile_pic": "",
        "comments": "I got the tickets your customer service is off the charts I will Definitely recommend your site to Lots of people I already do cause of the great prices"
    },
    {
        "name": "SEAN",
        "profile_pic": "",
        "comments": "Amazing. Thanks so much. Great customer service! I'm incredibly impressed!"
    },
    {
        "name": "James",
        "profile_pic": "",
        "comments": "Just got them! appreciate it so much immaculate customer service, definitely a customer for life"
    },
    {
        "name": "Ryan",
        "profile_pic": "",
        "comments": "You guys have the best customer service!!"
    },
    {
        "name": "Jack",
        "profile_pic": "",
        "comments": "Great site and service. Thanks again"
    },
    {
        "name": "Chris",
        "profile_pic": "",
        "comments": "By the way great customer service ...very quick and great job. Love your site and it's my go to!"
    },
    {
        "name": "Mitchell",
        "profile_pic": "",
        "comments": "It's greatly appreciated! Thank you. Means a lot to us. The service on this site is amazing. That's why we keep coming back"
    },
    {
        "name": "Mark",
        "profile_pic": "",
        "comments": "Great site and reasonable pricing"
    },
    {
        "name": "Lachlan",
        "profile_pic": "",
        "comments": "My card has been having issues so my friend just got the tickets. But seriously this is the best online customer service ive ever got you're incredible"
    },
    {
        "name": "Len",
        "profile_pic": "",
        "comments": "Thank you for the outstanding service"
    },
    {
        "name": "Harmony",
        "profile_pic": "",
        "comments": "Awesome, thank you so much! I really appreciate the quick resolution, you guys are the best"
    },
    {
        "name": "Jarred",
        "profile_pic": "",
        "comments": "Just wanted to let you know I received my tickets. Thanks again for following up and the incredible customer service. I really appreciate it"
    },
    {
        "name": "Tanner",
        "profile_pic": "",
        "comments": "Love your website and love not having to use Ticketmaster or other sites."
    },
    {
        "name": "Shane",
        "profile_pic": "",
        "comments": "Awesome thanks! Makes sense. I bought tickets through you guys aplenty over the last 2 years. Love your site!"
    },
    {
        "name": "Curtis",
        "profile_pic": "",
        "comments": "Got them. Thankyou. First time user. Very impressed with the customer service"
    },
    {
        "name": "Dean",
        "profile_pic": "",
        "comments": "Thank you now that is service nice work!!"
    },
    {
        "name": "Erin",
        "profile_pic": "",
        "comments": "FYI, fans first is by far the best to purchase Canadian tickets."
    },
    {
        "name": "Nebiy",
        "profile_pic": "",
        "comments": "You guys are the best and are now my go to for tickets"
    },
    {
        "name": "Jennifer",
        "profile_pic": "",
        "comments": "Thank you so much! I really appreciate your hard work as this is a really special treat for our family. I would be happy to give a positive recommendation to others looking to purchase through fans first."
    },
    {
        "name": "Corey",
        "profile_pic": "",
        "comments": "Excellent!!! Love the great work you guys do!!! Go Flames Go! 🔥🔥🔥"
    },
    {
        "name": "Corey",
        "profile_pic": "",
        "comments": "You guys know what your doing thanks so much love buying tickets here"
    },
    {
        "name": "Brock MB",
        "profile_pic": "",
        "comments": "Amazing value for fantastic seats to see the Jets! I won't buy anywhere else moving forward!"
    },
    {
        "name": "Casey Toews",
        "profile_pic": "",
        "comments": "The best service I have gotten. Bought oilers tickets and the seller changed his mind. Fan first called me and offered me a full rebate or other seats, I decided to except different seats and not only were they more expensive but fan first covered the difference. You guys have a client for life. 5 star service guys keep up the great customer service."
    },
    {
        "name": "Topher Laiye",
        "profile_pic": "",
        "comments": "I was really happy to find this site, and it's helped me to secure tickets to games at a much more favorable price than what's listed on Ticketmaster.  I also got to speak with Dave and I'm impressed that there are humans behind the scenes trying to keep this a great experience for hockey fans. Thanks so much for creating this platform!"
    },
    {
        "name": "Alexander Bell",
        "profile_pic": "",
        "comments": "Was a little hesitant to give that place a try because it looked too good to be true. Took a shot and found the nearly F/V tickets the day of Game 4 in the Battle of Alberta. Had them in my inbox within half an hour ( I did contact support to follow up so maybe not EVERYONES would be as quick)"
    },
    {
        "name": "Rooven Maharaj",
        "profile_pic": "",
        "comments": "One of the Best sites to list your tickets. Fantastic Sevice, Fantastic Prices!! FANSFIRST!! 5 stars"
    },
    {
        "name": "Jas Sahota",
        "profile_pic": "",
        "comments": "Excellent for both buyers and sellers. Their support phone number is always available. Highly recommend selling as you set the price."
    },
    {
        "name": "Nick Czuba",
        "profile_pic": "",
        "comments": "fast, excellent service! have used a few times without any problems! thanks"
    },
    {
        "name": "Travis Dyck",
        "profile_pic": "",
        "comments": "Just bought a pair of Canucks tickets from fansfirst.ca and it was a smooth transaction.  Customer service from chat was great and would highly recommend!"
    },
    {
        "name": "Bob Mellin",
        "profile_pic": "",
        "comments": "Just got my first tickets from FansFirst.ca and it won’t be my last. Super happy."
    },
    {
        "name": "Natalie Lynne Rietze",
        "profile_pic": "",
        "comments": "Such great prices and really great seats, Definitely going to buy tickets again."
    },
    {
        "name": "Dan Jefkins",
        "profile_pic": "",
        "comments": "Awesome seats at our jets game. 1/2 the price of ticket master. I’ll be a regular customer from now on."
    },
    {
        "name": "Nicole Smith-Marshall",
        "profile_pic": "",
        "comments": "Great experience. Great price. Great seats. Easy transaction. Will only buy my tickets here. Thank you!"
    },
    {
        "name": "Yufen Chiu",
        "profile_pic": "",
        "comments": "Would recommend. Great price, great seat , great experience... will buy my next ticket here again 👍👍👍👍"
    },
    {
        "name": "Amanda Rae",
        "profile_pic": "",
        "comments": "Quick. Easy. Good price. Will use again!"
    },
    {
        "name": "Kelly McQuiggan",
        "profile_pic": "",
        "comments": "Awesome customer service. Excellent ease of use website. Quick payments for tickets once sold. I've sold three games through them, and each time has been great. Thanks!"
    },
    {
        "name": "Sylvain Lauzon",
        "profile_pic": "",
        "comments": "Great deals on tickets with no fees! Super easy to use. Went to two games this week in the 200’s for $65 gotta check it out"
    },
    {
        "name": "Becky Gach",
        "profile_pic": "",
        "comments": "My go-to for tickets over the past year, and my only source moving forward!! I'm over paying stupid fees and charges over and above the ticket price. Plus their customer service is amazing- going above and beyond for buyers as soon as there is anything holding the purchase up!"
    },
    {
        "name": "Steven Bui",
        "profile_pic": "",
        "comments": "FanFirst makes it really easy to purchase tickets at a reduced price without all of the transactional fees. The process is very smooth and I completed my purchase in a matter of minutes. I had all of my questions answered quickly by their support as well! Really recommend FanFirst! They are legit and safe."
    },
    {
        "name": "Connie Paskemin",
        "profile_pic": "",
        "comments": "Super easy! Like that it compares prices and what you save 😊😊 definitely would use again!"
    },
    {
        "name": "Declan Hughes",
        "profile_pic": "",
        "comments": "Would recommend! All left and super helpful! Also deadly cheap tickets 🤤"
    },
    {
        "name": "Jonathan McMaster",
        "profile_pic": "",
        "comments": "Great site. Cut out Ticketmaster robbery!!"
    },
    {
        "name": "Ted Thorne",
        "profile_pic": "",
        "comments": "Super easy to use the site, got some great seats and there were NO extra fees!! Price listed is what I paid for them. Awesome!!!!"
    },
    {
        "name": "Miki PA",
        "profile_pic": "",
        "comments": "Purchased two tickets, lower bowl row 4 and had a blast. Easy, no hassle purchase with quick turn around to get the tickets. I will definitely use Fansfirst.ca in the future. Saved so much money compared to StubHub and Seatgeek."
    },
    {
        "name": "Anoop Kahlon",
        "profile_pic": "",
        "comments": "Great website no unnecessary fees and not in US currency."
    },
    {
        "name": "Logan Bandy",
        "profile_pic": "",
        "comments": "Was also tentative at first, but decided to get our game 1 tickets off of fan first. We had no problem at all and we’ll definitely use them again for the amazing prices"
    },
    {
        "name": "Alex Reid",
        "profile_pic": "",
        "comments": "Runs super smooth and the prices are a whole lot better than elsewhere"
    },
    {
        "name": "Christina Stevens",
        "profile_pic": "",
        "comments": "Just left the flames vs leafs game with tickets we bought off you. 2 lower bowl row 6 tickets for 500 canadian,  no extra fees. only using your site from now on! thanks"
    },
    {
        "name": "Matty Dubs",
        "profile_pic": "",
        "comments": "This was a great deal for getting tickets. I hope people keep selling on this site so I can score more good seats."
    },
    {
        "name": "Julio Hidalgo",
        "profile_pic": "",
        "comments": "great price for every flames fan. thank u."
    },
    {
        "name": "Edgardo Valenzuela",
        "profile_pic": "",
        "comments": "Bought tickets through this site for the Jan 9th game vs Colorado. Payment is made through your credit card and therefore I felt confident that if something went wrong I’d be covered. The tickets were transferred through ticketmaster and were in my apple wallet (see attached) within an hour. At the Dome I just scanned the tickets on my phone without any issues. The charge on my credit card was the exact price quoted, no fees or US exchange."
    },
    {
        "name": "Jesse Westman",
        "profile_pic": "",
        "comments": "Fast/Cheap Tickets, what more do you want?"
    },
]