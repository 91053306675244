import React, { Component } from "react";
import { Auth } from "aws-amplify";

import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  [theme.breakpoints.up("md")]: {
    formContainer: {
      margin: "5rem 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    formContainer: {
      marginTop: "3rem",
      padding: "0 1rem",
    },
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  button: {
    padding: ".875rem",
    marginTop: ".5rem",
  },
  successMessage: {
    marginTop: "1rem",
    marginBottom: "1rem",
    color: "#4caf50",
  },
});

class AccountVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      isValidCode: true,
      isSubmitting: false,
      isVerified: false,
      isResending: false,
      isResent: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendCode = this.resendCode.bind(this);
  }

  async componentDidMount() {}

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { code } = this.state;

    if (code === "") {
      this.setState({ isValidCode: false, isResent: false });
      return;
    }

    try {
      this.setState({
        isSubmitting: true,
        isResent: false,
      });

      await Auth.verifyCurrentUserAttributeSubmit("phone_number", code);

      this.setState({
        isSubmitting: false,
        isVerified: true,
      });

      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      this.props.setUserAttributes(user);
    } catch (e) {
      this.setState({
        isSubmitting: false,
      });

      if (e.code === "CodeMismatchException") {
        alert("Invalid code. Please try again.");
      } else {
        alert("Sorry, something went wrong. Please try again later.");
      }
    }
  }

  async resendCode(e) {
    try {
      this.setState({
        isResending: true,
      });

      await Auth.verifyCurrentUserAttribute("phone_number");

      this.setState({
        isResending: false,
        isResent: true,
      });
    } catch (e) {

      this.setState({
        isResending: false,
        isResent: true,
      });
      alert("Sorry, something went wrong. Please try again later.");
    }
  }

  renderForm() {
    const { classes, currUser } = this.props;
    const { isSubmitting, isResent, isResending, isVerified } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Typography
          style={{
            marginBottom: "1rem",
          }}
          variant="title"
          color="textPrimary"
        >
          Verify Your Mobile Number
        </Typography>
        <Typography variant="caption">
          Please check your mobile number ({currUser.phone_number}) for the
          verification code.
        </Typography>
        <Typography variant="caption" style={{ marginBottom: "1.5rem" }}>
          <a
            href="/account"
            style={{ color: "#2196f3", textDecoration: "none" }}
          >
            Click here to update your mobile number
          </a>
          .
        </Typography>

        {isResent && (
          <Typography
            variant="caption"
            align="center"
            className={classes.successMessage}
          >
            We have sent a verification code to your phone. Please enter it
            below.
          </Typography>
        )}
        <TextField
          autoFocus
          className={
            this.state.isValidCode ? classes.textField : classes.textFieldError
          }
          disabled={isSubmitting || isVerified}
          error={true}
          id="code"
          placeholder="Verification Code"
          name="code"
          fullWidth={true}
          value={this.state.code}
          onChange={this.handleChange}
          margin="dense"
          InputProps={{
            disableUnderline: true,
          }}
        />

        {this.state.isValidCode ? (
          ""
        ) : (
          <FormHelperText error={true}>
            Please enter the code that we sent to your mobile no.
          </FormHelperText>
        )}

        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting || isVerified}
          variant="contained"
          fullWidth={true}
          onClick={this.handleSubmit}
          type="submit"
        >
          Submit
        </Button>
        <Button
          disableFocusRipple={true}
          className={classes.button}
          disabled={isResending || isVerified}
          variant="text"
          fullWidth={true}
          onClick={this.resendCode}
        >
          Resend Code
        </Button>
        {isVerified && (
          <Typography
            variant="caption"
            align="center"
            className={classes.successMessage}
          >
            Your mobile number is now verified.{" "}
            <a href="/listings" style={{ color: "#4caf50" }}>
              Click here to go back to your listings
            </a>
            .
          </Typography>
        )}
      </form>
    );
  }

  render() {
    const { classes, currUser } = this.props;

    return (
      <div>
        <Grid
          className={classes.formContainer}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            {!currUser.phone_number_verified && this.renderForm()}
            {currUser.phone_number_verified && (
              <React.Fragment>
                <Typography
                  style={{
                    marginBottom: "1.5rem",
                  }}
                  variant="title"
                  color="textPrimary"
                >
                  Your mobile number is already verified.
                </Typography>
                <Typography variant="caption">
                  <a href="/listings" style={{ color: "#4caf50" }}>
                    Click here to go back to your listings
                  </a>
                  .
                </Typography>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AccountVerification);
