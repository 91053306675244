export const getZones = (rsr) => {
    let zones = [];
    var _JimBeamSocial = {
      path:
        "M 68.00,752.00 C 68.00,752.00 123.00,768.00 123.00,768.00 123.00,768.00 111.00,855.00 111.00,855.00 111.00,855.00 111.00,1005.00 111.00,1005.00 111.00,1005.00 110.00,1020.00 110.00,1020.00 110.00,1020.00 110.00,1099.00 110.00,1099.00 110.00,1099.00 110.00,1156.00 110.00,1156.00 110.01,1165.41 112.52,1176.55 114.08,1186.00 114.08,1186.00 120.25,1223.00 120.25,1223.00 120.71,1225.79 123.02,1237.89 122.62,1239.78 121.84,1243.45 114.20,1244.98 111.00,1245.77 111.00,1245.77 75.00,1256.00 75.00,1256.00 71.35,1244.95 65.45,1206.14 63.72,1193.00 63.72,1193.00 56.91,1126.00 56.91,1126.00 56.91,1126.00 55.00,1101.00 55.00,1101.00 55.00,1101.00 53.96,1080.00 53.96,1080.00 53.96,1080.00 53.96,1064.00 53.96,1064.00 53.96,1064.00 53.00,1052.00 53.00,1052.00 53.00,1052.00 53.00,975.00 53.00,975.00 53.00,975.00 54.00,960.00 54.00,960.00 54.00,960.00 54.00,945.00 54.00,945.00 54.00,945.00 54.96,934.00 54.96,934.00 54.96,934.00 54.96,923.00 54.96,923.00 54.96,923.00 56.00,908.00 56.00,908.00 56.00,908.00 58.91,860.00 58.91,860.00 58.91,860.00 64.17,792.00 64.17,792.00 64.17,792.00 68.00,752.00 68.00,752.00 Z",
      attr: {
        id: "JimBeamSocial",
        "data-name": "JimBeamSocial",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "", zone: "Jim Beam Social", zoneId: "1" },
    };
    var _101 = {
      path:
        "M 331.00,450.00 C 331.00,450.00 340.19,472.00 340.19,472.00 340.19,472.00 356.74,515.00 356.74,515.00 356.74,515.00 391.74,606.00 391.74,606.00 391.74,606.00 412.00,658.00 412.00,658.00 412.00,658.00 384.00,665.71 384.00,665.71 381.14,666.53 373.74,668.97 371.09,668.23 368.17,667.41 364.55,662.36 362.59,660.00 362.59,660.00 345.25,639.00 345.25,639.00 345.25,639.00 279.08,559.00 279.08,559.00 279.08,559.00 248.58,522.00 248.58,522.00 246.38,519.33 238.59,511.52 239.45,508.17 239.97,506.14 243.37,503.87 245.00,502.63 245.00,502.63 261.00,491.14 261.00,491.14 283.22,475.31 307.40,463.44 331.00,450.00 Z",
      attr: {
        id: "_101",
        "data-name": "101",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "101", zone: "Lower Zone", zoneId: "2" },
    };
    var _102 = {
      path:
        "M 446.00,407.00 C 446.00,407.00 451.26,431.00 451.26,431.00 451.26,431.00 460.46,480.00 460.46,480.00 460.46,480.00 480.42,586.00 480.42,586.00 480.42,586.00 490.00,639.00 490.00,639.00 490.00,639.00 428.00,654.00 428.00,654.00 428.00,654.00 378.26,525.00 378.26,525.00 378.26,525.00 359.86,477.00 359.86,477.00 359.86,477.00 347.00,443.00 347.00,443.00 362.00,435.22 393.52,423.27 410.00,418.03 410.00,418.03 446.00,407.00 446.00,407.00 Z",
      attr: {
        id: "_102",
        "data-name": "102",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "102", zone: "Lower Zone", zoneId: "2" },
    };
    var _103 = {
      path:
        "M 463.00,403.00 C 482.29,397.42 535.96,387.72 556.00,386.17 564.47,385.52 563.59,392.36 564.75,399.00 564.75,399.00 572.26,439.00 572.26,439.00 572.26,439.00 606.00,617.00 606.00,617.00 591.20,617.13 550.31,625.54 534.00,628.80 534.00,628.80 515.00,632.86 515.00,632.86 513.01,633.21 509.37,634.19 507.59,632.86 505.52,631.47 504.28,620.87 503.74,618.00 503.74,618.00 495.88,577.00 495.88,577.00 495.88,577.00 485.39,521.00 485.39,521.00 485.39,521.00 463.00,403.00 463.00,403.00 Z",
      attr: {
        id: "_103",
        "data-name": "103",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "103", zone: "Lower Zone", zoneId: "2" },
    };
    var _104 = {
      path:
        "M 579.00,384.00 C 579.00,384.00 604.00,381.83 604.00,381.83 604.00,381.83 626.00,380.00 626.00,380.00 626.00,380.00 655.00,380.00 655.00,380.00 655.00,380.00 667.00,379.04 667.00,379.04 667.00,379.04 680.00,379.04 680.00,379.04 680.00,379.04 680.00,610.00 680.00,610.00 680.00,610.00 654.00,610.00 654.00,610.00 654.00,610.00 622.00,614.00 622.00,614.00 622.00,614.00 615.00,575.00 615.00,575.00 615.00,575.00 605.42,524.00 605.42,524.00 605.42,524.00 587.46,429.00 587.46,429.00 587.46,429.00 579.00,384.00 579.00,384.00 Z",
      attr: {
        id: "_104",
        "data-name": "104",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "104", zone: "Lower Zone", zoneId: "2" },
    };
    var _105 = {
      path: "M 697.00,379.00 C 697.00,379.00 798.00,379.00 798.00,379.00 798.00,379.00 798.00,610.00 798.00,610.00 798.00,610.00 697.00,610.00 697.00,610.00 697.00,610.00 697.00,379.00 697.00,379.00 Z",
      attr: {
        id: "_105",
        "data-name": "105",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "105", zone: "Lower Zone", zoneId: "2" },
    };
    var _106 = {
      path: "M 815.00,379.00 C 815.00,379.00 882.00,379.00 882.00,379.00 882.00,379.00 882.00,609.00 882.00,609.00 882.00,609.00 815.00,609.00 815.00,609.00 815.00,609.00 815.00,379.00 815.00,379.00 Z",
      attr: {
        id: "_106",
        "data-name": "106",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "106", zone: "Lower Zone", zoneId: "2" },
    };
    var _107 = {
      path: "M 899.00,379.00 C 899.00,379.00 984.00,379.00 984.00,379.00 984.00,379.00 984.00,609.00 984.00,609.00 984.00,609.00 899.00,609.00 899.00,609.00 899.00,609.00 899.00,379.00 899.00,379.00 Z",
      attr: {
        id: "_107",
        "data-name": "107",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "107", zone: "Lower Zone", zoneId: "2" },
    };
    var _108 = {
      path:
        "M 1001.00,379.00 C 1001.00,379.00 1020.00,379.00 1020.00,379.00 1020.00,379.00 1036.00,378.00 1036.00,378.00 1036.00,378.00 1090.00,378.00 1090.00,378.00 1090.00,378.00 1090.00,609.00 1090.00,609.00 1090.00,609.00 1001.00,609.00 1001.00,609.00 1001.00,609.00 1001.00,379.00 1001.00,379.00 Z",
      attr: {
        id: "_108",
        "data-name": "108",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "108", zone: "Lower Zone", zoneId: "2" },
    };
    var _109 = {
      path: "M 1107.00,378.00 C 1107.00,378.00 1174.00,378.00 1174.00,378.00 1174.00,378.00 1174.00,608.00 1174.00,608.00 1174.00,608.00 1107.00,608.00 1107.00,608.00 1107.00,608.00 1107.00,378.00 1107.00,378.00 Z",
      attr: {
        id: "_109",
        "data-name": "109",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "109", zone: "Lower Zone", zoneId: "2" },
    };
    var _110 = {
      path: "M 1191.00,378.00 C 1191.00,378.00 1291.00,378.00 1291.00,378.00 1291.00,378.00 1291.00,608.00 1291.00,608.00 1291.00,608.00 1191.00,608.00 1191.00,608.00 1191.00,608.00 1191.00,378.00 1191.00,378.00 Z",
      attr: {
        id: "_110",
        "data-name": "110",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "110", zone: "Lower Zone", zoneId: "2" },
    };
    var _111 = {
      path:
        "M 1308.00,378.00 C 1308.00,378.00 1366.00,378.00 1366.00,378.00 1366.00,378.00 1389.00,379.83 1389.00,379.83 1389.00,379.83 1409.00,382.00 1409.00,382.00 1409.00,382.00 1377.46,553.00 1377.46,553.00 1377.46,553.00 1366.00,615.00 1366.00,615.00 1366.00,615.00 1326.00,607.00 1326.00,607.00 1326.00,607.00 1308.00,608.00 1308.00,608.00 1308.00,608.00 1308.00,378.00 1308.00,378.00 Z",
      attr: {
        id: "_111",
        "data-name": "111",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "111", zone: "Lower Zone", zoneId: "2" },
    };
    var _112 = {
      path:
        "M 1426.00,384.00 C 1426.00,384.00 1489.00,395.42 1489.00,395.42 1489.00,395.42 1525.00,404.00 1525.00,404.00 1525.00,404.00 1513.61,468.00 1513.61,468.00 1513.61,468.00 1491.54,587.00 1491.54,587.00 1491.54,587.00 1482.00,640.00 1482.00,640.00 1482.00,640.00 1383.00,619.00 1383.00,619.00 1383.00,619.00 1388.58,587.00 1388.58,587.00 1388.58,587.00 1399.74,526.00 1399.74,526.00 1399.74,526.00 1417.54,430.00 1417.54,430.00 1417.54,430.00 1426.00,384.00 1426.00,384.00 Z",
      attr: {
        id: "_112",
        "data-name": "112",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "112", zone: "Lower Zone", zoneId: "2" },
    };
    var _113 = {
      path:
        "M 1542.00,409.00 C 1542.00,409.00 1612.00,433.40 1612.00,433.40 1612.00,433.40 1629.00,440.58 1629.00,440.58 1630.97,441.42 1634.81,442.81 1635.97,444.64 1637.86,447.63 1633.62,455.82 1632.42,459.00 1632.42,459.00 1617.14,499.00 1617.14,499.00 1617.14,499.00 1610.94,516.00 1610.94,516.00 1610.94,516.00 1580.26,597.00 1580.26,597.00 1580.26,597.00 1558.00,656.00 1558.00,656.00 1558.00,656.00 1498.00,644.00 1498.00,644.00 1498.00,644.00 1525.58,496.00 1525.58,496.00 1525.58,496.00 1535.58,442.00 1535.58,442.00 1535.58,442.00 1542.00,409.00 1542.00,409.00 Z",
      attr: {
        id: "_113",
        "data-name": "113",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "113", zone: "Lower Zone", zoneId: "2" },
    };
    var _114 = {
      path:
        "M 1652.00,452.00 C 1659.10,454.02 1673.07,461.89 1680.00,465.70 1706.86,480.44 1725.28,493.45 1750.00,511.00 1750.00,511.00 1715.08,554.00 1715.08,554.00 1715.08,554.00 1654.59,627.00 1654.59,627.00 1654.59,627.00 1629.71,657.00 1629.71,657.00 1629.71,657.00 1617.83,668.31 1617.83,668.31 1617.83,668.31 1605.00,666.40 1605.00,666.40 1605.00,666.40 1574.00,660.00 1574.00,660.00 1574.00,660.00 1585.26,630.00 1585.26,630.00 1585.26,630.00 1605.26,577.00 1605.26,577.00 1605.26,577.00 1623.14,529.00 1623.14,529.00 1623.14,529.00 1640.81,483.00 1640.81,483.00 1640.81,483.00 1652.00,452.00 1652.00,452.00 Z",
      attr: {
        id: "_114",
        "data-name": "114",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "114", zone: "Lower Zone", zoneId: "2" },
    };
    var _115 = {
      path:
        "M 1762.00,522.00 C 1769.10,525.03 1775.29,531.69 1781.00,536.83 1790.53,545.41 1806.37,560.54 1814.58,570.00 1814.58,570.00 1825.06,582.00 1825.06,582.00 1826.39,583.72 1828.10,585.63 1827.55,587.99 1827.01,590.29 1823.79,592.76 1822.00,594.18 1822.00,594.18 1807.00,605.39 1807.00,605.39 1807.00,605.39 1749.00,651.84 1749.00,651.84 1749.00,651.84 1706.00,686.92 1706.00,686.92 1702.98,689.42 1692.36,699.12 1689.00,698.89 1685.76,698.67 1679.42,691.42 1677.00,689.00 1677.00,689.00 1647.00,661.00 1647.00,661.00 1647.00,661.00 1713.41,581.00 1713.41,581.00 1713.41,581.00 1742.41,546.00 1742.41,546.00 1742.41,546.00 1762.00,522.00 1762.00,522.00 Z",
      attr: {
        id: "_115",
        "data-name": "115",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "115", zone: "Lower Zone", zoneId: "2" },
    };
    var _116 = {
      path:
        "M 1702.00,796.00 C 1702.00,796.00 1702.00,779.00 1702.00,779.00 1702.00,779.00 1701.04,767.00 1701.04,767.00 1701.04,767.00 1701.04,754.00 1701.04,754.00 1701.04,754.00 1700.04,742.00 1700.04,742.00 1700.04,742.00 1700.04,729.00 1700.04,729.00 1699.99,725.02 1698.45,716.07 1700.04,713.29 1701.29,711.04 1705.88,707.52 1708.00,705.80 1708.00,705.80 1724.00,692.75 1724.00,692.75 1724.00,692.75 1754.00,668.21 1754.00,668.21 1754.00,668.21 1763.00,661.83 1763.00,661.83 1763.00,661.83 1786.00,643.08 1786.00,643.08 1786.00,643.08 1826.00,611.23 1826.00,611.23 1828.95,609.15 1836.58,601.63 1839.91,602.45 1842.36,603.06 1845.61,607.96 1847.12,610.00 1847.12,610.00 1862.00,632.00 1862.00,632.00 1877.16,655.00 1891.07,683.43 1901.40,709.00 1901.40,709.00 1911.00,735.00 1911.00,735.00 1911.00,735.00 1897.00,738.96 1897.00,738.96 1897.00,738.96 1866.00,748.26 1866.00,748.26 1866.00,748.26 1749.00,782.74 1749.00,782.74 1749.00,782.74 1702.00,796.00 1702.00,796.00 Z",
      attr: {
        id: "_116",
        "data-name": "116",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "116", zone: "Lower Zone", zoneId: "2" },
    };
    var _117 = {
      path:
        "M 1705.00,879.00 C 1705.00,879.00 1704.01,833.00 1704.01,833.00 1704.01,833.00 1704.01,814.51 1704.01,814.51 1704.01,814.51 1717.00,809.34 1717.00,809.34 1717.00,809.34 1754.00,798.58 1754.00,798.58 1754.00,798.58 1854.00,768.96 1854.00,768.96 1854.00,768.96 1916.00,751.00 1916.00,751.00 1916.00,751.00 1919.63,764.00 1919.63,764.00 1924.24,782.21 1933.97,823.47 1934.00,841.00 1934.00,841.00 1934.00,879.00 1934.00,879.00 1934.00,879.00 1705.00,879.00 1705.00,879.00 Z",
      attr: {
        id: "_117",
        "data-name": "117",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "117", zone: "Lower Zone", zoneId: "2" },
    };
    var _118 = {
      path:
        "M 1706.00,896.00 C 1706.00,896.00 1934.00,896.00 1934.00,896.00 1934.00,896.00 1934.00,998.00 1934.00,998.00 1934.00,998.00 1822.00,998.00 1822.00,998.00 1822.00,998.00 1821.00,983.00 1821.00,983.00 1821.00,983.00 1753.00,983.00 1753.00,983.00 1753.00,983.00 1736.00,982.00 1736.00,982.00 1736.00,982.00 1707.00,981.00 1707.00,981.00 1707.00,981.00 1707.00,931.00 1707.00,931.00 1707.00,931.00 1706.00,916.00 1706.00,916.00 1706.00,916.00 1706.00,896.00 1706.00,896.00 Z",
      attr: {
        id: "_118",
        "data-name": "118",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "118", zone: "Lower Zone", zoneId: "2" },
    };
    var _119 = {
      path:
        "M 1822.00,1015.00 C 1822.00,1015.00 1934.00,1015.00 1934.00,1015.00 1934.00,1015.00 1934.00,1122.00 1934.00,1122.00 1934.00,1122.00 1704.00,1122.00 1704.00,1122.00 1704.00,1122.00 1704.00,1076.00 1704.00,1076.00 1704.00,1076.00 1705.00,1060.00 1705.00,1060.00 1705.00,1060.00 1705.00,1031.00 1705.00,1031.00 1705.00,1031.00 1822.00,1031.00 1822.00,1031.00 1822.00,1031.00 1822.00,1015.00 1822.00,1015.00 Z",
      attr: {
        id: "_119",
        "data-name": "119",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "119", zone: "Lower Zone", zoneId: "2" },
    };
    var _120 = {
      path:
        "M 1703.96,1139.00 C 1703.96,1139.00 1934.00,1139.00 1934.00,1139.00 1934.00,1139.00 1934.00,1175.00 1934.00,1175.00 1933.97,1192.05 1926.91,1223.87 1922.63,1241.00 1922.63,1241.00 1918.00,1259.00 1918.00,1259.00 1918.00,1259.00 1893.00,1252.34 1893.00,1252.34 1893.00,1252.34 1862.00,1243.02 1862.00,1243.02 1862.00,1243.02 1779.00,1218.26 1779.00,1218.26 1779.00,1218.26 1752.00,1210.00 1752.00,1210.00 1752.00,1210.00 1721.00,1200.48 1721.00,1200.48 1721.00,1200.48 1704.60,1195.07 1704.60,1195.07 1704.60,1195.07 1703.00,1187.00 1703.00,1187.00 1703.00,1187.00 1703.00,1167.00 1703.00,1167.00 1703.00,1167.00 1703.96,1155.00 1703.96,1155.00 1703.96,1155.00 1703.96,1139.00 1703.96,1139.00 Z",
      attr: {
        id: "_120",
        "data-name": "120",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "120", zone: "Lower Zone", zoneId: "2" },
    };
    var _121 = {
      path:
        "M 1702.00,1213.00 C 1702.00,1213.00 1728.00,1220.00 1728.00,1220.00 1728.00,1220.00 1739.00,1223.04 1739.00,1223.04 1739.00,1223.04 1761.00,1229.96 1761.00,1229.96 1761.00,1229.96 1862.00,1260.04 1862.00,1260.04 1862.00,1260.04 1892.00,1269.04 1892.00,1269.04 1892.00,1269.04 1912.00,1275.00 1912.00,1275.00 1910.81,1282.57 1901.29,1305.99 1897.85,1314.00 1886.63,1340.11 1872.41,1367.34 1856.67,1391.00 1856.67,1391.00 1839.87,1415.00 1839.87,1415.00 1838.20,1417.23 1834.44,1422.90 1831.91,1423.70 1828.61,1424.75 1824.35,1420.37 1822.00,1418.41 1822.00,1418.41 1798.00,1398.59 1798.00,1398.59 1798.00,1398.59 1719.00,1331.80 1719.00,1331.80 1719.00,1331.80 1698.00,1314.41 1698.00,1314.41 1695.94,1312.70 1690.02,1308.28 1689.32,1305.91 1688.51,1303.17 1691.35,1300.11 1692.80,1298.00 1696.06,1293.23 1698.13,1289.85 1698.83,1284.00 1698.83,1284.00 1701.00,1250.00 1701.00,1250.00 1701.00,1250.00 1702.00,1235.00 1702.00,1235.00 1702.00,1235.00 1702.00,1213.00 1702.00,1213.00 Z",
      attr: {
        id: "_121",
        "data-name": "121",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "121", zone: "Lower Zone", zoneId: "2" },
    };
    var _122 = {
      path:
        "M 1677.00,1318.00 C 1677.00,1318.00 1707.00,1342.58 1707.00,1342.58 1707.00,1342.58 1750.00,1378.29 1750.00,1378.29 1750.00,1378.29 1759.00,1386.71 1759.00,1386.71 1759.00,1386.71 1802.00,1422.42 1802.00,1422.42 1802.00,1422.42 1821.00,1438.00 1821.00,1438.00 1813.17,1449.29 1791.63,1470.92 1781.00,1480.28 1774.11,1486.35 1767.80,1493.05 1760.00,1498.00 1760.00,1498.00 1724.51,1455.00 1724.51,1455.00 1724.51,1455.00 1718.88,1447.00 1718.88,1447.00 1718.88,1447.00 1673.00,1391.00 1673.00,1391.00 1673.00,1391.00 1657.46,1372.00 1657.46,1372.00 1657.46,1372.00 1651.83,1364.00 1651.83,1364.00 1650.00,1361.60 1644.36,1355.80 1644.34,1353.01 1644.31,1349.92 1650.74,1345.19 1653.00,1342.99 1653.00,1342.99 1677.00,1318.00 1677.00,1318.00 Z",
      attr: {
        id: "_122",
        "data-name": "122",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "122", zone: "Lower Zone", zoneId: "2" },
    };
    var _123 = {
      path:
        "M 1568.00,1352.00 C 1568.00,1352.00 1599.00,1344.63 1599.00,1344.63 1602.77,1343.69 1609.42,1341.28 1612.96,1343.04 1615.89,1344.49 1620.76,1351.20 1623.08,1354.00 1623.08,1354.00 1636.16,1370.00 1636.16,1370.00 1636.16,1370.00 1643.43,1380.00 1643.43,1380.00 1643.43,1380.00 1698.39,1447.00 1698.39,1447.00 1698.39,1447.00 1715.39,1469.00 1715.39,1469.00 1715.39,1469.00 1739.08,1498.00 1739.08,1498.00 1740.99,1500.30 1746.36,1505.72 1745.55,1508.83 1744.97,1511.07 1740.85,1513.68 1739.00,1515.00 1739.00,1515.00 1723.00,1526.33 1723.00,1526.33 1704.22,1538.99 1670.73,1558.71 1650.00,1567.00 1650.00,1567.00 1629.06,1512.00 1629.06,1512.00 1629.06,1512.00 1585.32,1398.00 1585.32,1398.00 1585.32,1398.00 1568.00,1352.00 1568.00,1352.00 Z",
      attr: {
        id: "_123",
        "data-name": "123",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "123", zone: "Lower Zone", zoneId: "2" },
    };
    var _124 = {
      path:
        "M 1495.00,1370.00 C 1495.00,1370.00 1537.00,1360.00 1537.00,1360.00 1539.98,1359.36 1548.97,1356.44 1551.30,1358.03 1553.13,1359.27 1556.46,1368.54 1557.34,1371.00 1557.34,1371.00 1570.00,1404.00 1570.00,1404.00 1570.00,1404.00 1595.26,1469.00 1595.26,1469.00 1595.26,1469.00 1616.44,1524.00 1616.44,1524.00 1616.44,1524.00 1630.26,1560.00 1630.26,1560.00 1631.26,1562.60 1634.98,1571.35 1633.85,1573.59 1632.95,1575.37 1629.76,1576.51 1628.00,1577.28 1628.00,1577.28 1612.00,1583.60 1612.00,1583.60 1596.03,1589.93 1600.28,1586.45 1586.00,1590.42 1586.00,1590.42 1538.00,1602.00 1538.00,1602.00 1538.00,1602.00 1530.46,1561.00 1530.46,1561.00 1530.46,1561.00 1516.42,1485.00 1516.42,1485.00 1516.42,1485.00 1500.46,1399.00 1500.46,1399.00 1500.46,1399.00 1495.00,1370.00 1495.00,1370.00 Z",
      attr: {
        id: "_124",
        "data-name": "124",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "124", zone: "Lower Zone", zoneId: "2" },
    };
    var _125 = {
      path:
        "M 1376.00,1394.00 C 1376.00,1394.00 1444.00,1381.20 1444.00,1381.20 1444.00,1381.20 1479.00,1374.00 1479.00,1374.00 1479.00,1374.00 1484.75,1406.00 1484.75,1406.00 1484.75,1406.00 1498.55,1480.00 1498.55,1480.00 1498.55,1480.00 1516.54,1577.00 1516.54,1577.00 1516.54,1577.00 1522.00,1606.00 1522.00,1606.00 1522.00,1606.00 1447.00,1618.27 1447.00,1618.27 1447.00,1618.27 1428.00,1621.28 1428.00,1621.28 1425.77,1621.44 1421.44,1622.40 1419.43,1621.28 1416.59,1620.05 1415.62,1610.14 1415.08,1607.00 1415.08,1607.00 1407.39,1565.00 1407.39,1565.00 1407.39,1565.00 1384.46,1440.00 1384.46,1440.00 1384.46,1440.00 1376.00,1394.00 1376.00,1394.00 Z",
      attr: {
        id: "_125",
        "data-name": "125",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "125", zone: "Lower Zone", zoneId: "2" },
    };
    var _126 = {
      path:
        "M 1359.00,1396.00 C 1359.00,1396.00 1363.80,1420.00 1363.80,1420.00 1363.80,1420.00 1374.26,1478.00 1374.26,1478.00 1374.26,1478.00 1392.42,1577.00 1392.42,1577.00 1392.42,1577.00 1401.00,1625.00 1401.00,1625.00 1401.00,1625.00 1379.00,1627.72 1379.00,1627.72 1379.00,1627.72 1305.00,1636.00 1305.00,1636.00 1305.00,1636.00 1305.00,1400.00 1305.00,1400.00 1305.00,1400.00 1341.00,1398.17 1341.00,1398.17 1341.00,1398.17 1359.00,1396.00 1359.00,1396.00 Z",
      attr: {
        id: "_126",
        "data-name": "126",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "126", zone: "Lower Zone", zoneId: "2" },
    };
    var _127 = {
      path: "M 1191.00,1401.00 C 1191.00,1401.00 1288.00,1401.00 1288.00,1401.00 1288.00,1401.00 1288.00,1636.00 1288.00,1636.00 1288.00,1636.00 1191.00,1636.00 1191.00,1636.00 1191.00,1636.00 1191.00,1401.00 1191.00,1401.00 Z",
      attr: {
        id: "_127",
        "data-name": "127",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "127", zone: "Lower Zone", zoneId: "2" },
    };
    var _128 = {
      path:
        "M 1105.00,1402.00 C 1105.00,1402.00 1122.00,1401.00 1122.00,1401.00 1122.00,1401.00 1174.00,1401.00 1174.00,1401.00 1174.00,1401.00 1174.00,1635.00 1174.00,1635.00 1174.00,1635.00 1105.00,1635.00 1105.00,1635.00 1105.00,1635.00 1105.00,1402.00 1105.00,1402.00 Z",
      attr: {
        id: "_128",
        "data-name": "128",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "128", zone: "Lower Zone", zoneId: "2" },
    };
    var _129 = {
      path:
        "M 1001.00,1401.00 C 1001.00,1401.00 1088.00,1401.00 1088.00,1401.00 1088.00,1401.00 1088.00,1634.96 1088.00,1634.96 1088.00,1634.96 1076.00,1634.96 1076.00,1634.96 1076.00,1634.96 1064.00,1634.00 1064.00,1634.00 1064.00,1634.00 1001.00,1634.00 1001.00,1634.00 1001.00,1634.00 1001.00,1401.00 1001.00,1401.00 Z",
      attr: {
        id: "_129",
        "data-name": "129",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "129", zone: "Lower Zone", zoneId: "2" },
    };
    var _130 = {
      path:
        "M 900.00,1401.00 C 900.00,1401.00 984.00,1401.00 984.00,1401.00 984.00,1401.00 984.00,1634.00 984.00,1634.00 984.00,1634.00 970.00,1634.00 970.00,1634.00 970.00,1634.00 955.00,1633.00 955.00,1633.00 955.00,1633.00 900.00,1633.00 900.00,1633.00 900.00,1633.00 900.00,1401.00 900.00,1401.00 Z",
      attr: {
        id: "_130",
        "data-name": "130",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "130", zone: "Lower Zone", zoneId: "2" },
    };
  
    var _131 = {
      path:
        "M 816.00,1400.00 C 816.00,1400.00 836.00,1401.00 836.00,1401.00 836.00,1401.00 883.00,1401.00 883.00,1401.00 883.00,1401.00 883.00,1633.00 883.00,1633.00 883.00,1633.00 863.00,1633.00 863.00,1633.00 863.00,1633.00 847.00,1632.00 847.00,1632.00 847.00,1632.00 816.00,1632.00 816.00,1632.00 816.00,1632.00 816.00,1400.00 816.00,1400.00 Z",
      attr: {
        id: "_131",
        "data-name": "131",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "131", zone: "Lower Zone", zoneId: "2" },
    };
    var _132 = {
      path:
        "M 695.00,1400.00 C 695.00,1400.00 799.00,1400.00 799.00,1400.00 799.00,1400.00 799.00,1632.00 799.00,1632.00 799.00,1632.00 760.00,1632.00 760.00,1632.00 760.00,1632.00 743.00,1631.00 743.00,1631.00 743.00,1631.00 695.00,1631.00 695.00,1631.00 695.00,1631.00 695.00,1400.00 695.00,1400.00 Z",
      attr: {
        id: "_132",
        "data-name": "132",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "132", zone: "Lower Zone", zoneId: "2" },
    };
    var _133 = {
      path:
        "M 626.00,1394.00 C 626.00,1394.00 659.00,1399.00 659.00,1399.00 659.00,1399.00 678.00,1399.00 678.00,1399.00 678.00,1399.00 678.00,1630.86 678.00,1630.86 678.00,1630.86 645.00,1630.86 645.00,1630.86 645.00,1630.86 629.00,1630.86 629.00,1630.86 629.00,1630.86 614.00,1630.00 614.00,1630.00 614.00,1630.00 602.00,1630.00 602.00,1630.00 602.00,1630.00 583.00,1629.00 583.00,1629.00 583.00,1629.00 592.46,1578.00 592.46,1578.00 592.46,1578.00 614.58,1457.00 614.58,1457.00 614.58,1457.00 623.55,1408.00 623.55,1408.00 623.55,1408.00 626.00,1394.00 626.00,1394.00 Z",
      attr: {
        id: "_133",
        "data-name": "133",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "133", zone: "Lower Zone", zoneId: "2" },
    };
    var _134 = {
      path:
        "M 521.00,1376.00 C 521.00,1376.00 555.00,1381.58 555.00,1381.58 555.00,1381.58 609.00,1391.00 609.00,1391.00 609.00,1391.00 596.45,1462.00 596.45,1462.00 596.45,1462.00 576.39,1573.00 576.39,1573.00 576.39,1573.00 569.00,1614.00 569.00,1614.00 567.20,1623.31 568.10,1627.45 560.00,1626.83 532.54,1624.70 505.04,1619.09 478.00,1614.00 478.00,1614.00 493.42,1529.00 493.42,1529.00 493.42,1529.00 521.00,1376.00 521.00,1376.00 Z",
      attr: {
        id: "_134",
        "data-name": "134",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "134", zone: "Lower Zone", zoneId: "2" },
    };
    var _135 = {
      path:
        "M 402.00,1490.00 C 402.00,1490.00 408.99,1473.00 408.99,1473.00 408.99,1473.00 424.85,1435.00 424.85,1435.00 426.42,1431.31 431.05,1417.99 434.27,1416.61 437.08,1415.41 447.56,1418.11 451.00,1418.80 451.00,1418.80 495.00,1428.00 495.00,1428.00 495.00,1428.00 489.54,1457.00 489.54,1457.00 489.54,1457.00 479.75,1511.00 479.75,1511.00 479.75,1511.00 467.54,1579.00 467.54,1579.00 467.54,1579.00 462.00,1610.00 462.00,1610.00 443.46,1607.50 393.66,1591.61 375.00,1584.58 375.00,1584.58 348.00,1574.00 348.00,1574.00 348.00,1574.00 360.19,1542.00 360.19,1542.00 360.19,1542.00 383.00,1484.00 383.00,1484.00 383.00,1484.00 402.00,1490.00 402.00,1490.00 Z",
      attr: {
        id: "_135",
        "data-name": "135",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "135", zone: "Lower Zone", zoneId: "2" },
    };
    var _136 = {
      path:
        "M 233.00,1508.00 C 233.00,1508.00 266.59,1466.00 266.59,1466.00 266.59,1466.00 332.75,1386.00 332.75,1386.00 332.75,1386.00 359.25,1354.00 359.25,1354.00 361.53,1351.26 368.37,1342.33 371.09,1341.11 374.25,1339.69 378.97,1342.20 382.00,1343.40 382.00,1343.40 410.00,1355.00 410.00,1355.00 410.00,1355.00 394.25,1387.00 394.25,1387.00 394.25,1387.00 387.74,1401.00 387.74,1401.00 387.74,1401.00 366.69,1444.00 366.69,1444.00 366.69,1444.00 352.00,1475.00 352.00,1475.00 352.00,1475.00 367.00,1479.00 367.00,1479.00 367.00,1479.00 355.74,1508.00 355.74,1508.00 355.74,1508.00 333.00,1566.00 333.00,1566.00 324.72,1564.06 307.03,1554.31 299.00,1549.86 271.77,1534.75 258.33,1525.99 233.00,1508.00 Z",
      attr: {
        id: "_136",
        "data-name": "136",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "136", zone: "Lower Zone", zoneId: "2" },
    };
    var _137 = {
      path:
        "M 219.00,1497.00 C 205.59,1486.03 184.50,1467.75 173.42,1455.00 170.67,1451.82 160.92,1443.37 162.40,1439.29 163.23,1437.00 168.96,1432.62 171.00,1430.92 171.00,1430.92 193.00,1412.58 193.00,1412.58 193.00,1412.58 260.00,1356.57 260.00,1356.57 260.00,1356.57 272.00,1345.59 272.00,1345.59 272.00,1345.59 296.00,1325.59 296.00,1325.59 298.28,1323.69 303.08,1319.14 306.00,1318.99 309.64,1318.80 314.55,1324.56 317.00,1327.00 324.89,1334.87 334.52,1341.95 341.00,1351.00 341.00,1351.00 259.25,1450.00 259.25,1450.00 259.25,1450.00 234.41,1480.00 234.41,1480.00 234.41,1480.00 219.00,1497.00 219.00,1497.00 Z",
      attr: {
        id: "_137",
        "data-name": "137",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "137", zone: "Lower Zone", zoneId: "2" },
    };
    var _138 = {
      path:
        "M 77.00,1276.00 C 77.00,1276.00 132.00,1259.26 132.00,1259.26 132.00,1259.26 155.00,1252.04 155.00,1252.04 155.00,1252.04 208.00,1236.28 208.00,1236.28 208.00,1236.28 256.00,1221.96 256.00,1221.96 256.00,1221.96 285.00,1214.00 285.00,1214.00 285.00,1214.00 292.25,1275.00 292.25,1275.00 292.25,1275.00 295.88,1293.00 295.88,1293.00 296.45,1295.40 297.85,1299.67 297.23,1301.99 296.30,1305.44 289.84,1310.05 287.00,1312.41 287.00,1312.41 260.00,1334.92 260.00,1334.92 260.00,1334.92 247.99,1345.92 247.99,1345.92 247.99,1345.92 190.00,1394.27 190.00,1394.27 190.00,1394.27 151.00,1426.00 151.00,1426.00 118.04,1392.31 98.77,1349.40 84.98,1305.00 84.98,1305.00 77.00,1276.00 77.00,1276.00 Z",
      attr: {
        id: "_138",
        "data-name": "138",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "138", zone: "Lower Zone", zoneId: "2" },
    };
    var _139 = {
      path:
        "M 129.00,1137.00 C 129.00,1137.00 281.00,1137.00 281.00,1137.00 282.19,1146.06 281.99,1151.05 282.00,1160.00 282.00,1160.00 282.99,1176.00 282.99,1176.00 283.02,1179.39 284.25,1193.69 282.99,1195.44 281.73,1197.15 277.99,1198.02 276.00,1198.67 276.00,1198.67 257.00,1204.33 257.00,1204.33 257.00,1204.33 183.00,1226.67 183.00,1226.67 183.00,1226.67 154.00,1235.34 154.00,1235.34 151.33,1236.20 145.18,1238.85 142.74,1236.99 141.09,1235.74 140.75,1231.96 140.41,1230.00 140.41,1230.00 137.41,1212.00 137.41,1212.00 137.41,1212.00 129.00,1157.00 129.00,1157.00 129.00,1157.00 129.00,1137.00 129.00,1137.00 Z",
      attr: {
        id: "_139",
        "data-name": "139",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "139", zone: "Lower Zone", zoneId: "2" },
    };
    var _140 = {
      path:
        "M 129.00,1015.00 C 129.00,1015.00 165.00,1015.00 165.00,1015.00 165.00,1015.00 165.00,1029.00 165.00,1029.00 165.00,1029.00 183.00,1030.00 183.00,1030.00 183.00,1030.00 224.00,1030.00 224.00,1030.00 224.00,1030.00 239.00,1031.00 239.00,1031.00 239.00,1031.00 254.00,1031.00 254.00,1031.00 254.00,1031.00 274.00,1031.00 274.00,1031.00 274.00,1031.00 279.98,1032.60 279.98,1032.60 279.98,1032.60 281.00,1040.00 281.00,1040.00 281.00,1040.00 281.00,1120.00 281.00,1120.00 281.00,1120.00 129.00,1120.00 129.00,1120.00 129.00,1120.00 129.00,1015.00 129.00,1015.00 Z",
      attr: {
        id: "_140",
        "data-name": "140",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "140", zone: "Lower Zone", zoneId: "2" },
    };
    var _141 = {
      path:
        "M 129.00,895.00 C 129.00,895.00 281.00,895.00 281.00,895.00 281.00,895.00 281.00,981.00 281.00,981.00 281.00,981.00 259.00,981.00 259.00,981.00 259.00,981.00 243.00,982.00 243.00,982.00 243.00,982.00 165.00,982.00 165.00,982.00 165.00,982.00 165.00,998.00 165.00,998.00 165.00,998.00 129.00,998.00 129.00,998.00 129.00,998.00 129.00,895.00 129.00,895.00 Z",
      attr: {
        id: "_141",
        "data-name": "141",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "141", zone: "Lower Zone", zoneId: "2" },
    };
    var _142 = {
      path:
        "M 142.00,771.00 C 142.00,771.00 219.00,794.04 219.00,794.04 219.00,794.04 261.00,806.72 261.00,806.72 261.00,806.72 283.00,813.00 283.00,813.00 283.00,813.00 281.00,878.00 281.00,878.00 281.00,878.00 129.00,878.00 129.00,878.00 129.00,878.00 129.00,861.00 129.00,861.00 129.00,861.00 132.73,832.00 132.73,832.00 132.73,832.00 142.00,771.00 142.00,771.00 Z",
      attr: {
        id: "_142",
        "data-name": "142",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "142", zone: "Lower Zone", zoneId: "2" },
    };
    var _143 = {
      path:
        "M 151.00,592.00 C 162.43,598.67 180.30,616.38 191.00,625.41 191.00,625.41 253.00,677.08 253.00,677.08 253.00,677.08 293.00,710.00 293.00,710.00 289.06,724.86 287.18,752.05 286.09,768.00 286.09,768.00 284.00,796.00 284.00,796.00 284.00,796.00 248.00,785.72 248.00,785.72 248.00,785.72 176.00,764.04 176.00,764.04 176.00,764.04 138.00,752.96 138.00,752.96 138.00,752.96 115.00,745.74 115.00,745.74 115.00,745.74 70.00,732.00 70.00,732.00 74.11,716.75 89.80,685.56 97.69,671.00 110.74,646.92 132.74,611.89 151.00,592.00 Z",
      attr: {
        id: "_143",
        "data-name": "143",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "143", zone: "Lower Zone", zoneId: "2" },
    };
    var _144 = {
      path:
        "M 162.00,580.00 C 164.97,574.47 174.31,564.71 179.00,560.00 179.00,560.00 207.00,533.28 207.00,533.28 209.92,530.72 220.76,520.36 224.00,520.34 227.10,520.31 230.25,524.82 232.08,527.00 232.08,527.00 251.08,550.00 251.08,550.00 251.08,550.00 315.58,628.00 315.58,628.00 315.58,628.00 332.08,648.00 332.08,648.00 333.76,650.01 337.86,654.46 338.10,657.00 338.47,660.93 331.73,666.05 329.00,668.42 322.74,673.86 306.79,689.67 302.00,696.00 302.00,696.00 263.00,664.75 263.00,664.75 263.00,664.75 202.01,613.92 202.01,613.92 202.01,613.92 162.00,580.00 162.00,580.00 Z",
      attr: {
        id: "_144",
        "data-name": "144",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "144", zone: "Lower Zone", zoneId: "2" },
    };
    var _201 = {
      path:
        "M 124.00,286.00 C 128.34,280.21 135.31,275.34 141.00,270.80 155.15,259.52 170.36,249.50 186.00,240.42 186.00,240.42 201.00,232.14 201.00,232.14 202.87,231.13 205.60,229.31 207.81,229.82 210.84,230.52 215.21,239.22 216.86,242.00 216.86,242.00 237.46,276.00 237.46,276.00 237.46,276.00 296.26,373.00 296.26,373.00 296.26,373.00 313.28,401.00 313.28,401.00 316.83,406.64 323.01,415.93 325.00,422.00 313.08,427.14 295.63,437.83 285.00,445.43 281.96,447.60 270.95,456.98 268.09,456.84 265.04,456.70 259.13,448.46 257.08,446.00 257.08,446.00 230.75,414.00 230.75,414.00 230.75,414.00 158.08,326.00 158.08,326.00 158.08,326.00 136.59,300.00 136.59,300.00 132.64,295.26 128.84,289.77 124.00,286.00 Z",
      attr: {
        id: "_201",
        "data-name": "201",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "201", zone: "Upper Zone", zoneId: "2" },
    };
    var _202 = {
      path:
        "M 224.00,220.00 C 224.00,220.00 271.00,199.80 271.00,199.80 271.00,199.80 296.00,191.00 296.00,191.00 296.00,191.00 309.26,224.00 309.26,224.00 309.26,224.00 354.14,337.00 354.14,337.00 354.14,337.00 368.14,372.00 368.14,372.00 368.14,372.00 378.00,398.00 378.00,398.00 378.00,398.00 340.00,414.00 340.00,414.00 340.00,414.00 306.15,358.00 306.15,358.00 306.15,358.00 260.85,283.00 260.85,283.00 260.85,283.00 235.95,242.00 235.95,242.00 235.95,242.00 224.00,220.00 224.00,220.00 Z",
      attr: {
        id: "_202",
        "data-name": "202",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "202", zone: "Upper Zone", zoneId: "2" },
    };
    var _203 = {
      path:
        "M 419.00,153.00 C 419.00,153.00 423.61,173.00 423.61,173.00 423.61,173.00 431.46,216.00 431.46,216.00 431.46,216.00 450.61,318.00 450.61,318.00 450.61,318.00 460.00,372.00 460.00,372.00 442.32,375.56 411.91,386.03 394.00,392.00 394.00,392.00 378.27,353.00 378.27,353.00 378.27,353.00 349.55,281.00 349.55,281.00 349.55,281.00 317.26,200.00 317.26,200.00 317.26,200.00 311.00,185.00 311.00,185.00 311.00,185.00 384.00,162.42 384.00,162.42 384.00,162.42 419.00,153.00 419.00,153.00 Z",
      attr: {
        id: "_203",
        "data-name": "203",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "203", zone: "Upper Zone", zoneId: "2" },
    };
    var _204 = {
      path:
        "M 436.00,149.00 C 436.00,149.00 469.00,142.00 469.00,142.00 469.00,142.00 537.00,129.00 537.00,129.00 537.00,129.00 547.45,185.00 547.45,185.00 547.45,185.00 578.00,348.00 578.00,348.00 578.00,348.00 547.00,353.25 547.00,353.25 547.00,353.25 503.00,361.60 503.00,361.60 503.00,361.60 485.00,365.81 485.00,365.81 485.00,365.81 477.73,365.81 477.73,365.81 477.73,365.81 474.00,352.00 474.00,352.00 474.00,352.00 466.39,311.00 466.39,311.00 466.39,311.00 446.42,204.00 446.42,204.00 446.42,204.00 436.00,149.00 436.00,149.00 Z",
      attr: {
        id: "_204",
        "data-name": "204",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "204", zone: "Upper Zone", zoneId: "2" },
    };
    var _205 = {
      path:
        "M 554.00,126.00 C 554.00,126.00 573.00,123.42 573.00,123.42 573.00,123.42 619.00,118.00 619.00,118.00 619.00,118.00 638.00,118.00 638.00,118.00 638.00,118.00 654.00,117.00 654.00,117.00 654.00,117.00 683.00,117.00 683.00,117.00 683.00,117.00 683.00,340.00 683.00,340.00 683.00,340.00 650.00,340.00 650.00,340.00 650.00,340.00 618.00,343.07 618.00,343.07 618.00,343.07 602.00,344.97 602.00,344.97 602.00,344.97 595.58,344.97 595.58,344.97 595.58,344.97 591.80,331.00 591.80,331.00 591.80,331.00 584.39,291.00 584.39,291.00 584.39,291.00 563.58,179.00 563.58,179.00 563.58,179.00 554.00,126.00 554.00,126.00 Z",
      attr: {
        id: "_205",
        "data-name": "205",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "205", zone: "Upper Zone", zoneId: "2" },
    };
    var _206 = {
      path: "M 700.00,117.00 C 700.00,117.00 803.00,117.00 803.00,117.00 803.00,117.00 803.00,340.00 803.00,340.00 803.00,340.00 700.00,340.00 700.00,340.00 700.00,340.00 700.00,117.00 700.00,117.00 Z",
      attr: {
        id: "_206",
        "data-name": "206",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "206", zone: "Upper Zone", zoneId: "2" },
    };
    var _207 = {
      path: "M 820.00,117.00 C 820.00,117.00 922.00,117.00 922.00,117.00 922.00,117.00 922.00,340.00 922.00,340.00 922.00,340.00 820.00,340.00 820.00,340.00 820.00,340.00 820.00,117.00 820.00,117.00 Z",
      attr: {
        id: "_207",
        "data-name": "207",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "207", zone: "Upper Zone", zoneId: "2" },
    };
    var _208 = {
      path: "M 939.00,116.00 C 939.00,116.00 1046.00,116.00 1046.00,116.00 1046.00,116.00 1046.00,340.00 1046.00,340.00 1046.00,340.00 939.00,340.00 939.00,340.00 939.00,340.00 939.00,116.00 939.00,116.00 Z",
      attr: {
        id: "_208",
        "data-name": "208",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "208", zone: "Upper Zone", zoneId: "2" },
    };
    var _209 = {
      path: "M 1063.00,116.00 C 1063.00,116.00 1168.00,116.00 1168.00,116.00 1168.00,116.00 1168.00,340.00 1168.00,340.00 1168.00,340.00 1063.00,340.00 1063.00,340.00 1063.00,340.00 1063.00,116.00 1063.00,116.00 Z",
      attr: {
        id: "_209",
        "data-name": "209",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "209", zone: "Upper Zone", zoneId: "2" },
    };
    var _210 = {
      path: "M 1185.00,116.00 C 1185.00,116.00 1288.00,116.00 1288.00,116.00 1288.00,116.00 1288.00,340.00 1288.00,340.00 1288.00,340.00 1185.00,340.00 1185.00,340.00 1185.00,340.00 1185.00,116.00 1185.00,116.00 Z",
      attr: {
        id: "_210",
        "data-name": "210",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "210", zone: "Upper Zone", zoneId: "2" },
    };
    var _211 = {
      path:
        "M 1305.00,116.00 C 1305.00,116.00 1368.00,116.00 1368.00,116.00 1368.00,116.00 1433.00,123.00 1433.00,123.00 1433.00,123.00 1416.46,212.00 1416.46,212.00 1416.46,212.00 1407.55,261.00 1407.55,261.00 1407.55,261.00 1397.74,314.00 1397.74,314.00 1397.74,314.00 1392.00,345.00 1392.00,345.00 1392.00,345.00 1344.00,340.00 1344.00,340.00 1344.00,340.00 1305.00,340.00 1305.00,340.00 1305.00,340.00 1305.00,116.00 1305.00,116.00 Z",
      attr: {
        id: "_211",
        "data-name": "211",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "211", zone: "Upper Zone", zoneId: "2" },
    };
    var _212 = {
      path:
        "M 1450.00,125.00 C 1450.00,125.00 1480.00,130.08 1480.00,130.08 1480.00,130.08 1526.00,139.00 1526.00,139.00 1526.00,139.00 1545.00,143.12 1545.00,143.12 1546.90,143.57 1550.79,144.11 1551.97,145.73 1553.62,148.01 1550.99,156.16 1550.46,159.00 1550.46,159.00 1543.75,194.00 1543.75,194.00 1543.75,194.00 1529.45,267.00 1529.45,267.00 1529.45,267.00 1518.42,324.00 1518.42,324.00 1518.42,324.00 1512.42,355.00 1512.42,355.00 1511.96,357.55 1511.33,364.48 1509.27,365.92 1507.62,367.16 1504.79,366.29 1503.00,365.92 1503.00,365.92 1489.00,362.42 1489.00,362.42 1489.00,362.42 1431.00,350.92 1431.00,350.92 1431.00,350.92 1415.00,348.27 1415.00,348.27 1412.54,347.85 1410.02,347.80 1409.40,344.85 1409.40,344.85 1412.58,326.00 1412.58,326.00 1412.58,326.00 1420.74,282.00 1420.74,282.00 1420.74,282.00 1440.55,175.00 1440.55,175.00 1440.55,175.00 1450.00,125.00 1450.00,125.00 Z",
      attr: {
        id: "_212",
        "data-name": "212",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "212", zone: "Upper Zone", zoneId: "2" },
    };
    var _213 = {
      path:
        "M 1570.00,149.00 C 1595.68,156.44 1639.64,166.75 1663.00,177.00 1663.00,177.00 1650.26,212.00 1650.26,212.00 1650.26,212.00 1609.37,322.00 1609.37,322.00 1609.37,322.00 1590.45,373.00 1590.45,373.00 1590.45,373.00 1584.00,390.00 1584.00,390.00 1572.45,385.41 1537.74,374.37 1526.00,372.00 1526.00,372.00 1529.46,355.00 1529.46,355.00 1529.46,355.00 1537.61,313.00 1537.61,313.00 1537.61,313.00 1560.00,199.00 1560.00,199.00 1560.00,199.00 1566.20,167.00 1566.20,167.00 1566.20,167.00 1570.00,149.00 1570.00,149.00 Z",
      attr: {
        id: "_213",
        "data-name": "213",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "213", zone: "Upper Zone", zoneId: "2" },
    };
    var _214 = {
      path:
        "M 1678.00,183.00 C 1678.00,183.00 1705.00,192.60 1705.00,192.60 1705.00,192.60 1748.00,211.31 1748.00,211.31 1748.00,211.31 1766.00,220.00 1766.00,220.00 1766.00,220.00 1734.15,272.00 1734.15,272.00 1734.15,272.00 1676.95,365.00 1676.95,365.00 1676.95,365.00 1654.65,401.00 1654.65,401.00 1652.87,403.88 1647.84,413.44 1644.81,414.19 1641.98,414.90 1634.85,410.62 1632.00,409.31 1632.00,409.31 1600.00,396.00 1600.00,396.00 1600.00,396.00 1619.95,341.00 1619.95,341.00 1619.95,341.00 1661.69,227.00 1661.69,227.00 1661.69,227.00 1678.00,183.00 1678.00,183.00 Z",
      attr: {
        id: "_214",
        "data-name": "214",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "214", zone: "Upper Zone", zoneId: "2" },
    };
    var _215 = {
      path:
        "M 1781.00,228.00 C 1801.15,238.64 1822.48,251.34 1841.00,264.58 1841.00,264.58 1854.98,275.00 1854.98,275.00 1856.49,276.27 1858.75,277.98 1858.97,280.09 1859.29,283.00 1853.47,289.57 1851.58,292.00 1851.58,292.00 1828.20,322.00 1828.20,322.00 1828.20,322.00 1749.00,422.00 1749.00,422.00 1749.00,422.00 1726.72,449.00 1726.72,449.00 1724.87,451.13 1719.31,457.98 1716.96,458.70 1714.04,459.60 1711.13,456.84 1709.00,455.21 1709.00,455.21 1691.00,442.14 1691.00,442.14 1691.00,442.14 1670.00,429.01 1670.00,429.01 1668.05,427.87 1663.05,425.54 1662.16,423.61 1660.68,420.39 1667.17,411.91 1668.95,409.00 1668.95,409.00 1694.18,368.00 1694.18,368.00 1694.18,368.00 1711.91,339.00 1711.91,339.00 1711.91,339.00 1730.54,309.00 1730.54,309.00 1730.54,309.00 1760.74,260.00 1760.74,260.00 1760.74,260.00 1781.00,228.00 1781.00,228.00 Z",
      attr: {
        id: "_215",
        "data-name": "215",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "215", zone: "Upper Zone", zoneId: "2" },
    };
    var _223 = {
      path:
        "M 1862.00,1727.00 C 1862.00,1727.00 1809.00,1763.20 1809.00,1763.20 1809.00,1763.20 1796.00,1770.85 1796.00,1770.85 1794.15,1771.87 1791.38,1773.69 1789.19,1773.18 1786.42,1772.54 1782.11,1764.56 1780.55,1762.00 1780.55,1762.00 1761.46,1731.00 1761.46,1731.00 1761.46,1731.00 1696.58,1625.00 1696.58,1625.00 1696.58,1625.00 1679.08,1596.00 1679.08,1596.00 1677.51,1593.54 1672.08,1585.65 1672.25,1583.10 1672.43,1580.39 1675.97,1578.64 1678.00,1577.31 1678.00,1577.31 1693.00,1567.12 1693.00,1567.12 1698.14,1563.33 1703.37,1559.36 1708.00,1554.96 1709.80,1553.25 1713.32,1549.20 1715.91,1549.20 1719.09,1549.20 1726.08,1559.34 1728.20,1562.00 1728.20,1562.00 1760.40,1602.00 1760.40,1602.00 1760.40,1602.00 1802.60,1654.00 1802.60,1654.00 1802.60,1654.00 1843.08,1703.00 1843.08,1703.00 1848.21,1709.14 1859.20,1720.43 1862.00,1727.00 Z",
      attr: {
        id: "_223",
        "data-name": "223",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "223", zone: "Upper Zone", zoneId: "2" },
    };
    var _224 = {
      path:
        "M 1602.00,1613.00 C 1602.00,1613.00 1657.00,1590.00 1657.00,1590.00 1657.00,1590.00 1669.10,1611.00 1669.10,1611.00 1669.10,1611.00 1692.35,1649.00 1692.35,1649.00 1692.35,1649.00 1747.30,1739.00 1747.30,1739.00 1747.30,1739.00 1765.65,1769.00 1765.65,1769.00 1765.65,1769.00 1774.00,1783.00 1774.00,1783.00 1774.00,1783.00 1749.00,1795.22 1749.00,1795.22 1749.00,1795.22 1707.00,1813.40 1707.00,1813.40 1707.00,1813.40 1681.00,1823.00 1681.00,1823.00 1681.00,1823.00 1676.86,1812.00 1676.86,1812.00 1676.86,1812.00 1661.58,1772.00 1661.58,1772.00 1661.58,1772.00 1627.63,1682.00 1627.63,1682.00 1627.63,1682.00 1602.00,1613.00 1602.00,1613.00 Z",
      attr: {
        id: "_224",
        "data-name": "224",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "224", zone: "Upper Zone", zoneId: "2" },
    };
    var _225 = {
      path:
        "M 1565.00,1859.00 C 1565.00,1859.00 1555.46,1805.00 1555.46,1805.00 1555.46,1805.00 1535.55,1696.00 1535.55,1696.00 1535.55,1696.00 1527.58,1653.00 1527.58,1653.00 1527.03,1649.98 1524.39,1640.05 1526.03,1637.72 1527.68,1635.39 1539.76,1632.97 1543.00,1632.12 1543.00,1632.12 1586.00,1619.00 1586.00,1619.00 1586.00,1619.00 1600.42,1657.00 1600.42,1657.00 1600.42,1657.00 1622.26,1714.00 1622.26,1714.00 1622.26,1714.00 1634.74,1748.00 1634.74,1748.00 1634.74,1748.00 1655.05,1801.00 1655.05,1801.00 1655.05,1801.00 1665.00,1829.00 1665.00,1829.00 1651.21,1835.08 1617.53,1845.24 1602.00,1849.71 1592.98,1852.30 1573.39,1858.61 1565.00,1859.00 Z",
      attr: {
        id: "_225",
        "data-name": "225",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "225", zone: "Upper Zone", zoneId: "2" },
    };
    var _226 = {
      path:
        "M 1405.00,1659.00 C 1405.00,1659.00 1465.00,1649.58 1465.00,1649.58 1465.00,1649.58 1490.00,1644.79 1490.00,1644.79 1490.00,1644.79 1508.00,1641.00 1508.00,1641.00 1508.00,1641.00 1511.46,1659.00 1511.46,1659.00 1511.46,1659.00 1517.42,1691.00 1517.42,1691.00 1517.42,1691.00 1531.46,1767.00 1531.46,1767.00 1531.46,1767.00 1542.45,1827.00 1542.45,1827.00 1542.45,1827.00 1549.00,1864.00 1549.00,1864.00 1549.00,1864.00 1531.00,1868.35 1531.00,1868.35 1531.00,1868.35 1475.00,1880.39 1475.00,1880.39 1475.00,1880.39 1456.00,1884.15 1456.00,1884.15 1453.79,1884.29 1449.32,1885.30 1447.31,1884.15 1444.31,1883.02 1443.80,1874.14 1443.20,1871.00 1443.20,1871.00 1435.88,1831.00 1435.88,1831.00 1435.88,1831.00 1431.54,1806.00 1431.54,1806.00 1431.54,1806.00 1417.92,1731.00 1417.92,1731.00 1417.92,1731.00 1405.00,1659.00 1405.00,1659.00 Z",
      attr: {
        id: "_226",
        "data-name": "226",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "226", zone: "Upper Zone", zoneId: "2" },
    };
    var _227 = {
      path:
        "M 1306.00,1666.00 C 1325.49,1666.00 1344.64,1666.14 1364.00,1663.58 1364.00,1663.58 1381.00,1661.93 1381.00,1661.93 1382.78,1661.65 1385.73,1660.90 1387.26,1661.93 1389.21,1663.46 1390.07,1673.21 1390.66,1676.00 1390.66,1676.00 1398.75,1720.00 1398.75,1720.00 1398.75,1720.00 1418.42,1829.00 1418.42,1829.00 1418.42,1829.00 1429.00,1889.00 1429.00,1889.00 1429.00,1889.00 1372.00,1896.00 1372.00,1896.00 1372.00,1896.00 1306.00,1896.00 1306.00,1896.00 1306.00,1896.00 1306.00,1666.00 1306.00,1666.00 Z",
      attr: {
        id: "_227",
        "data-name": "227",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "227", zone: "Upper Zone", zoneId: "2" },
    };
    var _228 = {
      path:
        "M 1186.00,1668.00 C 1186.00,1668.00 1208.00,1668.00 1208.00,1668.00 1208.00,1668.00 1225.00,1667.00 1225.00,1667.00 1225.00,1667.00 1289.00,1667.00 1289.00,1667.00 1289.00,1667.00 1289.00,1896.00 1289.00,1896.00 1289.00,1896.00 1223.00,1896.00 1223.00,1896.00 1223.00,1896.00 1208.00,1895.00 1208.00,1895.00 1208.00,1895.00 1186.00,1895.00 1186.00,1895.00 1186.00,1895.00 1186.00,1668.00 1186.00,1668.00 Z",
      attr: {
        id: "_228",
        "data-name": "228",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "228", zone: "Upper Zone", zoneId: "2" },
    };
    var _229 = {
      path:
        "M 1063.00,1669.00 C 1063.00,1669.00 1116.00,1669.00 1116.00,1669.00 1116.00,1669.00 1133.00,1668.00 1133.00,1668.00 1133.00,1668.00 1169.00,1668.00 1169.00,1668.00 1169.00,1668.00 1169.00,1895.00 1169.00,1895.00 1169.00,1895.00 1063.00,1895.00 1063.00,1895.00 1063.00,1895.00 1063.00,1669.00 1063.00,1669.00 Z",
      attr: {
        id: "_229",
        "data-name": "229",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "229", zone: "Upper Zone", zoneId: "2" },
    };
    var _230 = {
      path:
        "M 941.00,1670.00 C 941.00,1670.00 989.00,1670.00 989.00,1670.00 989.00,1670.00 1006.00,1669.00 1006.00,1669.00 1006.00,1669.00 1046.00,1669.00 1046.00,1669.00 1046.00,1669.00 1046.00,1895.00 1046.00,1895.00 1046.00,1895.00 1032.00,1894.00 1032.00,1894.00 1032.00,1894.00 941.00,1894.00 941.00,1894.00 941.00,1894.00 941.00,1670.00 941.00,1670.00 Z",
      attr: {
        id: "_230",
        "data-name": "230",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "230", zone: "Upper Zone", zoneId: "2" },
    };
    var _231 = {
      path:
        "M 820.00,1670.00 C 820.00,1670.00 924.00,1670.00 924.00,1670.00 924.00,1670.00 924.00,1894.00 924.00,1894.00 924.00,1894.00 863.00,1894.00 863.00,1894.00 863.00,1894.00 847.00,1893.00 847.00,1893.00 847.00,1893.00 820.00,1893.00 820.00,1893.00 820.00,1893.00 820.00,1670.00 820.00,1670.00 Z",
      attr: {
        id: "_231",
        "data-name": "231",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "231", zone: "Upper Zone", zoneId: "2" },
    };
    var _232 = {
      path: "M 697.00,1670.00 C 697.00,1670.00 803.00,1670.00 803.00,1670.00 803.00,1670.00 803.00,1893.00 803.00,1893.00 803.00,1893.00 697.00,1893.00 697.00,1893.00 697.00,1893.00 697.00,1670.00 697.00,1670.00 Z",
      attr: {
        id: "_232",
        "data-name": "232",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "232", zone: "Upper Zone", zoneId: "2" },
    };
    var _233 = {
      path:
        "M 557.00,1887.00 C 557.00,1887.00 560.74,1867.00 560.74,1867.00 560.74,1867.00 572.54,1805.00 572.54,1805.00 572.54,1805.00 588.34,1721.00 588.34,1721.00 588.34,1721.00 595.74,1682.00 595.74,1682.00 596.30,1679.02 597.48,1668.65 599.59,1667.02 602.15,1665.06 616.08,1667.75 620.00,1667.97 620.00,1667.97 638.00,1669.00 638.00,1669.00 638.00,1669.00 653.00,1670.00 653.00,1670.00 653.00,1670.00 680.00,1670.00 680.00,1670.00 680.00,1670.00 680.00,1893.00 680.00,1893.00 680.00,1893.00 643.00,1893.00 643.00,1893.00 643.00,1893.00 628.00,1892.00 628.00,1892.00 628.00,1892.00 609.00,1892.00 609.00,1892.00 609.00,1892.00 557.00,1887.00 557.00,1887.00 Z",
      attr: {
        id: "_233",
        "data-name": "233",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "233", zone: "Upper Zone", zoneId: "2" },
    };
    var _234 = {
      path:
        "M 485.00,1643.00 C 485.00,1643.00 529.00,1653.42 529.00,1653.42 529.00,1653.42 582.00,1663.00 582.00,1663.00 582.00,1663.00 553.26,1815.00 553.26,1815.00 553.26,1815.00 544.61,1861.00 544.61,1861.00 544.61,1861.00 540.49,1881.69 540.49,1881.69 538.97,1884.28 536.53,1883.74 534.00,1883.42 534.00,1883.42 519.00,1881.25 519.00,1881.25 519.00,1881.25 462.00,1871.20 462.00,1871.20 458.49,1870.50 444.94,1868.60 443.61,1865.57 442.52,1863.09 445.42,1851.16 446.12,1848.00 446.12,1848.00 453.92,1807.00 453.92,1807.00 453.92,1807.00 485.00,1643.00 485.00,1643.00 Z",
      attr: {
        id: "_234",
        "data-name": "234",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "234", zone: "Upper Zone", zoneId: "2" },
    };
    var _235 = {
      path:
        "M 401.00,1618.00 C 401.00,1618.00 469.00,1639.00 469.00,1639.00 469.00,1639.00 465.54,1656.00 465.54,1656.00 465.54,1656.00 458.54,1693.00 458.54,1693.00 458.54,1693.00 437.61,1802.00 437.61,1802.00 437.61,1802.00 430.42,1840.00 430.42,1840.00 430.42,1840.00 426.00,1863.00 426.00,1863.00 409.71,1860.80 361.62,1846.45 344.00,1840.98 344.00,1840.98 319.00,1833.00 319.00,1833.00 319.00,1833.00 337.26,1785.00 337.26,1785.00 337.26,1785.00 379.37,1675.00 379.37,1675.00 379.37,1675.00 401.00,1618.00 401.00,1618.00 Z",
      attr: {
        id: "_235",
        "data-name": "235",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "235", zone: "Upper Zone", zoneId: "2" },
    };
    var _236 = {
      path:
        "M 333.00,1590.00 C 345.05,1595.18 375.02,1609.43 386.00,1612.00 386.00,1612.00 364.06,1669.00 364.06,1669.00 364.06,1669.00 331.26,1755.00 331.26,1755.00 331.26,1755.00 313.26,1802.00 313.26,1802.00 313.26,1802.00 304.00,1827.00 304.00,1827.00 277.46,1818.14 233.40,1797.89 209.00,1784.00 209.00,1784.00 240.07,1735.00 240.07,1735.00 240.07,1735.00 303.66,1636.00 303.66,1636.00 303.66,1636.00 333.00,1590.00 333.00,1590.00 Z",
      attr: {
        id: "_236",
        "data-name": "236",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "236", zone: "Upper Zone", zoneId: "2" },
    };
    var _237 = {
      path:
        "M 266.00,1547.00 C 266.00,1547.00 270.00,1546.00 270.00,1546.00 270.00,1546.00 268.00,1550.00 268.00,1550.00 272.90,1552.27 279.24,1557.89 284.00,1561.29 293.10,1567.80 307.94,1577.51 318.00,1582.00 318.00,1582.00 305.66,1603.00 305.66,1603.00 305.66,1603.00 278.58,1645.00 278.58,1645.00 278.58,1645.00 225.29,1728.00 225.29,1728.00 225.29,1728.00 195.00,1775.00 195.00,1775.00 188.73,1773.17 180.59,1767.39 175.00,1763.67 162.92,1755.63 152.13,1748.14 141.00,1738.73 137.56,1735.81 126.46,1727.64 127.05,1723.00 127.37,1720.48 132.98,1714.27 134.80,1712.00 134.80,1712.00 155.00,1687.00 155.00,1687.00 155.00,1687.00 233.42,1590.00 233.42,1590.00 233.42,1590.00 257.00,1561.00 257.00,1561.00 260.27,1556.92 265.41,1552.31 266.00,1547.00 Z",
      attr: {
        id: "_237",
        "data-name": "237",
        opacity: 0,
        fill: "#2196f3",
        stroke: "#d7d7d7",
        "stroke-miterlimit": "10",
        "stroke-width": "0",
        "stroke-opacity": "1",
      },
      transform: "",
      data: { zoneNo: "237", zone: "Upper Zone", zoneId: "2" },
    };
  
    zones.push(
      _101,
      _102,
      _103,
      _104,
      _105,
      _106,
      _107,
      _108,
      _109,
      _110,
      _111,
      _112,
      _113,
      _114,
      _115,
      _116,
      _117,
      _118,
      _119,
      _120,
      _121,
      _122,
      _123,
      _124,
      _125,
      _126,
      _127,
      _128,
      _129,
      _130,
      _131,
      _132,
      _133,
      _134,
      _135,
      _136,
      _137,
      _138,
      _139,
      _140,
      _141,
      _142,
      _143,
      _144,
      _201,
      _202,
      _203,
      _204,
      _205,
      _206,
      _207,
      _208,
      _209,
      _210,
      _211,
      _212,
      _213,
      _214,
      _215,
      _223,
      _224,
      _225,
      _226,
      _227,
      _228,
      _229,
      _230,
      _231,
      _232,
      _233,
      _234,
      _235,
      _236,
      _237,
      _JimBeamSocial
    );
    return zones;
  };
  