import React from "react";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";

import { Button, Typography, withStyles } from "@material-ui/core";
import { getLocalizedDateTime, handleGameClickHelper } from "../../libs/shared/seat-helpers";
import { findGridLowestPrice, getFormattedTime } from "../../libs/shared/grid-helper";
import { teams } from "./team-selector";
import { getTeamName } from "../../libs/shared/team-helper";

const styles = (theme) => ({
  gamesGridContainer: {
    display: "flex",
    flexDirection: "column",
  },
  gridHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #D5DEE9",
  },
  gridHeader: {
    padding: "1rem 0",
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  gameDataContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1rem",
  },
  gameData: {
    flex: 1,
    padding: "1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  gameDate: {
    flex: 2,
    display: "flex",
  },
  seeAllButton: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderBottom: "1px solid #D5DEE9",
    marginBottom: "2rem",
  },
  "calgary-flames.font": {
    color: "#D42604",
    fontWeight: "700",
  },
  "winnipeg-jets.font": {
    color: "#004c97",
    fontWeight: "700",
  },
  "edmonton-oilers.font": {
    color: "#FF4B00",
    fontWeight: "700",
  },
  "vancouver-canucks.font": {
    color: "#008852",
    fontWeight: "700",
  },
  "toronto-maple-leafs.font": {
    color: "#001b5a",
    fontWeight: "700",
  },
  "ottawa-senators.font": {
    color: "#b79257",
    fontWeight: "700",
  },
  "toronto-raptors.font": {
    color: "#7637bf",
    fontWeight: "700",
  },
  "toronto-blue-jays.font": {
    color: "#002d5f",
    fontWeight: "700",
  },
  "edmonton-elks.font": {
    color: "#2B5134",
    fontWeight: "700",
  },
  "winnipeg-blue-bombers.font": {
    color: "#1D3D7A",
    fontWeight: "700",
  },
  "toronto-argonauts.font": {
    color: "#5F8FB1",
    fontWeight: "700",
  },
  "calgary-stampeders.font": {
    color: "#d60000",
    fontWeight: "700",
  },
  "ottawa-redblacks.font": {
    color: "#AC1F2D",
    fontWeight: "700",
  },
  "bc-lions.font": {
    color: "#F15623",
    fontWeight: "700",
  },
  "saskatchewan-roughriders.font": {
    color: "#00623F",
    fontWeight: "700",
  },
  "hamilton-tigercats.font": {
    color: "#FFB819",
    fontWeight: "700",
  },
  "grey-cup.font": {
    color: "#526D82",
    fontWeight: "700",
  },
  "calgary-wranglers.font": {
    color: "#C2273D ",
    fontWeight: "700",
  },
  "manitoba-moose.font": {
    color: "#004c97",
    fontWeight: "700",
  },
  "vancouver-whitecaps-fc.font": {
    color: "#9DC2EA",
    fontWeight: "700",
  },
  "toronto-fc.font": {
    color: "#B81137",
    fontWeight: "700",
  },
  "abbotsford-canucks.font": {
    color: "#047835",
    fontWeight: "700",
  },
  "belleville-senators.font": {
    color: "#E3173E",
    fontWeight: "700",
  },
  "toronto-marlies.font": {
    color: "#003E7E",
    fontWeight: "700",
  },
  "calgary-flames.background": {
    backgroundColor: "rgba(212,38,4,0.1)",
  },
  "winnipeg-jets.background": {
    backgroundColor: "rgba(0, 76, 151, 0.1)",
  },
  "edmonton-oilers.background": {
    backgroundColor: "rgba(255,75,0,0.1)",
  },
  "vancouver-canucks.background": {
    backgroundColor: "rgba(0,136,82,0.1)",
  },
  "toronto-maple-leafs.background": {
    backgroundColor: "rgba(0,27,90,0.1)",
  },
  "ottawa-senators.background": {
    backgroundColor: "rgba(183,146,87,0.1)",
  },
  "toronto-raptors.background": {
    backgroundColor: "rgba(118,55,191,0.1)",
  },
  "toronto-blue-jays.background": {
    backgroundColor: "rgba(0,45,95,0.1)",
  },
  "edmonton-elks.background": {
    backgroundColor: "rgba(43,81,52,0.1)",
  },
  "winnipeg-blue-bombers.background": {
    backgroundColor: "rgba(29,61,122,0.1)",
  },
  "toronto-argonauts.background": {
    backgroundColor: "rgba(95,143,177,0.1)",
  },
  "calgary-stampeders.background": {
    backgroundColor: "rgba(214,0,0,0.1)",
  },
  "ottawa-redblacks.background": {
    backgroundColor: "rgba(172,31,45,0.1)",
  },
  "bc-lions.background": {
    backgroundColor: "rgba(241,86,35,0.1)",
  },
  "saskatchewan-roughriders.background": {
    backgroundColor: "rgba(0, 98, 63,0.1)",
  },
  "hamilton-tigercats.background": {
    backgroundColor: "rgba(255,184,25,0.1)",
  },
  "grey-cup.background": {
    backgroundColor: "rgba(82,109,130,0.1)",
  },
  "calgary-wranglers.background": {
    backgroundColor: "rgba(206, 14, 45,0.1)",
  },
  "manitoba-moose.background": {
    backgroundColor: "rgba(0, 76, 151, 0.1)",
  },
  "vancouver-whitecaps-fc.background": {
    backgroundColor: "rgba(157, 194, 234, 0.1)",
  },
  "toronto-fc.background": {
    backgroundColor: "rgba(184, 17, 55, 0.1)",
  },
  "abbotsford-canucks.background": {
    backgroundColor: "rgba(4, 120, 53, 0.1)",
  },
  "belleville-senators.background": {
    backgroundColor: "rgba(227, 23, 62, 0.1)",
  },
  "toronto-marlies.background": {
    backgroundColor: "rgba(0, 62, 126, 0.1)",
  },
  [theme.breakpoints.up("lg")]: {
    gamesGridContainer: {
      padding: "0 10rem",
    },
  },
  [theme.breakpoints.up("md")]: {
    gridHeaderContainer: {
      padding: "0 10rem",
    },
    upcomingGamesHeader: {
      flex: 2,
    },
  },
  [theme.breakpoints.down("md")]: {
    gridHeaderContainer: {
      padding: "0 1rem",
    },
    gameDataContainer: {
      padding: "0 1rem",
    },
    gameDate: {
      flexDirection: "column",
      paddingRight: "1rem",
      flex: 2.5,
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
});

const GamesList = ({
  classes,
  history,
  games,
  selectedTeam,
  isLoadingGames,
  teIdList,
  selectedParent,
}) => {
  const matchedTeam = teams.find((team) => team.parentSlug === selectedParent);
  const filteredTeams = matchedTeam ? matchedTeam.teams : [];
  const teamDetails = filteredTeams.find(
    (team) => team.homeTeamSlug === selectedTeam
  );

  const handleGameClick = ({ game, zone, teId }) => {
    handleGameClickHelper({ game, zone, history, teId });
  };

  const renderGames = () => {
    if (isLoadingGames && !games) {
      return (
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          style={{ padding: "2rem", fontSize: "1.5rem" }}
        >
          Loading {teamDetails.name} games...
        </Typography>
      );
    } else {
      return (
        games &&
        Array.isArray(games) &&
        games.map((game, index) => {
          const { date, timezone, isPlayoffs, playoffSequence } = game;
          const gameDate = getLocalizedDateTime(date, timezone).stringDate;
          const findIDDate = teIdList.find((item) => {
            let teKey = isPlayoffs ? playoffSequence : gameDate;
            return item[teKey] ? true : false;
          });
          const gameKey = isPlayoffs ? playoffSequence : gameDate;
          const itemIDDate = findIDDate ? findIDDate : { fallback: "" };
          const selectedTeId = itemIDDate[gameKey] || "";
          const gridLowestPrices = findGridLowestPrice(game);
          return (
            <div
              className={`${classes.gameDataContainer} ${
                index % 2 === 0 ? classes[selectedTeam + ".background"] : ""
              }`}
              key={index}
            >
              <div
                className={`${classes.gameData} ${classes.gameDate}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 0,
                    teId: selectedTeId,
                  })
                }
              >
                <div style={{ flex: 1 }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ cursor: "pointer" }}
                  >
                    {!game.isTbd
                      ? moment.tz(game.date, game.timezone).format("MMM DD")
                      : "TBD"}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ cursor: "pointer" }}
                  >
                    {!game.isTbd
                      ? getFormattedTime(moment.tz(game.date, game.timezone).format("ddd h:mm A"), game)
                      : "TBD"}
                  </Typography>
                </div>
                <div style={{ flex: 1 }}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ cursor: "pointer" }}
                  >
                    {game.shortName}
                  </Typography>
                </div>
              </div>
              <div
                className={`${classes.gameData}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 1,
                    teId: selectedTeId,
                  })
                }
              >
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ cursor: "pointer" }}
                >
                  {gridLowestPrices.upperLevelMinPrice}
                </Typography>
              </div>
              <div
                className={`${classes.gameData}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 2,
                    teId: selectedTeId,
                  })
                }
              >
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ cursor: "pointer" }}
                >
                  {gridLowestPrices.secondLevelMinPrice}
                </Typography>
              </div>
              <div
                className={`${classes.gameData}`}
                onClick={() =>
                  handleGameClick({
                    game: game,
                    zone: 3,
                    teId: selectedTeId,
                  })
                }
              >
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ cursor: "pointer" }}
                >
                  {gridLowestPrices.lowerLevelMinPrice}
                </Typography>
              </div>
            </div>
          );
        })
      );
    }
  };

  return (
    <div>
      {teamDetails && (
        <>
          <div className={classes.gridHeaderContainer}>
            <div className={`${classes.gridHeader} ${classes.gameDate}`}>
              {selectedTeam !== 'grey-cup' && <Typography variant="caption" style={{ fontWeight: "bold" }}>
                Upcoming{" "}
                <span className={`${classes[selectedTeam + ".font"]}`}>
                  {getTeamName(selectedTeam)}
                </span>{" "}
                Games
              </Typography>}
            </div>
            {teamDetails.zones.map((zone, index) => {
              return (
                <div
                  key={index}
                  className={classes.gridHeader}
                  style={{ justifyContent: "center" }}
                >
                  <Typography
                    variant="caption"
                    style={{ fontWeight: "bold" }}
                    align="center"
                  >
                    {zone}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div className={classes.gamesGridContainer}>{renderGames()}</div>
          {isLoadingGames ? null : (
            <div className={classes.seeAllButton}>
              {selectedTeam !== 'grey-cup' && <Button
                variant="text"
                href={`/${selectedTeam}/games`}
                color="primary"
              >
                See All {teamDetails.name} Games
              </Button>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(withStyles(styles)(GamesList));
