const defaultRows = () => {
  const arr = [];

  for (let i = 1; i <= 30; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const generateAlphabetRows = (alphabet) => {
  const arr = [];

  alphabet.map((item) => {
    arr.push({
      value: item,
      label: `Row ${item}`,
    });
  });

  return arr;
}

const GARow = [
  {
    value: "GA",
    label: 'Row GA',
  },
];

export default {
  DEFAULT_ROWS: defaultRows(),
  GA_ROW: GARow,
  generateAlphabetRows,
};
  