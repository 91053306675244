export const getZones = rsr => {
    let zones = [];
      var _201 = {
        path:
          'M 1548.00,998.00 C 1548.00,998.00 1615.00,998.00 1615.00,998.00 1615.00,998.00 1615.00,1047.00 1615.00,1047.00 1615.00,1047.00 1453.00,1047.00 1453.00,1047.00 1453.00,1047.00 1454.00,1019.00 1454.00,1019.00 1454.00,1019.00 1548.00,1019.00 1548.00,1019.00 1548.00,1019.00 1548.00,998.00 1548.00,998.00 Z',
        attr: {
          id: '_201',
          'data-name': '201',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "201", zone: 'Lower Zone', zoneId: "2" },
      };
      var _202 = {
        path:
          'M 1453.00,1064.00 C 1453.00,1064.00 1615.00,1064.00 1615.00,1064.00 1615.00,1064.00 1615.00,1120.00 1615.00,1120.00 1615.00,1120.00 1499.00,1102.72 1499.00,1102.72 1499.00,1102.72 1453.00,1096.00 1453.00,1096.00 1453.00,1096.00 1453.00,1064.00 1453.00,1064.00 Z',
        attr: {
          id: '_202',
          'data-name': '202',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "202", zone: 'Lower Zone', zoneId: "2" },
        
      };
      var _203 = {
        path:
          'M 1453.00,1113.00 C 1453.00,1113.00 1499.00,1119.72 1499.00,1119.72 1499.00,1119.72 1614.00,1137.00 1614.00,1137.00 1614.00,1137.00 1608.00,1192.00 1608.00,1192.00 1608.00,1192.00 1562.00,1181.58 1562.00,1181.58 1562.00,1181.58 1522.00,1172.42 1522.00,1172.42 1522.00,1172.42 1489.00,1165.27 1489.00,1165.27 1489.00,1165.27 1461.00,1158.88 1461.00,1158.88 1461.00,1158.88 1450.02,1155.28 1450.02,1155.28 1450.02,1155.28 1450.02,1144.00 1450.02,1144.00 1450.02,1144.00 1453.00,1113.00 1453.00,1113.00 Z',
        attr: {
          id: '_203',
          'data-name': '203',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "203", zone: 'Lower Zone', zoneId: "2" },
      };

      var _204 = {
        path:
          'M 1445.00,1173.00 C 1445.00,1173.00 1498.00,1184.35 1498.00,1184.35 1498.00,1184.35 1575.00,1201.42 1575.00,1201.42 1575.00,1201.42 1604.00,1208.00 1604.00,1208.00 1603.73,1213.86 1602.11,1219.55 1599.93,1225.00 1599.01,1227.30 1597.76,1230.50 1594.90,1230.77 1593.21,1230.93 1581.72,1225.94 1579.00,1225.00 1575.64,1239.32 1566.91,1251.76 1561.00,1265.00 1561.00,1265.00 1528.00,1247.28 1528.00,1247.28 1528.00,1247.28 1471.00,1215.87 1471.00,1215.87 1471.00,1215.87 1448.00,1203.30 1448.00,1203.30 1448.00,1203.30 1439.35,1196.79 1439.35,1196.79 1439.35,1196.79 1440.67,1189.00 1440.67,1189.00 1440.67,1189.00 1445.00,1173.00 1445.00,1173.00 Z',
        attr: {
          id: '_204',
          'data-name': '204',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "204", zone: 'Lower Zone', zoneId: "2" },
      };
      var _206 = {
        path:
          'M 1404.00,1198.00 C 1404.00,1198.00 1448.00,1221.95 1448.00,1221.95 1448.00,1221.95 1511.00,1256.54 1511.00,1256.54 1511.00,1256.54 1551.00,1278.00 1551.00,1278.00 1549.44,1284.38 1544.03,1290.69 1540.12,1296.00 1533.34,1305.19 1515.44,1327.94 1507.00,1334.00 1507.00,1334.00 1485.42,1310.01 1485.42,1310.01 1485.42,1310.01 1476.73,1300.96 1476.73,1300.96 1476.73,1300.96 1460.58,1282.04 1460.58,1282.04 1460.58,1282.04 1421.75,1239.00 1421.75,1239.00 1421.75,1239.00 1402.00,1217.00 1402.00,1217.00 1402.00,1217.00 1396.18,1208.09 1396.18,1208.09 1396.18,1208.09 1404.00,1198.00 1404.00,1198.00 Z',
        attr: {
          id: '_206',
          'data-name': '206',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "206", zone: 'Lower Zone', zoneId: "2" },
      };
      var _207 = {
        path:
          'M 1356.00,1235.00 C 1356.00,1235.00 1374.00,1223.98 1374.00,1223.98 1374.00,1223.98 1381.91,1220.02 1381.91,1220.02 1381.91,1220.02 1391.42,1229.00 1391.42,1229.00 1391.42,1229.00 1412.87,1253.00 1412.87,1253.00 1412.87,1253.00 1450.83,1295.00 1450.83,1295.00 1450.83,1295.00 1472.96,1320.00 1472.96,1320.00 1472.96,1320.00 1496.00,1345.00 1496.00,1345.00 1491.88,1350.75 1481.32,1361.01 1475.00,1364.00 1475.00,1364.00 1485.00,1384.00 1485.00,1384.00 1476.09,1389.58 1457.19,1403.81 1448.00,1406.00 1448.00,1406.00 1421.67,1357.00 1421.67,1357.00 1421.67,1357.00 1379.83,1280.00 1379.83,1280.00 1379.83,1280.00 1356.00,1235.00 1356.00,1235.00 Z',
        attr: {
          id: '_207',
          'data-name': '207',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "207", zone: 'Lower Zone', zoneId: "2" },
      };
      var _209 = {
        path:
          'M 1312.00,1254.00 C 1312.00,1254.00 1341.00,1243.00 1341.00,1243.00 1341.00,1243.00 1397.67,1347.00 1397.67,1347.00 1397.67,1347.00 1420.49,1389.00 1420.49,1389.00 1420.49,1389.00 1434.00,1415.00 1434.00,1415.00 1414.88,1423.24 1387.93,1437.46 1368.00,1442.00 1368.00,1442.00 1351.26,1387.00 1351.26,1387.00 1351.26,1387.00 1332.26,1324.00 1332.26,1324.00 1332.26,1324.00 1325.04,1301.00 1325.04,1301.00 1325.04,1301.00 1318.97,1280.00 1318.97,1280.00 1318.97,1280.00 1312.00,1254.00 1312.00,1254.00 Z',
        attr: {
          id: '_209',
          'data-name': '209',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "209", zone: 'Lower Zone', zoneId: "2" },
      };

      var _210 = {
        path:
          'M 1256.00,1268.00 C 1256.00,1268.00 1296.00,1260.00 1296.00,1260.00 1296.00,1260.00 1302.26,1281.00 1302.26,1281.00 1302.26,1281.00 1319.96,1340.00 1319.96,1340.00 1319.96,1340.00 1337.96,1400.00 1337.96,1400.00 1337.96,1400.00 1352.00,1447.00 1352.00,1447.00 1352.00,1447.00 1326.00,1454.37 1326.00,1454.37 1326.00,1454.37 1292.00,1461.00 1292.00,1461.00 1292.00,1461.00 1266.54,1324.00 1266.54,1324.00 1266.54,1324.00 1256.00,1268.00 1256.00,1268.00 Z',
        attr: {
          id: '_210',
          'data-name': '210',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "210", zone: 'Lower Zone', zoneId: "2" },
      };
      var _211 = {
        path:
          'M 1194.00,1270.00 C 1194.00,1270.00 1239.00,1270.00 1239.00,1270.00 1239.00,1270.00 1262.45,1395.00 1262.45,1395.00 1262.45,1395.00 1270.61,1439.00 1270.61,1439.00 1270.61,1439.00 1275.00,1464.00 1275.00,1464.00 1266.89,1463.55 1263.65,1459.94 1257.00,1455.81 1257.00,1455.81 1231.00,1439.42 1231.00,1439.42 1220.88,1433.09 1219.15,1430.01 1207.00,1430.00 1207.00,1430.00 1205.17,1408.00 1205.17,1408.00 1205.17,1408.00 1202.17,1371.00 1202.17,1371.00 1202.17,1371.00 1199.83,1338.00 1199.83,1338.00 1199.83,1338.00 1194.00,1270.00 1194.00,1270.00 Z',
        attr: {
          id: '_211',
          'data-name': '211',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "211", zone: 'Lower Zone', zoneId: "2" },
      };
      var _212 = {
        path:
          'M 1135.00,1270.00 C 1135.00,1270.00 1177.00,1270.00 1177.00,1270.00 1177.00,1270.00 1180.91,1316.00 1180.91,1316.00 1180.91,1316.00 1183.91,1352.00 1183.91,1352.00 1183.91,1352.00 1186.17,1384.00 1186.17,1384.00 1186.17,1384.00 1188.17,1409.00 1188.17,1409.00 1188.17,1409.00 1190.00,1430.00 1190.00,1430.00 1190.00,1430.00 1166.00,1430.00 1166.00,1430.00 1166.00,1430.00 1151.00,1431.00 1151.00,1431.00 1151.00,1431.00 1135.00,1431.00 1135.00,1431.00 1135.00,1431.00 1135.00,1270.00 1135.00,1270.00 Z',
        attr: {
          id: '_212',
          'data-name': '212',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "212", zone: 'Lower Zone', zoneId: "2" },
      };
      var _213 = {
        path:
          'M 1060.00,1270.00 C 1060.00,1270.00 1118.00,1270.00 1118.00,1270.00 1118.00,1270.00 1118.00,1431.00 1118.00,1431.00 1118.00,1431.00 1060.00,1431.00 1060.00,1431.00 1060.00,1431.00 1060.00,1270.00 1060.00,1270.00 Z',
        attr: {
          id: '_213',
          'data-name': '213',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "213", zone: 'Lower Zone', zoneId: "2" },
      };
      var _214 = {
        path:
          'M 984.00,1270.00 C 984.00,1270.00 1043.00,1270.00 1043.00,1270.00 1043.00,1270.00 1043.00,1431.00 1043.00,1431.00 1043.00,1431.00 999.00,1431.00 999.00,1431.00 990.16,1431.06 991.34,1432.80 985.00,1438.00 985.00,1438.00 985.00,1375.00 985.00,1375.00 985.00,1375.00 984.00,1358.00 984.00,1358.00 984.00,1358.00 984.00,1270.00 984.00,1270.00 Z',
        attr: {
          id: '_214',
          'data-name': '214',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "214", zone: 'Lower Zone', zoneId: "2" },
      };
      var _215 = {
        path:
          'M 918.00,1270.00 C 918.00,1270.00 967.00,1270.00 967.00,1270.00 967.00,1270.00 967.00,1358.00 967.00,1358.00 967.00,1358.00 968.00,1373.00 968.00,1373.00 968.00,1373.00 968.00,1416.00 968.00,1416.00 968.00,1416.00 968.00,1448.00 968.00,1448.00 967.99,1451.44 968.33,1455.03 965.69,1457.69 962.44,1460.95 954.39,1460.99 950.00,1461.00 941.69,1461.01 925.31,1460.22 918.00,1462.00 918.00,1462.00 918.00,1270.00 918.00,1270.00 Z',
        attr: {
          id: '_215',
          'data-name': '215',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "215", zone: 'Lower Zone', zoneId: "2" },
      };

      var _216 = {
        path:
          'M 842.00,1481.00 C 842.00,1481.00 846.09,1433.00 846.09,1433.00 846.09,1433.00 847.83,1416.00 847.83,1416.00 847.83,1416.00 851.83,1366.00 851.83,1366.00 851.83,1366.00 854.83,1330.00 854.83,1330.00 854.83,1330.00 855.58,1319.00 855.58,1319.00 855.58,1319.00 858.58,1283.00 858.58,1283.00 858.58,1283.00 860.17,1271.57 860.17,1271.57 860.17,1271.57 868.00,1270.00 868.00,1270.00 868.00,1270.00 901.00,1270.00 901.00,1270.00 901.00,1270.00 901.00,1462.00 901.00,1462.00 896.02,1462.00 884.93,1461.09 881.00,1463.60 872.68,1468.92 870.17,1480.79 859.00,1481.00 859.00,1481.00 842.00,1481.00 842.00,1481.00 Z',
        attr: {
          id: '_216',
          'data-name': '216',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "216", zone: 'Lower Zone', zoneId: "2" },
      };
      var _217 = {
        path:
          'M 798.00,1266.00 C 798.00,1266.00 842.00,1270.00 842.00,1270.00 842.00,1270.00 839.17,1309.00 839.17,1309.00 839.17,1309.00 836.83,1342.00 836.83,1342.00 836.83,1342.00 834.09,1371.00 834.09,1371.00 834.09,1371.00 828.17,1444.00 828.17,1444.00 828.17,1444.00 825.00,1481.00 825.00,1481.00 825.00,1481.00 812.00,1480.00 812.00,1480.00 812.00,1480.00 778.00,1477.28 778.00,1477.28 774.85,1476.91 764.87,1476.86 763.04,1474.40 761.37,1472.17 763.58,1462.95 764.08,1460.00 764.08,1460.00 771.20,1420.00 771.20,1420.00 771.20,1420.00 789.25,1317.00 789.25,1317.00 789.25,1317.00 798.00,1266.00 798.00,1266.00 Z',
        attr: {
          id: '_217',
          'data-name': '217',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "217", zone: 'Lower Zone', zoneId: "2" },
      };
      var _218 = {
        path:
          'M 746.00,1255.00 C 746.00,1255.00 781.00,1263.00 781.00,1263.00 781.00,1263.00 776.42,1292.00 776.42,1292.00 776.42,1292.00 769.26,1333.00 769.26,1333.00 769.26,1333.00 754.92,1416.00 754.92,1416.00 754.92,1416.00 745.00,1473.00 745.00,1473.00 732.36,1471.32 696.20,1464.12 685.00,1460.00 685.00,1460.00 700.04,1408.00 700.04,1408.00 700.04,1408.00 730.65,1306.00 730.65,1306.00 730.65,1306.00 746.00,1255.00 746.00,1255.00 Z',
        attr: {
          id: '_218',
          'data-name': '218',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "218", zone: 'Lower Zone', zoneId: "2" },
      };
      var _219 = {
        path:
          'M 697.00,1237.00 C 697.00,1237.00 730.00,1250.00 730.00,1250.00 730.00,1250.00 717.96,1291.00 717.96,1291.00 717.96,1291.00 695.04,1367.00 695.04,1367.00 695.04,1367.00 684.00,1405.00 684.00,1405.00 684.00,1405.00 673.26,1442.00 673.26,1442.00 672.48,1444.79 670.44,1453.30 667.70,1454.31 664.92,1455.34 652.42,1450.47 649.00,1449.33 649.00,1449.33 597.00,1428.00 597.00,1428.00 597.00,1428.00 622.57,1379.00 622.57,1379.00 622.57,1379.00 675.03,1279.00 675.03,1279.00 675.03,1279.00 697.00,1237.00 697.00,1237.00 Z',
        attr: {
          id: '_219',
          'data-name': '219',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "219", zone: 'Lower Zone', zoneId: "2" },
      };
      var _221 = {
        path:
          'M 518.00,1379.00 C 523.21,1370.79 527.63,1367.91 533.72,1361.00 533.72,1361.00 563.17,1328.00 563.17,1328.00 563.17,1328.00 574.58,1316.00 574.58,1316.00 574.58,1316.00 614.42,1272.00 614.42,1272.00 614.42,1272.00 625.84,1259.99 625.84,1259.99 625.84,1259.99 636.17,1248.00 636.17,1248.00 636.17,1248.00 645.83,1238.00 645.83,1238.00 649.48,1233.97 658.26,1222.90 662.00,1220.37 666.78,1217.13 671.90,1222.41 676.00,1225.00 677.87,1226.19 680.69,1227.70 681.11,1230.10 681.48,1232.29 677.83,1238.79 676.69,1241.00 676.69,1241.00 663.00,1267.00 663.00,1267.00 663.00,1267.00 620.46,1348.00 620.46,1348.00 620.46,1348.00 610.30,1367.00 610.30,1367.00 610.30,1367.00 590.34,1405.00 590.34,1405.00 588.99,1407.70 584.24,1417.75 581.74,1418.59 578.76,1419.58 567.05,1412.03 564.00,1410.20 564.00,1410.20 518.00,1379.00 518.00,1379.00 Z',
        attr: {
          id: '_221',
          'data-name': '221',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "221", zone: 'Lower Zone', zoneId: "2" },
      };

      var _222 = {
        path:
          'M 609.00,1207.00 C 610.31,1208.71 611.62,1210.86 614.02,1211.13 616.37,1211.39 619.24,1208.93 621.00,1207.57 624.10,1205.20 634.79,1196.12 638.00,1195.87 641.85,1195.58 649.81,1204.97 652.00,1208.00 652.00,1208.00 634.42,1226.91 634.42,1226.91 634.42,1226.91 608.16,1255.04 608.16,1255.04 608.16,1255.04 596.91,1268.00 596.91,1268.00 596.91,1268.00 550.09,1319.00 550.09,1319.00 550.09,1319.00 540.58,1329.83 540.58,1329.83 540.58,1329.83 506.00,1368.00 506.00,1368.00 500.33,1364.96 491.73,1356.71 487.00,1352.00 487.00,1352.00 461.59,1325.00 461.59,1325.00 457.14,1319.72 450.17,1312.41 448.00,1306.00 448.00,1306.00 504.00,1271.28 504.00,1271.28 504.00,1271.28 568.00,1231.70 568.00,1231.70 568.00,1231.70 594.00,1215.47 594.00,1215.47 594.00,1215.47 609.00,1207.00 609.00,1207.00 Z',
        attr: {
          id: '_222',
          'data-name': '222',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "222", zone: 'Lower Zone', zoneId: "2" },
      };
      var _224 = {
        path:
          'M 438.00,1205.00 C 438.00,1205.00 464.00,1197.58 464.00,1197.58 464.00,1197.58 522.00,1181.52 522.00,1181.52 522.00,1181.52 586.00,1164.00 586.00,1164.00 586.00,1164.00 599.00,1193.00 599.00,1193.00 599.00,1193.00 553.00,1221.65 553.00,1221.65 553.00,1221.65 497.00,1256.53 497.00,1256.53 497.00,1256.53 458.00,1281.15 458.00,1281.15 458.00,1281.15 437.00,1293.00 437.00,1293.00 428.71,1279.23 421.52,1269.13 415.00,1254.00 423.97,1249.83 433.76,1247.23 442.00,1241.64 445.76,1239.09 449.78,1236.00 449.79,1231.00 449.79,1226.99 446.60,1223.34 444.63,1220.00 442.05,1215.64 439.68,1209.81 438.00,1205.00 Z',
        attr: {
          id: '_224',
          'data-name': '224',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "224", zone: 'Lower Zone', zoneId: "2" },
      };
      var _225 = {
        path:
          'M 434.00,1189.00 C 434.00,1189.00 430.00,1152.00 430.00,1152.00 430.00,1152.00 430.00,1140.00 430.00,1140.00 430.00,1140.00 430.99,1132.74 430.99,1132.74 430.99,1132.74 440.00,1130.41 440.00,1130.41 440.00,1130.41 465.00,1127.05 465.00,1127.05 465.00,1127.05 531.00,1118.28 531.00,1118.28 531.00,1118.28 578.00,1112.00 578.00,1112.00 578.00,1112.00 578.00,1130.00 578.00,1130.00 578.00,1130.00 578.97,1147.28 578.97,1147.28 578.97,1147.28 568.00,1151.23 568.00,1151.23 568.00,1151.23 541.00,1158.86 541.00,1158.86 541.00,1158.86 465.00,1180.26 465.00,1180.26 465.00,1180.26 434.00,1189.00 434.00,1189.00 Z',
        attr: {
          id: '_225',
          'data-name': '225',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "225", zone: 'Lower Zone', zoneId: "2" },
      };
      var _226 = {
        path:
          'M 446.00,1067.00 C 446.00,1067.00 578.00,1067.00 578.00,1067.00 578.00,1067.00 578.00,1095.00 578.00,1095.00 578.00,1095.00 490.00,1106.85 490.00,1106.85 490.00,1106.85 430.00,1115.00 430.00,1115.00 431.52,1106.12 442.54,1109.36 445.40,1102.79 446.28,1100.77 446.00,1093.57 446.00,1091.00 446.00,1091.00 446.00,1067.00 446.00,1067.00 Z',
        attr: {
          id: '_226',
          'data-name': '226',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "226", zone: 'Lower Zone', zoneId: "2" },
      };
      var _227 = {
        path:
          'M 446.00,995.00 C 446.00,995.00 492.00,995.00 492.00,995.00 491.98,1000.04 489.06,1015.14 493.31,1017.98 495.16,1019.21 498.81,1019.00 501.00,1019.00 501.00,1019.00 536.00,1019.00 536.00,1019.00 536.00,1019.00 552.00,1020.00 552.00,1020.00 552.00,1020.00 578.00,1020.00 578.00,1020.00 578.00,1020.00 578.00,1050.00 578.00,1050.00 578.00,1050.00 446.00,1050.00 446.00,1050.00 446.00,1050.00 446.00,995.00 446.00,995.00 Z',
        attr: {
          id: '_227',
          'data-name': '227',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "227", zone: 'Lower Zone', zoneId: "2" },
      };
      var _228 = {
        path:
          'M 447.00,932.00 C 447.00,932.00 580.00,932.00 580.00,932.00 580.00,932.00 580.00,959.00 580.00,959.00 580.00,959.00 560.00,960.00 560.00,960.00 560.00,960.00 492.00,960.00 492.00,960.00 492.00,960.00 492.00,978.00 492.00,978.00 492.00,978.00 447.00,978.00 447.00,978.00 447.00,978.00 447.00,932.00 447.00,932.00 Z',
        attr: {
          id: '_228',
          'data-name': '228',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "228", zone: 'Lower Zone', zoneId: "2" },
      };
      var _229 = {
        path:
          'M 429.00,860.00 C 429.00,860.00 472.00,864.83 472.00,864.83 472.00,864.83 531.00,871.16 531.00,871.16 531.00,871.16 580.00,876.00 580.00,876.00 580.00,876.00 580.00,915.00 580.00,915.00 580.00,915.00 447.00,915.00 447.00,915.00 447.00,915.00 447.00,887.00 447.00,887.00 446.76,874.39 436.18,878.02 431.59,873.30 429.18,870.81 429.03,863.39 429.00,860.00 Z',
        attr: {
          id: '_229',
          'data-name': '229',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "229", zone: 'Lower Zone', zoneId: "2" },
      };
      var _230 = {
        path:
          'M 439.00,786.00 C 439.00,786.00 476.00,796.74 476.00,796.74 476.00,796.74 545.00,816.65 545.00,816.65 545.00,816.65 569.00,823.52 569.00,823.52 569.00,823.52 580.00,827.70 580.00,827.70 580.00,827.70 580.00,842.00 580.00,842.00 580.00,842.00 580.00,859.00 580.00,859.00 580.00,859.00 532.00,854.17 532.00,854.17 532.00,854.17 455.00,845.83 455.00,845.83 455.00,845.83 430.00,843.00 430.00,843.00 430.00,824.97 433.94,803.31 439.00,786.00 Z',
        attr: {
          id: '_230',
          'data-name': '230',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "230", zone: 'Lower Zone', zoneId: "2" },
      };
      var _231 = {
        path:
          'M 436.00,686.00 C 436.00,686.00 484.00,713.46 484.00,713.46 484.00,713.46 558.00,756.55 558.00,756.55 558.00,756.55 600.00,781.00 600.00,781.00 600.00,781.00 586.00,811.00 586.00,811.00 586.00,811.00 481.00,781.26 481.00,781.26 481.00,781.26 445.00,771.00 445.00,771.00 446.00,761.39 452.17,753.60 456.00,745.00 456.00,745.00 415.00,727.00 415.00,727.00 417.39,716.82 429.99,695.20 436.00,686.00 Z',
        attr: {
          id: '_231',
          'data-name': '231',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "231", zone: 'Lower Zone', zoneId: "2" },
      };
      var _233 = {
        path:
          'M 498.00,617.00 C 498.00,617.00 538.17,657.96 538.17,657.96 538.17,657.96 556.96,677.00 556.96,677.00 556.96,677.00 598.17,719.91 598.17,719.91 598.17,719.91 632.28,754.99 632.28,754.99 637.18,760.42 641.75,763.00 645.00,770.00 642.40,771.84 638.15,776.12 635.00,775.72 630.13,775.10 621.40,763.87 617.00,764.00 614.97,764.06 611.73,766.81 610.00,768.00 610.00,768.00 600.00,762.35 600.00,762.35 600.00,762.35 554.00,735.38 554.00,735.38 554.00,735.38 447.00,673.00 447.00,673.00 448.81,666.35 455.72,659.21 460.29,654.00 474.20,638.13 482.92,630.86 498.00,617.00 Z',
        attr: {
          id: '_233',
          'data-name': '233',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "233", zone: 'Lower Zone', zoneId: "2" },
      };
      var _234 = {
        path:
          'M 678.00,746.00 C 678.00,746.00 665.00,754.33 665.00,754.33 663.10,755.56 660.43,757.54 658.04,757.13 655.33,756.65 651.11,751.18 649.02,749.09 649.02,749.09 626.72,726.01 626.72,726.01 626.72,726.01 597.04,696.00 597.04,696.00 597.04,696.00 549.58,646.72 549.58,646.72 549.58,646.72 521.83,617.99 521.83,617.99 519.71,615.70 513.20,609.99 513.20,607.04 513.20,603.55 522.21,598.14 525.00,596.14 525.00,596.14 564.00,570.80 564.00,570.80 567.31,568.82 576.82,561.81 580.25,564.64 582.09,566.16 589.67,580.94 591.17,584.00 591.17,584.00 609.30,618.00 609.30,618.00 609.30,618.00 643.69,682.00 643.69,682.00 643.69,682.00 678.00,746.00 678.00,746.00 Z',
        attr: {
          id: '_234',
          'data-name': '234',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "234", zone: 'Lower Zone', zoneId: "2" },
      };
      var _236 = {
        path:
          'M 594.00,554.00 C 594.00,554.00 629.00,539.00 629.00,539.00 629.00,539.00 649.25,580.00 649.25,580.00 650.88,583.27 655.09,593.42 658.21,594.65 660.49,595.56 665.61,593.69 668.00,593.00 668.00,593.00 688.00,588.00 688.00,588.00 688.00,588.00 709.03,667.00 709.03,667.00 709.03,667.00 719.48,706.00 719.48,706.00 719.48,706.00 724.00,724.00 724.00,724.00 724.00,724.00 692.00,738.00 692.00,738.00 692.00,738.00 664.13,686.00 664.13,686.00 664.13,686.00 618.87,601.00 618.87,601.00 618.87,601.00 594.00,554.00 594.00,554.00 Z',
        attr: {
          id: '_236',
          'data-name': '236',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "236", zone: 'Lower Zone', zoneId: "2" },
      };
      var _237 = {
        path:
          'M 704.00,584.00 C 704.00,584.00 727.00,578.35 727.00,578.35 727.00,578.35 756.00,573.00 756.00,573.00 756.00,573.00 760.72,607.00 760.72,607.00 760.72,607.00 770.28,674.00 770.28,674.00 770.28,674.00 773.95,698.00 773.95,698.00 774.36,700.69 775.71,706.12 773.95,708.42 772.55,710.31 766.39,711.27 764.00,711.79 764.00,711.79 740.00,718.00 740.00,718.00 740.00,718.00 718.27,637.00 718.27,637.00 718.27,637.00 704.00,584.00 704.00,584.00 Z',
        attr: {
          id: '_237',
          'data-name': '237',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "237", zone: 'Lower Zone', zoneId: "2" },
      };
      var _238 = {
        path:
          'M 772.00,570.00 C 772.00,570.00 809.00,564.23 809.00,564.23 809.00,564.23 822.40,564.23 822.40,564.23 822.40,564.23 824.58,574.00 824.58,574.00 824.58,574.00 827.28,603.00 827.28,603.00 827.28,603.00 832.17,654.00 832.17,654.00 832.17,654.00 839.00,721.00 839.00,721.00 839.00,721.00 818.00,723.00 818.00,723.00 818.00,723.00 813.27,706.61 813.27,706.61 813.27,706.61 806.00,705.83 806.00,705.83 806.00,705.83 792.00,707.00 792.00,707.00 792.00,707.00 783.28,646.00 783.28,646.00 783.28,646.00 772.00,570.00 772.00,570.00 Z',
        attr: {
          id: '_238',
          'data-name': '238',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "238", zone: 'Lower Zone', zoneId: "2" },
      };
      var _239 = {
        path:
          'M 840.00,562.00 C 840.00,562.00 862.00,561.00 862.00,561.00 862.00,561.00 902.00,561.00 902.00,561.00 902.00,561.00 902.00,720.00 902.00,720.00 902.00,720.00 856.00,720.00 856.00,720.00 856.00,720.00 850.83,667.00 850.83,667.00 850.83,667.00 845.17,612.00 845.17,612.00 845.17,612.00 840.00,562.00 840.00,562.00 Z',
        attr: {
          id: '_239',
          'data-name': '239',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "239", zone: 'Lower Zone', zoneId: "2" },
      };
      var _240 = {
        path:
          'M 919.00,560.00 C 919.00,560.00 978.00,560.00 978.00,560.00 978.00,560.00 978.00,719.00 978.00,719.00 963.00,720.98 935.32,720.00 919.00,720.00 919.00,720.00 919.00,560.00 919.00,560.00 Z',
        attr: {
          id: '_240',
          'data-name': '240',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "240", zone: 'Lower Zone', zoneId: "2" },
      };
      var _241 = {
        path:
          'M 995.00,560.00 C 995.00,560.00 1049.00,560.00 1049.00,560.00 1049.00,560.00 1049.00,719.00 1049.00,719.00 1049.00,719.00 995.00,719.00 995.00,719.00 995.00,719.00 995.00,560.00 995.00,560.00 Z',
        attr: {
          id: '_241',
          'data-name': '241',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "241", zone: 'Lower Zone', zoneId: "2" },
      };
      var _242 = {
        path:
          'M 1066.00,560.00 C 1066.00,560.00 1117.00,560.00 1117.00,560.00 1117.00,560.00 1117.00,719.00 1117.00,719.00 1117.00,719.00 1066.00,719.00 1066.00,719.00 1066.00,719.00 1066.00,560.00 1066.00,560.00 Z',
        attr: {
          id: '_242',
          'data-name': '242',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "242", zone: 'Lower Zone', zoneId: "2" },
      };
      var _243 = {
        path:
          'M 1134.00,560.00 C 1134.00,560.00 1190.00,560.00 1190.00,560.00 1190.00,560.00 1180.91,671.00 1180.91,671.00 1180.91,671.00 1177.91,707.00 1177.91,707.00 1177.91,707.00 1177.00,719.00 1177.00,719.00 1177.00,719.00 1134.00,719.00 1134.00,719.00 1134.00,719.00 1134.00,560.00 1134.00,560.00 Z',
        attr: {
          id: '_243',
          'data-name': '243',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "243", zone: 'Lower Zone', zoneId: "2" },
      };
      var _244 = {
        path:
          'M 1207.00,562.00 C 1207.00,562.00 1263.00,569.00 1263.00,569.00 1263.00,569.00 1254.58,622.00 1254.58,622.00 1254.58,622.00 1241.00,708.00 1241.00,708.00 1241.00,708.00 1220.00,705.00 1220.00,705.00 1219.89,707.32 1218.99,719.13 1217.98,720.40 1216.40,722.39 1213.19,721.85 1211.00,721.58 1211.00,721.58 1194.00,720.00 1194.00,720.00 1194.00,720.00 1195.83,696.00 1195.83,696.00 1195.83,696.00 1198.91,659.00 1198.91,659.00 1198.91,659.00 1203.83,600.00 1203.83,600.00 1203.83,600.00 1207.00,562.00 1207.00,562.00 Z',
        attr: {
          id: '_244',
          'data-name': '244',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "244", zone: 'Lower Zone', zoneId: "2" },
      };
      var _245 = {
        path:
          'M 1279.00,572.00 C 1289.44,572.23 1309.11,576.99 1320.00,579.20 1323.02,579.81 1332.31,581.13 1333.94,583.56 1335.48,585.88 1333.46,590.54 1332.72,593.00 1332.72,593.00 1325.48,618.00 1325.48,618.00 1325.48,618.00 1304.26,692.00 1304.26,692.00 1304.26,692.00 1296.00,721.00 1296.00,721.00 1296.00,721.00 1269.00,713.87 1269.00,713.87 1269.00,713.87 1259.14,710.42 1259.14,710.42 1259.14,710.42 1259.14,700.00 1259.14,700.00 1259.14,700.00 1263.41,674.00 1263.41,674.00 1263.41,674.00 1273.43,609.00 1273.43,609.00 1273.43,609.00 1279.00,572.00 1279.00,572.00 Z',
        attr: {
          id: '_245',
          'data-name': '245',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "245", zone: 'Lower Zone', zoneId: "2" },
      };
      var _246 = {
        path:
          'M 1401.00,552.00 C 1409.62,553.62 1427.62,563.57 1436.00,568.00 1434.42,574.17 1429.80,581.30 1426.67,587.00 1426.67,587.00 1413.17,612.00 1413.17,612.00 1413.17,612.00 1368.37,695.00 1368.37,695.00 1368.37,695.00 1351.77,726.00 1351.77,726.00 1350.44,728.37 1346.82,735.83 1344.73,736.88 1342.11,738.19 1337.55,735.82 1335.00,734.80 1335.00,734.80 1311.00,726.00 1311.00,726.00 1311.00,726.00 1325.74,676.00 1325.74,676.00 1325.74,676.00 1338.26,634.00 1338.26,634.00 1338.26,634.00 1352.00,587.00 1352.00,587.00 1352.00,587.00 1369.00,592.33 1369.00,592.33 1371.04,593.01 1374.85,594.53 1376.91,594.08 1380.34,593.32 1383.67,586.91 1385.40,584.00 1390.40,575.59 1397.34,561.16 1401.00,552.00 Z',
        attr: {
          id: '_246',
          'data-name': '246',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "246", zone: 'Lower Zone', zoneId: "2" },
      };
      var _248 = {
        path:
          'M 1451.00,577.00 C 1462.20,582.40 1483.75,597.76 1494.00,605.65 1499.97,610.25 1508.61,616.15 1513.00,622.00 1513.00,622.00 1404.00,731.00 1404.00,731.00 1404.00,731.00 1387.00,748.00 1387.00,748.00 1384.89,750.10 1380.89,754.60 1378.00,755.18 1375.15,755.75 1371.38,753.01 1369.00,751.60 1366.87,750.34 1362.22,748.14 1361.40,745.79 1360.34,742.75 1364.57,736.75 1366.05,734.00 1366.05,734.00 1382.55,703.00 1382.55,703.00 1382.55,703.00 1413.05,647.00 1413.05,647.00 1413.05,647.00 1451.00,577.00 1451.00,577.00 Z',
        attr: {
          id: '_248',
          'data-name': '248',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "248", zone: 'Lower Zone', zoneId: "2" },
      };

      var _249 = {
        path:
          'M 1391.00,767.00 C 1391.00,767.00 1426.00,731.00 1426.00,731.00 1426.00,731.00 1492.00,665.00 1492.00,665.00 1492.00,665.00 1516.00,641.00 1516.00,641.00 1518.09,638.91 1523.04,633.20 1526.00,633.20 1529.30,633.20 1536.47,642.35 1538.92,645.00 1547.59,654.44 1563.88,675.54 1569.00,687.00 1569.00,687.00 1519.00,715.70 1519.00,715.70 1519.00,715.70 1446.00,757.54 1446.00,757.54 1446.00,757.54 1429.00,766.94 1429.00,766.94 1429.00,766.94 1420.00,765.58 1420.00,765.58 1420.00,765.58 1402.00,775.01 1402.00,775.01 1402.00,775.01 1391.00,767.00 1391.00,767.00 Z',
        attr: {
          id: '_249',
          'data-name': '249',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "249", zone: 'Lower Zone', zoneId: "2" },
      };
      var _251 = {
        path:
          'M 1435.00,783.00 C 1435.00,783.00 1468.00,763.77 1468.00,763.77 1468.00,763.77 1534.00,726.10 1534.00,726.10 1534.00,726.10 1564.00,708.88 1564.00,708.88 1566.34,707.58 1573.74,702.90 1575.90,702.82 1579.83,702.67 1584.15,714.63 1585.69,718.00 1591.73,731.19 1597.97,748.94 1601.63,763.00 1602.50,766.33 1605.98,775.47 1603.36,778.15 1602.01,779.52 1593.34,781.52 1591.00,782.11 1591.00,782.11 1561.00,789.73 1561.00,789.73 1561.00,789.73 1482.00,810.27 1482.00,810.27 1482.00,810.27 1445.00,820.00 1445.00,820.00 1445.00,820.00 1439.28,796.00 1439.28,796.00 1439.28,796.00 1435.00,783.00 1435.00,783.00 Z',
        attr: {
          id: '_251',
          'data-name': '251',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "251", zone: 'Lower Zone', zoneId: "2" },
      };

      var _252 = {
        path:
          'M 1451.00,866.00 C 1451.00,866.00 1448.78,845.00 1448.78,845.00 1448.78,845.00 1448.78,836.73 1448.78,836.73 1448.78,836.73 1461.00,832.79 1461.00,832.79 1461.00,832.79 1490.00,825.27 1490.00,825.27 1490.00,825.27 1561.00,806.99 1561.00,806.99 1561.00,806.99 1609.00,795.00 1609.00,795.00 1609.00,795.00 1614.00,842.00 1614.00,842.00 1614.00,842.00 1539.00,852.72 1539.00,852.72 1539.00,852.72 1491.00,859.72 1491.00,859.72 1491.00,859.72 1451.00,866.00 1451.00,866.00 Z',
        attr: {
          id: '_252',
          'data-name': '252',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "252", zone: 'Lower Zone', zoneId: "2" },
      };
      var _253 = {
        path:
          'M 1616.00,857.00 C 1616.00,857.00 1616.00,869.00 1616.00,869.00 1616.00,871.04 1616.24,874.77 1614.98,876.42 1613.50,878.35 1609.27,878.73 1607.00,878.92 1607.00,878.92 1587.00,880.98 1587.00,880.98 1587.00,880.98 1570.00,882.83 1570.00,882.83 1570.00,882.83 1492.00,891.00 1492.00,891.00 1492.00,891.00 1462.00,894.03 1462.00,894.03 1459.27,894.30 1453.81,895.71 1451.60,894.03 1449.69,892.48 1449.93,888.22 1450.02,886.00 1450.29,879.36 1452.34,880.15 1464.00,878.42 1464.00,878.42 1508.00,872.08 1508.00,872.08 1508.00,872.08 1552.00,865.43 1552.00,865.43 1552.00,865.43 1616.00,857.00 1616.00,857.00 Z',
        attr: {
          id: '_253',
          'data-name': '253',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "253", zone: 'Lower Zone', zoneId: "2" },
      };
      var _254 = {
        path:
          'M 1545.00,983.00 C 1545.00,983.00 1545.00,967.00 1545.00,967.00 1545.04,965.05 1544.90,962.37 1546.60,961.02 1548.19,959.77 1552.01,960.00 1554.00,960.00 1554.00,960.00 1607.00,960.00 1607.00,960.00 1615.64,960.02 1615.98,960.36 1616.00,969.00 1616.00,969.00 1616.00,983.00 1616.00,983.00 1616.00,983.00 1545.00,983.00 1545.00,983.00 Z',
        attr: {
          id: '_254',
          'data-name': '254',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "254", zone: 'Lower Zone', zoneId: "2" },
      };
      var _336 = {
        path:
          'M 644.00,532.00 C 644.00,532.00 662.00,526.00 662.00,526.00 663.31,528.71 665.63,533.65 668.21,535.23 671.66,537.35 686.42,532.86 691.00,532.00 691.00,532.00 702.00,568.00 702.00,568.00 702.00,568.00 666.00,577.00 666.00,577.00 666.00,577.00 652.75,551.00 652.75,551.00 652.75,551.00 644.00,532.00 644.00,532.00 Z',
        attr: {
          id: '_336',
          'data-name': '336',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "336", zone: 'Middle Zone', zoneId: "3" },
      };
      var _337 = {
        path:
          'M 708.00,528.00 C 708.00,528.00 739.00,522.61 739.00,522.61 739.00,522.61 751.26,522.01 751.26,522.01 751.26,522.01 753.95,532.00 753.95,532.00 753.95,532.00 758.00,556.00 758.00,556.00 758.00,556.00 718.00,564.00 718.00,564.00 718.00,564.00 708.00,528.00 708.00,528.00 Z',
        attr: {
          id: '_337',
          'data-name': '337',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "337", zone: 'Middle Zone', zoneId: "3" },
      };
      var _338 = {
        path:
          'M 769.00,518.00 C 769.00,518.00 805.00,513.52 805.00,513.52 807.71,513.06 816.23,511.49 817.98,513.52 819.21,515.11 819.03,519.90 819.28,522.00 819.28,522.00 822.00,547.00 822.00,547.00 822.00,547.00 774.00,553.00 774.00,553.00 774.00,553.00 769.00,518.00 769.00,518.00 Z',
        attr: {
          id: '_338',
          'data-name': '338',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "338", zone: 'Middle Zone', zoneId: "3" },
      };
      var _339 = {
        path:
          'M 836.00,510.00 C 836.00,510.00 859.00,509.00 859.00,509.00 859.00,509.00 902.00,509.00 902.00,509.00 902.00,509.00 902.00,544.00 902.00,544.00 902.00,544.00 866.00,544.00 866.00,544.00 866.00,544.00 847.00,545.00 847.00,545.00 847.00,545.00 839.74,544.01 839.74,544.01 839.74,544.01 837.83,535.00 837.83,535.00 837.83,535.00 836.00,510.00 836.00,510.00 Z',
        attr: {
          id: '_339',
          'data-name': '339',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "339", zone: 'Middle Zone', zoneId: "3" },
      };
      var _340 = {
        path:
          'M 919.00,509.00 C 919.00,509.00 978.00,509.00 978.00,509.00 978.00,509.00 978.00,544.00 978.00,544.00 978.00,544.00 919.00,544.00 919.00,544.00 919.00,544.00 919.00,509.00 919.00,509.00 Z',
        attr: {
          id: '_340',
          'data-name': '340',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "340", zone: 'Middle Zone', zoneId: "3" },
      };
      var _341 = {
        path:
          'M 995.00,509.00 C 995.00,509.00 1049.00,509.00 1049.00,509.00 1049.00,509.00 1049.00,544.00 1049.00,544.00 1049.00,544.00 995.00,544.00 995.00,544.00 995.00,544.00 995.00,509.00 995.00,509.00 Z',
        attr: {
          id: '_341',
          'data-name': '341',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "341", zone: 'Middle Zone', zoneId: "3" },
      };
      var _342 = {
        path:
          'M 1066.00,509.00 C 1066.00,509.00 1117.00,510.00 1117.00,510.00 1117.00,510.00 1117.00,544.00 1117.00,544.00 1117.00,544.00 1066.00,544.00 1066.00,544.00 1066.00,544.00 1066.00,509.00 1066.00,509.00 Z',
        attr: {
          id: '_342',
          'data-name': '342',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "342", zone: 'Middle Zone', zoneId: "3" },
      };
      var _343 = {
        path:
          'M 1134.00,510.00 C 1134.00,510.00 1194.00,510.00 1194.00,510.00 1194.00,510.00 1191.00,544.00 1191.00,544.00 1191.00,544.00 1174.00,543.00 1174.00,543.00 1174.00,543.00 1148.00,543.00 1148.00,543.00 1148.00,543.00 1134.00,544.00 1134.00,544.00 1134.00,544.00 1134.00,510.00 1134.00,510.00 Z',
        attr: {
          id: '_343',
          'data-name': '343',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "343", zone: 'Middle Zone', zoneId: "3" },
      };
      var _344 = {
        path:
          'M 1211.00,510.00 C 1211.00,510.00 1225.00,511.00 1225.00,511.00 1225.00,511.00 1260.00,514.00 1260.00,514.00 1260.00,514.00 1256.00,551.00 1256.00,551.00 1256.00,551.00 1208.00,546.00 1208.00,546.00 1208.00,546.00 1211.00,510.00 1211.00,510.00 Z',
        attr: {
          id: '_344',
          'data-name': '344',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "344", zone: 'Middle Zone', zoneId: "3" },
      };
      var _345 = {
        path:
          'M 1277.00,516.00 C 1277.00,516.00 1320.00,525.00 1320.00,525.00 1320.00,525.00 1312.00,561.00 1312.00,561.00 1312.00,561.00 1273.00,554.00 1273.00,554.00 1273.00,554.00 1277.00,516.00 1277.00,516.00 Z',
        attr: {
          id: '_345',
          'data-name': '345',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "345", zone: 'Middle Zone', zoneId: "3" },
      };
      var _346 = {
        path:
          'M 1336.00,529.00 C 1346.37,530.46 1378.06,541.38 1388.00,546.00 1388.00,546.00 1372.00,576.00 1372.00,576.00 1372.00,576.00 1328.00,565.00 1328.00,565.00 1328.00,565.00 1336.00,529.00 1336.00,529.00 Z',
        attr: {
          id: '_346',
          'data-name': '346',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "346", zone: 'Middle Zone', zoneId: "3" },
      };

      var _GA = {
        path:
          'M 1450.00,953.00 C 1450.00,953.00 1450.00,910.00 1450.00,910.00 1450.00,907.80 1449.79,904.17 1451.02,902.31 1453.28,898.90 1468.56,898.41 1473.00,898.09 1473.00,898.09 1546.00,891.91 1546.00,891.91 1546.00,891.91 1599.00,887.09 1599.00,887.09 1602.48,886.85 1613.97,885.06 1615.98,887.60 1617.23,889.19 1617.00,893.01 1617.00,895.00 1617.00,895.00 1617.00,946.00 1617.00,946.00 1616.86,953.49 1614.74,952.99 1608.00,953.00 1608.00,953.00 1525.00,953.00 1525.00,953.00 1525.00,953.00 1450.00,953.00 1450.00,953.00 Z',
        attr: {
          id: '_GA',
          'data-name': 'GA',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'General Admission', zoneId: "1" },
      };
    zones.push(
      _201,
      _202,
      _203,
      _204,
      _206,
      _207,
      _209,
      _210,
      _211,
      _212,
      _213,
      _214,
      _215,
      _216,
      _217,
      _218,
      _219,
      _221,
      _222,
      _224,
      _225,
      _226,
      _227,
      _228,
      _229,
      _230,
      _231,
      _233,
      _234,
      _236,
      _237,
      _238,
      _239,
      _240,
      _241,
      _242,
      _243,
      _244,
      _245,
      _246,
      _248,
      _249,
      _251,
      _252,
      _253,
      _254,
      _336,
      _337,
      _338,
      _339,
      _340,
      _341,
      _342,
      _343,
      _344,
      _345,
      _346, 
      _GA
    );
  return zones;
};