import React, { useEffect, useState } from "react";
import { Button, Typography, withStyles } from "@material-ui/core";

import BuyerTrustSeal from "../../images/buyertrust-seal.svg";

const styles = (theme) => ({
  teamSelectorContainer: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  teamButton: {
    flex: 1,
    borderRadius: 0,
    borderBottom: "3px solid rgba(255,255,255,0)",
    fontWeight: 400,
    textTransform: "none",
  },
  "calgary-flames": {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
  "calgary-flames.selected": {
    color: "#D42604",
    fontWeight: "bold",
    borderBottomColor: "#D42604",
    "&:hover": {
      backgroundColor: "transparent",
      borderBottomColor: "#D42604",
    },
  },
  "winnipeg-jets": {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
  "winnipeg-jets.selected": {
    borderBottomColor: "#022E62",
    color: "#022E62",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "transparent",
      borderBottomColor: "#022E62",
    },
  },
  "edmonton-oilers": {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
  "edmonton-oilers.selected": {
    borderBottomColor: "#FF4B00",
    color: "#FF4B00",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "transparent",
      borderBottomColor: "#FF4B00",
    },
  },
  "vancouver-canucks": {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
  "vancouver-canucks.selected": {
    borderBottomColor: "#008852",
    color: "#008852",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "transparent",
      borderBottomColor: "#008852",
    },
  },
  finePrint: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  [theme.breakpoints.up("md")]: {
    teamSelectorContainer: {
      borderTop: "1px solid #D5DEE9",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 20,
      fontSize: "1.5rem",
      padding: "1rem",
    },
    finePrint: {
      padding: "1.5rem",
    },
  },
  [theme.breakpoints.down("md")]: {
    teamSelectorContainer: {
      boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.05)",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 8,
      padding: ".75rem",
    },
    finePrint: {
      padding: ".5rem",
    },
  },
});

const teams = [
  {
    name: "Oilers",
    homeTeamSlug: "edmonton-oilers",
  },
  {
    name: "Jets",
    homeTeamSlug: "winnipeg-jets",
  },
  {
    name: "Flames",
    homeTeamSlug: "calgary-flames",
  },
  {
    name: "Canucks",
    homeTeamSlug: "vancouver-canucks",
  },
];

const TeamSelector = ({
  classes,
  selectedTeam,
  onTeamSelection,
  isLoadingGames,
}) => {
  const handleSelection = ({ homeTeamSlug }) => {
    if (!isLoadingGames) {
      onTeamSelection({ homeTeamSlug });
    }
  };

  return (
    <React.Fragment>
      <div className={classes.finePrint}>
        <img src={BuyerTrustSeal} style={{ marginRight: ".5rem" }} />
        <Typography variant="caption" align="center" color="textSecondary">
          <strong>100% Guaranteed</strong>. All Prices CAD.
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(TeamSelector);
