import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import MapleLeaf from "../images/icons/maple-leaf.svg";
import Group from "../images/icons/group.svg";
import PiggyBank from "../images/icons/piggy-bank.svg";
import Shield from "../images/icons/shield.svg";
import Smartphone from "../images/icons/smartphone.svg";
import Money from "../images/icons/money.svg";

const whyUsContents = [
  {
    icon: MapleLeaf,
    title: "We're Canadian, Made by Fans, For Fans",
    content:
      "Why FansFirst? FansFirst is for Canadians, by Canadians and we truly don't charge our buyers a single penny. We're not trying to be the next StubHub or Ticketmaster. We are Flames season ticket holders who couldn't make it to every game and we think there should be an efficient option to resell these extra tickets for every Canadian team. We want tickets to be more affordable for real fans and we want season ticket holders to be able to sell their tickets quickly and easily without expensive middlemen. It's really that simple.",
  },
  {
    icon: PiggyBank,
    title: "No Buyer Fees and We Mean It!",
    content:
      "Lots of businesses claim to be the cheapest, and it's hard to believe them. At FansFirst buyers truly DO NOT pay service fees of any kind! This results in huge savings. Canadian users of other sites pay fees as high as 50%! Other sites say they don't charge fees, but just include them in their prices. <br/><br/>At FansFirst it's 0%.",
  },
  {
    icon: Shield,
    title: "Safe, Easy, and Secured; Use Your Credit Card!",
    content:
      "Searching for tickets on Facebook or Kijiji runs the risk that you're buying fraudulent tickets. Facebook and the like are cash-only (that includes Interac transfers). Once thieves have your cash, they're gone and you're left with nothing, with no recourse to get your money back. It's happened to us personally before. At FansFirst we ONLY accept credit cards. Why? Thieves want cash for a reason: no paper trail. We offer a money-back guarantee if anything goes wrong with your transaction. And you won't be stuck in limbo, since we have real people available to help anytime (see below).",
  },
  {
    icon: Group,
    title: "Real People, Available Anytime",
    content:
      "Connect to us anytime through the email (tix@fansfirst.ca) or by text/phone at 1-403-768-2298. Speak to a real human and get your questions answered quickly. Since we're new, there's a very high probability you'll actually talk to one of the founders.",
  },
  {
    icon: Smartphone,
    title: "Mobile Transfer; No PDFs",
    content:
      "No PDFs! Why is that a good thing? Mobile Transfers guarantee you'll get the original ticket from the Team's ticketing system: plain and simple. All you need is an email. Mobile Transfers eliminate the risk of duplication/fraud - there won't be several PDFs floating around for the same seats. Once a ticket is transferred through the Team's system, or is added to your mobile wallet via mobile links, all previous hard copies or printouts are automatically cancelled - only the recipient has access to the actual ticket – that's you!",
  },
  {
    icon: Money,
    title: "Fast Payouts",
    content:
      "We pay our Sellers by the end of the next business day after the game via Interac. That’s weeks and weeks faster than other third-party marketplaces, which will pay your proceeds after the game with mailed-out cheques.",
  },
];

const styles = {
  icon: {
    height: 32,
    marginBottom: "1.5rem",
  },
  details: {
    padding: "2rem 0 1rem",
  },
  detailsTitle: {
    color: "#2196f3",
    marginBottom: "1rem",
  },
};

const WhyUs = (props) => {
  const { classes } = props;

  const whyUsDetails = ({ icon, title, content }) => {
    return (
      <div className={classes.details} key={title}>
        <img src={icon} className={classes.icon} alt="Icon" />

        <Typography variant="title" className={classes.detailsTitle}>
          {title}
        </Typography>

        <Typography
          variant="body1"
          align="justify"
          dangerouslySetInnerHTML={{ __html: content }}
        ></Typography>
      </div>
    );
  };

  const renderWhyUs = () => {
    return whyUsContents.map((content) => {
      return whyUsDetails(content);
    });
  };

  return (
    <div>
      <Typography
        variant="headline"
        color="secondary"
        style={{ fontWeight: "bold" }}
      >
        Why FansFirst?
      </Typography>

      {renderWhyUs()}
    </div>
  );
};

export default withStyles(styles)(WhyUs);
