import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
  List,
  Divider,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";

const sellRequestWarningPopup = (props) => {
  const {
    onProceed,
    onUpdate,
    open,
    priceWarning,
    singleQuantityWarning,
    largeQuantityWarning,
    quantity,
    games
  } = props;

  const handleProceed = () => {
    onProceed();
  };

  const handleUpdate = () => {
    onUpdate();
  };

  const renderGames = () => {
    return (
      <List dense>
        {games.map((game) => {
          const regularGameDate = moment.tz(game.date, game.timezone)
            .format("MMM DD, YYYY");
          const playoffsGameDate = game.game;
          const gameDate =
            game.isPlayoffs && !game.showOpponent
              ? playoffsGameDate
              : regularGameDate;

          const priceDifference = parseFloat(game.priceDifference * 100).toFixed(2);

          return (
            <React.Fragment key={game.id}>
              <ListItem>
                <ListItemText
                  primary={
                    gameDate !== null
                      ? game.shortName
                      : `${game.shortName} ${gameDate}`
                  }
                  secondary={gameDate}
                />
                <ListItemSecondaryAction style={{ top: 'auto' }}>
                  <Typography variant="caption" gutterBottom style={{ marginBottom: '3em' }}>
                    CAD$ {parseFloat(game.price).toFixed(2)} <b>({priceDifference}%)</b>
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    );
  }

  var message = "";
  if (priceWarning && (singleQuantityWarning || largeQuantityWarning)) {
    message += `You entered a price that is significantly cheaper than similar seats and you entered ${quantity} ${quantity == 1 ? "seat" : "seats"
      }. Is that correct:`;
  } else if (priceWarning) {
    message +=
      "You entered a price that is significantly cheaper than similar seats, is that correct:";
  } else if (singleQuantityWarning) {
    message += "You entered 1 seat, is that correct?";
  } else if (largeQuantityWarning) {
    message += `You entered ${quantity} seats, is that correct?`;
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="sm">
      <DialogTitle id="simple-dialog-title">Question?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {renderGames()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProceed} autoFocus>
          Proceed Anyway
        </Button>
        <Button onClick={handleUpdate} color="primary" autoFocus>
          Update Details
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default sellRequestWarningPopup;
