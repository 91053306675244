import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, MenuItem, Paper, InputAdornment, ListSubheader } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TEAMS from "../../constants/Teams";

const styles = (theme) => ({
  searchContainer: {
    width: "100%",
    height: "53px",
    marginTop: "20px",
    margin: "20px auto",
    background: "rgba(119, 119, 119, 0.5)",
    borderRadius: "5px",
    padding: "4px",
    border: "0",
    position: "relative",
    lineHeight: "0.20357em",
    [theme.breakpoints.down('sm')]: {
      width: "calc(100% - 30px)",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  searchInput: {
    width: "370px",
    padding: "12px 8px",
    float: "left",
    border: "0",
    background: "#fff",
    borderRadius: "5px 0 0 5px",
    fontSize: "1.125rem",
    fontWeight: "400",
    [theme.breakpoints.down('sm')]: {
      width: "calc(100% - 20px)",
      padding: "10px",
      fontSize: "1rem",
    },
  },
  endAdornment: {
    maxHeight: "45px",
    width: "100px",
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: "100px",
    },
  },
  button: {
    position: "relative",
    float: "right",
    border: "0",
    padding: "0",
    cursor: "pointer",
    height: "45px",
    width: "100px",
    color: "#fff",
    background: "#2196f3",
    textTransform: "none",
    borderRadius: "0 5px 5px 0",
    fontSize: "1rem",
    "&:hover": {
      background: "#41A6FF",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      fontSize: "1rem",
    },
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    margin: "10px 0",
    paddingBottom: "10px",
    width: "calc(100% - 10px)",
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.3)",
    borderRadius: "5px",
    boxSizing: "border-box",
    [theme.breakpoints.down('sm')]: {
      width: "calc(100% - 10px)",
    },
  },
  subHeader: {
    fontSize: "0.9rem",
    textAlign: "left",
    paddingLeft: "16px",
    textTransform: "uppercase",
    margin: "auto 16px",
    borderBottom: "1px solid #D5DEE9",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.8rem",
    },
  },
  menuItems: {
    fontSize: "1rem",
    margin: "auto 14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    }
  },
});

const SearchAutocomplete = (props) => {
  const { classes } = props;
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const paperRef = useRef(null);

  useEffect(() => { 
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscPress);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscPress);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [filteredOptions, selectedIndex]);

  const handleKeyDown = (event) => {
    if (filteredOptions.length > 0) {
      if (event.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) =>
          prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
        );
      } else if (event.key === 'Enter') {
        if (selectedIndex >= 0 && selectedIndex < filteredOptions.length) {
          handleOptionSelect(filteredOptions[selectedIndex]);
        }
      }
    }
  };

  const handleEscPress = (event) => {
    if (event.key === 'Escape') {
      setFilteredOptions([]);
    }
  };

  const handleOutsideClick = (event) => {
    if (
      paperRef.current &&
      typeof event.target.value === 'undefined'
    ) {
      setFilteredOptions([]);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      const newFilteredOptions = TEAMS.filter((team) =>
        team.fullName.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(newFilteredOptions);
      setSelectedIndex(-1);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleOptionSelect = (option) => {
    setInputValue(option.fullName);
    setFilteredOptions([]);
    window.location.href = `/${option.homeTeamSlug}/games`;
  };

  const groupedFilteredOptions = filteredOptions.reduce((acc, option) => {
    const group = acc[option.category] || [];
    group.push(option);
    acc[option.category] = group;
    return acc;
  }, {});

  return (
    <div className={classes.searchContainer}>
      <TextField
        variant="outlined"
        placeholder="Search teams"
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.searchInput,
          },
          endAdornment: (
            <InputAdornment position="end" className={classes.endAdornment}>
              <Button
                color="primary"
                className={classes.button}
              >
                Search
              </Button>
            </InputAdornment>
          ),
        }}
      />
      {filteredOptions.length > 0 && (
        <Paper className={classes.paper} square ref={paperRef}>
          {Object.keys(groupedFilteredOptions).map((category, index) => (
            <React.Fragment key={index}>
              <ListSubheader className={classes.subHeader}>{category}</ListSubheader>
              {groupedFilteredOptions[category].map((option, i) => (
                <MenuItem
                  key={i}
                  selected={selectedIndex === i}
                  onClick={() => handleOptionSelect(option)}
                  className={classes.menuItems}
                >
                  {option.fullName}
                </MenuItem>
              ))}
            </React.Fragment>
          ))}
        </Paper>
      )}
    </div>
  );
};

export default withStyles(styles)(SearchAutocomplete);