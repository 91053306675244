const zones = [
    {
        label: "Field 1",
    },
    {
        label: "Field 2",
    },
    {
        label: "Field 3",
    },
    {
        label: "Field 4",
    },
    {
        label: "Upper Zone XX",
    },
    {
        label: "Upper Zone WW",
    },
    {
        label: "Upper Zone VV",
    },
    {
        label: "Upper Zone UU",
    },
    {
        label: "Upper Zone TT",
    },
    {
        label: "Upper Zone SS",
    },
    {
        label: "Upper Zone RR",
    },
    {
        label: "Upper Zone QQ",
    },
    {
        label: "Upper Zone PP",
    },
    {
        label: "Upper Zone NN",
    },
    {
        label: "Upper Zone MM",
    },
    {
        label: "Middle Zone 101",
    },
    {
        label: "Middle Zone 102",
    },
    {
        label: "Middle Zone 103",
    },
    {
        label: "Middle Zone 104",
    },
    {
        label: "Middle Zone 105",
    },
    {
        label: "Middle Zone 106",
    },
    {
        label: "Middle Zone 107",
    },
    {
        label: "Middle Zone 108",
    },
    {
        label: "Middle Zone 109",
    },
    {
        label: "Middle Zone 110",
    },
    {
        label: "Middle Zone 111",
    },
    {
        label: "Lower Zone Z",
    },
    {
        label: "Lower Zone Y",
    },
    {
        label: "Lower Zone X",
    },
    {
        label: "Lower Zone W",
    },
    {
        label: "Lower Zone V",
    },
    {
        label: "Lower Zone U",
    },
    {
        label: "Lower Zone T",
    },
    {
        label: "Lower Zone S",
    },
    {
        label: "Lower Zone R",
    },
    {
        label: "Lower Zone Q",
    },
    {
        label: "Lower Zone P",
    },
    {
        label: "Lower Zone N",
    },
    {
        label: "Lower Zone M",
    },
    {
        label: "Lower Zone L",
    },
    {
        label: "Lower Zone K",
    },
    {
        label: "Upper Zone JJ",
    },
    {
        label: "Upper Zone HH",
    },
    {
        label: "Upper Zone GG",
    },
    {
        label: "Upper Zone FF",
    },
    {
        label: "Upper Zone EE",
    },
    {
        label: "Upper Zone DD",
    },
    {
        label: "Upper Zone CC",
    },
    {
        label: "Upper Zone BB",
    },
    {
        label: "Upper Zone AA",
    },
    {
        label: "Lower Zone J",
    },
    {
        label: "Lower Zone H",
    },
    {
        label: "Lower Zone G",
    },
    {
        label: "Lower Zone F",
    },
    {
        label: "Lower Zone E",
    },
    {
        label: "Lower Zone D",
    },
    {
        label: "Lower Zone C",
    },
    {
        label: "Lower Zone B",
    },
    {
        label: "Lower Zone A",
    },
];

export default zones;