import React, { Component } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { withStyles, Tooltip, Button } from "@material-ui/core";
import { renderSeatNumbers, renderZoneName, renderRowName } from "../libs/shared/seat-helpers";
import { getFormattedTime } from "../libs/shared/grid-helper";
import { getTeamName } from "../libs/shared/team-helper";
import StatusInfoDialog from "../components/StatusInfoDialog";

const styles = (theme) => ({
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    '& a': {
      color: "#000000",
      textDecoration: "none",
    },
    '& a:hover': { 
      color: "#2196f3",
    },
  },
  subTitleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: "0.875rem",
  },
  infoButton: {
    padding: '6px 0',
    color: "#2196f3",
    textTransform: 'none',
    fontSize: 14,
    '&:focus': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  }
});

class UpcomingOrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      copyText: "Copy order number to clipboard?",
      isStatusInfoOpen: false,
      order: null,
    };
  }

  componentWillMount() {
    const { orders } = this.props;
    const sortedUpcoming = _.chain(orders).orderBy("game.date", "asc").value();
    this.setState({
      orders: sortedUpcoming
    });
  }

  componentWillReceiveProps(nextProps) {
    const { orders } = nextProps;
    const sortedUpcoming = _.chain(orders).orderBy("game.date", "asc").value();
    this.setState({
      orders: sortedUpcoming
    });
  }

  getGameDate(game) {
    return moment.tz(game.date, game.timezone).format("MMM D, YYYY");
  }

  getGameTime(game) {
    return getFormattedTime(moment.tz(game.date, game.timezone).format("ddd • h:mm A"), game);
  }

  getGameName(order) {
    if (order.game.isTbd || order.game.isPlayoffs) {
      return order.game.longName;
    }
    const team1 = order.game.opponent;
    const team2 = getTeamName(order.game.homeTeamSlug);
  
    return `${team1} vs. ${team2 ? team2 : '-'}`;
  }

  copyText = (event) => {
    const copyText = event.target.innerHTML;
    navigator.clipboard.writeText(copyText);
    this.setState({
      copyText: `Copied ${copyText} to clipboard!`,
    });
  };

  handleTooltipOrder = () => {
    this.setState({
      copyText: "Copy order number to clipboard?",
    });
  };

  handleClickOpen = (order) => {
    if (this.state.order !== order || !this.state.isStatusInfoOpen) {
      this.setState({
        isStatusInfoOpen: true,
        order: order,
      });
    }
  };

  handleClose = () => {
    this.setState({ isStatusInfoOpen: false });
  };

  renderTicketStatus = order => {
    const { classes } = this.props;
    if (order.isRefunded) {
      return (
        <Typography className={classes.subTitleText}>
          Order was Refunded<br /><Button onClick={() => this.handleClickOpen(order)} disableRipple className={classes.infoButton}>Click for more info</Button>
        </Typography>
      );
    } else if (order.isSent) {
      const transferredDetails = order.transferredDetails;
      const status = (
        <Typography className={classes.subTitleText}>
          Tickets have been transferred
        </Typography>
      );
      const details = transferredDetails
        ? (
          <Typography className={classes.subTitleText}>
            to: {transferredDetails.email} via {transferredDetails.transferMethod}
          </Typography>
        )
        : null;
      const deliveryTime = order.dateSent
      ? (
        <Typography className={classes.subTitleText}>
          on {moment.tz(Number(order.dateSent), "America/Edmonton").format("MMM DD, YYYY h:mm A")}
        </Typography>
      )
      : null;
      const moreInfo = (
        <Typography className={classes.subTitleText}>
          <Button onClick={() => this.handleClickOpen(order)} disableRipple className={classes.infoButton}>Click for more info</Button>
        </Typography>
      );
      return (
        <React.Fragment>
          {status}
          {details}
          {deliveryTime}
          {moreInfo}
        </React.Fragment>
      );
    } else {
      return (
        <Typography className={classes.subTitleText}>
          Still waiting for the seller<br /> <Button onClick={() => this.handleClickOpen(order)} disableRipple className={classes.infoButton}>Click for more info</Button>
        </Typography>
      );
    }
  };

  renderTable(orders) {
    const { classes } = this.props;
    const rowsToDisplay = orders.slice(0, 100);

    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow style={{ fontFamily: "Nunito Sans" }}>
              <TableCell style={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Order Number
                </Typography>
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Game
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seat Details
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Price (CAD)
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seats
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Total (CAD)
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Seat Numbers
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#5F666F",
                    fontSize: "14px",
                  }}
                >
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay.map((order, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    padding="checkbox"
                  >
                    <Typography className={classes.subTitleText}>
                      <Tooltip
                        disableFocusListener
                        title={this.state.copyText}
                        onOpen={this.handleTooltipOrder}
                        onClose={this.handleTooltipOrder}
                      >
                        <span
                          id={`${order.id}-${order.trackingNumber}`}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => this.copyText(e)}
                        >
                          {order.trackingNumber ? order.trackingNumber : "-"}
                        </span>
                      </Tooltip>
                    </Typography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: "10px 24px" }}
                  >
                    <Typography
                      className={classes.titleText}
                      style={{ color: "#00A0FF", textTransform: "uppercase" }}
                    >
                      {order.game.isTbd
                        ? "TBD"
                        : this.getGameDate(order.game)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {order.game.isTbd
                        ? null
                        : this.getGameTime(order.game)}
                    </Typography>
                    <Typography className={classes.titleText}>
                      <a href={`/seats/${order.game.homeTeamSlug}/${order.game.id}`}>
                        {this.getGameName(order)}
                      </a>
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                    <Typography className={classes.titleText}>
                    {renderZoneName(order.seat.zone, order.seat.zoneNo, order.seat.row, order.game.homeTeamSlug, order.game.isSpecial)}
                    </Typography>
                    <Typography className={classes.subTitleText}>
                      Row {renderRowName(order.seat.zone, order.seat.row, order.game.homeTeamSlug, order.game.isSpecial)}{" "}
                    </Typography>
                    {order.isAisleSeat ?
                    (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Aisle Seats
                      </Typography>
                    ) : null}
                    {order.isWheelchair ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Wheelchair
                      </Typography>
                    ) : null}
                    {order.isObstructedView ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "rgb(33, 150, 243)",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Obstructed View
                      </Typography>
                    ) : null}
                    {order.isInstantDelivery ? (
                      <Typography
                        style={{
                          display: "inline-block",
                          color: "#ff1744",
                          fontStyle: "oblique",
                          whiteSpace: "nowrap",
                        }}
                        variant="caption"
                      >
                        Instant Delivery
                      </Typography>
                    ) : null}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "right" }}
                  >
                    <Typography className={classes.subTitleText}>
                      ${(order.ticketPrice).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    <Typography className={classes.subTitleText}>
                      {order.noOfSeats}
                    </Typography>
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "right" }}
                  >
                    <Typography className={classes.subTitleText}>
                      ${(order.ticketPrice * order.noOfSeats).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                    style={{ textAlign: "center" }}
                  >
                    {renderSeatNumbers(order.seat, false, "-", true)}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.subTitleText}
                  >
                    {this.renderTicketStatus(order)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    const { orders, isStatusInfoOpen, order } = this.state;

    return (
      <React.Fragment>
        {orders.length > 0 && this.renderTable(orders)}
        {orders.length === 0 && (
          <Typography
            style={{ margin: "2rem 0" }}
            variant="subheading"
            align="center"
            color="textSecondary"
          >
            No Upcoming Orders
          </Typography>
        )}
        {isStatusInfoOpen && (
          <StatusInfoDialog
            order={order}
            isOpen={isStatusInfoOpen}
            handleClose={() => this.handleClose()}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UpcomingOrdersTable);
