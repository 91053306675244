import React from "react";
import Helmet from "react-helmet";
import WhyUs from "../components/WhyUs";

import Grid from "@material-ui/core/Grid";

const WhyUsPage = () => {
  const currentURL = window.location.href;
  return (
    <Grid container justify="center">
      <Helmet>
        <title>Why FansFirst?</title>
        <meta name="description" content="Why FansFirst?" />
        <meta name="keywords" content={`why fansfirst, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Why FansFirst?" />
        <meta property="og:description" content="Why FansFirst?" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentURL} />
        <link rel="canonical" href={currentURL} />
      </Helmet>
      <Grid item xs={12} md={6}>
        <main style={{ marginTop: "5rem" }}>
          <WhyUs />
        </main>
      </Grid>
    </Grid>
  );
};

export default WhyUsPage;
