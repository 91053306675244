export const getZones = rsr => {
    let zones = [];
    var _101 = {
        path:
          'M 1046.00,841.00 C 1046.00,841.00 1046.00,803.00 1046.00,803.00 1046.00,803.00 1047.00,788.00 1047.00,788.00 1047.00,788.00 1047.00,767.00 1047.00,767.00 1047.00,767.00 1047.00,751.00 1047.00,751.00 1047.00,751.00 1048.60,745.02 1048.60,745.02 1048.60,745.02 1056.00,744.00 1056.00,744.00 1056.00,744.00 1181.00,744.00 1181.00,744.00 1181.00,744.00 1181.00,841.00 1181.00,841.00 1181.00,841.00 1046.00,841.00 1046.00,841.00 Z',
        attr: {
          id: '_101',
          'data-name': '101',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "101", zone: 'Lower Zone', zoneId: "1" },
      };
      var _102 = {
        path:
          'M 1027.00,886.00 C 1027.00,886.00 1040.69,865.00 1040.69,865.00 1040.69,865.00 1046.16,849.57 1046.16,849.57 1046.16,849.57 1054.00,848.00 1054.00,848.00 1054.00,848.00 1180.00,848.00 1180.00,848.00 1179.83,867.20 1171.06,890.85 1165.00,909.00 1165.00,909.00 1113.00,886.86 1113.00,886.86 1110.99,885.97 1103.43,882.20 1102.02,882.13 1099.39,881.98 1097.05,884.16 1098.17,886.80 1099.52,889.99 1110.53,894.13 1114.00,895.70 1114.00,895.70 1161.00,916.00 1161.00,916.00 1158.38,927.16 1145.19,947.36 1138.29,957.00 1136.39,959.66 1131.32,967.77 1127.96,967.81 1125.66,967.84 1121.84,964.32 1120.00,962.88 1120.00,962.88 1100.00,946.41 1100.00,946.41 1100.00,946.41 1027.00,886.00 1027.00,886.00 Z',
        attr: {
          id: '_102',
          'data-name': '102',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "102", zone: 'Lower Zone', zoneId: "1" },
      };
      var _103 = {
        path:
          'M 982.00,910.00 C 991.95,906.68 999.79,904.38 1009.00,898.99 1011.96,897.26 1018.96,891.85 1022.00,892.28 1024.60,892.65 1028.90,896.67 1031.00,898.41 1031.00,898.41 1049.00,913.41 1049.00,913.41 1049.00,913.41 1096.00,952.59 1096.00,952.59 1102.36,957.88 1118.51,970.56 1123.00,976.00 1110.93,988.07 1106.14,993.37 1092.00,1003.58 1086.76,1007.36 1082.28,1011.17 1076.00,1013.00 1076.00,1013.00 1053.75,968.00 1053.75,968.00 1052.42,965.34 1047.22,952.40 1042.70,958.36 1040.41,961.39 1046.64,970.79 1048.25,974.00 1048.25,974.00 1070.00,1018.00 1070.00,1018.00 1070.00,1018.00 1059.00,1023.68 1059.00,1023.68 1047.91,1028.95 1021.60,1039.96 1010.00,1041.00 1010.00,1041.00 982.00,910.00 982.00,910.00 Z',
        attr: {
          id: '_103',
          'data-name': '103',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "103", zone: 'Lower Zone', zoneId: "1" },
      };
      var _104 = {
        path:
          'M 923.00,909.00 C 923.00,909.00 962.00,909.58 962.00,909.58 962.00,909.58 973.28,910.99 973.28,910.99 973.28,910.99 976.40,920.00 976.40,920.00 976.40,920.00 981.58,944.00 981.58,944.00 981.58,944.00 996.00,1011.00 996.00,1011.00 996.00,1011.00 1002.00,1044.00 1002.00,1044.00 986.19,1046.09 941.66,1045.00 923.00,1045.00 923.00,1045.00 923.00,909.00 923.00,909.00 Z',
        attr: {
          id: '_104',
          'data-name': '104',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "104", zone: 'Lower Zone', zoneId: "1" },
      };
      var _105 = {
        path:
          'M 871.00,909.00 C 871.00,909.00 916.00,909.00 916.00,909.00 916.00,909.00 916.00,1045.00 916.00,1045.00 916.00,1045.00 823.00,1045.00 823.00,1045.00 823.00,1045.00 823.00,928.00 823.00,928.00 823.00,928.00 871.00,928.00 871.00,928.00 871.00,928.00 871.00,909.00 871.00,909.00 Z',
        attr: {
          id: '_105',
          'data-name': '105',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "105", zone: 'Lower Zone', zoneId: "1" },
      };
      var _106 = {
        path:
          'M 722.00,928.00 C 722.00,928.00 816.00,928.00 816.00,928.00 816.00,928.00 816.00,1045.00 816.00,1045.00 816.00,1045.00 722.00,1045.00 722.00,1045.00 722.00,1045.00 722.00,928.00 722.00,928.00 Z',
        attr: {
          id: '_106',
          'data-name': '106',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "106", zone: 'Lower Zone', zoneId: "1" },
      };
      var _107 = {
        path:
          'M 621.00,909.00 C 621.00,909.00 664.00,909.00 664.00,909.00 664.00,909.00 664.00,928.00 664.00,928.00 664.00,928.00 715.00,928.00 715.00,928.00 715.00,928.00 715.00,1045.00 715.00,1045.00 715.00,1045.00 621.00,1045.00 621.00,1045.00 621.00,1045.00 621.00,909.00 621.00,909.00 Z',
        attr: {
          id: '_107',
          'data-name': '107',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "107", zone: 'Lower Zone', zoneId: "1" },
      };
      var _108 = {
        path:
          'M 563.00,909.00 C 563.00,909.00 614.00,909.00 614.00,909.00 614.00,909.00 614.00,1045.00 614.00,1045.00 614.00,1045.00 556.00,1045.00 556.00,1045.00 556.00,1045.00 542.00,1043.97 542.00,1043.97 542.00,1043.97 536.61,1041.57 536.61,1041.57 536.61,1041.57 537.88,1031.00 537.88,1031.00 537.88,1031.00 543.00,1006.00 543.00,1006.00 543.00,1006.00 563.00,909.00 563.00,909.00 Z',
        attr: {
          id: '_108',
          'data-name': '108',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "108", zone: 'Lower Zone', zoneId: "1" },
      };
      var _109 = {
        path:
          'M 470.00,930.00 C 470.00,930.00 502.00,903.42 502.00,903.42 504.56,901.32 512.12,894.50 515.00,894.24 517.75,894.00 524.96,899.21 528.00,900.73 537.12,905.30 545.09,907.13 555.00,909.00 555.00,909.00 547.60,947.00 547.60,947.00 547.60,947.00 531.79,1024.00 531.79,1024.00 531.79,1024.00 524.00,1061.00 524.00,1061.00 502.30,1056.92 479.26,1049.96 460.00,1039.00 461.88,1033.05 467.85,1023.78 471.15,1018.00 471.15,1018.00 492.15,981.00 492.15,981.00 494.57,976.75 503.52,963.33 502.30,959.04 501.34,955.67 494.88,951.78 492.00,949.63 485.23,944.55 474.83,936.68 470.00,930.00 Z',
        attr: {
          id: '_109',
          'data-name': '109',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "109", zone: 'Lower Zone', zoneId: "1" },
      };
      var _110 = {
        path:
          'M 429.00,848.00 C 429.00,848.00 490.00,848.00 490.00,848.00 491.45,865.29 500.03,874.60 509.00,888.00 509.00,888.00 480.00,912.00 480.00,912.00 474.71,916.27 469.50,921.80 463.00,924.00 456.54,915.36 451.22,909.69 445.45,900.00 435.12,882.63 431.60,867.68 429.00,848.00 Z',
        attr: {
          id: '_110',
          'data-name': '110',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "110", zone: 'Lower Zone', zoneId: "1" },
      };
      var _111 = {
        path:
          'M 429.00,744.00 C 429.00,744.00 489.00,744.00 489.00,744.00 489.00,744.00 489.00,825.00 489.00,825.00 489.00,825.00 488.00,841.00 488.00,841.00 488.00,841.00 429.00,841.00 429.00,841.00 429.00,841.00 429.00,744.00 429.00,744.00 Z',
        attr: {
          id: '_111',
          'data-name': '111',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "111", zone: 'Lower Zone', zoneId: "1" },
      };
      var _112 = {
        path:
          'M 429.00,737.00 C 429.00,737.00 433.15,715.00 433.15,715.00 436.71,701.67 443.00,689.38 450.67,678.00 453.05,674.46 460.57,662.47 465.01,662.90 467.79,663.17 475.54,670.04 478.00,672.08 478.00,672.08 509.00,697.00 509.00,697.00 498.88,711.58 490.59,717.56 489.00,737.00 489.00,737.00 429.00,737.00 429.00,737.00 Z',
        attr: {
          id: '_112',
          'data-name': '112',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "112", zone: 'Lower Zone', zoneId: "1" },
      };
      var _113 = {
        path:
          'M 470.00,563.00 C 485.48,555.79 509.03,544.43 526.00,543.00 526.00,543.00 554.00,674.00 554.00,674.00 545.47,674.77 534.43,679.62 527.00,683.87 524.33,685.40 517.37,690.81 515.00,690.86 511.96,690.91 505.50,685.01 503.00,683.00 495.96,677.35 474.49,660.76 470.00,655.00 476.84,648.18 484.16,641.94 492.00,636.29 494.57,634.44 502.76,629.24 503.87,626.82 505.73,622.76 498.94,613.65 496.85,610.00 496.85,610.00 470.00,563.00 470.00,563.00 Z',
        attr: {
          id: '_113',
          'data-name': '113',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "113", zone: 'Lower Zone', zoneId: "1" },
      };
      var _114 = {
        path:
          'M 533.00,540.00 C 533.00,540.00 615.00,540.00 615.00,540.00 615.00,540.00 615.00,674.00 615.00,674.00 615.00,674.00 570.00,674.00 570.00,674.00 570.00,674.00 562.72,673.01 562.72,673.01 562.72,673.01 559.60,664.00 559.60,664.00 559.60,664.00 555.12,643.00 555.12,643.00 555.12,643.00 543.58,589.00 543.58,589.00 543.58,589.00 533.00,540.00 533.00,540.00 Z',
        attr: {
          id: '_114',
          'data-name': '114',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "114", zone: 'Lower Zone', zoneId: "1" },
      };
      var _115 = {
        path:
          'M 622.00,540.00 C 622.00,540.00 715.00,540.00 715.00,540.00 715.00,540.00 715.00,674.00 715.00,674.00 715.00,674.00 622.00,674.00 622.00,674.00 622.00,674.00 622.00,540.00 622.00,540.00 Z',
        attr: {
          id: '_115',
          'data-name': '115',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "115", zone: 'Lower Zone', zoneId: "1" },
      };
      var _116 = {
        path:
          'M 722.00,540.00 C 722.00,540.00 816.00,540.00 816.00,540.00 816.00,540.00 816.00,657.00 816.00,657.00 816.00,657.00 722.00,657.00 722.00,657.00 722.00,657.00 722.00,540.00 722.00,540.00 Z',
        attr: {
          id: '_116',
          'data-name': '116',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "116", zone: 'Lower Zone', zoneId: "1" },
      };
      var _117 = {
        path:
          'M 823.00,540.00 C 823.00,540.00 915.00,540.00 915.00,540.00 915.00,540.00 915.00,674.00 915.00,674.00 915.00,674.00 823.00,674.00 823.00,674.00 823.00,674.00 823.00,540.00 823.00,540.00 Z',
        attr: {
          id: '_117',
          'data-name': '117',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "117", zone: 'Lower Zone', zoneId: "1" },
      };
      var _118 = {
        path:
          'M 922.00,540.00 C 922.00,540.00 1004.00,540.00 1004.00,540.00 1004.00,540.00 1000.35,556.00 1000.35,556.00 1000.35,556.00 990.58,601.00 990.58,601.00 990.58,601.00 975.00,674.00 975.00,674.00 975.00,674.00 922.00,674.00 922.00,674.00 922.00,674.00 922.00,540.00 922.00,540.00 Z',
        attr: {
          id: '_118',
          'data-name': '118',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "118", zone: 'Lower Zone', zoneId: "1" },
      };
      var _119 = {
        path:
          'M 1011.00,541.00 C 1022.74,542.65 1041.29,549.93 1052.00,555.25 1052.00,555.25 1069.00,564.00 1069.00,564.00 1069.00,564.00 1046.25,611.00 1046.25,611.00 1045.15,613.19 1041.23,620.06 1041.13,621.96 1040.98,624.61 1043.24,626.60 1045.80,625.60 1048.84,624.41 1053.16,614.19 1054.75,611.00 1054.75,611.00 1076.00,569.00 1076.00,569.00 1089.74,576.71 1115.05,596.50 1124.00,609.00 1124.00,609.00 1050.00,670.75 1050.00,670.75 1050.00,670.75 1031.00,686.59 1031.00,686.59 1029.08,688.18 1025.51,691.59 1023.00,691.68 1020.55,691.76 1012.65,685.80 1010.00,684.20 1001.78,679.24 992.56,675.35 983.00,674.00 983.00,674.00 990.58,636.00 990.58,636.00 990.58,636.00 1011.00,541.00 1011.00,541.00 Z',
        attr: {
          id: '_119',
          'data-name': '119',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "119", zone: 'Lower Zone', zoneId: "1" },
      };
      var _120 = {
        path:
          'M 1181.00,737.00 C 1181.00,737.00 1055.00,737.00 1055.00,737.00 1053.03,737.00 1049.31,737.26 1047.73,736.01 1045.96,734.60 1045.25,729.31 1044.56,727.00 1043.11,722.10 1040.16,715.39 1037.55,711.00 1036.02,708.43 1030.17,701.23 1030.19,699.01 1030.23,695.67 1038.40,689.92 1041.00,687.75 1041.00,687.75 1073.00,661.08 1073.00,661.08 1073.00,661.08 1113.00,627.75 1113.00,627.75 1115.94,625.30 1125.63,616.28 1128.99,616.32 1132.60,616.36 1137.76,624.21 1139.87,627.00 1146.36,635.58 1159.15,655.02 1162.00,665.00 1162.00,665.00 1112.00,686.85 1112.00,686.85 1108.82,688.22 1096.96,691.92 1099.17,696.77 1101.48,701.86 1112.44,694.94 1116.00,693.42 1116.00,693.42 1166.00,672.00 1166.00,672.00 1166.00,672.00 1174.85,700.00 1174.85,700.00 1174.85,700.00 1181.00,737.00 1181.00,737.00 Z',
        attr: {
          id: '_120',
          'data-name': '120',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "120", zone: 'Lower Zone', zoneId: "1" },
      };
      var _201 = {
        path:
          'M 1211.00,744.00 C 1211.00,744.00 1264.96,744.00 1264.96,744.00 1264.96,744.00 1264.96,760.00 1264.96,760.00 1264.96,760.00 1264.00,772.00 1264.00,772.00 1264.00,772.00 1264.00,841.00 1264.00,841.00 1264.00,841.00 1212.00,841.00 1212.00,841.00 1212.00,841.00 1211.00,820.00 1211.00,820.00 1211.00,820.00 1211.00,744.00 1211.00,744.00 Z',
        attr: {
          id: '_201',
          'data-name': '201',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "201", zone: 'Middle Zone', zoneId: "2" },
      };
      var _202 = {
        path:
          'M 1211.00,848.00 C 1211.00,848.00 1264.00,848.00 1264.00,848.00 1264.00,848.00 1264.00,860.00 1264.00,860.00 1263.96,884.50 1252.82,920.53 1245.00,944.00 1245.00,944.00 1208.00,928.15 1208.00,928.15 1205.35,927.01 1196.34,923.69 1195.12,921.51 1194.04,919.56 1195.29,916.91 1195.95,915.00 1195.95,915.00 1200.66,901.00 1200.66,901.00 1204.86,887.85 1210.70,861.54 1211.00,848.00 Z',
        attr: {
          id: '_202',
          'data-name': '202',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "202", zone: 'Middle Zone', zoneId: "2" },
      };
      var _203 = {
        path:
          'M 1190.00,929.00 C 1190.00,929.00 1236.00,948.00 1236.00,948.00 1236.00,948.00 1229.25,962.00 1229.25,962.00 1228.29,963.95 1226.71,966.67 1227.41,968.90 1228.91,973.66 1238.47,974.32 1237.69,980.04 1237.38,982.35 1233.46,987.78 1232.05,990.00 1232.05,990.00 1216.00,1014.00 1216.00,1014.00 1210.23,1011.40 1205.74,1005.78 1202.17,1007.11 1198.62,1008.43 1193.23,1018.63 1188.00,1017.83 1185.18,1017.40 1176.67,1009.61 1174.00,1007.42 1174.00,1007.42 1160.00,995.92 1160.00,995.92 1158.35,994.51 1155.22,992.17 1154.91,989.91 1154.49,986.89 1163.66,976.08 1165.86,973.00 1173.80,961.87 1185.54,941.78 1190.00,929.00 Z',
        attr: {
          id: '_203',
          'data-name': '203',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "203", zone: 'Middle Zone', zoneId: "2" },
      };
      var _204 = {
        path:
          'M 1147.00,996.00 C 1147.00,996.00 1163.00,1007.92 1163.00,1007.92 1163.00,1007.92 1190.00,1030.00 1190.00,1030.00 1180.31,1044.46 1155.38,1066.11 1141.00,1076.87 1134.71,1081.59 1122.17,1090.72 1115.00,1093.00 1115.00,1093.00 1090.00,1041.00 1090.00,1041.00 1107.16,1031.82 1135.50,1011.19 1147.00,996.00 Z',
        attr: {
          id: '_204',
          'data-name': '204',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "204", zone: 'Middle Zone', zoneId: "2" },
      };
      var _205 = {
        path:
          'M 1015.00,1068.00 C 1035.51,1066.28 1064.02,1053.17 1083.00,1045.00 1083.00,1045.00 1101.42,1083.00 1101.42,1083.00 1101.42,1083.00 1108.00,1098.00 1108.00,1098.00 1108.00,1098.00 1093.00,1105.14 1093.00,1105.14 1077.53,1112.03 1043.34,1124.64 1027.00,1125.00 1027.00,1125.00 1015.00,1068.00 1015.00,1068.00 Z',
        attr: {
          id: '_205',
          'data-name': '205',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "205", zone: 'Middle Zone', zoneId: "2" },
      };
      var _206 = {
        path:
          'M 923.00,1074.00 C 923.00,1074.00 976.00,1074.00 976.00,1074.00 976.00,1074.00 1008.00,1070.00 1008.00,1070.00 1008.00,1070.00 1020.00,1127.00 1020.00,1127.00 1020.00,1127.00 996.00,1129.00 996.00,1129.00 996.00,1129.00 923.00,1129.00 923.00,1129.00 923.00,1129.00 923.00,1074.00 923.00,1074.00 Z',
        attr: {
          id: '_206',
          'data-name': '206',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "206", zone: 'Middle Zone', zoneId: "2" },
      };
      var _207 = {
        path:
          'M 823.00,1074.00 C 823.00,1074.00 916.00,1074.00 916.00,1074.00 916.00,1074.00 916.00,1129.00 916.00,1129.00 916.00,1129.00 823.00,1129.00 823.00,1129.00 823.00,1129.00 823.00,1074.00 823.00,1074.00 Z',
        attr: {
          id: '_207',
          'data-name': '207',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "207", zone: 'Middle Zone', zoneId: "2" },
      };
      var _208 = {
        path:
          'M 722.00,1074.00 C 722.00,1074.00 816.00,1074.00 816.00,1074.00 816.00,1074.00 816.00,1129.00 816.00,1129.00 816.00,1129.00 722.00,1129.00 722.00,1129.00 722.00,1129.00 722.00,1074.00 722.00,1074.00 Z',
        attr: {
          id: '_208',
          'data-name': '208',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "208", zone: 'Middle Zone', zoneId: "2" },
      };
      var _209 = {
        path:
          'M 621.00,1073.00 C 621.00,1073.00 680.00,1073.00 680.00,1073.00 680.00,1073.00 715.00,1074.00 715.00,1074.00 715.00,1074.00 715.00,1129.00 715.00,1129.00 715.00,1129.00 621.00,1129.00 621.00,1129.00 621.00,1129.00 621.00,1073.00 621.00,1073.00 Z',
        attr: {
          id: '_209',
          'data-name': '209',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "209", zone: 'Middle Zone', zoneId: "2" },
      };
      var _210 = {
        path:
          'M 529.00,1071.00 C 529.00,1071.00 554.00,1073.00 554.00,1073.00 554.00,1073.00 614.00,1073.00 614.00,1073.00 614.00,1073.00 614.00,1129.00 614.00,1129.00 585.93,1129.00 543.26,1130.72 517.00,1126.00 517.00,1126.00 529.00,1071.00 529.00,1071.00 Z',
        attr: {
          id: '_210',
          'data-name': '210',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "210", zone: 'Middle Zone', zoneId: "2" },
      };
      var _211 = {
        path:
          'M 455.00,1046.00 C 461.22,1047.17 469.74,1052.00 476.00,1054.55 489.95,1060.22 507.27,1066.04 522.00,1069.00 522.00,1069.00 510.00,1125.00 510.00,1125.00 481.31,1119.60 452.39,1109.46 427.00,1095.00 427.00,1095.00 455.00,1046.00 455.00,1046.00 Z',
        attr: {
          id: '_211',
          'data-name': '211',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "211", zone: 'Middle Zone', zoneId: "2" },
      };
      var _212 = {
        path:
          'M 420.00,1091.00 C 403.31,1082.07 379.41,1063.34 366.00,1050.00 362.63,1046.64 351.52,1035.74 349.87,1032.00 347.90,1027.55 353.01,1024.60 356.00,1022.20 356.00,1022.20 380.00,1002.92 380.00,1002.92 382.30,1001.01 386.88,996.50 390.00,996.91 392.45,997.23 395.29,1000.33 397.00,1002.00 397.00,1002.00 408.00,1012.05 408.00,1012.05 420.46,1023.11 434.25,1033.20 449.00,1041.00 449.00,1041.00 420.00,1091.00 420.00,1091.00 Z',
        attr: {
          id: '_212',
          'data-name': '212',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "212", zone: 'Middle Zone', zoneId: "2" },
      };
      var _213 = {
        path:
          'M 323.00,944.00 C 323.00,944.00 348.00,933.00 348.00,933.00 348.00,933.00 372.15,973.00 372.15,973.00 372.15,973.00 382.84,988.96 382.84,988.96            378.00,995.08 362.00,1007.00 362.00,1007.00 362.00,1007.00 351.89,994.00 351.89,994.00 343.35,982.60 326.79,957.28 323.00,944.00 Z',
        attr: {
          id: '_213',
          'data-name': '213',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "213", zone: 'Middle Zone', zoneId: "2" },
      };
      var _214 = {
        path:
          'M 274.00,848.00 C 274.00,848.00 327.00,848.00 327.00,848.00 327.10,858.60 329.76,883.03 332.72,893.00 332.72,893.00 344.00,926.00 344.00,926.00 344.00,926.00 296.00,948.00 296.00,948.00 282.57,916.34 274.00,882.54 274.00,848.00 Z',
        attr: {
          id: '_214',
          'data-name': '214',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "214", zone: 'Middle Zone', zoneId: "2" },
      };
      var _215 = {
        path:
          'M 275.00,744.00 C 275.00,744.00 327.00,744.00 327.00,744.00 327.00,744.00 327.00,841.00 327.00,841.00 327.00,841.00 274.00,841.00 274.00,841.00 274.00,841.00 274.00,790.00 274.00,790.00 274.00,790.00 275.00,774.00 275.00,774.00 275.00,774.00 275.00,744.00 275.00,744.00 Z',
        attr: {
          id: '_215',
          'data-name': '215',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "215", zone: 'Middle Zone', zoneId: "2" },
      };
      var _216 = {
        path:
          'M 294.00,640.00 C 294.00,640.00 310.00,646.58 310.00,646.58 310.00,646.58 344.00,662.00 344.00,662.00 344.00,662.00 330.89,703.00 330.89,703.00 329.63,708.47 328.47,717.36 328.09,723.00 327.91,725.65 328.23,731.81 327.57,733.87 326.33,737.73 322.30,736.99 319.00,737.00 319.00,737.00 275.00,737.00 275.00,737.00 275.00,737.00 275.00,720.00 275.00,720.00 275.01,713.42 275.81,709.39 276.73,703.00 280.41,677.50 286.08,663.72 294.00,640.00 Z',
        attr: {
          id: '_216',
          'data-name': '216',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "216", zone: 'Middle Zone', zoneId: "2" },
      };
      var _217 = {
        path:
          'M 343.00,560.00 C 343.00,560.00 385.00,596.00 385.00,596.00 385.00,596.00 369.67,618.00 369.67,618.00 369.67,618.00 348.00,655.00 348.00,655.00 348.00,655.00 298.00,633.00 298.00,633.00 299.51,627.72 302.30,622.90 304.75,618.00 311.40,604.71 319.07,591.97 327.88,580.00 327.88,580.00 343.00,560.00 343.00,560.00 Z',
        attr: {
          id: '_217',
          'data-name': '217',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "217", zone: 'Middle Zone', zoneId: "2" },
      };
      var _218 = {
        path:
          'M 348.00,556.00 C 351.62,549.25 358.56,543.44 364.00,538.00 376.04,525.96 386.47,516.61 400.00,506.23 400.00,506.23 414.00,496.35 414.00,496.35            422.51,495.25 426.86,504.31 428.42,507.00 428.42,507.00 449.00,543.00 449.00,543.00 449.00,543.00 414.00,568.29 414.00,568.29 414.00,568.29 401.00,580.01 401.00,580.01 401.00,580.01 390.00,591.00 390.00,591.00 390.00,591.00 348.00,556.00 348.00,556.00 Z',
        attr: {
          id: '_218',
          'data-name': '218',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "218", zone: 'Middle Zone', zoneId: "2" },
      };
      var _219 = {
        path:
          'M 443.00,471.00 C 451.46,466.18 472.65,456.84 482.00,456.00 482.68,458.99 484.67,467.37 487.42,468.59 489.23,469.75 492.12,468.83 494.00,468.59 494.00,468.59 509.00,465.00 509.00,465.00 509.00,465.00 520.00,516.00 520.00,516.00 508.55,517.61 487.87,524.79 477.00,529.43 477.00,529.43 464.00,535.26 464.00,535.26 462.25,536.10 459.03,537.95 457.10,537.77 453.80,537.46 450.81,530.69 449.28,528.00 445.08,520.60 432.61,500.76 431.00,494.00 431.00,494.00 449.00,485.00 449.00,485.00 449.00,485.00 443.00,471.00 443.00,471.00 Z',
        attr: {
          id: '_219',
          'data-name': '219',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "219", zone: 'Middle Zone', zoneId: "2" },
      };
      var _220 = {
        path:
          'M 514.00,458.00 C 547.44,453.59 581.31,455.00 615.00,455.00 615.00,455.00 615.00,511.00 615.00,511.00 615.00,511.00 552.00,511.00 552.00,511.00 552.00,511.00 527.00,514.00 527.00,514.00 525.04,500.07 518.43,471.31 514.00,458.00 Z',
        attr: {
          id: '_220',
          'data-name': '220',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "220", zone: 'Middle Zone', zoneId: "2" },
      };
      var _221 = {
        path:
          'M 622.00,455.04 C 622.00,455.04 634.00,455.04 634.00,455.04 634.00,455.04 646.00,456.00 646.00,456.00 646.00,456.00 715.00,456.00 715.00,456.00 715.00,456.00 715.00,511.00 715.00,511.00 715.00,511.00 622.00,511.00 622.00,511.00 622.00,511.00 622.00,455.04 622.00,455.04 Z',
        attr: {
          id: '_221',
          'data-name': '221',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "221", zone: 'Middle Zone', zoneId: "2" },
      };
      var _222 = {
        path:
          'M 722.00,456.00 C 722.00,456.00 779.00,456.00 779.00,456.00 779.00,456.00 816.00,457.00 816.00,457.00 816.00,457.00 816.00,511.00 816.00,511.00 816.00,511.00 722.00,511.00 722.00,511.00 722.00,511.00 722.00,456.00 722.00,456.00 Z',
        attr: {
          id: '_222',
          'data-name': '222',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "222", zone: 'Middle Zone', zoneId: "2" },
      };
      var _223 = {
        path:
          'M 823.00,457.00 C 823.00,457.00 915.00,457.00 915.00,457.00 915.00,457.00 915.00,511.00 915.00,511.00 915.00,511.00 823.00,511.00 823.00,511.00 823.00,511.00 823.00,457.00 823.00,457.00 Z',
        attr: {
          id: '_223',
          'data-name': '223',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "223", zone: 'Middle Zone', zoneId: "2" },
      };
      var _224 = {
        path:
          'M 922.00,457.00 C 922.00,457.00 979.00,457.00 979.00,457.00 979.00,457.00 1007.00,457.00 1007.00,457.00 1007.00,457.00 1021.00,459.00 1021.00,459.00 1021.00,459.00 1009.00,515.00 1009.00,515.00 1009.00,515.00 975.00,511.00 975.00,511.00 975.00,511.00 922.00,511.00 922.00,511.00 922.00,511.00 922.00,457.00 922.00,457.00 Z',
        attr: {
          id: '_224',
          'data-name': '224',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "224", zone: 'Middle Zone', zoneId: "2" },
      };
      var _225 = {
        path:
          'M 1028.00,461.00 C 1036.97,461.73 1052.19,466.40 1061.00,469.33 1076.61,474.54 1091.62,479.82 1106.00,488.00 1106.00,488.00 1095.25,511.00 1095.25,511.00 1095.25,511.00 1081.00,540.00 1081.00,540.00 1059.46,530.72 1040.35,521.70 1017.00,517.00 1017.00,517.00 1028.00,461.00 1028.00,461.00 Z',
        attr: {
          id: '_225',
          'data-name': '225',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "225", zone: 'Middle Zone', zoneId: "2" },
      };
      var _226 = {
        path:
          'M 1113.00,492.00 C 1119.28,493.98 1125.40,498.33 1131.00,501.81 1145.06,510.53 1161.32,523.33 1173.00,535.00 1178.70,540.69 1187.08,547.55 1190.00,555.00 1190.00,555.00 1160.00,579.58 1160.00,579.58 1157.68,581.47 1150.69,587.84 1148.00,587.81 1145.72,587.78 1143.54,585.44 1142.00,583.98 1142.00,583.98 1131.00,573.73 1131.00,573.73 1118.97,563.42 1102.26,550.87 1088.00,544.00 1088.00,544.00 1113.00,492.00 1113.00,492.00 Z',
        attr: {
          id: '_226',
          'data-name': '226',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "226", zone: 'Middle Zone', zoneId: "2" },
      };
      var _227 = {
        path:
          'M 1195.00,560.00 C 1195.00,560.00 1210.67,580.00 1210.67,580.00 1210.67,580.00 1230.25,614.00 1230.25,614.00 1230.25,614.00 1238.00,632.00 1238.00,632.00 1238.00,632.00 1189.00,653.00 1189.00,653.00 1183.22,639.58 1174.42,623.81 1165.85,612.00 1165.85,612.00 1153.00,595.00 1153.00,595.00 1153.00,595.00 1195.00,560.00 1195.00,560.00 Z',
        attr: {
          id: '_227',
          'data-name': '227',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "227", zone: 'Middle Zone', zoneId: "2" },
      };
      var _228 = {
        path:
          'M 1192.00,660.00 C 1192.00,660.00 1224.00,646.14 1224.00,646.14 1224.00,646.14 1242.00,639.00 1242.00,639.00 1242.00,639.00 1248.31,657.00 1248.31,657.00 1248.31,657.00 1256.58,686.00 1256.58,686.00 1256.58,686.00 1262.72,718.00 1262.72,718.00 1262.72,718.00 1265.00,737.00 1265.00,737.00 1265.00,737.00 1210.00,737.00 1210.00,737.00 1209.55,716.22 1199.77,679.53 1192.00,660.00 Z',
        attr: {
          id: '_228',
          'data-name': '228',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "228", zone: 'Middle Zone', zoneId: "2" },
      };
      var _301 = {
        path:
          'M 1293.00,741.00 C 1293.00,741.00 1464.00,741.00 1464.00,741.00 1464.00,741.00 1464.00,771.00 1464.00,771.00 1464.00,771.00 1463.00,794.00 1463.00,794.00 1463.00,794.00 1464.00,807.00 1464.00,807.00 1464.00,807.00 1464.00,826.00 1464.00,826.00 1464.00,826.00 1464.00,845.00 1464.00,845.00 1464.00,845.00 1462.40,850.98 1462.40,850.98 1462.40,850.98 1455.00,852.00 1455.00,852.00 1455.00,852.00 1293.00,852.00 1293.00,852.00 1293.00,852.00 1293.00,741.00 1293.00,741.00 Z',
        attr: {
          id: '_301',
          'data-name': '301',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "301", zone: 'Upper Zone', zoneId: "3" },
      };
      var _302 = {
        path:
          'M 1293.00,859.00 C 1293.00,859.00 1463.00,859.00 1463.00,859.00 1463.00,859.00 1457.25,908.00 1457.25,908.00 1451.54,942.23 1443.19,976.14 1432.00,1009.00 1432.00,1009.00 1422.00,1036.00 1422.00,1036.00 1422.00,1036.00 1367.00,1010.31 1367.00,1010.31 1367.00,1010.31 1318.00,987.74 1318.00,987.74 1318.00,987.74 1288.00,973.74 1288.00,973.74 1288.00,973.74 1264.00,963.00 1264.00,963.00 1264.00,963.00 1274.31,936.00 1274.31,936.00 1281.47,915.88 1292.04,879.91 1293.00,859.00 Z',
        attr: {
          id: '_302',
          'data-name': '302',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "302", zone: 'Upper Zone', zoneId: "3" },
      };
      var _303 = {
        path:
          'M 1260.00,970.00 C 1260.00,970.00 1276.00,976.58 1276.00,976.58 1276.00,976.58 1315.00,994.74 1315.00,994.74 1315.00,994.74 1364.00,1017.31 1364.00,1017.31 1364.00,1017.31 1419.00,1043.00 1419.00,1043.00 1406.70,1073.75 1390.36,1104.45 1372.00,1132.00 1372.00,1132.00 1357.87,1152.00 1357.87,1152.00 1356.42,1153.95 1353.06,1158.99 1350.83,1159.68 1347.73,1160.63 1343.22,1156.09 1341.00,1154.25 1341.00,1154.25 1319.00,1135.75 1319.00,1135.75 1319.00,1135.75 1241.00,1070.25 1241.00,1070.25 1241.00,1070.25 1221.00,1053.41 1221.00,1053.41 1219.10,1051.83 1214.27,1048.31 1213.91,1045.83 1213.57,1043.50 1216.06,1040.72 1217.40,1039.00 1217.40,1039.00 1228.00,1025.00 1228.00,1025.00 1238.90,1009.70 1252.60,987.18 1260.00,970.00 Z',
        attr: {
          id: '_303',
          'data-name': '303',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "303", zone: 'Upper Zone', zoneId: "3" },
      };
      var _304 = {
        path:
        'M 1207.00,1052.00 C 1207.00,1052.00 1242.00,1080.59 1242.00,1080.59 1242.00,1080.59 1311.00,1138.59 1311.00,1138.59 1311.00,1138.59 1336.00,1159.59 1336.00,1159.59 1338.08,1161.32 1343.74,1165.30 1343.82,1168.09 1343.88,1170.68 1339.66,1175.07 1338.00,1177.00 1338.00,1177.00 1318.00,1199.00 1318.00,1199.00 1296.86,1220.18 1273.22,1239.87 1248.00,1256.05 1238.83,1261.93 1226.85,1269.76 1217.00,1274.00 1215.72,1267.19 1211.66,1261.93 1208.28,1256.00 1208.28,1256.00 1189.01,1223.00 1189.01,1223.00 1189.01,1223.00 1149.58,1155.00 1149.58,1155.00 1149.58,1155.00 1127.00,1116.00 1127.00,1116.00 1133.49,1112.87 1140.96,1108.03 1147.00,1104.00 1174.37,1085.76 1184.25,1074.75 1207.00,1052.00 Z',
        attr: {
          id: '_304',
          'data-name': '304',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "304", zone: 'Upper Zone', zoneId: "3" },
      };
      var _305 = {
        path:
          'M 1120.00,1120.00 C 1120.00,1120.00 1145.42,1163.00 1145.42,1163.00 1145.42,1163.00 1191.40,1242.00 1191.40,1242.00 1191.40,1242.00 1213.00,1277.00 1213.00,1277.00 1213.00,1277.00 1177.00,1293.58 1177.00,1293.58 1150.93,1304.51 1104.71,1319.09 1077.00,1323.00 1077.00,1323.00 1056.20,1213.00 1056.20,1213.00 1056.20,1213.00 1049.20,1176.00 1049.20,1176.00 1049.20,1176.00 1044.00,1148.00 1044.00,1148.00 1065.66,1143.64 1100.92,1130.86 1120.00,1120.00 Z',
        attr: {
          id: '_305',
          'data-name': '305',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "305", zone: 'Upper Zone', zoneId: "3" },
      };
      var _306 = {
        path:
          'M 933.00,1153.00 C 933.00,1153.00 976.00,1153.00 976.00,1153.00 976.00,1153.00 993.00,1152.00 993.00,1152.00 993.00,1152.00 1021.00,1152.00 1021.00,1152.00 1021.00,1152.00 1037.00,1150.00 1037.00,1150.00 1037.00,1150.00 1045.20,1195.00 1045.20,1195.00 1045.20,1195.00 1061.25,1280.00 1061.25,1280.00 1061.25,1280.00 1067.20,1311.00 1067.20,1311.00 1067.72,1313.73 1069.57,1321.21 1067.96,1323.42 1066.39,1325.59 1060.56,1325.90 1058.00,1326.27 1048.24,1327.68 1036.83,1328.98 1027.00,1329.00 1027.00,1329.00 933.00,1329.00 933.00,1329.00 933.00,1329.00 933.00,1153.00 933.00,1153.00 Z',
        attr: {
          id: '_306',
          'data-name': '306',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "306", zone: 'Upper Zone', zoneId: "3" },
      };
      var _307 = {
        path:
          'M 826.00,1154.00 C 826.00,1154.00 860.00,1154.00 860.00,1154.00 860.00,1154.00 877.00,1153.00 877.00,1153.00 877.00,1153.00 926.00,1153.00 926.00,1153.00 926.00,1153.00 926.00,1329.00 926.00,1329.00 926.00,1329.00 826.00,1329.00 826.00,1329.00 826.00,1329.00 826.00,1154.00 826.00,1154.00 Z',
        attr: {
          id: '_307',
          'data-name': '307',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "307", zone: 'Upper Zone', zoneId: "3" },
      };
      var _308 = {
        path:
          'M 719.00,1154.00 C 719.00,1154.00 819.00,1154.00 819.00,1154.00 819.00,1154.00 819.00,1329.00 819.00,1329.00 819.00,1329.00 776.00,1329.00 776.00,1329.00 776.00,1329.00 759.00,1330.00 759.00,1330.00 759.00,1330.00 719.00,1330.00 719.00,1330.00 719.00,1330.00 719.00,1154.00 719.00,1154.00 Z',
        attr: {
          id: '_308',
          'data-name': '308',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "308", zone: 'Upper Zone', zoneId: "3" },
      };
      var _309 = {
        path:
          'M 611.00,1154.00 C 611.00,1154.00 712.00,1154.00 712.00,1154.00 712.00,1154.00 712.00,1330.00 712.00,1330.00 712.00,1330.00 611.00,1330.00 611.00,1330.00 611.00,1330.00 611.00,1154.00 611.00,1154.00 Z',
        attr: {
          id: '_309',
          'data-name': '309',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "309", zone: 'Upper Zone', zoneId: "3" },
      };
      var _310 = {
        path:
          'M 500.00,1151.00 C 515.41,1155.08 527.33,1154.00 543.00,1154.00 543.00,1154.00 604.00,1154.00 604.00,1154.00 604.00,1154.00 604.00,1330.00 604.00,1330.00 593.15,1328.06 561.90,1329.00 549.00,1329.00 549.00,1329.00 522.00,1329.00 522.00,1329.00 522.00,1329.00 510.00,1328.00 510.00,1328.00 510.00,1328.00 501.00,1328.00 501.00,1328.00 501.00,1328.00 467.00,1324.00 467.00,1324.00 467.00,1324.00 476.60,1274.00 476.60,1274.00 476.60,1274.00 490.40,1203.00 490.40,1203.00 490.40,1203.00 500.00,1151.00 500.00,1151.00 Z',
        attr: {
          id: '_310',
          'data-name': '310',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "310", zone: 'Upper Zone', zoneId: "3" },
      };
      var _311 = {
        path:
          'M 417.00,1119.00 C 417.00,1119.00 439.00,1129.75 439.00,1129.75 439.00,1129.75 471.00,1143.00 471.00,1143.00 471.00,1143.00 486.00,1147.71 486.00,1147.71 486.00,1147.71 491.89,1150.56 491.89,1150.56 491.89,1150.56 490.60,1163.00 490.60,1163.00 490.60,1163.00 484.20,1196.00 484.20,1196.00 484.20,1196.00 468.60,1276.00 468.60,1276.00 468.60,1276.00 460.00,1323.00 460.00,1323.00 460.00,1323.00 419.00,1314.12 419.00,1314.12 386.52,1305.58 354.71,1293.70 325.00,1278.00 325.00,1278.00 356.58,1223.00 356.58,1223.0 356.58,1223.00 417.00,1119.00 417.00,1119.00 Z',
        attr: {
          id: '_311',
          'data-name': '311',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "311", zone: 'Upper Zone', zoneId: "3" },
      };
      var _312 = {
        path:
          'M 192.00,1167.00 C 192.00,1167.00 232.00,1134.27 232.00,1134.27 232.00,1134.27 294.00,1082.41 294.00,1082.41 294.00,1082.41 320.00,1060.59 320.00,1060.59 322.29,1058.68 328.13,1053.11 331.00,1053.18 333.54,1053.24 336.29,1056.33 338.00,1058.00 338.00,1058.00 352.00,1071.72 352.00,1071.72 368.68,1086.39 390.62,1103.17 410.00,1114.00 410.00,1114.00 397.80,1137.00 397.80,1137.00 397.80,1137.00 378.60,1170.00 378.60,1170.00 378.60,1170.00 318.00,1274.00 318.00,1274.00 286.53,1258.86 246.68,1226.68 222.00,1202.00 214.79,1194.79 195.90,1175.41 192.00,1167.00 Z',
        attr: {
          id: '_312',
          'data-name': '312',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "312", zone: 'Upper Zone', zoneId: "3" },
      };
      var _313 = {
        path:
          'M 187.00,1162.00 C 167.12,1137.11 147.84,1104.64 133.76,1076.00 133.76,1076.00 123.72,1054.00 123.72,1054.00 122.73,1051.70 120.17,1046.62 120.81,1044.21 121.56,1041.35 127.44,1038.99 130.00,1037.78 130.00,1037.78 157.00,1025.26 157.00,1025.26 157.00,1025.26 234.00,989.69 234.00,989.69 234.00,989.69 262.00,976.69 262.00,976.69 267.29,974.26 270.09,972.11 276.00,971.00 283.78,989.06 296.72,1009.37 308.65,1025.00 308.65,1025.00 320.00,1039.00 320.00,1039.00 321.45,1040.72 324.07,1043.49 323.89,1045.91 323.68,1048.82 317.25,1053.37 315.00,1055.25 315.00,1055.25 290.00,1076.25 290.00,1076.25 290.00,1076.25 229.00,1127.27 229.00,1127.27 229.00,1127.27 187.00,1162.00 187.00,1162.00 Z',
        attr: {
          id: '_313',
          'data-name': '313',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "313", zone: 'Upper Zone', zoneId: "3" },
      };
      var _314 = {
        path:
          'M 77.00,859.00 C 77.00,859.00 238.00,859.00 238.00,859.00 239.94,859.00 243.72,858.75 245.26,859.99 247.15,861.52 246.91,864.82 247.03,867.00 247.03,867.00 249.00,883.00 249.00,883.00 251.90,904.17 257.53,924.89 264.69,945.00 264.69,945.00 272.00,964.00 272.00,964.00 272.00,964.00 257.00,970.58 257.00,970.58 257.00,970.58 219.00,988.22 219.00,988.22 219.00,988.22 156.00,1017.31 156.00,1017.31 156.00,1017.31 116.00,1036.00 116.00,1036.00 116.00,1036.00 106.63,1010.00 106.63,1010.00 106.63,1010.00 104.32,1000.00 104.32,1000.00 104.32,1000.00 97.34,980.00 97.34,980.00 97.34,980.00 86.21,938.00 86.21,938.00 86.21,938.00 79.17,890.00 79.17,890.00 79.17,890.00 78.00,874.00 78.00,874.00 78.00,874.00 77.00,859.00 77.00,859.00 Z',
        attr: {
          id: '_314',
          'data-name': '314',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "314", zone: 'Upper Zone', zoneId: "3" },
      };
      var _315 = {
        path:
          'M 77.00,741.00 C 77.00,741.00 245.00,741.00 245.00,741.00 245.00,741.00 245.00,804.00 245.00,804.00 245.00,804.00 246.00,820.00 246.00,820.00 246.00,820.00 246.00,852.00 246.00,852.00 246.00,852.00 77.00,852.00 77.00,852.00 77.00,852.00 77.00,741.00 77.00,741.00 Z',
        attr: {
          id: '_315',
          'data-name': '315',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "315", zone: 'Upper Zone', zoneId: "3" },
      };
      var _316 = {
        path:
          'M 114.00,554.00 C 114.00,554.00 147.00,569.69 147.00,569.69 147.00,569.69 240.00,613.31 240.00,613.31 240.00,613.31 271.00,628.00 271.00,628.00 271.00,628.00 261.00,656.00 261.00,656.00 254.73,674.99 245.06,714.54 245.00,734.00 245.00,734.00 77.00,734.00 77.00,734.00 77.00,698.33 82.86,655.42 92.42,621.00 92.42,621.00 104.91,583.00 104.91,583.00 104.91,583.00 107.67,572.00 107.67,572.00 107.67,572.00 114.00,554.00 114.00,554.00 Z',
        attr: {
          id: '_316',
          'data-name': '316',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "316", zone: 'Upper Zone', zoneId: "3" },
      };
      var _317 = {
        path:
          'M 184.00,427.00 C 184.00,427.00 214.00,452.41 214.00,452.41 214.00,452.41 263.00,493.08 263.00,493.08 263.00,493.08 321.00,541.00 321.00,541.00 321.00,541.00 313.42,553.00 313.42,553.00 313.42,553.00 299.69,573.00 299.69,573.00 299.69,573.00 282.25,604.00 282.25,604.00 282.25,604.00 274.00,621.00 274.00,621.00 274.00,621.00 243.00,606.31 243.00,606.31 243.00,606.31 166.00,570.22 166.00,570.22 166.00,570.22 133.00,554.69 133.00,554.69 133.00,554.69 117.00,548.00 117.00,548.00 119.60,538.81 127.51,523.10 131.69,514.00 146.14,482.55 164.03,455.12 184.00,427.00 Z',
        attr: {
          id: '_317',
          'data-name': '317',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "317", zone: 'Upper Zone', zoneId: "3" },
      };
      var _318 = {
        path:
          'M 316.00,311.00 C 316.00,311.00 319.86,319.00 319.86,319.00 319.86,319.00 336.58,348.00 336.58,348.00 336.58,348.00 377.85,420.00 377.85,420.00 377.85,420.00 396.28,452.00 396.28,452.00 396.28,452.00 406.00,471.00 406.00,471.00 390.21,478.61 363.74,498.86 351.00,511.04 351.00,511.04 326.00,536.00 326.00,536.00 326.00,536.00 232.00,457.92 232.00,457.92 232.00,457.92 204.00,434.59 204.00,434.59 204.00,434.59 188.00,423.00 188.00,423.00 193.07,413.39 212.77,392.23 221.00,384.00 253.70,351.30 276.72,334.57 316.00,311.00 Z',
        attr: {
          id: '_318',
          'data-name': '318',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "318", zone: 'Upper Zone', zoneId: "3" },
      };
      var _319 = {
        path:
          'M 322.00,308.00 C 362.00,285.23 413.82,269.50 459.00,261.00 459.00,261.00 471.20,327.00 471.20,327.00 471.20,327.00 492.00,437.00 492.00,437.00 478.04,438.97 447.56,450.53 434.00,456.42 427.27,459.35 419.76,464.07 413.00,466.00 413.00,466.00 358.42,371.00 358.42,371.00 358.42,371.00 322.00,308.00 322.00,308.00 Z',
        attr: {
          id: '_319',
          'data-name': '319',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "319", zone: 'Upper Zone', zoneId: "3" },
      };
      var _320 = {
        path:
          'M 466.00,260.00 C 481.17,257.24 514.12,253.02 529.00,253.00 529.00,253.00 605.00,253.00 605.00,253.00 605.00,253.00 604.00,275.00 604.00,275.00 604.00,275.00 604.00,311.00 604.00,311.00 604.00,311.00 604.00,430.00 604.00,430.00 604.00,430.00 530.00,430.00 530.00,430.00 530.00,430.00 499.00,435.00 499.00,435.00 499.00,435.00 494.61,411.00 494.61,411.00 494.61,411.00 486.80,370.00 486.80,370.00 486.80,370.00 466.00,260.00 466.00,260.00 Z',
        attr: {
          id: '_320',
          'data-name': '320',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "320", zone: 'Upper Zone', zoneId: "3" },
      };
      var _321 = {
        path:
          'M 610.00,253.04 C 610.00,253.04 623.00,253.04 623.00,253.04 623.00,253.04 635.00,254.00 635.00,254.00 635.00,254.00 712.00,254.00 712.00,254.00 712.00,254.00 712.00,430.00 712.00,430.00 712.00,430.00 611.00,430.00 611.00,430.00 611.00,430.00 611.00,311.00 611.00,311.00 611.00,311.00 611.00,275.00 611.00,275.00 611.00,275.00 610.00,253.04 610.00,253.04 Z',
        attr: {
          id: '_321',
          'data-name': '321',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "321", zone: 'Upper Zone', zoneId: "3" },
      };
      var _322 = {
        path:
          'M 719.00,254.00 C 719.00,254.00 819.00,254.00 819.00,254.00 819.00,254.00 819.00,430.00 819.00,430.00 819.00,430.00 719.00,430.00 719.00,430.00 719.00,430.00 719.00,254.00 719.00,254.00 Z',
        attr: {
          id: '_322',
          'data-name': '322',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "322", zone: 'Upper Zone', zoneId: "3" },
      };
      var _323 = {
        path:
          'paM 826.00,254.00 C 826.00,254.00 927.00,254.00 927.00,254.00 927.00,254.00 926.00,276.00 926.00,276.00 926.00,276.00 926.00,312.00 926.00,312.00 926.00,312.00 926.00,430.00 926.00,430.00 926.00,430.00 826.00,430.00 826.00,430.00 826.00,430.00 826.00,254.00 826.00,254.00 Zth',
        attr: {
          id: '_323',
          'data-name': '323',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "323", zone: 'Upper Zone', zoneId: "3" },
      };
      var _324 = {
        path:
          'M 932.00,254.00 C 932.00,254.00 1021.00,254.00 1021.00,254.00 1038.08,254.03 1055.14,257.73 1072.00,260.00 1072.00,260.00 1059.20,325.00 1059.20,325.00 1059.20,325.00 1046.39,393.00 1046.39,393.00 1046.39,393.00 1039.00,433.00 1039.00,433.00 1039.00,433.00 1014.00,429.04 1014.00,429.04 1014.00,429.04 1003.00,430.00 1003.00,430.00 1003.00,430.00 933.00,430.00 933.00,430.00 933.00,430.00 933.00,312.00 933.00,312.00 933.00,312.00 933.00,276.00 933.00,276.00 933.00,276.00 932.00,254.00 932.00,254.00 Z',
        attr: {
          id: '_324',
          'data-name': '324',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "324", zone: 'Upper Zone', zoneId: "3" },
      };
      var _325 = {
        path:
          'M 1078.00,261.00 C 1078.00,261.00 1125.00,272.72 1125.00,272.72 1156.17,282.11 1186.25,294.81 1215.00,310.00 1215.00,310.00 1147.40,425.00 1147.40,425.00 1147.40,425.00 1130.40,454.00 1130.40,454.00 1128.95,456.44 1125.28,463.76 1122.79,464.59 1120.68,465.30 1117.86,463.66 1116.00,462.75 1116.00,462.75 1101.00,455.25 1101.00,455.25 1088.21,448.85 1059.68,437.11 1046.00,435.00 1046.00,435.00 1067.61,320.00 1067.61,320.00 1067.61,320.00 1078.00,261.00 1078.00,261.00 Z',
        attr: {
          id: '_325',
          'data-name': '325',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "325", zone: 'Upper Zone', zoneId: "3" },
      };
      var _326 = {
        path:
          'M 1220.00,313.00 C 1253.39,330.66 1290.35,359.35 1317.00,386.00 1324.27,393.27 1344.11,413.62 1348.00,422.00 1348.00,422.00 1312.00,451.58 1312.00,451.58 1312.00,451.58 1245.00,508.13 1245.00,508.13 1245.00,508.13 1211.00,536.00 1211.00,536.00 1188.39,513.39 1177.76,502.42 1151.00,483.98 1144.49,479.49 1137.10,474.42 1130.00,471.00 1130.00,471.00 1154.40,428.00 1154.40,428.00 1154.40,428.00 1198.28,353.00 1198.28,353.00 1198.28,353.00 1212.40,329.00 1212.40,329.00 1212.40,329.00 1220.00,313.00 1220.00,313.00 Z',
        attr: {
          id: '_326',
          'data-name': '326',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "326", zone: 'Upper Zone', zoneId: "3" },
      };
      var _327 = {
        path:
          'M 1352.00,427.00 C 1359.23,433.03 1370.79,451.46 1376.34,460.00 1388.23,478.30 1398.48,497.30 1407.78,517.00 1411.28,524.42 1418.93,540.69 1421.00,548.00 1421.00,548.00 1387.00,563.14 1387.00,563.14 1387.00,563.14 1298.00,604.86 1298.00,604.86 1298.00,604.86 1265.00,620.00 1265.00,620.00 1256.81,601.00 1246.28,583.07 1234.66,566.00 1230.22,559.49 1219.07,547.08 1217.00,541.00 1217.00,541.00 1235.00,526.08 1235.00,526.08 1235.00,526.08 1280.00,488.08 1280.00,488.08 1280.00,488.08 1321.00,453.42 1321.00,453.42 1321.00,453.42 1352.00,427.00 1352.00,427.00 Z',
        attr: {
          id: '_327',
          'data-name': '327',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "327", zone: 'Upper Zone', zoneId: "3" },
      };
      var _328 = {
        path:
          'M 1423.00,554.00 C 1425.96,558.56 1427.20,563.93 1429.05,569.00 1429.05,569.00 1440.42,605.00 1440.42,605.00 1448.57,634.29 1454.93,663.90 1459.27,694.00 1459.27,694.00 1464.00,734.00 1464.00,734.00 1464.00,734.00 1293.00,734.00 1293.00,734.00 1293.00,734.00 1290.84,715.00 1290.84,715.00 1290.84,715.00 1287.58,694.00 1287.58,694.00 1283.44,671.06 1276.65,648.62 1268.00,627.00 1268.00,627.00 1296.00,614.25 1296.00,614.25 1296.00,614.25 1332.00,597.26 1332.00,597.26 1332.00,597.26 1385.00,572.31 1385.00,572.31 1385.00,572.31 1423.00,554.00 1423.00,554.00 Z',
        attr: {
          id: '_328',
          'data-name': '328',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "328", zone: 'Upper Zone', zoneId: "3" },
      };
      var _LEDGE = {
        path:
          'M 75.00,566.00 C 75.00,566.00 100.00,576.00 100.00,576.00 100.00,576.00 90.02,605.00 90.02,605.00 90.02,605.00 78.45,651.00 78.45,651.00 75.01,668.20 70.03,705.95 70.00,723.00 70.00,723.00 70.00,787.00 70.00,787.00 70.00,787.00 70.00,855.00 70.00,855.00 70.00,855.00 71.00,871.00 71.00,871.00 71.06,884.26 73.31,902.69 75.27,916.00 79.55,945.08 87.47,973.36 97.35,1001.00 97.35,1001.00 101.00,1010.00 101.00,1010.00 95.13,1011.59 89.61,1014.25 84.00,1016.58 81.92,1017.44 78.46,1019.03 76.22,1018.30 73.75,1017.48 72.69,1014.16 71.70,1012.00 68.79,1005.66 66.10,998.67 64.02,992.00 56.15,966.67 49.52,933.42 47.07,907.00 47.07,907.00 44.00,875.00 44.00,875.00 44.00,875.00 44.00,866.00 44.00,866.00 44.00,866.00 43.00,854.00 43.00,854.00 43.00,854.00 43.00,779.00 43.00,779.00 43.00,779.00 43.00,721.00 43.00,721.00 43.03,704.79 47.63,668.54 50.61,652.00 54.46,630.69 59.67,609.54 66.51,589.00 66.51,589.00 75.00,566.00 75.00,566.00 Z',
        attr: {
          id: '_LODGE',
          'data-name': 'LODGE',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'Ledge', zoneId: "3" },
      };
      var _C109 = {
        path:
          'M 415.00,975.00 C 415.00,975.00 450.00,946.08 450.00,946.08 452.55,943.96 461.12,936.16 464.00,936.19 467.01,936.22 477.10,946.01 480.00,948.39 482.93,950.78 493.51,957.69 493.76,961.09 493.95,963.76 487.37,974.10 485.72,977.00 485.72,977.00 464.00,1016.00 464.00,1016.00 450.83,1008.60 423.55,986.94 415.00,975.00 Z',
        attr: {
          id: '_C109',
          'data-name': 'C109',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "C109", zone: 'Lower Zone', zoneId: "1" },
      };
      var _C110 = {
        path:
          'M 336.00,848.00 C 336.00,848.00 422.00,848.00 422.00,848.00 422.00,848.00 425.37,868.00 425.37,868.00 425.37,868.00 430.00,885.00 430.00,885.00 430.00,885.00 394.00,901.78 394.00,901.78 391.22,903.09 382.44,906.60 381.62,909.21 380.94,910.35 381.09,911.55 381.62,912.53 384.45,915.90 390.20,912.15 393.00,910.75 393.00,910.75 434.00,892.00 434.00,892.00 437.82,900.87 442.59,908.30 448.37,916.00 450.22,918.45 456.45,925.56 456.83,927.99 457.45,931.93 449.78,936.77 447.00,939.08 447.00,939.08 425.00,957.08 425.00,957.08 420.36,960.93 414.33,966.53 409.00,969.00 409.00,969.00 392.43,947.00 392.43,947.00 379.73,929.23 363.80,900.01 362.00,878.00 358.50,878.46 343.72,881.39 341.43,880.39 338.88,879.28 338.28,874.45 337.80,872.00 336.29,864.10 336.00,856.01 336.00,848.00 Z',
        attr: {
          id: '_C110',
          'data-name': 'C110',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "C110", zone: 'Lower Zone', zoneId: "1" },
      };
      var _C111 = {
        path:
          'M 335.00,744.00 C 335.00,744.00 422.00,744.00 422.00,744.00 422.00,744.00 422.00,841.00 422.00,841.00 422.00,841.00 335.00,841.00 335.00,841.00 335.00,841.00 335.00,744.00 335.00,744.00 Z',
        attr: {
          id: '_C111',
          'data-name': 'C111',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "C111", zone: 'Lower Zone', zoneId: "1" },
      };
      var _C112 = {
        path:
          'M 362.00,706.00 C 362.67,698.52 365.73,691.01 368.32,684.00 374.24,667.97 381.91,652.44 391.05,638.00 393.47,634.19 403.05,619.49 406.17,617.73 410.26,615.43 415.98,621.76 419.00,624.20 419.00,624.20 448.00,647.75 448.00,647.75 450.36,649.70 456.70,653.93 456.84,657.04 456.97,659.83 451.68,665.65 449.88,668.00 443.46,676.43 438.21,684.22 434.00,694.00 434.00,694.00 393.00,675.58 393.00,675.58 389.98,674.26 383.53,670.00 380.62,673.47 380.09,674.45 379.94,675.65 380.62,676.79 381.54,679.66 389.14,682.18 392.00,683.42 392.00,683.42 419.00,695.69 419.00,695.69 421.65,696.91 427.53,698.85 428.89,701.39 430.28,703.98 427.87,709.28 427.02,712.00 423.91,722.05 423.76,727.20 422.00,737.00 422.00,737.00 335.00,737.00 335.00,737.00 335.00,737.00 336.92,713.00 336.92,713.00 337.35,710.45 337.78,705.81 340.43,704.71 342.31,703.72 345.92,704.63 348.00,704.71 348.00,704.71 362.00,706.00 362.00,706.00 Z',
        attr: {
          id: '_C112',
          'data-name': 'C112',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "C112", zone: 'Lower Zone', zoneId: "1" },
      };
      var _C113 = {
        path:
          'M 413.00,610.00 C 418.46,599.82 438.16,583.99 448.00,577.00 452.96,573.48 458.02,569.46 464.00,568.00 464.00,568.00 485.80,606.00 485.80,606.00 487.41,608.70 494.82,620.80 494.99,622.96 495.33,627.23 483.37,634.52 480.00,637.26 477.00,639.70 466.97,649.67 464.00,649.67 460.94,649.66 451.71,641.33 449.00,639.08 449.00,639.08 413.00,610.00 413.00,610.00 Z',
        attr: {
          id: '_C113',
          'data-name': 'C113',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "C113", zone: 'Lower Zone', zoneId: "1" },
      };
      var _400 = {
        path:
          'M 964.00,226.00 C 964.00,226.00 980.00,225.00 980.00,225.00 980.00,225.00 1006.00,225.00 1006.00,225.00 1006.00,225.00 1006.00,246.00 1006.00,246.00 1006.00,246.00 980.00,246.00 980.00,246.00 980.00,246.00 964.00,247.00 964.00,247.00 964.00,247.00 964.00,226.00 964.00,226.00 Z',
        attr: {
          id: '_400',
          'data-name': '400',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "400", zone: 'Suite', zoneId: "3" },
      };
      var _401 = {
        path:
          'M 1012.00,225.00 C 1012.00,225.00 1028.00,225.91 1028.00,225.91 1028.00,225.91 1050.00,227.17 1050.00,227.17 1050.00,227.17 1062.00,229.00 1062.00,229.00 1062.00,229.00 1059.00,250.00 1059.00,250.00 1043.41,247.94 1027.73,246.00 1012.00,246.00 1012.00,246.00 1012.00,225.00 1012.00,225.00 Z',
        attr: {
          id: '_401',
          'data-name': '401',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "401", zone: 'Suite', zoneId: "3" },
      };
      var _402 = {
        path:
          'M 1065.00,251.00 C 1065.00,251.00 1066.93,237.00 1066.93,237.00 1067.12,235.10 1067.05,232.30 1068.74,231.01 1070.80,229.42 1078.41,231.29 1081.00,231.80 1081.00,231.80 1093.00,234.96 1093.00,234.96 1093.00,234.96 1107.00,237.68 1107.00,237.68 1107.00,237.68 1116.00,240.00 1116.00,240.00 1116.00,240.00 1112.00,261.00 1112.00,261.00 1112.00,261.00 1084.00,254.65 1084.00,254.65 1084.00,254.65 1065.00,251.00 1065.00,251.00 Z',
        attr: {
          id: '_402',
          'data-name': '402',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "402", zone: 'Suite', zoneId: "3" },
      };
      var _403 = {
        path:
          'M 1121.00,243.00 C 1130.72,243.21 1133.38,245.69 1142.00,248.76 1142.00,248.76 1152.00,251.52 1152.00,251.52 1152.00,251.52 1169.00,258.00 1169.00,258.00 1169.00,258.00 1160.00,278.00 1160.00,278.00 1160.00,278.00 1135.00,269.00 1135.00,269.00 1135.00,269.00 1117.00,263.00 1117.00,263.00 1117.00,263.00 1121.00,243.00 1121.00,243.00 Z',
        attr: {
          id: '_403',
          'data-name': '403',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "403", zone: 'Suite', zoneId: "3" },
      };
      var _404 = {
        path:
          'M 1166.00,280.00 C 1166.90,276.67 1171.73,263.57 1174.41,262.12 1177.06,260.71 1183.30,263.94 1186.00,265.00 1186.00,265.00 1218.00,279.00 1218.00,279.00 1216.94,282.36 1210.76,295.54 1207.79,296.77 1205.10,297.88 1198.61,294.45 1196.00,293.22 1196.00,293.22 1166.00,280.00 1166.00,280.00 Z',
        attr: {
          id: '_404',
          'data-name': '404',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "404", zone: 'Suite', zoneId: "3" },
      };
      var _405 = {
        path:
          'M 1223.00,282.00 C 1234.40,284.68 1256.17,300.23 1267.00,307.00 1267.00,307.00 1255.00,325.00 1255.00,325.00 1255.00,325.00 1234.00,312.00 1234.00,312.00 1234.00,312.00 1213.00,301.00 1213.00,301.00 1213.00,301.00 1223.00,282.00 1223.00,282.00 Z',
        attr: {
          id: '_405',
          'data-name': '405',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "405", zone: 'Suite', zoneId: "3" },
      };
      var _406 = {
        path:
          'M 1271.00,311.00 C 1278.00,314.15 1293.45,326.76 1300.00,332.08 1302.31,333.96 1308.77,338.03 1308.81,341.04 1308.86,345.15 1298.32,354.63 1295.00,357.00 1295.00,357.00 1259.00,329.00 1259.00,329.00 1259.00,329.00 1271.00,311.00 1271.00,311.00 Z',
        attr: {
          id: '_406',
          'data-name': '406',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "406", zone: 'Suite', zoneId: "3" },
      };
      var _407 = {
        path:
          'M 1334.00,395.00 C 1330.15,389.62 1317.26,376.93 1312.00,372.17 1309.41,369.83 1303.26,365.18 1302.28,362.00 1301.89,360.87 1301.94,360.11 1302.28,359.00 1303.06,356.86 1311.99,346.90 1314.09,346.54 1315.23,346.03 1315.97,346.24 1317.00,346.54 1319.08,347.75 1324.87,353.93 1327.00,355.91 1335.70,364.01 1342.97,370.06 1350.00,380.00 1350.00,380.00 1334.00,395.00 1334.00,395.00 Z',
        attr: {
          id: '_407',
          'data-name': '407',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "407", zone: 'Suite', zoneId: "3" },
      };
      var _408 = {
        path:
          'M 1369.00,437.00 C 1369.00,437.00 1354.39,418.00 1354.39,418.00 1354.39,418.00 1343.44,405.00 1343.44,405.00 1342.20,403.46 1340.13,401.08 1340.18,399.00 1340.25,396.27 1344.16,392.85 1346.00,391.00 1348.05,388.95 1351.73,384.49 1354.96,385.34 1357.15,385.91 1360.49,390.23 1362.05,392.00 1362.05,392.00 1379.58,414.00 1379.58,414.00 1381.25,416.15 1385.54,421.05 1385.10,423.83 1384.52,427.48 1372.45,435.44 1369.00,437.00 Z',
        attr: {
          id: '_408',
          'data-name': '408',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "408", zone: 'Suite', zoneId: "3" },
      };
      var _409 = {
        path:
          'M 1389.00,429.00 C 1397.63,436.14 1412.20,462.16 1418.00,473.00 1418.00,473.00 1398.00,483.00 1398.00,483.00 1398.00,483.00 1373.00,442.00 1373.00,442.00 1373.00,442.00 1389.00,429.00 1389.00,429.00 Z',
        attr: {
          id: '_409',
          'data-name': '409',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "409", zone: 'Suite', zoneId: "3" },
      };
      var _410 = {
        path:
          'M 1402.00,488.00 C 1402.00,488.00 1421.00,478.00 1421.00,478.00 1427.08,489.56 1439.19,512.99 1442.00,525.00 1442.00,525.00 1423.00,533.00 1423.00,533.00 1423.00,533.00 1410.68,506.00 1410.68,506.00 1410.68,506.00 1402.00,488.00 1402.00,488.00 Z',
        attr: {
          id: '_410',
          'data-name': '410',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "410", zone: 'Suite', zoneId: "3" },
      };
      var _411 = {
        path:
          'M 1445.00,530.00 C 1448.77,536.80 1451.14,543.49 1453.04,551.00 1454.44,556.57 1462.18,572.83 1461.53,576.78 1460.91,580.59 1445.42,584.80 1442.51,582.94 1440.40,581.59 1438.82,575.46 1438.00,573.00 1438.00,573.00 1429.05,549.00 1429.05,549.00 1428.10,546.46 1425.51,541.08 1427.04,538.64 1428.41,536.43 1442.01,530.85 1445.00,530.00 Z',
        attr: {
          id: '_411',
          'data-name': '411',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "411", zone: 'Suite', zoneId: "3" },
      };
      var _412 = {
        path:
          'M 1444.00,590.00 C 1447.64,589.18 1460.94,584.62 1463.57,585.66 1466.27,586.73 1467.32,594.22 1468.17,597.00 1468.17,597.00 1477.00,634.00 1477.00,634.00 1473.49,634.50 1459.63,638.22 1457.43,637.34 1454.70,636.25 1453.60,627.79 1453.00,625.00 1453.00,625.00 1444.00,590.00 1444.00,590.00 Z',
        attr: {
          id: '_412',
          'data-name': '412',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "412", zone: 'Suite', zoneId: "3" },
      };
      var _413 = {
        path:
          'M 1478.00,639.00 C 1478.00,639.00 1482.08,658.00 1482.08,658.00 1482.08,658.00 1487.00,692.00 1487.00,692.00 1487.00,692.00 1466.00,693.00 1466.00,693.00 1466.00,693.00 1458.00,644.00 1458.00,644.00 1458.00,644.00 1478.00,639.00 1478.00,639.00 Z',
        attr: {
          id: '_413',
          'data-name': '413',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "413", zone: 'Suite', zoneId: "3" },
      };
      var _414 = {
        path:
          'M 1467.00,699.00 C 1467.00,699.00 1488.00,698.00 1488.00,698.00 1488.00,698.00 1493.00,745.00 1493.00,745.00 1493.00,745.00 1472.00,745.00 1472.00,745.00 1472.00,745.00 1468.84,713.00 1468.84,713.00 1468.84,713.00 1467.00,699.00 1467.00,699.00 Z',
        attr: {
          id: '_414',
          'data-name': '414',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "414", zone: 'Suite', zoneId: "3" },
      };
      var _415 = {
        path:
          'M 1472.00,751.00 C 1472.00,751.00 1493.00,751.00 1493.00,751.00 1493.00,751.00 1493.00,799.00 1493.00,799.00 1493.00,799.00 1472.00,799.00 1472.00,799.00 1472.00,799.00 1472.00,751.00 1472.00,751.00 Z',
        attr: {
          id: '_415',
          'data-name': '415',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "415", zone: 'Suite', zoneId: "3" },
      };
      var _416 = {
        path:
          'M 1472.00,805.00 C 1472.00,805.00 1493.00,805.00 1493.00,805.00 1493.00,805.00 1493.00,851.00 1493.00,851.00 1493.00,851.00 1472.00,851.00 1472.00,851.00 1472.00,851.00 1472.00,805.00 1472.00,805.00 Z',
        attr: {
          id: '_416',
          'data-name': '416',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "416", zone: 'Suite', zoneId: "3" },
      };
      var _417 = {
        path:
          'M 1471.00,858.00 C 1471.00,858.00 1492.00,858.00 1492.00,858.00 1492.00,872.29 1489.65,891.91 1487.00,906.00 1487.00,906.00 1466.00,904.00 1466.00,904.00 1466.00,904.00 1471.00,858.00 1471.00,858.00 Z',
        attr: {
          id: '_417',
          'data-name': '417',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "417", zone: 'Suite', zoneId: "3" },
      };
      var _418 = {
        path:
          'M 1465.00,910.00 C 1465.00,910.00 1486.00,912.00 1486.00,912.00 1486.00,912.00 1477.00,961.00 1477.00,961.00 1477.00,961.00 1456.00,958.00 1456.00,958.00 1456.00,958.00 1465.00,910.00 1465.00,910.00 Z',
        attr: {
          id: '_418',
          'data-name': '418',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "418", zone: 'Suite', zoneId: "3" },
      };
      var _419 = {
        path:
          'M 1441.00,1009.00 C 1441.00,1009.00 1450.87,976.00 1450.87,976.00 1451.58,973.20 1452.68,965.81 1455.43,964.61 1457.74,963.60 1471.73,966.41 1475.00,967.00 1475.00,967.00 1466.08,1002.00 1466.08,1002.00 1466.08,1002.00 1462.00,1015.00 1462.00,1015.00 1462.00,1015.00 1441.00,1009.00 1441.00,1009.00 Z',
        attr: {
          id: '_419',
          'data-name': '419',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "419", zone: 'Suite', zoneId: "3" },
      };
      var _420 = {
        path:
          'M 1439.00,1015.00 C 1442.39,1015.68 1456.36,1019.01 1457.94,1021.51 1459.68,1024.27 1456.14,1031.15 1455.00,1034.00 1455.00,1034.00 1440.00,1068.00 1440.00,1068.00 1440.00,1068.00 1421.00,1059.00 1421.00,1059.00 1421.00,1059.00 1427.80,1043.00 1427.80,1043.00 1427.80,1043.00 1439.00,1015.00 1439.00,1015.00 Z',
        attr: {
          id: '_420',
          'data-name': '420',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "410", zone: 'Suite', zoneId: "3" },
      };
      var _427 = {
        path:
          'M 1160.00,1308.00 C 1160.00,1308.00 1185.00,1297.85 1185.00,1297.85 1185.00,1297.85 1203.00,1291.00 1203.00,1291.00 1203.00,1291.00 1212.00,1310.00 1212.00,1310.00 1212.00,1310.00 1198.00,1316.04 1198.00,1316.04 1198.00,1316.04 1190.00,1320.05 1190.00,1320.05 1190.00,1320.05 1167.00,1329.00 1167.00,1329.00 1167.00,1329.00 1160.00,1308.00 1160.00,1308.00 Z',
        attr: {
          id: '_427',
          'data-name': '427',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "427", zone: 'Suite', zoneId: "3" },
      };
      var _428 = {
        path:
          'M 1114.00,1345.00 C 1113.28,1341.43 1109.67,1327.95 1110.69,1325.30 1111.82,1322.34 1119.14,1321.12 1122.00,1320.37 1132.77,1317.57 1143.66,1314.11 1154.00,1310.00 1154.00,1310.00 1161.00,1330.00 1161.00,1330.00 1152.04,1334.64 1124.89,1342.14 1114.00,1345.00 Z',
        attr: {
          id: '_428',
          'data-name': '428',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "428", zone: 'Suite', zoneId: "3" },
      };
      var _429 = {
        path:
          'M 1061.00,1333.00 C 1061.00,1333.00 1083.00,1329.58 1083.00,1329.58 1083.00,1329.58 1104.00,1325.00 1104.00,1325.00 1104.00,1325.00 1108.00,1346.00 1108.00,1346.00 1108.00,1346.00 1063.00,1354.00 1063.00,1354.00 1063.00,1354.00 1061.00,1333.00 1061.00,1333.00 Z',
        attr: {
          id: '_429',
          'data-name': '429',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "429", zone: 'Suite', zoneId: "3" },
      };
      
      var _430 = {
        path:
          'M 1008.00,1335.00 C 1008.00,1335.00 1039.00,1335.00 1039.00,1335.00 1039.00,1335.00 1055.00,1334.00 1055.00,1334.00 1055.00,1334.00 1057.00,1355.00 1057.00,1355.00 1057.00,1355.00 1040.00,1356.00 1040.00,1356.00 1040.00,1356.00 1015.00,1356.00 1015.00,1356.00 1013.41,1355.97 1011.05,1356.07 1009.74,1355.01 1007.02,1352.81 1008.00,1338.83 1008.00,1335.00 Z',
        attr: {
          id: '_430',
          'data-name': '430',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "430", zone: 'Suite', zoneId: "3" },
      };
      var _431 = {
        path:
          'M 950.00,1335.96 C 950.00,1335.96 965.00,1335.96 965.00,1335.96 965.00,1335.96 977.00,1335.00 977.00,1335.00 977.00,1335.00 1002.00,1335.00 1002.00,1335.00 1002.00,1335.00 1002.00,1356.00 1002.00,1356.00 1002.00,1356.00 950.00,1356.00 950.00,1356.00 950.00,1356.00 950.00,1335.96 950.00,1335.96 Z',
        attr: {
          id: '_431',
          'data-name': '431',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "431", zone: 'Suite', zoneId: "3" },
      };
      var _432 = {
        path:
          'M 889.00,1336.00 C 889.00,1336.00 943.00,1336.00 943.00,1336.00 943.00,1336.00 943.00,1356.00 943.00,1356.00 943.00,1356.00 889.00,1357.00 889.00,1357.00 889.00,1357.00 889.00,1336.00 889.00,1336.00 Z',
        attr: {
          id: '_432',
          'data-name': '432',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "432", zone: 'Suite', zoneId: "3" },
      };
      var _433 = {
        path:
          'M 829.00,1336.00 C 829.00,1336.00 883.00,1336.00 883.00,1336.00 883.00,1336.00 883.00,1357.00 883.00,1357.00 883.00,1357.00 829.00,1357.00 829.00,1357.00 829.00,1357.00 829.00,1336.00 829.00,1336.00 Z',
        attr: {
          id: '_433',
          'data-name': '433',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "433", zone: 'Suite', zoneId: "3" },
      };
      var _434 = {
        path:
          'M 768.00,1336.00 C 768.00,1336.00 823.00,1336.00 823.00,1336.00 823.00,1336.00 823.00,1357.00 823.00,1357.00 823.00,1357.00 768.00,1357.00 768.00,1357.00 768.00,1357.00 768.00,1336.00 768.00,1336.00 Z',
        attr: {
          id: '_434',
          'data-name': '434',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "434", zone: 'Suite', zoneId: "3" },
      };
      var _435 = {
        path:
          'M 707.00,1336.00 C 707.00,1336.00 762.00,1336.00 762.00,1336.00 762.00,1336.00 762.00,1357.00 762.00,1357.00 762.00,1357.00 708.00,1357.00 708.00,1357.00 708.00,1357.00 707.00,1336.00 707.00,1336.00 Z',
        attr: {
          id: '_435',
          'data-name': '435',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "435", zone: 'Suite', zoneId: "3" },
      };
      var _436 = {
        path:
          'M 647.00,1336.00 C 647.00,1336.00 701.00,1336.00 701.00,1336.00 701.00,1336.00 701.00,1357.00 701.00,1357.00 701.00,1357.00 647.00,1357.00 647.00,1357.00 647.00,1357.00 647.00,1336.00 647.00,1336.00 Z',
        attr: {
          id: '_436',
          'data-name': '436',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "436", zone: 'Suite', zoneId: "3" },
      };
      var _437 = {
        path:
          'M 586.00,1336.00 C 586.00,1336.00 641.00,1336.00 641.00,1336.00 641.00,1336.00 641.00,1357.00 641.00,1357.00 641.00,1357.00 586.00,1357.00 586.00,1357.00 586.00,1357.00 586.00,1336.00 586.00,1336.00 Z',
        attr: {
          id: '_437',
          'data-name': '437',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "437", zone: 'Suite', zoneId: "3" },
      };
      var _438 = {
        path:
          'M 523.00,1336.00 C 523.00,1336.00 580.00,1336.00 580.00,1336.00 580.00,1336.00 579.00,1357.00 579.00,1357.00 579.00,1357.00 524.00,1357.00 524.00,1357.00 524.00,1357.00 523.00,1336.00 523.00,1336.00 Z',
        attr: {
          id: '_438',
          'data-name': '438',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "438", zone: 'Suite', zoneId: "3" },
      };
      var _450A = {
        path:
          'M 199.00,1220.00 C 202.18,1217.75 212.06,1208.37 215.00,1208.34 217.60,1208.31 220.23,1211.32 222.00,1212.97 222.00,1212.97 235.00,1224.91 235.00,1224.91 246.92,1235.99 271.06,1254.16 285.00,1262.81 310.70,1278.74 339.96,1295.33 368.00,1306.55 410.48,1323.54 471.26,1336.00 517.00,1336.00 517.00,1336.00 517.00,1357.00 517.00,1357.00 517.00,1357.00 497.00,1356.09 497.00,1356.09 497.00,1356.09 475.00,1354.71 475.00,1354.71 475.00,1354.71 461.00,1352.29 461.00,1352.29 461.00,1352.29 453.00,1351.71 453.00,1351.71 440.88,1350.13 428.80,1347.37 417.00,1344.29 417.00,1344.29 407.00,1342.08 407.00,1342.08 386.60,1336.59 373.52,1331.81 354.00,1324.00 354.00,1324.00 337.00,1317.11 337.00,1317.11 337.00,1317.11 308.00,1301.48 308.00,1301.48 308.00,1301.48 270.00,1279.51 270.00,1279.51 252.34,1267.74 235.94,1255.19 220.00,1241.15 213.47,1235.40 203.97,1226.94 199.00,1220.00 Z',
        attr: {
          id: '_450A',
          'data-name': '450A',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "450", zone: 'Suite A', zoneId: "3" },
      };
      var _450B = {
        path:
          'M 91.00,1052.00 C 91.00,1052.00 105.00,1045.80 105.00,1045.80 105.00,1045.80 110.51,1045.13 110.51,1045.13 110.51,1045.13 115.25,1053.00 115.25,1053.00 115.25,1053.00 124.25,1072.00 124.25,1072.00 124.25,1072.00 138.37,1101.00 138.37,1101.00 138.37,1101.00 162.00,1142.00 162.00,1142.00 162.00,1142.00 145.00,1156.00 145.00,1156.00 131.03,1136.26 120.78,1114.55 110.03,1093.00 110.03,1093.00 101.89,1077.00 101.89,1077.00 101.89,1077.00 96.51,1064.00 96.51,1064.00 93.89,1058.40 93.37,1058.78 91.00,1052.00 Z',
        attr: {
          id: '_450B',
          'data-name': '450B',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "450", zone: 'Suite B', zoneId: "3" },
      };
      var _475A = {
        path:
          'M 200.00,361.00 C 200.00,361.00 222.00,340.83 222.00,340.83 222.00,340.83 230.00,333.33 230.00,333.33 230.00,333.33 237.00,328.39 237.00,328.39 237.00,328.39 245.00,321.78 245.00,321.78 261.25,309.98 288.11,292.56 306.00,284.00 306.00,284.00 316.00,303.00 316.00,303.00 289.19,318.44 271.40,329.03 247.00,348.80 247.00,348.80 226.00,367.09 226.00,367.09 224.01,368.94 219.39,374.07 217.00,374.66 214.50,375.29 212.71,373.45 211.00,371.95 207.09,368.55 203.06,365.18 200.00,361.00 Z',
        attr: {
          id: '_475A',
          'data-name': '475A',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "475", zone: 'Suite A', zoneId: "3" },
      };
      var _480 = {
        path:
          'M 311.00,281.00 C 325.56,272.27 341.32,266.67 357.00,260.40 357.00,260.40 377.00,252.52 377.00,252.52 377.00,252.52 386.00,250.11 386.00,250.11 386.00,250.11 428.00,237.66 428.00,237.66 428.00,237.66 447.00,234.00 447.00,234.00 447.00,234.00 477.00,229.00 477.00,229.00 477.00,229.00 480.11,243.00 480.11,243.00 480.50,244.95 481.17,247.48 479.39,248.98 477.94,250.20 465.71,251.78 463.00,252.25 463.00,252.25 426.00,260.47 426.00,260.47 405.94,265.49 379.05,274.43 360.00,282.42 360.00,282.42 321.00,300.00 321.00,300.00 321.00,300.00 311.00,281.00 311.00,281.00 Z',
        attr: {
          id: '_480',
          'data-name': '480',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "480", zone: 'Suite', zoneId: "3" },
      };
      var _TLGTRA = {
        path:
          'M 1332.00,1194.00 C 1347.85,1178.10 1361.26,1159.43 1374.02,1141.00 1383.63,1127.12 1393.58,1113.08 1401.24,1098.00 1401.24,1098.00 1413.75,1073.00 1413.75,1073.00 1415.36,1069.61 1416.31,1064.67 1421.04,1065.68 1421.04,1065.68 1438.00,1073.00 1438.00,1073.00 1438.00,1073.00 1421.11,1108.00 1421.11,1108.00 1421.11,1108.00 1399.52,1143.00 1399.52,1143.00 1399.52,1143.00 1392.56,1154.00 1392.56,1154.00 1392.56,1154.00 1384.01,1165.00 1384.01,1165.00 1384.01,1165.00 1374.91,1178.00 1374.91,1178.00 1374.91,1178.00 1355.96,1201.00 1355.96,1201.00 1354.02,1203.02 1349.93,1207.70 1347.00,1207.66 1343.07,1207.61 1334.32,1197.24 1332.00,1194.00 Z',
        attr: {
          id: '_TLGTRA',
          'data-name': 'TLGTRA',
           opacity: 0,
          fill: '#2196f3',
          stroke: '#d7d7d7',
          'stroke-miterlimit': '10',
          'stroke-width': '0',
           'stroke-opacity': '1',
        },
        transform: "",
        data: { zoneNo: "", zone: 'TLGTRA', zoneId: "3" },
      };
    var _TLGTRB = {
      path:
        'M 1208.00,1287.00 C 1215.89,1284.19 1229.48,1276.11 1237.00,1271.60 1255.97,1260.22 1279.35,1243.24 1296.00,1228.72 1296.00,1228.72 1318.00,1208.00 1318.00,1208.00 1320.09,1205.91 1325.04,1200.20 1328.00,1200.20 1330.67,1200.20 1334.20,1204.20 1336.00,1206.00 1337.80,1207.80 1341.80,1211.33 1341.80,1214.00 1341.80,1216.69 1337.80,1220.18 1336.00,1222.00 1336.00,1222.00 1319.00,1238.04 1319.00,1238.04 1299.27,1256.82 1276.81,1272.14 1254.00,1286.86 1254.00,1286.86 1235.00,1298.03 1235.00,1298.03 1227.16,1302.35 1226.93,1304.32 1217.00,1307.00 1213.75,1301.29 1209.20,1293.38 1208.00,1287.00 Z',
      attr: {
        id: '_TLGTRB',
        'data-name': 'TLGTRB',
         opacity: 0,
        fill: '#2196f3',
        stroke: '#d7d7d7',
        'stroke-miterlimit': '10',
        'stroke-width': '0',
         'stroke-opacity': '1',
      },
      transform: "",
      data: { zoneNo: "", zone: 'TLGTRB', zoneId: "3" },
    };
    
      

    zones.push(
       _108,
       _109,
       _110,
       _111,
       _112,
       _113,
       _114,
       _115,
       _116,
       _117,
       _118,
       _119,
       _120,
       _201,
       _202,
       _203,
       _204,
       _205,
       _206,
       _207,
       _208,
       _209,
       _210,
       _211,
       _212,
       _213,
       _214,
       _215,
       _216,
       _217,
       _218,
       _219,
       _220,
       _221,
       _222,
       _223,
       _224,
       _225,
       _226,
       _227,
       _228,
       _301,
       _302,
       _303,
       _304,
       _305,
       _306,
       _307,
       _308,
       _309,
       _310,
       _311,
       _312,
       _313,
       _314,
       _315,
       _316,
       _317,
       _318,
       _319,
       _320,
       _321,
       _322,
       _323,
       _324,
       _325,
       _326,
       _327,
       _328,
       _C109,
       _C110,
       _C111,
       _C112,
       _C113,
       _LEDGE,
       _101,
       _102,
       _103,
       _104,
       _105,
       _106,
       _107,
       _400,
       _401,
       _402,
       _403,
       _404,
       _405,
       _406,
       _407,
       _408,
       _409,
       _410,
       _411,
       _412,
       _413,
       _414,
       _415,
       _416,
       _417,
       _418,
       _419,
       _420,
       _427,
       _428,
       _429,
       _430,
       _431,
       _432,
       _433,
       _434,
       _435,
       _436,
       _437,
       _438,
       _450A,
       _450B,
       _475A,
       _480,
       _TLGTRA,
       _TLGTRB
       
       
    );
    return zones;
 };
 