const nonLogeRows = () => {
  const arr = [];

  for (let i = 1; i <= 29; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const logeRows = [
  {
    value: 'A',
    label: 'Row A',
  },
];

const premiumRows = [
  {
    value: 'A',
    label: 'Row A',
  },
  {
    value: 'B',
    label: 'Row B',
  },
  {
    value: 'C',
    label: 'Row C',
  },
  {
    value: 'D',
    label: 'Row D',
  },
];

export default {
  NON_LOGE_ROWS: nonLogeRows(),
  LOGE_ROWS: logeRows,
  PREMIUM_ROWS: premiumRows
};
