import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  textField: {
    padding: "0",
    backgroundColor: "transparent",
    border: "0",
    borderBottom: 0,
    width: "100%",
  },
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
    fontWeight: "bold",
    color: "inherit",
    selectIcon: {
      fill: "white",
    },
  },
  paper: {
    width: "100%",
  },
});

class SeatsFilter extends Component {
  state = {
    noOfSeats: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters !== this.props.filters) {
      this.setState({ filters: nextProps.filters });
    }
  }

  handleChange = (value) => {
    // strip non numeric characters and cast value to a Number
    //const noOfSeats = Number(value.replace(/\D/g, ""));
    // Filter the seat list based on if noOfSeats is in
    // the seat.quantity_options
    this.setState(
      {
        noOfSeats: value,
      },
      () => this.props.onClose({ noOfSeats: value })
    );
  };

  handleSelect = () => {
    this.props.onClose(this.state);
  };

  handleCancel = () => {
    this.props.onClose(this.state);
  };

  renderSelect = () => {
    const { noOfSeats } = this.state;

    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1.5rem",
          }}
        >
          <Button
            style={{ boxShadow: "none" }}
            onClick={() => this.handleChange(1)}
            variant="fab"
            color={noOfSeats === 1 ? "secondary" : "default"}
          >
            1
          </Button>

          <Button
            style={{ boxShadow: "none" }}
            onClick={() => this.handleChange(2)}
            variant="fab"
            color={noOfSeats === 2 ? "secondary" : "default"}
          >
            2
          </Button>

          <Button
            style={{ boxShadow: "none" }}
            onClick={() => this.handleChange(3)}
            variant="fab"
            color={noOfSeats === 3 ? "secondary" : "default"}
          >
            3
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1.5rem",
          }}
        >
          <Button
            style={{ boxShadow: "none" }}
            onClick={() => this.handleChange(4)}
            variant="fab"
            color={noOfSeats === 4 ? "secondary" : "default"}
          >
            4
          </Button>

          <Button
            style={{ boxShadow: "none" }}
            onClick={() => this.handleChange(5)}
            variant="fab"
            color={noOfSeats === 5 ? "secondary" : "default"}
          >
            5+
          </Button>

          <Button
            style={{ boxShadow: "none" }}
            onClick={() => this.handleChange(0)}
            variant="fab"
            color={noOfSeats === 0 ? "secondary" : "default"}
          >
            Any
          </Button>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { value, onClose, open, classes, ...other } = this.props;
    return (
      <Dialog
        onClose={this.handleCancel}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        open={open}
        classes={{
          paper: classes.paper,
        }}
        {...other}
      >
        <DialogTitle
          id="simple-dialog-title"
          style={{
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FilterListRoundedIcon
              fontSize="inherit"
              style={{
                marginRight: ".5rem",
              }}
            />
            <Typography
              align="right"
              variant="caption"
              color="textPrimary"
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              # of Tickets
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "1rem",
          }}
        >
          {this.renderSelect()}
        </DialogContent>
      </Dialog>
    );
  }
}

SeatsFilter.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.object,
};

export default withStyles(styles)(SeatsFilter);
