import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { isValidEmail, isValidMobileNo, isValidName, sanitizeInput } from "../../utils/validation";
import NumberFormat from "react-number-format";
import cdialog from "../../components/cdialog";

const styles = (theme) => ({
  formContainer: {
    padding: "1rem 0",
  },
  userInfo: {
    marginBottom: "1rem",
  },
  form: {
    marginTop: "1.5rem",
  },
  formButtons: {
    marginTop: "2rem",
  },
  titleText: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  changeButton: {
    marginLeft: "1rem"
  },
  [theme.breakpoints.up("md")]: {
    header: {
      padding: "0 0 1rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    header: {
      padding: "2rem 0",
    },
    formContainer: {
      justifyContent: "flex-start",
      padding: "1rem 0",
    },
    changeButton: {
      marginLeft: "0.5rem"
    },
  },
});

function MobileNoCustomInput(props) {
  const { inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="(###) ### ####"
      thousandSeparator=""
      prefix=""
      mask="_"
    />
  );
}

class ProfileWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      mobileNo: "",
      isEditing: false,
      isSubmitting: false,
      isValidEmail: true,
      isValidMobileNo: true,
      isValidName: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { currUser } = this.props;
    let localFormat = "";
    if (currUser) {
      if (currUser.phone_number) {
        const phoneNumber = parsePhoneNumberFromString(currUser.phone_number);
        localFormat = phoneNumber.formatNational().replace(/[^\d]+/g, "");
      }

      this.setState({
        name: currUser.name,
        email: currUser.email,
        mobileNo: localFormat,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: name === "email" ? value.toLowerCase() : value,
    });
  }

  async isValidInput() {
    const { name, email, mobileNo } = this.state;
    const validMobileNo = await isValidMobileNo(mobileNo);
    const validName = isValidName(name);
    const validEmail = isValidEmail(email);

    this.setState({
      isValidEmail: validEmail,
      isValidMobileNo: validMobileNo,
      isValidName: validName
    })

    return validMobileNo && validName && validEmail;
  }

  async onSubmit(e) {
    e.preventDefault();

    const { name, email, mobileNo } = this.state;
    const sanitizedName = sanitizeInput(name);
    this.setState({
      isSubmitting: true,
    });

    if (!await this.isValidInput()) {
      this.setState({
        isSubmitting: false,
      });
      return;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      const old_email = user.attributes.email;
      let phone_number = "";
      if (mobileNo !== "") {
        const parsedPhoneNumber = parsePhoneNumberFromString(mobileNo, "CA")
        phone_number = parsedPhoneNumber.number;
      }
      
      await Auth.updateUserAttributes(user, {
        name: sanitizedName,
        email,
        phone_number,
        'custom:old_email' : old_email
      });

      const updatedDetails = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      this.props.setUserAttributes(updatedDetails);

      const { currUser } = this.props;
      let localFormat = "";

      if (currUser.phone_number) {
        const phoneNumber = parsePhoneNumberFromString(currUser.phone_number);
        localFormat = phoneNumber.formatNational().replace(/[^\d]+/g, "");
      }

      this.setState({
        name: currUser.name,
        email: currUser.email,
        mobileNo: localFormat,
        isSubmitting: false,
        isEditing: false,
      });
      if (!currUser.email_verified) {
        this.props.history.push(`/email-verification`);
      }
    } catch (e) {
      const { currUser } = this.props;
      let localFormat = "";

      if (currUser.phone_number) {
        const phoneNumber = parsePhoneNumberFromString(currUser.phone_number);
        localFormat = phoneNumber.formatNational().replace(/[^\d]+/g, "");
      }

      switch (e.code) {
        case "AliasExistsException": {
          cdialog.error('Error','An account with the given email already exists.');
          break;
        }

        default: {
          cdialog.error('Error','Sorry something went wrong. Please try again.');
        }
      }

      this.setState({
        name: currUser.name,
        email: currUser.email,
        mobileNo: localFormat,
        isSubmitting: false,
        isEditing: false,
      });
    }
  }

  renderAccountDetails() {
    const { classes, currUser } = this.props;
    const { isEditing } = this.state;

    return (
      !isEditing && (
        <Grid container spacing={16}>
          <Grid item xs={12} md={4}>
            <Typography variant="caption">Name</Typography>
            {currUser && (
              <Typography variant="body1" className={classes.userInfo}>
                {currUser.name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="caption">Email Address</Typography>
            {currUser && (
              <Typography variant="body1" className={classes.userInfo}>
                {currUser.email}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="caption">Mobile Number</Typography>
              {currUser && currUser.phone_number? (
                <React.Fragment>
                  <Typography variant="body1" className={classes.userInfo}>
                    {currUser.phone_number}
                  </Typography>
                </React.Fragment>
              ) : (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.userInfo}
                >
                  -
                </Typography>
              )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.setState({ isEditing: true })}
            >
              Edit Profile
            </Button>
            <Button
              color="secondary"
              disableRipple={true}
              disableFocusRipple={true}
              className={classes.changeButton}
              variant="contained"
              href="/account/password"
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      )
    );
  }

  renderEditProfileForm() {
    const { classes } = this.props;
    const {
      name,
      email,
      mobileNo,
      isEditing,
      isSubmitting,
      isValidEmail,
      isValidMobileNo,
      isValidName,
    } = this.state;

    return (
      isEditing && (
        <form onSubmit={this.onSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} md={4}>
              <FormControl error={!isValidName} fullWidth={true}>
                <TextField
                  autoFocus={true}
                  className={
                    isValidName ? classes.textField : classes.textFieldError
                  }
                  disabled={isSubmitting}
                  id="name"
                  margin="dense"
                  name="name"
                  onChange={this.handleChange}
                  placeholder="Full Name"
                  defaultValue={name}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
                {!isValidName ? (
                  <FormHelperText>Enter your full name</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl error={!isValidEmail} fullWidth={true}>
                <TextField
                  className={
                    isValidEmail ? classes.textField : classes.textFieldError
                  }
                  disabled={isSubmitting}
                  id="email"
                  name="email"
                  margin="dense"
                  onChange={this.handleChange}
                  placeholder="Email Address"
                  value={email}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
                {!isValidEmail ? (
                  <FormHelperText>Enter valid email</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl error={!isValidMobileNo} fullWidth={true}>
                <TextField
                  className={
                    isValidMobileNo ? classes.textField : classes.textFieldError
                  }
                  disabled={isSubmitting}
                  id="mobileNo"
                  name="mobileNo"
                  margin="dense"
                  onChange={this.handleChange}
                  placeholder="Mobile Number"
                  value={mobileNo}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                    startAdornment: (
                      <span
                        style={{ fontSize: ".875rem", marginRight: ".5rem" }}
                      >
                        +1
                      </span>
                    ),
                    inputComponent: MobileNoCustomInput,
                  }}
                />
                {!isValidMobileNo ? (
                  <FormHelperText>Enter valid mobile number</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className={classes.formButtons}>
                <Button
                  onClick={() => this.setState({ isEditing: false })}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  disabled={isSubmitting}
                  style={{
                    marginLeft: "1rem",
                  }}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? "Saving Changes..." : "Save Changes"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.formContainer}>
        <Grid item xs={12} md={12} xl={8}>
          {this.renderAccountDetails()}
          {this.renderEditProfileForm()}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProfileWidget);