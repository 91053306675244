import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TEAMS from "../constants/Teams";
import teamQuestions from '../constants/TeamQuestions';

const styles = theme => ({
  container: {
    marginTop: '5rem',
    padding: '0 10rem'
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      padding: '0 1rem'
    },
  },
  teamName: {
    color: '#2196f3',
    fontSize: theme.typography.pxToRem(28.8),
    marginBottom: '1.5rem',
  }, 
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  },
  description: {
    fontSize: theme.typography.pxToRem(15),
    '& a': {
      color: '#2196f3',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    }
  }
});

const TeamInfo = ({
  classes,
  selectedTeam,
}) => {
  const [teamName, setTeamName] = useState();
  const [questions, setQuestions] = useState([]);
  
  useEffect(() => {
    const teamInfos = teamQuestions[selectedTeam];
    const team = TEAMS.find(team => team.homeTeamSlug === selectedTeam);
    if (teamInfos && Object.keys(teamInfos).length > 0) {
      setTeamName(team.fullName);
      setQuestions(teamInfos.questions);
    }
  }, [selectedTeam]);

  if (!questions.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="title" className={classes.teamName}>
        {teamName}
      </Typography>
      {questions.map((element, index) => (
        <ExpansionPanel defaultExpanded key={index}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{element.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography className={classes.description} dangerouslySetInnerHTML={{__html: element.description}}></Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
}

export default withStyles(styles)(TeamInfo);