export const styles = (theme) => ({
  bannerContainer: {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bannerTitleContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 1,
  },
  bannerTitle: {
    display: "block",
    fontWeight: "500",
    fontFamily: "Nunito Sans",
    fontSize: "3.62rem",
    color: "#FFFFFF",
  },
  bannerImageContainer: {
    marginTop: "80px",
    position: "relative",
    padding: "0 2rem",
    display: "flex",
    justifyContents: "center",
    alignItems: "center",
  },
  bannerImage: {
    width: "100%",
    aspectRatio: "1440/353",
  },
  bannerHeadlineContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  bannerHeadline: {
    display: "block",
    width: "90%",
    marginTop: "110px",
  },
  subheading: {
    display: "block",
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    bannerContainer: {
      flexDirection: "column-reverse",
    },
    bannerHeadlineContainer: {
      padding: "2.5rem 1rem 1.5rem",
    },
    bannerHeadline: {
      fontFamily: '"Nunito Sans", sans-serif',
      fontSize: 24,
      fontWeight: 300,
      textAlign: "center",
      marginBottom: "1rem",
      marginTop: "50px",
      lineHeight: "32.78px",
    },
    subheading: {
      fontSize: theme.spacing.unit * 2,
      textAlign: "center",
      fontFamily: '"Nunito Sans", sans-serif',
      color: "#5F666F",
    },
    bannerImage: {
      marginTop: "55px",
      width: "100%",
      aspectRatio: "1440/353",
    },
    lineBreaker: {
      display: "none",
    },
    bannerTitleContainer: {
      paddingTop: "5.3rem",
      paddingBottom: "1.5rem",
    },
    bannerTitle: {
      fontSize: "1.82rem",
    }
  },
  [theme.breakpoints.up("sm")]: {
    bannerContainer: {
      padding: "2.5rem 0",
    },
    bannerHeadlineContainer: {
      marginBottom: "2.5rem",
    },
    bannerHeadline: {
      marginBottom: "1rem",
      fontSize: theme.spacing.unit * 5,
      fontWeight: 500,
      marginTop: "50px",
    },
    subheading: {
      fontSize: theme.spacing.unit * 2.5,
      lineHeight: 1.5,
    },
    lineBreaker: {
      display: "block",
    },
    bannerTitle: {
      fontSize: "2.82rem",
    }
  },
  [theme.breakpoints.up("md")]: {
    bannerContainer: {
      padding: "2rem 0",
    },
    bannerHeadlineContainer: {
      padding: "0 2rem",
      alignItems: "center",
    },
    lineBreaker: {
      display: "none",
    },
    bannerTitle: {
      fontSize: "3.62rem",
    }
  },
  [theme.breakpoints.up("lg")]: {
    bannerHeadline: {
      fontSize: theme.spacing.unit * 6,
      marginBottom: "1rem",
      subheading: {
        fontSize: theme.spacing.unit * 3,
      },
    },
    lineBreaker: {
      display: "block",
    },
  },
  ['@media screen and (min-width: 2500px) and (max-width: 4999px)']: {
    bannerImage: {
      width: "100%",
      aspectRatio: "1440/100",
    },
  },
  ['@media screen and (min-width: 5000px)']: {
    bannerImage: {
      width: "100%",
      aspectRatio: "1440/75",
    },
  },
  bannerSearchContainer: {
    position: "relative",
    width: "100%",
    padding: "0 25%",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  }
});

