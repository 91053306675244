import { flatten } from "lodash";

const zoneGroup = [
  {
    label: "Upper Zone",
    numbers: [
      ...["AA2", "BB2", "CC2", "A2", "B2", "C2", "D2", "E2", "F2", "G2", "H2", "I2", "J2", "K2", "L2", "M2"],
      ...["DD2", "EE2", "FF2"]
    ],
  },
  {
    label: "Lower Zone",
    numbers: [
      ...["AA", "BB", "CC", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"],
      ...["DD", "EE", "FF", "PDFF", "GG", "HH", "JJ", "KK", "LL", "MM", "NN", "PDNN", "OO"],
      ...["N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      ...["PP", "QQ", "RR"]
    ]
  },
  {
    label: "Upper Zone",
    numbers: [
      ...["RR2", "QQ2", "PP2", "Z2", "Y2", "X2", "W2", "V2", "U2", "T2", "S2", "R2"],
      ...["Q2", "P2", "O2", "N2", "OO2", "NN2", "MM2"]
    ],
  },
];

const otherZones = [  
  {
    label: "Twisted Tea Party Deck",
  },
  {
    label: "Coors Light Party Deck",
  },
  {
    label: "Rooftop Patio",
  },
  {
    label: "ENDZONE",
  }
];

const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});

export default flatten([...zones, ...otherZones]);