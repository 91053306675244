import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MediaQuery from "react-responsive";
import Nav from "../components/nav";
import SideNav from "../components/side-nav";
import Footer from "../components/footer";
import { sideNavPath } from "../utils/general";

const styles = {
  container: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    paddingBottom: '2rem',
  },
};

const WithHeaderLayout = ({
  children,
  currUser,
  isAuthenticated,
  handleLogout,
  history,
  classes,
  ...props
}) => {
  const isSideNavPath = sideNavPath(history.location.pathname);

  return (
    <React.Fragment>
      <Nav
        currUser={currUser}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
        history={history}
        {...props}
      />
      {(isAuthenticated && isSideNavPath) ? (
        <>
          <MediaQuery minWidth={960}>
            <div className={classes.container}>
              <SideNav
                currUser={currUser}
                isAuthenticated={isAuthenticated}
                handleLogout={handleLogout}
                history={history}
                {...props}
              />
              <div className={classes.content}>
                {children}
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={959}>
            <div className={classes.content}>
              {children}
            </div>
          </MediaQuery>
        </>
      ):(
        <div className={classes.content}>
          {children}
        </div>
      )}
      <Footer isSideNavPath={isSideNavPath} />
    </React.Fragment>
  );
};

export default withStyles(styles)(WithHeaderLayout);
