const zones = [
    {
        label: "Lower Zone 101",
    },
    {
        label: "Lower Zone 102",
    },
    {
        label: "Lower Zone 103",
    },
    {
        label: "Lower Zone 104",
    },
    {
        label: "Lower Zone 105",
    },
    {
        label: "Lower Zone 106",
    },
    {
        label: "Lower Zone 107",
    },
    {
        label: "Lower Zone 108",
    },
    {
        label: "Lower Zone 109",
    },
    {
        label: "Lower Zone 110",
    },
    {
        label: "Lower Zone 111",
    },
    {
        label: "Lower Zone 112",
    },
    {
        label: "Lower Zone 113",
    },
    {
        label: "Lower Zone 114",
    },
    {
        label: "Lower Zone 115",
    },
    {
        label: "Lower Zone 116",
    },
    {
        label: "Lower Zone 117",
    },
    {
        label: "Lower Zone 118",
    },
    {
        label: "Lower Zone 119",
    },
    {
        label: "Lower Zone 120",
    },
    {
        label: "Lower Zone 121",
    },
    {
        label: "Lower Zone 122",
    },
    {
        label: "Lower Zone 123",
    },
    {
        label: "Lower Zone 124",
    },
    {
        label: "Lower Zone 125",
    },
    {
        label: "Lower Zone 126",
    },
    {
        label: "Lower Zone 127",
    },
    {
        label: "Lower Zone 128",
    },
    {
        label: "Lower Zone 129",
    },
    {
        label: "Lower Zone 130",
    },
    {
        label: "Lower Zone 131",
    },
    {
        label: "Lower Zone 132",
    },
    {
        label: "Lower Zone 133",
    },
    {
        label: "Lower Zone 134",
    },
    {
        label: "Lower Zone 135",
    },
    {
        label: "Lower Zone 136",
    },
    {
        label: "Lower Zone 137",
    },
    {
        label: "Lower Zone 138",
    },
    {
        label: "Lower Zone 139",
    },
    {
        label: "Lower Zone 140",
    },
    {
        label: "Lower Zone 141",
    },
    {
        label: "Lower Zone 142",
    },
    {
        label: "Lower Zone 143",
    },
    {
        label: "Lower Zone 144",
    },
    {
        label: "Upper Zone 201",
    },
    {
        label: "Upper Zone 202",
    },
    {
        label: "Upper Zone 203",
    },
    {
        label: "Upper Zone 204",
    },
    {
        label: "Upper Zone 205",
    },
    {
        label: "Upper Zone 206",
    },
    {
        label: "Upper Zone 207",
    },
    {
        label: "Upper Zone 208",
    },
    {
        label: "Upper Zone 209",
    },
    {
        label: "Upper Zone 210",
    },
    {
        label: "Upper Zone 211",
    },
    {
        label: "Upper Zone 212",
    },
    {
        label: "Upper Zone 213",
    },
    {
        label: "Upper Zone 214",
    },
    {
        label: "Upper Zone 215",
    },
    {
        label: "Upper Zone 223",
    },
    {
        label: "Upper Zone 224",
    },
    {
        label: "Upper Zone 225",
    },
    {
        label: "Upper Zone 226",
    },
    {
        label: "Upper Zone 227",
    },
    {
        label: "Upper Zone 228",
    },
    {
        label: "Upper Zone 229",
    },
    {
        label: "Upper Zone 230",
    },
    {
        label: "Upper Zone 231",
    },
    {
        label: "Upper Zone 232",
    },
    {
        label: "Upper Zone 233",
    },
    {
        label: "Upper Zone 234",
    },
    {
        label: "Upper Zone 235",
    },
    {
        label: "Upper Zone 236",
    },
    {
        label: "Upper Zone 237",
    },
    {
        label: "Jim Beam Social",
    },
];

export default zones;
